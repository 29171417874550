import { HeavyDutyResults } from '@components';
import { useIsMobile } from '@lib';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import * as React from 'react';
import { FC } from 'react';

import { HeavyDutyResult } from '@components/Feedback/domain';
import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';

import useStyles from '../styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

export type ConfirmGeneratePOsModalData = {
  validationResult: HeavyDutyResult[];
  hasErrors: boolean;
};

type ConfirmGeneratePOsModalProps = {
  control: ModalControl<ConfirmGeneratePOsModalData>;
  onConfirm(): void;
};

const ConfirmGeneratePOsModal: FC<ConfirmGeneratePOsModalProps> = ({
  control,
  onConfirm,
}) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const title = control.data?.hasErrors
    ? 'Errors encountered during validating offer for PO generation'
    : 'Continue PO generation?';
  return (
    <OOModal open={control.isOpen} onClose={control.close} title={title}>
      <Box display="flex">
        <Box
          flex={1}
          marginLeft={isMobile ? 0 : 4}
          marginRight={isMobile ? 0 : 6}
          marginBottom={isMobile ? 0 : 4}
        >
          {!isMobile && (
            <Box className={classes.desktopModalTitle}>{title}</Box>
          )}

          <Box paddingX={2}>
            <HeavyDutyResults results={control.data?.validationResult ?? []} />
          </Box>
        </Box>
      </Box>

      <Box className={classes.floatingFooter}>
        <Box display="flex" padding={isMobile ? 0 : 3}>
          <Box
            paddingLeft={isMobile ? 2 : 0}
            paddingRight={2}
            flex={isMobile ? undefined : '1'}
          >
            <Button fullWidth onClick={control.close}>
              Cancel
            </Button>
          </Box>
          <Box flex="1">
            {control.data?.hasErrors ? (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={control.close}
              >
                Close
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={onConfirm}
              >
                Yes
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </OOModal>
  );
};

export default ConfirmGeneratePOsModal;
