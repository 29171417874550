var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
// https://stackoverflow.com/a/27078401
export function throttle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    var later = function () {
        previous = (options === null || options === void 0 ? void 0 : options.leading) === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
    };
    return function () {
        var currentArgs = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            currentArgs[_i] = arguments[_i];
        }
        var now = Date.now();
        if (!previous && (options === null || options === void 0 ? void 0 : options.leading) === false)
            previous = now;
        var remaining = wait - (now - previous);
        context = this;
        args = currentArgs;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
        }
        else if (!timeout && (options === null || options === void 0 ? void 0 : options.trailing) !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}
export var debounce = function (func, wait, immediate) {
    var timeout = null;
    var debounced = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var context = this;
        var later = function () {
            timeout = null;
            if (!immediate)
                func.call.apply(func, __spreadArray([context], args));
        };
        var callNow = immediate && !timeout;
        timeout && clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            func.call.apply(func, __spreadArray([context], args));
    };
    debounced.cancel = function () {
        timeout && clearTimeout(timeout);
        timeout = null;
    };
    return debounced;
};
export var promiseDebounce = function (func, wait, immediate) {
    var timeout = null;
    var debounced = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var context = this;
        return new Promise(function (resolve, reject) {
            var later = function () {
                timeout = null;
                if (!immediate)
                    func
                        .call.apply(func, __spreadArray([context], args)).then(resolve)
                        .catch(reject);
            };
            var callNow = immediate && !timeout;
            timeout && clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow)
                func
                    .call.apply(func, __spreadArray([context], args)).then(resolve)
                    .catch(reject);
        });
    };
    debounced.cancel = function () {
        timeout && clearTimeout(timeout);
        timeout = null;
    };
    return debounced;
};
