var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Message } from 'localize-react';
import React, { Fragment, useContext } from 'react';
import { formatPrice } from '@optioffer/core';
import { OfferPrintContext, useDevColor } from '../helpers';
import Footer from './snippets/Footer';
import Header from './snippets/Header';
import { commonStyles } from './style';
var bodyStyles = StyleSheet.create({
    table: {
        flex: 1,
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        marginLeft: '2mm',
    },
    cell: {
        maxLines: 1,
        marginBottom: '2mm',
    },
    alignRight: {
        textAlign: 'right',
    },
});
var TextCell = function (_a) {
    var children = _a.children, alignRight = _a.alignRight, rest = __rest(_a, ["children", "alignRight"]);
    return (React.createElement(View, __assign({}, rest),
        React.createElement(Text, { style: [bodyStyles.cell, alignRight ? bodyStyles.alignRight : {}] }, children)));
};
var AddonsPage = function () {
    var addons = useContext(OfferPrintContext).offer.addons;
    var devColor = useDevColor();
    function renderAddons() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Text, { style: [commonStyles.p, commonStyles.h] },
                React.createElement(Message, { descriptor: "addons.title" })),
            React.createElement(View, { style: [bodyStyles.table, devColor('#FDD835')] },
                React.createElement(View, { style: bodyStyles.col },
                    React.createElement(TextCell, { style: [commonStyles.p, commonStyles.h] }, " "),
                    addons.map(function (addon) { return (React.createElement(TextCell, { key: addon.id }, addon.name)); }),
                    React.createElement(TextCell, { style: [commonStyles.p, commonStyles.h] },
                        React.createElement(Message, { descriptor: "addons.total" }))),
                React.createElement(View, { style: bodyStyles.col },
                    React.createElement(TextCell, { style: [commonStyles.p, commonStyles.h], alignRight: true },
                        React.createElement(Message, { descriptor: "addons.listPrice" })),
                    addons.map(function (addon) { return (React.createElement(TextCell, { key: addon.id, alignRight: true }, formatPrice(addon.pricing.listPrice, addon.pricing.addonCurrency, false))); }),
                    React.createElement(TextCell, { style: [commonStyles.p, commonStyles.h], alignRight: true }, formatPrice(addons
                        .map(function (addon) { return addon.pricing.listPrice; })
                        .reduce(function (acc, it) { return acc + it; }, 0), addons[0].pricing.addonCurrency, false))),
                React.createElement(View, { style: bodyStyles.col },
                    React.createElement(TextCell, { style: [commonStyles.p, commonStyles.h], alignRight: true },
                        React.createElement(Message, { descriptor: "addons.netPrice" })),
                    addons.map(function (addon) { return (React.createElement(TextCell, { key: addon.id, alignRight: true }, formatPrice(addon.pricing.netPrice, addon.pricing.addonCurrency, false))); }),
                    React.createElement(TextCell, { style: [commonStyles.p, commonStyles.h], alignRight: true }, formatPrice(addons
                        .map(function (addon) { return addon.pricing.netPrice; })
                        .reduce(function (acc, it) { return acc + it; }, 0), addons[0].pricing.addonCurrency, false))))));
    }
    if (!addons.length)
        return React.createElement(Fragment, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(Page, { wrap: false, size: "A4", style: [commonStyles.page, devColor('#ccc')] },
            React.createElement(Header, null),
            renderAddons(),
            React.createElement(Footer, null))));
};
export default AddonsPage;
