import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useMemo } from 'react';

import { ooBrand } from '@optioffer/core/build/theme';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  bubble: {
    borderRadius: theme.spacing(1),
    width: theme.spacing(1),
    height: theme.spacing(1),
    backgroundColor: `${ooBrand.colors.primary.default}19`, // 40%
  },
  currentBubble: {
    width: theme.spacing(2),
    backgroundColor: ooBrand.colors.primary.default,
  },
  completeBubble: {
    backgroundColor: ooBrand.colors.primary.default,
  },
}));

type BubbleStepIndicatorProps = {
  step: number;
  of: number;
};

const BubbleStepIndicator: FC<BubbleStepIndicatorProps> = ({ step, of }) => {
  const localClasses = useLocalStyles();
  const bubbles = useMemo(
    () =>
      new Array(of).fill(null).map((_Null, idx) => ({
        key: idx,
        complete: idx + 1 < step,
        current: idx + 1 === step,
      })),
    [step, of]
  );

  return (
    <Box className={localClasses.root}>
      {bubbles.map((bubble) => (
        <Box
          key={bubble.key}
          className={clsx(
            localClasses.bubble,
            bubble.current && localClasses.currentBubble,
            bubble.complete && localClasses.completeBubble
          )}
        />
      ))}
    </Box>
  );
};

export default BubbleStepIndicator;
