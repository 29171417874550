import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { OOTheme } from '../../theme';

export const addColoredBar = (
  theme: OOTheme,
  color?: string
): CSSProperties => ({
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: theme.spacing(1),
    backgroundColor: color,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
});

export default makeStyles((theme: OOTheme) => ({
  simpleCard: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  coloredCard: {
    paddingLeft: theme.spacing(2),
    ...addColoredBar(theme),

    '& em': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  descriptionTooltip: {
    '& em': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  client: {
    '&::before': {
      backgroundColor: theme.oo.colors.client,
    },
  },
  product: {
    '&::before': {
      backgroundColor: theme.oo.colors.product,
    },
  },
  offer: {
    '&::before': {
      backgroundColor: theme.oo.colors.offer,
    },
  },
  catalog: {
    '&::before': {
      backgroundColor: theme.oo.colors.catalog,
    },
  },
  addon: {
    '&::before': {
      backgroundColor: theme.oo.colors.addon,
    },
  },

  viewButton: {
    margin: theme.spacing(-1, -1, 0, 2),
  },
  clickableCard: {
    cursor: 'pointer',
  },
  dragging: {
    paddingLeft: theme.spacing(3),
    '&::before': {
      width: theme.spacing(2),
    },
  },
  offerCardTitle: {
    marginBottom: theme.spacing(-1),
  },
  greyBackground: {
    backgroundColor: theme.palette.grey['200'],
  },

  newOfferCard: {
    minHeight: theme.spacing(2) + 24,
  },
  productThumbnail: {},
  offerItemActions: {
    flex: 0,
  },

  overlay: {
    position: 'absolute',
    opacity: 0.7,
    backgroundColor: theme.palette.grey.A400,
    color: theme.palette.getContrastText(theme.palette.grey.A400),
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  iconCard: {
    width: '20em',
    margin: theme.spacing(2),
  },
  iconCardIcon: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    width: '100%',
    height: '4em',
  },
}));
