import { DVKColumn, DVKField } from '@dvkiin/material-commons';
import { useMemo } from 'react';

import { CompanyAdminListItemOnAdminPageFragment } from '@optioffer/graphql';

export const columns = [
  { name: 'name', label: 'Name', type: 'text' },
  {
    name: 'price',
    label: 'Price',
    type: 'number',
    project: (it: number) => `${it / 100} €`,
  },
  {
    name: 'isPricePerUser',
    label: 'Pricing',
    type: 'text',
    project: (it: boolean) => (it ? 'per User' : 'per Company'),
  },
  {
    name: 'duration',
    label: 'Duration',
    type: 'number',
    project: (it: number) => `${it} Months`,
  },
  {
    name: 'stripePlanId',
    label: 'Stripe',
    type: 'text',
    project: (it: string) => (!!it ? '👌' : '⚠'),
  },
  {
    name: 'isPublic',
    label: 'Public',
    type: 'text',
    project: (it: boolean) => (it ? 'Public' : 'Not public'),
  },
  {
    name: 'isDefault',
    label: 'Default Plan',
    type: 'text',
    project: (it: boolean) => (it ? 'DEFAULT' : ''),
  },
  {
    name: 'publicFor',
    label: 'public for companies',
    type: 'text',
    project: (companies: CompanyAdminListItemOnAdminPageFragment[]) =>
      companies.map((it) => it.name).join(', '),
  },
] as DVKColumn[];

const usePlanFields = (features: { name: string }[]) => {
  return useMemo<DVKField[]>(
    () => [
      {
        name: 'name',
        label: 'Name',
        type: 'text',
        required: true,
        autoFocus: true,
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        required: true,
      },
      {
        name: 'price',
        label: 'Price (in Euro-cents)',
        type: 'number',
        required: true,
      },
      {
        name: 'isPricePerUser',
        label: 'Price per user',
        type: 'checkbox',
        required: true,
      },
      {
        name: 'duration',
        label: 'Duration (in Months)',
        type: 'number',
        required: true,
      },
      {
        name: 'stripePlanId',
        label: 'Stripe Plan ID',
        type: 'text',
        required: false,
      },

      {
        name: 'usersLimit',
        label: 'Allowed users',
        type: 'number',
        required: true,
      },
      {
        name: 'productsLimit',
        label: 'Allowed products',
        type: 'number',
        required: true,
      },
      {
        name: 'offersLimit',
        label: 'Allowed offers (per month)',
        type: 'number',
        required: true,
      },

      { name: 'isPublic', label: 'Public', type: 'checkbox' },
      {
        name: 'isDefault',
        label: 'Default plan',
        type: 'checkbox',
      },
      {
        name: 'hasTrial',
        label: 'Plan has trial period',
        type: 'checkbox',
      },
      {
        name: 'includedFeatures',
        label: 'Included features',
        type: 'combo-box',
        search: async () => features.map(({ name }) => ({ name, label: name })),
        multiple: true,
      },
    ],
    [features]
  );
};

export default usePlanFields;
