import {
  CheckBoxInput,
  NumberFormatInput,
  OptiInlineTextField,
} from '@components';
import { IconButton, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import PanoramaIcon from '@material-ui/icons/Panorama';
import RemoveIcon from '@material-ui/icons/Remove';
import clsx from 'clsx';
import React, { FC } from 'react';

import { formatPrice } from '@optioffer/core';
import { Currency, OfferItem } from '@optioffer/graphql';

import OOTable, {
  OOTableCell,
  OOTableCellWithSort,
  OOTableGenericProps,
  OOTableRow,
} from '@components/Table/OOTable';

import { ProductSearchResult } from '../../pages/NewOffer/domain';

const useLocalStyles = makeStyles((theme) => ({
  productName: {
    fontSize: theme.typography.pxToRem(14),
    opacity: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productCode: {
    fontSize: theme.typography.pxToRem(12),
    opacity: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productPrice: {
    fontSize: theme.typography.pxToRem(16),
  },
  placeholderIcon: {
    '& > svg': {
      fontSize: '3rem',
      opacity: '30%',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  imageTableCell: {
    minWidth: 75,
    height: 54,

    display: 'flex',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundOrigin: 'content-box',
  },
  actionsTableCell: {
    minWidth: 144,
  },
  checkboxTableCell: {
    verticalAlign: 'middle',
  },
  quantityBox: {
    minWidth: 47,
    marginRight: 0,
  },
}));

type ProductTableProps<
  T = {
    item: ProductSearchResult;
    offerItem?: OfferItem;
    selected?: boolean;
  }
> = OOTableGenericProps<T> & {
  currency: Currency;
  handleIncreaseQuantity: (item: ProductSearchResult) => Promise<any>;
  handleUpdateQuantity?: (offerItem: OfferItem) => Promise<any>;
  handleRemoveProduct?: (item: ProductSearchResult) => Promise<any>;
  actionColumnWithCheckbox?: boolean;
  hideActionColumn?: boolean;
};

const ProductTable: FC<ProductTableProps> = ({
  onEdit,
  currency,
  handleIncreaseQuantity,
  handleUpdateQuantity,
  handleRemoveProduct,
  actionColumnWithCheckbox,
  hideActionColumn,
  ...rest
}) => {
  const localClasses = useLocalStyles();

  return (
    <OOTable
      {...rest}
      onEdit={onEdit}
      renderHeader={() => (
        <>
          <OOTableCell width="70" />
          <OOTableCellWithSort sortString="name" width="50%" align="left">
            Title
          </OOTableCellWithSort>
          <OOTableCellWithSort
            sortString="versions.code"
            width="20%"
            align="left"
          >
            Code
          </OOTableCellWithSort>
          <OOTableCellWithSort
            sortString="versions.price"
            width="20%"
            align="right"
          >
            List Price
          </OOTableCellWithSort>
          {!hideActionColumn && <OOTableCell />}
        </>
      )}
      renderRow={(row) => (
        <OOTableRow
          key={`${row.item.product.id}-${row.item.version.id}`}
          className={localClasses.pointer}
          onClick={() => onEdit(row)}
        >
          <OOTableCell
            className={localClasses.imageTableCell}
            style={{
              backgroundImage: row.item.product?.image?.thumbnail
                ? `url("${row.item.product.image.thumbnail}")`
                : undefined,
            }}
          >
            {!row.item.product?.image?.thumbnail && (
              <Box className={localClasses.placeholderIcon}>
                <PanoramaIcon />
              </Box>
            )}
          </OOTableCell>
          <OOTableCell className={localClasses.productName}>
            {row.offerItem?.product?.name ?? row.item.product?.name}
          </OOTableCell>
          <OOTableCell className={localClasses.productCode}>
            {row.offerItem?.product?.code ?? row.item.version?.code}
          </OOTableCell>
          <OOTableCell className={localClasses.productPrice} align="right">
            {formatPrice(
              row.offerItem?.product?.price ?? row.item.version?.price,
              currency
            )}
          </OOTableCell>
          {!hideActionColumn && (
            <OOTableCell
              className={clsx(
                actionColumnWithCheckbox
                  ? localClasses.checkboxTableCell
                  : localClasses.actionsTableCell
              )}
            >
              <Box display="flex" alignItems="center" justifyContent="end">
                {actionColumnWithCheckbox ? (
                  <CheckBoxInput
                    checked={row.selected}
                    onClick={(e) => e?.stopPropagation()}
                    onChange={(event, checked) => {
                      checked
                        ? handleIncreaseQuantity(row.item)
                        : handleRemoveProduct?.(row.item);
                    }}
                  />
                ) : (
                  <>
                    {row.offerItem ? (
                      <>
                        <IconButton
                          onClick={async (e) => {
                            e.stopPropagation();
                            row.offerItem &&
                              handleUpdateQuantity &&
                              (await handleUpdateQuantity({
                                ...row.offerItem,
                                quantity: row.offerItem.quantity - 1,
                              }));
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <OptiInlineTextField
                          className={localClasses.quantityBox}
                          alignTextRight
                          InputProps={{
                            inputComponent: NumberFormatInput as any,
                            inputProps: {
                              min: 0,
                              onFocus: (e) => {
                                requestAnimationFrame(() => {
                                  e.target.select();
                                });
                              },
                            },
                          }}
                          onClick={(e) => e.stopPropagation()}
                          value={row.offerItem.quantity}
                          onBlur={async (e) => {
                            const newQuantity = parseInt(e.target.value);
                            row.offerItem &&
                              row.offerItem.quantity !== newQuantity &&
                              handleUpdateQuantity &&
                              (await handleUpdateQuantity({
                                ...row.offerItem,
                                quantity: newQuantity,
                              }));
                          }}
                        />
                      </>
                    ) : (
                      <Box flex={1} />
                    )}
                    <Box marginRight={-2}>
                      <IconButton
                        color={'primary'}
                        onClick={async (e) => {
                          e.stopPropagation();
                          await handleIncreaseQuantity(row.item);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </>
                )}
              </Box>
            </OOTableCell>
          )}
        </OOTableRow>
      )}
    />
  );
};

export default ProductTable;
