import { makeStyles } from '@material-ui/core';

import { ooBrand } from '@optioffer/core';

const useStyles = makeStyles((theme) => ({
  desktopModalTitle: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 'bold',
    margin: theme.spacing(0, 0, 2, 1.5),
  },
  desktopModalSubTitle: {
    fontSize: theme.typography.pxToRem(13),
    margin: theme.spacing(-2, 0, 2, 1.5),
  },
  floatingFooter: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    padding: theme.spacing(2, 1.5),
    backgroundColor: ooBrand.colors.pureWhite,
    boxShadow: ooBrand.boxShadow.grayTop,

    [theme.breakpoints.up('lg')]: {
      '&:not(.floatOnDesktop)': {
        boxShadow: 'none',

        '& button.NavigationButton': {
          width: theme.spacing(32),
        },

        width: '100%',
        position: 'static',
        alignSelf: 'end',
        padding: theme.spacing(0),
        backgroundColor: 'transparent',
      },
    },
  },
}));

export default useStyles;
