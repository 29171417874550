import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  paymentMethod: {
    marginBottom: theme.spacing(1),
  },
  paymentSection: {
    width: 600,
    marginLeft: 24,
  },
  cardInputForm: {
    border: `1px solid ${grey.A100}`,
    borderRadius: 4,
    padding: theme.spacing(1),
  },
  cardInputButton: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    margin: theme.spacing(1, -1, -1),
    width: `calc(100% + ${theme.spacing(2)}px)`,
  },
  countryInput: {
    width: 280,
    marginBottom: 10,
  },
  addressInput: {
    width: 280,
  },
  cardElement: {
    marginTop: 15,
  },
  addressField: {
    marginBottom: 10,
  },
  almostHeader: {
    marginTop: theme.spacing(1),
  },
}));
