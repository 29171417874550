import {
  createDefaultActions,
  DVKColumn,
  DVKPagination,
  DVKSort,
  DVKTable,
} from '@dvkiin/material-commons';
import { UpdatePaginationHandler } from '@lib';
import Paper from '@material-ui/core/Paper';
import React, { FC, useMemo } from 'react';

import { ProductBundle } from '../domain';
import { productBundleDefaultFields } from '../fields';
import useStyles from '../styles';

type ProductBundlesTableProps = {
  productBundles: ProductBundle[];
  total: number;
  pagination: DVKPagination;
  sort: DVKSort | null;
  onPaginationUpdate: UpdatePaginationHandler;
  onEdit: (productBundle: any) => void;
  onDelete: (productBundle: any) => void;
  onClone: (productBundle: any) => void;
};

const ProductBundlesTable: FC<ProductBundlesTableProps> = ({
  productBundles,
  total,
  pagination,
  sort,
  onPaginationUpdate,
  onEdit,
  onDelete,
  onClone,
}) => {
  const { tableCard } = useStyles();
  const actions = useMemo(
    () => createDefaultActions({ onEdit, onDelete, onClone }),
    [onEdit, onDelete, onClone]
  );

  const rows = useMemo(
    () =>
      productBundles.map((productBundle) => ({
        id: productBundle.id,
        image: productBundle.image,
        name: productBundle.name,
        description: productBundle.description,
      })),
    [productBundles]
  );

  return (
    <Paper className={tableCard}>
      <DVKTable
        columns={
          [
            {
              name: 'image.thumbnail',
              label: 'Thumbnail',
              type: 'imageBase64',
              noSort: true,
            },
            ...productBundleDefaultFields,
          ] as DVKColumn[]
        }
        actions={actions}
        rows={rows}
        total={total}
        pagination={pagination}
        sort={sort}
        onPaginationSortUpdate={onPaginationUpdate}
        onRowClick={onEdit}
      />
    </Paper>
  );
};

export default ProductBundlesTable;
