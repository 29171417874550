import { OptiTextField } from '@components';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { RoomOutlined } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';

import { COUNTRIES_DIAL_CODE_AND_FLAG } from '@components/Payment';

import { Country, CountrySelectorStyleParams } from '../Card/domain';
import useStyles from './styles';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    minWidth: 400,
    fontSize: 14,
    backgroundColor: theme.palette.common.white,
  },
}));

export type CountrySelectorProps = {
  onCountrySelected?: (country: Country | null) => void;
  selectorStyleOptions?: CountrySelectorStyleParams;
  defaultValue?: Country;
  value?: Country;
};

const CountrySelector: FC<CountrySelectorProps> = ({
  onCountrySelected,
  selectorStyleOptions = {
    size: 'small',
    showAdornment: true,
    inputVariant: 'outlined',
    placeholder: 'Country',
    label: undefined,
  },
  defaultValue,
  value,
}) => {
  const { icon, flex, center, dropDownIcon } = useStyles();
  const localClasses = useLocalStyles();
  const [selectedCountry, setSelectedCountry] = useState<any>(null);

  const adornment = selectorStyleOptions.showAdornment
    ? {
        startAdornment: (
          <InputAdornment position="start">
            {selectedCountry ? (
              <img
                src={selectedCountry.flag}
                alt={selectedCountry.name}
                className={icon}
              />
            ) : (
              <RoomOutlined className={icon} />
            )}
          </InputAdornment>
        ),
      }
    : {};

  useEffect(() => {
    setSelectedCountry(value);
  }, [value]);

  return (
    <Autocomplete
      autoHighlight
      className={localClasses.root}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option.name}
      id="country-select"
      options={COUNTRIES_DIAL_CODE_AND_FLAG}
      value={value}
      onChange={(e, newValue) => {
        setSelectedCountry(newValue);
        onCountrySelected && onCountrySelected(newValue);
      }}
      renderInput={(params) => (
        <OptiTextField
          {...params}
          placeholder={selectorStyleOptions.placeholder}
          variant={selectorStyleOptions.inputVariant}
          label={selectorStyleOptions.label}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            ...adornment,
          }}
        />
      )}
      renderOption={(props) => (
        <>
          <div className={flex}>
            <img src={props.flag} alt={props.name} className={dropDownIcon} />
            <div className={clsx(flex, center)}>{props.name}</div>
          </div>
        </>
      )}
      size={selectorStyleOptions.size}
    />
  );
};

export default CountrySelector;
