import { uuid } from '@dvkiin/material-commons';
import {
  isUUID,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useIsMobile,
} from '@lib';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  Auth_CurrentUserDocument,
  Profile_UpdateProfileDocument,
  ProfileOnProfilePageFragment,
  ProjectContributionInput,
} from '@optioffer/graphql';

import LoadingAwareButton from '@components/LoadingAwareButton';
import { isImage } from '@components/form/MediaInput';
import OOModal from '@components/modals/OOModal';

import { getQueryName } from '@lib/graphql';
import { ModalControl } from '@lib/materialCommons';

import addCustomerIllustration from '@resources/illustrations/add_customer.svg';

import useStyles from '../../NewOffer/styles';
import ProjectContributionCard from './ProjectContributionCard';
import { mapToProfileInput } from './helpers';

// const useProjectModalStyles = makeStyles((theme) => ({
//   personalInfoModalIcon: {
//     opacity: '30%',
//     fontSize: '65px',
//     color: theme.palette.primary.main,
//   },
// }));

type ProjectModalProps = {
  control: ModalControl<ProfileOnProfilePageFragment>;
};

const ProjectModal: FC<ProjectModalProps> = ({ control }) => {
  const classes = useStyles();
  // const localClasses = useProjectModalStyles();
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      projectContributions: [] as ProjectContributionInput[],
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (!control.data) return;

      setSubmitting(true);
      try {
        await updateProfile({
          variables: {
            id: control.data.id,
            profile: {
              ...mapToProfileInput(control.data),

              // actually changed
              projectContributions: values.projectContributions.map((c) => {
                return {
                  id: !c.id || isUUID(c.id) ? undefined : c.id,
                  description: c.description,
                  services: c.services,
                  project: {
                    id:
                      !c.project.id || isUUID(c.project.id)
                        ? undefined
                        : c.project.id,
                    name: c.project.name,
                    location: c.project.location,
                    date: c.project.date,
                    link: c.project.link,
                    description: c.project.description,
                    media: c.project.media
                      .filter(isImage)
                      .map((it) => it.imageFileForUpload || it.id),
                  },
                };
              }),
            },
          },
        });

        control.close();
      } catch {
        NOOP_graphqlErrorManagement();
      } finally {
        setSubmitting(false);
      }
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const [updateProfile] = useEnhancedMutation(Profile_UpdateProfileDocument, {
    refetchQueries: [getQueryName(Auth_CurrentUserDocument)],
    success: {
      message: 'Projects saved.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while saving your projects.',
    },
    formik,
  });

  const newProjectContribution = useCallback(
    () =>
      ({
        id: uuid(),
        description: '',
        services: control.data?.services ?? [],
        project: {
          id: uuid(),
          name: '',
          location: '',
          date: '',
          link: '',
          description: '',
          media: [],
        },
      } as ProjectContributionInput),
    [control.data]
  );

  useEffect(() => {
    if (control.data) {
      formikRef.current.setValues({
        projectContributions: control.data.projectContributions.length
          ? control.data.projectContributions.map((c) => ({
              id: c.id,
              description: c.description,
              services: c.services,
              project: {
                id: c.project.id,
                name: c.project.name,
                location: c.project.location,
                date: c.project.date,
                link: c.project.link,
                description: c.project.description,
                media: c.project.media
                  .filter((m) => !!m.image)
                  .map((m) => ({
                    id: uuid(),
                    type: 'IMAGE',
                    thumbnail: m.image!.thumbnail,
                    fullImage: m.image!.fullImage ?? m.image!.thumbnail, // 🤔
                  })),
              },
            }))
          : [newProjectContribution()],
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [newProjectContribution, formikRef, control.data]);

  const title = 'Show off your past projects';
  return (
    <>
      <OOModal title={title} open={control.isOpen} onClose={control.close}>
        <Box display="flex">
          <Box
            className={classes.illustrationWrapper}
            marginLeft={isMobile ? 0 : 6}
          >
            <img
              className={classes.illustration}
              src={addCustomerIllustration}
              alt="add customer illustration"
            />
          </Box>
          <Box
            flex={1}
            marginLeft={isMobile ? 0 : 4}
            marginRight={isMobile ? 0 : 6}
            marginBottom={isMobile ? 0 : 4}
          >
            {control.data && (
              <form onSubmit={formik.handleSubmit}>
                {!isMobile && (
                  <Box className={classes.desktopModalTitle}>{title}</Box>
                )}

                <Box paddingX={1.5} marginBottom={isMobile ? 10 : 2}>
                  {formik.values.projectContributions.map((xp, idx) => (
                    <Box marginY={2} key={idx}>
                      <ProjectContributionCard
                        formik={formik}
                        fieldName={`projectContributions[${idx}]`}
                        removeProjectContribution={() =>
                          formik.setFieldValue(
                            'projectContributions',
                            formik.values.projectContributions.filter(
                              (it) => it !== xp
                            )
                          )
                        }
                      />
                    </Box>
                  ))}

                  <Button
                    color="primary"
                    onClick={() => {
                      formik.setFieldValue('projectContributions', [
                        ...formik.values.projectContributions,
                        newProjectContribution(),
                      ]);
                    }}
                  >
                    + Add Project
                  </Button>
                </Box>

                <Box display="flex" className={clsx(classes.floatingFooter)}>
                  <Box flex="1" margin={isMobile ? 0 : 1.5}>
                    <LoadingAwareButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      loading={formik.isSubmitting}
                    >
                      Save Projects
                    </LoadingAwareButton>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </OOModal>
    </>
  );
};

export default ProjectModal;
