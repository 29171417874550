import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

import useStyles from './styles';

const ErrorPage: FC = () => {
  const { main, paper } = useStyles();

  return (
    <>
      <main className={main}>
        <Paper className={paper}>
          <Typography component="h4" variant="h4">
            404: Ooops, this page could not be found.
          </Typography>
        </Paper>
      </main>
    </>
  );
};

export default ErrorPage;
