import { uuid } from '@dvkiin/material-commons';

import {
  CalculationType,
  ClientInOfferModuleFragment,
  OfferClient,
  OfferItem,
  OfferItemInOfferModuleFragment,
  Product,
  ProductInProductSectionModuleFragment,
  ProductSearchResultWithoutVersionsInProductSectionModuleFragment,
  ProductSpecification,
  ProductVersion,
  ProductVersionInProductSectionModuleFragment,
  ProductVersionSearchResultInProductSectionModuleFragment,
  Scalars,
} from '@optioffer/graphql';

export function isClientTemporary(client: ClientInOfferModuleFragment) {
  return client.id?.startsWith(TEMPORARY_CLIENT_ID);
}

export function isOfferClientTemporary(client: OfferClient) {
  return client.clientId?.startsWith(TEMPORARY_CLIENT_ID);
}

export function getRealClientId(clientId: Scalars['ID']): Scalars['ID'] {
  return clientId?.replace(TEMPORARY_CLIENT_ID, '') ?? '';
}

export function convertOfferClientToClient<T extends OfferClient | undefined>(
  offerClient: T
): ClientInOfferModuleFragment | (undefined extends T ? undefined : never) {
  if (!offerClient) return undefined as undefined extends T ? undefined : never;
  return {
    ...offerClient,
    id: offerClient.clientId ?? '',
  } as ClientInOfferModuleFragment;
}

export function convertClientToOfferClient<
  T extends ClientInOfferModuleFragment | undefined
>(client: T): OfferClient | (undefined extends T ? undefined : never) {
  if (!client) return undefined as undefined extends T ? undefined : never;

  return {
    ...(client as ClientInOfferModuleFragment),
    clientId: client.id,
    id: undefined,
  } as OfferClient;
}

export const TEMPORARY_CLIENT_ID = 'TEMPORARY_CLIENT_ID';

export type ProductSearchResult = {
  version: ProductVersionSearchResultInProductSectionModuleFragment;
  product: ProductSearchResultWithoutVersionsInProductSectionModuleFragment;
};

export type ProductData = {
  offerItem: OfferItem;
};

export type ProductDataWithOfferItem = {
  // product: ProductInOfferModuleFragment;
  // version: ProductVersionInOfferModuleFragment;
  offerItem: OfferItem;
};

export const CUSTOM_PRODUCT_ID_SUFFIX = 'CUSTOM_PRODUCT-';
export function createCustomProductId() {
  return `${CUSTOM_PRODUCT_ID_SUFFIX}${uuid()}`;
}
export function isCustomProduct(product: Pick<Product, 'id'>): boolean {
  return product.id?.indexOf(CUSTOM_PRODUCT_ID_SUFFIX) === 0;
}

export const CUSTOM_PRODUCT_VERSION_ID_SUFFIX = 'CUSTOM_PRODUCT_VERSION-';
export function createCustomProductVersionId() {
  return `${CUSTOM_PRODUCT_VERSION_ID_SUFFIX}${uuid()}`;
}
export function isCustomProductVersion(
  productVersion: Pick<ProductVersion, 'id'>
): boolean {
  return productVersion.id?.indexOf(CUSTOM_PRODUCT_VERSION_ID_SUFFIX) === 0;
}

export const CUSTOM_SPECIFICATION_ID_SUFFIX = 'CUSTOM_SPECIFICATION-';
export function createCustomSpecificationId() {
  return `${CUSTOM_SPECIFICATION_ID_SUFFIX}${uuid()}`;
}
export function isCustomSpecification(
  specification: Pick<ProductSpecification, 'id'>
): boolean {
  return specification.id?.indexOf(CUSTOM_SPECIFICATION_ID_SUFFIX) === 0;
}

export const CUSTOM_OFFER_ITEM_ID_SUFFIX = 'CUSTOM_OFFER_ITEM-';
export function createCustomOfferItemId() {
  return `${CUSTOM_OFFER_ITEM_ID_SUFFIX}${uuid()}`;
}
export function isCustomOfferItem(offerItem: Pick<OfferItem, 'id'>): boolean {
  return offerItem.id?.indexOf(CUSTOM_OFFER_ITEM_ID_SUFFIX) === 0;
}

export function mapToOfferItem(
  product: ProductInProductSectionModuleFragment,
  productVersion: ProductVersionInProductSectionModuleFragment,
  createId: () => string = uuid
): OfferItem {
  return {
    id: createId(),
    quantity: 1,
    addons: [],
    children: [],
    offerItemDiscount: {
      calculationType: CalculationType.PERCENTAGE,
      value: 0,
    },
    product: {
      id: uuid(),
      productId: product.id,
      productVersionId: productVersion.id,
      name: product.name,
      code: productVersion.code,
      description: product.description ?? '',
      image: product.image,
      price: productVersion.price,
      specifications:
        productVersion.attributeValues.map((attrVal) => ({
          id: uuid(),
          name: attrVal.attribute.name,
          unit: attrVal.attribute.unit,
          value: attrVal.value,
        })) ?? [],
    },
    pricing: undefined as any, // this will force price calculation
  } as any;
}

export function getProductSearchResult(
  it: OfferItem
): ProductSearchResult | undefined {
  if (!it.product) return undefined;
  return {
    product: {
      id: it.product.productId ?? 'idk, fix this',
      name: it.product.name,
      image: it.product.image,
    },
    version: {
      id: it.product.productVersionId ?? 'idk, fix this',
      code: it.product.code,
      price: it.product.price,
    },
  };
}

export function convertOfferItemToProductData(
  it?: OfferItemInOfferModuleFragment | null
): ProductData | undefined {
  if (!it) return undefined;

  return {
    offerItem: it,
  } as any;
}
