import { DVKPagination, DVKSort, DVKTable } from '@dvkiin/material-commons';
import { UpdatePaginationHandler } from '@lib';
import { Paper } from '@material-ui/core';
import React, { FC } from 'react';

import { CompanyAdminListItemOnAdminPageFragment } from '@optioffer/graphql';

import { columns } from './fields';
import useStyles from './styles';

type PlansTableProps = {
  companies: CompanyAdminListItemOnAdminPageFragment[];
  total: number;
  pagination: DVKPagination;
  sort: DVKSort | null;
  onPaginationUpdate: UpdatePaginationHandler;
};

const CompanyTable: FC<PlansTableProps> = ({
  companies,
  total,
  pagination,
  sort,
  onPaginationUpdate,
}) => {
  const { tableCard } = useStyles();

  return (
    <Paper className={tableCard}>
      <DVKTable
        columns={columns}
        rows={companies}
        total={total}
        pagination={pagination}
        sort={sort}
        onPaginationSortUpdate={onPaginationUpdate}
      />
    </Paper>
  );
};

export default CompanyTable;
