import { Separator } from '@components';
import { DVKForm, DVKObject } from '@dvkiin/material-commons';
import {
  getInvalidFields,
  getOrigin,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
} from '@lib';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { FC, useMemo } from 'react';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Link, useHistory } from 'react-router-dom';

import {
  Auth_DirectSignupDocument,
  Auth_DirectSignupMutation,
  Auth_GoogleLoginOrSignupDocument,
  Auth_GoogleLoginOrSignupMutation,
} from '@optioffer/graphql';

import useStyles from '../styles';
import LoginWrapper from '../wrapper';

type DirectSignupPageProps = {};

const googleClientId: string | undefined =
  process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

const DirectSignupPage: FC<DirectSignupPageProps> = () => {
  const styles = useStyles();
  const { push } = useHistory();
  const [
    directSignup,
    { error, loading: directSignupLoading },
  ] = useEnhancedMutation(Auth_DirectSignupDocument, {
    error: {
      type: 'MODAL',
      message:
        'We were not able to create your account. Please check your input and try again.',
    },
    success: { message: 'Signup successful.' },
  });
  const [
    signupWithGoogle,
    { loading: signupWithGoogleLoading },
  ] = useEnhancedMutation(Auth_GoogleLoginOrSignupDocument, {
    error: {
      type: 'MODAL',
      message:
        'We were not able to create your account with Google. Please try again or create an account using email and password.',
    },
    success: { message: 'Signup successful.' },
  });

  const loading = directSignupLoading || signupWithGoogleLoading;
  const invalidFields = useMemo(() => getInvalidFields(error), [error]);

  async function handleSignup({ email, password }: DVKObject) {
    try {
      const { data } = await directSignup({
        variables: {
          user: {
            email: email as string,
            password: password as string,
          },
        },
      });
      const {
        directSignup: { accessToken, refreshToken },
      } = data as Auth_DirectSignupMutation;

      localStorage.setItem('x-token', accessToken);
      localStorage.setItem('x-refresh-token', refreshToken);

      push('/profile/new');
    } catch (_e) {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleGoogleSignup({
    code,
  }: GoogleLoginResponse | GoogleLoginResponseOffline) {
    if (!code) throw new Error('Invalid code from Google login');
    try {
      const { data } = await signupWithGoogle({
        variables: {
          code,
          origin: getOrigin(),
        },
      });
      const {
        googleLoginOrSignup: { accessToken, refreshToken },
      } = data as Auth_GoogleLoginOrSignupMutation;

      localStorage.setItem('x-token', accessToken);
      localStorage.setItem('x-refresh-token', refreshToken);

      push('/profile/new');
    } catch (_e) {
      NOOP_graphqlErrorManagement();
    }
  }

  function renderSignupForm() {
    return (
      <>
        <DVKForm
          className={styles.form}
          fields={[
            {
              name: 'email',
              label: 'Email Address',
              type: 'email',
              required: true,
              autoFocus: true,
              errorMessage: {
                duplicate:
                  'An account exists with this email. Please login or reset your password.',
              },
            },
            {
              name: 'password',
              label: 'Password',
              type: 'password',
              required: true,
            },
          ]}
          invalidFields={invalidFields}
          onSubmit={handleSignup}
          bottomContent={
            <>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                className={styles.submit}
              >
                Create free account
              </Button>
            </>
          }
        />
      </>
    );
  }

  return (
    <LoginWrapper title="Create a new OptiOffer Account" backLink>
      {renderSignupForm()}

      {googleClientId && (
        <>
          <Separator variant="or" />

          <GoogleLogin
            clientId={googleClientId}
            buttonText="Signup with Google"
            responseType="code"
            disabled={loading}
            className={styles.googleLoginButton}
            onSuccess={handleGoogleSignup}
            onFailure={console.error}
          />
        </>
      )}

      <Typography variant="body2" gutterBottom className={styles.footer}>
        If an account already exists, please try to{' '}
        <Link to="/login">login</Link> or{' '}
        <Link to="/reset-password">reset your password</Link>. Alternatively,
        you could try again or contact{' '}
        <a href="mailto:support@optioffer.com">support@optioffer.com</a>. We'll
        be more than happy to assist you!
      </Typography>
    </LoginWrapper>
  );
};

export default DirectSignupPage;
