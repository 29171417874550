import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { useUuid } from '@lib/hooks';

export type ModalControl<TData = any> = {
  isOpen: boolean;
  data?: TData;
  open: (data?: TData) => void;
  close: () => void;
};

export type ModalState<TData> = {
  data: TData | undefined;
};

export function useModal<TData = any>(): ModalControl<TData> {
  const modalUuid = useUuid();
  const [isOpen, setIsOpen] = useQueryParam(modalUuid, BooleanParam);
  const [{ data }, setModalState] = useState({} as ModalState<TData>);
  const dataRef = useRef(data);
  useUpdateRefIfShallowNew(dataRef, data);

  const open = useCallback(
    (data?: TData) => {
      setModalState({ data: data ?? ((null as unknown) as TData) });
      setIsOpen(true);
    },
    [setModalState, setIsOpen]
  );

  const close = useCallback(() => {
    setIsOpen(undefined, 'replaceIn');
  }, [setIsOpen]);

  useEffect(() => {
    // make sure the data is removed even if the modal is closed using history.goBack
    if (!isOpen) {
      setModalState({ data: undefined });
    }

    // fix history state caused by user navigating forward to an open modal
    // check with `undefined`, because a modal with no ada is opened with `nul`l
    if (isOpen && dataRef.current === undefined) {
      setIsOpen(undefined, 'replaceIn');
    }
  }, [isOpen, setIsOpen, dataRef, setModalState]);

  return {
    isOpen: isOpen || false,
    data,
    open,
    close,
  };
}

export type MenuControl = {
  isOpen: boolean;
  anchorElement?: null | HTMLElement;
  open: (event: React.MouseEvent<HTMLElement>) => void;
  close: () => void;
};

export function useMenu(): MenuControl {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );

  const open = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(event.currentTarget);
    },
    [setAnchorElement]
  );

  const close = useCallback(() => {
    setAnchorElement(null);
  }, [setAnchorElement]);

  return {
    isOpen: !!anchorElement,
    anchorElement,
    open,
    close,
  };
}
