export type TaxIdType = typeof taxIdTypes[0];

export const taxIdTypes = [
  {
    country: 'Australia',
    type: 'au_abn',
    description: 'Australian Business Number (AU ABN)',
    countryCode: 'AU',
  },
  {
    country: 'Canada',
    type: 'ca_bn',
    description: 'Canadian BN',
    countryCode: 'CA',
  },
  {
    country: 'Canada',
    type: 'ca_qst',
    description: 'Canadian QST number',
    countryCode: 'CA',
  },
  {
    country: 'Spain',
    type: 'es_cif',
    description: 'Spanish CIF number',
    countryCode: 'ES',
  },
  {
    country: 'Austria',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'AT',
  },
  {
    country: 'Belgium',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'BE',
  },
  {
    country: 'Bulgaria',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'BG',
  },
  {
    country: 'Cyprus',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'CY',
  },
  {
    country: 'Germany',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'DE',
  },
  {
    country: 'Denmark',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'DK',
  },
  {
    country: 'Estonia',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'EE',
  },
  {
    country: 'Spain',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'ES',
  },
  {
    country: 'Finland',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'FI',
  },
  {
    country: 'France',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'FR',
  },
  {
    country: 'Greece',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'GR',
  },
  {
    country: 'Croatia',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'HR',
  },
  {
    country: 'Hungary',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'HU',
  },
  {
    country: 'Ireland',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'IE',
  },
  {
    country: 'Italy',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'IT',
  },
  {
    country: 'Lithuania',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'LT',
  },
  {
    country: 'Luxembourg',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'LU',
  },
  {
    country: 'Latvia',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'LV',
  },
  {
    country: 'Malta',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'MT',
  },
  {
    country: 'Poland',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'PL',
  },
  {
    country: 'Portugal',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'PT',
  },
  {
    country: 'Romania',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'RO',
  },
  {
    country: 'Sweden',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'SE',
  },
  {
    country: 'Slovenia',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'SI',
  },
  {
    country: 'Slovakia',
    type: 'eu_vat',
    description: 'European VAT number',
    countryCode: 'SK',
  },
  {
    country: 'Hong Kong',
    type: 'hk_br',
    description: 'Hong Kong BR number',
    countryCode: 'HK',
  },
  {
    country: 'India',
    type: 'in_gst',
    description: 'Indian GST number',
    countryCode: 'IN',
  },
  {
    country: 'Japan',
    type: 'jp_cn',
    description: 'Japanese Corporate Number (Hōjin Bangō)',
    countryCode: 'JP',
  },
  {
    country: 'Liechtenstein',
    type: 'li_uid',
    description: 'Liechtensteinian UID number',
    countryCode: 'LI',
  },
  {
    country: 'Mexico',
    type: 'mx_rfc',
    description: 'Mexican RFC number',
    countryCode: 'MX',
  },
  {
    country: 'Malaysia',
    type: 'my_itn',
    description: 'Malaysian ITN',
    countryCode: 'MY',
  },
  {
    country: 'Malaysia',
    type: 'my_sst',
    description: 'Malaysian SST number',
    countryCode: 'MY',
  },
  {
    country: 'Norway',
    type: 'no_vat',
    description: 'Norwegian VAT number',
    countryCode: 'NO',
  },
  {
    country: 'New Zealand',
    type: 'nz_gst',
    description: 'New Zealand GST number',
    countryCode: 'NZ',
  },
  {
    country: 'Singapore',
    type: 'sg_uen',
    description: 'Singaporean UEN',
    countryCode: 'SG',
  },
  {
    country: 'Thailand',
    type: 'th_vat',
    description: 'Thai VAT',
    countryCode: 'TH',
  },
  {
    country: 'South Africa',
    type: 'za_vat',
    description: 'South African VAT number',
    countryCode: 'ZA',
  },
];
