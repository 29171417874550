import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    height: theme.spacing(4),
    width: '100%',
  },
  or: {
    margin: theme.spacing(1),
  },
  hr: {
    flex: 1,
    height: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dashed: {
    borderBottom: `2px dashed ${theme.palette.primary.main}`,
  },
}));

type SeparatorProps = {
  variant?: 'or' | 'dashed';
  disableSpacing?: boolean;
};

const Separator: FC<SeparatorProps> = ({
  variant = 'simple',
  disableSpacing = false,
}) => {
  const { row, or, hr, dashed } = useStyles();

  return (
    <>
      <div className={clsx(!disableSpacing && row)}>
        <div className={clsx(hr, variant === 'dashed' && dashed)} />
        {variant === 'or' && (
          <>
            <span className={or}>or</span>
            <div className={hr} />
          </>
        )}
      </div>
    </>
  );
};

export default Separator;
