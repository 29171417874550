import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { FC } from 'react';

import useStyles from './styles';

type FullScreenCircularProgressProps = {
  open: boolean;
};

const FullScreenCircularProgress: FC<FullScreenCircularProgressProps> = ({
  open,
}) => {
  const { backdrop } = useStyles();

  return (
    <Backdrop className={backdrop} open={open}>
      <CircularProgress />
    </Backdrop>
  );
};

export default FullScreenCircularProgress;
