import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import SettingsMenuPage from './MenuPage';

const SettingsModule: FC<RouteComponentProps> = ({ match }) => {
  return (
    <>
      <Switch>
        <Route path={match.path} component={SettingsMenuPage} />
      </Switch>
    </>
  );
};

export default SettingsModule;
