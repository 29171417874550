import { FullScreenCircularProgress, OptiTextField } from '@components';
import { ErrorModal } from '@dvkiin/material-commons';
import { mapFormikToTextField } from '@lib';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareIcon from '@material-ui/icons/Share';
import * as React from 'react';
import { FC, useContext } from 'react';

import useStyles from '../../styles';
import ExportModal from '../ExportModal';
import QuoteSentModal from '../QuoteSentModal';
import SendStepContext from './context';

type SendStepPageDesktopProps = {};

const SendStepPageDesktop: FC<SendStepPageDesktopProps> = () => {
  const classes = useStyles();
  const {
    quote,
    quoteSentModal,
    exportModal,
    renderingErrorModal,
    loadingQuoteFiles,
    renderedOfferPdfBlob,
    quotePdfFiles,

    sendFormik,
    quoteManagementFormik,
    handleSendOfferViaShare,
    handleDownloadQuote,
    downloadingQuoteForPrint,
    sendingOfferToClient,

    setStep,
  } = useContext(SendStepContext);

  function renderSendQuote() {
    return (
      <Paper className={classes.sectionPaper}>
        <Box padding={3}>
          <Box display="flex" alignItems="center">
            <Box className={classes.sectionHeading}>Send Quote</Box>
          </Box>

          <Box display="flex" flexDirection="row">
            <Box width="40%" marginTop={3.5}>
              <OptiTextField
                label="From"
                disabled
                value={
                  quote.createdByUser.emailPreferences?.smtpUser ??
                  'quotes@optioffer.com'
                }
                aria-readonly="true"
              />
            </Box>

            <Box width="40%" marginTop={3.5} marginLeft={3}>
              <OptiTextField
                label="To"
                disabled
                value={quote.client?.email ?? '(not available)'}
                aria-readonly="true"
              />
            </Box>
          </Box>

          <Box display="flex" width="40%" marginTop={2}>
            <OptiTextField
              label="Subject"
              required
              {...mapFormikToTextField(sendFormik, 'subject')}
            />
          </Box>

          <Box display="flex" marginTop={2}>
            <OptiTextField
              label="Custom Email Message"
              multiline
              rows={14}
              required
              {...mapFormikToTextField(sendFormik, 'message')}
            />
          </Box>

          <Box display="flex" marginTop={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  {...sendFormik.getFieldProps({
                    name: `sendToMyself`,
                    type: 'checkbox',
                  })}
                />
              }
              label={`Send quote to myself as well`}
            />
          </Box>
          <Box
            display="flex"
            marginTop={2}
            alignItems="right"
            justifyContent="flex-end"
          >
            <Box marginRight={2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.sendQuoteButton}
                disabled={!((navigator as any).canShare && quotePdfFiles)}
                onClick={() => handleSendOfferViaShare()}
              >
                <Box display="flex" alignItems="center">
                  <Box display="flex" marginRight={2}>
                    <ShareIcon />
                  </Box>
                  Share
                </Box>
              </Button>
            </Box>

            {!(navigator as any).canShare && (
              <Box marginRight={2}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.sendQuoteButton}
                  disabled={!renderedOfferPdfBlob}
                  onClick={handleDownloadQuote}
                >
                  <Box display="flex" alignItems="center">
                    <Box display="flex" marginRight={2}>
                      <GetAppIcon />
                    </Box>
                    Download as PDF
                  </Box>
                </Button>
              </Box>
            )}

            <Box display="flex" flexDirection="row-reverse">
              <Button
                className={classes.sendQuoteButton}
                variant="contained"
                color="primary"
                disabled={
                  !quote.client?.email?.length ||
                  !renderedOfferPdfBlob ||
                  sendFormik.isSubmitting
                }
                onClick={() => sendFormik.submitForm()}
              >
                Send Quote
                <ArrowForward style={{ marginLeft: 8 }} />
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  }

  function renderQuoteManagement() {
    return (
      <Paper className={classes.sectionPaper}>
        <Box padding={3}>
          <Box display="flex" alignItems="center">
            <Box className={classes.sectionHeading}>Quote Management</Box>
          </Box>

          <Box width="100%" marginTop={3.5}>
            <OptiTextField
              label="Quote title"
              {...mapFormikToTextField(quoteManagementFormik, 'title')}
              onBlur={() => quoteManagementFormik.submitForm()}
            />
          </Box>

          <Box width="100%" marginTop={3.5}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => exportModal.open({ offer: quote })}
            >
              Export Quote
            </Button>
          </Box>
        </Box>
      </Paper>
    );
  }

  return (
    <div className="SendStep">
      <Box display="flex" flexDirection="row" marginTop={2}>
        <Box display="flex" flex={4} marginRight={1} flexDirection={'column'}>
          {renderSendQuote()}
        </Box>
        <Box flex={2} marginLeft={1} height={333}>
          {renderQuoteManagement()}
        </Box>
      </Box>

      <Box marginBottom={10} />

      <Box className={classes.floatingFooter}>
        <Box display="flex">
          <Box paddingRight={2}>
            <Button
              className="NavigationButton"
              variant={'outlined'}
              fullWidth
              onClick={() => setStep(3)}
            >
              Back
            </Button>
          </Box>
          <Box flex="1" />
        </Box>
      </Box>

      <ExportModal control={exportModal} downloadQuote={handleDownloadQuote} />
      <QuoteSentModal control={quoteSentModal} />
      <ErrorModal
        error={renderingErrorModal.data}
        message={renderingErrorModal.data}
      />
      <FullScreenCircularProgress
        open={
          downloadingQuoteForPrint ||
          loadingQuoteFiles ||
          (sendFormik.isSubmitting && !sendingOfferToClient)
        }
      />
    </div>
  );
};

export default SendStepPageDesktop;
