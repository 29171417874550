import clsx from 'clsx';
import React, { FC } from 'react';

import { formatDiscount, formatPrice } from '@optioffer/core';

import { TaxIdType } from '../../lib/TaxId/domain';
import { Plan } from '../../pages/Login/domain';
import useStyles from './styles';

const currency = {
  name: 'Euro',
  symbol: '€',
  id: 'EUR',
};

type SubscriptionPriceBreakdownProps = {
  className?: string;
  vatRate: number | undefined;
  country: string | undefined;
  taxIdType: Omit<TaxIdType, 'description' | 'country'> | undefined;
  plan: Plan | undefined;
  totalLabel?: string;
  quantity?: number;
};

const SubscriptionPriceBreakdown: FC<SubscriptionPriceBreakdownProps> = ({
  className,
  plan,
  vatRate,
  country,
  taxIdType,
  totalLabel = 'You will be charged',
  quantity = 1,
}) => {
  const { row, bold } = useStyles();
  if (!plan) return null;

  const isReverseCharge =
    taxIdType && taxIdType.type === 'eu_vat' && taxIdType.countryCode !== 'EE';

  const price = (plan.price / 100) * quantity;
  const vat = vatRate && !isReverseCharge ? (price * vatRate) / 100 : undefined;
  const total = price + (vat || 0);

  return (
    <div className={className}>
      <div className={row}>
        <p>
          OptiOffer {plan.name} Account
          {quantity > 1 ? ` (${quantity} users)` : ''}
        </p>
        <p>{formatPrice(price, currency)}</p>
      </div>
      {vat && (
        <div className={row}>
          <p>
            VAT {country} ({vatRate && formatDiscount(vatRate)})
          </p>
          <p>{formatPrice(vat, currency)}</p>
        </div>
      )}
      {isReverseCharge && (
        <div className={row}>
          <p>Reverse charge VAT</p>
          <p>{formatPrice(0, currency)}</p>
        </div>
      )}
      <hr />
      <div className={clsx(row, bold)}>
        <p>{totalLabel}</p>
        <p>{formatPrice(total, currency)}</p>
      </div>
    </div>
  );
};

export default SubscriptionPriceBreakdown;
