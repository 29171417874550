import { makeStyles, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import React, { FC } from 'react';

import { CompanyUser } from '@optioffer/graphql';

import useStyles from './styles';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    minWidth: 150,
    fontSize: 14,
    backgroundColor: theme.palette.common.white,
    flex: 1,
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 16px)',
    },
    '& .MuiAutocomplete-clearIndicatorDirty': {
      visibility: 'visible',
    },
  },
}));

type CreatorSelectorProps = {
  options: CompanyUser[];
  onCreatorSelected: (creator: CompanyUser | null) => void;
  value: CompanyUser | null;
  defaultValue?: CompanyUser;
};

const CreatorSelector: FC<CreatorSelectorProps> = ({
  options,
  onCreatorSelected,
  value,
  defaultValue,
}) => {
  const { flex, center } = useStyles();
  const localClasses = useLocalStyles();

  return (
    <Autocomplete
      autoHighlight
      className={localClasses.root}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option.name}
      id="country-select"
      size={'small'}
      options={options}
      onChange={(e, newValue) => {
        onCreatorSelected(newValue);
      }}
      value={value}
      closeIcon={<CloseIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={'Creator'}
          variant={'outlined'}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      renderOption={(props) => (
        <>
          <div className={flex}>
            <div className={clsx(flex, center)}>{props.name}</div>
          </div>
        </>
      )}
    />
  );
};

export default CreatorSelector;
