import {
  NOOP_graphqlErrorManagement,
  SecurityContext,
  useEnhancedMutation,
  useEnhancedQuery,
  useIsMobile,
  useModal,
} from '@lib';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LaunchIcon from '@material-ui/icons/Launch';
import clsx from 'clsx';
import React, {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ooBrand } from '@optioffer/core';
import {
  Auth_CurrentUserDocument,
  Profile_GetProfileDocument,
  Profile_UpdateProfileDocument,
  ProfileOnProfilePageFragment,
  ProfileStatus,
  ProfileType,
} from '@optioffer/graphql';

import { getQueryName } from '@lib/graphql';

import createDemoQuoteIllustration from '@resources/illustrations/demo_quote.svg';

import ContactInfoModal from './ContactInfoModal';
import ExperienceModal from './ExperienceModal';
import PersonalInfoModal from './PersonalInfoModal';
import ProjectModal from './ProjectsModal';
import ServicesModal from './ServicesModal';
import {
  isContactInfoComplete,
  isExperienceComplete,
  isPersonalInfoComplete,
  isProfileComplete,
  isProjectsComplete,
  isServicesComplete,
  mapToProfileInput,
} from './helpers';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: 0,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    padding: theme.spacing(3, 2),
  },
  demoCard: {
    backgroundColor: `${ooBrand.colors.primary.default}19`, // 10%
    gap: theme.spacing(2),
    position: 'relative',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      paddingLeft: theme.spacing(25),
      marginTop: theme.spacing(20),
    },
  },
  demoCardIllustration: {
    display: 'none',
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  normalCard: {
    boxShadow: ooBrand.boxShadow.primary,
  },
  tasksCard: {
    [theme.breakpoints.up('lg')]: {},
  },
  header1: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.primary.main,

    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  header1V2: {
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.palette.primary.dark,
    padding: theme.spacing(0, 5),
  },
  header2: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600, // semi-bold
  },
  helpButton: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    '& svg': {
      marginRight: theme.spacing(1.5),
    },
  },
  tasksProgressBar: {
    width: 120,
    alignSelf: 'center',
    marginBottom: theme.spacing(1.2),
    borderRadius: 4,
  },
  task: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    padding: theme.spacing(2, 0),

    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0.5, 0),
      padding: theme.spacing(2, 1.5),
      backgroundColor: ooBrand.colors.gray['100'],
      borderRadius: 5,
      border: `1px solid transparent`,
      cursor: 'pointer',

      '&:hover': {
        borderColor: `${ooBrand.colors.primary['100']}64`, // 40%
      },
    },
  },
  activeTask: {
    borderColor: `${ooBrand.colors.primary['100']}64`, // 40%
  },
  backgroundTaskProgressRing: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: ooBrand.colors.black,
    opacity: '7%',
  },
  taskCategoryCompletion: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600, // semi-bold
  },
  taskCategoryTitle: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 600, // semi-bold
  },
  taskCategorySubTitle: {
    fontSize: theme.typography.pxToRem(12),
    color: ooBrand.colors.gray['500'],
    maxWidth: 400,
  },
  taskText: {
    flex: 1,
  },
  taskTextComplete: {
    textDecoration: 'line-through',
    color: `${ooBrand.colors.black}64`, // 40%
  },
  taskCompleteIcon: {
    color: theme.palette.primary.main,
  },
  taskBubble: {
    borderRadius: '50%',
    width: theme.spacing(1),
    height: theme.spacing(1),
    margin: theme.spacing(0, 1),
  },
  taskIcon: {
    color: theme.palette.primary.main,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

type ProfileCompletionTask = {
  text: string;
  description: ReactNode;
  buttonText: string;
  complete: boolean;
  onClick: () => void;
  secondaryButton?: {
    text: string;
    onClick: () => void;
  };
};

const ProfileSetupCard: FC = () => {
  const localClasses = useLocalStyles();
  const isMobile = useIsMobile();
  const { currentProfile: currentProfileFromContext } = useContext(
    SecurityContext
  );

  const personalInfoModal = useModal<ProfileOnProfilePageFragment>();
  const contactInfoModal = useModal<ProfileOnProfilePageFragment>();
  const servicesModal = useModal<ProfileOnProfilePageFragment>();
  const experienceModal = useModal<ProfileOnProfilePageFragment>();
  const projectsModal = useModal<ProfileOnProfilePageFragment>();

  const getProfileQuery = useEnhancedQuery(Profile_GetProfileDocument, {
    variables: { id: currentProfileFromContext?.id as string },
    skip: !currentProfileFromContext?.id,
  });
  const [updateProfile] = useEnhancedMutation(Profile_UpdateProfileDocument, {
    refetchQueries: [getQueryName(Auth_CurrentUserDocument)],
    success: {
      message: 'Profile saved.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while saving your profile.',
    },
  });
  const currentProfile = getProfileQuery.data?.profile.get;

  const handleContinueWithoutExperience = useCallback(() => {
    if (!currentProfile) return;
    updateProfile({
      variables: {
        id: currentProfile.id,
        profile: {
          ...mapToProfileInput(currentProfile),
          continueWithoutExperience: true,
        },
      },
    }).catch(NOOP_graphqlErrorManagement);
  }, [currentProfile, updateProfile]);
  const handleContinueWithoutProjects = useCallback(() => {
    if (!currentProfile) return;
    updateProfile({
      variables: {
        id: currentProfile.id,
        profile: {
          ...mapToProfileInput(currentProfile),
          continueWithoutProjects: true,
        },
      },
    }).catch(NOOP_graphqlErrorManagement);
  }, [currentProfile, updateProfile]);

  const tasks: ProfileCompletionTask[] = useMemo(
    () =>
      !currentProfile
        ? []
        : [
            {
              text:
                currentProfile.profileType === ProfileType.SUPPLIER
                  ? 'Date Companie'
                  : 'Informatii Personale',
              description: (
                <>
                  <p>Adauga o poza de profil si adresa. Simplu si rapid.</p>
                  <Box display="flex" alignItems="center" gridGap={4}>
                    <p>Durata completarii: 30s</p>
                    <AccessTimeIcon />
                  </Box>
                </>
              ),
              buttonText: 'Fill in Personal Info',
              complete: isPersonalInfoComplete(currentProfile),
              onClick: () => personalInfoModal.open(currentProfile),
            },
            {
              text: 'Detalii de contact',
              description: (
                <>
                  <p>
                    Spune-le clientilor sau angajatorilor un si cum sa te
                    contacteze cand vor sa colaboreze cu tine.
                  </p>
                  <Box display="flex" alignItems="center" gridGap={4}>
                    <p>Durata completarii: 2:30</p>
                    <AccessTimeIcon />
                  </Box>
                </>
              ),
              buttonText: 'Fill in Contact Details',
              complete: isContactInfoComplete(currentProfile),
              onClick: () => contactInfoModal.open(currentProfile),
            },
            {
              text:
                currentProfile.profileType === ProfileType.EMPLOYEE
                  ? 'Abilitati'
                  : 'Servicii',
              description: (
                <>
                  <p>
                    {currentProfile.profileType === ProfileType.EMPLOYEE
                      ? 'Alege pentru ce joburi vrei sa apari in cautararile angajatorilor, '
                      : 'Alege pentru ce servicii vrei sa apari in cautararile clientilor, '}
                    in ce localitati esti disponibil si spune-le cateva lucruri
                    despre tine intr-un bio.
                  </p>
                  <Box display="flex" alignItems="center" gridGap={4}>
                    <p>Durata completarii: 5m</p>
                    <AccessTimeIcon />
                  </Box>
                </>
              ),
              buttonText:
                currentProfile.profileType === ProfileType.EMPLOYEE
                  ? 'Fill in Skills'
                  : 'Fill in Services',
              complete: isServicesComplete(currentProfile),
              onClick: () => servicesModal.open(currentProfile),
            },
            ...(currentProfile.profileType === ProfileType.EMPLOYEE ||
            currentProfile.profileType === ProfileType.PROFESSIONAL_AND_EMPLOYEE
              ? [
                  {
                    text: 'Experienta',
                    description: (
                      <>
                        <p>
                          Evidentiaza pe scurt experienta pe care ai acumulat-o,
                          sau specifica faptul ca esti nou in domeniu.
                        </p>
                        <Box display="flex" alignItems="center" gridGap={4}>
                          <p>Durata completarii: 3m</p>
                          <AccessTimeIcon />
                        </Box>
                      </>
                    ),
                    buttonText: 'Add your Experience',
                    complete: isExperienceComplete(currentProfile),
                    onClick: () => experienceModal.open(currentProfile),
                    ...(!isProjectsComplete(currentProfile)
                      ? {
                          secondaryButton: {
                            text: "I don't have any experience",
                            onClick: handleContinueWithoutExperience,
                          },
                        }
                      : {}),
                  },
                ]
              : []),

            ...(currentProfile.profileType !== ProfileType.EMPLOYEE
              ? [
                  {
                    text: 'Portofoliu',
                    description: (
                      <>
                        <p>
                          Pentru a atrage cat mai multi clienti, este recomandat
                          sa adaugi proiecte cu poze si descriere.
                        </p>
                        <Box display="flex" alignItems="center" gridGap={4}>
                          <p>
                            Durata completarii: Variabil in functie de numarul
                            de proiecte - intre 5 si 30 min
                          </p>
                          <AccessTimeIcon />
                        </Box>
                      </>
                    ),
                    buttonText: 'Add finished Projects',
                    complete: isProjectsComplete(currentProfile),
                    onClick: () => projectsModal.open(currentProfile),
                    ...(!isProjectsComplete(currentProfile)
                      ? {
                          secondaryButton: {
                            text: 'Continue without adding projects',
                            onClick: handleContinueWithoutProjects,
                          },
                        }
                      : {}),
                  },
                ]
              : []),
          ],
    [
      personalInfoModal,
      contactInfoModal,
      servicesModal,
      experienceModal,
      projectsModal,

      handleContinueWithoutProjects,
      handleContinueWithoutExperience,
      currentProfile,
    ]
  );
  const [currentTask, setCurrentTask] = useState<ProfileCompletionTask>();

  const noOfCompletedTasks = tasks.filter((it) => it.complete).length;
  const allTasksCompleted = tasks.length === noOfCompletedTasks;

  useEffect(() => {
    if (
      currentProfile &&
      isProfileComplete(currentProfile) &&
      currentProfile.profileStatus === ProfileStatus.DRAFT
    ) {
      updateProfile({
        variables: {
          id: currentProfile.id,
          profile: {
            ...mapToProfileInput(currentProfile),
            profileStatus: ProfileStatus.PUBLIC,
          },
        },
      }).catch(NOOP_graphqlErrorManagement);
    }
  }, [updateProfile, currentProfile]);

  function renderTask(
    task: ProfileCompletionTask,
    idx: number,
    self: ProfileCompletionTask[]
  ) {
    const isCurrentTask = currentTask?.text === task.text;

    return (
      <Fragment key={task.text}>
        <Box
          className={clsx(
            localClasses.task,
            isCurrentTask && localClasses.activeTask
          )}
          onClick={() => setCurrentTask(task)}
        >
          {task.complete ? (
            <CheckCircleOutlinedIcon
              className={localClasses.taskCompleteIcon}
            />
          ) : (
            <Box
              className={localClasses.taskBubble}
              style={{
                backgroundColor: `${ooBrand.colors.primary.default}19`,
              }}
            />
          )}
          <Box
            className={clsx(
              localClasses.taskText,
              task.complete && localClasses.taskTextComplete
            )}
          >
            {task.text}
          </Box>
          {isMobile && (
            <Box className={localClasses.taskIcon}>
              {isCurrentTask ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </Box>
          )}
        </Box>
        {isMobile && isCurrentTask && (
          <Box paddingLeft={4} paddingRight={4} marginBottom={4}>
            <Box className={localClasses.taskCategorySubTitle}>
              {task.description}
            </Box>
            <Box marginTop={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={task.onClick}
              >
                {task.buttonText}
              </Button>
            </Box>
            {task.secondaryButton && (
              <Box marginTop={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={task.secondaryButton.onClick}
                >
                  {task.secondaryButton.text}
                </Button>
              </Box>
            )}
          </Box>
        )}
        {isMobile && idx < self.length - 1 && <Divider />}
      </Fragment>
    );
  }

  return (
    <>
      <Box className={clsx(localClasses.card, localClasses.demoCard)}>
        <img
          className={localClasses.demoCardIllustration}
          src={createDemoQuoteIllustration}
          alt="person working on laptop"
        />
        <Box>
          <Box
            className={clsx(
              isMobile ? localClasses.header2 : localClasses.header1
            )}
            textAlign="left"
          >
            {currentProfile?.profileStatus === ProfileStatus.PUBLIC
              ? 'Profilul este public pe Opti. A inceput sa lucreze pentru tine!'
              : currentProfile?.profileStatus === ProfileStatus.DISABLED
              ? 'Your profile was disabled, please contact support.'
              : 'Completeaza-ti profilul pentru a-l face live, si lasa Opti-ul sa lucreze pentru tine.'}
          </Box>
          <Box>Schimbarile pot dura pana la 24 de ore sa fie live.</Box>
        </Box>
        <Box>
          <Button
            className={localClasses.noWrap}
            variant="contained"
            color="primary"
            fullWidth
            disabled={currentProfile?.profileStatus !== ProfileStatus.PUBLIC}
            href={`${process.env.REACT_APP_LANDING_PAGE}/ro/`}
            target="_blank"
            endIcon={<LaunchIcon />}
          >
            See public Profile
          </Button>
        </Box>
      </Box>

      <Box
        className={clsx(
          localClasses.card,
          localClasses.normalCard,
          localClasses.tasksCard
        )}
      >
        <Box display="flex" alignItems="center">
          {!isMobile && (
            <Box marginRight={1}>
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant="determinate"
                  className={localClasses.backgroundTaskProgressRing}
                  size={60}
                  value={100}
                />
                <CircularProgress
                  size={60}
                  variant="determinate"
                  value={(noOfCompletedTasks / tasks.length) * 100}
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box className={localClasses.taskCategoryCompletion}>
                    {noOfCompletedTasks}/{tasks.length}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            className={isMobile ? localClasses.header1 : localClasses.header1V2}
            width="100%"
          >
            {allTasksCompleted
              ? 'Your Profile is complete!'
              : 'Complete your Profile'}
          </Box>
        </Box>

        {isMobile ? (
          <>
            <Box
              alignSelf={'center'}
              marginTop={1}
              marginBottom={1}
              color={ooBrand.colors.gray['400']}
            >
              {noOfCompletedTasks} of {tasks.length} Steps Completed
            </Box>
            <LinearProgress
              className={localClasses.tasksProgressBar}
              variant="determinate"
              value={(noOfCompletedTasks / tasks.length) * 100}
            />
            <Box>{tasks.map(renderTask)}</Box>
          </>
        ) : (
          <Box display="flex" marginTop={3}>
            <Box width={320}>{tasks.map(renderTask)}</Box>

            <Box flex={1} paddingLeft={5}>
              {currentTask ? (
                <>
                  <Box className={localClasses.taskCategoryTitle}>
                    {currentTask.text}
                  </Box>
                  <Box className={localClasses.taskCategorySubTitle}>
                    {currentTask.description}
                  </Box>
                  <Box marginTop={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={currentTask.onClick}
                    >
                      {currentTask.buttonText}
                    </Button>
                  </Box>
                  {currentTask.secondaryButton && (
                    <Box marginTop={4}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={currentTask.secondaryButton.onClick}
                      >
                        {currentTask.secondaryButton.text}
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box className={localClasses.taskCategoryTitle}>
                    Fa-ti profilul vizibil pe Opti si bucura-te de rezultate
                  </Box>
                  <Box className={localClasses.taskCategorySubTitle}>
                    Pentru a avea profilul LIVE, completeaza toate cele{' '}
                    {tasks.length} campuri. Nu trebuie sa fie perfecte din
                    prima, le poti imbunatatii oricand. Exact ca pe social
                    media.
                  </Box>
                  <Box display="flex" alignItems="center" gridGap={4}>
                    <p>Durata aproximativa a completarii: 12 min</p>
                    <AccessTimeIcon />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>

      <PersonalInfoModal control={personalInfoModal} />
      <ContactInfoModal control={contactInfoModal} />
      <ServicesModal control={servicesModal} />
      <ExperienceModal control={experienceModal} />
      <ProjectModal control={projectsModal} />
    </>
  );
};

export default ProfileSetupCard;
