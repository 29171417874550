import { useHasRole } from '@lib';
import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { UserRole } from '@optioffer/graphql';

import PlanListPage from './ListPage';
import PlanViewEditPage from './ViewEditPage';

const PlanModule: FC<RouteComponentProps> = ({ match }) => {
  const isSuperAdmin = useHasRole(UserRole.SUPER_ADMIN);
  if (!isSuperAdmin) return <Redirect to={'/'} />;

  return (
    <>
      <Route path={`${match.path}/:id`} component={PlanViewEditPage} />
      <Route exact path={match.path} component={PlanListPage} />
    </>
  );
};

export default PlanModule;
