export * from './pagination';
export * from './error';
export * from './hooks';
export * from './date';
export * from './routing';
export * from './noop';
export * from './security';
export * from './client';
export * from './offer';
export * from './formik';
export * from './materialCommons';
export * from './images';

export const waitForStatePropagation = () =>
  new Promise((resolve) => requestAnimationFrame(resolve));

export const waitForAnimationFrame = waitForStatePropagation;

// https://www.w3schools.com/js/js_cookies.asp
export function getCookie(cookieName: string): string | null {
  let name = cookieName + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function capitalizeFirstLetter(str: string): string {
  return str[0].toUpperCase() + str.slice(1);
}

export function isUUID(str: string): boolean {
  return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(
    str
  );
}
