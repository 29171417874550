import { makeStyles } from '@material-ui/core';

import { addColoredBar } from '@components/Card/styles';

import { OOTheme } from '../../theme';

export default makeStyles((theme: OOTheme) => ({
  tableCard: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    ...addColoredBar(theme, theme.oo.colors.product),
  },
  viewEditCard: {
    maxWidth: 700,
    ...addColoredBar(theme, theme.oo.colors.product),
  },
  bundleViewEditCard: {
    maxWidth: 700,
    ...addColoredBar(theme, theme.oo.colors.productBundle),
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  versionsCard: {
    marginTop: theme.spacing(1),
  },
  versionsTable: {},
  selectProductVersion: {
    maxWidth: 600,
    margin: 'auto',
    display: 'flex',
    '& > div': {
      flex: 1,
      marginRight: theme.spacing(1),
    },
  },
  versionsCardContent: {
    overflowX: 'auto',
  },
  pdfViewer: {
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  importCard: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    padding: theme.spacing(1),
  },
  bulkOperationsHeader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  filtersCard: {
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  cardHeader: {
    marginTop: theme.spacing(-1),
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  sortSelect: {
    minWidth: 200,
  },
  filtersWrapper: {
    width: '100%',
    flex: 1,
  },
  toolbarRight: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  filterGroupWrapper: {
    width: '100%',
  },
  filterGroup: {
    paddingLeft: theme.spacing(2),
    maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
  },
  filterLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 1.5,
    flex: 1,
  },
  filterSecondaryLabel: {
    color: theme.palette.text.disabled,
    textAlign: 'right',
  },
  filterChipsContainer: {
    margin: theme.spacing(-1, -0.5, 1, -0.5),
  },
  filterChip: {
    margin: theme.spacing(0.5),
  },
  openOptiPanelButton: {
    marginLeft: theme.spacing(2),
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(0, -2),
  },
  offerItemProductCard: {
    maxHeight: 96,
    width: '100%',
  },
}));
