import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import OfferListPage from './list';

const OfferModule: FC<RouteComponentProps> = ({ match }) => {
  return (
    <div className="offerModule">
      <Switch>
        <Route exact path={match.path} component={OfferListPage} />
      </Switch>
    </div>
  );
};

export default OfferModule;
