var _a;
export var CalculationType;
(function (CalculationType) {
    CalculationType["PERCENTAGE"] = "PERCENTAGE";
    CalculationType["FIXED"] = "FIXED";
})(CalculationType || (CalculationType = {}));
export var TaxCalculationOrderType;
(function (TaxCalculationOrderType) {
    TaxCalculationOrderType["BEFORE_DISCOUNT"] = "BEFORE_DISCOUNT";
    TaxCalculationOrderType["AFTER_DISCOUNT"] = "AFTER_DISCOUNT";
    TaxCalculationOrderType["FINAL"] = "FINAL";
})(TaxCalculationOrderType || (TaxCalculationOrderType = {}));
export var TaxType;
(function (TaxType) {
    TaxType["EUROPEAN_VAT"] = "EUROPEAN_VAT";
    TaxType["OTHER"] = "OTHER";
})(TaxType || (TaxType = {}));
export var TaxTypeLabels = (_a = {},
    _a[TaxType.EUROPEAN_VAT] = 'V.A.T.',
    _a[TaxType.OTHER] = 'Other',
    _a);
