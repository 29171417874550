import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import { OfferPrintContext, useDevColor } from '../helpers';
import Footer from './snippets/Footer';
import Header from './snippets/Header';
import { commonStyles } from './style';
var bodyStyles = StyleSheet.create({
    intro: {
        flex: 1,
        paddingRight: '5mm',
        marginLeft: '5mm',
        width: '195mm',
    },
});
var IntroPage = function () {
    var _a = useContext(OfferPrintContext).offer, companyIntro = _a.companyIntro, ownedByCompany = _a.ownedByCompany;
    var devColor = useDevColor();
    function renderBody() {
        return (React.createElement(View, { style: [bodyStyles.intro, devColor('#80deea')] },
            React.createElement(Text, { style: [commonStyles.p] }, companyIntro !== null && companyIntro !== void 0 ? companyIntro : ownedByCompany.companyIntro)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Page, { wrap: true, size: "A4", style: [commonStyles.page, devColor('#ccc')] },
            React.createElement(Header, null),
            renderBody(),
            React.createElement(Footer, null))));
};
export default IntroPage;
