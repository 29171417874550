import {
  DVKObject,
  FlexExpander,
  InputModal,
  SlideUp,
  useModal,
} from '@dvkiin/material-commons';
import { NOOP_graphqlErrorManagement } from '@lib';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import React, { FC } from 'react';

import { HeavyDutyResults } from '@components/Feedback';
import { HeavyDutyResult } from '@components/Feedback/domain';

const GUIDE_LINK = `${process.env.REACT_APP_LANDING_PAGE}/features/imp-ex-up`;

type ImportProductImagesProps = {
  validateImport: (file: File[]) => Promise<HeavyDutyResult[]>;
  executeImport: (files: File[]) => Promise<HeavyDutyResult[]>;
};

const ImportProductImages: FC<ImportProductImagesProps> = ({
  validateImport,
  executeImport,
}) => {
  const {
    isOpen: isImportProductImagesOpen,
    open: openImportProductImages,
    close: closeImportProductImages,
  } = useModal();
  const {
    isOpen: isImportProductImagesConfirmModalOpen,
    open: openImportProductImagesConfirmModal,
    close: closeImportProductImagesConfirmModal,
    data: importProductImagesConfirmData,
  } = useModal<{ files: File[]; simulationResult: HeavyDutyResult[] }>();
  const {
    isOpen: isFeedBackImagesModalOpen,
    open: openFeedBackImagesModal,
    close: closeFeedBackImagesModal,
    data: feedBackImagesData,
  } = useModal<HeavyDutyResult[]>();

  const importImagesValidationHasError = !!importProductImagesConfirmData?.simulationResult.find(
    (result) => result.status === 'ERROR'
  );
  const importImagesValidationNoImagesFound = !importProductImagesConfirmData?.simulationResult.find(
    (result) => result.status === 'OK'
  );
  const importImagesHasError = !!feedBackImagesData?.find(
    (result) => result.status === 'ERROR'
  );

  async function handleAnalyzeProductImages({
    importProductImages,
  }: DVKObject) {
    const files = (importProductImages as any) as File[];
    try {
      const simulationResult = await validateImport(files);
      openImportProductImagesConfirmModal({ files: files, simulationResult });
    } catch (e) {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleImportImages() {
    //TODO: Check for different file with same extension
    const filesForImport = importProductImagesConfirmData!.files.filter(
      (file) =>
        importProductImagesConfirmData!.simulationResult.find(
          (sim) =>
            sim.status === 'OK' &&
            sim.id === file.name.split('.').slice(0, -1).join('.')
        )
    );
    try {
      const importImagesResult = await executeImport(filesForImport);
      openFeedBackImagesModal(importImagesResult);
    } catch (e) {
      NOOP_graphqlErrorManagement();
    }
  }

  function renderImportProductImagesButton() {
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddAPhotoIcon />}
        onClick={openImportProductImages}
      >
        Import product images
      </Button>
    );
  }
  function renderImportProductImagesAnalysisModal() {
    return (
      <InputModal
        title="Import product images"
        open={isImportProductImagesOpen}
        fields={[
          {
            name: 'importProductImages',
            label: 'JPG files for import',
            type: 'file',
            required: true,
            acceptedFileType: '.jpg',
            errorMessage: {
              type: 'This file type is not supported.',
            },
            multiple: true,
          },
        ]}
        saveLabel="Analyze product images"
        onClose={closeImportProductImages}
        onCreate={handleAnalyzeProductImages}
      >
        <Typography>
          For more information about the import process, please{' '}
          <Link href={GUIDE_LINK} target="_blank" rel="noopener noreferrer">
            check this guide
          </Link>
          .
        </Typography>
        <Typography variant="caption">
          After you select your images and click 'Analyze product images', we
          will show you what would happen if you choose to continue with the
          import. Before you confirm the import in the next step, nothing will
          be imported.
        </Typography>
      </InputModal>
    );
  }
  function renderImportProductImagesConfirmationModal() {
    return (
      <>
        {importProductImagesConfirmData && (
          <Dialog
            aria-labelledby="confirmation-dialog-title"
            TransitionComponent={SlideUp}
            open={isImportProductImagesConfirmModalOpen}
            onClose={closeImportProductImagesConfirmModal}
            fullWidth
            maxWidth="sm"
            aria-describedby="confirmation-dialog-description"
          >
            <DialogTitle id="confirmation-dialog-title">
              {importImagesValidationHasError
                ? 'One of the files cannot be imported'
                : importImagesValidationNoImagesFound
                ? 'No products with missing images have been matched with the provied files.'
                : 'Continue product image import?'}
            </DialogTitle>
            <DialogContent id="confirmation-dialog-description">
              <Typography>
                For more information about the import process and requirement,
                please{' '}
                <Link
                  href={`${process.env.REACT_APP_LANDING_PAGE}/features/imp-ex-up`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  check this guide
                </Link>
                .
              </Typography>
              <HeavyDutyResults
                results={importProductImagesConfirmData!.simulationResult}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeImportProductImagesConfirmModal}>
                Cancel
              </Button>
              <FlexExpander />
              {importImagesValidationNoImagesFound ? (
                <></>
              ) : importImagesValidationHasError ? (
                <Button onClick={closeImportProductImagesConfirmModal}>
                  Try again
                </Button>
              ) : (
                <Button onClick={handleImportImages}>Yes</Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  function renderImportProductImagesResultModal() {
    return (
      <>
        {feedBackImagesData && (
          <Dialog
            aria-labelledby="feedback-dialog-title"
            TransitionComponent={SlideUp}
            open={isFeedBackImagesModalOpen}
            onClose={closeFeedBackImagesModal}
            fullWidth
            maxWidth="sm"
            aria-describedby="feedback-dialog-description"
          >
            <DialogTitle id="feedback-dialog-title">
              {importImagesHasError ? 'Import failed' : 'Import successful!'}
            </DialogTitle>
            <DialogContent id="feedback-dialog-description">
              <HeavyDutyResults results={feedBackImagesData} />
            </DialogContent>
            <DialogActions>
              {!importImagesHasError && (
                <Button
                  onClick={() => {
                    closeFeedBackImagesModal();
                    closeImportProductImagesConfirmModal();
                    closeImportProductImages();
                  }}
                >
                  Cancel
                </Button>
              )}
              <FlexExpander />
              {importImagesHasError ? (
                <Button onClick={closeFeedBackImagesModal}>
                  Try another file
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    closeFeedBackImagesModal();
                    closeImportProductImagesConfirmModal();
                    closeImportProductImages();
                  }}
                >
                  Ok
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
  return (
    <>
      {renderImportProductImagesButton()}
      {renderImportProductImagesAnalysisModal()}
      {renderImportProductImagesConfirmationModal()}
      {renderImportProductImagesResultModal()}
    </>
  );
};

export default ImportProductImages;
