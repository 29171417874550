import { makeStyles } from '@material-ui/core';

import { ooBrand } from '@optioffer/core';

import { OOTheme } from '../../theme';

export default makeStyles((theme: OOTheme) => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  logo: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    height: 40,
    cursor: 'pointer',
  },
  listItemText: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  logoSmall: {
    height: 30,
    [theme.breakpoints.up(600)]: {
      height: 30,
    },
    cursor: 'pointer',
  },
  footer: {
    height: 50,
    padding: theme.spacing(0, 3),
  },
  socialIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  socialIcon: {
    width: theme.spacing(3),
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      background: '#ffffff',
      color: '#818181',
    },
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      background: ooBrand.colors.gray['50'],
      color: ooBrand.colors.black,
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 600, // semi-bold
      textAlign: 'center',
    },
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: 50,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    position: 'relative',
    flex: 1,

    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
    },
  },
  appBarButton: {
    marginRight: theme.spacing(2),
    borderRadius: 1,
    color: '#707070',
    textTransform: 'none',
  },
  clientGroup: {
    '&, & svg': {
      color: theme.oo.colors.client,
    },
  },
  adminGroup: {
    '&, & svg': {
      color: theme.oo.colors.admin,
    },
  },
  actionCenter: {
    '&, & svg': {
      color: theme.oo.colors.actionCenter,
    },
  },
  offersWrapper: {
    overflowY: 'auto',
  },
  companyName: {
    marginLeft: theme.spacing(3),
  },
  popover: {
    borderTop: '2px solid green',
  },
  logoutText: {
    color: '#f5855e',
    fontWeight: 600, // semi-bold
  },
  avatarIcon: {
    color: '#9a9a9a',
  },
  menuItemText: {
    fontWeight: 600, // semi-bold
    color: '#414141',
    fontSize: 15,
  },
  notification: {
    color: '#9a9a9a',
  },
  notificationSelected: {
    color: theme.palette.primary.main,
  },
  badge: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  countAvatar: {
    height: 24,
    width: 24,
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
  },
}));
