import { CheckBoxInput } from '@components';
import { SecurityContext } from '@lib';
import { IconButton, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import PanoramaIcon from '@material-ui/icons/Panorama';
import RemoveIcon from '@material-ui/icons/Remove';
import * as React from 'react';
import { FC, useContext } from 'react';

import { formatPrice } from '@optioffer/core';
import { OfferItem } from '@optioffer/graphql';

import { OfferContext } from '@containers/OfferContext';

import { ProductSearchResult } from './domain';

const useLocalStyles = makeStyles((theme) => ({
  productName: {
    fontSize: theme.typography.pxToRem(14),
    opacity: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productCode: {
    fontSize: theme.typography.pxToRem(12),
    opacity: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productPrice: {
    fontSize: theme.typography.pxToRem(16),
  },
  placeholderIcon: {
    '& > svg': {
      fontSize: '3rem',
      opacity: '30%',
    },
  },
  checkboxFormControl: {
    width: 'initial',
    '& > label': {
      marginRight: 0,
    },
  },
}));

type ProductListItemProps = {
  item: ProductSearchResult;
  offerItem?: OfferItem;
  selected?: boolean;
  handleIncreaseQuantity: (item: ProductSearchResult) => Promise<any>;
  handleUpdateQuantity?: (offerItem: OfferItem) => Promise<any>;
  handleRemoveProduct?: (item: ProductSearchResult) => Promise<any>;
  onClick: (item: ProductSearchResult) => Promise<any>;
  actionIsCheckbox: boolean;
  hideAction: boolean;
};

const ProductListItem: FC<ProductListItemProps> = ({
  children,
  item,
  offerItem,
  selected,
  handleIncreaseQuantity,
  handleUpdateQuantity,
  handleRemoveProduct,
  onClick,
  actionIsCheckbox,
  hideAction,
}) => {
  const localClasses = useLocalStyles();
  const offer = useContext(OfferContext);
  const { company } = useContext(SecurityContext);

  return (
    <Box width="100%">
      <Box
        display="flex"
        alignItems={'center'}
        paddingY={1}
        paddingX={2}
        marginX={-2}
      >
        <Box
          minWidth={54}
          maxWidth={54}
          marginRight={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => onClick(item)}
        >
          {item.product?.image?.thumbnail ? (
            <img
              style={{ width: '100%' }}
              src={item.product?.image?.thumbnail}
              alt="product"
            />
          ) : (
            <Box className={localClasses.placeholderIcon}>
              <PanoramaIcon />
            </Box>
          )}
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          flex={1}
          minWidth={0}
          onClick={() => onClick(item)}
        >
          <Box className={localClasses.productName}>
            {offerItem?.product?.name ?? item.product?.name}
          </Box>
          <Box className={localClasses.productCode}>
            {offerItem?.product?.code ?? item.version?.code}
          </Box>
          <Box className={localClasses.productPrice}>
            {formatPrice(
              offerItem?.product?.price ?? item.version?.price,
              offer?.currency ?? company.currency
            )}
          </Box>
        </Box>
        {!hideAction &&
          (actionIsCheckbox ? (
            <CheckBoxInput
              formControlClassName={localClasses.checkboxFormControl}
              checked={selected}
              onChange={(event, checked) =>
                checked
                  ? handleIncreaseQuantity(item)
                  : handleRemoveProduct?.(item)
              }
            />
          ) : (
            <>
              {offerItem && (
                <>
                  <IconButton
                    onClick={() =>
                      handleUpdateQuantity?.({
                        ...offerItem,
                        quantity: offerItem.quantity - 1,
                      })
                    }
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Box>{offerItem.quantity}</Box>
                </>
              )}
              <Box marginRight={-2}>
                <IconButton
                  color={'primary'}
                  onClick={() => handleIncreaseQuantity(item)}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </>
          ))}
      </Box>

      {children}
    </Box>
  );
};

export default ProductListItem;
