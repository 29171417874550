import { OptiTextField } from '@components';
import {
  mapFormikToAutocomplete,
  mapFormikToButtonSwitchField,
  mapFormikToTextField,
  SecurityContext,
  useEnhancedMutation,
  useIsMobile,
} from '@lib';
import { Box, Container, Link, makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { FC, ReactNode, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { ooBrand } from '@optioffer/core';
import {
  Auth_CurrentUserDocument,
  Profile_NewProfileDocument,
  ProfileType,
} from '@optioffer/graphql';

import BubbleStepIndicator from '@components/BubbleStepIndicator';
import LoadingAwareButton from '@components/LoadingAwareButton';
import ButtonSwitchInput from '@components/form/ButtonSwitchInput';

import { getQueryName } from '@lib/graphql';
import { PROFILE_CATEGORIES } from '@lib/profile';

import { ReactComponent as ProfileTypeSupplierIllustration } from '@resources/illustrations/onboarding/company_type_reseller.svg';
import { ReactComponent as ProfileTypeEmployeeIllustration } from '@resources/illustrations/profile/profile_type_employee.svg';
import { ReactComponent as ProfileTypeEmployeeAndProfessionalIllustration } from '@resources/illustrations/profile/profile_type_employee_and_professional.svg';
import { ReactComponent as ProfileTypeProfessionalIllustration } from '@resources/illustrations/profile/profile_type_professional.svg';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(10, 0),
    },
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 600, // semi-bold
    textAlign: 'center',
  },
  subHeader: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
  },
  toc: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(12),
    color: `${ooBrand.colors.black}78`, // 60%
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 4),

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0),
    },
  },
  nextButtonWrapper: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        maxWidth: 200,
      },
    },
  },
  extraLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600, // semi-bold
  },
  onBoardingButtonsWrapper: {
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  onBoardingButton: {
    width: 160,
    minHeight: 150,
    display: 'flex',
    flexDirection: 'column',
  },
  onBoardingButtonIcon: {
    height: 80,
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '&:not(.active)': {
      color: ooBrand.colors.gray['400'],
    },
  },
  onBoardingButtonSubHeader: {
    flex: 1,
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    '&:not(.active)': {
      color: ooBrand.colors.gray['400'],
    },
  },
}));

type SignupProps = {};

const Signup: FC<SignupProps> = () => {
  const localClasses = useLocalStyles();
  const isMobile = useIsMobile();
  const { currentUser, setCurrentProfile } = useContext(SecurityContext);

  const [step, setStep] = useState(1);

  const step1Formik = useFormik({
    initialValues: {
      profileType: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        setStep(2);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const step2Formik = useFormik({
    initialValues: {
      companyName:
        currentUser.companyName && currentUser.companyName !== currentUser.name
          ? currentUser.companyName
          : '',
      contactPersonName: currentUser.name ?? '',
      category: '',
      email: currentUser.email ?? '',
      phoneNumber: currentUser.phoneNumber ?? '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const { data } = await createProfile({
          variables: {
            profile: {
              profileType: step1Formik.values.profileType as any,

              contactPersonName: values.contactPersonName,
              companyName: values.companyName,
              category: values.category,
              email: values.email,
              phone: values.phoneNumber,
            },
          },
        });

        if (data?.profile.new.id) {
          setCurrentProfile(data.profile.new);
        }

        setStep(3);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const [createProfile] = useEnhancedMutation(Profile_NewProfileDocument, {
    refetchQueries: [getQueryName(Auth_CurrentUserDocument)],
    success: {
      message: 'Profile created successfully.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while creating the profile.',
    },
    formik: step2Formik,
  });

  function onBoardingButton(
    current: string,
    value: string,
    icon: ReactNode,
    header: string,
    subHeader?: string
  ) {
    return {
      value,
      label: (
        <Box className={localClasses.onBoardingButton}>
          <Box
            className={clsx(
              localClasses.onBoardingButtonIcon,
              current === value && 'active'
            )}
          >
            {icon}
          </Box>
          <Box>{header}</Box>
          <Box
            className={clsx(
              localClasses.onBoardingButtonSubHeader,
              current === value && 'active'
            )}
          >
            {subHeader}
          </Box>
        </Box>
      ),
    };
  }

  if (step === 1) {
    const acceptToc = (
      <Box className={localClasses.toc}>
        By clicking "Continue" you accept our{' '}
        <Link
          href={`${process.env.REACT_APP_LANDING_PAGE}/terms-and-conditions`}
          target="_blank"
        >
          terms and conditions
        </Link>
        .
      </Box>
    );

    return (
      <Container
        className={localClasses.root}
        component="form"
        onSubmit={step1Formik.handleSubmit}
        maxWidth="md"
      >
        <Box
          marginTop={5}
          marginBottom={1.5}
          display="flex"
          justifyContent="center"
        >
          <BubbleStepIndicator step={1} of={4} />
        </Box>
        <Box className={localClasses.header} marginBottom={1}>
          Choose a profile
        </Box>
        <Box
          className={localClasses.subHeader}
          marginBottom={6}
          maxWidth={310}
          alignSelf="center"
        >
          What would you like to get by using OptiOffer?
        </Box>

        <Box marginBottom={2}>
          <ButtonSwitchInput
            buttonWrapperClassName={localClasses.onBoardingButtonsWrapper}
            createButtonProps={() => ({
              variant: 'outlined',
            })}
            options={[
              onBoardingButton(
                step1Formik.values.profileType,
                ProfileType.SUPPLIER,
                <ProfileTypeSupplierIllustration />,
                'Supplier',
                'In cazul in care vinzi produse HoReCa in marketplace, acesta este profilul cu care manageriezi procesul.'
              ),
              onBoardingButton(
                step1Formik.values.profileType,
                ProfileType.PROFESSIONAL,
                <ProfileTypeProfessionalIllustration />,
                'Professional',
                'Daca esti interesat de mai multi clienti clienti pentru serviciile pe care le prestezi, acesta este profilul potrivit pentru tine.'
              ),
              onBoardingButton(
                step1Formik.values.profileType,
                ProfileType.EMPLOYEE,
                <ProfileTypeEmployeeIllustration />,
                'Employment',
                'Daca vrei oferte de munca constant fara nici un efor depus din partea ta. Fie ca ai un job, fie ca esti in cautare, o oferta mai buna este intotodeuna bine primita.'
              ),
              onBoardingButton(
                step1Formik.values.profileType,
                ProfileType.PROFESSIONAL_AND_EMPLOYEE,
                <ProfileTypeEmployeeAndProfessionalIllustration />,
                'Professional & Employment',
                'Daca esti interesat de mai multi clienti clienti pentru serviciile pe care le prestezi, dar vrei si oferte de munca permanente, acesta-i profilul potrivit pentru tine'
              ),
            ]}
            {...mapFormikToButtonSwitchField(step1Formik, 'profileType')}
          />
        </Box>

        <Box flex={1} />
        {isMobile && acceptToc}
        <Box paddingBottom={2} className={localClasses.nextButtonWrapper}>
          <LoadingAwareButton
            type="submit"
            color="primary"
            variant="contained"
            loading={step1Formik.isSubmitting}
            disabled={!step1Formik.dirty}
            fullWidth
          >
            <Box display="flex" gridGap={8}>
              Continue
              <ArrowForwardIcon />
            </Box>
          </LoadingAwareButton>
        </Box>
        {!isMobile && acceptToc}
      </Container>
    );
  }

  if (step === 2) {
    return (
      <Container
        className={localClasses.root}
        component="form"
        onSubmit={step2Formik.handleSubmit}
        maxWidth="md"
      >
        <Container maxWidth="xs">
          <Box
            marginTop={5}
            marginBottom={1.5}
            display="flex"
            justifyContent="center"
          >
            <BubbleStepIndicator step={2} of={4} />
          </Box>
          <Box className={localClasses.header} marginBottom={4}>
            About you
          </Box>
          {step1Formik.values.profileType !== ProfileType.SUPPLIER && (
            <Box marginBottom={2}>
              <OptiTextField
                required
                label="Nume Persoanei de contact"
                {...mapFormikToTextField(step2Formik, 'contactPersonName')}
              />
            </Box>
          )}
          {step1Formik.values.profileType !== ProfileType.EMPLOYEE && (
            <Box marginBottom={2}>
              <OptiTextField
                required={
                  step1Formik.values.profileType === ProfileType.SUPPLIER
                }
                label={
                  step1Formik.values.profileType === ProfileType.SUPPLIER
                    ? 'Numele Companiei'
                    : 'Numele Companiei (daca se aplica)'
                }
                placeholder="e.g. You Company SRL"
                {...mapFormikToTextField(step2Formik, 'companyName')}
              />
            </Box>
          )}
          <Box marginBottom={2}>
            <Autocomplete
              options={
                (PROFILE_CATEGORIES[step1Formik.values.profileType] ??
                  []) as string[]
              }
              freeSolo
              renderInput={(params) => (
                <OptiTextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  required
                  label="Categoria"
                  autoComplete="off"
                />
              )}
              {...mapFormikToAutocomplete(step2Formik, 'category')}
              size="small"
            />
          </Box>
          <Box marginBottom={2}>
            <OptiTextField
              required
              label="Email"
              {...mapFormikToTextField(step2Formik, 'email')}
            />
          </Box>
          <Box marginBottom={2}>
            <OptiTextField
              required
              label="Numar de telefon"
              placeholder="07xx xxx xxx"
              {...mapFormikToTextField(step2Formik, 'phoneNumber')}
            />
          </Box>
          {step1Formik.values.profileType === ProfileType.SUPPLIER && (
            <Box marginBottom={2}>
              <OptiTextField
                label="Nume Persoanei de contact"
                {...mapFormikToTextField(step2Formik, 'contactPersonName')}
              />
            </Box>
          )}
        </Container>
        <Box flex={1} />
        <Box paddingBottom={2} className={localClasses.nextButtonWrapper}>
          <LoadingAwareButton
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            loading={step2Formik.isSubmitting}
          >
            <Box display="flex" gridGap={8}>
              Continue
              <ArrowForwardIcon />
            </Box>
          </LoadingAwareButton>
        </Box>
      </Container>
    );
  }

  return <Redirect to={'/'} />;
};

export default Signup;
