import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';
import { forwardRef } from 'react';

import { ooBrand } from '@optioffer/core';

export const OptiTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: ooBrand.colors.black,
    boxShadow: ooBrand.boxShadow.primary,
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1.5),
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(
  forwardRef<unknown, TooltipProps>(({ children, ...props }, ref) => (
    <Tooltip ref={ref} arrow {...props}>
      {children}
    </Tooltip>
  ))
);
