import { useIsMobile } from '@lib';
import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';

import useStyles from '../styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

export type PricingLevelModalData = {
  displayDiscounts: boolean;
};

type PricingLevelModalProps = {
  control: ModalControl<PricingLevelModalData>;
  updatePricingLevel: (displayDiscounts: boolean) => Promise<any>;
};

const PricingLevelModal: FC<PricingLevelModalProps> = ({
  control,
  updatePricingLevel,
}) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      displayDiscounts: true,
    },
    onSubmit: async (values) => {
      if (!control.data) return;

      await updatePricingLevel(values.displayDiscounts);

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.data) {
      formikRef.current.resetForm({
        values: {
          displayDiscounts: control.data.displayDiscounts ?? true,
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = 'Pricing Level';
  return (
    <OOModal
      open={control.isOpen}
      onClose={control.close}
      title={title}
      minWidth="sm"
    >
      <Box marginX={isMobile ? 0 : 3} marginBottom={isMobile ? 0 : 3}>
        <form onSubmit={formik.handleSubmit}>
          {!isMobile && (
            <Box className={classes.desktopModalTitle}>{title}</Box>
          )}
          <Box height={'100%'} marginBottom={10}>
            <Box paddingX={1.5}>
              <Box marginY={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...formik.getFieldProps({
                        name: 'displayDiscounts',
                        type: 'checkbox',
                      })}
                    />
                  }
                  label="Display discounts on the quote"
                />
              </Box>
              <Box marginY={2}>
                {formik.values.displayDiscounts
                  ? 'Discounts will be visible on the quote.'
                  : 'Discounts will not be visible on the quote. Prices will be calculated to include discounts.'}
              </Box>
            </Box>
          </Box>

          <Box marginBottom={10} />

          <Box display="flex" className={classes.floatingFooter}>
            <Box flex="1">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={!formik.dirty}
              >
                {'Save Changes'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </OOModal>
  );
};

export default PricingLevelModal;
