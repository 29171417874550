import { Box, ButtonBase, Divider, makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Pagination } from '@material-ui/lab';
import * as React from 'react';
import { FC, Fragment } from 'react';

import { formatDate, ooBrand } from '@optioffer/core';

import QuoteStatusChip from '@components/Chip/QuoteStatus';

import { Offer } from '../domain';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ooBrand.colors.pureWhite,
    borderRadius: 5,
    boxShadow: ooBrand.boxShadow.grayBottom,
  },
  quoteTile: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 1.5, 1.5, 2),
    width: '100%',
    textAlign: 'left',
    fontFamily: 'inherit',
    fontSize: 'inherit',
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600, // semi-bold
  },
  label: {
    opacity: 0.4,
    color: ooBrand.colors.gray['800'],
  },
  value: {
    color: ooBrand.colors.gray['600'],
  },
  arrow: {
    color: ooBrand.colors.primary.default,
  },
}));

type OffersTableMobileProps = {
  data: Offer[];
  onEdit: (offer: Offer) => unknown;
  pageCount: number;
  onPaginationUpdate: (page: number) => unknown;
  page: number;
};

const OffersTableMobile: FC<OffersTableMobileProps> = ({
  data,
  onEdit,
  page,
  pageCount,
  onPaginationUpdate,
}) => {
  const localClasses = useLocalStyles();
  return (
    <>
      <Box className={localClasses.root}>
        {data.map((offer, idx, self) => (
          <Fragment key={offer.id}>
            <ButtonBase
              className={localClasses.quoteTile}
              onClick={() => onEdit(offer)}
            >
              <Box flex={1} display="flex" flexDirection="column">
                <Box
                  marginBottom={1}
                  display="flex"
                  alignItems="center"
                  gridGap={8}
                >
                  <QuoteStatusChip status={offer.status} />
                  <Box className={localClasses.title}>{offer.title}</Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} className={localClasses.label}>
                    Client Name
                  </Box>
                  <Box flex={1} className={localClasses.value}>
                    {offer.client?.name}
                    {offer.client?.companyName &&
                      `, ${offer.client?.companyName}`}
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} className={localClasses.label}>
                    Creator
                  </Box>
                  <Box flex={1} className={localClasses.value}>
                    {offer.createdBy}
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} className={localClasses.label}>
                    Last Modified
                  </Box>
                  <Box flex={1} className={localClasses.value}>
                    {formatDate(offer.lastModifiedDate)}
                  </Box>
                </Box>
              </Box>
              <Box className={localClasses.arrow}>
                <ChevronRightIcon />
              </Box>
            </ButtonBase>
            {idx < self.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Box>
      {pageCount > 1 && (
        <Box marginTop={3} marginBottom={1}>
          <Pagination
            showFirstButton
            showLastButton
            size="small"
            count={pageCount}
            onChange={(e, value) => onPaginationUpdate(value - 1)}
            page={page}
          />
        </Box>
      )}
    </>
  );
};

export default OffersTableMobile;
