import { Box, Button } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

import TextField from '@components/form/TextField';

import { Company } from '../domain';
import useStyles from '../styles';

type GDriveSettingsProps = {
  companyDetails: Company;
  onGdriveUpdate: (
    gDriveTemplateFile: string | undefined,
    gDriveTargetFolder: string | undefined,
    gDriveEveryProduct: boolean | undefined
  ) => void;
};

const GDriveSettings: FC<GDriveSettingsProps> = ({
  companyDetails,
  onGdriveUpdate,
}) => {
  const { button, buttonBox } = useStyles();

  const [gDriveTemplateFile, setGDriveTemplateFile] = useState<string>();
  const [gDriveTargetFolder, setGDriveTargetFolder] = useState<string>();
  const [gDriveEveryProduct, setGDriveEveryProduct] = useState<boolean>();

  useEffect(() => {
    if (companyDetails) {
      setGDriveEveryProduct(companyDetails.gDriveEveryProduct);
      setGDriveTargetFolder(companyDetails.gDriveTargetFolder);
      setGDriveTemplateFile(companyDetails.gDriveTemplateFile);
    }
  }, [companyDetails]);

  return (
    <>
      <h4>Google Drive Defaults</h4>
      <TextField
        label="Google drive template file"
        value={gDriveTemplateFile}
        onChange={(e) => setGDriveTemplateFile(e.target.value)}
      />
      <TextField
        label="Google drive target folder"
        value={gDriveTargetFolder}
        onChange={(e) => setGDriveTargetFolder(e.target.value)}
      />
      <Box className={buttonBox} style={{ marginBottom: 16 }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          fullWidth
          onClick={async () =>
            await onGdriveUpdate(
              gDriveTemplateFile,
              gDriveTargetFolder,
              gDriveEveryProduct
            )
          }
        >
          Save Data
        </Button>
        <Button
          variant="contained"
          size="small"
          fullWidth
          className={button}
          onClick={async () => await onGdriveUpdate('', '', false)}
        >
          Delete Data
        </Button>
      </Box>
    </>
  );
};

export default GDriveSettings;
