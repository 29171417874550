var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
export * from './hooks';
export * from './date';
export * from './flowControl';
export function d(value, tag) {
    if (tag === void 0) { tag = '[OO]'; }
    var __unsafeValue = value;
    if (typeof __unsafeValue === 'function') {
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var res = __unsafeValue.call.apply(__unsafeValue, __spreadArray([this], args));
            if (typeof res.then === 'function')
                return res.then(function (val) {
                    console.log(null, tag, args, val);
                    return val;
                });
            console.log(tag, args, res);
            return res;
        };
    }
    else {
        if (__unsafeValue && typeof __unsafeValue.then === 'function') {
            return __unsafeValue.then(function (val) {
                console.log(null, tag, val);
                return val;
            });
        }
        console.log(tag, __unsafeValue);
        return __unsafeValue;
    }
}
export var downloadBlob = function (blob, fileName) {
    var linkToBlob = window.URL.createObjectURL(blob);
    downloadLink(linkToBlob, fileName);
};
export var downloadLink = function (link, fileName) {
    var a = document.createElement('a');
    a.style.display = 'none';
    a.href = link;
    a.download = fileName;
    document.body.append(a);
    a.click();
    document.body.removeChild(a);
};
// https://stackoverflow.com/a/57272491/6615035
export var toBase64 = function (file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () { return resolve(reader.result); };
        reader.onerror = function (error) { return reject(error); };
    });
};
export function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
export function sum(a, b) {
    return (a || 0) + (b || 0);
}
