import {
  OptiAccordion,
  OptiAccordionDetails,
  OptiAccordionSummary,
  OptiDatePicker,
  OptiTextField,
} from '@components';
import { NOOP_graphqlErrorManagement } from '@lib';
import {
  Box,
  Button,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SettingsIcon from '@material-ui/icons/Settings';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import clsx from 'clsx';
import moment from 'moment';
import * as React from 'react';
import { FC, Fragment, useContext } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import {
  formatDate,
  formatDiscount,
  formatPrice,
  ooBrand,
  sum,
} from '@optioffer/core';
import {
  CalculationType,
  CumulatedTaxCalculated,
  OfferItem,
  OfferItemInOfferModuleFragment,
} from '@optioffer/graphql';

import PieChartActiveShape from '../../../../components/Charts/PieChartActiveShape';
import { getProductSearchResult } from '../../domain';
import useStyles from '../../styles';
import OfferItemListItem from '../OfferItemListItem';
import ReviewModalContext from './context';

const useLocalStyles = makeStyles((theme) => ({
  offerItemWithAccessories: {
    position: 'relative',

    '&::after': {
      position: 'absolute',
      display: 'block',
      content: '" "',
      left: 0,
      top: 0,
      width: theme.spacing(0.75),
      backgroundColor: '#DBCCBA',
    },
  },
}));

type ReviewModalMobileProps = {};

const ReviewModalMobile: FC<ReviewModalMobileProps> = () => {
  const classes = useStyles();
  const localClasses = useLocalStyles();

  const {
    quote,

    companyIntroModal,
    offerItemModal,
    offerItemAccessoryModal,
    quoteTemplateModal,
    quoteLanguageModal,
    quoteCurrencyModal,
    pricingLevelModal,
    quoteTaxesModal,
    previewModal,
    quoteDetailsMenu,
    quoteSummaryMenu,

    formik,
    pricing,

    handleEditTermsAndConditions,
    handleMoveOfferItem,
    handleMoveAccessory,
    setCurrentAccessoryParent,
    setStep,

    handleEditAddon,
    handleEditDiscount,
  } = useContext(ReviewModalContext);

  function renderQuoteDetails() {
    return (
      <Box>
        <Box display="flex" alignItems="center" marginTop={1.5}>
          <Box flex={1} className={classes.sectionHeading}>
            Quote Details
          </Box>

          <Box marginRight={-1.5}>
            <IconButton
              aria-controls="quote-details-menu"
              aria-haspopup="true"
              onClick={quoteDetailsMenu.open}
            >
              <SettingsIcon />
            </IconButton>
          </Box>
          <Menu
            id="quote-details-menu"
            anchorEl={quoteDetailsMenu.anchorElement}
            keepMounted
            open={quoteDetailsMenu.isOpen}
            onClose={quoteDetailsMenu.close}
          >
            <MenuItem
              onClick={() => {
                quoteDetailsMenu.close();
                quoteTemplateModal.open({
                  quoteTemplate: formik.values.quoteTemplate,
                });
              }}
            >
              Change Template
            </MenuItem>
            <MenuItem
              onClick={() => {
                quoteDetailsMenu.close();
                quoteLanguageModal.open({
                  quoteLanguage: formik.values.quoteLanguage,
                });
              }}
            >
              Change Language
            </MenuItem>
          </Menu>
        </Box>

        <OptiTextField
          label="Creator"
          disabled
          value={quote.createdBy}
          aria-readonly="true"
        />
        <Box display="flex" marginTop={1.5}>
          <Box flex={1}>
            <OptiTextField
              label="Created at"
              disabled
              value={formatDate(quote.createdAt ?? undefined)}
              aria-readonly="true"
            />
          </Box>
          <Box width={20} />
          <Box flex={1}>
            <OptiDatePicker
              label="Expires at"
              value={moment(formik.values.expireAt)}
              onChange={async (expireAt) => {
                await formik.setFieldValue(
                  'expireAt',
                  expireAt?.valueOf() ?? Date.now()
                );
                await formik.submitForm();
              }}
            />
          </Box>
        </Box>
        <Box width="100%" marginTop={3.5}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={previewModal.open}
          >
            Preview Quote
          </Button>
        </Box>
      </Box>
    );
  }

  function renderCustomerDetails() {
    return (
      <Box marginX={-1.5} marginTop={1.5}>
        <OptiAccordion>
          <OptiAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-customer-details-content"
            id="panel-customer-details-header"
          >
            <Typography className={classes.sectionHeading}>
              Customer Details
            </Typography>
          </OptiAccordionSummary>
          <OptiAccordionDetails>
            <Box>
              <Box paddingX={2}>
                <Box className={classes.modalHeader}>Full Name</Box>
                <Box>{quote.client?.name}</Box>
              </Box>
              <Box paddingX={2} marginTop={2.5}>
                <Box className={classes.modalHeader}>Email</Box>
                <Box>{quote.client?.email}</Box>
              </Box>
              <Box paddingX={2} marginTop={2.5}>
                <Box className={classes.modalHeader}>Phone Number</Box>
                <Box>{quote.client?.phoneNumber}</Box>
              </Box>
              <Box paddingX={2} marginTop={2.5}>
                <Box className={classes.modalHeader}>Company Name</Box>
                <Box>{quote.client?.companyName}</Box>
              </Box>
            </Box>
          </OptiAccordionDetails>
        </OptiAccordion>
      </Box>
    );
  }

  function handleEditCompanyIntro() {
    companyIntroModal.open({
      companyIntro: formik.values.companyIntro,
    });
  }

  function renderIntroduction() {
    return (
      <Box marginX={-1.5} marginTop={1.5}>
        <OptiAccordion>
          <OptiAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-introduction-content"
            id="panel-introduction-header"
          >
            <Typography className={classes.sectionHeading}>
              Introduction
            </Typography>
          </OptiAccordionSummary>
          <OptiAccordionDetails>
            <Box
              className={classes.actionableRow}
              onClick={handleEditCompanyIntro}
            >
              <Box flex={1} whiteSpace="pre-line">
                {formik.values.companyIntro || <pre>(no company intro)</pre>}
              </Box>
              <ChevronRightIcon />
            </Box>
          </OptiAccordionDetails>
        </OptiAccordion>
      </Box>
    );
  }

  function renderTermsAndConditions() {
    return (
      <Box marginX={-1.5} marginTop={1.5}>
        <OptiAccordion>
          <OptiAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-introduction-content"
            id="panel-introduction-header"
          >
            <Typography className={classes.sectionHeading}>
              Terms and Conditions
            </Typography>
          </OptiAccordionSummary>
          <OptiAccordionDetails>
            <Box
              className={classes.actionableRow}
              onClick={handleEditTermsAndConditions}
            >
              <Box flex={1} whiteSpace="pre-line">
                {formik.values.termsAndConditions || (
                  <pre>(no terms and conditions)</pre>
                )}
              </Box>
              <ChevronRightIcon />
            </Box>
          </OptiAccordionDetails>
        </OptiAccordion>
      </Box>
    );
  }

  const productAddons = quote.offerItems
    .filter((oi): oi is OfferItem => !!oi)
    .flatMap((oi) => [
      ...oi.addons,
      ...(oi.children?.flatMap((acc) => acc.addons) ?? []),
    ]);

  const allAddonsPricing = [...productAddons, ...quote.addons]
    .map((addon) => addon.pricing)
    .filter(Boolean);

  function renderTax(tax: CumulatedTaxCalculated) {
    const taxValues =
      tax.valueTotal +
      allAddonsPricing
        .flatMap((addonPricing) => [
          ...addonPricing.taxesBeforeDiscount,
          ...addonPricing.taxesAfterDiscount,
          ...addonPricing.taxesFinal,
        ])
        .filter((addonTax) => addonTax.tax.id === tax.tax.id)
        .reduce((acc, tax) => acc + tax.valueTotal, 0);

    return (
      quote.pricing && (
        <Box
          key={tax.tax.id}
          className={clsx(classes.simpleRow, classes.simpleRowWithLabel)}
          marginBottom={1}
        >
          <Box flex={2}>
            {tax.tax.name}{' '}
            {tax.tax.calculationType === CalculationType.PERCENTAGE
              ? formatDiscount(tax.tax.value)
              : ''}
          </Box>

          <Box>{quote.pricing.offerCurrency.symbol}</Box>
          <Box flex={1} textAlign="right">
            {formatPrice(taxValues, quote.pricing.offerCurrency, true)}
          </Box>
        </Box>
      )
    );
  }

  function renderAddons() {
    return (
      <>
        {formik.values.addons.map((addon) => {
          return (
            <Fragment key={addon.id}>
              <Box
                className={clsx(
                  classes.actionableRow,
                  classes.simpleRowWithLabel
                )}
                marginBottom={1}
                onClick={() => handleEditAddon(addon)}
              >
                <Box flex={2}>{addon.name}</Box>

                <Box>{quote.pricing.offerCurrency.symbol}</Box>
                <Box flex={1} textAlign="right">
                  {formatPrice(addon.price, quote.pricing.offerCurrency, true)}
                </Box>
                <ChevronRightIcon />
              </Box>
              {!!addon.pricing?.discount?.valueTotal && (
                <Box
                  className={clsx(
                    classes.simpleRow,
                    classes.simpleRowWithLabel
                  )}
                  marginBottom={1}
                  onClick={() => handleEditAddon(addon)}
                >
                  <Box flex={2}>
                    Disc.{' '}
                    {addon.pricing.discount.discount.calculationType ===
                    CalculationType.PERCENTAGE
                      ? formatDiscount(addon.pricing.discount.discount.value)
                      : ''}{' '}
                    {addon.name}
                  </Box>

                  <Box>{quote.pricing.offerCurrency.symbol}</Box>
                  <Box flex={1} textAlign="right">
                    {formatPrice(
                      -1 * addon.pricing.discount.valueTotal,
                      quote.pricing.offerCurrency,
                      true
                    )}
                  </Box>
                </Box>
              )}
            </Fragment>
          );
        })}
        <Box paddingX={2}>
          <Button
            className={classes.flatInlineButton}
            fullWidth
            onClick={() => handleEditAddon(undefined)}
          >
            <StarBorderIcon /> Add {quote.addons.length ? 'another ' : ''}extra
            service to quote?
          </Button>
        </Box>
      </>
    );
  }

  function renderPricing() {
    if (!pricing) return;

    const productAddonsTotal = productAddons
      .filter((addon) => !!addon.pricing)
      .map((addon) => addon.pricing.listPrice)
      .reduce(sum, 0);

    return (
      <Box display="flex" flexDirection="column" marginX={-2}>
        <Box marginY={1.5}>
          <Divider />
        </Box>

        <Box
          className={clsx(classes.simpleRow, classes.simpleRowWithLabel)}
          marginBottom={1}
        >
          <Box flex={2}>
            List Price{productAddonsTotal ? ' (Without Extra Services)' : ''}
          </Box>

          <Box>{pricing.offerCurrency.symbol}</Box>
          <Box flex={1} textAlign="right">
            {formatPrice(pricing.listPrice, pricing.offerCurrency, true)}
          </Box>
        </Box>
        {!!productAddonsTotal && (
          <Box
            className={clsx(classes.simpleRow, classes.simpleRowWithLabel)}
            marginBottom={1}
          >
            <Box flex={2}>Product Addons Total</Box>

            <Box>{pricing.offerCurrency.symbol}</Box>
            <Box flex={1} textAlign="right">
              {formatPrice(productAddonsTotal, pricing.offerCurrency, true)}
            </Box>
          </Box>
        )}

        <Box marginY={1.5}>
          <Divider />
        </Box>

        {renderAddons()}

        <Box marginY={1.5}>
          <Divider />
        </Box>

        {pricing.taxesBeforeDiscount.map(renderTax)}

        {!!pricing.discount?.valueTotal && (
          <Box
            className={clsx(classes.simpleRow, classes.simpleRowWithLabel)}
            marginBottom={1}
          >
            <Box flex={2}>Cumulative Discount</Box>

            <Box>{pricing.offerCurrency.symbol}</Box>
            <Box flex={1} textAlign="right">
              {formatPrice(
                -1 * pricing.discount.valueTotal,
                pricing.offerCurrency,
                true
              )}
            </Box>
          </Box>
        )}

        {!!pricing.discountFinal?.valueTotal && (
          <Box
            className={clsx(classes.actionableRow, classes.simpleRowWithLabel)}
            marginBottom={1}
            onClick={handleEditDiscount}
          >
            <Box flex={2}>
              Additional Discount{' '}
              {pricing.discountFinal.discount?.calculationType ===
              CalculationType.PERCENTAGE
                ? formatDiscount(pricing.discountFinal.discount.value)
                : ''}
            </Box>

            <Box>{pricing.offerCurrency.symbol}</Box>
            <Box flex={1} textAlign="right">
              {formatPrice(
                -1 * pricing.discountFinal.valueTotal,
                pricing.offerCurrency,
                true
              )}
            </Box>
            <ChevronRightIcon />
          </Box>
        )}

        {pricing.taxesAfterDiscount.map(renderTax)}
        {pricing.taxesFinal.map(renderTax)}

        <Box
          className={clsx(classes.simpleRow, classes.simpleRowWithLabel)}
          marginBottom={1}
        >
          <Box flex={2}>GRAND TOTAL</Box>

          <Box>{pricing.offerCurrency.symbol}</Box>
          <Box flex={1} textAlign="right">
            {formatPrice(pricing.netPrice, pricing.offerCurrency, true)}
          </Box>
        </Box>

        {!pricing.discountFinal?.valueTotal && (
          <Box paddingX={2}>
            <Button
              className={classes.flatInlineButton}
              fullWidth
              onClick={handleEditDiscount}
            >
              <LocalOfferIcon /> Add Discount?
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  function renderQuoteSummary() {
    return (
      <Box>
        <Box display="flex" alignItems="center" marginTop={1.5}>
          <Box flex={1} className={classes.sectionHeading}>
            Quote Summary
          </Box>

          <Box marginRight={-1.5}>
            <IconButton
              aria-controls="quote-summary-menu"
              aria-haspopup="true"
              onClick={quoteSummaryMenu.open}
            >
              <SettingsIcon />
            </IconButton>
          </Box>
          <Menu
            id="quote-summary-menu"
            anchorEl={quoteSummaryMenu.anchorElement}
            keepMounted
            open={quoteSummaryMenu.isOpen}
            onClose={quoteSummaryMenu.close}
          >
            <MenuItem
              onClick={() => {
                quoteCurrencyModal.open({
                  quoteCurrency: formik.values.quoteCurrency,
                });
                quoteSummaryMenu.close();
              }}
            >
              Change Currency
            </MenuItem>
            <MenuItem
              onClick={() => {
                pricingLevelModal.open({
                  displayDiscounts: formik.values.displayDiscounts,
                });
                quoteSummaryMenu.close();
              }}
            >
              Set Pricing Level
            </MenuItem>
            <MenuItem
              onClick={() => {
                quoteTaxesModal.open({
                  taxes: formik.values.taxes,
                });
                quoteSummaryMenu.close();
              }}
            >
              Change TAX
            </MenuItem>
          </Menu>
        </Box>

        <Box>
          {formik.values.offerItems.map((item, index, self) => (
            <OfferItemListItem
              key={item?.id ?? `offerItem_${index}`}
              className={clsx(
                item.children?.length && localClasses.offerItemWithAccessories
              )}
              numberOfChildren={item.children?.length}
              item={item as OfferItemInOfferModuleFragment}
              index={index}
              moveOfferItem={handleMoveOfferItem}
              onMoveDone={() => {
                formik.submitForm().catch(NOOP_graphqlErrorManagement);
              }}
              onClick={async (it) => {
                offerItemModal.open(getProductSearchResult(it as OfferItem));
              }}
            >
              {(index < self.length - 1 || !!item.children?.length) && (
                <Divider />
              )}
              {item.children?.map(
                (accessory, accessoryIndex, accessorySelf) => (
                  <Box key={accessory?.id ?? `accessory_${index}`} paddingX={2}>
                    <OfferItemListItem
                      item={accessory as OfferItemInOfferModuleFragment}
                      index={accessoryIndex}
                      moveOfferItem={handleMoveAccessory.bind(null, item.id)}
                      onMoveDone={() => {
                        formik.submitForm().catch(NOOP_graphqlErrorManagement);
                      }}
                      onClick={async (it) => {
                        setCurrentAccessoryParent(item);
                        offerItemAccessoryModal.open(
                          getProductSearchResult(it as OfferItem)
                        );
                      }}
                      parentId={item.id}
                    >
                      {(accessoryIndex < accessorySelf.length - 1 ||
                        index < self.length - 1) && <Divider />}
                    </OfferItemListItem>
                  </Box>
                )
              )}
            </OfferItemListItem>
          ))}
        </Box>

        {renderPricing()}
      </Box>
    );
  }

  function renderProfitAnalysis() {
    const report = quote.report;
    const currency = report?.currency ?? quote.pricing?.offerCurrency;
    if (!report || !currency) return;

    const marginSectorLength =
      (360 * report.margin) / (report.cost + report.margin);
    return (
      <Box>
        <Box display="flex" alignItems="center" marginTop={4}>
          <Box flex={1} className={classes.sectionHeading}>
            Profit Analysis
          </Box>
        </Box>

        <Box width="100%" height={300}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={200} height={200}>
              <Pie
                data={[
                  {
                    name: 'Extra Service Cost',
                    value: report.cost,
                    currency: report.currency,
                  },
                  {
                    name: 'Profit',
                    value: report.margin,
                    currency: report.currency,
                  },
                ]}
                dataKey="value"
                cx="25%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                startAngle={marginSectorLength / 2}
                endAngle={360 + marginSectorLength / 2}
                activeIndex={1}
                activeShape={PieChartActiveShape}
              >
                <Cell fill={ooBrand.colors.secondary['100']} />{' '}
                <Cell
                  fill={
                    report.margin > 0
                      ? ooBrand.colors.primary.default
                      : ooBrand.colors.error
                  }
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>

        <Box marginX={-2}>
          <Box
            className={clsx(classes.simpleRow, classes.simpleRowWithLabel)}
            marginBottom={1}
          >
            <Box flex={2}>Total cost</Box>

            <Box>{currency.symbol}</Box>
            <Box flex={1} textAlign="right">
              {formatPrice(report.cost, currency, true)}
            </Box>
          </Box>

          <Box
            className={clsx(classes.simpleRow, classes.simpleRowWithLabel)}
            marginBottom={1}
          >
            <Box flex={2}>Profit</Box>

            <Box>{currency.symbol}</Box>
            <Box flex={1} textAlign="right">
              {formatPrice(report.margin, currency, true)}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  function renderContent() {
    return (
      <div className="ReviewStep">
        <Box display="flex" flexDirection={'column'}>
          {renderQuoteDetails()}
          {renderCustomerDetails()}
          {renderIntroduction()}
          {renderTermsAndConditions()}
          {renderQuoteSummary()}
          {renderProfitAnalysis()}

          <Box marginBottom={10} />

          <Box className={classes.floatingFooter}>
            <Box display="flex">
              <Box paddingX={2}>
                <Button
                  className="NavigationButton"
                  variant={'text'}
                  fullWidth
                  onClick={() => setStep(2)}
                >
                  Back
                </Button>
              </Box>
              <Box flex={1} display="flex" flexDirection="row-reverse">
                <Button
                  className="NavigationButton"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={false}
                  onClick={() => setStep(4)}
                >
                  Confirm Quote
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <>
      {renderContent()}

      <Box height={80} />
    </>
  );
};

export default ReviewModalMobile;
