import {
  NumberFormatInput,
  OptiAccordion,
  OptiAccordionDetails,
  OptiAccordionSummary,
  OptiTextField,
  RangeInput,
} from '@components';
import { Formik, mapFormikToRangeInput, mapFormikToTextField } from '@lib';
import { Box, InputAdornment, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { FC } from 'react';

import { Currency, HistogramEntry } from '@optioffer/graphql';

import { PriceFilter } from '@lib/product/search';

import useStyles from './styles';

type ProductPriceFilterProps = {
  currency: Currency;
  formik: Formik<
    {
      priceFilter: PriceFilter;
    } & any
  >;

  histogram: HistogramEntry[];
  min?: number;
  max?: number;
};

const ProductPriceFilter: FC<ProductPriceFilterProps> = ({
  currency,
  formik,
  histogram,
  min,
  max,
}) => {
  const classes = useStyles();

  return (
    <OptiAccordion defaultExpanded>
      <OptiAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-price-content"
        id="panel-price-header"
      >
        <Typography className={classes.sectionHeading}>List Price</Typography>
      </OptiAccordionSummary>
      <OptiAccordionDetails>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          paddingX={1.5}
          overflow="hidden"
        >
          <RangeInput
            {...mapFormikToRangeInput(formik, 'priceFilter')}
            histogram={histogram}
            min={min}
            max={max}
          />
          <Box display="flex" justifyContent="space-between" marginBottom={3}>
            <Box width={150}>
              <OptiTextField
                {...mapFormikToTextField(formik, 'priceFilter[0]')}
                label="Min"
                InputProps={{
                  inputComponent: NumberFormatInput as any,
                  inputProps: {
                    decimalScale: 0,
                    textAlign: 'right',
                    onFocus: (e) => e.target.select(),
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      {currency.symbol}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box width={150}>
              <OptiTextField
                {...mapFormikToTextField(formik, 'priceFilter[1]')}
                label="Max"
                InputProps={{
                  inputComponent: NumberFormatInput as any,
                  inputProps: {
                    decimalScale: 0,
                    textAlign: 'right',
                    onFocus: (e) => e.target.select(),
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      {currency.symbol}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
      </OptiAccordionDetails>
    </OptiAccordion>
  );
};
export default ProductPriceFilter;
