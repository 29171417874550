import { MenuMode, SecurityContext, useHasRole } from '@lib';
import {
  Box,
  IconButton,
  ListItemAvatar,
  makeStyles,
  SwipeableDrawer,
  withStyles,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import AccountIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { useLocation } from 'react-router';

import { UserRole } from '@optioffer/graphql';

import ProfileTypeChip from '@components/Chip/ProfileType';

import useStyles from '@containers/Layout/styles';

const ListItem = withStyles(() => ({
  gutters: {
    paddingLeft: 26,
  },
}))(MuiListItem);

const ListItemText = withStyles(() => ({
  root: {
    textAlign: 'left',
  },
}))(MuiListItemText);

const useLocalStyles = makeStyles((theme) => ({
  currentProfile: {
    backgroundColor: theme.palette.grey['100'],
  },
}));

type MobileMenuProps = { notificationCount: number | undefined };

const MobileMenu: FC<MobileMenuProps> = ({ notificationCount }) => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const context = useContext(SecurityContext);
  const location = useLocation();

  const isSuperAdmin = useHasRole(UserRole.SUPER_ADMIN);

  const shouldHideLayout = useMemo(
    () => location.pathname.indexOf('new-offer') > -1,
    [location]
  );

  function handleLogout() {
    localStorage.removeItem('x-token');
    localStorage.removeItem('x-refresh-token');
    navigate('/login');
  }

  function navigate(path: string) {
    context.navigate(path);
    context.setDrawerState(false);
  }

  function getPageTitle() {
    const path = location.pathname;
    if (path === '/') return 'OptiOffer';
    else if (~path.indexOf('/offer')) return 'Quotes';
    else if (~path.indexOf('/new-offer')) return 'Quotes';
    else if (~path.indexOf('/product')) return 'My Products';
    else if (~path.indexOf('/attribute-collection'))
      return 'Attribute Collection';
    else if (~path.indexOf('/client')) return 'Customers';
    else if (~path.indexOf('/supplier')) return 'Suppliers';
    else if (~path.indexOf('/plan')) return 'Plans';
    else if (~path.indexOf('/companies')) return 'Companies';
    else if (~path.indexOf('/profile')) return 'Profile';
    else if (~path.indexOf('/settings')) return 'Settings';
    else if (~path.indexOf('/support')) return 'Help & Feedback';
    else if (~path.indexOf('/virtual-assistant')) return 'Virtual Assistant';
    else return 'OptiOffer';
  }

  function renderQuotingMenu() {
    return (
      <>
        <ListItem
          button
          onClick={() => navigate('/')}
          selected={location.pathname === '/'}
        >
          <ListItemText primary="Home" className={classes.listItemText} />
        </ListItem>
        <ListItem
          button
          onClick={() => navigate('/offer?order=desc&orderBy=createdAt')}
          selected={
            !!(
              ~location.pathname.indexOf('/offer') ||
              ~location.pathname.indexOf('/new-offer')
            )
          }
        >
          <ListItemText primary="Quotes" className={classes.listItemText} />
        </ListItem>
        <ListItem
          button
          onClick={() => navigate('/product')}
          selected={!!~location.pathname.indexOf('/product')}
        >
          <ListItemText
            primary="My Products"
            className={classes.listItemText}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => navigate('/client')}
          selected={!!~location.pathname.indexOf('/client')}
        >
          <ListItemText primary="Customers" className={classes.listItemText} />
        </ListItem>
        <ListItem
          button
          onClick={() => navigate('/supplier')}
          selected={!!~location.pathname.indexOf('/supplier')}
        >
          <ListItemText primary="Suppliers" className={classes.listItemText} />
        </ListItem>
      </>
    );
  }

  function renderProfessionalMenu() {
    return (
      <>
        <ListItem
          button
          onClick={() => context.navigate('/')}
          selected={'/' === location.pathname}
        >
          <ListItemText
            primary="PRO Dashboard"
            className={classes.listItemText}
          />
        </ListItem>
      </>
    );
  }

  function renderEmployeeMenu() {
    return (
      <>
        <ListItem
          button
          onClick={() => context.navigate('/')}
          selected={'/' === location.pathname}
        >
          <ListItemText
            primary="Employee Dashboard"
            className={classes.listItemText}
          />
        </ListItem>
      </>
    );
  }

  function renderProEmployeeMenu() {
    return (
      <>
        <ListItem
          button
          onClick={() => context.navigate('/')}
          selected={'/' === location.pathname}
        >
          <ListItemText
            primary="PRO & Employee Dashboard"
            className={classes.listItemText}
          />
        </ListItem>
      </>
    );
  }

  return (
    <>
      {!shouldHideLayout && (
        <Toolbar className={classes.toolbar}>
          <IconButton
            aria-label="open menu"
            color="primary"
            onClick={() => context.setDrawerState(true)}
          >
            <MenuIcon />
          </IconButton>
          <Box flex={1} marginLeft={-6}>
            {getPageTitle()}
          </Box>
        </Toolbar>
      )}
      <SwipeableDrawer
        open={context.drawerOpen}
        onClose={() => context.setDrawerState(false)}
        onOpen={() => context.setDrawerState(true)}
      >
        <Box className={classes.toolbar} marginRight={4}>
          <IconButton
            aria-label="close menu"
            color="primary"
            onClick={() => context.setDrawerState(false)}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Box flex={1}>OptiOffer</Box>
        </Box>
        <Divider />

        {context.currentUser.profiles.map((profile) => (
          <ListItem
            key={profile.id}
            button
            onClick={() => {
              context.setCurrentProfile(profile);
            }}
            className={clsx(
              context.currentProfile?.id === profile.id &&
                localClasses.currentProfile
            )}
          >
            <ListItemAvatar>
              {profile.image?.thumbnail ? (
                <Avatar src={profile.image.thumbnail} />
              ) : (
                <AccountIcon className={classes.avatarIcon} />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={profile.name}
              secondary={<ProfileTypeChip status={profile.profileType} />}
            />
          </ListItem>
        ))}

        <ListItem
          button
          onClick={() => {
            context.navigate('/profile/new');
          }}
        >
          <ListItemText primary="+ Create a new Profile" />
        </ListItem>

        <Divider />

        {context.menuMode === MenuMode.QUOTING && renderQuotingMenu()}
        {context.menuMode === MenuMode.PRO && renderProfessionalMenu()}
        {context.menuMode === MenuMode.EMPLOYEE && renderEmployeeMenu()}
        {context.menuMode === MenuMode.PRO_EMPLOYEE && renderProEmployeeMenu()}

        {isSuperAdmin && (
          <>
            <ListItem
              button
              onClick={() => context.navigate('/plan')}
              className={clsx(classes.adminGroup)}
            >
              <ListItemText primary="Plans" />
            </ListItem>
            <ListItem
              button
              onClick={() => context.navigate('/companies')}
              className={clsx(classes.adminGroup)}
            >
              <ListItemText primary="Companies" />
            </ListItem>
          </>
        )}

        <Divider />
        {context.menuMode === MenuMode.QUOTING && (
          <>
            <ListItem
              button
              onClick={() => navigate('/virtual-assistant')}
              selected={!!~location.pathname.indexOf('/virtual-assistant')}
            >
              <ListItemText
                primary="Virtual Assistant"
                className={classes.listItemText}
              />
              <Avatar className={classes.countAvatar}>
                {notificationCount}
              </Avatar>
            </ListItem>

            <Divider />
          </>
        )}

        <ListItem
          button
          onClick={() => navigate('/settings/profile')}
          selected={!!~location.pathname.indexOf('/settings/profile')}
        >
          <ListItemText
            primary="Quoting Account"
            className={classes.listItemText}
          />
        </ListItem>

        <ListItem
          button
          onClick={() => navigate('/settings/team')}
          selected={!!~location.pathname.indexOf('/settings/team')}
        >
          <ListItemText primary="My Team" className={classes.listItemText} />
        </ListItem>

        <ListItem
          button
          onClick={() => navigate('/settings/general')}
          selected={!!~location.pathname.indexOf('/settings/general')}
        >
          <ListItemText
            primary="Quote Settings"
            className={classes.listItemText}
          />
        </ListItem>

        <Divider />

        <ListItem
          button
          onClick={() => navigate('/support')}
          selected={!!~location.pathname.indexOf('/support')}
        >
          <ListItemText
            primary="Help & Feedback"
            className={classes.listItemText}
          />
        </ListItem>

        <Divider />

        <ListItem button onClick={handleLogout}>
          <ListItemText
            primary="Log out"
            className={clsx(classes.listItemText, classes.logoutText)}
          />
        </ListItem>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
