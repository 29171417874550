import {
  DVKObject,
  FlexExpander,
  InputModal,
  SlideUp,
  useModal,
} from '@dvkiin/material-commons';
import { NOOP_graphqlErrorManagement } from '@lib';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ImportIcon from '@material-ui/icons/Delete';
import React, { FC } from 'react';

import { HeavyDutyResults } from '@components/Feedback';
import { HeavyDutyResult } from '@components/Feedback/domain';

const GUIDE_LINK = `${process.env.REACT_APP_LANDING_PAGE}/features/imp-ex-up`;

type DeleteProductsProps = {
  validateDelete: (file: File) => Promise<HeavyDutyResult[]>;
  executeDelete: (file: File) => Promise<HeavyDutyResult[]>;
};

const DeleteProducts: FC<DeleteProductsProps> = ({
  validateDelete,
  executeDelete,
}) => {
  const {
    isOpen: isBulkDeleteModalOpen,
    open: openBulkDeleteModal,
    close: closeBulkDeleteModal,
  } = useModal();
  const {
    isOpen: isBulkDeleteConfirmModalOpen,
    open: openBulkDeleteConfirmModal,
    close: closeBulkDeleteConfirmModal,
    data: bulkDeleteConfirmData,
  } = useModal<{ file: File; simulationResult: HeavyDutyResult[] }>();
  const {
    isOpen: isBulkDeleteProductFeedbackModalOpen,
    open: openBulkDeleteProductFeedbackModal,
    close: closeBulkDeleteProductFeedbackModal,
    data: bulkDeleteProductFeedbackData,
  } = useModal<HeavyDutyResult[]>();

  const bulkDeleteAnalysisHasError = !!bulkDeleteConfirmData?.simulationResult.find(
    (result) => result.status === 'ERROR'
  );
  const bulkDeleteHasError = !!bulkDeleteProductFeedbackData?.find(
    (result) => result.status === 'ERROR'
  );

  async function handleValidateBulkDeleteProducts({ importFile }: DVKObject) {
    const file = ((importFile as any) as File[])[0];
    try {
      console.log('handleValidateBulkDeleteProducts start');
      const simulationResult = await validateDelete(file);
      openBulkDeleteConfirmModal({ file: file as any, simulationResult });
    } catch (e) {
      console.error('handleValidateBulkDeleteProducts', e);
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleBulkDeleteProducts() {
    const file = bulkDeleteConfirmData!.file;
    try {
      const bulkDeleteResult = await executeDelete(file);
      openBulkDeleteProductFeedbackModal(bulkDeleteResult);
    } catch (e) {
      NOOP_graphqlErrorManagement();
    }
  }

  function renderBulkDeleteProductsButton() {
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<ImportIcon />}
        onClick={openBulkDeleteModal}
      >
        Bulk delete products
      </Button>
    );
  }

  function renderBulkDeleteProductsAnalysisModal() {
    return (
      <InputModal
        title="Bulk delete products"
        open={isBulkDeleteModalOpen}
        fields={[
          {
            name: 'importFile',
            label: 'CSV file for bulk delete',
            type: 'file',
            required: true,
            acceptedFileType: '.csv',
            errorMessage: {
              type: 'This file type is not supported.',
            },
          },
        ]}
        saveLabel="Analyze file"
        onClose={closeBulkDeleteModal}
        onCreate={handleValidateBulkDeleteProducts}
      >
        <Typography gutterBottom color="secondary">
          This feature will delete all the products provided in the CSV file.
        </Typography>
        <Typography gutterBottom>
          For more information about the multiple product delete process, please{' '}
          <Link href={GUIDE_LINK} target="_blank" rel="noopener noreferrer">
            check this guide
          </Link>
          .
        </Typography>
        <Typography variant="caption">
          After you select your file and click 'Analyze file', we will show you
          what would happen if you choose to continue with the multiple product
          delete. Before you confirm the delete in the next step, nothing will
          be deleted.
        </Typography>
      </InputModal>
    );
  }

  function renderBulkDeleteProductsResultModal() {
    return (
      <>
        {bulkDeleteProductFeedbackData && (
          <Dialog
            aria-labelledby="feedback-dialog-title"
            TransitionComponent={SlideUp}
            open={isBulkDeleteProductFeedbackModalOpen}
            onClose={closeBulkDeleteProductFeedbackModal}
            fullWidth
            maxWidth="sm"
            aria-describedby="feedback-dialog-description"
          >
            <DialogTitle id="feedback-dialog-title">
              {bulkDeleteHasError
                ? 'Bulk delete failed'
                : 'Bulk delete successful!'}
            </DialogTitle>
            <DialogContent id="feedback-dialog-description">
              <HeavyDutyResults results={bulkDeleteProductFeedbackData} />
            </DialogContent>
            <DialogActions>
              {!bulkDeleteHasError && (
                <Button
                  onClick={() => {
                    closeBulkDeleteProductFeedbackModal();
                    closeBulkDeleteConfirmModal();
                    closeBulkDeleteModal();
                  }}
                >
                  Cancel
                </Button>
              )}
              <FlexExpander />
              {bulkDeleteHasError ? (
                <Button
                  onClick={() => {
                    closeBulkDeleteProductFeedbackModal();
                    closeBulkDeleteConfirmModal();
                  }}
                >
                  Try another file
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    closeBulkDeleteProductFeedbackModal();
                    closeBulkDeleteConfirmModal();
                    closeBulkDeleteModal();
                  }}
                >
                  Ok
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  function renderBulkDeleteProductsConfirmationModal() {
    return (
      <>
        {bulkDeleteConfirmData && (
          <Dialog
            aria-labelledby="confirmation-dialog-title"
            TransitionComponent={SlideUp}
            open={isBulkDeleteConfirmModalOpen}
            onClose={closeBulkDeleteConfirmModal}
            fullWidth
            maxWidth="sm"
            aria-describedby="confirmation-dialog-description"
          >
            <DialogTitle id="confirmation-dialog-title">
              {bulkDeleteAnalysisHasError
                ? 'We cannot use this file'
                : 'Continue multiple product delete?'}
            </DialogTitle>
            <DialogContent id="confirmation-dialog-description">
              <HeavyDutyResults
                results={bulkDeleteConfirmData!.simulationResult}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeBulkDeleteConfirmModal}>Cancel</Button>
              <FlexExpander />
              {bulkDeleteAnalysisHasError ? (
                <Button onClick={closeBulkDeleteConfirmModal}>
                  Try another file
                </Button>
              ) : (
                <Button onClick={handleBulkDeleteProducts}>Yes</Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  return (
    <>
      {renderBulkDeleteProductsButton()}
      {renderBulkDeleteProductsAnalysisModal()}
      {renderBulkDeleteProductsConfirmationModal()}
      {renderBulkDeleteProductsResultModal()}
    </>
  );
};

export default DeleteProducts;
