import { uuid } from '@dvkiin/material-commons';
import {
  SecurityContext,
  useEnhancedMutation,
  useEnhancedQuery,
  useIsMobile,
  useModal,
} from '@lib';
import { Box, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { useDebouncedMemo } from '@optioffer/core';
import {
  Addon,
  AddonInput,
  AddonPriceInOfferModuleFragment,
  CalculationType,
  DiscountInput,
  OfferItem,
  OfferItemPriceInOfferModuleFragment,
  ProductSection_CalculateOfferItemPriceDocument,
  ProductSection_GetDefaultTaxesDocument,
  ProductSection_GetRecommendedAccessoriesDocument,
  ProductSection_SaveProductDocument,
  ProductSection_SearchProductsDocument,
  ProductSection_UpdateProductVersionDocument,
  ProductSpecification,
  TaxCalculationInput,
} from '@optioffer/graphql';

import OOModal from '@components/modals/OOModal';

import { OfferContext } from '@containers/OfferContext';

import { getQueryName } from '@lib/graphql';
import { ModalControl } from '@lib/materialCommons';

import AddonModal, { AddonModalData } from '../AddonModal';
import DiscountModal, { DiscountModalData } from '../DiscountModal';
import SearchAccessoryModal, {
  SearchAccessoryModalData,
} from '../SearchAccessoryModal';
import {
  convertToOfferItem,
  GetProductFn,
  isCustomOfferItem,
  isCustomProduct,
  ProductSearchResult,
} from '../domain';
import OfferItemModalContext from './context';
import OfferItemModalDesktop from './desktop';
import OfferItemModalMobile from './mobile';

type OfferItemModalProps = {
  control: ModalControl<ProductSearchResult>;
  updateOfferItem: (offerItem: OfferItem) => Promise<OfferItem>;
  openEditProduct: (data: ProductSearchResult) => void;
  getProduct: GetProductFn;
  isAccessoryModal?: boolean;
  offerItems?: OfferItem[];
};

// necessary because it's a dependency of useEffect
const DEFAULT_OFFER_ITEMS: OfferItem[] = [];

const OfferItemModal: FC<OfferItemModalProps> = ({
  control,
  updateOfferItem,
  openEditProduct,
  getProduct,
  isAccessoryModal,
  offerItems = DEFAULT_OFFER_ITEMS,
}) => {
  const isMobile = useIsMobile();
  const offer = useContext(OfferContext);
  const { company } = useContext(SecurityContext);
  const accessoryModal = useModal<ProductSearchResult>();
  const accessorySearchModal = useModal<SearchAccessoryModalData>();
  const addonModal = useModal<AddonModalData>();
  const discountModal = useModal<DiscountModalData>();

  const [offerItem, setOfferItem] = useState<OfferItem | undefined>();
  const [shouldCalculatePricing, setShouldCalculatePricing] = useState(false);
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: '',
      code: '',
      description: '',
      listPrice: 0,
      discountType: CalculationType.PERCENTAGE,
      discountValue: 0,
      quantity: 0,
      specifications: [] as ProductSpecification[],
      children: [] as OfferItem[],
      addons: [] as Addon[],
    },
    onSubmit: async (values) => {
      await saveOfferItem(values);

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const [saveNewProduct] = useEnhancedMutation(
    ProductSection_SaveProductDocument,
    {
      refetchQueries: [getQueryName(ProductSection_SearchProductsDocument)],
      error: {
        type: 'SNACKBAR',
        message: 'Could not save product in database.',
      },
    }
  );

  const [updateProductVersion] = useEnhancedMutation(
    ProductSection_UpdateProductVersionDocument,
    {
      refetchQueries: [getQueryName(ProductSection_SearchProductsDocument)],
      error: {
        type: 'SNACKBAR',
        message: 'Could not save product in database.',
      },
    }
  );

  const defaultTaxesQuery = useEnhancedQuery(
    ProductSection_GetDefaultTaxesDocument,
    { skip: !!offer }
  );

  async function saveOfferItem(values = formik.values) {
    const dirtyOfferItem = getDirtyOfferItem(values);
    if (!dirtyOfferItem) return;

    if (dirtyOfferItem.product) {
      const productInput = {
        code: dirtyOfferItem.product.code,
        name: dirtyOfferItem.product.name,
        description: dirtyOfferItem.product.description,
        price: dirtyOfferItem.product.price,
        images: [],
        accessories: [],
        specifications: dirtyOfferItem.product.specifications.map((spec) => ({
          name: spec.name,
          unit: spec.unit,
          value: spec.value,
        })),
      };
      if (isCustomProduct({ id: dirtyOfferItem.product.productId ?? '' })) {
        // save product as a new one
        const newProduct = await saveNewProduct({
          variables: {
            product: productInput,
          },
        });

        if (newProduct.data) {
          dirtyOfferItem.product.productId = newProduct.data.createProduct.id;
          dirtyOfferItem.product.productVersionId =
            newProduct.data.createProduct.versions[0].id;
        }
      } else if (
        !offer &&
        dirtyOfferItem.product.productId &&
        dirtyOfferItem.product.productVersionId
      ) {
        // update product version in DB
        await updateProductVersion({
          variables: {
            id: dirtyOfferItem.product.productId,
            productVersionId: dirtyOfferItem.product.productVersionId,
            product: productInput,
          },
        });
      }
    }

    await updateOfferItem(dirtyOfferItem);
  }

  function getDirtyOfferItem(values = formik.values): OfferItem | undefined {
    if (!offerItem) return undefined;
    return {
      ...offerItem,
      children: values.children,
      addons: values.addons,
      offerItemDiscount: {
        value: values.discountValue,
        calculationType: values.discountType,
      },
      quantity: values.quantity ?? 0,
      ...(offerItem.product
        ? {
            product: {
              ...offerItem.product,
              name: values.name,
              code: values.code,
              description: values.description,
              specifications: values.specifications,
              price: values.listPrice ?? 0,
            },
          }
        : {
            productBundle: undefined,
          }),
    };
  }

  useEffect(() => {
    if (offerItem) {
      formikRef.current.resetForm({
        values: {
          name: offerItem.product?.name ?? '',
          code: offerItem.product?.code ?? '',
          description: offerItem.product?.description ?? '',
          listPrice: offerItem.product?.price ?? 0,
          discountValue: offerItem.offerItemDiscount.value ?? 0,
          discountType:
            offerItem.offerItemDiscount.calculationType ??
            CalculationType.PERCENTAGE,
          quantity: offerItem.quantity ?? 0,
          specifications: offerItem.product?.specifications ?? [],
          children: offerItem.children ?? [],
          addons: offerItem.addons ?? [],
        },
      });
      setShouldCalculatePricing(!offerItem.pricing);
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, offerItem, setShouldCalculatePricing]);

  useEffect(() => {
    let cancelled = false;

    (async () => {
      if (control.data) {
        const oi =
          offerItems.find(
            (it) => it.product?.productVersionId === control.data?.version.id
          ) ?? (await convertToOfferItem(getProduct, control.data));
        if (!cancelled) {
          setOfferItem(oi);
        }
      } else {
        setOfferItem(undefined);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [setOfferItem, control.data, offerItems, getProduct]);
  const offerItemInQuote = useMemo(
    () => !!(offerItem && offerItems.some((it) => it.id === offerItem.id)),
    [offerItem, offerItems]
  );

  const [shouldLoadAccessories, setShouldLoadAccessories] = useState(false);
  const recommendedAccessoriesQuery = useEnhancedQuery(
    ProductSection_GetRecommendedAccessoriesDocument,
    {
      skip: !(
        offerItem &&
        !isCustomOfferItem(offerItem) &&
        shouldLoadAccessories &&
        offerItem.product?.productId &&
        offerItem.product?.productVersionId
      ),
      variables: {
        productId: offerItem?.product?.productId as string, // because we skip the query if not
        productVersionId: offerItem?.product?.productVersionId as string, // because we skip the query if not
      },
      error: {
        type: 'SNACKBAR',
        message: 'An error occurred while loading the accessories.',
      },
    }
  );

  useEffect(() => {
    if (!control.data) setShouldLoadAccessories(false);
  }, [control.data, setShouldLoadAccessories]);

  const debouncedVariables = useDebouncedMemo(
    () => {
      return {
        offerItem: {
          id: 'temporary id',

          offerItemDiscount: {
            value: formik.values.discountValue,
            calculationType: formik.values.discountType,
          },
          addons: formik.values.addons.map((addon) => ({
            id: addon.id,
            price: addon.price,
            discount: {
              value: addon.discount.value,
              calculationType: addon.discount.calculationType,
            },
          })),
          children: [],
          quantity: formik.values.quantity ?? 0,

          product: {
            price: formik.values.listPrice ?? 0,
          },
        },
        currency: offer
          ? {
              id: offer.currency.id,
              name: offer.currency.name,
              symbol: offer.currency.symbol,
            }
          : {
              id: company.currency.id,
              name: company.currency.name,
              symbol: company.currency.symbol,
            },
        taxes: (
          offer?.taxes ??
          defaultTaxesQuery?.data?.getCompanyDetails.defaultTaxes ??
          []
        ).map<TaxCalculationInput>((tax) => ({
          id: tax.id,
          calculationType: tax.calculationType,
          enabled: tax.enabled,
          name: tax.name,
          taxCalculationOrder: tax.taxCalculationOrder,
          type: tax.type,
          value: tax.value,
        })),
        offerDiscount: {
          value: offer?.offerDiscount.value ?? 0,
          calculationType:
            offer?.offerDiscount.calculationType ?? CalculationType.FIXED,
        },
      };
    },
    [
      formik.values,
      offer?.currency,
      offer?.taxes,
      offer?.offerDiscount,
      defaultTaxesQuery?.data,
      company,
    ],
    1000
  );

  const pricingQuery = useEnhancedQuery(
    ProductSection_CalculateOfferItemPriceDocument,
    {
      skip: !(shouldCalculatePricing && offerItem && debouncedVariables),
      variables: debouncedVariables,
      error: {
        type: 'SNACKBAR',
        message: 'An error occurred while calculating the pricing.',
      },
    }
  );
  useEffect(() => {
    if (!control.data) setShouldCalculatePricing(false);
  }, [control.data, setShouldCalculatePricing]);

  const pricing: OfferItemPriceInOfferModuleFragment | undefined =
    pricingQuery.data?.calculateOffer.offerItemsPricing[0] ??
    offerItem?.pricing;

  function getAddonPricing(
    addon: Addon
  ): AddonPriceInOfferModuleFragment | undefined {
    const calculatedPricings =
      pricingQuery.data?.calculateOffer.addonsPricing ?? [];
    return (
      calculatedPricings.find((it) => it.id === addon.id) ??
      addon.pricing ??
      undefined
    );
  }

  async function handleRemoveOfferItem(): Promise<void> {
    const dirtyOfferItem = getDirtyOfferItem();
    if (!dirtyOfferItem) return;

    await updateOfferItem({ ...dirtyOfferItem, quantity: 0 });
    control.close();
  }

  async function handleEditAddon(addon?: Addon) {
    const dirtyOfferItem = getDirtyOfferItem();
    if (!dirtyOfferItem) return;

    addonModal.open({
      offerItem: dirtyOfferItem,
      addon: addon
        ? {
            ...addon,
            addonDiscount: addon.discount,
          }
        : undefined,
    });
  }

  async function handleUpdateAddon(addon: AddonInput) {
    const newAddon: Addon = {
      ...addon,
      id: addon.id ?? uuid(),
      discount: addon.addonDiscount,
      pricing: undefined as any,
    };
    if (formik.values.addons.some((it) => it.id === addon.id)) {
      await formik.setFieldValue(
        'addons',
        formik.values.addons.map((oldAddon) =>
          oldAddon.id === newAddon.id ? newAddon : oldAddon
        )
      );
    } else {
      await formik.setFieldValue('addons', [...formik.values.addons, newAddon]);
    }

    setShouldCalculatePricing(true);
  }

  async function handleEditDiscount() {
    const dirtyOfferItem = getDirtyOfferItem();
    if (!dirtyOfferItem) return;

    discountModal.open({
      ...control.data,
      offerItem: dirtyOfferItem,
      discount: {
        value: formik.values.discountValue,
        calculationType: formik.values.discountType,
      },
      basePrice: formik.values.listPrice,
      quantity: formik.values.quantity,
    });
  }

  async function handleUpdateDiscount(discount: DiscountInput) {
    await formik.setFieldValue('discountType', discount.calculationType);
    await formik.setFieldValue('discountValue', discount.value);

    setShouldCalculatePricing(true);
  }

  function getOfferItem(it: ProductSearchResult) {
    return formik.values.children.find(
      (item) => item.product?.productVersionId === it.version.id
    );
  }

  async function updateAccessory(it: OfferItem) {
    let newChildren;
    if (!formik.values.children.some((oi) => oi.id === it.id)) {
      newChildren = [...formik.values.children, it];
    } else {
      newChildren = formik.values.children
        .map((accessory) => (accessory.id === it.id ? it : accessory))
        .filter((accessory) => !!accessory.quantity);
    }

    await formik.setFieldValue('children', newChildren);
    if (accessorySearchModal.isOpen) {
      const dirtyOfferItem = getDirtyOfferItem({
        ...formik.values,
        children: newChildren,
      });
      if (dirtyOfferItem) {
        accessorySearchModal.open({
          productName: dirtyOfferItem.product?.name ?? '',
          productVersionCode: dirtyOfferItem.product?.code ?? '',
          offerItemAccessories: dirtyOfferItem.children ?? [],
        });
      }
    }
    return it;
  }

  return (
    <>
      <OOModal
        title=""
        open={control.isOpen}
        onClose={control.close}
        minWidth="lg"
      >
        <form onSubmit={formik.handleSubmit}>
          <OfferItemModalContext.Provider
            value={{
              control,
              updateOfferItem,
              openEditProduct,
              getProduct,
              isAccessoryModal,
              offerItems,

              accessoryModal,
              accessorySearchModal,
              addonModal,
              discountModal,

              formik,
              pricing,
              offerItem,
              offerItemInQuote,
              getAddonPricing,
              getOfferItem,
              getDirtyOfferItem,
              handleEditAddon,
              handleEditDiscount,
              updateAccessory,
              handleRemoveOfferItem,
              setShouldCalculatePricing,
              setShouldLoadAccessories,
              pricingQuery,
              recommendedAccessoriesQuery,
            }}
          >
            {offerItem ? (
              isMobile ? (
                <OfferItemModalMobile />
              ) : (
                <OfferItemModalDesktop />
              )
            ) : (
              <Box
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
                paddingY={2}
                height={300}
              >
                <CircularProgress />
              </Box>
            )}
          </OfferItemModalContext.Provider>
        </form>
      </OOModal>

      <AddonModal control={addonModal} updateAddon={handleUpdateAddon} />
      <DiscountModal
        control={discountModal}
        updateDiscount={handleUpdateDiscount}
      />
      {!isAccessoryModal && offerItem && (
        <>
          <SearchAccessoryModal
            control={accessorySearchModal}
            getProduct={getProduct}
            accessoryModalControl={accessoryModal}
            handleUpdateOfferItem={updateAccessory}
          />
          <OfferItemModal
            isAccessoryModal
            control={accessoryModal}
            updateOfferItem={updateAccessory}
            openEditProduct={openEditProduct}
            getProduct={getProduct}
            offerItems={formik.values.children}
          />
        </>
      )}
    </>
  );
};

export default OfferItemModal;
