// Light
import '@fontsource/poppins/300.css';
// Medium / Normal
import '@fontsource/poppins/400.css';
// SemiBold
import '@fontsource/poppins/600.css';
// Bold
import '@fontsource/poppins/700.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import * as React from 'react';
import { FC } from 'react';

import theme from './theme';

const jss = create({
  plugins: [jssExtend() as any, ...jssPreset().plugins],
});

type MuiWrapperProps = {};

const MuiWrapper: FC<MuiWrapperProps> = ({ children }) => {
  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default MuiWrapper;
