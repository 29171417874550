/**
 * @deprecated please define new queries, mutations and fragments in @optioffer/graphql/documents
 */
export var currencyFields = "\n  id\n  name\n  symbol\n";
/**
 * @deprecated please define new queries, mutations and fragments in @optioffer/graphql/documents
 */
export var taxFields = "\n    id\n    name\n    type\n    calculationType\n    taxCalculationOrder\n    value\n    enabled\n    currency {\n      " + currencyFields + "\n    }\n";
/**
 * @deprecated please define new queries, mutations and fragments in @optioffer/graphql/documents
 */
export var taxCalculatedFields = "\n    tax {\n      " + taxFields + "\n    }\n    value\n    valueTotal\n";
/**
 * @deprecated please define new queries, mutations and fragments in @optioffer/graphql/documents
 */
export var cumulatedTaxCalculatedFields = "\n    tax {\n      " + taxFields + "\n    }\n    valueTotal\n";
/**
 * @deprecated please define new queries, mutations and fragments in @optioffer/graphql/documents
 */
export var discountFields = "\n    calculationType\n    value\n";
/**
 * @deprecated please define new queries, mutations and fragments in @optioffer/graphql/documents
 */
export var costFields = "\n    calculationType\n    value\n";
/**
 * @deprecated please define new queries, mutations and fragments in @optioffer/graphql/documents
 */
export var discountCalculatedFields = "\n  discount {\n    " + discountFields + "\n  }\n  value\n  valueTotal\n";
/**
 * @deprecated please define new queries, mutations and fragments in @optioffer/graphql/documents
 */
export var cumulatedDiscountCalculatedFields = "\n  discount {\n    " + discountFields + "\n  }\n  valueTotal\n";
/**
 * @deprecated please define new queries, mutations and fragments in @optioffer/graphql/documents
 */
export var productListReportFields = "\n  cost\n  margin\n  currency {\n    " + currencyFields + "\n  }\n";
/**
 * @deprecated please define new queries, mutations and fragments in @optioffer/graphql/documents
 */
export var productReportFields = "\n  costUnit\n  costTotal\n  marginUnit\n  marginTotal\n  currency {\n    " + currencyFields + "\n  }\n";
