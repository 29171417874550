import { DVKField } from '@dvkiin/material-commons';

const MAX_DESCRIPTION_LENGTH = 300;

export default [
  {
    name: 'name',
    label: 'Product name',
    type: 'text',
    required: true,
    autoFocus: true,
    errorMessage: 'Product name should not be empty',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'text',
    multiline: true,
    project: (it: string) =>
      it && it.length > 300 ? `${it.substr(0, MAX_DESCRIPTION_LENGTH)}...` : it,
  },
] as DVKField[];

export const attributeCollectionFields = [
  {
    name: 'name',
    label: 'Attribute collection name',
    type: 'text',
    required: true,
    autoFocus: true,
    errorMessage: 'Attribute collection name should not be empty',
  },
  {
    name: 'description',
    label: 'Attribute collection description',
    type: 'text',
    multiline: true,
  },
] as DVKField[];

export const productBundleDefaultFields = [
  {
    name: 'name',
    label: 'Product bundle name',
    type: 'text',
    required: true,
    autoFocus: true,
    errorMessage: 'Product bundle name should not be empty',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'text',
    multiline: true,
    project: (it: string) =>
      it && it.length > 300 ? `${it.substr(0, MAX_DESCRIPTION_LENGTH)}...` : it,
  },
] as DVKField[];
