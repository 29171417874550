import MomentUtils from '@date-io/moment';
import {
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
} from '@lib';
import { Box } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';

import {
  Addon,
  AddonInput,
  MutationSaveOfferArgs,
  NewOffer_GetOfferDocument,
  NewOffer_SaveOfferDocument,
  OfferClient,
  OfferInOfferModuleFragment,
  OfferItem,
  SaveOfferItemInput,
} from '@optioffer/graphql';

import { OfferContext } from '@containers/OfferContext';

import ClientStep from './ClientStep';
import QuoteHeader from './Header';
import ProductsStep from './ProductsStep';
import ReviewStep from './ReviewStep';
import SendStep from './SendStep';

type OfferWizardPageParams = {
  id: string;
};
const OfferWizard: FC<RouteComponentProps<OfferWizardPageParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const [step, setStep] = useQueryParam('step', NumberParam);
  useEffect(() => {
    if (step === null || step === undefined) setStep(1, 'replaceIn');
  }, [step, setStep]);

  const [{ offer, dirty: shouldSaveOffer }, setOffer] = useState<{
    offer: OfferInOfferModuleFragment | undefined;
    dirty: boolean;
  }>({ offer: undefined, dirty: false });
  const { push } = useHistory();

  const {
    data: { getOffer } = {
      getOffer: undefined,
    },
    error: getError,
    loading,
  } = useEnhancedQuery(NewOffer_GetOfferDocument, {
    variables: { id },
    error: {
      type: 'MODAL',
      message: 'An error occurred while loading the quote.',
    },
  });

  const [saveOffer] = useEnhancedMutation(NewOffer_SaveOfferDocument, {
    // success: { message: 'Quote saved successfully.' },
    error: {
      type: 'MODAL',
      message: 'An error occurred while saving your quote.',
    },
  });

  useEffect(() => {
    if (getOffer) {
      setOffer((oldState) =>
        oldState.offer ? oldState : { offer: getOffer, dirty: false }
      );
    }
  }, [getOffer]);

  function mapAddonToSaveInput(addon: Addon): AddonInput {
    return {
      id: addon.id,
      name: addon.name,
      price: addon.price,
      addonDiscount: {
        calculationType: addon.discount.calculationType,
        value: addon.discount.value,
      },
      cost: addon.cost
        ? {
            calculationType: addon.cost.calculationType,
            value: addon.cost.value,
          }
        : undefined,
    };
  }

  function mapOfferItemToSaveInputs(
    parentId: OfferItem['id'] | undefined,
    oi: OfferItem
  ): SaveOfferItemInput[] {
    return [
      {
        id: oi.id,
        parentId,
        productId: oi.product?.productId,
        productVersionId: oi.product?.productVersionId,
        offerItemDiscount: {
          calculationType: oi.offerItemDiscount.calculationType,
          value: oi.offerItemDiscount.value,
        },
        quantity: oi.quantity,
        name: oi.product?.name,
        code: oi.product?.code,
        description: oi.product?.description,
        price: oi.product?.price,
        specifications:
          oi.product?.specifications?.map(({ id, name, unit, value }) => ({
            id,
            name,
            unit,
            value: `${value}`,
          })) ?? [],
        addons: oi.addons.map(mapAddonToSaveInput),
      },
      ...(oi.children ?? []).flatMap(
        mapOfferItemToSaveInputs.bind(null, oi.id)
      ),
    ];
  }

  async function persistOffer(
    newOffer?: OfferInOfferModuleFragment
  ): Promise<OfferInOfferModuleFragment | undefined> {
    const offerToSave = newOffer ?? offer;

    if (offerToSave) {
      const items =
        offerToSave.offerItems
          ?.map((oi) => oi as OfferItem)
          .flatMap(mapOfferItemToSaveInputs.bind(null, undefined)) ?? [];
      const variables: MutationSaveOfferArgs = {
        id: offerToSave.id,
        client: offerToSave.client
          ? {
              clientId: offerToSave.client.clientId,
              name: offerToSave.client.name,
              email: offerToSave.client.email,
              phoneNumber: offerToSave.client.phoneNumber,
              companyName: offerToSave.client.companyName,
              companyVatId: offerToSave.client.companyVatId,
              companyEmail: offerToSave.client.companyEmail,
              companyPhoneNumber: offerToSave.client.companyPhoneNumber,
              companyAddress: offerToSave.client.companyAddress,
              contactPersonName: offerToSave.client.contactPersonName,
              country: offerToSave.client.country,
              zipCode: offerToSave.client.zipCode,
              city: offerToSave.client.city,
            }
          : undefined,
        items,
        offerDiscount: {
          calculationType: offerToSave.offerDiscount.calculationType,
          value: offerToSave.offerDiscount.value,
        },
        addons: offerToSave.addons.map(mapAddonToSaveInput),
        expireAt: offerToSave.expireAt,
        currency: {
          id: offerToSave.currency.id,
          rate: offerToSave.currency.rate ?? 1,
        },
        displayDiscounts: offerToSave.displayDiscounts,
        taxes: offerToSave.taxes.map((tax) => ({
          id: tax.id,
          enabled: tax.enabled,
        })),
        quoteTemplate: offerToSave.template,
        quoteLanguage: offerToSave.language,
        companyIntro: offerToSave.companyIntro,
        termsAndConditions: offerToSave.termsAndConditions,
        title: offerToSave.title ?? '',
      };
      const result = await saveOffer({
        variables,
      });

      setOffer({ offer: result.data?.saveOffer, dirty: false });
      return result.data?.saveOffer;
    }
    return undefined;
  }

  async function handleNavigate(step: number) {
    if (shouldSaveOffer) {
      await persistOffer();
    }

    if (step === -1) {
      push('/offer');
    } else {
      setStep(step);
      window.scrollTo(0, 0);
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="en-gb">
      <Box className="OfferWizard" paddingY={1.5} paddingX={2} maxWidth="100vw">
        <QuoteHeader step={step ?? 1} setStep={handleNavigate} />
        {loading ? (
          <>Loading</>
        ) : getError || !offer ? (
          <>Error</>
        ) : (
          <OfferContext.Provider value={offer}>
            {step === 1 ? (
              <ClientStep
                currentCustomer={offer?.client as any}
                setCurrentCustomer={(client) => {
                  setOffer({
                    offer: {
                      ...offer!,
                      client: client
                        ? ({
                            ...client,
                            id: undefined,
                            clientId: client.id,
                          } as OfferClient)
                        : undefined,
                    },
                    dirty: true,
                  });
                }}
                setStep={handleNavigate}
              />
            ) : step === 2 ? (
              <ProductsStep
                offerItems={offer.offerItems as OfferItem[]}
                setOfferItems={(offerItems) => {
                  persistOffer({ ...offer!, offerItems }).catch(
                    NOOP_graphqlErrorManagement
                  );
                }}
                currencyId={offer?.currency?.id ?? 'EUR'}
                setStep={handleNavigate}
              />
            ) : step === 3 ? (
              offer && (
                <ReviewStep
                  offer={offer}
                  updateOffer={async (offer) => {
                    return (await persistOffer(offer))!;
                  }}
                  setStep={handleNavigate}
                />
              )
            ) : step === 4 ? (
              offer && (
                <SendStep
                  offer={offer}
                  setStep={handleNavigate}
                  updateOffer={async (offer) => {
                    return (await persistOffer(offer))!;
                  }}
                />
              )
            ) : null}
          </OfferContext.Provider>
        )}
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default OfferWizard;
