import {
  PageTracker,
  SecurityContextProvider,
  useEnhancedQuery,
  UserDataTracker,
} from '@lib';
import React, { FC } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import {
  QueryParamProvider,
  StringParam,
  useQueryParam,
} from 'use-query-params';

import {
  Auth_CurrentUserDocument,
  Auth_GetCompanyDetailsDocument,
} from '@optioffer/graphql';

import ApolloFeedback from '@components/Feedback/ApolloFeedback';
import { ApolloFeedbackContextProvider } from '@components/Feedback/context';

import MuiWrapper from './MuiWrapper';
import { LoadingIndicator } from './components';
import { Layout } from './containers';
import {
  AttributeCollectionModule,
  ClientModule,
  CompaniesModule,
  ErrorPage,
  HomeModule,
  loginRoutes,
  OfferModule,
  PlanModule,
  ProductModule,
  SettingsModule,
  SupplierModule,
} from './pages';
import CatalogAdminModule from './pages/Admin/Catalog';
import ProfileAdminModule from './pages/Admin/Profile';
import ProjectAdminModule from './pages/Admin/Project';
import NewOfferModule from './pages/NewOffer';
import ProfileModule from './pages/Profile';
import SupportPage from './pages/Support';
import VirtualAssistantPage from './pages/VirtualAssistant';

const SecuredSection: FC<RouteComponentProps> = ({ location }) => {
  const {
    data: userData,
    error: userError,
    loading: loadingUser,
  } = useEnhancedQuery(Auth_CurrentUserDocument, {
    fetchPolicy: 'network-only',
  });
  const { data: companyData, loading: loadingCompany } = useEnhancedQuery(
    Auth_GetCompanyDetailsDocument
  );
  const [auth] = useQueryParam('auth', StringParam);

  if (auth)
    return (
      <Redirect to={{ pathname: '/login', state: { from: location, auth } }} />
    );
  else if (loadingUser || loadingCompany)
    // TODO: fancy loading screen
    return <>Loading ...</>;
  else if (userError || !userData)
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  else
    return (
      <SecurityContextProvider userData={userData} companyData={companyData}>
        <UserDataTracker>
          <Layout>
            <Switch>
              <Route path="/client" component={ClientModule} />
              <Route path="/supplier" component={SupplierModule} />
              <Route path="/offer" component={OfferModule} />
              <Route path="/product" component={ProductModule} />
              <Route
                path="/attribute-collection"
                component={AttributeCollectionModule}
              />
              <Route path="/settings" component={SettingsModule} />
              <Route path="/new-offer" component={NewOfferModule} />

              <Route path="/admin/plan" component={PlanModule} />
              <Route path="/admin/company" component={CompaniesModule} />
              <Route path="/admin/profile" component={ProfileAdminModule} />
              <Route path="/admin/project" component={ProjectAdminModule} />
              <Route path="/admin/catalog" component={CatalogAdminModule} />

              <Route path="/profile" component={ProfileModule} />

              {/* deprecated */}
              <Route
                exact
                path="/settings/profile"
                component={SettingsModule}
              />
              <Route exact path="/support" component={SupportPage} />
              <Route
                exact
                path="/virtual-assistant"
                component={VirtualAssistantPage}
              />

              <Route exact path="/" component={HomeModule} />
              <Route component={ErrorPage} />
            </Switch>
          </Layout>
        </UserDataTracker>
      </SecurityContextProvider>
    );
};

const App: FC = () => {
  return (
    <Router>
      <PageTracker>
        <QueryParamProvider ReactRouterRoute={Route}>
          <MuiWrapper>
            <ApolloFeedbackContextProvider>
              <div className="App">
                <Switch>
                  {loginRoutes()}

                  <Route component={SecuredSection} />
                </Switch>
              </div>
              <ApolloFeedback />
              <LoadingIndicator />
            </ApolloFeedbackContextProvider>
          </MuiWrapper>
        </QueryParamProvider>
      </PageTracker>
    </Router>
  );
};

export default App;
