import { useEnhancedProgrammaticQuery, useIsMobile, useModal } from '@lib';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { ProductSection_GetProductDocument } from '@optioffer/graphql';

import ProductList from '@containers/ProductSection/ProductList';
import ProductModal from '@containers/ProductSection/ProductModal';

import { ProductSearchResult } from '../NewOffer/domain';
import ProductBundleListPage from './bundleList';
import ProductBundleViewEditPage from './bundleViewEdit';
import ProductImportPage from './import';

const ProductHomepage: FC<RouteComponentProps> = ({
  match,
  history: { push },
}) => {
  const isMobile = useIsMobile();

  const productModal = useModal<ProductSearchResult>();

  const [getProduct] = useEnhancedProgrammaticQuery(
    ProductSection_GetProductDocument,
    {
      error: {
        type: 'MODAL',
        message: 'An error occurred while loading the product.',
      },
    }
  );

  return (
    <>
      {!isMobile && <h2>Products</h2>}
      <Box
        display="flex"
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        maxWidth="calc(100vw - 32px)"
        marginTop={isMobile ? -3 : 0}
      >
        <ProductList
          getProduct={getProduct}
          productModal={productModal}
          extraButtons={
            !isMobile && (
              <Box
                flex={1}
                display="flex"
                justifyContent="flex-end"
                marginRight={2}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => push(`${match.path}/import`)}
                >
                  Import
                </Button>
              </Box>
            )
          }
        />
      </Box>

      <ProductModal control={productModal} />
    </>
  );
};

const ProductModule: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/import`}
        exact
        component={ProductImportPage}
      />
      <Route
        path={`${match.path}/bundle/:id`}
        component={ProductBundleViewEditPage}
      />
      <Route
        path={`${match.path}/bundle`}
        exact
        component={ProductBundleListPage}
      />
      <Route path={match.path} component={ProductHomepage} />
    </Switch>
  );
};

export default ProductModule;
