import {
  OptiAccordion,
  OptiAccordionDetails,
  OptiAccordionSummary,
} from '@components';
import { Formik, useIsMobile } from '@lib';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { FC, Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  SearchAttributeFilter,
  SearchAttributeWithCount,
} from '@optioffer/graphql';

import {
  areFiltersEqual,
  renderSearchAttributeValue,
  sanitizeFilter,
  useFilterDefinitions,
} from '@lib/product/search';

import { OptiFormControlLabel } from './ProductFilterModal';
import useStyles from './styles';

const useLocalStyles = makeStyles(() => ({
  filterCount: {
    flex: 1,
    textAlign: 'right',
    opacity: '20%',
  },
}));

type ProductAttributeFiltersProps = {
  formik: Formik<
    any & {
      filters: SearchAttributeFilter[];
    }
  >;
  possibleFilters: SearchAttributeWithCount[];
  getFilterCount(filter: SearchAttributeWithCount): number;
  debouncedSearchString?: string;
};

const ProductAttributeFilters: FC<ProductAttributeFiltersProps> = ({
  formik,
  possibleFilters,
  getFilterCount,
  debouncedSearchString = '',
}) => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const isMobile = useIsMobile();
  const [numberOfFiltersVisible, setNumberOfFiltersVisible] = useState(10);
  const [endOfListRef, endOfListInView] = useInView();

  const [filterDefinitions, filtersLoading] = useFilterDefinitions(
    formik.values.filters,
    possibleFilters,
    debouncedSearchString
  );

  useEffect(() => {
    if (endOfListInView) {
      setNumberOfFiltersVisible((val) => val + 10);
    }
  }, [endOfListInView, setNumberOfFiltersVisible]);

  return (
    <>
      {filterDefinitions
        .slice(0, numberOfFiltersVisible)
        .map((filterGroup, index, self) => (
          <Fragment key={filterGroup.group}>
            <OptiAccordion TransitionProps={{ unmountOnExit: true }}>
              <OptiAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${filterGroup.group}-content`}
                id={`panel-${filterGroup.group}-header`}
              >
                <Typography className={classes.sectionHeading}>
                  {filterGroup.filters[0].name}
                </Typography>
              </OptiAccordionSummary>
              <OptiAccordionDetails>
                <Box display="flex" flexDirection="column" width="100%">
                  {filterGroup.filters.map((filter) => {
                    const total = getFilterCount(filter);
                    return (
                      <Box
                        key={filter.valueKey}
                        display="flex"
                        alignItems="center"
                        paddingX={1.5}
                      >
                        <OptiFormControlLabel
                          control={
                            <Checkbox
                              onChange={async ({ target }) => {
                                await formik.setFieldValue(
                                  'filters',
                                  target.checked
                                    ? [
                                        ...formik.values.filters,
                                        sanitizeFilter(filter),
                                      ]
                                    : formik.values.filters.filter(
                                        (it: SearchAttributeFilter) =>
                                          !areFiltersEqual(filter, it)
                                      )
                                );
                              }}
                              checked={formik.values.filters.some(
                                areFiltersEqual.bind(null, filter)
                              )}
                              disabled={!total}
                              color="primary"
                            />
                          }
                          label={renderSearchAttributeValue(filter)}
                        />
                        <Box className={localClasses.filterCount}>
                          ({total} product{total !== 1 ? 's' : ''})
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </OptiAccordionDetails>
            </OptiAccordion>
            {isMobile && index < self.length - 1 && (
              <Box marginY={0.5}>
                <Divider />
              </Box>
            )}
          </Fragment>
        ))}
      <div ref={endOfListRef} />
      {filtersLoading && (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ProductAttributeFilters;
