import { Formik, ModalControl } from '@lib';
import { createContext } from 'react';

import { OfferInOfferModuleFragment } from '@optioffer/graphql';

import { ExportModalData } from '../ExportModal';
import { QuoteSentModalData } from '../QuoteSentModal';

type MessageSendFormik = Formik<{
  subject: string;
  message: string;
  sendToMyself: boolean;
}>;

type QuoteManagementFormik = Formik<{
  title: string;
}>;

const SendStepContext = createContext<{
  quote: OfferInOfferModuleFragment;
  updateOffer(
    offer: OfferInOfferModuleFragment
  ): Promise<OfferInOfferModuleFragment>;

  quoteSentModal: ModalControl<QuoteSentModalData>;
  exportModal: ModalControl<ExportModalData>;
  renderingErrorModal: ModalControl<string>;

  sendFormik: MessageSendFormik;
  quoteManagementFormik: QuoteManagementFormik;

  loadingQuoteFiles: boolean;
  renderedOfferPdfBlob?: Blob;
  quotePdfFiles?: File[];
  downloadingQuoteForPrint: boolean;
  sendingOfferToClient: boolean;

  handleDownloadQuote(): Promise<any>;
  handleSendOfferViaEmail(): Promise<any>;
  handleSendOfferViaShare(): Promise<any>;

  setStep(step: number): void;
}>(undefined as any);

export default SendStepContext;
