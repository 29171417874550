import { AvatarProps } from '@material-ui/core/Avatar/Avatar';
import Button from '@material-ui/core/Button';
import * as React from 'react';
import { FC } from 'react';

export type ImageInputProps = AvatarProps & {
  buttonLabel: string;
  error?: boolean;
  imageChanged: (event: any) => any;
};

const ImageInput: FC<ImageInputProps> = (props) => {
  return (
    <>
      <input
        color="primary"
        accept="image/*"
        type="file"
        id="icon-button-file"
        style={{ display: 'none' }}
        onChange={props.imageChanged}
      />
      <label htmlFor="icon-button-file">
        <Button variant="contained" component="span" color="primary" fullWidth>
          {props.buttonLabel}
        </Button>
      </label>
      {!!props.src && (
        <img
          src={props.src}
          width={150}
          alt={props.buttonLabel}
          style={{ marginTop: 16, marginLeft: 'auto', display: 'flex' }}
        />
      )}
    </>
  );
};

export default ImageInput;
