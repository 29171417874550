import { makeStyles } from '@material-ui/core';

import { OOTheme } from '../../../theme';

export default makeStyles((theme: OOTheme) => ({
  tableCard: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
}));
