import { ModalTransition } from '@components';
import { ModalControl, useEnhancedProgrammaticQuery } from '@lib';
import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { usePDF } from '@react-pdf/renderer';
import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  OfferInOfferModuleFragment,
  Printing_GetOfferDocument,
} from '@optioffer/graphql';
import { BasicLayout, layoutOffer } from '@optioffer/printing';

import { printingResources } from '@resources/printingResources';

import useStyles from '../styles';

const useLocalStyles = makeStyles((theme) => ({
  transformWrapper: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: theme.palette.grey.A100,
  },
  transformContent: {},
  documentPage: {
    margin: theme.spacing(1),
  },
}));

type PreviewModalProps = {
  offer: OfferInOfferModuleFragment;
  variant: string | undefined;
  locale: string;

  control: ModalControl;
  confirm: () => void;
};

const PreviewModal: FC<PreviewModalProps> = ({ control, confirm, offer }) => {
  const classes = useStyles();
  const localClasses = useLocalStyles();

  const [
    loadOfferForPrintQuery,
    offerForPrintQuery,
  ] = useEnhancedProgrammaticQuery(Printing_GetOfferDocument, {
    variables: {
      id: offer.id,
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while loading the quote for preview.',
    },
  });
  const offerForPrintQueryRef = useRef(offerForPrintQuery);
  useUpdateRefIfShallowNew(offerForPrintQueryRef, offerForPrintQuery);
  const [layoutedQuote, setLayoutedQuote] = useState<ReactNode>();
  const [renderedQuote, reRenderQuote] = usePDF({
    document: layoutedQuote as any,
  });
  const reRenderQuoteRef = useRef(reRenderQuote);
  useUpdateRefIfShallowNew(reRenderQuoteRef, reRenderQuote);
  const transformRef = useRef<ReactZoomPanPinchRef>();

  const [numPages, setNumPages] = useState(0);

  useEffect(() => {
    let cancelled = false;

    if (!control.isOpen) {
      setLayoutedQuote(undefined);
    } else {
      (async () => {
        const offerForPrintData =
          offerForPrintQueryRef.current.data ??
          (await loadOfferForPrintQuery()).data;

        if (cancelled || !offerForPrintData) return;
        setLayoutedQuote(
          layoutOffer(
            offerForPrintData.getOffer as any,
            BasicLayout,
            printingResources,
            offer.template,
            offer.language
          )
        );
        requestAnimationFrame(() => {
          if (cancelled) return;
          reRenderQuoteRef.current();
        });
      })();
    }

    return () => {
      cancelled = true;
    };
  }, [
    loadOfferForPrintQuery,
    offerForPrintQueryRef,
    control.isOpen,
    offer,
    reRenderQuoteRef,
  ]);

  return (
    <Dialog
      TransitionComponent={ModalTransition}
      keepMounted
      fullScreen
      open={control.isOpen}
      onClose={control.close}
    >
      <Box display="flex" flexDirection="column" height="100vh">
        <Box
          boxShadow={2}
          width="100%"
          display="flex"
          alignItems="center"
          marginBottom={0.5}
        >
          <IconButton color="primary" onClick={control.close}>
            <ChevronLeftIcon />
          </IconButton>

          <Box>
            <Typography className={classes.modalHeader}>Preview</Typography>
          </Box>
        </Box>

        <Box width={'100%'} flex={1} height={0}>
          <TransformWrapper minScale={0.2} ref={transformRef as any}>
            <TransformComponent
              wrapperClass={localClasses.transformWrapper}
              contentClass={localClasses.transformContent}
            >
              {renderedQuote.url &&
              !renderedQuote.error &&
              !renderedQuote.loading ? (
                <Document
                  file={renderedQuote.url}
                  onLoadSuccess={({ numPages }: { numPages: number }) => {
                    setNumPages(numPages);
                  }}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      renderMode="svg"
                      width={
                        (transformRef.current?.instance.wrapperComponent
                          ?.clientWidth ?? 200) - 16
                      }
                      // 16px = padding
                      className={localClasses.documentPage}
                    />
                  ))}
                </Document>
              ) : (
                <Box
                  width="100vw"
                  height="80vh"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {renderedQuote?.error || <CircularProgress />}
                </Box>
              )}
            </TransformComponent>
          </TransformWrapper>
        </Box>

        <Box marginBottom={7} />

        <Box className={clsx('floatOnDesktop', classes.floatingFooter)}>
          <Box display="flex">
            <Box paddingX={2}>
              <Button fullWidth onClick={control.close}>
                Back
              </Button>
            </Box>
            <Box flex="1">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={confirm}
              >
                Confirm Quote
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PreviewModal;
