import {
  DVKObject,
  FlexExpander,
  InputModal,
  SlideUp,
  useModal,
} from '@dvkiin/material-commons';
import { NOOP_graphqlErrorManagement, useHasRole } from '@lib';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ImportIcon from '@material-ui/icons/PostAdd';
import React, { FC } from 'react';

import { UserRole } from '@optioffer/graphql';

import { HeavyDutyResult } from '@components/Feedback/domain';

import { HeavyDutyResults } from '../../components/Feedback';
import useStyles from './styles';

type ImportSectionProps = {
  simulateImport: (file: File) => Promise<HeavyDutyResult[]>;
  importClients: (file: File) => Promise<HeavyDutyResult[]>;
};

const ClientImportSection: FC<ImportSectionProps> = ({
  simulateImport,
  importClients,
}) => {
  const { addButton } = useStyles();
  const isManager = useHasRole(UserRole.MANAGER);

  const {
    isOpen: isImportModalOpen,
    open: openImportModal,
    close: closeImportModal,
  } = useModal();
  const {
    isOpen: isImportConfirmModalOpen,
    open: openImportConfirmModal,
    close: closeImportConfirmModal,
    data: importConfirmData,
  } = useModal<{ file: File; simulationResult: HeavyDutyResult[] }>();
  const {
    isOpen: isFeedbackModalOpen,
    open: openFeedbackModal,
    close: closeFeedbackModal,
    data: feedbackData,
  } = useModal<HeavyDutyResult[]>();

  const simulationHasError = !!importConfirmData?.simulationResult.find(
    (result) => result.status === 'ERROR'
  );
  const importHasError = !!feedbackData?.find(
    (result) => result.status === 'ERROR'
  );

  async function handleAnalyze({ importFile }: DVKObject) {
    const file = ((importFile as any) as File[])[0];
    try {
      const simulationResult = await simulateImport(file);
      openImportConfirmModal({ file: file as any, simulationResult });
    } catch (e) {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleImport() {
    const file = importConfirmData!.file;
    try {
      const importResult = await importClients(file);
      openFeedbackModal(importResult);
    } catch (e) {
      NOOP_graphqlErrorManagement();
    }
  }

  if (!isManager) {
    return null;
  }

  return (
    <>
      <Button
        className={addButton}
        variant="contained"
        color="primary"
        startIcon={<ImportIcon />}
        onClick={openImportModal}
      >
        Import customers from file
      </Button>

      <InputModal
        title="Import customers"
        open={isImportModalOpen}
        fields={[
          {
            name: 'importFile',
            label: 'CSV file for import',
            type: 'file',
            required: true,
            acceptedFileType: '.csv',
            errorMessage: {
              type: 'This file type is not supported.',
            },
          },
        ]}
        saveLabel="Analyze file"
        onClose={closeImportModal}
        onCreate={handleAnalyze}
      >
        <Typography variant="caption">
          After you select your file and click 'Analyze file', we will show you
          what would happen if you choose to continue with the import. Before
          you confirm the import in the next step, nothing will be imported.
        </Typography>
      </InputModal>

      {importConfirmData && (
        <Dialog
          aria-labelledby="confirmation-dialog-title"
          TransitionComponent={SlideUp}
          open={isImportConfirmModalOpen}
          onClose={closeImportConfirmModal}
          fullWidth
          maxWidth="sm"
          aria-describedby="confirmation-dialog-description"
        >
          <DialogTitle id="confirmation-dialog-title">
            {simulationHasError
              ? 'We cannot import this file'
              : 'Continue import?'}
          </DialogTitle>
          <DialogContent id="confirmation-dialog-description">
            <HeavyDutyResults results={importConfirmData!.simulationResult} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeImportConfirmModal}>Cancel</Button>
            <FlexExpander />
            {simulationHasError ? (
              <Button onClick={closeImportConfirmModal}>
                Try another file
              </Button>
            ) : (
              <Button onClick={handleImport}>Yes</Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {feedbackData && (
        <Dialog
          aria-labelledby="feedback-dialog-title"
          TransitionComponent={SlideUp}
          open={isFeedbackModalOpen}
          onClose={closeFeedbackModal}
          fullWidth
          maxWidth="sm"
          aria-describedby="feedback-dialog-description"
        >
          <DialogTitle id="feedback-dialog-title">
            {importHasError ? 'Import failed' : 'Import successful!'}
          </DialogTitle>
          <DialogContent id="feedback-dialog-description">
            <HeavyDutyResults results={feedbackData} />
          </DialogContent>
          <DialogActions>
            {!importHasError && (
              <Button
                onClick={() => {
                  closeFeedbackModal();
                  closeImportConfirmModal();
                  closeImportModal();
                }}
              >
                Cancel
              </Button>
            )}
            <FlexExpander />
            {importHasError ? (
              <Button
                onClick={() => {
                  closeFeedbackModal();
                  closeImportConfirmModal();
                }}
              >
                Try another file
              </Button>
            ) : (
              <Button
                onClick={() => {
                  closeFeedbackModal();
                  closeImportConfirmModal();
                  closeImportModal();
                }}
              >
                Ok
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ClientImportSection;
