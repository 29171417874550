export var ro = {
    global: {
        offerId: 'Id Oferta: {{id}}',
        vatId: 'Cod fiscal: {{id}}',
        validity: 'Valabilitate: {{validity}}',
    },
    product: {
        accessoryFor: "Accesoriu pentru '{{parent}}'",
        specifications: 'Specificatii tehnice',
        bundleProducts: 'Produse',
        description: 'Descriere',
        quantity: 'Cant.',
        total: 'TOTAL',
        listPrice: 'Pret de lista',
        discount: 'Disc. {{discount}}',
        addonDiscount: 'Disc. {{addon}} {{discount}}',
        additionalDiscount: 'Disc. supl. {{discount}}',
        netPrice: 'Pret net',
        portrait: {
            code: 'Cod produs / SKU: {{code}}',
        },
    },
    addons: {
        title: 'Servicii',
        total: 'Total servicii',
        listPrice: 'Pret de lista',
        netPrice: 'Pret net',
    },
    lastPage: {
        listPrice: 'Pret de lista (fara servicii)',
        productAddonsTotal: 'Total servicii produse',
        addonsTotal: 'Total servicii',
        totalListPrice: 'Pret de lista total',
        cumulativeDiscount: 'Discount total',
        additionalDiscount: 'Discount suplimentar ({{discount}})',
        grandTotal: 'TOTAL:',
    },
};
