import React, { FC } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import SupplierListPage from './ListPage';
import SupplierViewEditPage from './ViewEditPage';

const SupplierModule: FC<RouteComponentProps> = ({ match }) => {
  return (
    <>
      <Route path={`${match.path}/:id`} component={SupplierViewEditPage} />
      <Route exact path={match.path} component={SupplierListPage} />
    </>
  );
};

export default SupplierModule;
