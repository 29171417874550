import { useCallback, useEffect, useRef, useState, } from 'react';
import { debounce, throttle } from './flowControl';
export var useDelayedRender = function (delay) {
    if (delay === void 0) { delay = 1000; }
    var delayRef = useRef(delay);
    var _a = useState(false), shouldRender = _a[0], setShouldRender = _a[1];
    useEffect(function () {
        var cancelled = false;
        var timeout = setTimeout(function () {
            if (!cancelled) {
                setShouldRender(true);
            }
        }, delayRef.current);
        return function () {
            cancelled = true;
            try {
                clearTimeout(timeout);
            }
            catch (_a) {
                // don't care; we have cancelled as a fallback
            }
        };
    }, [delayRef, setShouldRender]);
    return shouldRender;
};
export var useThrottledMemo = function (valueFactory, deps, wait, options) {
    var _a = useState(valueFactory()), valueMemo = _a[0], setValueMemo = _a[1];
    var throttledSetState = useCallback(throttle(setValueMemo, wait, options), []);
    useEffect(function () {
        throttledSetState(valueFactory());
    }, deps);
    return valueMemo;
};
export var useDebouncedMemo = function (valueFactory, deps, wait, immediate) {
    var _a = useState(valueFactory()), valueMemo = _a[0], setValueMemo = _a[1];
    var debouncedSetState = useCallback(debounce(setValueMemo, wait, immediate), []);
    useEffect(function () {
        debouncedSetState(valueFactory());
    }, deps);
    return valueMemo;
};
export var useDebouncedState = function (initialValue, wait, immediate) {
    var _a = useState(initialValue), valueMemo = _a[0], setValueMemo = _a[1];
    var debouncedSetState = useCallback(debounce(setValueMemo, wait, immediate), [setValueMemo, wait, immediate]);
    return [valueMemo, debouncedSetState];
};
