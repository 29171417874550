import {
  useEnhancedMutation,
  useEnhancedQuery,
  usePaginationFromUrl,
} from '@lib';
import React, { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import {
  VirtualAssistant_DeleteNotificationDocument,
  VirtualAssistant_FindAllNotificationsDocument,
  VirtualAssistant_SnoozeNotificationDocument,
} from '@optioffer/graphql';

import NotificationTable from './NotificationTable';
import { CustomNotification, NotificationState } from './domain';

const ActionCenterListPage: FC<RouteComponentProps> = ({ history }) => {
  const { push } = useHistory();
  const {
    pagination,
    graphQLPagination,
    handlePaginationUpdate,
  } = usePaginationFromUrl();
  const { data: findAllNotifications } = useEnhancedQuery(
    VirtualAssistant_FindAllNotificationsDocument,
    {
      variables: { pagination: graphQLPagination },
      error: {
        type: 'SNACKBAR',
        message: 'An error occurred while loading the notifications.',
      },
    }
  );
  const [snoozeNotification] = useEnhancedMutation(
    VirtualAssistant_SnoozeNotificationDocument,
    {
      refetchQueries: ['findAllNotifications'],
      error: {
        type: 'MODAL',
        message: 'An error occurred while snoozing the notification',
      },
      success: {
        message: 'Notification snoozed',
      },
    }
  );
  const [dismissNotification] = useEnhancedMutation(
    VirtualAssistant_DeleteNotificationDocument,
    {
      refetchQueries: ['findAllNotifications'],
      error: {
        type: 'MODAL',
        message: 'An error occurred while dismissing the notification',
      },
      success: {
        message: 'Notification dismissed',
      },
    }
  );
  const { notifications, total } = useMemo(
    () => ({
      notifications: findAllNotifications?.findAllNotifications?.notifications,
      total: findAllNotifications?.findAllNotifications?.total,
    }),
    [findAllNotifications]
  );

  // endregion

  async function handleSnoozeNotification(notification: CustomNotification) {
    await snoozeNotification({
      variables: {
        notificationId: notification.id, // notificationId
        nextNotificationDate: Date.now() + 24 * 60 * 60 * 1000, // 1 day
        notificationState: NotificationState.Snoozed,
      },
    });
  }

  async function handleDismissNotification(notification: CustomNotification) {
    await dismissNotification({
      variables: {
        notificationId: notification.id,
      },
    });
  }

  const handlePageChange = (page: number) => {
    handlePaginationUpdate({ page, rowsPerPage: 10 }, undefined);
  };

  const handleGoToOffer = useCallback(
    ({ quoteId }: CustomNotification) => {
      push(`/offer/${quoteId}`);
    },
    [push]
  );
  function renderTable() {
    if (!notifications) return 'Loading...';
    return (
      <NotificationTable
        notifications={notifications}
        total={total || 0}
        page={pagination.page}
        handlePageChange={handlePageChange}
        onSnooze={handleSnoozeNotification}
        onDismiss={handleDismissNotification}
        onGoToOffer={handleGoToOffer}
      />
    );
  }

  return <>{renderTable()}</>;
};

export default ActionCenterListPage;
