import {
  ModalControl,
  SecurityContext,
  toStandardSort,
  useEnhancedQuery,
  useIsMobile,
  useModal,
  usePagination,
} from '@lib';
import {
  Badge,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import * as React from 'react';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useDebouncedMemo } from '@optioffer/core';
import {
  OfferItem,
  ProductSection_SearchProductsDocument,
  SearchAttributeFilter,
} from '@optioffer/graphql';

import ProductTable from '@components/Table/ProductTable';

import { OfferContext } from '@containers/OfferContext';

import { areFiltersEqual, PriceFilter } from '@lib/product/search';

import { FilterIcon } from '@resources/icons';

import DesktopProductFilterSection from './DesktopProductFilterSection';
import ProductFilterModal, {
  ProductFilterModalData,
} from './ProductFilterModal';
import ProductListItem from './ProductListItem';
import {
  convertToOfferItem,
  GetProductFn,
  getProductSearchResult,
  isCustomProduct,
  ProductSearchResult,
} from './domain';
import useStyles from './styles';

const useLocalStyles = makeStyles((theme) => ({
  searchFeedbackText: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    fontWeight: 'bold',
    opacity: '50%',
  },
  filterIcon: {
    color: theme.palette.primary.main,
  },
  clearFilterButton: {
    minWidth: 'initial',
    marginLeft: theme.spacing(0.5),
    '& svg': {
      width: theme.spacing(2),
    },
  },
  filtersColumn: {
    maxHeight: 'calc(100vh - 130px)',
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 0,
  },
}));

type ProductListProps = {
  updateOfferItem?: (offerItem: OfferItem) => Promise<OfferItem>;
  updateSelectedProduct?: (
    product: ProductSearchResult,
    selected: boolean
  ) => Promise<any>;
  getProduct: GetProductFn;
  offerItems?: OfferItem[];
  selectedProducts?: ProductSearchResult[];

  productModal: ModalControl<ProductSearchResult>;
  offerItemModal?: ModalControl<ProductSearchResult>;
  isAccessoryList?: boolean;
  extraButtons?: ReactNode;
};
const defaultOfferItems: OfferItem[] = [];
const defaultSelectedProducts: ProductSearchResult[] = [];

const MemoProductList = React.memo<
  Pick<ProductListProps, 'offerItems' | 'selectedProducts' | 'productModal'> & {
    actionIsCheckbox: boolean;
    hideAction: boolean;
    handleIncreaseQuantity(item: ProductSearchResult): Promise<any>;
    handleUpdateQuantity?: (offerItem: OfferItem) => Promise<any>;
    handleRemoveProduct?: (item: ProductSearchResult) => Promise<any>;
    items: ProductSearchResult[];
  }
>(
  ({
    items,
    offerItems = defaultOfferItems,
    selectedProducts = defaultSelectedProducts,
    handleIncreaseQuantity,
    handleUpdateQuantity,
    handleRemoveProduct,
    productModal,
    actionIsCheckbox,
    hideAction,
  }) => {
    return (
      <>
        {items.map((item, index, self) => (
          <ProductListItem
            key={item.version.id}
            item={item}
            offerItem={offerItems.find(
              (oi) => oi.product?.productVersionId === item.version.id
            )}
            selected={selectedProducts.some(
              (oi) =>
                oi.product.id === item.product.id &&
                oi.version.id === item.version.id
            )}
            handleIncreaseQuantity={handleIncreaseQuantity}
            handleUpdateQuantity={handleUpdateQuantity}
            handleRemoveProduct={handleRemoveProduct}
            onClick={async (item: ProductSearchResult) => {
              productModal.open(item);
            }}
            actionIsCheckbox={actionIsCheckbox}
            hideAction={hideAction}
          >
            {index < self.length - 1 && (
              <Box marginX={-2}>
                <Divider />
              </Box>
            )}
          </ProductListItem>
        ))}
      </>
    );
  }
  // (prevProps, newProps) =>
  //   (!prevProps.items.length && !newProps.items.length) ||
  //   prevProps.items === newProps.items
);

const desktopDefaultPagination = { page: 0, rowsPerPage: 20 };
const mobileDefaultPagination = { page: 0, rowsPerPage: 50 };

const ProductList: FC<ProductListProps> = ({
  updateOfferItem,
  updateSelectedProduct,
  offerItems = defaultOfferItems,
  selectedProducts = defaultSelectedProducts,
  getProduct,
  productModal,
  offerItemModal,
  isAccessoryList = false,
  extraButtons,
}) => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const isMobile = useIsMobile();
  const offer = useContext(OfferContext);
  const { company } = useContext(SecurityContext);
  const defaultPagination = isMobile
    ? mobileDefaultPagination
    : desktopDefaultPagination;

  const {
    sort,
    pagination,
    graphQLPagination,
    handlePaginationUpdate,
    stateRef,
  } = usePagination(defaultPagination, 'name');

  useEffect(() => {
    handlePaginationUpdate(
      isMobile ? mobileDefaultPagination : desktopDefaultPagination,
      stateRef.current.sort
    );
  }, [isMobile, handlePaginationUpdate, stateRef]);

  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filters, setFilters] = useState<SearchAttributeFilter[]>([]);
  const [priceFilter, setPriceFilter] = useState<PriceFilter>([
    undefined,
    undefined,
  ]);

  const debouncedSearchParams = useDebouncedMemo(
    () => ({
      searchString,
      filters,
      minPrice: priceFilter[0],
      maxPrice: priceFilter[1],
    }),
    [searchString, filters, priceFilter],
    1000
  );

  const { data: products, loading: searchingProducts } = useEnhancedQuery(
    ProductSection_SearchProductsDocument,
    {
      variables: { ...debouncedSearchParams, pagination: graphQLPagination },
      error: {
        type: 'MODAL',
        message: 'An error occurred while loading the products.',
      },
    }
  );

  const filterModal = useModal<ProductFilterModalData>();

  const searchResults: ProductSearchResult[] = useMemo(
    () =>
      ((products?.searchProducts.products.flatMap((product) =>
        product.versions.map((version) => ({ product, version }))
      ) ?? []) as ProductSearchResult[]).sort((a, b) => {
        // temporary hack; see packages/server/src/modules/product/queries.ts for explanations on how to solve this
        if (graphQLPagination.sort === 'name')
          return a.product.name.localeCompare(b.product.name);
        if (graphQLPagination.sort === '-name')
          return b.product.name.localeCompare(a.product.name);
        if (graphQLPagination.sort === 'versions.code')
          return a.version.code.localeCompare(b.version.code);
        if (graphQLPagination.sort === '-versions.code')
          return b.version.code.localeCompare(a.version.code);
        if (graphQLPagination.sort === 'versions.price')
          return a.version.price - b.version.price;
        if (graphQLPagination.sort === '-versions.price')
          return b.version.price - a.version.price;
        return 0;
      }),
    [products, graphQLPagination]
  );

  const customItems: ProductSearchResult[] = useMemo(
    () =>
      offerItems
        .filter(
          (it) =>
            it.product && isCustomProduct({ id: it.product.productId ?? '' })
        )
        .map(getProductSearchResult)
        .filter((it): it is ProductSearchResult => !!it),
    [offerItems]
  );

  const handleIncreaseQuantity = useCallback(
    async (item: ProductSearchResult) => {
      if (updateOfferItem) {
        const offerItem = offerItems.find(
          (it) => it.product?.productVersionId === item.version.id
        );

        return await updateOfferItem(
          offerItem
            ? {
                ...offerItem,
                quantity: offerItem.quantity + 1,
              }
            : await convertToOfferItem(getProduct, item)
        );
      } else {
        return updateSelectedProduct?.(item, true);
      }
    },
    [offerItems, getProduct, updateOfferItem, updateSelectedProduct]
  );

  const handleRemoveProduct = useCallback(
    async (item: ProductSearchResult) => {
      return updateSelectedProduct?.(item, false);
    },
    [updateSelectedProduct]
  );

  async function handleUpdateFilters(
    sort: string,
    filters: SearchAttributeFilter[],
    priceFilter: PriceFilter
  ): Promise<any> {
    handlePaginationUpdate(defaultPagination, toStandardSort(sort));
    setFilters(filters);
    setPriceFilter(priceFilter);
  }

  function renderList(items: ProductSearchResult[], itemsAreCustom: boolean) {
    if (!items.length) return null;

    if (isMobile) {
      return (
        <Box maxWidth="100%" width="100%">
          <MemoProductList
            items={items}
            offerItems={offerItems}
            selectedProducts={selectedProducts}
            handleUpdateQuantity={updateOfferItem}
            handleIncreaseQuantity={handleIncreaseQuantity}
            handleRemoveProduct={handleRemoveProduct}
            productModal={offerItemModal ?? productModal}
            actionIsCheckbox={!updateOfferItem}
            hideAction={!updateOfferItem && !isAccessoryList}
          />
        </Box>
      );
    }

    if (searchingProducts) return null;
    return (
      <Box maxWidth="100%" width="100%">
        <ProductTable
          data={items.map((item) => ({
            item,
            offerItem: offerItems.find(
              (oi) => oi.product?.productVersionId === item.version.id
            ),
            selected: selectedProducts.some(
              (oi) =>
                oi.product.id === item.product.id &&
                oi.version.id === item.version.id
            ),
          }))}
          onEdit={async ({ item }) => {
            (offerItemModal ?? productModal).open(item);
          }}
          pageCount={
            itemsAreCustom
              ? undefined
              : Math.ceil(
                  (products?.searchProducts.totalProductVersions ?? 0) /
                    defaultPagination.rowsPerPage
                )
          }
          onPaginationUpdate={
            itemsAreCustom
              ? undefined
              : (page: number) => {
                  handlePaginationUpdate({ ...defaultPagination, page }, sort);
                }
          }
          page={itemsAreCustom ? undefined : pagination.page + 1}
          sort={itemsAreCustom ? undefined : graphQLPagination.sort}
          onSortUpdate={
            itemsAreCustom
              ? undefined
              : (newSort: string) => {
                  handlePaginationUpdate(
                    defaultPagination,
                    toStandardSort(newSort)
                  );
                }
          }
          currency={offer?.currency ?? company.currency}
          handleUpdateQuantity={updateOfferItem}
          handleIncreaseQuantity={handleIncreaseQuantity}
          handleRemoveProduct={handleRemoveProduct}
          actionColumnWithCheckbox={!updateOfferItem}
          hideActionColumn={!updateOfferItem && !isAccessoryList}
        />
      </Box>
    );
  }

  return (
    <>
      <Box display="flex" width="100%">
        {!isMobile && showFilterPanel && (
          <Box flex={1} minWidth={340} className={localClasses.filtersColumn}>
            <DesktopProductFilterSection
              filters={filters}
              priceFilter={priceFilter}
              possibleFilters={
                products?.searchProducts.attributesWithCount ?? []
              }
              priceHistogram={products?.searchProducts.priceHistogram ?? []}
              priceBoundaries={products?.searchProducts.priceBoundaries}
              updateFilters={(
                filters: SearchAttributeFilter[],
                priceFilter: PriceFilter
              ) => {
                handlePaginationUpdate(defaultPagination, sort);
                setFilters(filters);
                setPriceFilter(priceFilter);
              }}
            />
          </Box>
        )}

        <Box flex={3} maxWidth="100%">
          <Box
            display="flex"
            width="100%"
            marginY={2}
            borderLeft={
              !isMobile && showFilterPanel ? '1px solid #EBE6E3' : undefined
            }
          >
            {!isMobile && (
              <Box marginLeft={1}>
                <Button
                  aria-label="open filter panel"
                  variant="text"
                  onClick={() => setShowFilterPanel((it) => !it)}
                  className={classes.grayTextButton}
                >
                  Filters
                  {showFilterPanel ? <ChevronLeft /> : <ChevronRight />}
                </Button>
              </Box>
            )}

            <Box
              marginLeft={isMobile ? 0 : 1}
              marginRight={1}
              flex={1}
              maxWidth={isMobile ? undefined : 256}
            >
              <TextField
                fullWidth
                placeholder="Search by name or code"
                variant="outlined"
                margin="none"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: isMobile ? (
                    <InputAdornment
                      className={classes.pointer}
                      position="end"
                      onClick={() =>
                        filterModal.open({
                          searchString,
                          sort: graphQLPagination.sort,
                          filters,
                          priceFilter,
                          possibleFilters:
                            products?.searchProducts.attributesWithCount ?? [],
                          priceHistogram:
                            products?.searchProducts.priceHistogram ?? [],
                          priceBoundaries:
                            products?.searchProducts.priceBoundaries,
                        })
                      }
                    >
                      <Badge
                        color="secondary"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        badgeContent={filters.length}
                      >
                        <FilterIcon className={localClasses.filterIcon} />
                      </Badge>
                    </InputAdornment>
                  ) : (
                    searchString && (
                      <InputAdornment
                        position="end"
                        onClick={() => setSearchString('')}
                      >
                        <CloseIcon cursor={'pointer'} />
                      </InputAdornment>
                    )
                  ),
                }}
                value={searchString}
                onChange={({ target: { value } }) => {
                  setSearchString(value);
                  handlePaginationUpdate(
                    { ...defaultPagination, page: 0 },
                    sort
                  );
                  if (value === '' && searchString !== '' && filters.length) {
                    setFilters([]);
                  }
                }}
              />
            </Box>
            {extraButtons || (!isMobile && <Box flex={1} />)}
            <Button
              className={clsx(isMobile && classes.iconButton)}
              variant="contained"
              color="primary"
              onClick={() => {
                productModal.open(undefined);
              }}
            >
              <Box display="flex" marginRight={isMobile ? 0 : 1}>
                <AddIcon />
              </Box>
              {!isMobile && `New ${isAccessoryList ? 'Accessory' : 'Product'}`}
            </Button>
          </Box>

          {!isMobile && !!filters.length && (
            <Box
              display="flex"
              alignItems="center"
              marginLeft={2}
              marginTop={2}
              marginBottom={2}
            >
              <Box marginRight={2}>
                <FilterIcon className={localClasses.filterIcon} />
              </Box>
              {filters.map((filter) => (
                <Box
                  key={`${filter.name}${filter.type}${filter.value}${filter.unit}`}
                  marginRight={2}
                  display="flex"
                  alignItems="center"
                >
                  {filter.name}: {filter.value} {filter.unit}
                  <Button
                    className={localClasses.clearFilterButton}
                    onClick={() => {
                      handlePaginationUpdate(defaultPagination, sort);
                      setFilters((oldFilters) =>
                        oldFilters.filter((it) => !areFiltersEqual(it, filter))
                      );
                    }}
                  >
                    <Close />
                  </Button>
                </Box>
              ))}
              <Button
                aria-label="clear filters"
                variant="text"
                onClick={() => {
                  handlePaginationUpdate(defaultPagination, sort);
                  setFilters([]);
                  setPriceFilter([undefined, undefined]);
                  setShowFilterPanel(false);
                }}
                className={classes.grayTextButton}
              >
                Clear all
              </Button>
            </Box>
          )}

          {searchString !== '' && (
            <Box width="100%">
              <Box className={localClasses.searchFeedbackText}>
                {`${products?.searchProducts.totalProductVersions} products found for "${searchString}"`}
              </Box>
              <Box marginX={-2}>
                <Divider />
              </Box>
            </Box>
          )}

          {searchingProducts && (isMobile || !searchResults.length) ? (
            <Box
              width={isMobile ? '100vw' : '100%'}
              height="50vh"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            !searchResults.length && (
              <Box width="100%">
                <Box className={localClasses.searchFeedbackText}>
                  No products found.
                </Box>
              </Box>
            )
          )}

          {renderList(searchResults, false)}

          {!!customItems.length && (
            <>
              <Box
                display="flex"
                alignItems="center"
                marginTop={4}
                width="100%"
              >
                <Box flex={1} className={classes.sectionHeading}>
                  Custom Products
                </Box>
              </Box>

              {renderList(customItems, true)}
            </>
          )}
        </Box>
      </Box>

      <Box marginBottom={10} />

      {isMobile && (
        <ProductFilterModal
          control={filterModal}
          updateFilters={handleUpdateFilters}
        />
      )}
    </>
  );
};

export default ProductList;
