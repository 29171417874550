export var en = {
    global: {
        offerId: 'Offer ID: {{id}}',
        vatId: 'VAT ID: {{id}}',
        validity: 'Validity: {{validity}}',
    },
    product: {
        accessoryFor: "Accessory for '{{parent}}'",
        specifications: 'Technical specifications',
        bundleProducts: 'Products',
        description: 'Description',
        quantity: 'Qty.',
        total: 'TOTAL',
        listPrice: 'List price',
        discount: 'Disc. {{discount}}',
        addonDiscount: 'Disc. {{addon}} {{discount}}',
        additionalDiscount: 'Additional Disc. {{discount}}',
        netPrice: 'Net price',
        portrait: {
            code: 'Product ID / SKU: {{code}}',
        },
    },
    addons: {
        title: 'Extra Services',
        total: 'Total Extra Services',
        listPrice: 'List price',
        netPrice: 'Net price',
    },
    lastPage: {
        listPrice: 'List Price (without extra services)',
        productAddonsTotal: 'Product Extra Services Total',
        addonsTotal: 'Total Extra Services',
        totalListPrice: 'Total List Price',
        cumulativeDiscount: 'Cumulative Discount',
        additionalDiscount: 'Additional Discount ({{discount}})',
        grandTotal: 'GRAND TOTAL:',
    },
};
