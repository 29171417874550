export type AttributeType = string;

export const getAttributeTypeName = (attributeType: AttributeType): string => {
  switch (attributeType) {
    case 'TEXT':
      return 'Text';
    case 'NUMBER':
      return 'Number';
    case 'BOOLEAN':
      return 'Yes or No';
  }
  return attributeType;
};
export const attributeTypeToDVKType = (
  attributeType: AttributeType
): string => {
  switch (attributeType) {
    case 'TEXT':
      return 'text';
    case 'NUMBER':
      return 'number';
    case 'BOOLEAN':
      return 'checkbox';
  }
  return attributeType;
};
