import { Chip, Tooltip } from '@material-ui/core';
import { ChipProps } from '@material-ui/core/Chip';
import React, { FC } from 'react';

type Status =
  // User invitation
  | 'EXPIRED'
  // subscription
  | 'trialing'
  | 'active'
  | 'past_due'
  | 'incomplete'
  | 'canceled'
  | 'no_subscription'
  // misc
  | 'WIP';

const STATUSES = {
  EXPIRED: {
    label: 'Expired',
    description: 'This is no longer valid.',
    color: 'secondary',
  },
  trialing: {
    label: 'Trial',
    description: 'You subscription is in trial mode.',
    color: 'primary',
  },
  active: {
    label: 'Active',
    description: 'Your subscription is active.',
    color: 'primary',
  },
  past_due: {
    label: 'Past due',
    description: 'You need to pay to continue using OptiOffer.',
    color: 'secondary',
  },
  incomplete: {
    label: 'Incomplete',
    description:
      'Please finish the payment process to continue using OptiOffer.',
    color: 'secondary',
  },
  canceled: {
    label: 'Canceled',
    description:
      'Last active plan ended. Please chose a new plan to continue using OptiOffer.',
    color: 'secondary',
  },
  no_subscription: {
    label: 'No subscription',
    description: 'No subscription links to this account',
    color: 'secondary',
  },
  WIP: {
    label: 'W.I.P.',
    description:
      "We are actively working on this feature and it's not complete yet.",
    color: 'primary',
  },
};

type StatusChipProps = {
  status: Status;
};

const StatusChip: FC<StatusChipProps> = ({ status }) => {
  const statusObject = STATUSES[status];
  if (!statusObject) return <>{status}</>;

  return (
    <Tooltip
      title={statusObject.description}
      aria-label={`${statusObject.label} status`}
      placement="right"
    >
      <Chip
        label={statusObject.label}
        color={statusObject.color as ChipProps['color']}
      />
    </Tooltip>
  );
};

export default StatusChip;
