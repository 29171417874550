import { Paper, Typography } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import useStyles from './styles';

export type SimpleCardProps = {
  label?: string;
  className?: string;
} & PaperProps;

const SimpleCard = forwardRef<unknown, SimpleCardProps>(
  ({ children, label, className, onClick, ...rest }, ref) => {
    const { simpleCard, clickableCard } = useStyles();

    return (
      <Paper
        ref={ref}
        className={clsx(simpleCard, className, onClick && clickableCard)}
        onClick={onClick}
        {...rest}
      >
        {label && <Typography variant="h5">{label}</Typography>}
        {children}
      </Paper>
    );
  }
);

export default SimpleCard;
