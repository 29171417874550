import { SelectProps, TextFieldProps } from '@material-ui/core';
import { AvatarProps } from '@material-ui/core/Avatar/Avatar';
import { CheckboxProps } from '@material-ui/core/Checkbox/Checkbox';
import { AutocompleteProps } from '@material-ui/lab';
import { FormikValues, useFormik } from 'formik';
import { FormikConfig } from 'formik/dist/types';

import { ButtonSwitchInputProps } from '@components/form/ButtonSwitchInput';
import { CountrySelectorProps } from '@components/form/CountrySelector';
import { ListInputProps } from '@components/form/ListInput';
import { MediaInputProps } from '@components/form/MediaInput';
import { RangeInputProps } from '@components/form/RangeInput';
import { SelectAndTextInputProps } from '@components/form/SelectAndTextInput';
import { SelectInputProps } from '@components/form/SelectInput';

export * from './errors';

class UseFormikWrapper<T> {
  // wrapped has no explicit return type so we can infer it
  wrapped(config: FormikConfig<T>) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useFormik<T>(config);
  }
}
export type Formik<Values extends FormikValues> = ReturnType<
  UseFormikWrapper<Values>['wrapped']
>;

export function mapFormikToTextField<Values extends FormikValues>(
  formik: Formik<Values>,
  fieldName: string
): Partial<TextFieldProps> {
  return {
    ...formik.getFieldProps(fieldName),
    error: formik.touched[fieldName] && !!formik.errors[fieldName],
    helperText: formik.touched[fieldName] && formik.errors[fieldName],
  };
}

export function mapFormikToAutocomplete<Values extends FormikValues>(
  formik: Formik<Values>,
  fieldName: string
): Partial<AutocompleteProps<any, any, any, any>> {
  return {
    ...formik.getFieldProps(fieldName),
    onChange: (event, newValue) => {
      formik.setFieldValue(fieldName, newValue);
    },
  };
}

export function mapFormikToSelectField<Values extends FormikValues>(
  formik: Formik<Values>,
  fieldName: string
): Partial<SelectInputProps> {
  return {
    ...formik.getFieldProps(fieldName),
    error: formik.touched[fieldName] && !!formik.errors[fieldName],
    helperText: formik.touched[fieldName] && formik.errors[fieldName],
  };
}

export function mapFormikToSelectAndTextField<Values extends FormikValues>(
  formik: Formik<Values>,
  selectFieldName: string,
  textFieldName: string,
  otherSelectProps: Partial<SelectProps>,
  otherTextProps: Partial<TextFieldProps>
): Partial<SelectAndTextInputProps> {
  return {
    select: {
      ...formik.getFieldProps(selectFieldName),
      ...otherSelectProps,
    },
    text: {
      ...formik.getFieldProps(textFieldName),
      ...otherTextProps,
    },
    error:
      (formik.touched[selectFieldName] && !!formik.errors[selectFieldName]) ||
      (formik.touched[textFieldName] && !!formik.errors[textFieldName]),
    helperText:
      (formik.touched[selectFieldName] && formik.errors[selectFieldName]) ||
      (formik.touched[textFieldName] && formik.errors[textFieldName]),
  };
}

export function mapFormikToRangeInput<Values extends FormikValues>(
  formik: Formik<Values>,
  fieldName: string
): Partial<RangeInputProps> {
  return {
    id: fieldName,
    name: fieldName,
    value: formik.getFieldProps(fieldName).value,
    onChange: ((event: any, newValue: number | number[]) =>
      formik.setFieldValue(fieldName, newValue)) as any,
  };
}

export function mapFormikToCheckBoxInput<Values extends FormikValues>(
  formik: Formik<Values>,
  fieldName: string
): Partial<CheckboxProps> {
  return {
    ...formik.getFieldProps(fieldName),
    checked: formik.getFieldProps(fieldName).value,
  };
}

export function mapFormikToListInput<Values extends FormikValues, T = any>(
  formik: Formik<Values>,
  fieldName: string
): Partial<ListInputProps<T>> & Pick<ListInputProps<T>, 'value' | 'onChange'> {
  return {
    ...formik.getFieldProps(fieldName),
    onChange: (newValue) => {
      formik.setFieldValue(fieldName, newValue);
    },
  };
}

export function mapFormikToImgField<Values extends FormikValues>(
  formik: Formik<Values>,
  fieldName: string
): Partial<AvatarProps> {
  return {
    ...formik.getFieldProps(fieldName),
    src: formik.getFieldProps(fieldName).value?.thumbnail,
  };
}

export function mapFormikToButtonSwitchField<Values extends FormikValues>(
  formik: Formik<Values>,
  fieldName: string
): Partial<ButtonSwitchInputProps> {
  return {
    ...formik.getFieldProps(fieldName),
    onChange: (newValue) => {
      formik.setFieldValue(fieldName, newValue);
    },
    error: formik.touched[fieldName] && !!formik.errors[fieldName],
    helperText: formik.touched[fieldName] && formik.errors[fieldName],
  };
}

export function mapFormikToCountrySelector<Values extends FormikValues>(
  formik: Formik<Values>,
  fieldName: string
): Partial<CountrySelectorProps> {
  return {
    value: formik.getFieldProps(fieldName).value ?? null,
    onCountrySelected: (newValue) => {
      formik.setFieldValue(fieldName, newValue);
    },
    // error: formik.touched[fieldName] && !!formik.errors[fieldName],
    // helperText: formik.touched[fieldName] && formik.errors[fieldName],
  };
}

export function mapFormikToMediaInput<Values extends FormikValues>(
  formik: Formik<Values>,
  fieldName: string
): Pick<MediaInputProps, 'value' | 'onChange'> {
  return {
    value: formik.getFieldProps(fieldName).value ?? [],
    onChange: (newValue) => {
      formik.setFieldValue(fieldName, newValue);
    },
  };
}
