import { Document } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import { OfferPrintContext, pdfIf } from '../helpers';
import AddonsPage from './Addons';
import IntroPage from './IntroPage';
import TotalPage from './TotalPage';
import { BasicLayoutVariant } from './index';
import CompactOfferItems from './items/compactLandscape/OfferItems';
import DetailedOfferItems from './items/detailedPortrait/OfferItems';
var FullOffer = function (_a) {
    var variant = _a.variant;
    var addons = useContext(OfferPrintContext).offer.addons;
    function renderVariant() {
        switch (variant) {
            case BasicLayoutVariant.COMPACT:
                return React.createElement(CompactOfferItems, null);
            case BasicLayoutVariant.DETAILED:
                return React.createElement(DetailedOfferItems, null);
            default:
                return React.createElement(React.Fragment, null);
        }
    }
    return (React.createElement(Document, null,
        React.createElement(IntroPage, null),
        renderVariant(),
        pdfIf(addons.length > 2, function () { return (React.createElement(AddonsPage, null)); }),
        React.createElement(TotalPage, null)));
};
export default FullOffer;
