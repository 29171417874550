import React, { FC } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import ActionCenterListPage from './ListPage';

const VirtualAssistantPage: FC<RouteComponentProps> = ({ match }) => {
  return (
    <>
      <Route exact path={match.path} component={ActionCenterListPage} />
    </>
  );
};

export default VirtualAssistantPage;
