import { useIsMobile } from '@lib';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { FC } from 'react';

export const InstagramIcon = () => (
  <svg
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
    />
  </svg>
);

export const FacebookIcon = () => (
  <svg
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
    />
  </svg>
);

export const TwitterIcon = () => (
  <svg
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
    />
  </svg>
);

export const LinkedinIcon = () => (
  <svg
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
    />
  </svg>
);

export const YoutubeIcon = () => (
  <svg
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
    />
  </svg>
);

export const WhatsAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    aria-labelledby="title"
    aria-describedby="desc"
    role="img"
    height="24"
    width="24"
  >
    <path
      data-name="layer2"
      d="M30.287 2.029A29.769 29.769 0 0 0 5.223 45.266L2.064 60.6a1.158 1.158 0 0 0 1.4 1.361L18.492 58.4A29.76 29.76 0 1 0 30.287 2.029zm17.931 46.2"
      fill="#0F5C52"
      stroke="#FFFFFF"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4"
    />
    <path
      data-name="layer1"
      d="M46.184 38.205l-5.765-1.655a2.149 2.149 0 0 0-2.126.561l-1.41 1.436a2.1 2.1 0 0 1-2.283.482c-2.727-1.1-8.463-6.2-9.927-8.754a2.1 2.1 0 0 1 .166-2.328l1.23-1.592a2.148 2.148 0 0 0 .265-2.183l-2.424-5.485a2.149 2.149 0 0 0-3.356-.769c-1.609 1.361-3.517 3.428-3.749 5.719-.409 4.039 1.323 9.13 7.872 15.242 7.566 7.063 13.626 8 17.571 7.04 2.238-.542 4.026-2.714 5.154-4.493a2.15 2.15 0 0 0-1.218-3.221z"
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

export const SmartBillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="26"
    viewBox="0 0 32 35"
  >
    <g transform="translate(0 59.745)">
      <g transform="translate(43.579 -51.803)" />
      <g transform="translate(0 -59.745)">
        <path
          className="a"
          fill="currentColor"
          d="M31.093-51.678l-4.161-4.161a13.342,13.342,0,0,0-18.865,0L3.906-51.678a13.342,13.342,0,0,0,0,18.865l4.161,4.162a13.342,13.342,0,0,0,18.865,0l4.161-4.162A13.342,13.342,0,0,0,31.093-51.678ZM15.151-45.772l.76.864,1.042.479,1.341.014-.042,3.444L19.687-44.1l1.389.637-1.442,3.143,2.6-2.282L23.24-41.45l-2.6,2.282,3.3-1.029.455,1.46-3.3,1.029,3.433.4-.178,1.516-3.434-.4,2.975,1.761-.781,1.316-2.975-1.764,2,2.821-1.246.882-2-2.817.679,3.388-1.5.3-.676-3.388-.759,3.374-1.491-.336L15.9-34.03l-2.065,2.772-1.228-.914,2.068-2.772L11.662-33.25l-.746-1.333,3.014-1.694-3.444.323-.14-1.522,4.329-.4.122,1.333.563,1,.924.686,1.12.252,1.127-.224.938-.669.588-.987.132-1.144-.342-1.1-.76-.864-1.042-.479-1.341-.014.042-3.444L15.309-40.4l-1.386-.638,1.442-3.139-2.6,2.279L11.76-43.046l2.6-2.282-3.3,1.029L10.6-45.759l3.3-1.029-3.433-.4.178-1.519,3.434.4-2.975-1.764.781-1.316,2.971,1.764L12.862-52.44l1.246-.883,2,2.821-.679-3.391,1.5-.3L17.6-50.8l.759-3.374,1.491.336L19.1-50.466l2.065-2.772,1.225.913-2.065,2.773,3.013-1.694.749,1.334L21.07-48.219l3.441-.322.143,1.522-4.329.4L20.2-47.95l-.563-1-.924-.689-1.12-.253-1.127.227L15.529-49l-.588.988-.132,1.141Z"
          transform="translate(0 59.745)"
        />
      </g>
    </g>
  </svg>
);

export const GoogleDriveIcon = () => (
  <svg height="24" viewBox="0 0 87.3 78" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z"
      fill="#0066da"
    />
    <path
      d="m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z"
      fill="#00ac47"
    />
    <path
      d="m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z"
      fill="#ea4335"
    />
    <path
      d="m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z"
      fill="#00832d"
    />
    <path
      d="m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z"
      fill="#2684fc"
    />
    <path
      d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z"
      fill="#ffba00"
    />
  </svg>
);

export const GoogleMailIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="52 42 88 66"
    height="18"
    width="18"
    className={className}
  >
    <path fill="#4285f4" d="M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6" />
    <path fill="#34a853" d="M120 108h14c3.32 0 6-2.69 6-6V59l-20 15" />
    <path fill="#fbbc04" d="M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2" />
    <path fill="#ea4335" d="M72 74V48l24 18 24-18v26L96 92" />
    <path
      fill="#c5221f"
      d="M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2"
    />
  </svg>
);

export const YahooMailIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 3386.34 3010.5"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    height="18"
    width="18"
    className={className}
  >
    <path
      d="M0 732.88h645.84l376.07 962.1 380.96-962.1h628.76l-946.8 2277.62H451.98l259.19-603.53L.02 732.88zm2763.84 768.75h-704.26L2684.65 0l701.69.03-622.5 1501.6zm-519.78 143.72c216.09 0 391.25 175.17 391.25 391.22 0 216.06-175.16 391.23-391.25 391.23-216.06 0-391.19-175.17-391.19-391.23 0-216.05 175.16-391.22 391.19-391.22z"
      fill="#5f01d1"
      fillRule="nonzero"
    />
  </svg>
);

export const FilterIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="23"
    viewBox="0 0 22 23"
    className={className}
  >
    <defs>
      <clipPath id="clip-FilterIcon">
        <rect width="22" height="23" />
      </clipPath>
    </defs>
    <g id="FilterIcon" data-name="Filter Icon" clipPath="url(#clip-FilterIcon)">
      <path
        id="Icon_awesome-filter"
        data-name="Icon awesome-filter"
        d="M17.155,0H.845a.844.844,0,0,0-.6,1.44l6.5,6.5v7.244a.844.844,0,0,0,.36.691l2.812,1.968a.844.844,0,0,0,1.328-.691V7.943l6.5-6.5A.844.844,0,0,0,17.155,0Z"
        transform="translate(2 3)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const CustomerStepIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.488"
    height="16.124"
    viewBox="0 0 14.488 16.124"
    className={className}
  >
    <g
      id="CustomerStepIcon"
      data-name="CustomerStepIcon"
      transform="translate(0.7 0.7)"
    >
      <path
        id="Path_551"
        data-name="Path 551"
        d="M19.088,27.408V25.772A3.272,3.272,0,0,0,15.816,22.5H9.272A3.272,3.272,0,0,0,6,25.772v1.636"
        transform="translate(-6 -12.684)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Path_552"
        data-name="Path 552"
        d="M18.544,7.772A3.272,3.272,0,1,1,15.272,4.5,3.272,3.272,0,0,1,18.544,7.772Z"
        transform="translate(-8.728 -4.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </g>
  </svg>
);

export const ProductsStepIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.167"
    height="17.815"
    viewBox="0 0 16.167 17.815"
    className={className}
  >
    <g
      id="ProductsStepIcon"
      data-name="ProductsStepIcon"
      transform="translate(-3.764 -2.279)"
    >
      <path
        id="Path_545"
        data-name="Path 545"
        d="M18.6,10.552,11.25,6.315"
        transform="translate(-3.076 -1.509)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Path_546"
        data-name="Path 546"
        d="M19.195,14.431V7.9a1.633,1.633,0,0,0-.816-1.412L12.664,3.222a1.633,1.633,0,0,0-1.633,0L5.316,6.487A1.633,1.633,0,0,0,4.5,7.9v6.531a1.633,1.633,0,0,0,.816,1.412l5.715,3.265a1.633,1.633,0,0,0,1.633,0l5.715-3.265a1.633,1.633,0,0,0,.816-1.412Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Path_547"
        data-name="Path 547"
        d="M4.905,10.44l7.127,4.123,7.127-4.123"
        transform="translate(-0.185 -3.389)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Path_548"
        data-name="Path 548"
        d="M18,26.229V18"
        transform="translate(-6.153 -6.835)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </g>
  </svg>
);

export const ReviewStepIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="15.889"
    viewBox="0 0 13 15.889"
    className={className}
  >
    <g
      id="ReviewStepIcon"
      data-name="ReviewStepIcon"
      transform="translate(-6 -2)"
    >
      <path
        id="Path_540"
        data-name="Path 540"
        d="M16.662,22.967l-1.444-1.408a.758.758,0,0,1-.072-.975.686.686,0,0,1,1.083-.072l.939.939,3.106-3.106a.722.722,0,0,1,1.011,1.011l-3.611,3.611a.686.686,0,0,1-1.011,0Z"
        transform="translate(-5.751 -10.35)"
        fill="currentColor"
      />
      <path
        id="Path_541"
        data-name="Path 541"
        d="M18.278,3.444H16.833V2.722A.722.722,0,0,0,16.111,2H8.889a.722.722,0,0,0-.722.722v.722H6.722A.722.722,0,0,0,6,4.167v13a.722.722,0,0,0,.722.722H18.278A.722.722,0,0,0,19,17.167v-13A.722.722,0,0,0,18.278,3.444Zm-.722,13H7.444V4.889H9.611V3.444h5.778V4.889h2.167Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const SendStepIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.385"
    height="16.385"
    viewBox="0 0 16.385 16.385"
    className={className}
  >
    <g
      id="SendStepIcon"
      data-name="SendStepIcon"
      transform="translate(-168.3 -184.01)"
    >
      <path
        id="Path_549"
        data-name="Path 549"
        d="M24.582,3,16.5,11.082"
        transform="translate(159.113 182)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Path_550"
        data-name="Path 550"
        d="M17.695,3,12.551,17.695,9.613,11.082,3,8.143Z"
        transform="translate(166 182)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </g>
  </svg>
);

export const StepDoneIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.09"
    height="15.09"
    viewBox="0 0 15.09 15.09"
    className={className}
  >
    <g
      id="Group_849"
      data-name="Group 849"
      transform="translate(-78.555 -21.636)"
    >
      <ellipse
        id="Ellipse_367"
        data-name="Ellipse 367"
        cx="7.201"
        cy="6.701"
        rx="7.201"
        ry="6.701"
        transform="translate(79.196 22.299)"
        fill="#fff"
      />
      <path
        id="Icon_awesome-check-circle"
        data-name="Icon awesome-check-circle"
        d="M15.652,8.108A7.545,7.545,0,1,1,8.107.563,7.545,7.545,0,0,1,15.652,8.108Zm-8.418,4,5.6-5.6a.487.487,0,0,0,0-.688l-.688-.688a.487.487,0,0,0-.688,0L6.89,9.693,4.759,7.562a.487.487,0,0,0-.688,0l-.688.688a.487.487,0,0,0,0,.688L6.546,12.1a.487.487,0,0,0,.688,0Z"
        transform="translate(77.992 21.073)"
        fill="#057869"
      />
    </g>
  </svg>
);

export const SpecificationTextIcon: FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="27"
    viewBox="0 0 11 27"
    className={className}
  >
    <text
      id="T"
      transform="translate(0 20)"
      fill="currentColor"
      fontSize="20"
      fontFamily="SegoeUI, Segoe UI"
    >
      <tspan x="0" y="0">
        T
      </tspan>
    </text>
  </svg>
);

export const SpecificationNumberIcon: FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    viewBox="0 0 17 19"
    className={className}
  >
    <text
      id="_01"
      data-name="01"
      transform="translate(0 15)"
      fill="currentColor"
      fontSize="14"
      fontFamily="RobotoMono-Medium, Roboto Mono"
      fontWeight="500"
    >
      <tspan x="0" y="0">
        01
      </tspan>
    </text>
  </svg>
);

export const SpecificationBooleanIcon: FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.259"
    height="18"
    viewBox="0 0 21.259 18"
    className={className}
  >
    <path
      d="M5661.421-8637.17l2.391,2.391,1.345-1.345,2.24-2.241"
      transform="translate(-5660.36 8646.571)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <g transform="translate(15.617 7.307)">
      <path
        d="M13.222,12.651l2.032-2.013a.4.4,0,0,0,.038-.518.364.364,0,0,0-.575-.038l-2.032,2.032-2.032-2.032a.364.364,0,0,0-.575.038.4.4,0,0,0,.038.518l2.032,2.013-2.032,2.013a.4.4,0,0,0-.038.518.364.364,0,0,0,.575.038l2.032-2.032,2.032,2.032a.364.364,0,0,0,.575-.038.4.4,0,0,0-.038-.518Z"
        transform="translate(-10.001 -9.959)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </g>
    <line
      y2="18"
      transform="translate(11.327)"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
    />
  </svg>
);

export const SpecificationIcon: FC<{
  type: 'TEXT' | 'NUMBER' | 'BOOLEAN';
  className?: string;
}> = ({ type, className }) => {
  switch (type) {
    case 'TEXT':
      return <SpecificationTextIcon className={className} />;
    case 'NUMBER':
      return <SpecificationNumberIcon className={className} />;
    case 'BOOLEAN':
      return <SpecificationBooleanIcon className={className} />;
    default:
      return null;
  }
};

export const OpenRowIcon: FC = () => {
  const isMobile = useIsMobile();

  return isMobile ? <ChevronRightIcon /> : <MoreVertIcon />;
};

export const AddProductImageIcon: FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    className={className}
  >
    <g>
      <rect width="29" height="29" fill="none" />
    </g>
    <g transform="translate(1.175 4.318)">
      <path
        d="M26.682,6H3.175A1.175,1.175,0,0,0,2,7.175V25.981a1.175,1.175,0,0,0,1.175,1.175H26.682a1.175,1.175,0,0,0,1.175-1.175V7.175A1.175,1.175,0,0,0,26.682,6ZM25.507,24.806H4.351V20.574l4.7-4.7L15.281,22.1a1.117,1.117,0,0,0,1.645,0l3.879-3.879,4.7,4.7Zm0-5.23-3.879-3.82a1.117,1.117,0,0,0-1.645,0L16.1,19.575,9.875,13.4a1.117,1.117,0,0,0-1.645,0l-3.879,3.82V8.351H25.507Z"
        transform="translate(-2 -6)"
        fill="#9bc9c3"
      />
    </g>
  </svg>
);
