import { makeStyles } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  //region MenuPage
  settingsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(0, -2),
  },
  //endregion

  sectionCard: {
    marginTop: '2em',
  },

  //region QuotasSection
  quotasContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  quotaContainer: {
    margin: theme.spacing(1),

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > :first-child': {
      marginBottom: -40,
    },
  },
  //endregion

  //region PlanBox
  planCard: {
    minWidth: '250px',
    margin: theme.spacing(1),
  },
  plansContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(0, -1),
  },
  planActive: {
    border: '2px solid #FAC05F',
  },
  planDescriptionItem: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing(2),
    },
  },
  //endregion

  //region PlansSection
  changePlanContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  currentPlanContainer: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  message: {
    marginLeft: 24,
    display: 'flex',
    alignItems: 'center',

    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  errorMessage: {
    border: `1px solid ${red['500']}`,
    borderRadius: 4,
    padding: theme.spacing(0.5),

    '& > svg': {
      color: red['500'],
    },
  },
  okMessage: {
    '& > svg': {
      color: green['500'],
    },
  },
  warningMessage: {
    '& > svg': {
      color: yellow['500'],
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  paymentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'bottom',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    '& > div:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  priceBreakdown: {
    margin: theme.spacing(2, 0),
  },
  //endregion

  //region TaxIdSection
  taxIdSection: {
    width: 300,
  },
  saveButton: {
    marginBottom: theme.spacing(1),
  },
  taxIdField: {
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginBottom: theme.spacing(1),
  },
  //endregion

  //region UsersSection
  addUserButton: {
    marginLeft: theme.spacing(2),
  },
  //endregion

  //region TaxSection
  addTaxButton: {
    marginLeft: theme.spacing(2),
  },
  //endregion

  //#region DataManagementSection
  deleteAllButton: {
    backgroundColor: 'darkred',
    marginLeft: theme.spacing(2),
  },
  //#endregion

  //region external systems
  smartBillCard: {
    width: 350,
  },
  gDriveCard: {
    width: 350,
  },
  togglePlaceholderButton: {
    marginBottom: 10,
    marginLeft: 10,
  },
  helpIcon: {
    marginLeft: 10,
  },
  marginer: {
    marginLeft: theme.spacing(3),
    color: '#414141',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    fontSize: 14,
  },
  button: {
    marginLeft: theme.spacing(2),
    backgroundColor: '#e8624f',
    color: 'white',
  },
  tableMargin: {
    padding: theme.spacing(2),
  },
  pointer: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  card: {
    minWidth: 400,
    maxWidth: 440,
  },
  connectedMessage: {
    marginBottom: theme.spacing(4),
  },
  connectButton: {
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(1),
  },
  socialIcon: {
    marginRight: theme.spacing(2),
  },
  header: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
  },
  bold: {
    fontWeight: 'bold',
  },
  container: {},
  //endregion
}));
