var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Font, Text } from '@react-pdf/renderer';
import React from 'react';
export function registerFonts(_a) {
    var roboto = _a.roboto, materialIcons = _a.materialIcons;
    Font.registerEmojiSource({
        format: 'png',
        url: 'https://twemoji.maxcdn.com/2/72x72/',
    });
    Font.register({
        family: 'Roboto',
        fonts: [
            { src: roboto.normal },
            { src: roboto.bold, fontWeight: 700 }, // normal, bold
        ],
    });
    Font.register({
        family: 'Material Icons',
        src: materialIcons,
    });
}
export var MUIIcon = function (_a) {
    var name = _a.name, style = _a.style, rest = __rest(_a, ["name", "style"]);
    var muiIconStyle = {
        fontFamily: 'Material Icons',
    };
    var styles = style
        ? Array.isArray(style)
            ? __spreadArray(__spreadArray([], style), [muiIconStyle]) : [style, muiIconStyle]
        : [muiIconStyle];
    return (React.createElement(Text, __assign({}, rest, { style: styles }), name));
};
