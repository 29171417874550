import { PDFViewer } from '@react-pdf/renderer';
import React, { FC, Fragment } from 'react';

type PDFViewerProps = typeof PDFViewer.prototype['props'];

type SafePDFViewerProps = PDFViewerProps & {
  children?: undefined;
  document: PDFViewerProps['children'];
};

const SafePDFViewer: FC<SafePDFViewerProps> = ({ document, ...rest }) => {
  if (!document) return <Fragment />; // TODO loading placeholder with width and height

  return (
    <PDFViewer {...rest} key={Math.random()}>
      {document}
    </PDFViewer>
  );
};

export default React.memo(SafePDFViewer);
