import { ObservableQuery } from '@apollo/client';
import { OfferItemProductCard } from '@components';
import {
  ConfirmationModal,
  createDefaultActions,
  DVKColumn,
  DVKComboBoxFieldValue,
  DVKField,
  DVKObject,
  DVKTable,
  InputModal,
  useIncrementalKey,
  useModal,
} from '@dvkiin/material-commons';
import {
  FieldErrors,
  NOOP_graphqlErrorManagement,
  SecurityContext,
} from '@lib';
import { Button, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import AddIcon from '@material-ui/icons/Add';
import MoveIcon from '@material-ui/icons/SwapVert';
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';

import { formatPrice } from '@optioffer/core';

import { OfferItemProduct } from '../../Offer/domain';
import {
  Product,
  ProductBundle,
  ProductBundleItem,
  ProductBundleItemInput,
  ProductVersion,
} from '../domain';
import useStyles from '../styles';

const mapProductVersionToOfferItemProduct = (product: Product) => (
  version: ProductVersion
): OfferItemProduct => ({
  id: `${product.id}-${version.id}`,
  productId: product.id,
  productVersionId: version.id,
  name: product.name,
  code: version.code,
  price: version.price,
  image: product.image,
  specifications: [],
});

const mapOfferItemProductToComboBoxOption = (
  product: OfferItemProduct
): DVKComboBoxFieldValue => ({
  name: JSON.stringify({
    productId: product.productId,
    productVersionId: product.productVersionId,
  }),
  label: `[${product.code}] ${product.name}`,
  data: product,
});

const mapProductBundleItemToComboBoxOption = (
  productBundleItem: ProductBundleItem
): DVKComboBoxFieldValue =>
  mapOfferItemProductToComboBoxOption(
    mapProductVersionToOfferItemProduct(productBundleItem.product)(
      productBundleItem.productVersion
    )
  );

type ProductBundleItemWithPosition = ProductBundleItem & {
  position: number;
};

type ItemsSectionProps = {
  productBundle: ProductBundle;
  addProductBundleItem(
    productBundleItemInput: ProductBundleItemInput
  ): Promise<any>;
  removeProductBundleItem(
    productBundleItemId: ProductBundleItem['id']
  ): Promise<any>;
  moveProductBundleItem(
    productBundleItemId: ProductBundleItem['id'],
    newPosition: number
  ): Promise<any>;
  updateProductBundleItem(
    productBundleItemId: ProductBundleItem['id'],
    productBundleItemInput: ProductBundleItemInput
  ): Promise<any>;
  refetchProducts: ObservableQuery<{
    findAllProductsByFilter: Product[];
  }>['refetch'];
  updateItemInvalidFields: FieldErrors | undefined;
  addItemInvalidFields: FieldErrors | undefined;
};

const ItemsSection: FC<ItemsSectionProps> = ({
  productBundle,
  updateItemInvalidFields,
  addItemInvalidFields,
  addProductBundleItem,
  removeProductBundleItem,
  updateProductBundleItem,
  moveProductBundleItem,
  refetchProducts,
}) => {
  const {
    versionsCard,
    versionsTable,
    versionsCardContent,
    offerItemProductCard,
  } = useStyles();
  const {
    company: { currency },
  } = useContext(SecurityContext);

  const [newPosition, setNewPosition] = useState(0);
  const [position, setPosition] = useState(0);
  const [
    moveProductBundleItemKey,
    incrementMoveProductBundleItemKey,
  ] = useIncrementalKey();
  const [updateItemKey, incrementUpdateItemKey] = useIncrementalKey();
  const [addItemKey, incrementAddItemKey] = useIncrementalKey();
  const {
    isOpen: isDeleteItemModalOpen,
    open: openDeleteItemModal,
    close: closeDeleteItemModal,
    data: deleteItemModalData,
  } = useModal<ProductBundleItem>();
  const {
    isOpen: isUpdateItemModalOpen,
    open: openUpdateItemModal,
    close: closeUpdateItemModal,
    data: updateItemModalData,
  } = useModal<
    ProductBundleItem & {
      selectedProductVersion: DVKComboBoxFieldValue;
    }
  >();
  const {
    isOpen: isAddItemModalOpen,
    open: openAddItemModal,
    close: closeAddItemModal,
  } = useModal();
  const {
    isOpen: isMoveProductBundleItemModalOpen,
    open: openMoveProductBundleItemModal,
    close: closeMoveProductBundleItemModal,
    data: moveProductBundleItemModalData,
  } = useModal<ProductBundleItemWithPosition>();

  const searchProducts = useCallback(
    async (searchString: string): Promise<OfferItemProduct[]> => {
      try {
        const result = await refetchProducts({ topCount: 10, searchString });
        if (result.data && result.data.findAllProductsByFilter) {
          return result.data.findAllProductsByFilter
            .map((product) =>
              product.versions.map(mapProductVersionToOfferItemProduct(product))
            )
            .reduce((acc, it) => [...acc, ...it], []);
        }
        return [];
      } catch {
        return [];
      }
    },
    [refetchProducts]
  );
  const renderProductVersion = useCallback(
    (version: OfferItemProduct) => {
      return (
        <OfferItemProductCard
          className={offerItemProductCard}
          data={version as any}
          currency={currency.symbol}
          disableColor
        />
      );
    },
    [currency, offerItemProductCard]
  );

  const itemTableFields: DVKColumn[] = useMemo(
    () => [
      {
        name: 'productVersion.code',
        label: 'Code',
        type: 'text',
      },
      {
        name: 'product.name',
        label: 'Name',
        type: 'text',
      },
      {
        name: 'productVersion.price',
        label: 'Price',
        type: 'number',
        project: (price) => formatPrice(price, currency),
      },
      {
        name: 'quantity',
        label: 'Quantity',
        type: 'number',
      },
    ],
    [currency]
  );

  const itemFields: DVKField[] = useMemo(
    () => [
      {
        name: 'selectedProductVersion',
        label: 'Product',
        type: 'combo-box',
        required: true,
        search: async (search) =>
          searchProducts(search).then((versions) =>
            versions.map(mapOfferItemProductToComboBoxOption)
          ),
        renderOption: (value) =>
          renderProductVersion(value.data as OfferItemProduct),
      },
      { name: 'quantity', label: 'Quantity', type: 'number', required: true },
    ],
    [searchProducts, renderProductVersion]
  );

  const moveProductBundleItemFields: DVKField[] = useMemo(
    () => [
      {
        name: 'product.name',
        label: 'Name',
        type: 'text',
        disabled: true,
      },
      {
        name: 'productVersion.code',
        label: 'Code',
        type: 'text',
        disabled: true,
      },
      {
        name: 'position',
        label: 'Position',
        type: 'number',
        required: true,
      },
    ],
    []
  );

  const defaultItem: Partial<ProductBundleItem> = useMemo(
    () => ({
      quantity: 1,
    }),
    []
  );

  async function handleDeleteProductBundleItem() {
    try {
      await removeProductBundleItem(deleteItemModalData!.id);
      closeDeleteItemModal();
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleAddProductBundleItem(productBundleItem: DVKObject) {
    try {
      const { productId, productVersionId } = JSON.parse(
        productBundleItem.selectedProductVersion as string
      );
      await addProductBundleItem({
        productId,
        productVersionId,
        quantity: productBundleItem.quantity as number,
      });
      closeAddItemModal();
      incrementAddItemKey();
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleUpdateProductBundleItem(productBundleItem: DVKObject) {
    try {
      const { productId, productVersionId } = JSON.parse(
        productBundleItem.selectedProductVersion as string
      );
      await updateProductBundleItem(updateItemModalData!.id, {
        productId,
        productVersionId,
        quantity: productBundleItem.quantity as number,
      });
      closeUpdateItemModal();
      incrementUpdateItemKey();
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleMoveProductBundleItem(
    moveProductBundleItemWrapper: DVKObject
  ) {
    if (!moveProductBundleItemModalData) return;
    try {
      await moveProductBundleItem(
        moveProductBundleItemModalData.id,
        (moveProductBundleItemWrapper.position as number) - 1
      );
      incrementMoveProductBundleItemKey();
      closeMoveProductBundleItemModal();
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  return (
    <>
      <Card className={versionsCard}>
        <CardHeader
          title="Items"
          action={
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={openAddItemModal}
            >
              Add new Item
            </Button>
          }
        />
        <CardContent className={versionsCardContent}>
          <DVKTable
            className={versionsTable}
            columns={itemTableFields}
            rows={productBundle.items}
            onRowClick={(row: ProductBundleItem) =>
              openUpdateItemModal({
                ...row,
                selectedProductVersion: mapProductBundleItemToComboBoxOption(
                  row
                ).name,
              })
            }
            actions={[
              {
                name: 'move',
                label: 'Move bundle item',
                Icon: MoveIcon,
                onClick: (data: ProductBundleItem) => {
                  const currentPosition =
                    productBundle.items.findIndex((it) => it.id === data?.id) +
                    1;
                  openMoveProductBundleItemModal({
                    ...data,
                    position: currentPosition,
                  });
                  setPosition(currentPosition);
                },
              },
              ...createDefaultActions({
                onEdit: (row: ProductBundleItem) =>
                  openUpdateItemModal({
                    ...row,
                    selectedProductVersion: mapProductBundleItemToComboBoxOption(
                      row
                    ).name,
                  }),
                onDelete: openDeleteItemModal,
              }),
            ]}
          />
        </CardContent>
      </Card>
      <ConfirmationModal
        title="Confirm item delete"
        message="Are you sure you want to remove the product from the bundle?"
        open={isDeleteItemModalOpen}
        onCancel={closeDeleteItemModal}
        onAccept={handleDeleteProductBundleItem}
      />
      <InputModal
        saveLabel="Create"
        title="Add new product bundle item"
        formKey={`${addItemKey}`}
        open={isAddItemModalOpen}
        fields={itemFields}
        invalidFields={addItemInvalidFields}
        defaultValue={defaultItem as any}
        onClose={closeAddItemModal}
        onCreate={handleAddProductBundleItem}
      />
      <InputModal
        saveLabel="Save"
        title={`Edit item '${updateItemModalData?.productVersion.code}'`}
        formKey={`${updateItemKey}`}
        open={isUpdateItemModalOpen}
        fields={itemFields}
        invalidFields={updateItemInvalidFields}
        defaultValue={updateItemModalData as any}
        onClose={closeUpdateItemModal}
        onCreate={handleUpdateProductBundleItem}
      />
      <InputModal
        bottomContent={
          <Typography variant="caption">
            * Currently on {position} will be moved to {newPosition}
          </Typography>
        }
        title="Move bundle item to position"
        open={isMoveProductBundleItemModalOpen}
        fields={moveProductBundleItemFields}
        formKey={`${moveProductBundleItemKey}`}
        onClose={closeMoveProductBundleItemModal}
        defaultValue={moveProductBundleItemModalData as DVKObject | undefined}
        onCreate={handleMoveProductBundleItem}
        onChange={(data) => setNewPosition(data?.position as number)}
        saveLabel={'Move'}
      />
    </>
  );
};

export default ItemsSection;
