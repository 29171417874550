import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useLocalStorageState<S = string>(
  key: string
): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
  const [internalState, setInternalState] = useState(() => {
    try {
      const persisted = localStorage.getItem(key);
      if (persisted) return JSON.parse(persisted);
    } catch {
      // n ai csf
    }
    return undefined;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(internalState));
  }, [key, internalState]);

  return [internalState, setInternalState];
}
