import { SlideUp, useModal } from '@dvkiin/material-commons';
import { NOOP_graphqlErrorManagement } from '@lib';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import OKIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import ForwardIcon from '@material-ui/icons/Forward';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import React, { FC, useMemo, useState } from 'react';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';

import { formatDate } from '@optioffer/core';

import {
  IPaymentMethodCard,
  PaymentMethodSection,
} from '../../../components/Payment';
import SubscriptionPriceBreakdown from '../../../components/SubscriptionPriceBreakdown';
import { BillingDetails, Plan, Subscription } from '../domain';
import useStyles from '../styles';
import PlanBox from './PlanBox';
import TaxIdSection from './TaxIdSection';

type CompanyPlansSectionProps = ReactStripeElements.InjectedStripeProps & {
  availablePlans: Plan[];
  subscription: Subscription;
  defaultPaymentMethod?: IPaymentMethodCard;
  setDefaultPaymentMethod: (
    paymentMethodId: stripe.paymentMethod.PaymentMethod['id'],
    country: string,
    addressLine: string
  ) => void;
  customerName: string;
  changePlan: (planId: Plan['id']) => Promise<Subscription>;
  reloadSubscription: () => Promise<any>;
  cancelSubscription: () => void;
  reactivateSubscription: () => void;
  removePaymentMethod: () => void;
  retryPayment: () => Promise<Subscription>;
  billingDetails: BillingDetails | undefined;
  taxCountry: string | undefined;
  vatRate: { standard: number } | undefined | null;
  numberOfUsers: number | undefined;
};

const CompanyPlansSection: FC<CompanyPlansSectionProps> = ({
  availablePlans,
  subscription,
  defaultPaymentMethod,
  setDefaultPaymentMethod,
  customerName,
  changePlan,
  reloadSubscription,
  cancelSubscription,
  reactivateSubscription,
  retryPayment,
  removePaymentMethod,
  stripe,
  billingDetails,
  taxCountry,
  vatRate,
  numberOfUsers,
}) => {
  const {
    header,
    changePlanContainer,
    plansContainer,
    errorMessage,
    okMessage,
    warningMessage,
    message,
    divider,
    actionButton,
    paymentContainer,
    priceBreakdown,
  } = useStyles();

  const [showPlans, setShowPlans] = useState(false);
  const {
    isOpen: isPayModalOpen,
    open: openPayModal,
    close: closePayNodal,
    data: payModalData,
  } = useModal<Plan>();

  const currentPlan = useMemo(
    () =>
      subscription?.plan &&
      availablePlans.find((plan: Plan) => plan.id === subscription.plan.id)!,
    [availablePlans, subscription]
  );

  const canBuy = useMemo(
    () =>
      !!defaultPaymentMethod ||
      (subscription?.status === 'trialing' && payModalData?.hasTrial),
    [defaultPaymentMethod, subscription, payModalData]
  );

  async function handleBuyPlan(plan: Plan) {
    try {
      const newSubscription = await changePlan(plan.id);
      if (newSubscription.nextActionSecret)
        await handleNextAction(newSubscription.nextActionSecret);
      closePayNodal();
    } catch (_ex) {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleNextAction(nextActionSecret: string) {
    await stripe!.confirmCardPayment(nextActionSecret);
    try {
      await reloadSubscription();
    } catch {}
  }

  async function handleConfirmOrRetryPayment() {
    if (subscription?.nextActionSecret) {
      await handleNextAction(subscription?.nextActionSecret);
    } else {
      // next action failed or expired
      const newSubscription = await retryPayment();
      if (newSubscription.nextActionSecret)
        await handleNextAction(newSubscription.nextActionSecret);
    }
  }

  /**
   * Returns undefined if current subscription not scheduled to cancel
   */
  function getSubscriptionDaysLeft() {
    if (subscription?.cancelAtPeriodEnd) {
      return Math.round(
        (subscription?.currentPeriodEnd * 1000 - Date.now()) /
          (1000 * 3600 * 24)
      );
    }
  }

  function renderPlan(plan: Plan) {
    const owned =
      plan.id === subscription?.plan.id &&
      !(subscription?.status === 'canceled');
    return (
      <PlanBox
        key={plan.id}
        plan={plan}
        daysLeft={getSubscriptionDaysLeft()}
        owned={owned}
        showButton
        onButtonClick={openPayModal}
      />
    );
  }

  function renderPlanOwned(plan: Plan | undefined) {
    return (
      <PlanBox
        plan={plan}
        daysLeft={getSubscriptionDaysLeft()}
        owned={true}
        showButton
        onButtonClick={openPayModal}
      />
    );
  }

  function renderExplanation() {
    // Dzsiiiiiz 🤦‍♀️
    if (subscription?.status === 'past_due') {
      if (!!defaultPaymentMethod) {
        return (
          <>
            <Typography variant="body1" className={clsx(message, errorMessage)}>
              <ErrorIcon />
              Your last payment attempt failed. Please make sure your card is
              valid and you have enough funds. You may retry the payment by
              clicking the button below. If this persists, maybe try with
              another card.
            </Typography>
            <Button
              className={actionButton}
              variant="contained"
              color="primary"
              onClick={handleConfirmOrRetryPayment}
            >
              Retry payment
            </Button>
          </>
        );
      } else {
        return (
          <Typography variant="body1" className={clsx(message, errorMessage)}>
            <ErrorIcon />
            Your subscription is past due and you don't have a payment method
            associated. You will soon be reverted to the Free plan.
          </Typography>
        );
      }
    } else if (subscription?.status === 'trialing') {
      if (!!defaultPaymentMethod) {
        return (
          <Typography variant="body1" className={clsx(message, okMessage)}>
            <OKIcon />
            You are currently testing OptiOffer for free. We will only change
            you at the start of the next billing cycle.
          </Typography>
        );
      } else {
        return (
          <Typography variant="body1" className={clsx(message, warningMessage)}>
            <WarningIcon />
            You are currently testing OptiOffer for free. By the end of the
            billing cycle, make sure to add a payment method to continue using
            this plan.
          </Typography>
        );
      }
    } else if (subscription?.status === 'active') {
      if (subscription?.cancelAtPeriodEnd) {
        return (
          <Typography variant="body1" className={clsx(message, okMessage)}>
            <OKIcon />
            Your subscription is still active until{' '}
            {formatDate(subscription?.currentPeriodEnd * 1000)}. After the
            period ends your subscription will not be renewed and you will be
            reverted to the Free plan.
          </Typography>
        );
      }
    } else if (subscription?.status === 'incomplete') {
      if (!!defaultPaymentMethod) {
        if (subscription?.nextActionSecret) {
          return (
            <>
              <Typography
                variant="body1"
                className={clsx(message, errorMessage)}
              >
                <ErrorIcon />
                Your last payment was not completed. Please click the button
                below to confirm it.
              </Typography>
              <Button
                className={actionButton}
                variant="contained"
                color="primary"
                onClick={handleConfirmOrRetryPayment}
              >
                Confirm payment
              </Button>
            </>
          );
        } else {
          return (
            <>
              <Typography
                variant="body1"
                className={clsx(message, errorMessage)}
              >
                <ErrorIcon />
                Your last payment attempt failed. Please make sure your card is
                valid and you have enough funds. You may retry the payment by
                clicking the button below. If this persists, maybe try with
                another card.
              </Typography>
              <Button
                className={actionButton}
                variant="contained"
                color="primary"
                onClick={handleConfirmOrRetryPayment}
              >
                Retry payment
              </Button>
            </>
          );
        }
      } else {
        return (
          <Typography variant="body1" className={clsx(message, errorMessage)}>
            <ErrorIcon />
            Your last payment attempt was not completed. You will soon be
            reverted to the Free plan.
          </Typography>
        );
      }
    } else if (subscription?.status === 'canceled') {
      return (
        <>
          <Typography variant="body1" className={clsx(message, okMessage)}>
            <OKIcon />
            Your subscription was canceled on{' '}
            {formatDate(subscription?.currentPeriodEnd * 1000)}.
          </Typography>
        </>
      );
    }
  }

  function renderSubscriptionCard() {
    const isOnFreePlan = !subscription || subscription.status === 'canceled';

    return (
      <div style={{ marginLeft: 24 }}>
        <h3>Plan details</h3>

        {showPlans ? (
          <div className={plansContainer}>
            {availablePlans
              .sort((p1, p2) => p1.price / p1.duration - p2.price / p2.duration)
              .map(renderPlan)}
          </div>
        ) : (
          <>
            <Grid container>
              <Grid item xs={6}>
                <div className={plansContainer}>
                  {renderPlanOwned(subscription?.plan)}
                </div>
              </Grid>
              {!isOnFreePlan && (
                <Grid item xs={6}>
                  <div>Billing Cycle</div>
                  <h4>
                    {formatDate(subscription.currentPeriodStart * 1000)}
                    {' - '}
                    {formatDate(subscription.currentPeriodEnd * 1000)}
                  </h4>
                </Grid>
              )}
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowPlans(true)}
              style={{ marginTop: 8 }}
            >
              {isOnFreePlan ? 'Change Plan' : 'Choose a plan'}
            </Button>
          </>
        )}

        {subscription ? (
          subscription.status !== 'canceled' ? (
            subscription.cancelAtPeriodEnd ? (
              <Button
                style={{ marginLeft: 16, marginTop: 8 }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  reactivateSubscription();
                }}
              >
                Reactivate Plan
              </Button>
            ) : (
              <Button
                style={{ marginLeft: 16, marginTop: 8 }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  cancelSubscription();
                }}
              >
                Cancel Plan
              </Button>
            )
          ) : (
            <></>
          )
        ) : null}
      </div>
    );
  }

  function renderPaymentMethodCard() {
    return (
      <PaymentMethodSection
        customerName={customerName}
        defaultPaymentMethod={defaultPaymentMethod}
        setDefaultPaymentMethod={setDefaultPaymentMethod}
        removePaymentMethod={removePaymentMethod}
      />
    );
  }
  function renderTaxIDCard() {
    return <TaxIdSection getBillingDetails={billingDetails} />;
  }

  return (
    <>
      <Box marginBottom={2}>
        <h2 className={header}>Billing</h2>
        {renderExplanation()}
        {renderSubscriptionCard()}
        <Divider style={{ marginTop: 16 }} />
        {renderPaymentMethodCard()}
        <Divider style={{ marginTop: 16 }} />
        {renderTaxIDCard()}

        <Dialog
          TransitionComponent={SlideUp}
          open={isPayModalOpen}
          onClose={closePayNodal}
          fullWidth
          maxWidth={false}
        >
          <DialogTitle>
            {subscription?.status !== 'canceled'
              ? `Change plan`
              : `Choose a plan`}
          </DialogTitle>
          <DialogContent>
            <div>
              <Typography variant="h5">
                {subscription?.status !== 'canceled'
                  ? `Plan change`
                  : `Choose a plan`}
              </Typography>

              <div className={changePlanContainer}>
                {subscription?.status !== 'canceled' ? (
                  <>
                    <div>
                      <Typography variant="h6" align="center">
                        You own
                      </Typography>
                      <PlanBox plan={currentPlan} owned />
                    </div>
                    <ForwardIcon />
                  </>
                ) : (
                  <></>
                )}
                <div>
                  <Typography variant="h6" align="center">
                    You want
                  </Typography>
                  {payModalData && <PlanBox plan={payModalData} />}
                </div>
              </div>
            </div>

            <Divider className={divider} />

            <div className={paymentContainer}>
              <div>
                <PaymentMethodSection
                  customerName={customerName}
                  defaultPaymentMethod={defaultPaymentMethod}
                  setDefaultPaymentMethod={setDefaultPaymentMethod}
                  removePaymentMethod={removePaymentMethod}
                />
              </div>

              <div>
                <SubscriptionPriceBreakdown
                  className={priceBreakdown}
                  totalLabel="Total"
                  country={taxCountry}
                  taxIdType={billingDetails}
                  plan={payModalData}
                  vatRate={vatRate?.standard}
                  quantity={
                    payModalData?.isPricePerUser ? numberOfUsers : undefined
                  }
                />
                <Typography variant="caption" component="p" gutterBottom>
                  * When changing plans, it's likely you will not be changed the
                  whole amount shown above.
                </Typography>
                <Typography variant="caption" component="p" gutterBottom>
                  ** We will only charge the difference from what you already
                  paid.
                </Typography>
              </div>
            </div>

            <Button
              fullWidth
              variant="contained"
              color={canBuy ? 'primary' : 'default'}
              disabled={!canBuy}
              onClick={() => handleBuyPlan(payModalData!)}
            >
              {canBuy
                ? subscription?.status !== 'canceled'
                  ? `Change plan`
                  : `Choose plan`
                : 'Please select a payment method'}
            </Button>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default injectStripe(CompanyPlansSection);
