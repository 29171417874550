import {
  DVKObject,
  FlexExpander,
  InputModal,
  SlideUp,
  useModal,
} from '@dvkiin/material-commons';
import { NOOP_graphqlErrorManagement } from '@lib';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import UpdateIcon from '@material-ui/icons/Edit';
import React, { FC } from 'react';

import { HeavyDutyResults } from '@components/Feedback';
import { HeavyDutyResult } from '@components/Feedback/domain';

const GUIDE_LINK = `${process.env.REACT_APP_LANDING_PAGE}/features/imp-ex-up`;

type UpdateProductsProps = {
  validateUpdate: (file: File) => Promise<HeavyDutyResult[]>;
  executeUpdate: (file: File) => Promise<HeavyDutyResult[]>;
};

const UpdateProducts: FC<UpdateProductsProps> = ({
  validateUpdate,
  executeUpdate,
}) => {
  const {
    isOpen: isBulkUpdateModalOpen,
    open: openBulkUpdateModal,
    close: closeBulkUpdateModal,
  } = useModal();
  const {
    isOpen: isBulkUpdateConfirmModalOpen,
    open: openBulkUpdateConfirmModal,
    close: closeBulkUpdateConfirmModal,
    data: bulkUpdateConfirmData,
  } = useModal<{ file: File; simulationResult: HeavyDutyResult[] }>();
  const {
    isOpen: isBulkUpdateProductFeedbackModalOpen,
    open: openBulkUpdateProductFeedbackModal,
    close: closeBulkUpdateProductFeedbackModal,
    data: bulkUpdateProductFeedbackData,
  } = useModal<HeavyDutyResult[]>();

  const bulkUpdateAnalysisHasError = !!bulkUpdateConfirmData?.simulationResult.find(
    (result) => result.status === 'ERROR'
  );
  const bulkUpdateHasError = !!bulkUpdateProductFeedbackData?.find(
    (result) => result.status === 'ERROR'
  );

  async function handleValidateBulkUpdateProducts({ importFile }: DVKObject) {
    const file = ((importFile as any) as File[])[0];
    try {
      const simulationResult = await validateUpdate(file);
      openBulkUpdateConfirmModal({ file: file as any, simulationResult });
    } catch (e) {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleBulkUpdateProducts() {
    const file = bulkUpdateConfirmData!.file;
    try {
      const bulkUpdateResult = await executeUpdate(file);
      openBulkUpdateProductFeedbackModal(bulkUpdateResult);
    } catch (e) {
      NOOP_graphqlErrorManagement();
    }
  }

  function renderBulkUpdateProductsButton() {
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<UpdateIcon />}
        onClick={openBulkUpdateModal}
      >
        Bulk update products
      </Button>
    );
  }

  function renderBulkUpdateProductsAnalysisModal() {
    return (
      <InputModal
        title="Bulk update products"
        open={isBulkUpdateModalOpen}
        fields={[
          {
            name: 'importFile',
            label: 'CSV file for update',
            type: 'file',
            required: true,
            acceptedFileType: '.csv',
            errorMessage: {
              type: 'This file type is not supported.',
            },
          },
        ]}
        saveLabel="Analyze file"
        onClose={closeBulkUpdateModal}
        onCreate={handleValidateBulkUpdateProducts}
      >
        <Typography gutterBottom color="secondary">
          This feature will always update existing products. To create new
          products, please use 'Import Products' button.
        </Typography>
        <Typography gutterBottom>
          For more information about the multiple product update process, please{' '}
          <Link href={GUIDE_LINK} target="_blank" rel="noopener noreferrer">
            check this guide
          </Link>
          .
        </Typography>
        <Typography variant="caption">
          After you select your file and click 'Analyze file', we will show you
          what would happen if you choose to continue with the multiple product
          update. Before you confirm the update in the next step, nothing will
          be updated.
        </Typography>
      </InputModal>
    );
  }

  function renderBulkUpdateProductsResultModal() {
    return (
      <>
        {bulkUpdateProductFeedbackData && (
          <Dialog
            aria-labelledby="feedback-dialog-title"
            TransitionComponent={SlideUp}
            open={isBulkUpdateProductFeedbackModalOpen}
            onClose={closeBulkUpdateProductFeedbackModal}
            fullWidth
            maxWidth="sm"
            aria-describedby="feedback-dialog-description"
          >
            <DialogTitle id="feedback-dialog-title">
              {bulkUpdateHasError ? 'Import failed' : 'Import successful!'}
            </DialogTitle>
            <DialogContent id="feedback-dialog-description">
              <HeavyDutyResults results={bulkUpdateProductFeedbackData} />
            </DialogContent>
            <DialogActions>
              {!bulkUpdateHasError && (
                <Button
                  onClick={() => {
                    closeBulkUpdateProductFeedbackModal();
                    closeBulkUpdateConfirmModal();
                    closeBulkUpdateModal();
                  }}
                >
                  Cancel
                </Button>
              )}
              <FlexExpander />
              {bulkUpdateHasError ? (
                <Button
                  onClick={() => {
                    closeBulkUpdateProductFeedbackModal();
                    closeBulkUpdateConfirmModal();
                  }}
                >
                  Try another file
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    closeBulkUpdateProductFeedbackModal();
                    closeBulkUpdateConfirmModal();
                    closeBulkUpdateModal();
                  }}
                >
                  Ok
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  function renderBulkUpdateProductsConfirmationModal() {
    return (
      <>
        {bulkUpdateConfirmData && (
          <Dialog
            aria-labelledby="confirmation-dialog-title"
            TransitionComponent={SlideUp}
            open={isBulkUpdateConfirmModalOpen}
            onClose={closeBulkUpdateConfirmModal}
            fullWidth
            maxWidth="sm"
            aria-describedby="confirmation-dialog-description"
          >
            <DialogTitle id="confirmation-dialog-title">
              {bulkUpdateAnalysisHasError
                ? 'We cannot use this file'
                : 'Continue multiple product update?'}
            </DialogTitle>
            <DialogContent id="confirmation-dialog-description">
              <HeavyDutyResults
                results={bulkUpdateConfirmData!.simulationResult}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeBulkUpdateConfirmModal}>Cancel</Button>
              <FlexExpander />
              {bulkUpdateAnalysisHasError ? (
                <Button onClick={closeBulkUpdateConfirmModal}>
                  Try another file
                </Button>
              ) : (
                <Button onClick={handleBulkUpdateProducts}>Yes</Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  return (
    <>
      {renderBulkUpdateProductsButton()}
      {renderBulkUpdateProductsAnalysisModal()}
      {renderBulkUpdateProductsConfirmationModal()}
      {renderBulkUpdateProductsResultModal()}
    </>
  );
};

export default UpdateProducts;
