import { Page } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import { OfferPrintContext, useDevColor } from '../../../helpers';
import Footer from '../../snippets/Footer';
import Header from '../../snippets/Header';
import { commonStyles } from '../../style';
import { getDetailedOfferItems } from '../helpers';
import OfferItemRow from './OfferItem';
var OfferItems = function () {
    var offerItems = useContext(OfferPrintContext).offer.offerItems;
    var devColor = useDevColor();
    var detailedOfferItems = getDetailedOfferItems(offerItems);
    return (React.createElement(Page, { wrap: true, size: "A4", orientation: "landscape", style: [commonStyles.page, devColor('#ccc')] },
        React.createElement(Header, { compact: true }),
        detailedOfferItems.map(function (oi) { return (React.createElement(OfferItemRow, { key: oi.id, offerItem: oi })); }),
        React.createElement(Footer, null)));
};
export default OfferItems;
