import { createMuiTheme, Theme } from '@material-ui/core';
import {
  green,
  indigo,
  lightBlue,
  orange,
  purple,
  red,
  teal,
} from '@material-ui/core/colors';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import createPalette from '@material-ui/core/styles/createPalette';

import { ooBrand } from '@optioffer/core';

type OOThemeExtra = {
  oo: {
    colors: {
      client: string;
      supplier: string;
      product: string;
      productBundle: string;
      offer: string;
      catalog: string;
      admin: string;
      addon: string;
      actionCenter: string;
    };
  };
};

export type OOTheme = Theme & OOThemeExtra;
type OOThemeOptions = ThemeOptions & OOThemeExtra;

const theme: OOTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 600, // semi-bold
      },
      outlined: {
        borderWidth: '3px',
        '&:hover': {
          borderWidth: '3px',
        },
      },
      outlinedPrimary: {
        borderWidth: '3px',
        '&:hover': {
          borderWidth: '3px',
        },
      },
    },
  },
  palette: createPalette({
    primary: {
      main: ooBrand.colors.primary.default,
    },
    secondary: {
      main: ooBrand.colors.secondary.default,
    },
    error: {
      main: ooBrand.colors.error,
    },
    warning: {
      main: ooBrand.colors.warning,
    },
  }),
  typography: {
    fontFamily: ooBrand.fontFamily.body.join(','),
    button: {
      textTransform: 'none',
    },
  },
  oo: {
    colors: {
      supplier: '#2B4162',
      client: purple[500],
      product: green[500],
      productBundle: green[700],
      offer: lightBlue[500],
      catalog: orange[500],
      admin: red[500],
      addon: indigo[500],
      actionCenter: teal[400],
      defaultBackground:
        'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    },
  },
} as OOThemeOptions) as OOTheme;

export default theme;
