import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import CreateProfilePage from './CreateProfile';

const ProfileModule: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/new`} component={CreateProfilePage} />
    </Switch>
  );
};

export default ProfileModule;
