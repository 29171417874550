import { OptiTextField } from '@components';
import {
  mapFormikToCountrySelector,
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useIsMobile,
} from '@lib';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  ClientInOfferModuleFragment,
  NewOffer_CreateClientDocument,
  NewOffer_SearchClientDocument,
  NewOffer_UpdateClientDocument,
} from '@optioffer/graphql';

import { Country } from '@components/Card/domain';
import { findOneCountry } from '@components/Payment';
import OOModal from '@components/modals/OOModal';

import { getQueryName } from '@lib/graphql';
import { ModalControl } from '@lib/materialCommons';

import addCustomerIllustration from '@resources/illustrations/add_customer.svg';

import CountrySelector from '../../../components/form/CountrySelector';
import {
  getRealClientId,
  isClientTemporary,
  TEMPORARY_CLIENT_ID,
} from '../domain';
import useStyles from '../styles';

const useClientModalStyles = makeStyles((theme) => ({
  clientModalIcon: {
    opacity: '30%',
    fontSize: '65px',
    color: theme.palette.primary.main,
  },
}));

type ClientModalProps = {
  control: ModalControl<ClientInOfferModuleFragment>;
  setCurrentCustomer: (
    customer: ClientInOfferModuleFragment | undefined
  ) => void;
};

const ClientModal: FC<ClientModalProps> = ({ control, setCurrentCustomer }) => {
  const classes = useStyles();
  const localClasses = useClientModalStyles();
  const isMobile = useIsMobile();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      companyName: '',
      companyVatId: '',
      country: null as Country | null,
      dontPersistClient: false,
    },
    onSubmit: async (values) => {
      try {
        await handleSaveClient(values);
      } catch {
        NOOP_graphqlErrorManagement();
      }
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const [createClient] = useEnhancedMutation(NewOffer_CreateClientDocument, {
    refetchQueries: [getQueryName(NewOffer_SearchClientDocument)],
    success: {
      message: 'Client created successfully.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while creating the client.',
    },
    formik,
  });

  const [updateClient] = useEnhancedMutation(NewOffer_UpdateClientDocument, {
    success: {
      message: 'Client updated successfully.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while saving the client.',
    },
    formik,
  });

  useEffect(() => {
    if (control.data) {
      formikRef.current.setValues({
        name: control.data.name ?? '',
        email: control.data.email ?? '',
        phoneNumber: control.data.phoneNumber ?? '',
        companyName: control.data.companyName ?? '',
        companyVatId: control.data.companyVatId ?? '',
        country: findOneCountry(control.data.country ?? undefined) ?? null,
        dontPersistClient: control.data && isClientTemporary(control.data),
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  async function handleSaveClient(values: typeof formik.values) {
    if (values.dontPersistClient) {
      // update client for current quote only
      setCurrentCustomer({
        id:
          control.data && isClientTemporary(control.data)
            ? control.data.id
            : `${TEMPORARY_CLIENT_ID}${control.data?.id}`,
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
        companyName: values.companyName,
        companyVatId: values.companyVatId,
        country: values.country?.isoCode,
      });
    } else {
      const realId = control.data?.id
        ? isClientTemporary(control.data)
          ? getRealClientId(control.data.id)
          : control.data.id
        : undefined;
      if (realId) {
        // update client in db
        const result = await updateClient({
          variables: {
            id: realId,
            client: {
              name: values.name,
              email: values.email,
              phoneNumber: values.phoneNumber,
              companyName: values.companyName,
              companyVatId: values.companyVatId,
              country: values.country?.isoCode,

              // don't replace the other fields; i know, we must fix this
              companyEmail: control.data?.companyEmail,
              companyPhoneNumber: control.data?.companyPhoneNumber,
              companyAddress: control.data?.companyAddress,
              contactPersonName: control.data?.contactPersonName,
              zipCode: control.data?.zipCode,
              city: control.data?.city,
            },
          },
        });
        result.data && setCurrentCustomer(result.data.updateClient);
      } else {
        // create new client
        const result = await createClient({
          variables: {
            client: {
              name: values.name,
              email: values.email,
              phoneNumber: values.phoneNumber,
              companyName: values.companyName,
              companyVatId: values.companyVatId,
              country: values.country?.isoCode,
            },
          },
        });
        result.data && setCurrentCustomer(result.data.createClient);
      }
    }

    control.close();
  }

  const title =
    control.data?.id === undefined ? 'New Customer' : 'Edit Customer Details';

  return (
    <>
      <OOModal title={title} open={control.isOpen} onClose={control.close}>
        <Box display="flex">
          <Box
            className={classes.illustrationWrapper}
            marginLeft={isMobile ? 0 : 6}
          >
            <img
              className={classes.illustration}
              src={addCustomerIllustration}
              alt="add customer illustration"
            />
          </Box>
          <Box
            flex={1}
            marginLeft={isMobile ? 0 : 4}
            marginRight={isMobile ? 0 : 6}
            marginBottom={isMobile ? 0 : 4}
          >
            <form onSubmit={formik.handleSubmit}>
              {isMobile ? (
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  marginTop={1.5}
                  marginBottom={3.5}
                >
                  <AccountCircleIcon className={localClasses.clientModalIcon} />
                </Box>
              ) : (
                <Box className={classes.desktopModalTitle}>{title}</Box>
              )}

              <Box paddingX={1.5} marginBottom={isMobile ? 10 : 2}>
                <Box marginY={2}>
                  <OptiTextField
                    required
                    label="Full Name"
                    {...mapFormikToTextField(formik, 'name')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    required
                    label="Email"
                    {...mapFormikToTextField(formik, 'email')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Phone Number"
                    {...mapFormikToTextField(formik, 'phoneNumber')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Company Name"
                    {...mapFormikToTextField(formik, 'companyName')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Company VAT Id"
                    {...mapFormikToTextField(formik, 'companyVatId')}
                  />
                </Box>
                <Box marginY={2}>
                  <CountrySelector
                    selectorStyleOptions={{
                      size: 'small',
                      showAdornment: true,
                      inputVariant: 'outlined',
                      placeholder: undefined,
                      label: 'Country',
                    }}
                    {...mapFormikToCountrySelector(formik, 'country')}
                  />
                </Box>
                {control.data?.id !== undefined && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...formik.getFieldProps({
                          name: 'dontPersistClient',
                          type: 'checkbox',
                        })}
                      />
                    }
                    label="Save changes only for this quote"
                  />
                )}
              </Box>

              <Box display="flex" className={clsx(classes.floatingFooter)}>
                <Box flex="1" margin={isMobile ? 0 : 1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={
                      !formik.dirty ||
                      !formik.values.email ||
                      !formik.values.name
                    }
                  >
                    {control.data?.id === undefined
                      ? 'Add Customer'
                      : 'Save Changes'}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </OOModal>
    </>
  );
};

export default ClientModal;
