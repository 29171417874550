import { Grid } from '@material-ui/core';
import React, { FC } from 'react';

import { COUNTRIES_DIAL_CODE_AND_FLAG } from '@components/Payment/countryHelpers';

import { IPaymentMethodCard } from './domain';
import useStyles from './styles';

type PaymentMethodCardProps = {
  paymentMethodCard: IPaymentMethodCard;
};

const PaymentMethodCard: FC<PaymentMethodCardProps> = ({
  paymentMethodCard,
}) => {
  const { almostHeader } = useStyles();
  const countryName = COUNTRIES_DIAL_CODE_AND_FLAG.find(
    (ct) => ct.isoCode === paymentMethodCard.country
  )?.name;

  return (
    <Grid container>
      <Grid item xs={6}>
        <div>
          <div>Country</div>
          <h4 className={almostHeader}>
            {countryName
              ? 'Country: ' + countryName
              : "You haven't specified a country yet"}
          </h4>
        </div>
        <div style={{ marginTop: 16 }}>
          <div>Card number</div>
          <h4 className={almostHeader}>
            {paymentMethodCard.brand}, **** **** **** {paymentMethodCard.last4},{' '}
          </h4>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div>
          <div>Address</div>
          <h4 className={almostHeader}>
            {paymentMethodCard.address
              ? 'Address: ' + paymentMethodCard.address
              : "You haven't specified an adress yet"}
          </h4>
        </div>
        <div style={{ marginTop: 16 }}>
          <div>Expiration Date</div>
          <h4 className={almostHeader}>
            {paymentMethodCard.extMonth}/{paymentMethodCard.extYear}
          </h4>
        </div>
      </Grid>
    </Grid>
  );
};

export default PaymentMethodCard;
