import { Box, BoxProps, IconButton, makeStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    transition: 'max-height 0.5s ease-in-out',
  },
  open: {
    maxHeight: 2000,
  },
  closed: {
    minHeight: 50,
    maxHeight: 100,
    overflow: 'hidden',
  },
  showMore: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 50,
    background:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  showMoreButton: {
    transform: 'rotateZ(-90deg)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

type ExpandableBoxProps = BoxProps & {
  defaultState?: boolean;
};

const ExpandableBox: FC<ExpandableBoxProps> = ({
  defaultState = false,
  children,
  className,
  ...rest
}) => {
  const localClasses = useLocalStyles();
  const [openedByUser, setOpenedByUser] = useState(false);
  const [open, setOpen] = useState(defaultState);

  useEffect(() => {
    if (!openedByUser) setOpen(defaultState);
  }, [openedByUser, setOpen, defaultState]);

  return (
    <Box
      className={clsx(
        className,
        localClasses.root,
        open ? localClasses.open : localClasses.closed
      )}
      {...rest}
    >
      {children}

      {!open && (
        <Box className={localClasses.showMore}>
          <IconButton
            className={localClasses.showMoreButton}
            onClick={() => {
              setOpen(true);
              setOpenedByUser(true);
            }}
          >
            <ChevronLeft />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ExpandableBox;
