import { DVKField } from '@dvkiin/material-commons';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';

import { SmtpAuthenticationType } from '@optioffer/graphql';

function getAuthenticationFields(
  authenticationType?: SmtpAuthenticationType
): DVKField[] {
  const genericFields: DVKField[] = [
    {
      name: 'smtpServer',
      label: 'SMTP Server',
      type: 'text',
      required: true,
    },
    {
      name: 'smtpPort',
      label: 'SMTP Port',
      type: 'number',
      required: true,
    },
    {
      name: 'smtpSecure',
      label: 'Secure connection?',
      type: 'checkbox',
      required: true,
    },
  ];

  switch (authenticationType) {
    case SmtpAuthenticationType.LOGIN:
      return [
        ...genericFields,
        {
          name: 'smtpUser',
          label: 'User',
          type: 'text',
          required: true,
        },
        {
          name: 'smtpPassword',
          label: 'Password',
          type: 'password',
          required: true,
        },
      ];
    case SmtpAuthenticationType.OAUTH2:
      return [
        ...genericFields,
        {
          name: 'smtpUser',
          label: 'Service Account Owner',
          type: 'text',
          required: true,
        },
        {
          name: 'smtpServiceClient',
          label: 'Service Account ClientId',
          type: 'text',
          required: true,
        },
        {
          name: 'smtpPrivateKey',
          label: 'Service Account Private Key',
          type: 'text',
          multiline: true,
          required: true,
        },
      ];
    default:
      return [];
  }
}

export const useEmailSettingsFields = (
  authenticationType?: SmtpAuthenticationType
): DVKField[] => {
  return useMemo(() => {
    return [
      {
        name: 'authenticationType',
        label: 'Authentication type',
        type: 'select',
        values: [
          {
            name: SmtpAuthenticationType.OAUTH2,
            label: 'OAuth2 for service workers',
          },
          {
            name: SmtpAuthenticationType.LOGIN,
            label: 'Password authentication',
          },
        ],
        infoModal: {
          message: (
            <>
              <Typography variant="body1" gutterBottom>
                Configure your own SMTP server for email sending. This is useful
                if you want to{' '}
                <strong>send the quotes using your own email address</strong>.
                These are the same settings that you need for an email client
                like Thunderbird or Outlook. If you don't know what to input
                here, please <strong>ask your system admin</strong> and they
                will be able to help you.
              </Typography>
            </>
          ),
          title: 'Custom SMTP',
        },
      },
      ...getAuthenticationFields(authenticationType),
    ] as DVKField[];
  }, [authenticationType]);
};
