import {
  NumberFormatInput,
  OptiTextField,
  SelectAndTextInput,
} from '@components';
import {
  mapFormikToSelectAndTextField,
  mapFormikToTextField,
  useIsMobile,
} from '@lib';
import { Box, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useContext, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { AddonInput, CalculationType } from '@optioffer/graphql';

import OOModal from '@components/modals/OOModal';

import { OfferContext } from '@containers/OfferContext';

import { ModalControl } from '@lib/materialCommons';

import addonIllustration from '@resources/illustrations/addon.svg';

import { ProductDataWithOfferItem } from './domain';
import useStyles from './styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

export type AddonModalData = Partial<ProductDataWithOfferItem> & {
  addon?: AddonInput;
};

type AddonModalProps = {
  control: ModalControl<AddonModalData>;
  updateAddon: (addon: AddonInput) => Promise<any>;
};

const AddonModal: FC<AddonModalProps> = ({ control, updateAddon }) => {
  const classes = useStyles();
  // const localClasses = useLocalStyles();
  const offer = useContext(OfferContext);
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      name: '',
      price: 0,

      discountType: CalculationType.PERCENTAGE,
      discountValue: 0,

      costType: CalculationType.PERCENTAGE,
      costValue: 0,
    },
    onSubmit: async (values) => {
      if (!control.data) return;

      await updateAddon({
        id: control.data.addon?.id,
        name: values.name,
        price: values.price ?? 0,
        addonDiscount: {
          calculationType: values.discountType,
          value: values.discountValue ?? 0,
        },
        cost: {
          calculationType: values.costType,
          value: values.costValue ?? 0,
        },
      });

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.data) {
      formikRef.current.resetForm({
        values: {
          name: control.data.addon?.name ?? '',
          price: control.data.addon?.price ?? 0,
          discountType:
            control.data.addon?.addonDiscount.calculationType ??
            CalculationType.PERCENTAGE,
          discountValue: control.data.addon?.addonDiscount.value ?? 0,
          costType:
            control.data.addon?.cost?.calculationType ??
            CalculationType.PERCENTAGE,
          costValue: control.data.addon?.cost?.value ?? 0,
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = `${!control.data?.addon ? 'New ' : ''}Extra Service`;
  const subTitle = control.data?.offerItem
    ? `${control.data?.offerItem.product?.code} - ${control.data?.offerItem.product?.name}`
    : undefined;

  // AddonModal does not make sense without an offer
  if (!offer) return null;

  return (
    <>
      <OOModal
        open={control.isOpen}
        onClose={control.close}
        title={title}
        subTitle={subTitle}
      >
        <Box display="flex">
          <Box
            className={classes.illustrationWrapper}
            marginLeft={isMobile ? 0 : 6}
          >
            <img
              className={classes.illustration}
              src={addonIllustration}
              alt="add customer illustration"
            />
          </Box>
          <Box
            flex={1}
            marginLeft={isMobile ? 0 : 4}
            marginRight={isMobile ? 0 : 6}
            marginBottom={isMobile ? 0 : 4}
          >
            <form onSubmit={formik.handleSubmit}>
              {!isMobile && (
                <>
                  <Box className={classes.desktopModalTitle}>{title}</Box>
                  <Box className={classes.desktopModalSubTitle}>{subTitle}</Box>
                </>
              )}

              <Box height={'100%'} marginBottom={10}>
                <Box paddingX={1.5}>
                  <Box marginY={2}>
                    <OptiTextField
                      required
                      label="Name"
                      {...mapFormikToTextField(formik, 'name')}
                    />
                  </Box>
                </Box>

                <Box paddingX={1.5}>
                  <Box marginY={2}>
                    <OptiTextField
                      required
                      label="Price"
                      {...mapFormikToTextField(formik, 'price')}
                      InputProps={{
                        inputComponent: NumberFormatInput as any,
                        inputProps: {
                          textAlign: 'right',
                          onFocus: (e) => e.target.select(),
                        },
                      }}
                    />
                  </Box>
                </Box>

                <Box paddingX={1.5}>
                  <Box marginY={2}>
                    <SelectAndTextInput
                      label="Discount"
                      options={[
                        { label: '%', value: CalculationType.PERCENTAGE },
                        {
                          label: offer.currency.symbol,
                          value: CalculationType.FIXED,
                        },
                      ]}
                      {...mapFormikToSelectAndTextField(
                        formik,
                        'discountType',
                        'discountValue',
                        {},
                        {
                          InputProps: {
                            inputComponent: NumberFormatInput as any,
                            inputProps: {
                              textAlign: 'right',
                              onFocus: (e) => e.target.select(),
                            },
                          },
                        }
                      )}
                    />
                  </Box>
                </Box>

                <Box paddingX={1.5}>
                  <Box marginY={2}>
                    <SelectAndTextInput
                      label="Extra Service Cost"
                      options={[
                        { label: '%', value: CalculationType.PERCENTAGE },
                        {
                          label: offer.currency.symbol,
                          value: CalculationType.FIXED,
                        },
                      ]}
                      {...mapFormikToSelectAndTextField(
                        formik,
                        'costType',
                        'costValue',
                        {},
                        {
                          InputProps: {
                            inputComponent: NumberFormatInput as any,
                            inputProps: {
                              textAlign: 'right',
                              onFocus: (e) => e.target.select(),
                            },
                          },
                        }
                      )}
                    />
                  </Box>
                </Box>
              </Box>

              <Box marginBottom={10} />

              <Box display="flex" className={classes.floatingFooter}>
                <Box flex="1" margin={isMobile ? 0 : 1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={
                      !formik.dirty ||
                      !formik.values.name ||
                      !formik.values.price
                    }
                  >
                    {!control.data?.addon
                      ? 'Add Extra Service'
                      : !formik.dirty
                      ? 'Changes Saved'
                      : 'Save Changes'}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </OOModal>
    </>
  );
};

export default AddonModal;
