import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as React from 'react';
import { FC, forwardRef, PropsWithChildren } from 'react';

import { ClientInOfferModuleFragment } from '@optioffer/graphql';

import { isClientTemporary } from '../domain';

const useClientListStyles = makeStyles(() => ({
  clientLabel: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  clientLabelContainer: {
    flex: 1,
    minWidth: 0,
  },
  radioControl: {
    color: 'rgba(0, 0, 0, 0.20)',
  },
}));

type ClientListItemProps = {
  client: ClientInOfferModuleFragment;
  openClientDetails: (client: ClientInOfferModuleFragment) => void;
};

export const ClientListItem = forwardRef<
  any,
  PropsWithChildren<ClientListItemProps>
>(({ client, openClientDetails, children }, ref) => {
  const localClasses = useClientListStyles();

  return (
    <Box maxWidth="100%">
      <Box display="flex" paddingY={1} paddingX={2} marginX={-2}>
        <FormControlLabel
          ref={ref}
          value={client.id}
          control={
            <Radio color="primary" className={localClasses.radioControl} />
          }
          label={`${isClientTemporary(client) ? '(custom) ' : ''}${[
            client.name,
            client.companyName,
          ]
            .filter(Boolean)
            .join(', ')}`}
          classes={{
            root: localClasses.clientLabelContainer,
            label: localClasses.clientLabel,
          }}
        />
        <Box marginX={-2}>
          <IconButton color="primary" onClick={() => openClientDetails(client)}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Box>
      {children}
    </Box>
  );
});

type ClientListProps = {
  clients: ClientInOfferModuleFragment[];
  openClientDetails: (client: ClientInOfferModuleFragment) => void;
  currentCustomerId?: ClientInOfferModuleFragment['id'];
  currentCustomerInListRef: React.Ref<any>;
};

const ClientList: FC<ClientListProps> = ({
  clients,
  openClientDetails,
  currentCustomerId,
  currentCustomerInListRef,
}) => {
  return (
    <>
      {clients.map((client, index, self) => (
        <ClientListItem
          key={client.id}
          client={client}
          openClientDetails={openClientDetails}
          ref={
            client.id === currentCustomerId
              ? currentCustomerInListRef
              : undefined
          }
        >
          {index < self.length - 1 && (
            <Box marginX={-2}>
              <Divider />
            </Box>
          )}
        </ClientListItem>
      ))}
    </>
  );
};

export default React.memo(ClientList);
