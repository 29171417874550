import { CheckBoxInput, OptiTextField } from '@components';
import {
  mapFormikToCheckBoxInput,
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useIsMobile,
} from '@lib';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  Auth_CurrentUserDocument,
  Profile_UpdateProfileDocument,
  ProfileOnProfilePageFragment,
} from '@optioffer/graphql';

import LoadingAwareButton from '@components/LoadingAwareButton';
import OOModal from '@components/modals/OOModal';

import { getQueryName } from '@lib/graphql';
import { ModalControl } from '@lib/materialCommons';

import addCustomerIllustration from '@resources/illustrations/add_customer.svg';

import useStyles from '../../NewOffer/styles';
import { mapToProfileInput } from './helpers';

// const useContactInfoModalStyles = makeStyles((theme) => ({
//   personalInfoModalIcon: {
//     opacity: '30%',
//     fontSize: '65px',
//     color: theme.palette.primary.main,
//   },
// }));

type ContactInfoModalProps = {
  control: ModalControl<ProfileOnProfilePageFragment>;
};

const ContactInfoModal: FC<ContactInfoModalProps> = ({ control }) => {
  const classes = useStyles();
  // const localClasses = useContactInfoModalStyles();
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      phone: '',
      email: '',
      website: '',
      fbLink: '',
      instaLink: '',
      linLink: '',
      tikTokLink: '',
      continueWithoutSocialLinks: false,
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (!control.data) return;

      setSubmitting(true);
      try {
        await updateProfile({
          variables: {
            id: control.data.id,
            profile: {
              ...mapToProfileInput(control.data),

              // actually changed
              email: values.email,
              phone: values.phone,
              website: values.website,
              fbLink: values.fbLink,
              linLink: values.linLink,
              instaLink: values.instaLink,
              tikTokLink: values.tikTokLink,
              continueWithoutSocialLinks: values.continueWithoutSocialLinks,
            },
          },
        });

        control.close();
      } catch {
        NOOP_graphqlErrorManagement();
      } finally {
        setSubmitting(false);
      }
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const [updateProfile] = useEnhancedMutation(Profile_UpdateProfileDocument, {
    refetchQueries: [getQueryName(Auth_CurrentUserDocument)],
    success: {
      message: 'Contact info saved.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while saving your contact info.',
    },
    formik,
  });

  useEffect(() => {
    if (control.data) {
      formikRef.current.setValues({
        email: control.data.email ?? '',
        phone: control.data.phone ?? '',
        website: control.data.website ?? '',
        fbLink: control.data.fbLink ?? '',
        linLink: control.data.linLink ?? '',
        instaLink: control.data.instaLink ?? '',
        tikTokLink: control.data.tikTokLink ?? '',
        continueWithoutSocialLinks:
          control.data.continueWithoutSocialLinks ?? false,
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = 'Edit Contact Info';
  return (
    <>
      <OOModal title={title} open={control.isOpen} onClose={control.close}>
        <Box display="flex">
          <Box
            className={classes.illustrationWrapper}
            marginLeft={isMobile ? 0 : 6}
          >
            <img
              className={classes.illustration}
              src={addCustomerIllustration}
              alt="add customer illustration"
            />
          </Box>
          <Box
            flex={1}
            marginLeft={isMobile ? 0 : 4}
            marginRight={isMobile ? 0 : 6}
            marginBottom={isMobile ? 0 : 4}
          >
            {control.data && (
              <form onSubmit={formik.handleSubmit}>
                {!isMobile && (
                  <Box className={classes.desktopModalTitle}>{title}</Box>
                )}

                <Box paddingX={1.5} marginBottom={isMobile ? 10 : 2}>
                  <Box marginY={2}>
                    <OptiTextField
                      required
                      label="Email"
                      {...mapFormikToTextField(formik, 'email')}
                    />
                  </Box>
                  <Box marginY={2}>
                    <OptiTextField
                      required
                      label="Phone number"
                      {...mapFormikToTextField(formik, 'phone')}
                    />
                  </Box>
                  <Box marginY={2}>
                    <OptiTextField
                      label="Website address"
                      {...mapFormikToTextField(formik, 'website')}
                    />
                  </Box>
                  <Box marginY={2}>
                    <OptiTextField
                      label="Facebook profile"
                      {...mapFormikToTextField(formik, 'fbLink')}
                    />
                  </Box>
                  <Box marginY={2}>
                    <OptiTextField
                      label="LinkedIn profile"
                      {...mapFormikToTextField(formik, 'linLink')}
                    />
                  </Box>
                  <Box marginY={2}>
                    <OptiTextField
                      label="Instagram profile"
                      {...mapFormikToTextField(formik, 'instaLink')}
                    />
                  </Box>
                  <Box marginY={2}>
                    <OptiTextField
                      label="TikTok profile"
                      {...mapFormikToTextField(formik, 'tikTokLink')}
                    />
                  </Box>
                  {!(
                    formik.values.website ||
                    formik.values.fbLink ||
                    formik.values.linLink ||
                    formik.values.instaLink ||
                    formik.values.tikTokLink
                  ) && (
                    <Box marginTop={-2}>
                      <CheckBoxInput
                        label="Continue without social links"
                        {...mapFormikToCheckBoxInput(
                          formik,
                          'continueWithoutSocialLinks'
                        )}
                      />
                    </Box>
                  )}
                </Box>

                <Box display="flex" className={clsx(classes.floatingFooter)}>
                  <Box flex="1" margin={isMobile ? 0 : 1.5}>
                    <LoadingAwareButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      loading={formik.isSubmitting}
                    >
                      Save Contact Info
                    </LoadingAwareButton>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </OOModal>
    </>
  );
};

export default ContactInfoModal;
