import { OptiTextField } from '@components';
import {
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
} from '@lib';
import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  Admin_NewProfileDocument,
  Admin_SearchProfilesDocument,
  ProfileOnProfilePageFragment,
} from '@optioffer/graphql';

import OOModal from '@components/modals/OOModal';

import { getQueryName } from '@lib/graphql';
import { ModalControl } from '@lib/materialCommons';

import useStyles from '../styles';

// const useProfileModalStyles = makeStyles((theme) => ({
// }));

type ProfileModalProps = {
  control: ModalControl;
  navigate(profile: ProfileOnProfilePageFragment): void;
};

const ProfileModal: FC<ProfileModalProps> = ({ control, navigate }) => {
  const classes = useStyles();
  // const localClasses = useProfileModalStyles();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      companyName: '',
      contactPersonName: '',
    },
    onSubmit: async (values) => {
      try {
        const result = await createProfile({
          variables: {
            profile: {
              companyName: values.companyName,
              contactPersonName: values.contactPersonName,
            },
          },
        });
        result.data?.profileAdmin.new && navigate(result.data.profileAdmin.new);
        control.close();
      } catch {
        NOOP_graphqlErrorManagement();
      }
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const [createProfile] = useEnhancedMutation(Admin_NewProfileDocument, {
    refetchQueries: [getQueryName(Admin_SearchProfilesDocument)],
    success: {
      message: 'Profile created successfully.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while creating the profile.',
    },
    formik,
  });

  useEffect(() => {
    formikRef.current.resetForm();
  }, [formikRef, control.data]);

  const title = 'New Profile';

  return (
    <>
      <OOModal title={title} open={control.isOpen} onClose={control.close}>
        <Box display="flex">
          <Box flex={1} marginLeft={4} marginRight={6} marginBottom={4}>
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.desktopModalTitle}>{title}</Box>

              <Box paddingX={1.5} marginBottom={2}>
                <Box marginY={2}>
                  <OptiTextField
                    required
                    label="Company Name"
                    {...mapFormikToTextField(formik, 'companyName')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    required
                    label="Contact Person Name"
                    {...mapFormikToTextField(formik, 'contactPersonName')}
                  />
                </Box>
              </Box>

              <Box display="flex" className={clsx(classes.floatingFooter)}>
                <Box flex="1" margin={1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={
                      !formik.dirty ||
                      !(
                        formik.values.companyName ||
                        formik.values.contactPersonName
                      )
                    }
                  >
                    {'Add Profile'}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </OOModal>
    </>
  );
};

export default ProfileModal;
