import { OptiTextField } from '@components';
import {
  capitalizeFirstLetter,
  mapFormikToAutocomplete,
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useIsMobile,
} from '@lib';
import { Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  Auth_CurrentUserDocument,
  Profile_UpdateProfileDocument,
  ProfileOnProfilePageFragment,
  ProfileType,
} from '@optioffer/graphql';

import LoadingAwareButton from '@components/LoadingAwareButton';
import OOModal from '@components/modals/OOModal';

import { getQueryName } from '@lib/graphql';
import { ModalControl } from '@lib/materialCommons';
import { DEFAULT_SERVICES, PROFILE_CATEGORIES } from '@lib/profile';

import addCustomerIllustration from '@resources/illustrations/add_customer.svg';

import useStyles from '../../NewOffer/styles';
import { mapToProfileInput } from './helpers';

// const useServicesModalStyles = makeStyles((theme) => ({
//   personalInfoModalIcon: {
//     opacity: '30%',
//     fontSize: '65px',
//     color: theme.palette.primary.main,
//   },
// }));

type ServicesModalProps = {
  control: ModalControl<ProfileOnProfilePageFragment>;
};

const ServicesModal: FC<ServicesModalProps> = ({ control }) => {
  const classes = useStyles();
  // const localClasses = useServicesModalStyles();
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      description: '',
      category: '',
      areaServed: '',
      services: [] as string[],
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (!control.data) return;

      setSubmitting(true);
      try {
        await updateProfile({
          variables: {
            id: control.data.id,
            profile: {
              ...mapToProfileInput(control.data),

              // actually changed
              description: values.description,
              category: values.category,
              areaServed: values.areaServed,
              services: values.services,
            },
          },
        });

        control.close();
      } catch {
        NOOP_graphqlErrorManagement();
      } finally {
        setSubmitting(false);
      }
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const [updateProfile] = useEnhancedMutation(Profile_UpdateProfileDocument, {
    refetchQueries: [getQueryName(Auth_CurrentUserDocument)],
    success: {
      message: 'Skills saved.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while saving your skills.',
    },
    formik,
  });

  useEffect(() => {
    if (control.data) {
      formikRef.current.setValues({
        description: control.data.description ?? '',
        category: control.data.category ?? '',
        areaServed: control.data.areaServed ?? '',
        services: control.data.services ?? [],
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title =
    control.data?.profileType === ProfileType.EMPLOYEE
      ? 'Edit Skills'
      : 'Edit Services';
  return (
    <>
      <OOModal title={title} open={control.isOpen} onClose={control.close}>
        <Box display="flex">
          <Box
            className={classes.illustrationWrapper}
            marginLeft={isMobile ? 0 : 6}
          >
            <img
              className={classes.illustration}
              src={addCustomerIllustration}
              alt="add customer illustration"
            />
          </Box>
          <Box
            flex={1}
            marginLeft={isMobile ? 0 : 4}
            marginRight={isMobile ? 0 : 6}
            marginBottom={isMobile ? 0 : 4}
          >
            {control.data && (
              <form onSubmit={formik.handleSubmit}>
                {!isMobile && (
                  <Box className={classes.desktopModalTitle}>{title}</Box>
                )}

                <Box paddingX={1.5} marginBottom={isMobile ? 10 : 2}>
                  <Box marginY={2}>
                    <OptiTextField
                      required
                      label="Bio"
                      multiline
                      rows={7}
                      {...mapFormikToTextField(formik, 'description')}
                    />
                  </Box>
                  <Box marginY={2}>
                    <OptiTextField
                      required
                      label="Available to work in"
                      placeholder="Where do you provide your services?"
                      {...mapFormikToTextField(formik, 'areaServed')}
                    />
                  </Box>
                  <Box marginY={2}>
                    <Autocomplete
                      options={
                        (PROFILE_CATEGORIES[control.data.profileType] ??
                          []) as string[]
                      }
                      freeSolo
                      renderInput={(params) => (
                        <OptiTextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          required
                          label="Category"
                          autoComplete="off"
                        />
                      )}
                      {...mapFormikToAutocomplete(formik, 'category')}
                      size="small"
                    />
                  </Box>

                  <Box marginY={2}>
                    <Autocomplete
                      options={DEFAULT_SERVICES}
                      freeSolo
                      multiple
                      renderInput={(params) => (
                        <OptiTextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          label={
                            control.data?.profileType === ProfileType.EMPLOYEE
                              ? 'Skills'
                              : 'Services'
                          }
                          autoComplete="off"
                          helperText={`Type to search; use Enter to add a new${
                            control.data?.profileType === ProfileType.EMPLOYEE
                              ? 'skill'
                              : 'service'
                          }.`}
                        />
                      )}
                      {...mapFormikToAutocomplete(formik, 'services')}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          'services',
                          newValue.map(capitalizeFirstLetter)
                        );
                      }}
                      size="small"
                    />
                  </Box>
                </Box>

                <Box display="flex" className={clsx(classes.floatingFooter)}>
                  <Box flex="1" margin={isMobile ? 0 : 1.5}>
                    <LoadingAwareButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      loading={formik.isSubmitting}
                    >
                      {control.data?.profileType === ProfileType.EMPLOYEE
                        ? 'Save Skills'
                        : 'Save Services'}
                    </LoadingAwareButton>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </OOModal>
    </>
  );
};

export default ServicesModal;
