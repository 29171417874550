import { NumberFormatInput, OptiTextField } from '@components';
import { Formik, mapFormikToTextField, useIsMobile } from '@lib';
import { Box, Button, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormikValues } from 'formik';
import * as React from 'react';
import { FC } from 'react';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

type ExperienceCardProps<Values extends FormikValues> = {
  formik: Formik<Values>;
  fieldName: string;
  removeExperience: () => void;
  isAdminPage?: boolean;
};

const ExperienceCard: FC<ExperienceCardProps<any>> = ({
  formik,
  fieldName,
  removeExperience,
  isAdminPage,
}) => {
  // const localClasses = useLocalStyles();
  const isMobile = useIsMobile();

  return (
    <Paper elevation={2}>
      <Box padding={2}>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={removeExperience}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Box>

        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gridGap={4}
        >
          <OptiTextField
            required={!isAdminPage}
            label="Company Name"
            placeholder="At which company did you work?"
            {...mapFormikToTextField(formik, `${fieldName}.companyName`)}
          />
          <OptiTextField
            required={!isAdminPage}
            label="Location"
            placeholder="Where was this job located?"
            {...mapFormikToTextField(formik, `${fieldName}.location`)}
          />
        </Box>
        <Box marginY={2} display="flex" gridGap={4}>
          <OptiTextField
            required={!isAdminPage}
            label="From"
            placeholder="e.g.: 2020"
            InputProps={{
              inputComponent: NumberFormatInput as any,
              inputProps: {
                onFocus: (e) => e.target.select(),
                decimalScale: 0,
                thousandSeparator: false,
              },
            }}
            {...mapFormikToTextField(formik, `${fieldName}.yearStart`)}
          />
          <OptiTextField
            label="To"
            placeholder="e.g.: 2022"
            InputProps={{
              inputComponent: NumberFormatInput as any,
              inputProps: {
                onFocus: (e) => e.target.select(),
                decimalScale: 0,
                thousandSeparator: false,
              },
            }}
            {...mapFormikToTextField(formik, `${fieldName}.yearEnd`)}
            helperText="Leave empty if you still work here"
          />
        </Box>
        <Box marginY={2}>
          <OptiTextField
            required={!isAdminPage}
            label="Job Title"
            placeholder="What position did you occupy?"
            {...mapFormikToTextField(formik, `${fieldName}.jobTitle`)}
          />
        </Box>
        <Box marginY={2}>
          <OptiTextField
            label="Description"
            placeholder="Describe shortly how was your work"
            multiline
            rows={7}
            {...mapFormikToTextField(formik, `${fieldName}.description`)}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default ExperienceCard;
