import { DVKPagination, DVKSort } from '@dvkiin/material-commons';
import queryString from 'query-string';
import {
  MutableRefObject,
  Reducer,
  useCallback,
  useReducer,
  useRef,
} from 'react';
import { useHistory } from 'react-router';

import {
  DVKPaginationState,
  PaginationState,
  toBothStates,
  toGraphQLPagination,
  toStandardSort,
  UpdatePaginationHandler,
} from '../pagination';

const defaultState = {
  pagination: { page: 0, rowsPerPage: 10 },
  sort: '',
};

function getPaginationFromUrl(history: any): DVKPagination | undefined {
  let parsedQry = queryString.parse(history.location.search);
  if (!parsedQry || !parsedQry.page || !parsedQry.rows) return undefined;

  let page = parseInt(parsedQry.page as any);
  let rows = parseInt(parsedQry.rows as any);

  return {
    page: page,
    rowsPerPage: rows,
  };
}

function getSortFromUrl(history: any): DVKSort | undefined {
  let parsedQry = queryString.parse(history.location.search);
  if (!parsedQry || !parsedQry.order || !parsedQry.orderBy) return undefined;

  let order = parsedQry.order as any;
  let orderBy = parsedQry.orderBy as any;

  return {
    order: order,
    orderBy: orderBy,
  };
}

export function usePaginationFromUrl(
  defaultPagination = defaultState
): PaginationState & {
  handlePaginationUpdate: UpdatePaginationHandler;
} {
  const history = useHistory();
  const handlePaginationUpdate = useCallback(
    (
      pagination: DVKPagination | undefined,
      sort: DVKSort | null | undefined
    ) => {
      let parsedSearch = queryString.parse(history.location.search);

      if (pagination) {
        parsedSearch.page = pagination && pagination.page.toString();
        parsedSearch.rows = pagination && pagination.rowsPerPage.toString();
      }

      if (sort) {
        parsedSearch.order = sort && sort.order;
        parsedSearch.orderBy = sort && sort.orderBy;
      }

      const stringified = queryString.stringify(parsedSearch);
      history.push(`${history.location.pathname}?${stringified}`);
    },
    [history]
  );

  let pagination = getPaginationFromUrl(history);
  if (!pagination) pagination = defaultPagination.pagination;

  let sort = getSortFromUrl(history);
  if (!sort) sort = toStandardSort(defaultPagination.sort);
  let graphQLPagination = toGraphQLPagination(pagination, sort);
  return {
    pagination,
    sort,
    graphQLPagination,
    handlePaginationUpdate,
  };
}

export function usePagination(
  defaultPagination: DVKPagination = defaultState.pagination,
  defaultSort: string = defaultState.sort
): PaginationState & {
  handlePaginationUpdate: UpdatePaginationHandler;
  stateRef: MutableRefObject<PaginationState>;
} {
  const [
    { pagination, sort, graphQLPagination },
    updatePagination,
  ] = useReducer<Reducer<PaginationState, DVKPaginationState>>(
    (oldState, { pagination, sort }) => {
      return toBothStates(pagination, sort);
    },
    toBothStates(defaultPagination, toStandardSort(defaultSort))
  );

  const handlePaginationUpdate = useCallback(
    (
      pagination: DVKPagination | undefined,
      sort: DVKSort | null | undefined
    ) => {
      updatePagination({
        pagination: { ...defaultState.pagination, ...(pagination || {}) },
        sort: sort || null,
      });
    },
    [updatePagination]
  );
  const stateRef = useRef<PaginationState>({
    pagination,
    sort,
    graphQLPagination,
  });
  stateRef.current.pagination = pagination;
  stateRef.current.sort = sort;
  stateRef.current.graphQLPagination = graphQLPagination;

  return {
    pagination,
    sort,
    graphQLPagination,
    handlePaginationUpdate,
    stateRef,
  };
}
