import { ErrorModal } from '@dvkiin/material-commons';
import { useEnhancedQuery, usePaginationFromUrl } from '@lib';
import React, { FC, useMemo } from 'react';

import {
  GetAllCompaniesDocument,
  GetAllCompaniesQuery,
} from '@optioffer/graphql';

import { ApolloModalErrors } from '@components/Feedback';

import CompanyTable from './Table';

const CompanyListPage: FC = () => {
  const {
    pagination,
    sort,
    graphQLPagination,
    handlePaginationUpdate,
  } = usePaginationFromUrl();
  const getAllCompaniesQuery = useEnhancedQuery<GetAllCompaniesQuery>(
    GetAllCompaniesDocument,
    {
      variables: { pagination: graphQLPagination },
    }
  );

  // region errors
  const errors = useMemo(
    () => [
      {
        error: getAllCompaniesQuery.error,
        message:
          'The server was unable to load the companies. Please try again later. If this persists, contact support.',
      },
    ],
    [getAllCompaniesQuery.error]
  );

  // endregion

  function renderTable() {
    if (getAllCompaniesQuery.error)
      return (
        <ErrorModal
          error={getAllCompaniesQuery.error}
          message="There was an error while loading the companies. Please try again later. If the problem persists, please contact and administrator."
        />
      );
    if (getAllCompaniesQuery.loading) return 'Loading...';
    if (!getAllCompaniesQuery.data) return 'There are no companies';
    return (
      <CompanyTable
        companies={getAllCompaniesQuery.data.getAllCompanies.companies}
        total={getAllCompaniesQuery.data.getAllCompanies.total}
        pagination={pagination}
        sort={sort}
        onPaginationUpdate={handlePaginationUpdate}
      />
    );
  }

  return (
    <>
      {renderTable()}

      <ApolloModalErrors errors={errors} />
    </>
  );
};

export default CompanyListPage;
