var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function getDetailedOfferItems(offerItems) {
    return offerItems.reduce(function (acc, oi, index) {
        acc.push(__assign(__assign({}, oi), { position: index + 1 }));
        oi.children.forEach(function (ch, chIndex) {
            var parent = offerItems.find(function (o) { return o.id === ch.parentId; });
            var parentCode = parent
                ? !parent.product
                    ? parent
                        .productBundle.items.map(function (item) { return item.product.code; })
                        .join('+')
                    : parent.product.code
                : undefined;
            acc.push(__assign(__assign({}, ch), { position: index + 1 + "." + (chIndex + 1), parentCode: parentCode }));
        });
        return acc;
    }, []);
}
