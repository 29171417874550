import { useHasRole } from '@lib';
import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { UserRole } from '@optioffer/graphql';

import ProjectListPage from './ListPage';

const ProjectModule: FC<RouteComponentProps> = ({ match }) => {
  const isSuperAdmin = useHasRole(UserRole.SUPER_ADMIN);
  if (!isSuperAdmin) return <Redirect to={'/'} />;

  return (
    <>
      <Route exact path={match.path} component={ProjectListPage} />
    </>
  );
};

export default ProjectModule;
