import { SecurityContext, useModal } from '@lib';
import * as React from 'react';
import { FC, useCallback, useContext } from 'react';

import NPSSurveyModal from '@containers/NPSSurvey/NPSSurveyModal';

import { NPSSurveyContext } from './context';

type NPSSurveyProviderProps = {};

const NPSAskInterval = 6 * 30 * 24 * 60 * 60 * 1000; // 6 months

const NPSSurveyProvider: FC<NPSSurveyProviderProps> = ({ children }) => {
  const control = useModal();
  const {
    currentUser: { lastNPS },
  } = useContext(SecurityContext);
  const tryToShowNPSSurvey = useCallback(() => {
    console.log(
      'tryToShowNPSSurvey',
      !lastNPS || lastNPS + NPSAskInterval < Date.now()
    );

    if (!lastNPS || lastNPS + NPSAskInterval < Date.now())
      requestAnimationFrame(() => control.open());
  }, [lastNPS, control]);

  return (
    <NPSSurveyContext.Provider value={{ tryToShowNPSSurvey }}>
      {children}
      <NPSSurveyModal control={control} />
    </NPSSurveyContext.Provider>
  );
};

export default NPSSurveyProvider;
