import {
  NumberFormatInput,
  OptiInlineTextField,
  OptiTextField,
  SelectAndTextInput,
} from '@components';
import {
  mapFormikToAutocomplete,
  mapFormikToMediaInput,
  mapFormikToSelectAndTextField,
  mapFormikToSelectField,
  mapFormikToTextField,
  SecurityContext,
  useMenu,
} from '@lib';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useContext } from 'react';

import { formatPrice, ooBrand } from '@optioffer/core';
import { AttributeType, CalculationType } from '@optioffer/graphql';

import MediaInput from '@components/form/MediaInput';
import SelectInput from '@components/form/SelectInput';

import AccessoryListItem from '@containers/ProductSection/AccessoryListItem';
import ProductModalContext from '@containers/ProductSection/ProductModal/context';
import useStyles from '@containers/ProductSection/styles';

import { SpecificationIcon } from '@resources/icons';
import addAccessoriesIllustration from '@resources/illustrations/add_accessories.svg';

import CheckBoxInput from '../../../components/form/CheckBoxInput';

const useLocalStyles = makeStyles((theme) => ({
  sectionBox: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    boxShadow: ooBrand.boxShadow.primary,
    backgroundColor: ooBrand.colors.pureWhite,
  },
  saveCheckboxFormControl: {
    marginTop: 0,
  },
  accessoriesIllustrations: {
    position: 'absolute',
    width: '30%',
    right: 0,
    top: '90%',
  },
}));

type ProductModalMobileProps = {};

const ProductModalMobile: FC<ProductModalMobileProps> = () => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const { company } = useContext(SecurityContext);
  const {
    control,
    formik,
    isAccessoryModal,

    canChangeSaveType,
    persistProductInDB,
    setPersistProductInDB,

    handleRemoveSpecification,
    handleUpdateSelectedProduct,

    handleCloneProduct,

    newAttributeModal,
    accessoryModal,
    accessorySearchModal,
    deleteModal,

    availableSuppliers,
  } = useContext(ProductModalContext);

  const moreOptionsMenu = useMenu();

  function renderGeneralInformation() {
    return (
      <Box className={localClasses.sectionBox} marginTop={2}>
        <Box display="flex" alignItems="center" width="100%" marginBottom={2}>
          <Typography className={classes.sectionHeading}>
            General Information
          </Typography>
        </Box>
        <Box marginTop={2}>
          <OptiTextField
            label="Product Name"
            required
            {...mapFormikToTextField(formik, 'name')}
          />
        </Box>
        <Box marginTop={2}>
          <OptiTextField
            label="Product Code"
            required
            {...mapFormikToTextField(formik, 'code')}
          />
        </Box>
        <Box marginTop={2}>
          <Autocomplete
            options={availableSuppliers}
            freeSolo
            renderInput={(params) => (
              <OptiTextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={'Supplier'}
                autoComplete="off"
              />
            )}
            {...mapFormikToAutocomplete(formik, 'supplier')}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.name;
            }}
            size="small"
          />
        </Box>
      </Box>
    );
  }

  function renderDescription() {
    return (
      <Box className={localClasses.sectionBox}>
        <Box display="flex" alignItems="center" width="100%">
          <Typography className={classes.sectionHeading}>
            Description
          </Typography>
        </Box>

        <OptiTextField
          label=""
          multiline
          rows={10}
          {...mapFormikToTextField(formik, 'description')}
        />
      </Box>
    );
  }

  function renderSpecifications() {
    return (
      <Box className={localClasses.sectionBox}>
        <Box marginBottom={2}>
          <Typography className={classes.sectionHeading}>
            Specifications
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column">
          <Box display="flex" marginBottom={2}>
            <Box marginRight={1} flex={1}>
              <TextField
                fullWidth
                placeholder="Search specifications or collections"
                variant="outlined"
                margin="none"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              className={classes.iconButton}
              onClick={() => newAttributeModal.open()}
            >
              <AddIcon />
            </Button>
          </Box>

          {formik.values.specifications.map((spec, idx) => (
            <Box
              key={spec.id}
              marginBottom={1}
              display="flex"
              alignItems="center"
            >
              <Box width={26} display="flex" alignItems="center">
                <SpecificationIcon
                  type={spec.type}
                  className={classes.specificationTypeIcon}
                />
              </Box>
              <Box flex={1} className={classes.specificationName}>
                {spec.name}
                {spec.unit ? ` (${spec.unit})` : ''}:
              </Box>
              <Box flex={1}>
                {formik.values.specifications[idx].type ===
                AttributeType.BOOLEAN ? (
                  <SelectInput
                    size="inline"
                    options={[
                      { label: <em>None</em>, value: '' },
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' },
                    ]}
                    {...mapFormikToSelectField(
                      formik,
                      `specifications[${idx}].value`
                    )}
                  />
                ) : (
                  <OptiInlineTextField
                    {...(formik.values.specifications[idx].type ===
                    AttributeType.NUMBER
                      ? {
                          alignTextRight: true,
                          InputProps: {
                            inputComponent: NumberFormatInput as any,
                          },
                        }
                      : {})}
                    {...mapFormikToTextField(
                      formik,
                      `specifications[${idx}].value`
                    )}
                  />
                )}
              </Box>
              <Box marginLeft={1}>
                <Button
                  className={clsx(classes.iconButton, classes.removeButton)}
                  variant="text"
                  onClick={() => handleRemoveSpecification(spec.id)}
                >
                  <CancelOutlinedIcon />
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  function renderCalculatedPrice(
    name: string,
    value: number,
    percentage?: boolean
  ) {
    return (
      <Box
        className={clsx(classes.simpleRow, classes.simpleRowWithLabelDesktop)}
        marginBottom={1}
      >
        <Box flex={1}>{name}</Box>

        <Box
          flex={1}
          textAlign="right"
          display="flex"
          justifyContent="space-between"
        >
          <Box paddingLeft="17px">
            {percentage ? '%' : company.currency.symbol}
          </Box>
          {formatPrice(value, company.currency, true)}
        </Box>
      </Box>
    );
  }

  function renderPricing() {
    const costValue = formik.values.costValue || 0;
    const price = formik.values.listPrice;
    const cost =
      formik.values.costType === CalculationType.FIXED
        ? costValue
        : costValue < 0
        ? (1 + costValue / 100) * price
        : (costValue / 100) * price;
    const profit = price - cost;

    return (
      <Box className={localClasses.sectionBox}>
        <Box marginBottom={2}>
          <Typography className={classes.sectionHeading}>Pricing</Typography>
        </Box>

        <Box display="flex" flexDirection="column" marginX={-2}>
          <Box
            className={clsx(
              classes.simpleRow,
              classes.simpleRowWithLabelDesktop
            )}
            marginBottom={1}
          >
            <Box flex={1}>List Price Unit</Box>

            <Box flex={1} textAlign="right" display="flex" marginRight={-1}>
              <OptiInlineTextField
                required
                alignTextRight
                {...mapFormikToTextField(formik, 'listPrice')}
                InputProps={{
                  inputComponent: NumberFormatInput as any,
                  inputProps: {
                    onFocus: (e) => e.target.select(),
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      {company.currency.symbol}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>

          <Box marginY={1.5}>
            <Divider />
          </Box>

          <Box
            className={clsx(
              classes.simpleRow,
              classes.simpleRowWithLabelDesktop
            )}
            marginBottom={1}
          >
            <Box flex={1}>Production Cost</Box>

            <Box flex={1} textAlign="right" marginRight={-1}>
              <SelectAndTextInput
                size="inline"
                options={[
                  { label: '%', value: CalculationType.PERCENTAGE },
                  {
                    label: company.currency.symbol,
                    value: CalculationType.FIXED,
                  },
                ]}
                {...mapFormikToSelectAndTextField(
                  formik,
                  'costType',
                  'costValue',
                  {},
                  {
                    InputProps: {
                      inputComponent: NumberFormatInput as any,
                      inputProps: {
                        textAlign: 'right',
                        onFocus: (e) => e.target.select(),
                      },
                    },
                  }
                )}
              />
            </Box>
          </Box>

          {renderCalculatedPrice('Margin', (profit / (price || 1)) * 100, true)}
          {renderCalculatedPrice('Profit', profit)}
        </Box>
      </Box>
    );
  }

  function renderAccessories() {
    if (isAccessoryModal) return;

    return (
      <Box className={localClasses.sectionBox}>
        <Box marginBottom={2}>
          <Typography className={classes.sectionHeading}>
            Accessories
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" width="100%">
          {!formik.values.accessories.length && (
            <Box paddingY={2} position="relative">
              <Box>
                <Typography>
                  Do you have any products that work well with this one? Add
                  them as Accessories to find them easily when creating a quote
                  with this product.
                </Typography>
              </Box>
              <img
                className={localClasses.accessoriesIllustrations}
                src={addAccessoriesIllustration}
                alt="add accessories illustration"
              />
            </Box>
          )}
          {formik.values.accessories.map((item, index, self) => (
            <AccessoryListItem
              key={item.version.id}
              item={item}
              handleRemove={(it) => handleUpdateSelectedProduct(it, false)}
              onClick={async (it) => accessoryModal.open(it)}
            >
              {index < self.length - 1 && (
                <Box marginX={-2}>
                  <Divider />
                </Box>
              )}
            </AccessoryListItem>
          ))}
          <Box marginTop={3}>
            <Button
              className={classes.flatButton}
              onClick={() => {
                accessorySearchModal.open({
                  productName: formik.values.name,
                  productVersionCode: formik.values.code,
                  productAccessories: formik.values.accessories,
                });
              }}
            >
              <AddCircleOutlineOutlinedIcon /> Add Accessories
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  function renderMedia() {
    return (
      <Box className={localClasses.sectionBox}>
        <Box marginBottom={2}>
          <Typography className={classes.sectionHeading}>
            Photos & Videos
          </Typography>
        </Box>

        <MediaInput
          productCode={formik.values.code}
          {...mapFormikToMediaInput(formik, 'media')}
        />
      </Box>
    );
  }

  function renderButtons() {
    return (
      <>
        {control.data && !canChangeSaveType && (
          <Box marginRight={4}>
            <Button
              variant="outlined"
              color="primary"
              onClick={moreOptionsMenu.open}
            >
              <MoreVertIcon />
            </Button>
            <Menu
              anchorEl={moreOptionsMenu.anchorElement}
              keepMounted
              open={moreOptionsMenu.isOpen}
              onClose={moreOptionsMenu.close}
            >
              <MenuItem
                onClick={() => {
                  moreOptionsMenu.close();
                  return handleCloneProduct();
                }}
              >
                <ListItemIcon>
                  <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Clone" />
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  moreOptionsMenu.close();
                  deleteModal.open();
                }}
              >
                <ListItemIcon>
                  <DeleteIcon
                    fontSize="small"
                    htmlColor={ooBrand.colors.error}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Delete"
                  style={{ color: ooBrand.colors.error }}
                />
              </MenuItem>
            </Menu>
          </Box>
        )}
        <Box flex={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={!formik.dirty}
          >
            {!formik.dirty ? 'Changes Saved' : 'Save Changes'}
          </Button>
        </Box>
      </>
    );
  }

  function renderContent() {
    return (
      <>
        {renderGeneralInformation()}
        {renderDescription()}
        {renderMedia()}
        {renderSpecifications()}
        {renderAccessories()}
        {renderPricing()}
      </>
    );
  }

  return (
    <Box style={{ backgroundColor: ooBrand.colors.gray['50'] }}>
      {renderContent()}

      <Box height={canChangeSaveType ? 120 : 80} />

      <Box
        display="flex"
        flexDirection="column"
        className={clsx(classes.floatingFooter)}
      >
        {canChangeSaveType && (
          <CheckBoxInput
            formControlClassName={localClasses.saveCheckboxFormControl}
            label="Save only for this Quote"
            checked={!persistProductInDB}
            onChange={(event, checked) => setPersistProductInDB(!checked)}
          />
        )}
        <Box display="flex">{renderButtons()}</Box>
      </Box>
    </Box>
  );
};

export default ProductModalMobile;
