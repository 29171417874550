import { Box, Button, makeStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ooBrand } from '@optioffer/core';
import { OfferInOfferModuleFragment } from '@optioffer/graphql';

import useLayoutStyles from '@containers/Layout/styles';

import {
  CustomerStepIcon,
  ProductsStepIcon,
  ReviewStepIcon,
  SendStepIcon,
  StepDoneIcon,
} from '@resources/icons';

import { OOTheme } from '../../../theme';
import useStyles from '../styles';

const useLocalStyles = makeStyles((theme: OOTheme) => ({
  root: {
    margin: theme.spacing(-1.5, -2, 0),
  },
  stepperWrapper: {
    height: 48,
  },
  step: {},
  activeStep: {
    '& $stepIconWrapper': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  stepIconWrapper: {
    position: 'relative',
    height: theme.spacing(4),
    width: theme.spacing(4),
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    backgroundColor: ooBrand.colors.gray.A100,
    borderRadius: '50%',
  },
  stepIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    color: ooBrand.colors.pureWhite,
  },
  stepDoneIcon: {
    position: 'absolute',
    top: '60%',
    left: '60%',
  },
}));

type DesktopHeaderProps = {
  offer?: OfferInOfferModuleFragment;
  step: number;
  setStep: (step: number) => void;
};

const DesktopHeader: FC<DesktopHeaderProps> = ({ step, setStep }) => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const layoutClasses = useLayoutStyles();
  // const context = useContext(SecurityContext);
  const { push } = useHistory();

  return (
    <Box
      boxShadow={1}
      className={clsx(localClasses.root, layoutClasses.toolbar)}
    >
      <Button
        aria-label="back to quote list"
        variant="text"
        onClick={() => push('/offer')}
        className={classes.grayTextButton}
      >
        <ChevronLeft />
        Back to Quotes
      </Button>
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Button
          className={clsx(
            localClasses.step,
            step === 1 && localClasses.activeStep
          )}
          variant="text"
          onClick={() => setStep(1)}
        >
          <Box className={localClasses.stepIconWrapper}>
            <CustomerStepIcon className={localClasses.stepIcon} />
            {step > 1 && <StepDoneIcon className={localClasses.stepDoneIcon} />}
          </Box>
          1. Customer
        </Button>
        <Button
          className={clsx(
            localClasses.step,
            step === 2 && localClasses.activeStep
          )}
          variant="text"
          onClick={() => setStep(2)}
        >
          <Box className={localClasses.stepIconWrapper}>
            <ProductsStepIcon className={localClasses.stepIcon} />
            {step > 2 && <StepDoneIcon className={localClasses.stepDoneIcon} />}
          </Box>
          2. Products
        </Button>
        <Button
          className={clsx(
            localClasses.step,
            step === 3 && localClasses.activeStep
          )}
          variant="text"
          onClick={() => setStep(3)}
        >
          <Box className={localClasses.stepIconWrapper}>
            <ReviewStepIcon className={localClasses.stepIcon} />
            {step > 3 && <StepDoneIcon className={localClasses.stepDoneIcon} />}
          </Box>
          3. Review
        </Button>
        <Button
          className={clsx(
            localClasses.step,
            step === 4 && localClasses.activeStep
          )}
          variant="text"
          onClick={() => setStep(4)}
        >
          <Box className={localClasses.stepIconWrapper}>
            <SendStepIcon className={localClasses.stepIcon} />
            {step > 4 && <StepDoneIcon className={localClasses.stepDoneIcon} />}
          </Box>
          4. Send
        </Button>
      </Box>
    </Box>
  );
};

export default DesktopHeader;
