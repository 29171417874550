import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  cardRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '& > *': {
      flex: 1,

      '&:first-child': {
        marginRight: theme.spacing(2),
      },
    },
  },
  card: {
    maxWidth: 700,
  },
  toolbarRight: {
    marginRight: -12,
    marginLeft: 'auto',
  },
  addButton: {
    marginLeft: theme.spacing(1),
    minWidth: 150,
  },

  offerItemProductCard: {
    maxHeight: 96,
  },

  accessoryCard: {
    cursor: 'pointer',
  },

  offerItemRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  offerItemPosition: {
    width: 0,
    marginRight: theme.spacing(2),
  },
  offerItemPositionWithParent: {
    width: 40,
  },
  productThumbnailWrapper: {
    alignSelf: 'center',
  },
  productThumbnail: {
    maxHeight: 80, // 🔨
  },
  offerItemName: {
    maxHeight: 64, // 🔨
    overflow: 'hidden',
  },
  offerItemSpecifications: {
    minWidth: 200,

    display: 'none',

    [theme.breakpoints.up('lg')]: {
      display: 'initial',
    },
  },
  offerItemPriceValues: {
    minWidth: 110,
  },
  offerItemActions: {
    alignSelf: 'center',
  },
  createProformaButton: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  socialIcon: {
    '& svg': {
      width: theme.spacing(3),
      marginRight: theme.spacing(1),
    },
  },
  header: {
    marginLeft: 12,
    color: theme.palette.primary.main,
  },
  toolbar: {
    height: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
  searchInput: {
    marginRight: theme.spacing(2),
    fontSize: 14,
    height: 40,
    backgroundColor: theme.palette.common.white,

    [theme.breakpoints.up('lg')]: {
      width: 350,
    },
  },
  pickerInput: {
    width: 200,
    marginRight: theme.spacing(2),
    fontSize: 14,
    height: 40,
    backgroundColor: theme.palette.common.white,
    color: '#707070',
    '& .MuiInputBase-root': {
      fontSize: 14,
    },
  },
  icon: {
    width: 20,
    height: 20,
  },
  searchBar: { position: 'relative', display: 'inline-block' },
  inlineBlock: {
    display: 'inline-block',
    color: '#707070',
    fontSize: 17,
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
  },
  activeStatus: {
    color: '#0f5c52',
    borderBottom: '2px solid #0f5c52',
  },

  iconButton: {
    padding: 6,
    minWidth: 40,
  },
}));
