import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { FC } from 'react';

import NotificationCard from './NotificationCard';
import { CustomNotification } from './domain';

type NotificationsTableProps = {
  notifications: any[];
  total: number;
  page: number;
  handlePageChange: (page: number) => void;
  onSnooze: (obj: CustomNotification) => void;
  onDismiss: (obj: CustomNotification) => void;
  onGoToOffer: (obj: CustomNotification) => void;
};

const NotificationsTable: FC<NotificationsTableProps> = ({
  notifications,
  total,
  page,
  handlePageChange,
  onSnooze,
  onDismiss,
  onGoToOffer,
}) => {
  const PER_PAGE = 10;

  const count = Math.ceil(total / PER_PAGE);

  return (
    <Box marginBottom={5} paddingBottom={3}>
      {notifications.map((notification) => (
        <>
          <NotificationCard
            key={notification.id}
            notification={notification}
            onSnooze={onSnooze}
            onDismiss={onDismiss}
            onGoToOffer={onGoToOffer}
          />
        </>
      ))}
      <Pagination
        count={count}
        page={page + 1}
        onChange={(event, page) => handlePageChange(page - 1)}
      />
    </Box>
  );
};

export default NotificationsTable;
