import { DVKColumn, DVKField } from '@dvkiin/material-commons';

export const columns = [
  { name: 'name', label: 'Name', type: 'text' },
  { name: 'email', label: 'Email', type: 'email' },
  { name: 'phoneNumber', label: 'Phone number', type: 'text' },
] as DVKColumn[];

export default [
  {
    name: 'name',
    label: 'Contact person',
    type: 'text',
    required: true,
    autoFocus: true,
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    errorMessage: 'This is not a valid email address.',
  },
  {
    name: 'phoneNumber',
    label: 'Phone number',
    type: 'text',
    errorMessage: 'We were unable to recognize this phone number.',
  },
  { name: 'customerNo', label: 'Customer No', type: 'text' },
  { name: 'companyName', label: 'Company name', type: 'text' },
  {
    name: 'companyVatId',
    label: 'Company VAT ID',
    type: 'text',
    errorMessage: 'This is not a valid company VAT ID.',
  },
  {
    name: 'companyEmail',
    label: 'Company Email',
    type: 'email',
    errorMessage: 'This is not a valid email address.',
  },
  { name: 'companyAddress', label: 'Company address', type: 'text' },
  { name: 'country', label: 'Country', type: 'text' },
  { name: 'city', label: 'City', type: 'text' },
  { name: 'zipCode', label: 'ZipCode', type: 'text' },
  {
    name: 'companyPhoneNumber',
    label: 'Company Phone number',
    type: 'text',
    errorMessage: 'We were unable to recognize this phone number.',
  },
] as DVKField[];
