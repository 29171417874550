import React, { FC } from 'react';

import { Project } from '@optioffer/graphql';

import OOTable, {
  OOTableCell,
  OOTableGenericProps,
  OOTableRow,
} from '@components/Table/OOTable';

type ProjectTableProps<T = Project> = OOTableGenericProps<T>;

const ProjectTable: FC<ProjectTableProps> = ({ onEdit, ...rest }) => {
  return (
    <OOTable
      {...rest}
      onEdit={onEdit}
      renderHeader={() => (
        <>
          <OOTableCell component="th" width="20%">
            Name
          </OOTableCell>
          <OOTableCell width="20%">Location</OOTableCell>
          <OOTableCell width="20%">Status</OOTableCell>
        </>
      )}
      renderRow={(row) => (
        <OOTableRow key={row.name} onClick={() => onEdit(row)}>
          <OOTableCell scope="row">{row.name}</OOTableCell>
          <OOTableCell scope="row">{row.location}</OOTableCell>
          <OOTableCell scope="row">{row.projectStatus}</OOTableCell>
        </OOTableRow>
      )}
    />
  );
};

export default ProjectTable;
