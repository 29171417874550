import { DVKColumn } from '@dvkiin/material-commons';
import { SubscriptionStatus } from '@lib';
import React from 'react';

import { StatusChip } from '../../../components/Chip';

export const columns = [
  { name: 'name', label: 'Name', type: 'text' },
  { name: 'ownerEmail', label: 'Owner email', type: 'text', noSort: true },
  { name: 'nrOfUsers', label: '#Users', type: 'number', noSort: true },
  { name: 'nrOfProducts', label: '#Products', type: 'number', noSort: true },
  { name: 'nrOfOffers', label: '#Offers', type: 'number', noSort: true },
  { name: 'plan.name', label: 'Plan', type: 'text', noSort: true },
  {
    name: 'status',
    label: 'Status',
    type: 'text',
    noSort: true,
    project: (status: SubscriptionStatus) => <StatusChip status={status} />,
  },
] as DVKColumn[];
