import { OptiTextField } from '@components';
import {
  capitalizeFirstLetter,
  Formik,
  mapFormikToAutocomplete,
  mapFormikToMediaInput,
  mapFormikToTextField,
  useIsMobile,
} from '@lib';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import { FormikValues } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import MediaInput from '@components/form/MediaInput';

import { DEFAULT_SERVICES } from '@lib/profile';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

type ProjectContributionCardProps<Values extends FormikValues> = {
  formik: Formik<Values>;
  fieldName: string;
  removeProjectContribution: () => void;
  isAdminPage?: boolean;
};

const ProjectContributionCard: FC<ProjectContributionCardProps<any>> = ({
  formik,
  fieldName,
  removeProjectContribution,
  isAdminPage,
}) => {
  // const localClasses = useLocalStyles();
  const isMobile = useIsMobile();

  return (
    <Paper elevation={2}>
      <Box padding={2}>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={removeProjectContribution}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Box>

        <Typography>About the project</Typography>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gridGap={4}
        >
          <OptiTextField
            required={!isAdminPage}
            label="Project Name"
            {...mapFormikToTextField(formik, `${fieldName}.project.name`)}
          />
          <OptiTextField
            required={!isAdminPage}
            label="Location"
            {...mapFormikToTextField(formik, `${fieldName}.project.location`)}
          />
        </Box>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gridGap={4}
        >
          <OptiTextField
            required={!isAdminPage}
            label="Date"
            placeholder="e.g.: 2022"
            {...mapFormikToTextField(formik, `${fieldName}.project.date`)}
          />
          <OptiTextField
            label="Link"
            {...mapFormikToTextField(formik, `${fieldName}.project.link`)}
          />
        </Box>
        <Box marginBottom={2}>
          <Typography>Image</Typography>
          <MediaInput
            productCode="project-picture"
            {...mapFormikToMediaInput(formik, `${fieldName}.project.media`)}
          />
        </Box>
        <Box marginY={2}>
          <OptiTextField
            label="Description"
            placeholder="Describe the project shortly"
            multiline
            rows={7}
            {...mapFormikToTextField(
              formik,
              `${fieldName}.project.description`
            )}
          />
        </Box>

        <Typography>About your contribution</Typography>
        <Box marginY={2}>
          <OptiTextField
            label="Your contribution"
            placeholder="Describe your contribution in this project"
            multiline
            rows={7}
            {...mapFormikToTextField(formik, `${fieldName}.description`)}
          />
        </Box>
        <Box marginY={2}>
          <Autocomplete
            options={DEFAULT_SERVICES}
            freeSolo
            multiple
            renderInput={(params) => (
              <OptiTextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={'Services provided'}
                autoComplete="off"
                helperText={`Type to search; use Enter to add a new service`}
              />
            )}
            {...mapFormikToAutocomplete(formik, `${fieldName}.services`)}
            onChange={(event, newValue) => {
              formik.setFieldValue(
                `${fieldName}.services`,
                newValue.map(capitalizeFirstLetter)
              );
            }}
            size="small"
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default ProjectContributionCard;
