import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  from,
  InMemoryCache,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getCookie } from '@lib';
import { createUploadLink } from 'apollo-upload-client';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

const uploadLink = createUploadLink({ uri: process.env.REACT_APP_GRAPHQL });

const variablesSanitizers = [
  // do not send the fullImage and thumbnail to server
  function (this: any, key: string, value: any) {
    if (value && value['__typename'] === 'Image') {
      this[key] = { fileName: value.fileName };
      return this[key];
    } else {
      return value;
    }
  },
].reduce(
  (acc, it) =>
    function (key: string, value: any) {
      return acc(key, it(key, value));
    }
);

const authMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    // make sure we don't send crap to server
    JSON.stringify(operation.variables, variablesSanitizers);
  }
  const fbc = getCookie('_fbc');
  const fbp = getCookie('_fbp');
  operation.setContext({
    headers: {
      'x-token': localStorage.getItem('x-token') || null,
      'x-refresh-token': localStorage.getItem('x-refresh-token') || null,
      ...(fbc ? { 'x-fbc': fbc } : {}),
      ...(fbp ? { 'x-fbp': fbp } : {}),
    },
  });

  return forward!(operation);
});

const authAfterware = new ApolloLink((operation, forward) =>
  forward!(operation).map((response) => {
    const {
      response: { headers },
    } = operation.getContext();
    const newAccessToken = headers && headers.get('x-token');
    newAccessToken && localStorage.setItem('x-token', newAccessToken);

    return response;
  })
);

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((graphQLError) =>
      console.error('Server request error', graphQLError)
    );
  if (networkError) console.error('Server communication error', networkError);

  // TODO handle UNAUTHENTICATED (redirect to login with old link, do NOT double redirect on get current user)
  // TODO format user input error

  if (networkError && (networkError as ServerError).statusCode === 401) {
    // TODO some global error management
    localStorage.removeItem('x-token');
    localStorage.removeItem('x-refresh-token');

    window.location.href = '/';
  }
});

const cache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'OfferItemProduct':
        return object.id + object.offerId; // we do this so offers with different product versions are not conflicted
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
  typePolicies: {
    OfferClient: {
      merge: false,
    },
  },
});

const {
  link: statusNotifierLink,
  useApolloNetworkStatus: statusNotifierHook,
} = createNetworkStatusNotifier();

const client = new ApolloClient({
  cache: cache,
  link: from([
    statusNotifierLink,
    logoutLink,
    authMiddleware,
    authAfterware,
    uploadLink as any,
  ]),
});

export const useApolloNetworkStatus = statusNotifierHook;

export default client;
