import {
  ConfirmationModal,
  DVKColumn,
  DVKField,
  DVKFieldType,
  DVKForm,
  FlexExpander,
  InputModal,
  useModal,
} from '@dvkiin/material-commons';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import React, { FC, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { ApolloModalErrors, ApolloSnackbars } from '../../components/Feedback';
import {
  booleanResultToError,
  getInvalidFields,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
} from '../../lib';
import {
  attributeTypeToDVKType,
  getAttributeTypeName,
} from '../../lib/attribute';
import { AttributeCollection } from './domain';
import { attributeCollectionListFields } from './fields';
import {
  DELETE_ATTRIBUTE_COLLECTION,
  FIND_ALL_ATTRIBUTE_TYPES,
  GET_ATTRIBUTE_COLLECTION,
  UPDATE_ATTRIBUTE_COLLECTION,
} from './graphql';
import useStyles from './styles';

type AttributeCollectionPageParams = {
  id: string;
};

const AttributeCollectionViewEditPage: FC<
  RouteComponentProps<AttributeCollectionPageParams>
> = ({
  match: {
    params: { id },
  },
  history: { replace },
}) => {
  const { viewEditCard } = useStyles();

  const {
    isOpen: isDeleteModalOpen,
    data: deleteModalData,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal();
  const [defaultValueType] = useState<DVKFieldType>('text');

  //region queries
  const {
    data: { getAttributeCollection } = { getAttributeCollection: undefined },
    error: getError,
    loading,
  } = useEnhancedQuery<{ getAttributeCollection: AttributeCollection }>(
    GET_ATTRIBUTE_COLLECTION,
    { variables: { id } }
  );
  const {
    data: { findAllAttributeTypes } = { findAllAttributeTypes: [] },
  } = useEnhancedQuery<{ findAllAttributeTypes: string[] }>(
    FIND_ALL_ATTRIBUTE_TYPES
  );
  //endregion

  //region mutations
  const [
    updateAttributeCollection,
    { data: updateResult, error: updateError },
  ] = useEnhancedMutation(UPDATE_ATTRIBUTE_COLLECTION, {
    refetchQueries: ['findAllAttributeCollections'],
  });
  const [
    deleteAttributeCollection,
    { data: deleteResult, error: deleteError },
  ] = useEnhancedMutation(DELETE_ATTRIBUTE_COLLECTION, {
    refetchQueries: ['findAllAttributeCollections'],
  });
  //endregion

  //region fields
  const attributeCollectionFields = useMemo(() => {
    return [
      {
        name: 'id',
        label: 'Unique id',
        type: 'text',
        disabled: true,
      } as DVKField,
      ...attributeCollectionListFields,
      {
        name: 'attributes',
        label: 'Attributes',
        type: 'list',
        fields: [
          {
            name: 'index',
            label: '#',
            type: 'number',
            disabled: true,
          },
          {
            name: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            autoFocus: true,
          },
          {
            name: 'type',
            label: 'Type',
            type: 'select',
            required: true,
            values: findAllAttributeTypes.map((type) => ({
              name: type,
              label: getAttributeTypeName(type),
            })),
            project: getAttributeTypeName,
          },
          {
            name: 'description',
            label: 'Description',
            type: 'text',
            multiline: true,
          },
          { name: 'unit', label: 'Measuring unit', type: 'text' },
          {
            name: 'defaultValue',
            label: 'Default Value',
            type: attributeTypeToDVKType(defaultValueType),
          } as DVKField & DVKColumn,
        ],
      },
    ] as DVKField[];
  }, [findAllAttributeTypes, defaultValueType]);
  //endregion

  //region errors
  const errors = useMemo(
    () => [
      {
        error: getError,
        message:
          'There was an error while retrieving the attribute collection. Please try again.',
      },
      {
        error: updateError,
        message:
          'There was an error while updating the attribute collection. Please check your input and try again.',
      },
      {
        error: booleanResultToError(deleteResult, 'deleteAttributeCollection'),
        message:
          'There was an error while deleting the attribute collection. Please try again.',
      },
      {
        error: deleteError,
        message:
          'There was an error while deleting the attribute collection. Please try again.',
      },
    ],
    [getError, updateError, deleteResult, deleteError]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      {
        result: deleteResult,
        message: 'Attribute collection deleted.',
        booleanKey: 'deleteAttributeCollection',
      },
      updateResult && {
        result: updateResult,
        message: `Attribute collection '${updateResult.updateAttributeCollection.name}' updated.`,
      },
    ],
    [deleteResult, updateResult]
  );

  //endregion

  async function handleSave({ name, description, attributes }: any) {
    try {
      await updateAttributeCollection({
        variables: {
          id,
          attributeCollection: { name, description, attributes },
        },
      });
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  function renderAttributeCollection(attributeCollection: AttributeCollection) {
    const invalidFields = getInvalidFields(updateError);
    return (
      <Grid container spacing={3}>
        <Grid item>
          <Card className={viewEditCard}>
            <CardHeader title={attributeCollection.name} />
            <DVKForm
              fields={[...attributeCollectionFields]}
              defaultValue={
                {
                  ...attributeCollection,
                  attributes: attributeCollection.attributes.map(
                    (item, index) => ({ ...item, index: index + 1 })
                  ),
                } as any
              }
              ContentWrapper={CardContent}
              ActionsWrapper={CardActions}
              InputModal={InputModal}
              renderActions={() => (
                <>
                  <Button onClick={() => openDeleteModal(attributeCollection)}>
                    Delete
                  </Button>
                  <FlexExpander />
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </>
              )}
              onSubmit={handleSave}
              invalidFields={invalidFields}
            />
          </Card>
        </Grid>
      </Grid>
    );
  }

  async function handleAttributeCollectionDelete() {
    const {
      data: { deleteAttributeCollection: deleteAttributeCollectionResult },
    } = await deleteAttributeCollection({
      variables: { id: deleteModalData.id },
    });
    if (deleteAttributeCollectionResult) {
      replace('/attributeCollection');
    }
  }

  return (
    <>
      {loading && 'Loading your attribute collection...'}{' '}
      {/* fancy loading screen here */}
      <ConfirmationModal
        open={isDeleteModalOpen}
        onCancel={closeDeleteModal}
        onAccept={handleAttributeCollectionDelete}
        message={`Are you sure you want to delete attribute collection '${
          deleteModalData && deleteModalData.code
        }'?`}
        title="Please confirm attribute collection delete"
      />
      {getAttributeCollection &&
        renderAttributeCollection(getAttributeCollection)}
      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default AttributeCollectionViewEditPage;
