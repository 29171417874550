import { DVKTable } from '@dvkiin/material-commons';
import { Paper } from '@material-ui/core';
import React, { FC } from 'react';

import { PlanOnAdminPageFragment } from '@optioffer/graphql';

import { columns } from './fields';
import useStyles from './styles';

type PlansTableProps = {
  plans: PlanOnAdminPageFragment[];
  total: number;
  onEdit: (plan: any) => void;
};

const PlansTable: FC<PlansTableProps> = ({ plans, total, onEdit }) => {
  const { tableCard } = useStyles();

  return (
    <Paper className={tableCard}>
      <DVKTable
        columns={columns}
        rows={plans}
        total={total}
        onRowClick={onEdit}
      />
    </Paper>
  );
};

export default PlansTable;
