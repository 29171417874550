import {
  SecurityContext,
  useEnhancedMutation,
  useEnhancedQuery,
  useHasRole,
  useIsMobile,
} from '@lib';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import clsx from 'clsx';
import React, { FC, Fragment, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ooBrand } from '@optioffer/core';
import {
  Home_CreateDemoOfferDocument,
  Home_OnboardingCompletionDocument,
  UserRole,
} from '@optioffer/graphql';

import { getQueryName } from '@lib/graphql';

import createDemoQuoteIllustration from '@resources/illustrations/demo_quote.svg';

import ProfileSetupCard from './Profile/ProfileSetupCard';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: 0,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    padding: theme.spacing(3, 2),
  },
  demoCard: {
    backgroundColor: `${ooBrand.colors.primary.default}19`, // 10%
    gap: theme.spacing(2),
    position: 'relative',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      paddingLeft: theme.spacing(25),
      marginTop: theme.spacing(20),
    },
  },
  demoCardIllustration: {
    display: 'none',
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  normalCard: {
    boxShadow: ooBrand.boxShadow.primary,
  },
  tasksCard: {
    [theme.breakpoints.up('lg')]: {},
  },
  header1: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  header1V2: {
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.palette.primary.dark,
    padding: theme.spacing(0, 5),
    marginBottom: theme.spacing(3),
  },
  header2: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600, // semi-bold
  },
  helpButton: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    '& svg': {
      marginRight: theme.spacing(1.5),
    },
  },
  tasksProgressBar: {
    width: 120,
    alignSelf: 'center',
    marginBottom: theme.spacing(1.2),
    borderRadius: 4,
  },
  task: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    padding: theme.spacing(2, 0),

    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0.5, 0),
      padding: theme.spacing(2, 1.5),
      backgroundColor: ooBrand.colors.gray['100'],
      borderRadius: 5,
      border: `1px solid transparent`,
      cursor: 'pointer',

      '&:hover': {
        borderColor: `${ooBrand.colors.primary['100']}64`, // 40%
      },
    },
  },
  backgroundTaskProgressRing: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: ooBrand.colors.black,
    opacity: '7%',
  },
  taskCategoryCompletion: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600, // semi-bold
  },
  taskCategoryTitle: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 600, // semi-bold
  },
  taskCategorySubTitle: {
    fontSize: theme.typography.pxToRem(12),
    color: ooBrand.colors.gray['500'],
    maxWidth: 200,
  },
  taskText: {
    flex: 1,
  },
  taskTextComplete: {
    textDecoration: 'line-through',
    color: `${ooBrand.colors.black}64`, // 40%
  },
  taskCompleteIcon: {
    color: theme.palette.primary.main,
  },
  taskBubble: {
    borderRadius: '50%',
    width: theme.spacing(1),
    height: theme.spacing(1),
    margin: theme.spacing(0, 1),
  },
  taskIcon: {
    color: theme.palette.primary.main,
  },
}));

type OnboardingTaskCategory = {
  title: string;
  subTitle: string;
};

type OnboardingTask = {
  category: OnboardingTaskCategory;
  text: string;
  onClick(): void;
  complete: boolean;
};

const TASK_CATEGORY_DB: OnboardingTaskCategory = {
  title: 'Create your database',
  subTitle: 'Add products and contacts to easily assign them to quotes later',
};

const TASK_CATEGORY_QUOTE_SETTINGS: OnboardingTaskCategory = {
  title: 'Complete quote information',
  subTitle:
    'Manage information that will show up in your quote to better inform your customers',
};

const SupplierHome: FC = () => {
  const localClasses = useLocalStyles();
  const isMobile = useIsMobile();
  const isManager = useHasRole(UserRole.MANAGER);
  const { push } = useHistory();
  const { currentProfile } = useContext(SecurityContext);

  const taskCompletion = useEnhancedQuery(Home_OnboardingCompletionDocument);
  const [createDemoOffer] = useEnhancedMutation(Home_CreateDemoOfferDocument, {
    refetchQueries: [getQueryName(Home_OnboardingCompletionDocument)],
  });

  const tasks: OnboardingTask[] = useMemo(
    () => [
      {
        category: TASK_CATEGORY_DB,
        text: 'Add your first product',
        onClick: () => push('/product'),
        complete: !!taskCompletion.data?.findAllProducts.total,
      },
      {
        category: TASK_CATEGORY_DB,
        text: 'Add your first customer',
        onClick: () => push('/client'),
        complete: !!taskCompletion.data?.findAllClients.total,
      },
      ...(isManager
        ? [
            {
              category: TASK_CATEGORY_QUOTE_SETTINGS,
              text: 'Set up company information',
              onClick: () => push('/settings/companyDetails'),
              complete:
                !!taskCompletion.data?.getCompanyDetails.email ||
                !!taskCompletion.data?.getCompanyDetails.address ||
                !!taskCompletion.data?.getCompanyDetails.website ||
                !!taskCompletion.data?.getCompanyDetails.phoneNumber ||
                !!taskCompletion.data?.getCompanyDetails.motto ||
                !!taskCompletion.data?.getCompanyDetails.vat ||
                !!taskCompletion.data?.getCompanyDetails.companyLogo,
            },
            {
              category: TASK_CATEGORY_QUOTE_SETTINGS,
              text: 'Set up taxes',
              onClick: () => push('/settings/taxes'),
              complete: !!taskCompletion.data?.getCompanyDetails.defaultTaxes
                .length,
            },
            {
              category: TASK_CATEGORY_QUOTE_SETTINGS,
              text: 'Set up quote preferences',
              onClick: () => push('/settings/general'),
              complete:
                !!taskCompletion.data?.getCompanyDetails.companyIntro ||
                !!taskCompletion.data?.getCompanyDetails
                  .companyThankYouMessage ||
                !!taskCompletion.data?.getCompanyDetails.companyTerms ||
                !!taskCompletion.data?.getCompanyDetails.defaultOfferLanguage ||
                !!taskCompletion.data?.getCompanyDetails.defaultOfferFormat,
            },
          ]
        : []),
    ],
    [push, taskCompletion.data, isManager]
  );
  const noOfCompletedTasks = tasks.filter((it) => it.complete).length;
  const allTasksCompleted = tasks.length === noOfCompletedTasks;

  async function createDemoQuote() {
    const result = await createDemoOffer();
    result.data?.createDemoOffer.id &&
      (await push(`/new-offer/${result.data.createDemoOffer.id}`));
  }

  function renderTask(
    task: OnboardingTask,
    idx: number,
    self: OnboardingTask[]
  ) {
    return (
      <Fragment key={task.text}>
        <Box className={localClasses.task} onClick={task.onClick}>
          {task.complete ? (
            <CheckCircleOutlinedIcon
              className={localClasses.taskCompleteIcon}
            />
          ) : (
            <Box
              className={localClasses.taskBubble}
              style={{
                backgroundColor: `${ooBrand.colors.primary.default}19`,
              }}
            />
          )}
          <Box
            className={clsx(
              localClasses.taskText,
              task.complete && localClasses.taskTextComplete
            )}
          >
            {task.text}
          </Box>
          {isMobile && !task.complete && (
            <Box className={localClasses.taskIcon}>
              <ChevronRightIcon />
            </Box>
          )}
        </Box>
        {isMobile && idx < self.length - 1 && <Divider />}
      </Fragment>
    );
  }

  return (
    <Container maxWidth="md" className={localClasses.root}>
      {currentProfile && <ProfileSetupCard />}

      {!taskCompletion.data?.currentUser?.marketingData.demoQuoteCreated && (
        <Box className={clsx(localClasses.card, localClasses.demoCard)}>
          <img
            className={localClasses.demoCardIllustration}
            src={createDemoQuoteIllustration}
            alt="person creating a demo quote on laptop"
          />
          <Box
            className={clsx(
              isMobile ? localClasses.header2 : localClasses.header1
            )}
          >
            See how it works without any set up
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={createDemoQuote}
            >
              Create Dummy Quote
            </Button>
          </Box>
        </Box>
      )}

      {!allTasksCompleted && (
        <Box
          className={clsx(
            localClasses.card,
            localClasses.normalCard,
            localClasses.tasksCard
          )}
        >
          <Box
            className={isMobile ? localClasses.header1 : localClasses.header1V2}
          >
            Get ready for quoting
          </Box>
          {isMobile ? (
            <>
              <Box
                alignSelf={'center'}
                marginTop={1}
                marginBottom={1}
                color={ooBrand.colors.gray['400']}
              >
                {noOfCompletedTasks} of {tasks.length} Steps Completed
              </Box>
              <LinearProgress
                className={localClasses.tasksProgressBar}
                variant="determinate"
                value={(noOfCompletedTasks / tasks.length) * 100}
              />
              <Box>
                {tasks.sort((a) => (a.complete ? 0 : -1)).map(renderTask)}
              </Box>
            </>
          ) : (
            <>
              {tasks
                .reduce(
                  (
                    acc: {
                      category: OnboardingTaskCategory;
                      completedTasks: number;
                      tasks: OnboardingTask[];
                    }[],
                    task
                  ) => {
                    let group = acc.find((it) => it.category === task.category);
                    if (!group) {
                      group = {
                        category: task.category,
                        completedTasks: 0,
                        tasks: [],
                      };
                      acc.push(group);
                    }
                    group.tasks.push(task);
                    group.completedTasks += task.complete ? 1 : 0;
                    return acc;
                  },
                  []
                )
                .flatMap((group, idx, self) => (
                  <Fragment key={group.category.title}>
                    <Box display="flex" paddingLeft={5} marginTop={3}>
                      <Box marginRight={2.5}>
                        <Box position="relative" display="inline-flex">
                          <CircularProgress
                            variant="determinate"
                            className={localClasses.backgroundTaskProgressRing}
                            size={60}
                            value={100}
                          />
                          <CircularProgress
                            size={60}
                            variant="determinate"
                            value={
                              (group.completedTasks / group.tasks.length) * 100
                            }
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box
                              className={localClasses.taskCategoryCompletion}
                            >
                              {group.completedTasks}/{group.tasks.length}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box flex={1}>
                        <Box className={localClasses.taskCategoryTitle}>
                          {group.category.title}
                        </Box>
                        <Box className={localClasses.taskCategorySubTitle}>
                          {group.category.subTitle}
                        </Box>
                      </Box>
                      <Box width={320}>
                        {group.tasks
                          .sort((a) => (a.complete ? 0 : -1))
                          .map(renderTask)}
                      </Box>
                    </Box>
                    {idx < self.length - 1 && (
                      <Box marginTop={2.5}>
                        <Divider />
                      </Box>
                    )}
                  </Fragment>
                ))}
            </>
          )}
        </Box>
      )}

      <Box className={clsx(localClasses.card, localClasses.normalCard)}>
        <Box className={localClasses.header2}>Need any help?</Box>
        <Box marginTop={2} display="flex" flexDirection="column" gridGap={16}>
          <Button
            variant="text"
            color="primary"
            className={localClasses.helpButton}
            href={`mailto:nichita.herput@optioffer.com?subject=I need help adding my product database`}
            target="_blank"
          >
            <FileCopyIcon fontSize="small" /> I need help adding my product
            database
          </Button>
          <Button
            variant="text"
            color="primary"
            className={localClasses.helpButton}
            href={`mailto:support@optioffer.com`}
            target="_blank"
          >
            <HelpOutlineIcon fontSize="small" />
            Ask us anything
          </Button>
          <Button
            variant="text"
            color="primary"
            className={localClasses.helpButton}
            href={`mailto:innovation@optioffer.com`}
            target="_blank"
          >
            <EditIcon fontSize="small" />I have a feature recommendation
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SupplierHome;
