import { Formik, ModalControl } from '@lib';
import { createContext, Dispatch, SetStateAction } from 'react';

import {
  CalculationType,
  SupplierInProductSectionModuleFragment,
} from '@optioffer/graphql';

import { ProductMedia } from '@components/form/MediaInput';

import { NewAttributeModalData } from '@containers/ProductSection/NewAttributeModal';
import { SearchAccessoryModalData } from '@containers/ProductSection/SearchAccessoryModal';

import { ProductCategory } from '@lib/product';

import { ProductSearchResult, ProductSpecificationWithAttrId } from '../domain';

type ProductModalFormik = Formik<{
  name: string;
  code: string;
  category: ProductCategory | string;
  supplier: SupplierInProductSectionModuleFragment | null;
  description: string;
  listPrice: number;
  costType: CalculationType;
  costValue: number;
  specifications: ProductSpecificationWithAttrId[];
  accessories: ProductSearchResult[];
  media: ProductMedia[];
}>;

const ProductModalContext = createContext<{
  control: ModalControl<ProductSearchResult>;
  isAccessoryModal?: boolean;
  availableSuppliers: SupplierInProductSectionModuleFragment[];
  canChangeSaveType: boolean;
  persistProductInDB: boolean;
  setPersistProductInDB: Dispatch<SetStateAction<boolean>>;

  handleRemoveSpecification(
    specificationId: ProductSpecificationWithAttrId['id']
  ): Promise<any>;
  handleUpdateSelectedProduct(
    product: ProductSearchResult,
    selected: boolean
  ): Promise<any>;

  handleCloneProduct(): Promise<any>;

  newAttributeModal: ModalControl<NewAttributeModalData>;
  accessoryModal: ModalControl<ProductSearchResult>;
  accessorySearchModal: ModalControl<SearchAccessoryModalData>;
  deleteModal: ModalControl;

  formik: ProductModalFormik;
}>(undefined as any);

export default ProductModalContext;
