import { DVKForm, DVKObject } from '@dvkiin/material-commons';
import {
  getInvalidFields,
  getOrigin,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
} from '@lib';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { Nullable } from '@optioffer/core';
import { Auth_SendSignupLinkDocument } from '@optioffer/graphql';

import useStyles from '../styles';
import LoginWrapper from '../wrapper';

type RequestSignupPageProps = {};

const RequestSignupPage: FC<RequestSignupPageProps> = () => {
  const { form, submit, footer } = useStyles();

  const [sendSignupLink, { data, error, loading }] = useEnhancedMutation(
    Auth_SendSignupLinkDocument,
    {
      error: {
        type: 'MODAL',
        message:
          'We were not able to send you the signup email. Please check your input and try again.',
      },
      success: { message: 'Email sent.' },
    }
  );

  const invalidFields = useMemo(() => getInvalidFields(error), [error]);
  const [emailSentTo, setEmailSentTo] = useState<string | null>(null);

  useEffect(() => setEmailSentTo((data && data.sendSignupLink.email) || null), [
    data,
  ]);

  async function handleSignup({ email, name }: DVKObject) {
    try {
      await sendSignupLink({
        variables: {
          email: email as string,
          name: name as Nullable<string>,
          origin: getOrigin(),
        },
      });
    } catch (_e) {
      NOOP_graphqlErrorManagement();
    }
  }

  function renderSuccess() {
    return (
      <>
        <Typography variant="body1" gutterBottom>
          We've sent a email to <strong>{emailSentTo}</strong>. Follow the
          instructions in the email to create your account!
        </Typography>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          className={submit}
          onClick={() => handleSignup({ email: emailSentTo! })}
        >
          Resend email
        </Button>
      </>
    );
  }

  function renderSignupForm() {
    return (
      <>
        <Typography variant="body2" gutterBottom>
          Please enter your email address and we'll send you a email to verify
          that it's yours.
        </Typography>

        <DVKForm
          className={form}
          fields={[
            {
              name: 'email',
              label: 'Email Address',
              type: 'email',
              required: true,
              autoFocus: true,
              errorMessage: {
                duplicate:
                  'An account exists with this email. Please login or reset your password.',
              },
            },
            {
              name: 'name',
              label: 'Name',
              type: 'text',
            },
          ]}
          invalidFields={invalidFields}
          onSubmit={handleSignup}
          bottomContent={
            <>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                className={submit}
              >
                Create free account
              </Button>
            </>
          }
        />
      </>
    );
  }

  return (
    <LoginWrapper title="Create a FREE account!" backLink>
      {emailSentTo ? renderSuccess() : renderSignupForm()}

      <Typography variant="body2" gutterBottom className={footer}>
        If you already did this, there should be an email waiting for you in
        your inbox. If not, you could try again or contact{' '}
        <a href="mailto:support@optioffer.com">support@optioffer.com</a>. We'll
        be more than happy to assist you!
      </Typography>
    </LoginWrapper>
  );
};

export default RequestSignupPage;
