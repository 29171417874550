import { DVKField } from '@dvkiin/material-commons';

import { taxIdTypes } from './domain';

export const taxIdFields = [
  {
    name: 'taxIdValue',
    label: 'Tax Id',
    type: 'text',
    required: true,
    errorMessage: 'This tax id is not valid',
  },
  {
    name: 'taxIdType',
    label: 'Tax Id type',
    type: 'combo-box',
    required: true,
    options: taxIdTypes
      .sort((a, b) => a.country.localeCompare(b.country))
      .map((tax) => ({
        name: JSON.stringify(tax),
        label: `${tax.country} - ${tax.description}`,
        countryCode: tax.countryCode,
      })),
  },
] as DVKField[];
