import { DVKForm, DVKObject, lett } from '@dvkiin/material-commons';
import {
  booleanResultToError,
  getInvalidFields,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
} from '@lib';
import { Button, Typography } from '@material-ui/core';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { taxIdTypes } from '@lib/TaxId/domain';
import { taxIdFields } from '@lib/TaxId/fields';

import {
  ApolloModalErrors,
  ApolloSnackbars,
} from '../../../components/Feedback';
import { BillingDetails } from '../domain';
import { DELETE_BILLING_DETAILS, UPDATE_BILLING_DETAILS } from '../graphql';
import useStyles from '../styles';

type TaxIdSectionProps = {
  getBillingDetails: BillingDetails | undefined;
};

const TaxIdSection: FC<TaxIdSectionProps> = ({ getBillingDetails }) => {
  const { taxIdSection, saveButton, taxIdField, deleteButton } = useStyles();
  const [showTaxIdInput, setShowTaxIdInput] = useState(false);

  const [
    updateBillingDetails,
    { data: updateResult, error: updateError },
  ] = useEnhancedMutation(UPDATE_BILLING_DETAILS, {
    refetchQueries: ['getBillingDetails'],
  });
  const [
    deleteBillingDetails,
    { data: deleteResult, error: deleteError },
  ] = useEnhancedMutation(DELETE_BILLING_DETAILS, {
    refetchQueries: ['getBillingDetails'],
  });

  async function handleSave({ taxIdType: typeString, taxIdValue }: any) {
    try {
      const tax: typeof taxIdTypes[0] = JSON.parse(typeString);
      await updateBillingDetails({
        variables: {
          billing: {
            type: tax.type,
            value: taxIdValue,
            countryCode: tax.countryCode,
          },
        },
      });
      setShowTaxIdInput(false);
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  const handleDeleteTaxId = useCallback(async () => {
    return deleteBillingDetails().catch(NOOP_graphqlErrorManagement);
  }, [deleteBillingDetails]);

  const errors = useMemo(
    () => [
      {
        error: updateError,
        message:
          'There was an error while updating tax id. Please check your input and try again.',
      },
      {
        error: deleteError,
        message:
          'There was an error while deleting the tax id. Please try again.',
      },
      {
        error: booleanResultToError(deleteResult, 'deleteBillingDetails'),
        message:
          'There was an error while deleting the tax id. Please try again.',
      },
    ],
    [deleteError, updateError, deleteResult]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      {
        result: deleteResult,
        message: 'Tax id deleted.',
        booleanKey: 'deleteBillingDetails',
      },
      { result: updateResult, message: 'Tax id updated.' },
    ],
    [deleteResult, updateResult]
  );
  //endregion

  const defaultTaxId = useMemo(
    () =>
      lett(
        getBillingDetails,
        (taxId) =>
          ({
            taxIdValue: taxId.value,
            taxIdType: JSON.stringify(
              taxIdTypes.find(
                (el) =>
                  el.type === taxId.type && el.countryCode === taxId.countryCode
              )
            ),
          } as DVKObject)
      ) || {},
    [getBillingDetails]
  );

  function renderTaxIdInput() {
    return (
      <>
        <DVKForm
          className={taxIdSection}
          fields={taxIdFields}
          onSubmit={handleSave}
          invalidFields={getInvalidFields(updateError) || undefined}
          defaultValue={defaultTaxId}
          bottomContent={
            <>
              <Button type="submit" variant="outlined" color="primary">
                {getBillingDetails ? 'Update tax id' : 'Add tax id'}
              </Button>
            </>
          }
        />
      </>
    );
  }

  function renderDefaultTaxId() {
    return (
      <>
        {getBillingDetails && (
          <div className={taxIdField}>
            Your tax id: <strong>{getBillingDetails.value}</strong>
          </div>
        )}
        <div>
          <Button
            className={saveButton}
            variant="outlined"
            color={'primary'}
            onClick={() => {
              setShowTaxIdInput(true);
            }}
          >
            {getBillingDetails ? 'Update Tax Id' : 'Add tax Id'}
          </Button>
        </div>
        {getBillingDetails && (
          <div>
            <Button onClick={handleDeleteTaxId} className={deleteButton}>
              Delete Tax Id
            </Button>
          </div>
        )}
        {getBillingDetails && (
          <Typography variant="caption">
            *This tax id will be used at your next invoice
          </Typography>
        )}
      </>
    );
  }

  return (
    <>
      <div style={{ marginLeft: 24 }}>
        <h3>Tax Id</h3>

        {showTaxIdInput ? renderTaxIdInput() : renderDefaultTaxId()}
      </div>
      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default TaxIdSection;
