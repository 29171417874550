import { OptiTextField } from '@components';
import { mapFormikToButtonSwitchField, useIsMobile } from '@lib';
import { Box, Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { Attribute, AttributeType } from '@optioffer/graphql';

import ButtonSwitchInput from '@components/form/ButtonSwitchInput';
import OOModal from '@components/modals/OOModal';

import { mapFormikToTextField } from '@lib/formik';
import { ModalControl } from '@lib/materialCommons';

import {
  SpecificationBooleanIcon,
  SpecificationNumberIcon,
  SpecificationTextIcon,
} from '@resources/icons';

import useStyles from './styles';

const useLocalStyles = makeStyles((theme) => ({
  root: {},
  typeButton: {
    flex: 1,
    margin: 4,
    padding: theme.spacing(1.5),
    color: theme.palette.grey.A100,

    '& > .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  typeButtonSelected: {
    color: theme.palette.primary.main,
  },
  typeButtonIcon: {
    width: 28,
    height: 22,
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.grey.A100,
    borderRadius: 3,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  typeButtonIconSelected: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export type NewAttributeModalData = {};

type NewAttributeModalProps = {
  control: ModalControl<NewAttributeModalData>;
  createAttribute: (newAttribute: Omit<Attribute, 'id'>) => Promise<any>;
};

const NewAttributeModal: FC<NewAttributeModalProps> = ({
  control,
  createAttribute,
}) => {
  const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      name: '',
      unit: '',
      type: AttributeType.TEXT,
    },
    onSubmit: async (values) => {
      await createAttribute({
        name: values.name,
        unit: values.unit,
        type: values.type,
      });

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.isOpen) {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.isOpen]);

  const title = 'New Specification';
  return (
    <>
      <OOModal
        open={control.isOpen}
        onClose={control.close}
        title={title}
        minWidth="sm"
      >
        <Box marginX={isMobile ? 0 : 3} marginBottom={isMobile ? 0 : 3}>
          <form onSubmit={formik.handleSubmit}>
            {!isMobile && (
              <>
                <Box className={classes.desktopModalTitle}>{title}</Box>
              </>
            )}
            <Box height={'100%'} marginBottom={10}>
              <Box paddingX={1.5}>
                <Box marginY={2} display="flex">
                  <Box flex={2} marginRight={2}>
                    <OptiTextField
                      required
                      label="Name"
                      placeholder="e.g. Height"
                      {...mapFormikToTextField(formik, 'name')}
                    />
                  </Box>
                  <Box flex={1}>
                    <OptiTextField
                      label="Measuring Unit"
                      placeholder="e.g. cm"
                      {...mapFormikToTextField(formik, 'unit')}
                    />
                  </Box>
                </Box>
                <Box marginY={2}>
                  <ButtonSwitchInput
                    label="Specification Type"
                    tooltip={
                      'This will help filter your products better. If you\'re unsure about this, select "Text".'
                    }
                    createButtonProps={(value) => ({
                      className: clsx(
                        localClasses.typeButton,
                        value === formik.values.type &&
                          localClasses.typeButtonSelected
                      ),
                      variant: 'outlined',
                    })}
                    options={[
                      {
                        label: (
                          <>
                            <Box
                              className={clsx(
                                localClasses.typeButtonIcon,
                                AttributeType.TEXT === formik.values.type &&
                                  localClasses.typeButtonIconSelected
                              )}
                            >
                              <SpecificationTextIcon />
                            </Box>
                            <Box>Text</Box>
                          </>
                        ),
                        value: AttributeType.TEXT,
                      },
                      {
                        label: (
                          <>
                            <Box
                              className={clsx(
                                localClasses.typeButtonIcon,
                                AttributeType.NUMBER === formik.values.type &&
                                  localClasses.typeButtonIconSelected
                              )}
                            >
                              <SpecificationNumberIcon />
                            </Box>
                            <Box>Numerical</Box>
                          </>
                        ),
                        value: AttributeType.NUMBER,
                      },
                      {
                        label: (
                          <>
                            <Box
                              className={clsx(
                                localClasses.typeButtonIcon,
                                AttributeType.BOOLEAN === formik.values.type &&
                                  localClasses.typeButtonIconSelected
                              )}
                            >
                              <SpecificationBooleanIcon />
                            </Box>
                            <Box>Yes / No</Box>
                          </>
                        ),
                        value: AttributeType.BOOLEAN,
                      },
                    ]}
                    {...mapFormikToButtonSwitchField(formik, 'type')}
                  />
                </Box>
              </Box>
            </Box>

            <Box marginBottom={10} />

            <Box display="flex" className={classes.floatingFooter}>
              <Box flex="1" margin={isMobile ? 0 : 1.5}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={!formik.dirty || !formik.values.name}
                >
                  {'Create Specification'}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </OOModal>
    </>
  );
};

export default NewAttributeModal;
