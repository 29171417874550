import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Message } from 'localize-react';
import React from 'react';
import { DocumentLayoutErrorBoundary, pdfIf, useDevColor, } from '../../../helpers';
import Footer from '../../snippets/Footer';
import Header from '../../snippets/Header';
import { commonStyles } from '../../style';
import PricingTable from '../PricingTable';
var offerItemStyles = StyleSheet.create({
    container: {
        flex: 1,
        paddingLeft: '5mm',
    },
    name: {
        maxHeight: '20mm',
        paddingBottom: '3mm',
    },
    imageAndSpecs: {
        flexDirection: 'row',
    },
    imageAndSpecsWithImage: {
        minHeight: '50mm',
    },
    image: {
        flex: 1,
        height: '100%',
        maxHeight: '85mm',
        width: '85mm',
        maxWidth: '85mm',
        objectFit: 'contain',
        marginRight: '5mm',
    },
    specs: {
        flex: 1,
    },
    description: {
        flex: 1,
        marginVertical: '5mm',
    },
    sectionTitle: {
        paddingLeft: '5mm',
    },
});
var OfferItemPage = function (_a) {
    var offerItem = _a.offerItem;
    var devColor = useDevColor();
    var isBundle = !offerItem.product;
    function renderName() {
        var _a, _b, _c, _d, _e;
        var code = (_b = (_a = offerItem.product) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : offerItem.productBundle.items.map(function (item) { return item.product.code; }).join('+');
        return (React.createElement(View, { fixed: true, style: [offerItemStyles.name, devColor('#4FC3F7')] },
            React.createElement(Text, { style: [commonStyles.p, commonStyles.h] },
                offerItem.position,
                ".",
                ' ', (_d = (_c = offerItem.product) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : (_e = offerItem.productBundle) === null || _e === void 0 ? void 0 : _e.name),
            React.createElement(Text, null,
                React.createElement(Message, { descriptor: "product.portrait.code", values: { code: code } })),
            pdfIf(offerItem.parentCode, function () { return (React.createElement(Text, { style: [commonStyles.p] },
                React.createElement(Message, { descriptor: "product.accessoryFor", values: { parent: offerItem.parentCode } }))); })));
    }
    function renderImageAndSpecs() {
        var _a, _b, _c, _d;
        var hasImage = !!(isBundle
            ? (_a = offerItem.productBundle.image) === null || _a === void 0 ? void 0 : _a.fullImage
            : (_b = offerItem.product.image) === null || _b === void 0 ? void 0 : _b.fullImage);
        var specs = (_d = (_c = offerItem.product) === null || _c === void 0 ? void 0 : _c.specifications) === null || _d === void 0 ? void 0 : _d.filter(function (spec) { return !~[null, undefined, ''].indexOf(spec.value); });
        return (React.createElement(View, { style: [
                offerItemStyles.imageAndSpecs,
                hasImage ? offerItemStyles.imageAndSpecsWithImage : {},
                devColor('#D500F9'),
            ] },
            pdfIf(hasImage, function () { return (React.createElement(Image, { style: [offerItemStyles.image, devColor('#673AB7')], src: isBundle
                    ? offerItem.productBundle.image.fullImage
                    : offerItem.product.image.fullImage })); }),
            React.createElement(View, { style: [offerItemStyles.specs, devColor('#303F9F')] },
                pdfIf(specs === null || specs === void 0 ? void 0 : specs.length, function () { return (React.createElement(React.Fragment, null,
                    React.createElement(Text, { style: [
                            commonStyles.p,
                            commonStyles.h,
                            offerItemStyles.sectionTitle,
                        ] },
                        React.createElement(Message, { descriptor: "product.specifications" })),
                    specs.map(function (spec) { return (React.createElement(Text, { key: spec.id, style: [commonStyles.p] },
                        React.createElement(Text, { style: [commonStyles.b] }, spec.name),
                        ': ',
                        React.createElement(Text, null, spec.value),
                        ' ',
                        React.createElement(Text, null, spec.unit ? "(" + spec.unit + ")" : ''))); }))); }),
                pdfIf(isBundle, function () { return (React.createElement(React.Fragment, null,
                    React.createElement(Text, { style: [
                            commonStyles.p,
                            commonStyles.h,
                            offerItemStyles.sectionTitle,
                        ] },
                        React.createElement(Message, { descriptor: "product.bundleProducts" })),
                    offerItem.productBundle.items.map(function (item) { return (React.createElement(Text, { key: item.id, style: [commonStyles.p] },
                        React.createElement(Text, null, item.quantity),
                        'x ',
                        React.createElement(Text, { style: [commonStyles.b] }, "[" + item.product.code + "]"),
                        '  ',
                        React.createElement(Text, null, item.product.name))); }))); }))));
    }
    function renderDescription() {
        var _a;
        var description = isBundle
            ? (_a = offerItem.productBundle) === null || _a === void 0 ? void 0 : _a.description
            : offerItem.product.description;
        return (React.createElement(View, { style: [offerItemStyles.description, devColor('#1DE9B6')] }, pdfIf(description, function () { return (React.createElement(React.Fragment, null,
            React.createElement(Text, { style: [
                    commonStyles.p,
                    commonStyles.h,
                    offerItemStyles.sectionTitle,
                ] },
                React.createElement(Message, { descriptor: "product.description" })),
            React.createElement(Text, { style: [commonStyles.p] }, description))); })));
    }
    return (React.createElement(Page, { wrap: true, size: "A4", style: [commonStyles.page, devColor('#ccc')] },
        React.createElement(Header, { compact: true }),
        React.createElement(DocumentLayoutErrorBoundary, null,
            React.createElement(View, { style: [offerItemStyles.container] },
                renderName(),
                renderImageAndSpecs(),
                renderDescription(),
                React.createElement(PricingTable, { offerItem: offerItem }))),
        React.createElement(Footer, null)));
};
export default OfferItemPage;
