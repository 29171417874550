import { makeStyles } from '@material-ui/core';

import { ooBrand } from '@optioffer/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  pointer: {
    cursor: 'pointer',
  },
  clickThrough: {
    pointerEvents: 'none',
  },

  header1: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 'bold',
  },

  iconButton: {
    padding: 6,
    minWidth: 40,
  },

  grayTextButton: {
    color: ooBrand.colors.gray.A200,
  },

  modalHeader: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 'bold',
  },

  modalSubHeader: { fontSize: theme.typography.pxToRem(13), opacity: '60%' },

  floatingFooter: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    padding: theme.spacing(2, 1.5),
    backgroundColor: ooBrand.colors.pureWhite,
    boxShadow: ooBrand.boxShadow.grayTop,

    [theme.breakpoints.up('lg')]: {
      '&:not(.floatOnDesktop)': {
        boxShadow: 'none',

        '& button.NavigationButton': {
          width: theme.spacing(32),
        },

        width: '100%',
        position: 'static',
        alignSelf: 'end',
        padding: theme.spacing(0),
        backgroundColor: 'transparent',
      },
    },
  },

  flatInlineButton: {
    backgroundColor: '#05786919',
    color: theme.palette.primary.light,

    '& .MuiButton-label': {
      textTransform: 'none',
      fontWeight: 'bold',
    },
    '& svg': {
      marginRight: theme.spacing(2),
    },

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1.5, 3, 1.5, 1.5),
      marginLeft: theme.spacing(-1.5),
      backgroundColor: 'transparent',

      '&:hover': {
        backgroundColor: '#05786919',
      },

      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },

  sectionHeading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },

  simpleRow: {
    padding: theme.spacing(1, 2),
    paddingRight: '36px', // spacing * 2 - 6px + 24px
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  simpleRowDesktop: {
    padding: theme.spacing(1, 1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  actionableRow: {
    extend: 'simpleRow',
    backgroundColor: '#59847E07',
    paddingRight: '12px', // spacing * 2 - 6px

    '& svg:last-child': {
      cursor: 'pointer',
    },
  },
  actionableRowDesktop: {
    extend: 'simpleRowDesktop',
    padding: theme.spacing(1, 1.5, 1, 2),
    backgroundColor: '#F8F5F1',
    cursor: 'pointer',
    '&:hover': {
      opacity: '100%',
      fontWeight: 'bold',
      color: 'black',
    },
  },
  simpleRowWithLabel: {
    '& > div:first-child': {
      opacity: '50%',
    },
  },
  simpleRowWithLabelDesktop: {
    '& > div:first-child': {
      opacity: '75%',
    },
    '&:hover': {
      opacity: '100%',
    },
  },
  actionableRowSpecial: {
    padding: theme.spacing(2),
    marginY: theme.spacing(1),
  },

  illustrationWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  illustration: {
    width: '80%',
  },

  desktopModalTitle: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 'bold',
    margin: theme.spacing(0, 0, 2, 1.5),
  },
  desktopModalSubTitle: {
    fontSize: theme.typography.pxToRem(13),
    margin: theme.spacing(-2, 0, 2, 1.5),
  },
  sectionPaper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(3),
    boxShadow: ooBrand.boxShadow.primary,
  },
  customerAvatar: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
  customerIcon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
    marginTop: 2,
    color: '#414141',
  },
  customerLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  customerContactContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(6),
  },
  container: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  accordionText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
  },
  column: {
    flexDirection: 'column',
  },
  accordionIcon: {
    color: 'white',
    background: theme.palette.primary.main,
    borderRadius: '50%',
  },
  currencyBox: {
    flex: 1,
    paddingLeft: 20,
  },
  totalDivider: {
    borderTop: `2px dashed ${theme.palette.primary.main}`,
    paddingTop: theme.spacing(3),
  },
  socialButton: {
    background: theme.palette.primary.main,
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
  },
  sendQuoteButton: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
  actionButton: {
    width: 350,
    fontSize: 14,
    textAlign: 'center',
  },
}));

export default useStyles;
