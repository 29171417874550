import { useEffect, useState } from 'react';

import { escapeRegExp } from '@optioffer/core';
import {
  SearchAttributeFilter,
  SearchAttributeWithCount,
} from '@optioffer/graphql';

export const renderSearchAttributeValue = ({
  value,
  unit,
}: SearchAttributeFilter): string => `${value}${unit ? ` ${unit}` : ''}`;

export const serializeFilter = (filter: SearchAttributeFilter): string =>
  JSON.stringify({
    name: filter.name,
    type: filter.type,
    value: filter.value,
    unit: filter.unit,
  });

export const filterToGroupName = (filter: SearchAttributeFilter) =>
  `${filter.type}-${filter.name}`;
export const filterToValueKey = (filter: SearchAttributeFilter) =>
  `${filter.unit}-${filter.value}`;

export const areFiltersEqual = (
  filter1: SearchAttributeFilter,
  filter2: SearchAttributeFilter
): boolean =>
  filter1.name === filter2.name &&
  filter1.type === filter2.type &&
  filter1.value === filter2.value &&
  filter1.unit === filter2.unit;

export const sanitizeFilter = (
  filter: SearchAttributeFilter
): SearchAttributeFilter => ({
  name: filter.name,
  type: filter.type,
  value: filter.value,
  unit: filter.unit,
});

export type SearchFilter = SearchAttributeWithCount & {
  valueKey: string;
};

export type SearchFilterGroup = {
  group: string;
  filters: SearchFilter[];
};

export const useFilterDefinitions = (
  filters: SearchAttributeFilter[],
  attributesWithCount: SearchAttributeWithCount[],
  debouncedSearchString: string
): [SearchFilterGroup[], boolean] => {
  const [loading, setLoading] = useState(false);
  const [filterDefinitions, setFilterDefinitions] = useState<
    SearchFilterGroup[]
  >([]);

  useEffect(() => {
    let cancelled = false;

    requestAnimationFrame(async () => {
      if (cancelled) return;
      setLoading(true);

      const newFilterDefinitions = await Object.entries(
        attributesWithCount
          .map((attribute) => ({
            group: filterToGroupName(attribute),
            attribute: {
              ...attribute,
              valueKey: filterToValueKey(attribute),
            },
          }))
          .reduce(
            (acc, it) => ({
              ...acc,
              [it.group]: [...(acc[it.group] ?? []), it.attribute],
            }),
            {} as { [key: string]: SearchFilter[] }
          )
      )
        .map(([group, attributes]) => ({
          group,
          filters: [
            ...attributes,
            // add the filters with 0
            ...(filters ?? [])
              .filter(
                (selectedFilter) => filterToGroupName(selectedFilter) === group
              ) // same group
              .filter(
                (selectedFilter) =>
                  !attributes.some((alreadyPresentFilter) =>
                    areFiltersEqual(alreadyPresentFilter, selectedFilter)
                  )
              ) // not already present
              .map((selectedFilter) => ({
                ...selectedFilter,
                total: 0,
                valueKey: filterToValueKey(selectedFilter),
              })),
          ].sort((a, b) => a.valueKey.localeCompare(b.valueKey)),
        }))
        .filter((filterGroup) => {
          const searchRegExp = new RegExp(
            escapeRegExp(debouncedSearchString),
            'gi'
          );
          return (
            searchRegExp.test(filterGroup.filters[0].name) ||
            filterGroup.filters.some(
              (filter) => filter.unit && searchRegExp.test(filter.unit)
            ) ||
            filterGroup.filters.some(
              (filter) => filter.value && searchRegExp.test(filter.value)
            )
          );
        })
        .sort((a, b) => a.group.localeCompare(b.group));

      if (cancelled) return;
      setLoading(false);
      setFilterDefinitions(newFilterDefinitions);
    });

    return () => {
      cancelled = true;
    };
  }, [
    setFilterDefinitions,
    filters,
    attributesWithCount,
    debouncedSearchString,
  ]);

  return [filterDefinitions, loading];
};

export type PriceFilter = (number | undefined)[];
