import { makeStyles } from '@material-ui/core';

import { addColoredBar } from '../../components/Card/styles';
import { OOTheme } from '../../theme';

export default makeStyles((theme: OOTheme) => ({
  tableCard: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    ...addColoredBar(theme, theme.oo.colors.product),
  },
  viewEditCard: {
    maxWidth: 1280,
    ...addColoredBar(theme, theme.oo.colors.product),
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
}));
