import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { FC, FormEvent, useState } from 'react';
import {
  CardElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements';

import { COUNTRY_MAP } from './index';
import useStyles from './styles';

type CardFormProps = ReactStripeElements.InjectedStripeProps & {
  customerName: string;
  onSuccess: (
    paymentMethod: stripe.paymentMethod.PaymentMethod,
    addressLine: string,
    country: string
  ) => void;
  selectedCountry?: string;
  address?: string;
};

const CardForm: FC<CardFormProps> = ({
  stripe,
  elements,
  customerName,
  onSuccess,
  selectedCountry,
  address,
}) => {
  const {
    cardInputButton,
    cardInputForm,
    countryInput,
    addressInput,
    cardElement,
  } = useStyles();
  const [actualCountry, setActualCountry] = useState(selectedCountry);
  const [actualAddress, setActualAddress] = useState(address);

  if (!stripe || !elements) return <div>Loading stripe...</div>;

  async function handleSubmit(ev: FormEvent) {
    ev.preventDefault();
    if (actualAddress && actualCountry) {
      const cardElement = elements!.getElement('card');

      const paymentMethodResponse = await stripe!.createPaymentMethod({
        type: 'card',
        card: cardElement || undefined,
        billing_details: {
          name: customerName,
          address: { country: actualCountry, line1: actualAddress },
        },
      });

      if (paymentMethodResponse.error) throw paymentMethodResponse.error;
      if (!paymentMethodResponse.paymentMethod)
        throw new Error('Invalid payment method');
      onSuccess(
        paymentMethodResponse.paymentMethod,
        actualAddress,
        actualCountry
      );
    }
  }

  return (
    <form className={cardInputForm} onSubmit={handleSubmit}>
      <InputLabel id="country-label">Select a country</InputLabel>
      <Select
        value={actualCountry}
        labelId="country-label"
        className={countryInput}
        onChange={(e) => setActualCountry(e.target.value as any)}
      >
        {COUNTRY_MAP.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            {country.label}
          </MenuItem>
        ))}
      </Select>
      {!actualCountry && (
        <FormHelperText error>This is required!</FormHelperText>
      )}
      <TextField
        id="address"
        label="Address"
        className={addressInput}
        onChange={(e) => setActualAddress(e.target.value as any)}
      />
      {!actualAddress && (
        <FormHelperText error>This is required!</FormHelperText>
      )}
      <CardElement className={cardElement} />

      <Button
        className={cardInputButton}
        variant="contained"
        type="submit"
        color="primary"
      >
        Add Card
      </Button>
    </form>
  );
};

export default injectStripe(CardForm);
