import { ErrorWithMessage, ResultWithMessage } from '@lib';
import React, { useCallback, useMemo, useState } from 'react';
import { createContext, FC } from 'react';

import { Nullable } from '@optioffer/core';

export const ApolloFeedbackContext = createContext<{
  registerSuccess(operationId: string, success: ResultWithMessage): void;
  registerError(operationId: string, error: ErrorWithMessage): void;
  unregisterOperation(operationId: string): void;
  results: Nullable<ResultWithMessage>[];
  modalErrors: ErrorWithMessage[];
  snackbarErrors: ErrorWithMessage[];
}>({} as any);

export const ApolloFeedbackContextProvider: FC = ({ children }) => {
  const [successMap, setSuccessMap] = useState<{
    [key: string]: ResultWithMessage | undefined;
  }>({});
  const [modalErrorMap, setModalErrorMap] = useState<{
    [key: string]: ErrorWithMessage | undefined;
  }>({});
  const [snackbarErrorMap, setSnackbarErrorMap] = useState<{
    [key: string]: ErrorWithMessage | undefined;
  }>({});

  const registerSuccess = useCallback(
    (operationId: string, success: ResultWithMessage): void => {
      setSuccessMap((old) => ({ ...old, [operationId]: success }));
    },
    [setSuccessMap]
  );

  const registerError = useCallback(
    (operationId: string, error: ErrorWithMessage): void => {
      if (error.type === 'SNACKBAR') {
        setSnackbarErrorMap((old) => ({ ...old, [operationId]: error }));
      } else {
        // also if `error.type` is `undefined`
        setModalErrorMap((old) => ({ ...old, [operationId]: error }));
      }
    },
    [setModalErrorMap, setSnackbarErrorMap]
  );

  const unregisterOperation = useCallback(
    (operationId: string): void => {
      setSuccessMap((old) => ({ ...old, [operationId]: undefined }));
      setModalErrorMap((old) => ({ ...old, [operationId]: undefined }));
      setSnackbarErrorMap((old) => ({ ...old, [operationId]: undefined }));
    },
    [setSuccessMap, setModalErrorMap, setSnackbarErrorMap]
  );

  const results = useMemo(() => Object.values(successMap), [successMap]);

  const modalErrors = useMemo(
    () =>
      Object.values(modalErrorMap).filter((it): it is ErrorWithMessage =>
        Boolean(it)
      ),
    [modalErrorMap]
  );
  const snackbarErrors = useMemo(
    () =>
      Object.values(snackbarErrorMap).filter((it): it is ErrorWithMessage =>
        Boolean(it)
      ),
    [snackbarErrorMap]
  );

  return (
    <ApolloFeedbackContext.Provider
      value={{
        registerSuccess,
        registerError,
        unregisterOperation,
        results,
        modalErrors,
        snackbarErrors,
      }}
    >
      {children}
    </ApolloFeedbackContext.Provider>
  );
};
