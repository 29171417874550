import { Chip } from '@material-ui/core';
import { ChipProps } from '@material-ui/core/Chip';
import React, { FC } from 'react';

import { ProfileType } from '@optioffer/graphql';

const STATUSES = {
  [ProfileType.EMPLOYEE]: {
    label: 'Employee',
    color: 'primary',
  },
  [ProfileType.PROFESSIONAL_AND_EMPLOYEE]: {
    label: 'Professional & Employee',
    color: 'primary',
  },
  [ProfileType.PROFESSIONAL]: {
    label: 'Professional',
    color: 'primary',
  },
  [ProfileType.SUPPLIER]: {
    label: 'Supplier',
    color: 'primary',
  },
};

type ProfileTypeChipProps = {
  status: ProfileType;
};

const ProfileTypeChip: FC<ProfileTypeChipProps> = ({ status }) => {
  const statusObject = STATUSES[status];
  if (!statusObject) return <>{status}</>;

  return (
    <Chip
      component="span"
      label={statusObject.label}
      color={statusObject.color as ChipProps['color']}
    />
  );
};

export default ProfileTypeChip;
