import { DVKField, DVKForm, DVKObject, lett } from '@dvkiin/material-commons';
import {
  FieldErrors,
  getInvalidFields,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
  useQueryParams,
} from '@lib';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Auth_ResetPasswordDocument,
  Auth_ResetPasswordMutation,
  Auth_ValidateResetPasswordTokenDocument,
} from '@optioffer/graphql';

import useStyles from '../styles';
import LoginWrapper from '../wrapper';

type ResetPasswordPageProps = {};

const ResetPasswordPage: FC<ResetPasswordPageProps> = () => {
  const { form, submit } = useStyles();
  const { token } = useQueryParams();
  const { push } = useHistory();

  const [invalidFields, setInvalidFields] = useState<FieldErrors | null>(null);

  const {
    data: { validateResetPasswordToken } = {
      validateResetPasswordToken: undefined,
    },
    error: validateError,
    loading,
    refetch,
  } = useEnhancedQuery(Auth_ValidateResetPasswordTokenDocument, {
    variables: { token: token as string },
  });
  const [
    resetPassword,
    { error: resetPasswordError, data },
  ] = useEnhancedMutation(Auth_ResetPasswordDocument, {
    fetchPolicy: 'no-cache',
    error: {
      type: 'MODAL',
      message:
        'We were not able finish the reset password process. Please check your input and try again.',
    },
    success: {
      message: 'Password changed.',
      action: () => (
        <Button color="inherit" size="small" onClick={() => push('/')}>
          Login
        </Button>
      ),
    },
  });

  const defaultValues = useMemo(
    () =>
      lett(
        validateResetPasswordToken,
        (t) =>
          ({
            id: t.id,
            email: t.email,
          } as DVKObject)
      ) || {},
    [validateResetPasswordToken]
  );

  const resetPasswordFields = useMemo(
    () =>
      [
        {
          name: 'email',
          label: 'Email',
          type: 'email',
          disabled: true,
          required: true,
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
          required: true,
          autoComplete: 'new-password',
          errorMessage:
            "Please use a password that contains both lower and uppercase letters. And a number, just to be safe. And to top it off, make sure it's at least 8 characters long.",
        },
        {
          name: 'repeatPassword',
          label: 'Repeat password',
          type: 'password',
          required: true,
          autoComplete: 'repeat-password',
          errorMessage: 'Passwords do not match.',
        },
      ] as DVKField[],
    []
  );

  //endregion

  async function handleResetPassword({ password, repeatPassword }: DVKObject) {
    setInvalidFields(null);
    if (password !== repeatPassword) {
      setInvalidFields({ repeatPassword: true });
      return;
    }

    try {
      const { data } = await resetPassword({
        variables: {
          newPassword: { token: token as string, password: password as string },
        },
      });
      const {
        resetPassword: { accessToken, refreshToken },
      } = data as Auth_ResetPasswordMutation;

      localStorage.setItem('x-token', accessToken);
      localStorage.setItem('x-refresh-token', refreshToken);
    } catch (_e) {
      // maybe the token expired
      refetch().catch(NOOP_graphqlErrorManagement);
    }
  }

  function renderSuccess() {
    return (
      <>
        <Typography variant="body1" gutterBottom>
          Your password was changed successfully!
        </Typography>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          className={submit}
          onClick={() => push('/')}
        >
          Go to app
        </Button>
      </>
    );
  }

  function renderForm() {
    return (
      <>
        <DVKForm
          fields={resetPasswordFields}
          className={form}
          defaultValue={defaultValues}
          invalidFields={invalidFields || getInvalidFields(resetPasswordError)}
          onSubmit={handleResetPassword}
          renderActions={() => (
            <>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                className={submit}
              >
                Reset password
              </Button>
            </>
          )}
        />
      </>
    );
  }

  if (loading) return <>Validating your token...</>; // todo: fancy loading
  if (validateError) return <>Token is not valid. It may be expired!</>; // todo: nice explanation page

  return (
    <LoginWrapper title="Choose new password">
      {data && data.resetPassword ? renderSuccess() : renderForm()}
    </LoginWrapper>
  );
};

export default ResetPasswordPage;
