import {
  NOOP_graphqlErrorManagement,
  SecurityContext,
  useEnhancedMutation,
  useEnhancedQuery,
} from '@lib';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { Billing_GetVatRateDocument } from '@optioffer/graphql';

import { IPaymentMethodCard } from '@components/Payment';

import {
  ApolloModalErrors,
  ApolloSnackbars,
} from '../../../components/Feedback';
import { BillingDetails, CompanyQuotas, Plan, Subscription } from '../domain';
import {
  CANCEL_SUBSCRIPTION,
  FIND_ALL_PLANS,
  GET_BILLING_DETAILS,
  GET_COMPANY_QUOTAS,
  GET_DEFAULT_PAYMENT_METHOD,
  GET_SUBSCRIPTION,
  REACTIVATE_SUBSCRIPTION,
  REMOVE_DEFAULT_PAYMENT_METHOD,
  RETRY_PAYMENT,
  UPDATE_DEFAULT_PAYMENT_METHOD,
  UPDATE_SUBSCRIPTION,
} from '../graphql';
import CompanyPlansSection from '../sections/Plans';

type BillingSettingsPageProps = {};

const BillingSettingsPage: FC<BillingSettingsPageProps> = () => {
  const {
    currentUser: { name },
  } = useContext(SecurityContext);

  //region Queries
  const {
    data: { findAllPublicPlans } = { findAllPublicPlans: [] },
    error: getPublicPlansError,
    loading: loadingPlans,
  } = useEnhancedQuery<{ findAllPublicPlans: Plan[] }>(FIND_ALL_PLANS);
  const {
    data: { getCurrentSubscription } = { getCurrentSubscription: null },
    error: getSubscriptionError,
    loading: loadingSubscription,
    refetch: refetchCurrentSubscription,
  } = useEnhancedQuery<{ getCurrentSubscription: Subscription }>(
    GET_SUBSCRIPTION
  );

  const {
    data: { getDefaultPaymentMethod } = { getDefaultPaymentMethod: null },
  } = useEnhancedQuery<{ getDefaultPaymentMethod?: IPaymentMethodCard }>(
    GET_DEFAULT_PAYMENT_METHOD
  );
  const {
    data: { getCompanyQuotas } = { getCompanyQuotas: null },
  } = useEnhancedQuery<{ getCompanyQuotas: CompanyQuotas }>(GET_COMPANY_QUOTAS);
  const {
    data: { getBillingDetails } = { getBillingDetails: undefined },
  } = useEnhancedQuery<{ getBillingDetails: BillingDetails }>(
    GET_BILLING_DETAILS
  );
  const taxCountry = useMemo<string | undefined>(
    () => getBillingDetails?.countryCode || getDefaultPaymentMethod?.country,
    [getBillingDetails, getDefaultPaymentMethod]
  );
  const { data: { getVatRate } = { getVatRate: undefined } } = useEnhancedQuery(
    Billing_GetVatRateDocument,
    {
      skip: !taxCountry,
      variables: { country: taxCountry as any },
    }
  );
  //endregion

  //region Mutations
  const [
    updatePaymentMethod,
    { data: updatePaymentMethodData, error: updatePaymentMethodError },
  ] = useEnhancedMutation(UPDATE_DEFAULT_PAYMENT_METHOD, {
    refetchQueries: ['getDefaultPaymentMethod'],
  });
  const [
    removePaymentMethod,
    { data: removePaymentMethodData, error: removePaymentMethodError },
  ] = useEnhancedMutation(REMOVE_DEFAULT_PAYMENT_METHOD, {
    refetchQueries: ['getCompanyDetails'],
  });
  const [
    cancelSubscription,
    { data: cancelSubscriptionData, error: cancelSubscriptionError },
  ] = useEnhancedMutation(CANCEL_SUBSCRIPTION);
  const [
    reactivateSubscription,
    { data: reactivateSubscriptionData, error: reactivateSubscriptionError },
  ] = useEnhancedMutation(REACTIVATE_SUBSCRIPTION);
  const [
    updateSubscription,
    { data: updateSubscriptionData, error: updateSubscriptionError },
  ] = useEnhancedMutation(UPDATE_SUBSCRIPTION, {
    refetchQueries: ['getCompanyDetails'],
  });
  const [
    retryPayment,
    { data: retryPaymentData, error: retryPaymentError },
  ] = useEnhancedMutation(RETRY_PAYMENT);
  //endregion

  //region Callbacks
  const handleRemoveDefaultPaymentMethod = useCallback(async () => {
    return removePaymentMethod().catch(NOOP_graphqlErrorManagement);
  }, [removePaymentMethod]);

  const handleUpdatePaymentMethod = useCallback(
    async (
      paymentMethodId: stripe.paymentMethod.PaymentMethod['id'],
      addressLine: string,
      country: string
    ) => {
      return updatePaymentMethod({
        variables: {
          paymentMethodInput: { paymentMethodId, addressLine, country },
        },
      }).catch(NOOP_graphqlErrorManagement);
    },
    [updatePaymentMethod]
  );

  const handleUpdateSubscription = useCallback(
    async (planId: Plan['id']) => {
      const { data } = await updateSubscription({ variables: { planId } });
      return data!.updateSubscription as Subscription;
    },
    [updateSubscription]
  );

  const handleRetryPayment = useCallback(async () => {
    const { data } = await retryPayment();
    return data!.retryPayment as Subscription;
  }, [retryPayment]);
  //endregion

  //region Errors
  const errors = useMemo(
    () => [
      {
        error: getPublicPlansError,
        message:
          'We were not able to load payment plans. Please try again. If this persists, please contact support.',
      },
      {
        error: getSubscriptionError,
        message:
          'We were not able to load your subscription. Please try again. If this persists, please contact support.',
      },
      {
        error: updatePaymentMethodError,
        message:
          'There seems to be a problem with your card. Please try another one. If you believe this is a problem on our side, please contact support.',
      },
      {
        error: updateSubscriptionError,
        message:
          'We were not able to change your plan. Please try again. If this persists, please contact support.',
      },
      {
        error: retryPaymentError,
        message:
          'There was an error processing you payment. Please try again or choose another card. If this persists, please contact support.',
      },
      {
        error: removePaymentMethodError,
        message:
          'There was an error removing your payment method. Please try again. If this persists, please contact support.',
      },
      {
        error: cancelSubscriptionError,
        message:
          'There was an error canceling your subscription. Please try again and if this persists, please contact support.',
      },
      {
        error: reactivateSubscriptionError,
        message:
          'There was an error reactivating your subscription. Please try again and if this persists, please contact support.',
      },
    ],
    [
      getPublicPlansError,
      getSubscriptionError,
      updatePaymentMethodError,
      updateSubscriptionError,
      retryPaymentError,
      removePaymentMethodError,
      cancelSubscriptionError,
      reactivateSubscriptionError,
    ]
  );
  //endregion

  //region Results
  const results = useMemo(
    () => [
      { result: updatePaymentMethodData, message: 'Payment method changed.' },
      { result: updateSubscriptionData, message: 'Plan changed successfully.' },
      {
        result: reactivateSubscriptionData,
        message: 'Subscription reactivated successfully.',
      },
      { result: cancelSubscriptionData, message: 'Subscription canceled.' },
      { result: removePaymentMethodData, message: 'Payment method removed.' },
    ],
    [
      updatePaymentMethodData,
      updateSubscriptionData,
      reactivateSubscriptionData,
      cancelSubscriptionData,
      removePaymentMethodData,
    ]
  );
  //endregion

  return (
    <>
      {loadingPlans || loadingSubscription ? (
        'Loading payment plans'
      ) : (
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY as string}>
          <Elements>
            <CompanyPlansSection
              availablePlans={findAllPublicPlans}
              subscription={
                (retryPaymentData && retryPaymentData.retryPaymentData) ||
                getCurrentSubscription!
              }
              customerName={name}
              defaultPaymentMethod={getDefaultPaymentMethod || undefined}
              setDefaultPaymentMethod={handleUpdatePaymentMethod}
              changePlan={handleUpdateSubscription}
              reloadSubscription={refetchCurrentSubscription}
              cancelSubscription={cancelSubscription}
              reactivateSubscription={reactivateSubscription}
              retryPayment={handleRetryPayment}
              removePaymentMethod={handleRemoveDefaultPaymentMethod}
              billingDetails={getBillingDetails}
              vatRate={getVatRate}
              taxCountry={taxCountry}
              numberOfUsers={getCompanyQuotas?.nrUsers}
            />
          </Elements>
        </StripeProvider>
      )}

      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default BillingSettingsPage;
