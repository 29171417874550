import {
  getInvalidFields,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
} from '@lib';
import React, { FC, useCallback, useMemo } from 'react';

import { CreateTaxInput, ITax, UpdateTaxInput } from '@optioffer/core';

import { ApolloModalErrors, ApolloSnackbars } from '@components/Feedback';

import { Company } from '../domain';
import {
  ADD_DEFAULT_TAX,
  DELETE_DEFAULT_TAX,
  GET_COMPANY_DETAILS,
  UPDATE_DEFAULT_TAX,
} from '../graphql';
import TaxSection from '../sections/TaxSection';

type TaxesSettingsPageProps = {};

const TaxesSettingsPage: FC<TaxesSettingsPageProps> = () => {
  //region Queries
  const {
    data: { getCompanyDetails } = { getCompanyDetails: [] },
    error: getCompanyDetailsError,
    loading: loadingCompanyDetails,
  } = useEnhancedQuery<{ getCompanyDetails: Company }>(GET_COMPANY_DETAILS);
  //endregion

  //region Mutations
  const [
    addDefaultTax,
    { data: addDefaultTaxResult, error: addDefaultTaxError },
  ] = useEnhancedMutation<{ addDefaultTax: { add: Company } }, unknown>(
    ADD_DEFAULT_TAX,
    {
      refetchQueries: ['getCompanyDetails'],
    }
  );

  const [
    updateDefaultTax,
    { data: updateDefaultTaxResult, error: updateDefaultTaxError },
  ] = useEnhancedMutation<{ updateDefaultTax: { update: Company } }, unknown>(
    UPDATE_DEFAULT_TAX,
    {
      refetchQueries: ['getCompanyDetails'],
    }
  );

  const [
    deleteDefaultTax,
    { data: deleteDefaultTaxResult, error: deleteDefaultTaxError },
  ] = useEnhancedMutation<{ deleteDefaultTax: { delete: Company } }, unknown>(
    DELETE_DEFAULT_TAX,
    {
      refetchQueries: ['getCompanyDetails'],
    }
  );
  //endregion

  //region memos
  const getCompanyTaxes = useMemo(
    () => getCompanyDetails && (getCompanyDetails as Company).defaultTaxes,
    [getCompanyDetails]
  );
  //endregion

  //region errors
  const errors = useMemo(
    () => [
      {
        error: getCompanyDetailsError,
        message: 'No company found for current user.',
      },
      {
        error: addDefaultTaxError,
        message:
          'There was an error adding the tax. Please try again and if this persists, please contact support.',
      },
      {
        error: updateDefaultTaxError,
        message:
          'There was an error updating the tax. Please try again and if this persists, please contact support.',
      },
      {
        error: deleteDefaultTaxError,
        message:
          'There was an error deleting the tax. Please try again and if this persists, please contact support.',
      },
    ],
    [
      getCompanyDetailsError,
      deleteDefaultTaxError,
      updateDefaultTaxError,
      addDefaultTaxError,
    ]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      { result: addDefaultTaxResult, message: 'Tax added.' },
      { result: updateDefaultTaxResult, message: 'Tax updated.' },
      { result: deleteDefaultTaxResult, message: 'Tax deleted.' },
    ],
    [addDefaultTaxResult, updateDefaultTaxResult, deleteDefaultTaxResult]
  );
  //endregion

  //region Handlers
  const handleAddTax = useCallback(
    async (tax: CreateTaxInput) => {
      return addDefaultTax({
        variables: {
          taxes: [tax],
        },
      }).catch(NOOP_graphqlErrorManagement);
    },
    [addDefaultTax]
  );

  const handleUpdateTax = useCallback(
    async (tax: UpdateTaxInput) => {
      return updateDefaultTax({
        variables: {
          tax: { ...((tax as any) as UpdateTaxInput), __typename: undefined },
        },
      }).catch(NOOP_graphqlErrorManagement);
    },
    [updateDefaultTax]
  );

  const handleDeleteTax = useCallback(
    async (taxId: ITax['id']) => {
      return deleteDefaultTax({
        variables: {
          taxId: taxId,
        },
      }).catch(NOOP_graphqlErrorManagement);
    },
    [deleteDefaultTax]
  );
  //endregion

  return (
    <>
      {loadingCompanyDetails ? (
        'Loading details'
      ) : (
        <TaxSection
          taxes={getCompanyTaxes}
          onAddTax={handleAddTax}
          onDeleteTax={handleDeleteTax}
          onUpdateTax={handleUpdateTax}
          invalidTaxFields={
            getInvalidFields(addDefaultTaxError) ||
            getInvalidFields(updateDefaultTaxError) ||
            undefined
          }
        />
      )}

      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default TaxesSettingsPage;
