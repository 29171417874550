import {
  ConfirmationModal,
  DVKField,
  DVKForm,
  ErrorModal,
  FlexExpander,
  SuccessSnackbar,
  useModal,
} from '@dvkiin/material-commons';
import { Button, Card, CardHeader, Grid } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { findOneCountry } from '@components/Payment';

import CountrySelector from '../../components/form/CountrySelector';
import {
  getInvalidFields,
  useEnhancedMutation,
  useEnhancedQuery,
} from '../../lib';
import { Country } from '../Supplier/domain';
import { Client } from './domain';
import defaultFields from './fields';
import { DELETE_CLIENT, GET_CLIENT, UPDATE_CLIENT } from './graphql';
import useStyles from './styles';

const clientFields = [
  {
    name: 'id',
    label: 'Client unique identifier',
    type: 'text',
    disabled: true,
  },
  ...defaultFields,
] as DVKField[];

type ClientPageParams = {
  id: string;
};

const ClientViewEditPage: FC<RouteComponentProps<ClientPageParams>> = ({
  match: {
    params: { id },
  },
  history: { replace },
}) => {
  const { viewEditCard } = useStyles();

  const [
    inputSelectedCountry,
    setInputSelectedCountry,
  ] = useState<Country | null>(null);

  const {
    data: { getClient } = { getClient: undefined },
    error: getError,
    loading,
  } = useEnhancedQuery<{ getClient: Client | undefined }>(GET_CLIENT, {
    variables: { id },
  });

  const [
    updateClient,
    { data: updateResult, error: updateError },
  ] = useEnhancedMutation<{ updateClient: Client }>(UPDATE_CLIENT);

  const [
    deleteClient,
    { data: deleteResult, error: deleteError },
  ] = useEnhancedMutation(DELETE_CLIENT, {
    refetchQueries: ['findAllClients'],
  });
  const {
    isOpen: isDeleteModalOpen,
    data: deleteModalData,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal<Client>();

  function renderClient(client: Client) {
    const invalidFields = getInvalidFields(updateError);
    return (
      <Card className={viewEditCard}>
        <CardHeader title={client.name} />
        <DVKForm
          fields={[...clientFields]}
          defaultValue={client as any} // TODO [material-commons] defaultValue field should be able to be undefined
          ContentWrapper={CardContent}
          ActionsWrapper={CardActions}
          renderActions={() => (
            <>
              <Button onClick={() => openDeleteModal(client)}>Delete</Button>
              <FlexExpander />
              <Button color="primary" type="submit">
                Save
              </Button>
            </>
          )}
          bottomContent={
            <CountrySelector
              onCountrySelected={setInputSelectedCountry}
              selectorStyleOptions={{
                size: 'medium',
                showAdornment: false,
                inputVariant: 'standard',
                label: 'Country',
              }}
              defaultValue={findOneCountry(client?.country)}
            />
          }
          onSubmit={({
            name,
            email,
            phoneNumber,
            companyName,
            companyVatId,
            companyEmail,
            companyPhoneNumber,
            companyAddress,
            contactPersonName,
            city,
            zipCode,
          }) =>
            updateClient({
              variables: {
                id,
                client: {
                  name,
                  email,
                  phoneNumber,
                  companyName,
                  companyVatId,
                  companyEmail,
                  companyPhoneNumber,
                  companyAddress,
                  contactPersonName,
                  country: inputSelectedCountry?.isoCode,
                  city,
                  zipCode,
                },
              },
            })
          }
          invalidFields={invalidFields}
        />
      </Card>
    );
  }

  async function handleClientDelete() {
    if (!deleteModalData) return;

    const {
      data: { deleteClient: deletedClient },
    } = await deleteClient({ variables: { id: deleteModalData.id } });
    if (deletedClient) {
      replace('/client');
    }
  }

  function getDeleteError() {
    return (
      deleteError ||
      (deleteResult && !deleteResult.deleteClient && deleteResult)
    );
  }

  const deleteUnsuccessful = getDeleteError();

  //TODO: shouldn't we refactor this component to have error/result handling as in the other components?
  return (
    <>
      {loading && 'Loading your client...'} {/* fancy loading screen here */}
      {!!updateResult && (
        <SuccessSnackbar
          key={(updateResult as any).timestamp}
          message={
            <span>Client '{updateResult.updateClient.name}' updated.</span>
          }
        />
      )}
      {getError && (
        <ErrorModal
          key={JSON.stringify(getError)}
          error={getError}
          message="The server was unable to load your client. Please try again later. If this persists, contact admin."
        />
      )}
      {updateError && (
        <ErrorModal
          key={(updateError as any).timestamp}
          error={updateError}
          message="There was an error while updating the client. Please check your input and try again."
        />
      )}
      {deleteUnsuccessful && (
        <ErrorModal
          key={deleteUnsuccessful.timestamp}
          error={deleteUnsuccessful}
          message="There was an error while deleting the client. Please try again."
        />
      )}
      <ConfirmationModal
        open={isDeleteModalOpen}
        onCancel={closeDeleteModal}
        onAccept={handleClientDelete}
        message={`Are you sure you want to delete client '${
          deleteModalData && deleteModalData.name
        }'?`}
        title="Please confirm client delete"
      />
      <Grid container justify="space-between">
        <Grid item sm={12} xl={6}>
          {getClient && renderClient(getClient)}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientViewEditPage;
