import { OptiTextField } from '@components';
import { uuid } from '@dvkiin/material-commons';
import {
  capitalizeFirstLetter,
  isUUID,
  mapFormikToAutocomplete,
  mapFormikToMediaInput,
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
} from '@lib';
import { Box, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useCallback, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  Admin_GetProfileDocument,
  Admin_SearchProfilesDocument,
  Admin_UpdateProfileDocument,
  EmployeeExperienceOnProfilePageFragment,
  ProfileBadgeStatus,
  ProfileStatus,
  ProfileType,
  ProjectContributionInput,
} from '@optioffer/graphql';

import LoadingAwareButton from '@components/LoadingAwareButton';
import MediaInput, { isImage, ProductMedia } from '@components/form/MediaInput';

import { getQueryName } from '@lib/graphql';
import { DEFAULT_SERVICES, PROFILE_CATEGORIES } from '@lib/profile';

import ExperienceCard from '../../Home/Profile/ExperienceCard';
import ProjectContributionCard from '../../Home/Profile/ProjectContributionCard';
import { mapToProfileInput } from '../../Home/Profile/helpers';

type ProfileViewEditPageProps = {
  id: string;
};

const ProfileViewEditPage: FC<
  RouteComponentProps<ProfileViewEditPageProps>
> = ({
  match: {
    params: { id },
  },
}) => {
  const getProfileQuery = useEnhancedQuery(Admin_GetProfileDocument, {
    variables: { id },
  });
  const [updateProfile] = useEnhancedMutation(Admin_UpdateProfileDocument, {
    refetchQueries: [getQueryName(Admin_SearchProfilesDocument)],
    success: {
      message: 'Profile saved.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while saving the profile.',
    },
  });
  const currentProfile = getProfileQuery.data?.profileAdmin.get;

  const formik = useFormik({
    initialValues: {
      companyName: '',
      contactPersonName: '',
      address: '',
      media: [] as ProductMedia[],
      phone: '',
      email: '',
      website: '',
      fbLink: '',
      instaLink: '',
      linLink: '',
      tikTokLink: '',
      experience: [] as EmployeeExperienceOnProfilePageFragment[],
      projectContributions: [] as ProjectContributionInput[],
      description: '',
      category: '',
      areaServed: '',
      services: [] as string[],
      profileStatus: ProfileStatus.DRAFT,
      badgeStatus: ProfileBadgeStatus.DRAFT,
      profileType: ProfileType.PROFESSIONAL,
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (!currentProfile) return;

      setSubmitting(true);
      try {
        const images = values.media
          .filter(isImage)
          .map((it) => it.imageFileForUpload || it.id);

        await updateProfile({
          variables: {
            id: currentProfile.id,
            profile: {
              ...mapToProfileInput(currentProfile),

              // actually changed
              image: images.length ? images[0] : undefined,
              companyName: values.companyName,
              contactPersonName: values.contactPersonName,
              address: values.address,

              // actually changed
              email: values.email,
              phone: values.phone,
              website: values.website,
              fbLink: values.fbLink,
              linLink: values.linLink,
              instaLink: values.instaLink,
              tikTokLink: values.tikTokLink,

              // actually changed
              experience: values.experience
                .filter((xp) => !!xp.companyName)
                .map(
                  ({
                    yearEnd,
                    description,
                    yearStart,
                    companyName,
                    location,
                    jobTitle,
                  }) => ({
                    companyName,
                    jobTitle,

                    description: description || undefined,
                    location: location || undefined,

                    yearStart,
                    yearEnd: yearEnd || undefined,
                  })
                ),

              // actually changed
              projectContributions: values.projectContributions
                .filter((c) => !!c.project.name)
                .map((c) => {
                  return {
                    id: !c.id || isUUID(c.id) ? undefined : c.id,
                    description: c.description,
                    services: c.services,
                    project: {
                      id:
                        !c.project.id || isUUID(c.project.id)
                          ? undefined
                          : c.project.id,
                      name: c.project.name,
                      location: c.project.location,
                      date: c.project.date,
                      link: c.project.link,
                      description: c.project.description,
                      media: c.project.media
                        .filter(isImage)
                        .map((it) => it.imageFileForUpload || it.id),
                    },
                  };
                }),

              // actually changed
              description: values.description,
              category: values.category,
              areaServed: values.areaServed,
              services: values.services,

              // admin
              profileStatus: values.profileStatus,
              badgeStatus: values.badgeStatus,
              profileType: values.profileType,
            },
          },
        });
      } catch {
        NOOP_graphqlErrorManagement();
      } finally {
        setSubmitting(false);
      }
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const newProjectContribution = useCallback(
    () =>
      ({
        id: uuid(),
        description: '',
        services: currentProfile?.services ?? [],
        project: {
          id: uuid(),
          name: '',
          location: '',
          date: '',
          link: '',
          description: '',
          media: [],
        },
      } as ProjectContributionInput),
    [currentProfile]
  );

  useEffect(() => {
    if (currentProfile) {
      formikRef.current.setValues({
        companyName: currentProfile.companyName ?? '',
        contactPersonName: currentProfile.contactPersonName ?? '',
        address: currentProfile.address ?? '',
        media: currentProfile.image
          ? [
              {
                id: uuid(),
                type: 'IMAGE',
                thumbnail: currentProfile.image.thumbnail,
                fullImage:
                  currentProfile.image.fullImage ??
                  currentProfile.image.thumbnail, // 🤔
                isMainPhoto: true,
              },
            ]
          : [],
        // contact
        email: currentProfile.email ?? '',
        phone: currentProfile.phone ?? '',
        website: currentProfile.website ?? '',
        fbLink: currentProfile.fbLink ?? '',
        linLink: currentProfile.linLink ?? '',
        instaLink: currentProfile.instaLink ?? '',
        tikTokLink: currentProfile.tikTokLink ?? '',
        // xp
        experience: currentProfile.experience.length
          ? currentProfile.experience
          : [
              {
                companyName: '',
                jobTitle: '',
                description: '',
                location: '',
                yearStart: '' as any,
                yearEnd: '' as any,
              },
            ],
        // proj
        projectContributions: currentProfile.projectContributions.length
          ? currentProfile.projectContributions.map((c) => ({
              id: c.id,
              description: c.description,
              services: c.services,
              project: {
                id: c.project.id,
                name: c.project.name,
                location: c.project.location,
                date: c.project.date,
                link: c.project.link,
                description: c.project.description,
                media: c.project.media
                  .filter((m) => !!m.image)
                  .map((m) => ({
                    id: uuid(),
                    type: 'IMAGE',
                    thumbnail: m.image!.thumbnail,
                    fullImage: m.image!.fullImage ?? m.image!.thumbnail, // 🤔
                  })),
              },
            }))
          : [newProjectContribution()],

        // services
        description: currentProfile.description ?? '',
        category: currentProfile.category ?? '',
        areaServed: currentProfile.areaServed ?? '',
        services: currentProfile.services ?? [],

        // admin
        profileStatus: currentProfile.profileStatus,
        badgeStatus: currentProfile.badgeStatus,
        profileType: currentProfile.profileType,
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, currentProfile, newProjectContribution]);

  function renderPersonalInfo() {
    return (
      <Paper elevation={5}>
        <Box maxWidth={400} padding={4}>
          <Typography variant="h5" color="primary" gutterBottom>
            Personal info
          </Typography>
          <Box marginBottom={2}>
            <Typography>Profile Image</Typography>
            <MediaInput
              productCode="profile-picture"
              {...mapFormikToMediaInput(formik, 'media')}
            />
          </Box>
          <Box marginY={2}>
            <OptiTextField
              label="Company name"
              placeholder="e.g. You Company SRL"
              {...mapFormikToTextField(formik, 'companyName')}
            />
          </Box>
          <Box marginY={2}>
            <OptiTextField
              label="Contact person name"
              {...mapFormikToTextField(formik, 'contactPersonName')}
            />
          </Box>
          <Box marginY={2}>
            <OptiTextField
              label="Address"
              {...mapFormikToTextField(formik, 'address')}
            />
          </Box>
        </Box>
      </Paper>
    );
  }

  function renderContactInfo() {
    return (
      <Paper elevation={5}>
        <Box maxWidth={400} padding={4}>
          <Typography variant="h5" color="primary" gutterBottom>
            Contact info
          </Typography>
          <Box marginY={2}>
            <OptiTextField
              label="Email"
              {...mapFormikToTextField(formik, 'email')}
            />
          </Box>
          <Box marginY={2}>
            <OptiTextField
              label="Phone number"
              {...mapFormikToTextField(formik, 'phone')}
            />
          </Box>
          <Box marginY={2}>
            <OptiTextField
              label="Website address"
              {...mapFormikToTextField(formik, 'website')}
            />
          </Box>
          <Box marginY={2}>
            <OptiTextField
              label="Facebook profile"
              {...mapFormikToTextField(formik, 'fbLink')}
            />
          </Box>
          <Box marginY={2}>
            <OptiTextField
              label="LinkedIn profile"
              {...mapFormikToTextField(formik, 'linLink')}
            />
          </Box>
          <Box marginY={2}>
            <OptiTextField
              label="Instagram profile"
              {...mapFormikToTextField(formik, 'instaLink')}
            />
          </Box>
          <Box marginY={2}>
            <OptiTextField
              label="TikTok profile"
              {...mapFormikToTextField(formik, 'tikTokLink')}
            />
          </Box>
        </Box>
      </Paper>
    );
  }

  function renderExperience() {
    return (
      <Paper elevation={5}>
        <Box maxWidth={900} padding={4}>
          <Typography variant="h5" color="primary" gutterBottom>
            Experience
          </Typography>
          {formik.values.experience.map((xp, idx) => (
            <Box marginY={2} key={idx}>
              <ExperienceCard
                isAdminPage
                formik={formik}
                fieldName={`experience[${idx}]`}
                removeExperience={() =>
                  formik.setFieldValue(
                    'experience',
                    formik.values.experience.filter((it) => it !== xp)
                  )
                }
              />
            </Box>
          ))}

          <Button
            color="primary"
            onClick={() => {
              formik.setFieldValue('experience', [
                ...formik.values.experience,
                {
                  companyName: '',
                  jobTitle: '',
                  description: '',
                  location: '',
                  yearStart: '',
                  yearEnd: '',
                },
              ]);
            }}
          >
            + Add Experience
          </Button>
        </Box>
      </Paper>
    );
  }

  function renderProjects() {
    return (
      <Paper elevation={5}>
        <Box maxWidth={900} padding={4}>
          <Typography variant="h5" color="primary" gutterBottom>
            Projects
          </Typography>
          {formik.values.projectContributions.map((xp, idx) => (
            <Box marginY={2} key={idx}>
              <ProjectContributionCard
                isAdminPage
                formik={formik}
                fieldName={`projectContributions[${idx}]`}
                removeProjectContribution={() =>
                  formik.setFieldValue(
                    'projectContributions',
                    formik.values.projectContributions.filter((it) => it !== xp)
                  )
                }
              />
            </Box>
          ))}

          <Button
            color="primary"
            onClick={() => {
              formik.setFieldValue('projectContributions', [
                ...formik.values.projectContributions,
                newProjectContribution(),
              ]);
            }}
          >
            + Add Project
          </Button>
        </Box>
      </Paper>
    );
  }

  function renderServices() {
    if (!currentProfile) return;
    return (
      <Paper elevation={5}>
        <Box maxWidth={400} padding={4}>
          <Typography variant="h5" color="primary" gutterBottom>
            Services
          </Typography>
          <Box marginY={2}>
            <OptiTextField
              label="Bio"
              multiline
              rows={7}
              {...mapFormikToTextField(formik, 'description')}
            />
          </Box>
          <Box marginY={2}>
            <OptiTextField
              label="Available to work in"
              {...mapFormikToTextField(formik, 'areaServed')}
            />
          </Box>
          <Box marginY={2}>
            <Autocomplete
              options={
                (PROFILE_CATEGORIES[currentProfile.profileType] ??
                  []) as string[]
              }
              freeSolo
              renderInput={(params) => (
                <OptiTextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Category"
                  autoComplete="off"
                />
              )}
              {...mapFormikToAutocomplete(formik, 'category')}
              size="small"
            />
          </Box>

          <Box marginY={2}>
            <Autocomplete
              options={DEFAULT_SERVICES}
              freeSolo
              multiple
              renderInput={(params) => (
                <OptiTextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={
                    currentProfile?.profileType === ProfileType.EMPLOYEE
                      ? 'Skills'
                      : 'Services'
                  }
                  autoComplete="off"
                  helperText={`Type to search; use Enter to add a new${
                    currentProfile?.profileType === ProfileType.EMPLOYEE
                      ? 'skill'
                      : 'service'
                  }.`}
                />
              )}
              {...mapFormikToAutocomplete(formik, 'services')}
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  'services',
                  newValue.map(capitalizeFirstLetter)
                );
              }}
              size="small"
            />
          </Box>
        </Box>
      </Paper>
    );
  }

  function renderOnlyAdmin() {
    return (
      <Paper elevation={5}>
        <Box width={400} padding={4}>
          <Typography variant="h5" color="primary" gutterBottom>
            Only Admin
          </Typography>
          <Box marginY={2}>
            <Autocomplete
              options={Object.keys(ProfileStatus)}
              renderInput={(params) => (
                <OptiTextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Profile Status"
                  autoComplete="off"
                />
              )}
              {...mapFormikToAutocomplete(formik, 'profileStatus')}
              size="small"
            />
          </Box>
          <Box marginY={2}>
            <Autocomplete
              options={Object.keys(ProfileBadgeStatus)}
              renderInput={(params) => (
                <OptiTextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Badge Status"
                  autoComplete="off"
                />
              )}
              {...mapFormikToAutocomplete(formik, 'badgeStatus')}
              size="small"
            />
          </Box>
          <Box marginY={2}>
            <Autocomplete
              options={Object.keys(ProfileType)}
              renderInput={(params) => (
                <OptiTextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Profile Type"
                  autoComplete="off"
                />
              )}
              {...mapFormikToAutocomplete(formik, 'profileType')}
              size="small"
            />
          </Box>
        </Box>
      </Paper>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginBottom={4}
        gridGap={4}
      >
        <LoadingAwareButton
          variant="contained"
          color="primary"
          type="submit"
          loading={formik.isSubmitting}
        >
          Save Profile
        </LoadingAwareButton>
      </Box>
      <Box display="flex" flexWrap="wrap" gridGap={16} marginBottom={4}>
        {renderOnlyAdmin()}
        {renderPersonalInfo()}
        {renderContactInfo()}
        {renderServices()}
      </Box>
      <Box display="flex" flexWrap="wrap" gridGap={16}>
        {renderExperience()}
        {renderProjects()}
      </Box>
    </form>
  );
};

export default ProfileViewEditPage;
