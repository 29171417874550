import { ModalControl } from '@lib';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC, ReactNode } from 'react';

import { ModalTransition } from '@components/transitions';

const useLocalStyles = makeStyles((theme) => ({
  modalPaper: {
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`,
  },
}));

export type InfoModalProps = {
  control: ModalControl;
  message?: ReactNode;
  title: ReactNode;
};

const InfoModal: FC<InfoModalProps> = ({ control, message, title }) => {
  const localClasses = useLocalStyles();

  return (
    <Dialog
      TransitionComponent={ModalTransition}
      open={control.isOpen}
      onClose={control.close}
      fullWidth
      classes={{
        paperFullWidth: localClasses.modalPaper,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message && typeof message === 'string' ? (
          <DialogContentText>{message}</DialogContentText>
        ) : (
          message
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InfoModal;
