import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  flex: {
    display: 'flex',
  },
  icon: {
    width: 20,
    marginLeft: theme.spacing(1),
  },
  dropDownIcon: {
    height: 20,
    marginRight: theme.spacing(2),
  },
  center: {
    alignItems: 'center',
  },
}));
