import React, { FC } from 'react';

import { findOneShortCountry } from '@components/Payment';
import OOTable, {
  OOTableCell,
  OOTableGenericProps,
  OOTableRow,
} from '@components/Table/OOTable';

import { Client, Supplier } from './domain';

type SupplierCustomerTableProps<T = Supplier | Client> = OOTableGenericProps<T>;

const SupplierCustomerTable: FC<SupplierCustomerTableProps> = ({
  onEdit,
  ...rest
}) => {
  return (
    <OOTable
      {...rest}
      onEdit={onEdit}
      renderHeader={() => (
        <>
          <OOTableCell width="25%">Contact Person</OOTableCell>
          <OOTableCell width="20%" align="left">
            Company & TAX ID
          </OOTableCell>
          <OOTableCell width="20%" align="left">
            Phone Number
          </OOTableCell>
          <OOTableCell width="20%" align="left">
            Email
          </OOTableCell>
          <OOTableCell width="15%" align="center">
            Country
          </OOTableCell>
        </>
      )}
      renderRow={(row) => (
        <OOTableRow key={row.name} onClick={() => onEdit(row)}>
          <OOTableCell component="th" scope="row">
            {row.name}
          </OOTableCell>
          <OOTableCell align="left">
            <div>
              <strong>{row.companyName}</strong>
            </div>
            <div style={{ color: '#9a9a9a' }}>{row.companyVatId}</div>
          </OOTableCell>
          <OOTableCell align="left">{row.phoneNumber}</OOTableCell>
          <OOTableCell align="left">{row.email}</OOTableCell>
          <OOTableCell align="center">
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              {findOneShortCountry(row?.country)?.label}
            </div>
          </OOTableCell>
        </OOTableRow>
      )}
    />
  );
};

export default SupplierCustomerTable;
