var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Message, useLocalize } from 'localize-react';
import React, { useContext } from 'react';
import { CalculationType, formatDiscount, formatPrice, ooBrand, sum, } from '@optioffer/core';
import { OfferPrintContext, useDevColor } from '../../helpers';
import { commonStyles } from '../style';
var offerItemStyles = StyleSheet.create({
    addonsAndPrice: {
        flexDirection: 'row',
        borderColor: ooBrand.colors.secondary['200'],
        borderWidth: 1,
        borderLeft: 0,
        borderRight: 0,
    },
    price: {
        flex: 1,
        flexDirection: 'row',
    },
    priceCol: {
        flex: 2,
        marginLeft: '2mm',
    },
    quantityCol: {
        flex: 1,
    },
    nameCol: {
        flex: 3,
    },
});
var TextView = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (React.createElement(View, __assign({}, rest),
        React.createElement(Text, { style: { textAlign: 'right', maxLines: 1 } }, children)));
};
var PricingRow = /** @class */ (function () {
    function PricingRow(text, price, quantity, total) {
        this.text = text;
        this.price = price;
        this.quantity = quantity;
        this.total = total;
    }
    return PricingRow;
}());
function getAllTaxes(offerItem, whichTaxes) {
    return __spreadArray(__spreadArray([], offerItem.pricing[whichTaxes].map(function (tax) { return (__assign(__assign({}, tax), { quantity: offerItem.quantity })); })), offerItem.addons
        .flatMap(function (addon) { return addon.pricing[whichTaxes]; })
        .map(function (tax) { return (__assign(__assign({}, tax), { quantity: 1 })); }));
}
function groupTaxes(taxes) {
    return taxes
        .reduce(function (acc, tax) {
        var existingTax = acc.find(function (t) { return tax.tax.id === t.tax.id; });
        if (existingTax) {
            if (tax.tax.calculationType === CalculationType.PERCENTAGE) {
                existingTax.value = 0;
                existingTax.quantity = 0;
            }
            else {
                existingTax.quantity += tax.quantity;
            }
            existingTax.valueTotal += tax.valueTotal;
        }
        else {
            return __spreadArray(__spreadArray([], acc), [__assign({}, tax)]);
        }
        return acc;
    }, [])
        .map(function (tax) {
        return new PricingRow("" + tax.tax.name + (tax.tax.calculationType === CalculationType.PERCENTAGE
            ? " (" + formatDiscount(tax.tax.value) + ")"
            : ''), tax.value || null, tax.quantity || null, tax.valueTotal);
    });
}
function groupDiscounts(discounts) {
    return discounts
        .filter(function (it) { return !!it; })
        .map(function (it) { return it; })
        .reduce(function (groupedDiscount, discount) {
        if (groupedDiscount) {
            groupedDiscount.value = 0;
            groupedDiscount.quantity = 0;
            groupedDiscount.valueTotal += discount.valueTotal;
            return groupedDiscount;
        }
        else {
            return discount;
        }
    }, null);
}
var PricingTable = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var offerItem = _a.offerItem, style = _a.style, _l = _a.hStyle, hStyle = _l === void 0 ? commonStyles.h : _l;
    var displayDiscounts = useContext(OfferPrintContext).offer.displayDiscounts;
    var devColor = useDevColor();
    var translate = useLocalize().translate;
    var pricingRows = [];
    // list price with or without discount
    var listPrice = offerItem.pricing.listPrice;
    var listPriceTotal = offerItem.pricing.listPriceTotal;
    var hideDiscounts = !displayDiscounts && !offerItem.pricing.taxesBeforeDiscount.length;
    if (hideDiscounts) {
        listPrice -= ((_b = offerItem.pricing.discount) === null || _b === void 0 ? void 0 : _b.value) || 0;
        listPriceTotal -= ((_c = offerItem.pricing.discount) === null || _c === void 0 ? void 0 : _c.valueTotal) || 0;
        listPrice -= ((_d = offerItem.pricing.discountFinal) === null || _d === void 0 ? void 0 : _d.value) || 0;
        listPriceTotal -= ((_e = offerItem.pricing.discountFinal) === null || _e === void 0 ? void 0 : _e.valueTotal) || 0;
    }
    pricingRows.push(new PricingRow(translate('product.listPrice'), listPrice, offerItem.quantity, listPriceTotal));
    // addons list price
    offerItem.addons.forEach(function (addon) {
        var _a, _b;
        var addonListPrice = addon.pricing.listPrice;
        var hideAddonDiscounts = !displayDiscounts && !addon.pricing.taxesBeforeDiscount.length;
        if (hideAddonDiscounts) {
            addonListPrice -= ((_a = offerItem.pricing.discount) === null || _a === void 0 ? void 0 : _a.value) || 0;
            addonListPrice -= ((_b = offerItem.pricing.discountFinal) === null || _b === void 0 ? void 0 : _b.value) || 0;
        }
        pricingRows.push(new PricingRow(addon.name, addonListPrice, 1, addonListPrice));
    });
    // taxes before discount
    pricingRows.push.apply(pricingRows, groupTaxes(getAllTaxes(offerItem, 'taxesBeforeDiscount')));
    // item discount
    if (!hideDiscounts && ((_f = offerItem.pricing.discount) === null || _f === void 0 ? void 0 : _f.value)) {
        pricingRows.push(new PricingRow(translate('product.discount', {
            discount: formatDiscount((_g = offerItem.pricing.discount) === null || _g === void 0 ? void 0 : _g.discount.value),
        }), -((_h = offerItem.pricing.discount) === null || _h === void 0 ? void 0 : _h.value), offerItem.quantity, -((_j = offerItem.pricing.discount) === null || _j === void 0 ? void 0 : _j.valueTotal)));
    }
    // addons discounts
    offerItem.addons.forEach(function (addon) {
        var _a, _b, _c;
        var hideAddonDiscounts = !displayDiscounts && !addon.pricing.taxesBeforeDiscount.length;
        if (!hideAddonDiscounts) {
            pricingRows.push(new PricingRow(translate('product.addonDiscount', {
                addon: addon.name,
                discount: formatDiscount((_a = addon.pricing.discount) === null || _a === void 0 ? void 0 : _a.discount.value),
            }), -((_b = addon.pricing.discount) === null || _b === void 0 ? void 0 : _b.value), 1, -((_c = addon.pricing.discount) === null || _c === void 0 ? void 0 : _c.valueTotal)));
        }
    });
    if (!hideDiscounts && ((_k = offerItem.pricing.discountFinal) === null || _k === void 0 ? void 0 : _k.value)) {
        var finalDiscount = groupDiscounts(__spreadArray([
            offerItem.pricing.discountFinal
                ? __assign(__assign({}, offerItem.pricing.discountFinal), { quantity: offerItem.quantity }) : null
        ], offerItem.addons
            .flatMap(function (addon) { return addon.pricing.discountFinal; })
            .map(function (discount) { return (discount ? __assign(__assign({}, discount), { quantity: 1 }) : null); })));
        if (finalDiscount) {
            pricingRows.push(new PricingRow(translate('product.additionalDiscount', {
                discount: formatDiscount(finalDiscount.discount.value),
            }), -finalDiscount.value || null, finalDiscount.quantity || null, -finalDiscount.valueTotal));
        }
    }
    // taxes after discount
    pricingRows.push.apply(pricingRows, groupTaxes(getAllTaxes(offerItem, 'taxesAfterDiscount')));
    // final taxes
    pricingRows.push.apply(pricingRows, groupTaxes(getAllTaxes(offerItem, 'taxesFinal')));
    // total
    var netPriceTotal = offerItem.pricing.netPriceTotal +
        offerItem.addons.map(function (it) { return it.pricing.netPrice; }).reduce(sum, 0);
    pricingRows.push(new PricingRow(translate('product.netPrice'), offerItem.addons.length ? null : offerItem.pricing.netPrice, offerItem.addons.length ? null : offerItem.quantity, netPriceTotal));
    return (React.createElement(View, { wrap: false, style: __spreadArray(__spreadArray([
            offerItemStyles.addonsAndPrice
        ], (Array.isArray(style) ? style : [style || {}])), [
            devColor('#F57F17'),
        ]) },
        React.createElement(View, { style: [offerItemStyles.price, devColor('#FDD835')] },
            React.createElement(View, { style: [offerItemStyles.priceCol, offerItemStyles.nameCol] },
                React.createElement(TextView, { style: [commonStyles.p, hStyle] }, " "),
                pricingRows.map(function (row, index) { return (React.createElement(TextView, { key: index, style: index === pricingRows.length - 1
                        ? [commonStyles.p, hStyle]
                        : undefined }, row.text)); })),
            React.createElement(View, { style: [offerItemStyles.priceCol] },
                React.createElement(TextView, { style: [commonStyles.p, hStyle] }, offerItem.pricing.offerItemCurrency.id),
                pricingRows.map(function (row, index) { return (React.createElement(TextView, { key: index, style: index === pricingRows.length - 1
                        ? [commonStyles.p, hStyle]
                        : undefined }, row.price
                    ? formatPrice(row.price, offerItem.pricing.offerItemCurrency, true)
                    : ' ')); })),
            React.createElement(View, { style: [offerItemStyles.priceCol, offerItemStyles.quantityCol] },
                React.createElement(TextView, { style: [commonStyles.p, hStyle] },
                    React.createElement(Message, { descriptor: "product.quantity" })),
                pricingRows.map(function (row, index) { return (React.createElement(TextView, { key: index, style: index === pricingRows.length - 1
                        ? [commonStyles.p, hStyle]
                        : undefined }, row.quantity || ' ')); })),
            React.createElement(View, { style: [offerItemStyles.priceCol] },
                React.createElement(TextView, { style: [commonStyles.p, hStyle] },
                    React.createElement(Message, { descriptor: "product.total" })),
                pricingRows.map(function (row, index) { return (React.createElement(TextView, { key: index, style: index === pricingRows.length - 1
                        ? [commonStyles.p, hStyle]
                        : undefined }, row.total
                    ? formatPrice(row.total, offerItem.pricing.offerItemCurrency, true)
                    : ' ')); })))));
};
export default PricingTable;
