import { uuid } from '@dvkiin/material-commons';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useMemo } from 'react';

export * from './graphql';
export * from './pagination';

export const useUuid = () => useMemo(() => uuid(), []);

export const useIsMobile = () => {
  const theme = useTheme();
  // breakpoints.down is inclusive; down(md) = < 1280px
  return useMediaQuery(theme.breakpoints.down('md'));
};
