import { SecurityContext, useIsMobile } from '@lib';
import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useContext, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { formatDiscount, formatPrice } from '@optioffer/core';
import { CalculationType, Tax } from '@optioffer/graphql';

import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';

import useStyles from '../styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

export type QuoteTaxesModalData = {
  taxes: Tax[];
};

type QuoteTaxesModalProps = {
  control: ModalControl<QuoteTaxesModalData>;
  updateQuoteTaxes: (taxes: Tax[]) => Promise<any>;
};

const QuoteTaxesModal: FC<QuoteTaxesModalProps> = ({
  control,
  updateQuoteTaxes,
}) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const {
    company: { currency: defaultCurrency },
  } = useContext(SecurityContext);

  const formik = useFormik({
    initialValues: {
      taxes: [] as Tax[],
    },
    onSubmit: async (values) => {
      if (!control.data) return;

      await updateQuoteTaxes(values.taxes);

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.data) {
      formikRef.current.resetForm({
        values: {
          taxes: control.data.taxes ?? [],
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = 'Taxes';
  return (
    <OOModal
      open={control.isOpen}
      onClose={control.close}
      title={title}
      minWidth="sm"
    >
      <Box marginX={isMobile ? 0 : 3} marginBottom={isMobile ? 0 : 3}>
        <form onSubmit={formik.handleSubmit}>
          {!isMobile && (
            <Box className={classes.desktopModalTitle}>{title}</Box>
          )}
          <Box height={'100%'} marginBottom={10}>
            <Box paddingX={1.5}>
              {formik.values.taxes.map((tax, idx) => (
                <Box marginY={2} key={tax.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...formik.getFieldProps({
                          name: `taxes.${idx}.enabled`,
                          type: 'checkbox',
                        })}
                      />
                    }
                    label={`${tax.name} (${
                      tax.calculationType === CalculationType.FIXED
                        ? formatPrice(
                            tax.value,
                            tax.currency ?? defaultCurrency
                          )
                        : formatDiscount(tax.value)
                    })`}
                  />
                </Box>
              ))}
            </Box>
          </Box>

          <Box marginBottom={10} />

          <Box display="flex" className={classes.floatingFooter}>
            <Box flex="1">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={!formik.dirty}
              >
                {'Save Changes'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </OOModal>
  );
};

export default QuoteTaxesModal;
