import { MenuMode, SecurityContext } from '@lib';
import React, { FC, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import EmployeeProHome from './EmployeeProHome';
import SupplierHome from './SupplierHome';

const HomeModule: FC<RouteComponentProps> = () => {
  const securityContext = useContext(SecurityContext);

  if (
    securityContext.menuMode === MenuMode.EMPLOYEE ||
    securityContext.menuMode === MenuMode.PRO ||
    securityContext.menuMode === MenuMode.PRO_EMPLOYEE
  )
    return <EmployeeProHome />;

  return <SupplierHome />;
};

export default HomeModule;
