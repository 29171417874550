import {
  NumberFormatInput,
  OptiTextField,
  SelectAndTextInput,
} from '@components';
import {
  mapFormikToSelectAndTextField,
  SecurityContext,
  useIsMobile,
} from '@lib';
import { Box, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useContext, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';
import * as Yup from 'yup';

import { CalculationType, DiscountInput } from '@optioffer/graphql';

import OOModal from '@components/modals/OOModal';

import { OfferContext } from '@containers/OfferContext';

import { ModalControl } from '@lib/materialCommons';

import moneyIllustration from '@resources/illustrations/money_pig.svg';

import { ProductDataWithOfferItem } from './domain';
import useStyles from './styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

export type DiscountModalData = ProductDataWithOfferItem & {
  discount: DiscountInput;
  basePrice: number;
  quantity: number;
};

type DiscountModalProps = {
  control: ModalControl<DiscountModalData>;
  updateDiscount: (discount: DiscountInput) => Promise<any>;
};

const DiscountModal: FC<DiscountModalProps> = ({ control, updateDiscount }) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const offer = useContext(OfferContext);
  const { company } = useContext(SecurityContext);

  const formik = useFormik({
    initialValues: {
      type: CalculationType.PERCENTAGE,
      value: 0,
    },
    validationSchema: Yup.object({
      value: Yup.number().min(0, 'Discount should be positive.'),
    }),
    onSubmit: async (values) => {
      if (!control.data) return;

      await updateDiscount({
        calculationType: values.type,
        value: values.value ?? 0,
      });

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.data) {
      formikRef.current.resetForm({
        values: {
          type: control.data.discount.calculationType,
          value: control.data.discount.value ?? 0,
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = 'Discount';
  const subTitle = `${control.data?.offerItem.product?.code} - ${control.data?.offerItem.product?.name}`;

  return (
    <>
      <OOModal
        open={control.isOpen}
        onClose={control.close}
        title={title}
        subTitle={subTitle}
      >
        <Box display="flex">
          {!isMobile && (
            <Box
              position="absolute"
              right={0}
              top={-90}
              className={classes.clickThrough}
            >
              <img
                className={classes.illustration}
                src={moneyIllustration}
                alt="discount illustration"
              />
            </Box>
          )}
          <Box
            flex={1}
            marginLeft={isMobile ? 0 : 4}
            marginRight={isMobile ? 0 : 6}
            marginBottom={isMobile ? 0 : 4}
          >
            <form onSubmit={formik.handleSubmit}>
              {!isMobile && (
                <>
                  <Box className={classes.desktopModalTitle}>{title}</Box>
                  <Box className={classes.desktopModalSubTitle}>{subTitle}</Box>
                </>
              )}

              <Box paddingX={1.5}>
                <Box marginY={2}>
                  <SelectAndTextInput
                    label="Discount"
                    options={[
                      { label: '%', value: CalculationType.PERCENTAGE },
                      {
                        label: (offer?.currency ?? company.currency).symbol,
                        value: CalculationType.FIXED,
                      },
                    ]}
                    {...mapFormikToSelectAndTextField(
                      formik,
                      'type',
                      'value',
                      {},
                      {
                        InputProps: {
                          inputComponent: NumberFormatInput as any,
                          inputProps: {
                            textAlign: 'right',
                            onFocus: (e) => e.target.select(),
                          },
                        },
                      }
                    )}
                  />
                </Box>
              </Box>

              {control.data?.quantity && control.data.quantity > 1 && (
                <Box paddingX={1.5}>
                  <Box marginY={2}>
                    <OptiTextField
                      label="Discount Total"
                      InputProps={{
                        inputComponent: NumberFormatInput as any,
                        inputProps: {
                          textAlign: 'right',
                          suffix: ` ${
                            (offer?.currency ?? company.currency).symbol
                          }`,
                          onFocus: (e) => e.target.select(),
                        },
                      }}
                      value={
                        formik.values.type === CalculationType.PERCENTAGE
                          ? ((control.data?.basePrice ?? 0) *
                              control.data.quantity *
                              formik.values.value) /
                            100
                          : control.data.quantity * formik.values.value
                      }
                      onChange={({ target }) => {
                        const discountValue =
                          ((target.value as unknown) as number) ?? 0;
                        if (formik.values.type === CalculationType.PERCENTAGE) {
                          formik.setFieldValue(
                            'value',
                            (discountValue /
                              (control.data?.basePrice ?? 1) /
                              (control.data?.quantity ?? 1)) *
                              100
                          );
                        } else {
                          formik.setFieldValue(
                            'value',
                            discountValue / (control.data?.quantity ?? 1)
                          );
                        }
                      }}
                    />
                  </Box>
                </Box>
              )}

              <Box marginBottom={10} />

              <Box display="flex" className={classes.floatingFooter}>
                <Box flex="1" margin={isMobile ? 0 : 1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={!formik.dirty}
                  >
                    {!formik.dirty
                      ? 'Changes Saved'
                      : formik.values.value
                      ? 'Apply Discount'
                      : 'Remove Discount'}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </OOModal>
    </>
  );
};

export default DiscountModal;
