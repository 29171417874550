import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import React, { FC, ReactNode, useState } from 'react';

export type SuccessSnackbarProps = {
  message: ReactNode;
  action?: ReactNode;
};

const SuccessSnackbar: FC<SuccessSnackbarProps> = ({ message, action }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const autoHide = Math.max(
    2 * 1000,
    Math.min(
      10 * 1000,
      (typeof message === 'string' ? message.length * 100 : Infinity) +
        (action ? 2 * 1000 : 0)
    )
  );

  return (
    <Snackbar open={open} onClose={handleClose} autoHideDuration={autoHide}>
      <Alert onClose={handleClose} action={action} severity="success">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SuccessSnackbar;
