import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { FC, MouseEvent, useState } from 'react';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

type LoadingAwareButtonProps = Omit<ButtonProps, 'onClick'> & {
  onClick?: (event: MouseEvent) => Promise<any>;
  loading?: boolean;
};

const LoadingAwareButton: FC<LoadingAwareButtonProps> = ({
  children,
  onClick,
  disabled,
  loading,
  ...props
}) => {
  // const localClasses = useLocalStyles();
  const [autoDetectedLoading, setAutoDetectedLoading] = useState(false);

  const isLocked = loading || autoDetectedLoading;
  return (
    <Button
      onClick={
        onClick
          ? (event) => {
              setAutoDetectedLoading(true);
              return onClick(event).finally(() =>
                setAutoDetectedLoading(false)
              );
            }
          : undefined
      }
      {...props}
      disabled={isLocked || disabled}
    >
      {isLocked ? <CircularProgress size={24.5} /> : children}
    </Button>
  );
};

export default LoadingAwareButton;
