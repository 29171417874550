import { FC } from 'react';

import { OfferInOfferModuleFragment } from '@optioffer/graphql';

import SendStepPage from './SendStepPage';

type SendStepProps = {
  offer: OfferInOfferModuleFragment;
  updateOffer(
    offer: OfferInOfferModuleFragment
  ): Promise<OfferInOfferModuleFragment>;
  setStep(step: number): void;
};

const SendStep: FC<SendStepProps> = ({ offer, updateOffer, setStep }) => {
  return (
    <SendStepPage quote={offer} updateOffer={updateOffer} setStep={setStep} />
  );
};

export default SendStep;
