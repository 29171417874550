import { useIsMobile } from '@lib';
import { Box, Button } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';

import { OfferItem, Product, ProductVersion } from '@optioffer/graphql';

import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';

import ProductList from './ProductList';
import { GetProductFn, ProductSearchResult } from './domain';
import useStyles from './styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

export type SearchAccessoryModalData = {
  productName: Product['name'];
  productVersionCode: ProductVersion['code'];
  offerItemAccessories?: OfferItem[];
  productAccessories?: ProductSearchResult[];
};

type SearchAccessoryModalProps = {
  control: ModalControl<SearchAccessoryModalData>;

  handleUpdateOfferItem?: (offerItem: OfferItem) => Promise<OfferItem>;
  handleUpdateSelectedProduct?: (
    product: ProductSearchResult,
    selected: boolean
  ) => Promise<any>;
  getProduct: GetProductFn;

  accessoryModalControl: ModalControl<ProductSearchResult>;
};

const SearchAccessoryModal: FC<SearchAccessoryModalProps> = ({
  control,

  handleUpdateOfferItem,
  handleUpdateSelectedProduct,
  getProduct,

  accessoryModalControl,
}) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const title = 'Search Accessory';
  const subTitle = `${control.data?.productName} - ${control.data?.productVersionCode}`;
  return (
    <OOModal
      title={title}
      subTitle={subTitle}
      open={control.isOpen}
      onClose={control.close}
      minWidth="lg"
    >
      <Box marginX={isMobile ? 0 : 3} marginBottom={isMobile ? 0 : 3}>
        {!isMobile && (
          <Box marginTop={-3}>
            <Box className={classes.desktopModalTitle}>{title}</Box>
            <Box className={classes.desktopModalSubTitle}>{subTitle}</Box>
          </Box>
        )}

        <Box height={'100%'}>
          <Box paddingX={1.5}>
            <ProductList
              getProduct={getProduct}
              productModal={accessoryModalControl}
              offerItems={control.data?.offerItemAccessories}
              selectedProducts={control.data?.productAccessories}
              updateOfferItem={handleUpdateOfferItem}
              updateSelectedProduct={handleUpdateSelectedProduct}
              isAccessoryList
            />
          </Box>
        </Box>

        <Box height={isMobile ? 10 : 2} />

        <Box display="flex" className={classes.floatingFooter}>
          <Box
            flex="1"
            margin={isMobile ? 0 : 1.5}
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              className="NavigationButton"
              variant="contained"
              color="primary"
              fullWidth
              onClick={control.close}
            >
              {'Confirm Accessories'}
            </Button>
          </Box>
        </Box>
      </Box>
    </OOModal>
  );
};

export default SearchAccessoryModal;
