import clsx from 'clsx';
import React, { forwardRef } from 'react';

import SimpleCard, { SimpleCardProps } from './SimpleCard';
import useStyles from './styles';

type ColoredCardProps = {
  label?: string;
  group?: 'client' | 'product' | 'offer' | 'catalog' | 'addon';
} & SimpleCardProps;

const ColoredCard = forwardRef<unknown, ColoredCardProps>(
  ({ children, className, label, group, ...rest }, ref) => {
    const styles = useStyles();

    return (
      <SimpleCard
        ref={ref}
        label={label}
        className={clsx(className, styles.coloredCard, group && styles[group])}
        {...rest}
      >
        {children}
      </SimpleCard>
    );
  }
);

export default ColoredCard;
