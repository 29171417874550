import { useIsMobile } from '@lib';
import { Button, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import * as React from 'react';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { OfferInOfferModuleFragment } from '@optioffer/graphql';

import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';

import { StepDoneIcon } from '@resources/icons';
import { QuoteSentIllustration } from '@resources/illustrations';
import quoteSentDesktopIllustration from '@resources/illustrations/quote_sent_desktop.svg';

import useStyles from '../styles';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',

    '& > div': {
      zIndex: 1,
    },
  },
  bgIllustration: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: -50,
  },
  checkIconWrapper: {
    padding: theme.spacing(5),
    '& > svg': {
      height: theme.spacing(4.5),
      width: theme.spacing(4.5),
    },
  },
  header: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(26),
    color: theme.palette.primary.main,
  },
  body: {
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
}));

export type QuoteSentModalData = {
  offer: OfferInOfferModuleFragment;
};

type QuoteSentModalProps = {
  control: ModalControl<QuoteSentModalData>;
};

const QuoteSentModal: FC<QuoteSentModalProps> = ({ control }) => {
  const localClasses = useLocalStyles();
  const classes = useStyles();
  const { push } = useHistory();
  const isMobile = useIsMobile();

  return (
    <OOModal
      open={control.isOpen}
      onClose={control.close}
      innerWrapperClassName={localClasses.root}
      title=""
      minWidth="sm"
    >
      {!isMobile && (
        <Box
          position="absolute"
          right={-63}
          top={-53}
          left={47}
          className={classes.clickThrough}
        >
          <img
            className={classes.illustration}
            src={quoteSentDesktopIllustration}
            alt="quote sent background"
          />
        </Box>
      )}
      <Box className={localClasses.mainContainer}>
        {isMobile && (
          <QuoteSentIllustration className={localClasses.bgIllustration} />
        )}
        <Box className={localClasses.checkIconWrapper}>
          <StepDoneIcon />
        </Box>
        <Box className={localClasses.header}>Quote sent!</Box>
        <Box className={localClasses.body}>
          The quote is on it's way to <br />
          {control.data?.offer.client?.name} at <br />
          <strong>{control.data?.offer.client?.email}</strong>.
        </Box>
      </Box>
      <Box padding={2}>
        <Box width="100%" marginTop={3.5}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => push('/offer')}
          >
            Go to Quote list
          </Button>
        </Box>
      </Box>
    </OOModal>
  );
};

export default QuoteSentModal;
