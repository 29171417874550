import { useHasRole } from '@lib';
import { Grid, List, ListItem } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { UserRole } from '@optioffer/graphql';

import BillingSettingsPage from './pages/BillingPage';
import CompanyDetailsSettingsPage from './pages/CompanyDetailsPage';
import ExternalSettingsPage from './pages/ExternalSettingsPage';
import GeneralSettingsPage from './pages/GeneralSettingsPage';
import ProfilePage from './pages/ProfileSettingsPage';
import TaxesSettingsPage from './pages/TaxesPage';
import TeamSettingsPage from './pages/TeamPage';
import useStyles from './styles';

const SettingsMenuPage: FC<RouteComponentProps> = ({ match }) => {
  const { pointer, bold, container } = useStyles();
  const { push } = useHistory();
  const location = useLocation();
  const isOwner = useHasRole(UserRole.OWNER);
  const isManager = useHasRole(UserRole.MANAGER);

  if (location.pathname === '/settings') {
    return (
      <Redirect
        to={isManager ? '/settings/companyDetails' : '/settings/profile'}
      />
    );
  }

  return (
    <>
      <h1>Settings</h1>
      <Grid container className={container}>
        <Grid
          item
          lg={3}
          style={{
            marginRight: 16,
            marginBottom: 16,
            minWidth: 275,
            backgroundColor: 'white',
            height: 'fit-content',
            boxShadow: '1px 1px 4px #0578692E',
          }}
        >
          <List>
            <ListItem
              className={clsx(
                pointer,
                location.pathname === '/settings/profile' && bold
              )}
              onClick={() => push('/settings/profile')}
            >
              My Account
            </ListItem>
            {isManager && (
              <ListItem
                className={clsx(
                  pointer,
                  location.pathname === '/settings/team' && bold
                )}
                onClick={() => push('/settings/team')}
              >
                My Team
              </ListItem>
            )}
            {isOwner && (
              <>
                <Divider />
                <ListItem
                  className={clsx(
                    pointer,
                    location.pathname === '/settings/companyDetails' && bold
                  )}
                  onClick={() => push('/settings/companyDetails')}
                >
                  Company Settings
                </ListItem>
                <ListItem
                  className={clsx(
                    pointer,
                    location.pathname === '/settings/general' && bold
                  )}
                  onClick={() => push('/settings/general')}
                >
                  Quote Settings
                </ListItem>
                <ListItem
                  className={clsx(
                    pointer,
                    location.pathname === '/settings/taxes' && bold
                  )}
                  onClick={() => push('/settings/taxes')}
                >
                  Taxes
                </ListItem>
                <ListItem
                  className={clsx(
                    pointer,
                    location.pathname === '/settings/external' && bold
                  )}
                  onClick={() => push('/settings/external')}
                >
                  Integrations
                </ListItem>
                <Divider />
                <ListItem
                  className={clsx(
                    pointer,
                    location.pathname === '/settings/billing' && bold
                  )}
                  onClick={() => push('/settings/billing')}
                >
                  Billing
                </ListItem>
              </>
            )}
          </List>
        </Grid>

        <Grid
          item
          xs={12}
          lg={8}
          style={{
            marginRight: 16,
            minWidth: 800,
            backgroundColor: 'white',
            boxShadow: '1px 1px 4px #0578692E',
          }}
        >
          <Switch>
            <Route
              path={`${match.path}/profile`}
              exact
              component={ProfilePage}
            />
            <Route
              path={`${match.path}/team`}
              exact
              component={TeamSettingsPage}
            />
            <Route
              path={`${match.path}/companyDetails`}
              exact
              component={CompanyDetailsSettingsPage}
            />
            <Route
              path={`${match.path}/general`}
              exact
              component={GeneralSettingsPage}
            />
            <Route
              path={`${match.path}/taxes`}
              exact
              component={TaxesSettingsPage}
            />
            <Route
              path={`${match.path}/external`}
              exact
              component={ExternalSettingsPage}
            />
            <Route
              path={`${match.path}/billing`}
              exact
              component={BillingSettingsPage}
            />
          </Switch>
        </Grid>
      </Grid>
    </>
  );
};

export default SettingsMenuPage;
