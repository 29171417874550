import { PrintingResources, registerFonts } from '@optioffer/printing';

import optiOfferLogo from './OO_logo_wo_safespace_Primary - Color.png';
import materialIcons from './fonts/MaterialIcons-Regular.ttf';
import bold from './fonts/roboto/Roboto-Bold.ttf';
import normal from './fonts/roboto/Roboto-Regular.ttf';
import userPlaceholderLogo from './undraw_female_avatar_w3jk.png';
import placeholderLogo from './undraw_logo_design_bjnx.png';

registerFonts({
  roboto: {
    bold,
    normal,
  },
  materialIcons,
});

export const printingResources: PrintingResources = {
  optiOfferLogo,
  placeholderLogo,
  userPlaceholderLogo,
};
