import {
  ConfirmationModal,
  DVKObject,
  InputModal,
  useModal,
} from '@dvkiin/material-commons';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { ApolloModalErrors, ApolloSnackbars } from '../../components/Feedback';
import {
  booleanResultToError,
  getInvalidFields,
  goBackIfCurrentPageEmpty,
  useEnhancedMutation,
  useEnhancedQuery,
  usePaginationFromUrl,
} from '../../lib';
import AttributeCollectionsTable from './Table';
import { AttributeCollection, AttributeCollectionPage } from './domain';
import { attributeCollectionListFields } from './fields';
import {
  CLONE_ATTRIBUTE_COLLECTION,
  CREATE_ATTRIBUTE_COLLECTION,
  DELETE_ATTRIBUTE_COLLECTION,
  FIND_ALL_ATTRIBUTE_COLLECTIONS,
} from './graphql';
import useStyles from './styles';

type AttributeCollectionListPageProps = {};

const AttributeCollectionListPage: FC<AttributeCollectionListPageProps> = () => {
  const { addButton } = useStyles();
  const { push } = useHistory();

  const {
    isOpen: isCreateModalOpen,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal();
  const {
    isOpen: isDeleteModalOpen,
    data: deleteModalData,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal<AttributeCollection>();
  const {
    pagination,
    sort,
    graphQLPagination,
    handlePaginationUpdate,
  } = usePaginationFromUrl();
  const [
    createAttributeCollectionKey,
    setCreateAttributeCollectionKey,
  ] = useState(0);
  const handleNavigateToAttributeCollection = useCallback(
    ({ id }: AttributeCollection) => {
      push(`/attribute-collection/${id}`);
    },
    [push]
  );

  //region queries
  const {
    data: { findAllAttributeCollections } = {
      findAllAttributeCollections: undefined,
    },
    error: getError,
  } = useEnhancedQuery<{
    findAllAttributeCollections: AttributeCollectionPage;
  }>(FIND_ALL_ATTRIBUTE_COLLECTIONS, {
    variables: { pagination: graphQLPagination },
  });
  //endregion

  //region mutations
  const [
    createAttributeCollection,
    { data: createResult, error: createError },
  ] = useEnhancedMutation(CREATE_ATTRIBUTE_COLLECTION, {
    refetchQueries: ['findAllAttributeCollections'],
  });
  const [
    deleteAttributeCollection,
    { data: deleteResult, error: deleteError },
  ] = useEnhancedMutation(DELETE_ATTRIBUTE_COLLECTION, {
    refetchQueries: ['findAllAttributeCollections'],
  });
  const [cloneAttributeCollection, { error: cloneError }] = useEnhancedMutation(
    CLONE_ATTRIBUTE_COLLECTION,
    {
      refetchQueries: ['findAllAttributeCollections'],
    }
  );
  //endregion

  //region errors
  const errors = useMemo(
    () => [
      {
        error: createError,
        message:
          'There was an error while creating the attribute collection. Please check your input and try again.',
      },
      {
        error: cloneError,
        message:
          'There was an error while cloning the attribute collection. Please try again.',
      },
      {
        error: booleanResultToError(deleteResult, 'deleteAttributeCollection'),
        message:
          'There was an error while deleting the attribute collection. Please try again.',
      },
      {
        error: deleteError,
        message:
          'There was an error while deleting the attribute collection. Please try again.',
      },
    ],
    [createError, cloneError, deleteResult, deleteError]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      {
        result: deleteResult,
        message: 'Attribute collection deleted.',
        booleanKey: 'deleteAttributeCollection',
      },
      createResult && {
        result: createResult,
        message: `Attribute collection '${createResult.createAttributeCollection.name}' created.`,
        action: () => (
          <Button
            color="inherit"
            size="small"
            onClick={() =>
              handleNavigateToAttributeCollection(
                createResult.createAttributeCollection
              )
            }
          >
            View and edit
          </Button>
        ),
      },
    ],
    [deleteResult, createResult, handleNavigateToAttributeCollection]
  );
  //endregion

  useEffect(
    () =>
      goBackIfCurrentPageEmpty(
        findAllAttributeCollections,
        pagination,
        sort,
        handlePaginationUpdate
      ),
    [findAllAttributeCollections, pagination, sort, handlePaginationUpdate]
  );

  async function handleAttributeCollectionDelete() {
    if (!deleteModalData) return;
    await deleteAttributeCollection({ variables: { id: deleteModalData.id } });
    closeDeleteModal();
  }

  async function handleAttributeCollectionSave(attributeCollection: DVKObject) {
    const attributeCollectionToSave = {
      ...attributeCollectionListFields.reduce(
        (acc, { name }) => ({ ...acc, [name]: attributeCollection[name] }),
        {}
      ),
      attributes: [],
    };
    const { error } = (await createAttributeCollection({
      variables: { attributeCollection: attributeCollectionToSave },
    })) as any;
    if (!error) {
      setCreateAttributeCollectionKey((key) => key + 1);
    }
  }

  async function handleAttributeCollectionClone(
    attributeCollection: AttributeCollection
  ) {
    const {
      data: { cloneAttributeCollection: clonedAttributeCollection },
    } = await cloneAttributeCollection({
      variables: {
        id: attributeCollection.id,
      },
    });
    handleNavigateToAttributeCollection(clonedAttributeCollection);
  }

  function renderTable() {
    if (getError) return null;
    if (!findAllAttributeCollections) return 'Loading...';
    if (!findAllAttributeCollections.attributeCollections.length)
      return 'You have no attribute collections yet. Add some ;)';

    return (
      <AttributeCollectionsTable
        attributeCollections={findAllAttributeCollections.attributeCollections}
        total={findAllAttributeCollections.total}
        pagination={pagination}
        sort={sort}
        onPaginationUpdate={handlePaginationUpdate}
        onEdit={handleNavigateToAttributeCollection}
        onDelete={openDeleteModal}
        onClone={handleAttributeCollectionClone}
      />
    );
  }

  return (
    <>
      <h3>
        AttributeCollections
        <Button
          className={addButton}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openCreateModal}
        >
          New attribute collection
        </Button>
      </h3>

      {renderTable()}

      <InputModal
        title="Add new Attribute collection"
        formKey={`${createAttributeCollectionKey}`}
        open={isCreateModalOpen}
        fields={attributeCollectionListFields}
        invalidFields={getInvalidFields(createError) || undefined}
        onClose={() => {
          closeCreateModal();
        }}
        onCreate={handleAttributeCollectionSave}
      />

      <ConfirmationModal
        title={`Delete attribute collection '${
          deleteModalData && deleteModalData.name
        }'`}
        message={`Are you sure you want to delete attribute collection '${
          deleteModalData && deleteModalData.name
        }'?`}
        open={isDeleteModalOpen}
        onCancel={closeDeleteModal}
        onAccept={handleAttributeCollectionDelete}
      />

      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default AttributeCollectionListPage;
