import { useEnhancedQuery, usePagination } from '@lib';
import {
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useThrottledMemo } from '@optioffer/core';
import { Admin_SearchProjectsDocument, Project } from '@optioffer/graphql';

import ProjectTable from './ProjectTable';

const useLocalStyles = makeStyles((theme) => ({
  searchFeedbackText: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    fontWeight: 'bold',
    opacity: '50%',
  },
}));

const defaultPagination = { page: 0, rowsPerPage: 50 };

const ProjectListPage: FC<RouteComponentProps> = ({ history }) => {
  const localClasses = useLocalStyles();

  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useThrottledMemo(
    () => searchString,
    [searchString],
    1000
  );

  const {
    pagination,
    graphQLPagination,
    handlePaginationUpdate,
    sort,
  } = usePagination(defaultPagination, 'name');

  const { data: projects, loading: searchingProjects } = useEnhancedQuery(
    Admin_SearchProjectsDocument,
    {
      variables: {
        searchString: debouncedSearchString,
        pagination: graphQLPagination,
      },
      error: {
        type: 'SNACKBAR',
        message: 'An error occurred while loading the projects.',
      },
    }
  );

  function handleNavigateToProject({ id }: Project) {
    history.push(`/admin/project/${id}`);
  }

  const handlePageChange = (page: number) => {
    handlePaginationUpdate(
      { page, rowsPerPage: defaultPagination.rowsPerPage },
      undefined
    );
  };

  const searchResults = projects?.projectAdmin?.findAll?.projects ?? [];
  return (
    <>
      <Box display="flex" width="100%">
        <Box flex={3} maxWidth="100%">
          <Box display="flex" width="100%" marginY={2}>
            <Box marginLeft={1} marginRight={1} flex={1} maxWidth={256}>
              <TextField
                fullWidth
                placeholder="Search by name"
                variant="outlined"
                margin="none"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchString && (
                    <InputAdornment
                      position="end"
                      onClick={() => setSearchString('')}
                    >
                      <CloseIcon cursor={'pointer'} />
                    </InputAdornment>
                  ),
                }}
                value={searchString}
                onChange={({ target: { value } }) => {
                  setSearchString(value);
                  handlePaginationUpdate(
                    { ...defaultPagination, page: 0 },
                    sort
                  );
                }}
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                // productModal.open(undefined);
              }}
            >
              <Box display="flex" marginRight={1}>
                <AddIcon />
              </Box>
              New Project
            </Button>
          </Box>
          {searchString !== '' && (
            <Box width="100%">
              <Box className={localClasses.searchFeedbackText}>
                {`${projects?.projectAdmin?.findAll?.total} projects found for "${searchString}"`}
              </Box>
              <Box marginX={-2}>
                <Divider />
              </Box>
            </Box>
          )}
          {searchingProjects && !searchResults.length ? (
            <Box
              width={'100%'}
              height="50vh"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            !searchResults.length && (
              <Box width="100%">
                <Box className={localClasses.searchFeedbackText}>
                  No projects found.
                </Box>
              </Box>
            )
          )}

          <ProjectTable
            data={searchResults as Project[]}
            pageCount={Math.round(
              (projects?.projectAdmin?.findAll?.total || 0) /
                defaultPagination.rowsPerPage
            )}
            page={pagination.page + 1}
            onPaginationUpdate={handlePageChange}
            onEdit={handleNavigateToProject}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProjectListPage;
