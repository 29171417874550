import { ApolloError } from '@apollo/client';
import { FormikErrors } from 'formik';

type SimpleGraphQLFormikFieldError<FormValues> = keyof FormValues;
type NamedGraphQLFormikFieldError<FormValues> = {
  field: keyof FormValues;
  code: string;
};

type GraphQLFormikFieldError<FormValues> =
  | SimpleGraphQLFormikFieldError<FormValues>
  | NamedGraphQLFormikFieldError<FormValues>;

function isSimpleFormikError<FormValues>(
  error: GraphQLFormikFieldError<FormValues>
): error is SimpleGraphQLFormikFieldError<FormValues> {
  return typeof error === 'string';
}

export function getInvalidFormikFields<FormValues>(
  error?: ApolloError
): FormikErrors<FormValues> | undefined {
  if (!error) {
    return undefined;
  }

  if (error.graphQLErrors) {
    const errors = error.graphQLErrors
      .filter((err) => err.extensions?.code === 'BAD_USER_INPUT')
      .reduce((acc: FormikErrors<FormValues>, it) => {
        const invalidArgs = (it.extensions!.exception as any).invalidArgs;
        if (invalidArgs) {
          invalidArgs.forEach(
            (fieldError: GraphQLFormikFieldError<FormValues>) => {
              if (isSimpleFormikError(fieldError)) {
                acc[fieldError] =
                  (it.extensions!.message as any) || 'Invalid value';
              } else {
                acc[fieldError.field] =
                  fieldError.code || ('Invalid value' as any); // TODO fix types
              }
            }
          );
        }
        return acc;
      }, {} as FormikErrors<FormValues>);

    return Object.keys(errors).length ? errors : undefined;
  }

  return undefined;
}

export function mapInvalidFormikFieldNames<FormValues>(
  errors: FormikErrors<FormValues> | undefined,
  namesMap: Map<string, string> | undefined
): FormikErrors<FormValues> | undefined {
  if (!errors) return undefined;
  if (!namesMap) return errors;

  try {
    return (
      Object.entries(errors)
        // any because [string, unknown] fives a weird error
        .map<any[]>(([fieldName, error]) => [
          namesMap.get(fieldName) ?? fieldName,
          error,
        ])
        .reduce(
          (acc, it) => ({ ...acc, [it[0]]: it[1] }),
          {} as FormikErrors<FormValues>
        )
    );
  } catch {
    return undefined;
  }
}

export function getFormikErrors<FormValues>(
  error: ApolloError,
  namesMap?: Map<string, string>
): FormikErrors<FormValues> {
  return (
    mapInvalidFormikFieldNames(getInvalidFormikFields(error), namesMap) ?? {}
  );
}
