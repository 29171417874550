import { OptiDatePicker, OptiTextField } from '@components';
import { NOOP_graphqlErrorManagement } from '@lib';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  BusinessRounded,
  EmailRounded,
  PhoneRounded,
} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SettingsIcon from '@material-ui/icons/Settings';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import clsx from 'clsx';
import moment from 'moment';
import * as React from 'react';
import { FC, Fragment, useContext } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import {
  formatDate,
  formatDiscount,
  formatPrice,
  ooBrand,
  sum,
} from '@optioffer/core';
import {
  CalculationType,
  CumulatedTaxCalculated,
  OfferItem,
  OfferItemInOfferModuleFragment,
} from '@optioffer/graphql';

import ExpandableBox from '@components/ExpandableBox';

import PieChartActiveShape from '../../../../components/Charts/PieChartActiveShape';
import { getProductSearchResult } from '../../domain';
import useStyles from '../../styles';
import OfferItemListItem from '../OfferItemListItem';
import ReviewModalContext from './context';

type ReviewModalDesktopProps = {};

const ReviewModalDesktop: FC<ReviewModalDesktopProps> = () => {
  const classes = useStyles();

  const {
    quote,

    companyIntroModal,
    offerItemModal,
    offerItemAccessoryModal,

    quoteTemplateModal,
    quoteLanguageModal,
    quoteCurrencyModal,
    pricingLevelModal,
    quoteTaxesModal,
    previewModal,
    quoteDetailsMenu,
    quoteSummaryMenu,

    formik,
    pricing,

    handleEditTermsAndConditions,
    handleMoveOfferItem,
    handleMoveAccessory,
    handleUpdateOfferItem,
    handleUpdateAccessory,
    setCurrentAccessoryParent,
    setStep,

    handleEditAddon,
    handleEditDiscount,
  } = useContext(ReviewModalContext);

  function renderQuoteDetails() {
    return (
      <Paper className={classes.sectionPaper}>
        <Box>
          <Box display="flex" alignItems="center" marginTop={1.5}>
            <Box flex={1} className={classes.sectionHeading}>
              Quote Details
            </Box>

            <Box marginRight={-1.5}>
              <IconButton
                aria-controls="quote-details-menu"
                aria-haspopup="true"
                onClick={quoteDetailsMenu.open}
              >
                <SettingsIcon />
              </IconButton>
            </Box>
            <Menu
              id="quote-details-menu"
              anchorEl={quoteDetailsMenu.anchorElement}
              keepMounted
              open={quoteDetailsMenu.isOpen}
              onClose={quoteDetailsMenu.close}
            >
              <MenuItem
                onClick={() => {
                  quoteDetailsMenu.close();
                  quoteTemplateModal.open({
                    quoteTemplate: formik.values.quoteTemplate,
                  });
                }}
              >
                Change Template
              </MenuItem>
              <MenuItem
                onClick={() => {
                  quoteDetailsMenu.close();
                  quoteLanguageModal.open({
                    quoteLanguage: formik.values.quoteLanguage,
                  });
                }}
              >
                Change Language
              </MenuItem>
            </Menu>
          </Box>

          <OptiTextField
            label="Creator"
            disabled
            value={quote.createdBy}
            aria-readonly="true"
          />
          <Box display="flex" marginTop={1.5}>
            <Box flex={1}>
              <OptiTextField
                label="Created at"
                disabled
                value={formatDate(quote.createdAt ?? undefined)}
                aria-readonly="true"
              />
            </Box>
            <Box width={20} />
            <Box flex={1}>
              <OptiDatePicker
                label="Expires at"
                value={moment(formik.values.expireAt)}
                onChange={async (expireAt) => {
                  await formik.setFieldValue(
                    'expireAt',
                    expireAt?.valueOf() ?? Date.now()
                  );
                  await formik.submitForm();
                }}
              />
            </Box>
          </Box>
          <Box width="100%" marginTop={3.5}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={previewModal.open}
            >
              Preview Quote
            </Button>
          </Box>
        </Box>
      </Paper>
    );
  }

  function handleEditCompanyIntro() {
    companyIntroModal.open({
      companyIntro: formik.values.companyIntro,
    });
  }

  const productAddons = quote.offerItems
    .filter((oi): oi is OfferItem => !!oi)
    .flatMap((oi) => [
      ...oi.addons,
      ...(oi.children?.flatMap((acc) => acc.addons) ?? []),
    ]);

  const allAddonsPricing = [...productAddons, ...quote.addons]
    .map((addon) => addon.pricing)
    .filter(Boolean);

  function renderCustomerDetails() {
    return (
      <Paper className={classes.sectionPaper}>
        <Box padding={3}>
          <Box className={classes.sectionHeading} marginBottom={2}>
            Customer Details
          </Box>
          <Grid container className={classes.container}>
            <Grid item xl={3}>
              <Grid className={classes.customerLabelContainer}>
                <Avatar className={classes.customerAvatar} />
                <div style={{ marginLeft: 24 }}>{quote?.client?.name} </div>
              </Grid>
            </Grid>

            <Grid item className={classes.customerContactContainer}>
              {quote?.client?.email && (
                <Grid item className={classes.customerLabelContainer}>
                  <EmailRounded className={classes.customerIcon} />
                  <div style={{ margin: 'auto 0' }}>
                    {quote?.client?.email}{' '}
                  </div>
                </Grid>
              )}
              {quote?.client?.phoneNumber && (
                <Grid item className={classes.customerLabelContainer}>
                  <PhoneRounded className={classes.customerIcon} />
                  <div style={{ margin: 'auto 0' }}>
                    {quote?.client?.phoneNumber}{' '}
                  </div>
                </Grid>
              )}
              {quote?.client?.companyName && (
                <Grid item className={classes.customerLabelContainer}>
                  <BusinessRounded className={classes.customerIcon} />
                  <div style={{ margin: 'auto 0' }}>
                    {quote?.client?.companyName}{' '}
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }

  function renderIntroduction() {
    return (
      <Paper className={classes.sectionPaper}>
        <Box padding={3}>
          <Box display="flex" alignItems="center" width="100%" marginBottom={2}>
            <Typography className={classes.sectionHeading}>
              Introduction
            </Typography>
            <Box flex={1} />
            <Box marginTop={-2} marginRight={-2}>
              <IconButton onClick={handleEditCompanyIntro}>
                <EditIcon />
              </IconButton>
            </Box>
          </Box>
          <ExpandableBox
            flex={1}
            whiteSpace="pre-line"
            defaultState={!formik.values.companyIntro}
          >
            {formik.values.companyIntro || <pre>(no company intro)</pre>}
          </ExpandableBox>
        </Box>
      </Paper>
    );
  }

  function renderTermsAndConditions() {
    return (
      <Paper className={classes.sectionPaper}>
        <Box padding={3}>
          <Box display="flex" alignItems="center" width="100%" marginBottom={2}>
            <Typography className={classes.sectionHeading}>
              Terms & Conditions
            </Typography>
            <Box flex={1} />
            <Box marginTop={-2} marginRight={-2}>
              <IconButton onClick={handleEditTermsAndConditions}>
                <EditIcon />
              </IconButton>
            </Box>
          </Box>
          <ExpandableBox
            flex={1}
            whiteSpace="pre-line"
            defaultState={!formik.values.termsAndConditions}
          >
            {formik.values.termsAndConditions || (
              <pre>(no Terms & Conditions)</pre>
            )}
          </ExpandableBox>
        </Box>
      </Paper>
    );
  }

  function renderQuoteSummary() {
    return (
      <Paper className={classes.sectionPaper}>
        <Box padding={3}>
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Box flex={1} className={classes.sectionHeading}>
              Quote Summary
            </Box>

            <Box marginRight={-1.5}>
              <IconButton
                aria-controls="quote-summary-menu"
                aria-haspopup="true"
                onClick={quoteSummaryMenu.open}
              >
                <SettingsIcon />
              </IconButton>
            </Box>
            <Menu
              id="quote-summary-menu"
              anchorEl={quoteSummaryMenu.anchorElement}
              keepMounted
              open={quoteSummaryMenu.isOpen}
              onClose={quoteSummaryMenu.close}
            >
              <MenuItem
                onClick={() => {
                  quoteCurrencyModal.open({
                    quoteCurrency: formik.values.quoteCurrency,
                  });
                  quoteSummaryMenu.close();
                }}
              >
                Change Currency
              </MenuItem>
              <MenuItem
                onClick={() => {
                  pricingLevelModal.open({
                    displayDiscounts: formik.values.displayDiscounts,
                  });
                  quoteSummaryMenu.close();
                }}
              >
                Set Pricing Level
              </MenuItem>
              <MenuItem
                onClick={() => {
                  quoteTaxesModal.open({
                    taxes: formik.values.taxes,
                  });
                  quoteSummaryMenu.close();
                }}
              >
                Change TAX
              </MenuItem>
            </Menu>
          </Box>

          <Box marginX={2}>
            {formik.values.offerItems.map((item, index, self) => (
              <OfferItemListItem
                key={item?.id ?? `offerItem_${index}`}
                numberOfChildren={item.children?.length}
                item={item as OfferItemInOfferModuleFragment}
                index={index}
                moveOfferItem={handleMoveOfferItem}
                onMoveDone={() => {
                  formik.submitForm().catch(NOOP_graphqlErrorManagement);
                }}
                onClick={async (it) => {
                  offerItemModal.open(getProductSearchResult(it as OfferItem));
                }}
                updateQuantity={(newQuantity) =>
                  handleUpdateOfferItem({
                    ...item,
                    quantity: newQuantity,
                  } as OfferItem)
                }
              >
                {(index < self.length - 1 || !!item.children?.length) && (
                  <Divider />
                )}
                {item.children?.map(
                  (accessory, accessoryIndex, accessorySelf) => (
                    <Box
                      key={accessory?.id ?? `accessory_${index}`}
                      paddingX={2}
                      marginLeft={2}
                    >
                      <OfferItemListItem
                        item={accessory as OfferItemInOfferModuleFragment}
                        index={accessoryIndex}
                        moveOfferItem={handleMoveAccessory.bind(null, item.id)}
                        onMoveDone={() => {
                          formik
                            .submitForm()
                            .catch(NOOP_graphqlErrorManagement);
                        }}
                        onClick={async (it) => {
                          setCurrentAccessoryParent(item);
                          offerItemAccessoryModal.open(
                            getProductSearchResult(it as OfferItem)
                          );
                        }}
                        parentId={item.id}
                        updateQuantity={async (newQuantity) =>
                          handleUpdateAccessory(
                            {
                              ...accessory,
                              quantity: newQuantity,
                            } as OfferItem,
                            item.id
                          )
                        }
                      >
                        {(accessoryIndex < accessorySelf.length - 1 ||
                          index < self.length - 1) && <Divider />}
                      </OfferItemListItem>
                    </Box>
                  )
                )}
              </OfferItemListItem>
            ))}
          </Box>

          {renderPricing()}
        </Box>
      </Paper>
    );
  }

  function renderTax(tax: CumulatedTaxCalculated) {
    const taxValues =
      tax.valueTotal +
      allAddonsPricing
        .flatMap((addonPricing) => [
          ...addonPricing.taxesBeforeDiscount,
          ...addonPricing.taxesAfterDiscount,
          ...addonPricing.taxesFinal,
        ])
        .filter((addonTax) => addonTax.tax.id === tax.tax.id)
        .reduce((acc, tax) => acc + tax.valueTotal, 0);

    return (
      quote.pricing && (
        <Box
          key={tax.tax.id}
          className={clsx(classes.simpleRowDesktop, classes.simpleRowWithLabel)}
          marginBottom={1}
        >
          <Box flex={4} marginLeft={3}>
            {`[TAX] ${tax.tax.name} (${
              tax.tax.calculationType === CalculationType.PERCENTAGE
                ? formatDiscount(tax.tax.value)
                : ''
            })`}
          </Box>
          <Box display={'flex'} flexDirection={'row'} flex={1} minWidth={0}>
            <Box className={classes.currencyBox}>
              {' '}
              {quote.pricing.offerCurrency.symbol}
            </Box>
            <Box flex={1} textAlign="right">
              {formatPrice(taxValues, quote.pricing.offerCurrency, true)}
            </Box>
          </Box>
        </Box>
      )
    );
  }

  function renderAddons() {
    return (
      <>
        {formik.values.addons.map((addon) => {
          return (
            <Fragment key={addon.id}>
              <Box
                className={clsx(
                  classes.actionableRowDesktop,
                  classes.simpleRowWithLabel
                )}
                marginBottom={1}
                onClick={() => handleEditAddon(addon)}
              >
                <Box flex={4} marginLeft={3}>
                  {addon.name}
                </Box>

                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  flex={1}
                  minWidth={0}
                >
                  <Box className={classes.currencyBox}>
                    {quote.pricing.offerCurrency.symbol}
                  </Box>
                  <Box flex={1} textAlign="right">
                    {formatPrice(
                      addon.price,
                      quote.pricing.offerCurrency,
                      true
                    )}
                  </Box>
                </Box>
              </Box>
              {!!addon.pricing?.discount?.valueTotal && (
                <Box
                  className={clsx(
                    classes.simpleRowDesktop,
                    classes.simpleRowWithLabel
                  )}
                  marginBottom={1}
                  onClick={() => handleEditAddon(addon)}
                >
                  <Box flex={4} marginLeft={3}>
                    Disc.{' '}
                    {addon.pricing.discount.discount.calculationType ===
                    CalculationType.PERCENTAGE
                      ? formatDiscount(addon.pricing.discount.discount.value)
                      : ''}{' '}
                    {addon.name}
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    flex={1}
                    minWidth={0}
                  >
                    <Box className={classes.currencyBox}>
                      {quote.pricing.offerCurrency.symbol}
                    </Box>
                    <Box flex={1} textAlign="right">
                      {formatPrice(
                        -1 * addon.pricing.discount.valueTotal,
                        quote.pricing.offerCurrency,
                        true
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Fragment>
          );
        })}
        <Box>
          <Button
            className={classes.flatInlineButton}
            onClick={() => handleEditAddon(undefined)}
          >
            <StarBorderIcon /> Add {quote.addons.length ? 'another ' : ''}extra
            service to quote?
          </Button>
        </Box>
      </>
    );
  }

  function renderPricing() {
    if (!pricing) return;

    const productAddonsTotal = productAddons
      .filter((addon) => !!addon.pricing)
      .map((addon) => addon.pricing.listPrice)
      .reduce(sum, 0);

    return (
      <Box display="flex" flexDirection="column">
        <Box marginY={1.5}>
          <Divider />
        </Box>

        <Box
          className={clsx(classes.simpleRowDesktop, classes.simpleRowWithLabel)}
          marginBottom={1}
          paddingX={0}
        >
          <Box flex={4} marginLeft={3}>
            List Price{productAddonsTotal ? ' (Without Extra Services)' : ''}
          </Box>

          <>
            <Box display={'flex'} flexDirection={'row'} flex={1} minWidth={0}>
              <Box className={classes.currencyBox}>
                {pricing.offerCurrency.symbol}
              </Box>
              <Box flex={1} textAlign="right">
                {formatPrice(pricing.listPrice, pricing.offerCurrency, true)}
              </Box>
            </Box>
          </>
        </Box>
        {!!productAddonsTotal && (
          <Box
            className={clsx(
              classes.simpleRowDesktop,
              classes.simpleRowWithLabel
            )}
            marginBottom={1}
          >
            <Box flex={4} marginLeft={3}>
              Product Extra Services Total
            </Box>
            <Box display={'flex'} flexDirection={'row'} flex={1} minWidth={0}>
              <Box className={classes.currencyBox}>
                {pricing.offerCurrency.symbol}
              </Box>
              <Box flex={1} textAlign="right">
                {formatPrice(productAddonsTotal, pricing.offerCurrency, true)}
              </Box>
            </Box>
          </Box>
        )}

        <Box marginY={1.5}>
          <Divider />
        </Box>

        {renderAddons()}

        <Box marginY={1.5}>
          <Divider />
        </Box>

        {pricing.taxesBeforeDiscount.map(renderTax)}

        {!!pricing.discount?.valueTotal && (
          <Box
            className={clsx(
              classes.simpleRowDesktop,
              classes.simpleRowWithLabel
            )}
            marginBottom={1}
          >
            <Box flex={4} marginLeft={3}>
              Cumulative Discount
            </Box>

            <Box display={'flex'} flexDirection={'row'} flex={1} minWidth={0}>
              <Box className={classes.currencyBox}>
                {pricing.offerCurrency.symbol}
              </Box>
              <Box flex={1} textAlign="right">
                {formatPrice(
                  -1 * pricing.discount.valueTotal,
                  pricing.offerCurrency,
                  true
                )}
              </Box>
            </Box>
          </Box>
        )}

        {!!pricing.discountFinal?.valueTotal && (
          <Box
            className={clsx(
              classes.actionableRowDesktop,
              classes.simpleRowWithLabel
            )}
            marginBottom={1}
            onClick={handleEditDiscount}
          >
            <Box flex={4} marginLeft={3}>
              Additional Discount{' '}
              {pricing.discountFinal.discount?.calculationType ===
              CalculationType.PERCENTAGE
                ? formatDiscount(pricing.discountFinal.discount.value)
                : ''}
            </Box>
            <Box display={'flex'} flexDirection={'row'} flex={1} minWidth={0}>
              <Box className={classes.currencyBox}>
                {pricing.offerCurrency.symbol}
              </Box>
              <Box flex={1} textAlign="right">
                {formatPrice(
                  -1 * pricing.discountFinal.valueTotal,
                  pricing.offerCurrency,
                  true
                )}
              </Box>
            </Box>
          </Box>
        )}

        {pricing.taxesAfterDiscount.map(renderTax)}
        {pricing.taxesFinal.map(renderTax)}

        <Box
          className={clsx(classes.simpleRowDesktop, classes.totalDivider)}
          marginBottom={1}
        >
          <Box flex={4}>
            <strong>GRAND TOTAL</strong>
          </Box>

          <Box display={'flex'} flexDirection={'row'} flex={1} minWidth={0}>
            <Box className={classes.currencyBox} marginLeft={0.66}>
              <strong>{pricing.offerCurrency.symbol}</strong>
            </Box>
            <Box flex={1} textAlign="right">
              <strong>
                {formatPrice(pricing.netPrice, pricing.offerCurrency, true)}
              </strong>
            </Box>
          </Box>
        </Box>

        {!pricing.discountFinal?.valueTotal && (
          <Box>
            <Button
              className={classes.flatInlineButton}
              onClick={handleEditDiscount}
            >
              <LocalOfferIcon /> Add Discount?
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  function renderProfitAnalysis() {
    const report = quote.report;
    const currency = report?.currency ?? quote.pricing?.offerCurrency;
    if (!report || !currency) return;

    const marginSectorLength =
      (360 * report.margin) / (report.cost + report.margin);
    return (
      <Paper className={classes.sectionPaper}>
        <Box padding={3}>
          <Box display="flex" alignItems="center" marginTop={4}>
            <Box className={classes.sectionHeading}>Profit Analysis</Box>
          </Box>
          <Box flexDirection={'row'} display={'flex'}>
            <Box width={'50%'} height={300} paddingLeft={9}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={200} height={200}>
                  <Pie
                    data={[
                      {
                        name: 'Extra Service Cost',
                        value: report.cost,
                        currency: report.currency,
                      },
                      {
                        name: 'Profit',
                        value: report.margin,
                        currency: report.currency,
                      },
                    ]}
                    dataKey="value"
                    cx="25%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    startAngle={marginSectorLength / 2}
                    endAngle={360 + marginSectorLength / 2}
                    activeIndex={1}
                    activeShape={PieChartActiveShape}
                  >
                    <Cell fill={ooBrand.colors.secondary['100']} />
                    <Cell
                      fill={
                        report.margin > 0
                          ? ooBrand.colors.primary.default
                          : ooBrand.colors.error
                      }
                    />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Box>

            <Box width={'50%'} marginRight={3}>
              <h3 style={{ textAlign: 'right' }}>
                GRAND TOTAL - TOTAL COST = MARGIN (PROFIT)
              </h3>
              <Box
                width={'20%'}
                display={'flex'}
                flexDirection={'column'}
                marginLeft={'auto'}
              >
                <Box flex={2} marginTop={2} marginBottom={1}>
                  Total cost
                </Box>

                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  marginBottom={3}
                  justifyContent={'space-between'}
                >
                  <Box>
                    <strong>{currency.symbol}</strong>
                  </Box>
                  <Box flex={1} textAlign="right">
                    <strong>{formatPrice(report.cost, currency, true)}</strong>
                  </Box>
                </Box>

                <Box flex={2} marginTop={2} marginBottom={1}>
                  Profit
                </Box>

                <Box display={'flex'} flexDirection={'row'}>
                  <Box>
                    <strong>{currency.symbol}</strong>
                  </Box>
                  <Box flex={1} textAlign="right">
                    <strong>
                      {formatPrice(report.margin, currency, true)}
                    </strong>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  }

  function renderContent() {
    return (
      <div className="ReviewStep">
        <Box display="flex" flexDirection="row" marginTop={2}>
          <Box display="flex" flexDirection={'column'} flex={3} marginRight={1}>
            {renderCustomerDetails()}
            {renderIntroduction()}
            {renderTermsAndConditions()}
            {renderQuoteSummary()}
            {renderProfitAnalysis()}

            <Box marginBottom={10} />
          </Box>

          <Box display="flex" flex={1} marginLeft={1} height={333}>
            {renderQuoteDetails()}
          </Box>
        </Box>
        <Box className={classes.floatingFooter}>
          <Box display="flex">
            <Box paddingRight={2}>
              <Button
                className="NavigationButton"
                variant={'outlined'}
                fullWidth
                onClick={() => setStep(2)}
              >
                Back
              </Button>
            </Box>
            <Box flex="1" />
            <Box display="flex" flexDirection="row-reverse">
              <Button
                className="NavigationButton"
                variant="contained"
                color="primary"
                fullWidth
                disabled={false}
                onClick={() => setStep(4)}
              >
                Confirm Quote
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <>
      {renderContent()}

      <Box height={80} />
    </>
  );
};

export default ReviewModalDesktop;
