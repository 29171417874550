import { ApolloError } from '@apollo/client';
import { deepGet } from '@dvkiin/material-commons';
import { GraphQLError } from 'graphql';

import { Nullable } from '@optioffer/core';

export type FieldErrors = {
  [key: string]: string | boolean;
};

export type GraphQLFieldError =
  | string // the name of the field
  | { field: string; code: string };

export function getInvalidFields(error?: ApolloError): FieldErrors | undefined {
  if (!error) {
    return undefined;
  }

  if (error.graphQLErrors) {
    const errors = error.graphQLErrors
      .filter(
        (err) => err.extensions && err.extensions.code === 'BAD_USER_INPUT'
      )
      .reduce((acc, it) => {
        const fieldErrors = it.extensions.invalidArgs as GraphQLFieldError[];
        fieldErrors.forEach((fieldError) => {
          if (typeof fieldError === 'string') {
            acc[fieldError] = true;
          } else {
            acc[fieldError.field] = fieldError.code;
          }
        });
        return acc;
      }, {} as FieldErrors);

    return Object.keys(errors).length ? errors : undefined;
  }

  return undefined;
}

export function hasInvalidField(field: string, error?: ApolloError): boolean {
  const invalidFields = getInvalidFields(error);
  return !!(invalidFields && invalidFields[field]);
}

function isApolloError(
  errors: ApolloError | readonly GraphQLError[]
): errors is ApolloError {
  return errors && !Array.isArray(errors);
}

export function getErrorMessages(
  errors: Nullable<ApolloError | readonly GraphQLError[]>
): string[] | null {
  if (!errors) {
    return null;
  }

  const graphQLErrors = isApolloError(errors) ? errors.graphQLErrors : errors;

  if (graphQLErrors) {
    const errors = graphQLErrors
      .map((err) => err.message)
      .filter((err) => !!err);

    return errors && errors.length ? errors : null;
  }

  if (isApolloError(errors) && errors.networkError) {
    return [errors.networkError.message];
  }

  return null;
}

export function booleanResultToError(
  result: any,
  booleanKey: string
): ApolloError | undefined {
  if (!result || deepGet(result, booleanKey, true)) return undefined;
  return createFakeApolloError();
}

export function createFakeApolloError(): ApolloError {
  return { timestamp: new Date().getTime() } as any;
}
