import { withStyles } from '@material-ui/core';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@material-ui/pickers';
import * as React from 'react';
import { FC } from 'react';

const DatePicker = withStyles((theme) => ({
  root: {
    marginTop: '20px',
    '& > label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0, -17px)',
      fontSize: theme.typography.pxToRem(13),
    },
    '& fieldset.MuiOutlinedInput-notchedOutline legend': {
      maxWidth: '0.01px',
    },
  },
}))(MuiDatePicker);

type OptiDatePickerProps = DatePickerProps & {};

const OptiDatePicker: FC<OptiDatePickerProps> = ({ ...rest }) => {
  return (
    <>
      <DatePicker
        margin="none"
        size="small"
        fullWidth
        variant="dialog"
        inputVariant="outlined"
        autoComplete="off"
        format={'ll'}
        InputLabelProps={{ shrink: true }}
        {...rest}
      />
    </>
  );
};

export default OptiDatePicker;
