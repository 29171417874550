import React, { FC } from 'react';

export const QuoteSentIllustration: FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="600.474"
    height="781.019"
    viewBox="0 0 600.474 781.019"
    className={className}
  >
    <g
      id="Group_1429"
      data-name="Group 1429"
      transform="translate(47.156 -42.305)"
    >
      <g
        id="Group_868"
        data-name="Group 868"
        transform="translate(-131.839 -42.452)"
        opacity="0.73"
      >
        <g
          id="Group_866"
          data-name="Group 866"
          transform="translate(84.683 84.757)"
        >
          <path
            id="Path_584"
            data-name="Path 584"
            d="M649.365,166.8C613.188,111.889,544.432,79.28,477.972,85.517S350.342,136.554,325.489,197.2c-12.7,30.975-16.729,65.731-35.721,93.449-19.472,28.419-51.909,45.371-83.74,59.528s-65.253,27.2-90.36,51.017-39.909,62-25.055,92.971c15.1,31.481,53.231,44.056,80.761,66.043s45.05,54.314,73.176,75.562c25.982,19.63,59.47,28.278,92.3,28.212s65.149-8.344,95.819-19.789c95.15-35.508,184.788-101.591,222.742-194.068C725.7,278.85,649.365,166.8,649.365,166.8Z"
            transform="translate(-84.683 -84.757)"
            fill="#92e3a9"
          />
        </g>
        <g
          id="Group_867"
          data-name="Group 867"
          transform="translate(84.683 84.757)"
          opacity="0.7"
        >
          <path
            id="Path_585"
            data-name="Path 585"
            d="M649.365,166.8C613.188,111.889,544.432,79.28,477.972,85.517S350.342,136.554,325.489,197.2c-12.7,30.975-16.729,65.731-35.721,93.449-19.472,28.419-51.909,45.371-83.74,59.528s-65.253,27.2-90.36,51.017-39.909,62-25.055,92.971c15.1,31.481,53.231,44.056,80.761,66.043s45.05,54.314,73.176,75.562c25.982,19.63,59.47,28.278,92.3,28.212s65.149-8.344,95.819-19.789c95.15-35.508,184.788-101.591,222.742-194.068C725.7,278.85,649.365,166.8,649.365,166.8Z"
            transform="translate(-84.683 -84.757)"
            fill="#fff"
          />
        </g>
      </g>
      <g
        id="Group_870"
        data-name="Group 870"
        transform="translate(-551.139 279.502)"
      >
        <path
          id="Path_590"
          data-name="Path 590"
          d="M603.047,517.371c-20.282-73.287-48.491-101.829-56.7-177.2-1.572-14.432-7.685-83.713,20.825-130.306,14.816-24.215,37.988-20.38,39.316-6.926,2.96,30.01-60.147,36.544-33.2-20.671,26.041-55.289,156.853-72.022,181.776-119.032"
          transform="translate(48.548 138.836) rotate(-11)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          strokeDasharray="8 9"
        />
        <g
          id="Group_869"
          data-name="Group 869"
          transform="translate(715.229 30.498)"
        >
          <path
            id="Path_586"
            data-name="Path 586"
            d="M634.067,71.8v36.74L734.6,31.047Z"
            transform="translate(-596.488 -29.768)"
            fill="#057869"
            stroke="#0a423b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_587"
            data-name="Path 587"
            d="M655.51,72.533,758.361,30.5,617.931,45.062Z"
            transform="translate(-617.931 -30.498)"
            fill="#057869"
            stroke="#0a423b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_588"
            data-name="Path 588"
            d="M639.26,80.137l48.423,35.827L730.017,30.5Z"
            transform="translate(-589.587 -30.498)"
            fill="#057869"
            stroke="#0a423b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_589"
            data-name="Path 589"
            d="M646.16,51.813,634.067,80.949l25.757-19.026Z"
            transform="translate(-596.488 -2.174)"
            fill="#057869"
            stroke="#0a423b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
);
