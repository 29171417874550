import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import logo from '@resources/OO_logo_wo_safespace_Secondary - Mark - Color.svg';

import useStyles from './styles';

type LoginWrapperProps = {
  title: string;
  backLink?: boolean;
  outsideContent?: ReactNode;
};

const LoginWrapper: FC<LoginWrapperProps> = ({
  children,
  outsideContent,
  title,
  backLink,
}) => {
  const { main, paper, avatar } = useStyles();

  return (
    <main className={main}>
      <Paper className={paper}>
        <img src={logo} className={avatar} alt="Optioffer logo" />

        <Typography component="h5" variant="h5" gutterBottom align="center">
          {title}
        </Typography>

        {children}

        {backLink && <Link to="/">Back to login page</Link>}
      </Paper>

      {outsideContent}
    </main>
  );
};

export default LoginWrapper;
