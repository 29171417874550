import { uuid } from '@dvkiin/material-commons';
import { ProgrammaticQueryMethod } from '@lib';

import {
  Attribute,
  CalculationType,
  OfferItem,
  OfferItemInOfferModuleFragment,
  Product,
  ProductInProductSectionModuleFragment,
  ProductSearchResultWithoutVersionsInProductSectionModuleFragment,
  ProductSection_GetProductQuery,
  ProductSection_GetProductQueryVariables,
  ProductSpecification,
  ProductVersion,
  ProductVersionInProductSectionModuleFragment,
  ProductVersionSearchResultInProductSectionModuleFragment,
} from '@optioffer/graphql';

export type ProductSearchResult = {
  version: ProductVersionSearchResultInProductSectionModuleFragment;
  product: ProductSearchResultWithoutVersionsInProductSectionModuleFragment;
};

export type ProductData = {
  offerItem: OfferItem;
};

export type ProductDataWithOfferItem = {
  // product: ProductInProductSectionModuleFragment;
  // version: ProductVersionInProductSectionModuleFragment;
  offerItem: OfferItem;
};

export const CUSTOM_PRODUCT_ID_SUFFIX = 'CUSTOM_PRODUCT-';
export function createCustomProductId() {
  return `${CUSTOM_PRODUCT_ID_SUFFIX}${uuid()}`;
}
export function isCustomProduct(product: Pick<Product, 'id'>): boolean {
  return product.id?.indexOf(CUSTOM_PRODUCT_ID_SUFFIX) === 0;
}

export const CUSTOM_PRODUCT_VERSION_ID_SUFFIX = 'CUSTOM_PRODUCT_VERSION-';
export function createCustomProductVersionId() {
  return `${CUSTOM_PRODUCT_VERSION_ID_SUFFIX}${uuid()}`;
}
export function isCustomProductVersion(
  productVersion: Pick<ProductVersion, 'id'>
): boolean {
  return productVersion.id?.indexOf(CUSTOM_PRODUCT_VERSION_ID_SUFFIX) === 0;
}

export const CUSTOM_SPECIFICATION_ID_SUFFIX = 'CUSTOM_SPECIFICATION-';
export function createCustomSpecificationId() {
  return `${CUSTOM_SPECIFICATION_ID_SUFFIX}${uuid()}`;
}
export function isCustomSpecification(
  specification: Pick<ProductSpecification, 'id'>
): boolean {
  return specification.id?.indexOf(CUSTOM_SPECIFICATION_ID_SUFFIX) === 0;
}

export const CUSTOM_OFFER_ITEM_ID_SUFFIX = 'CUSTOM_OFFER_ITEM-';
export function createCustomOfferItemId() {
  return `${CUSTOM_OFFER_ITEM_ID_SUFFIX}${uuid()}`;
}
export function isCustomOfferItem(offerItem: Pick<OfferItem, 'id'>): boolean {
  return offerItem.id?.indexOf(CUSTOM_OFFER_ITEM_ID_SUFFIX) === 0;
}

export type ProductSpecificationWithAttrId = Pick<
  ProductSpecification,
  'id' | 'name' | 'unit' | 'value'
> & {
  type: Attribute['type'];
  attributeId: Attribute['id'];
};

export function extractSpecifications(
  product: ProductInProductSectionModuleFragment,
  productVersion: ProductVersionInProductSectionModuleFragment
): ProductSpecificationWithAttrId[] {
  return (
    productVersion?.attributeValues.map((attrVal) => ({
      id: uuid(),
      attributeId: attrVal.attribute.id,
      name: attrVal.attribute.name,
      unit: attrVal.attribute.unit,
      value: attrVal.value,
      type: attrVal.attribute.type,
    })) ?? []
  );
}

export function mapToOfferItem(
  it: ProductSearchResult,
  fullProduct: ProductInProductSectionModuleFragment | undefined,
  fullVersion: ProductVersionInProductSectionModuleFragment | undefined,
  createId: () => string = uuid
): OfferItem {
  return {
    id: createId(),
    quantity: 1,
    addons: [],
    children: [],
    offerItemDiscount: {
      calculationType: CalculationType.PERCENTAGE,
      value: 0,
    },
    product: {
      id: uuid(),
      productId: it.product.id,
      productVersionId: it.version.id,
      name: it.product.name,
      code: it.version.code,
      description: fullProduct?.description ?? '',
      image: it.product.image,
      price: it.version.price,
      specifications:
        fullVersion?.attributeValues.map((attrVal) => ({
          id: uuid(),
          name: attrVal.attribute.name,
          unit: attrVal.attribute.unit,
          value: attrVal.value,
        })) ?? [],
    },
    pricing: undefined as any, // this will force price calculation
  } as any;
}

export async function convertToOfferItem(
  getProduct: GetProductFn,
  it: ProductSearchResult,
  createId: () => string = uuid
): Promise<OfferItem> {
  const fullProduct = isCustomProduct(it.product)
    ? undefined
    : (
        await getProduct({
          variables: {
            productId: it.product.id,
            productVersionId: it.version.id,
          },
        })
      )?.data?.getProduct;
  const fullVersion = fullProduct?.versions.find(
    (ver) => ver.id === it.version.id
  );

  return mapToOfferItem(it, fullProduct, fullVersion, createId);
}

export function getProductSearchResult(
  it: OfferItem
): ProductSearchResult | undefined {
  if (!it.product) return undefined;
  return {
    product: {
      id: it.product.productId ?? 'idk, fix this',
      name: it.product.name,
      image: it.product.image,
    },
    version: {
      id: it.product.productVersionId ?? 'idk, fix this',
      code: it.product.code,
      price: it.product.price,
    },
  };
}

export function convertOfferItemToProductData(
  it?: OfferItemInOfferModuleFragment | null
): ProductData | undefined {
  if (!it) return undefined;

  return {
    offerItem: it,
  } as any;
}

// TODO not sure if we should do it like this, seems junky
export type GetProductFn = ProgrammaticQueryMethod<
  ProductSection_GetProductQuery,
  ProductSection_GetProductQueryVariables
>;
