import { SecurityContext, useIsMobile } from '@lib';
import { Button, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import PanoramaIcon from '@material-ui/icons/Panorama';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useContext } from 'react';

import { formatPrice } from '@optioffer/core';

import useStyles from '@containers/ProductSection/styles';

import { ProductSearchResult } from './domain';

const useLocalStyles = makeStyles((theme) => ({
  productName: {
    fontSize: theme.typography.pxToRem(14),
    opacity: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productCode: {
    fontSize: theme.typography.pxToRem(12),
    opacity: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productPrice: {
    fontSize: theme.typography.pxToRem(16),
  },
  placeholderIcon: {
    '& > svg': {
      fontSize: '3rem',
      opacity: '30%',
    },
  },
}));

type AccessoryListItemProps = {
  item: ProductSearchResult;
  handleRemove: (item: ProductSearchResult) => Promise<any>;
  onClick: (item: ProductSearchResult) => Promise<any>;
};

const AccessoryListItem: FC<AccessoryListItemProps> = ({
  children,
  item,
  handleRemove,
  onClick,
}) => {
  const classes = useStyles();
  const localClasses = useLocalStyles();

  const { company } = useContext(SecurityContext);
  const isMobile = useIsMobile();

  return (
    <Box width="100%">
      <Box
        display="flex"
        alignItems={'center'}
        paddingY={1}
        paddingX={2}
        marginX={-2}
      >
        <Box
          minWidth={54}
          maxWidth={54}
          marginRight={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => onClick(item)}
        >
          {item.product?.image?.thumbnail ? (
            <img
              style={{ width: '100%' }}
              src={item.product?.image?.thumbnail}
              alt="product"
            />
          ) : (
            <Box className={localClasses.placeholderIcon}>
              <PanoramaIcon />
            </Box>
          )}
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          flex={3}
          minWidth={0}
          onClick={() => onClick(item)}
        >
          <Box className={localClasses.productName}>{item.product?.name}</Box>
          <Box className={localClasses.productCode}>{item.version?.code}</Box>
          {isMobile && (
            <Box className={localClasses.productPrice}>
              {formatPrice(item.version?.price, company.currency)}
            </Box>
          )}
        </Box>

        {!isMobile && (
          <Box display={'flex'} flexDirection={'row'} flex={1} minWidth={0}>
            <Box flex={1} paddingLeft={2}>
              {company.currency.symbol}
            </Box>
            <Box flex={1} textAlign="right">
              {formatPrice(item.version?.price, company.currency, true)}
            </Box>
          </Box>
        )}

        <Box marginLeft={1}>
          <Button
            className={clsx(
              classes.removeButton,
              isMobile && classes.iconButton
            )}
            variant="text"
            onClick={() => handleRemove(item)}
          >
            <CancelOutlinedIcon />
          </Button>
        </Box>
      </Box>

      {children}
    </Box>
  );
};

export default AccessoryListItem;
