import { FullScreenCircularProgress, OptiTextField } from '@components';
import { ErrorModal } from '@dvkiin/material-commons';
import { mapFormikToTextField } from '@lib';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareIcon from '@material-ui/icons/Share';
import React, { FC, useContext } from 'react';

import useStyles from '../../styles';
import ExportModal from '../ExportModal';
import QuoteSentModal from '../QuoteSentModal';
import SendStepContext from './context';

type SendStepPageMobileProps = {};

const SendStepPageMobile: FC<SendStepPageMobileProps> = () => {
  const classes = useStyles();
  const {
    quote,
    quoteSentModal,
    exportModal,
    renderingErrorModal,
    loadingQuoteFiles,
    renderedOfferPdfBlob,
    quotePdfFiles,

    sendFormik,
    quoteManagementFormik,
    handleSendOfferViaShare,
    handleDownloadQuote,
    downloadingQuoteForPrint,
    sendingOfferToClient,

    setStep,
  } = useContext(SendStepContext);

  function renderSendQuote() {
    return (
      <>
        <Box display="flex" alignItems="center" marginTop={1.5}>
          <Box flex={1} className={classes.sectionHeading}>
            Send Quote
          </Box>
        </Box>

        <Box width="100%" marginTop={3.5}>
          <OptiTextField
            label="From"
            disabled
            value={
              quote.createdByUser.emailPreferences?.smtpUser ??
              'quotes@optioffer.com'
            }
            aria-readonly="true"
          />
        </Box>

        <Box display="flex" marginTop={1.5}>
          <OptiTextField
            label="To"
            disabled
            value={quote.client?.email ?? '(not available)'}
            aria-readonly="true"
          />
        </Box>

        <Box display="flex" marginTop={1.5}>
          <OptiTextField
            label="Subject"
            required
            {...mapFormikToTextField(sendFormik, 'subject')}
          />
        </Box>

        <Box display="flex" marginTop={1.5}>
          <OptiTextField
            label="Custom Email Message"
            multiline
            rows={14}
            required
            {...mapFormikToTextField(sendFormik, 'message')}
          />
        </Box>

        <Box display="flex" marginTop={1.5}>
          <FormControlLabel
            control={
              <Checkbox
                {...sendFormik.getFieldProps({
                  name: `sendToMyself`,
                  type: 'checkbox',
                })}
              />
            }
            label={`Send quote to myself as well`}
          />
        </Box>
      </>
    );
  }

  function renderQuoteManagement() {
    return (
      <>
        <Box display="flex" alignItems="center" marginTop={1.5}>
          <Box flex={1} className={classes.sectionHeading}>
            Quote Management
          </Box>
        </Box>

        <Box width="100%" marginTop={3.5}>
          <OptiTextField
            label="Quote title"
            {...mapFormikToTextField(quoteManagementFormik, 'title')}
            onBlur={() => quoteManagementFormik.submitForm()}
          />
        </Box>

        <Box width="100%" marginTop={3.5}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => exportModal.open({ offer: quote })}
          >
            Export Quote
          </Button>
        </Box>

        <Box width="100%" marginTop={3.5}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!((navigator as any).canShare && quotePdfFiles)}
            onClick={handleSendOfferViaShare}
          >
            <Box display="flex" alignItems="center">
              <Box display="flex" marginRight={2}>
                <ShareIcon />
              </Box>
              Share Quote as PDF
            </Box>
          </Button>
        </Box>

        {!(navigator as any).canShare && (
          <Box width="100%" marginTop={3.5}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!renderedOfferPdfBlob}
              onClick={handleDownloadQuote}
            >
              <Box display="flex" alignItems="center">
                <Box display="flex" marginRight={2}>
                  <GetAppIcon />
                </Box>
                Download as PDF
              </Box>
            </Button>
          </Box>
        )}
      </>
    );
  }

  return (
    <div className="SendStep">
      {renderSendQuote()}
      {renderQuoteManagement()}

      <Box marginBottom={10} />

      <Box className={classes.floatingFooter}>
        <Box display="flex">
          <Box paddingX={2}>
            <Button fullWidth onClick={() => setStep(3)}>
              Back
            </Button>
          </Box>
          <Box flex="1">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                !quote.client?.email?.length ||
                !renderedOfferPdfBlob ||
                sendFormik.isSubmitting
              }
              onClick={() => sendFormik.submitForm()}
            >
              Send Quote
            </Button>
          </Box>
        </Box>
      </Box>

      <ExportModal control={exportModal} downloadQuote={handleDownloadQuote} />
      <QuoteSentModal control={quoteSentModal} />
      <ErrorModal
        error={renderingErrorModal.data}
        message={renderingErrorModal.data}
      />
      <FullScreenCircularProgress
        open={
          downloadingQuoteForPrint ||
          loadingQuoteFiles ||
          (sendFormik.isSubmitting && !sendingOfferToClient)
        }
      />
    </div>
  );
};

export default SendStepPageMobile;
