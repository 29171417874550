import { Box, Button } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

import TextField from '@components/form/TextField';

import { Company } from '../domain';
import useStyles from '../styles';

type SmartBillSettingsProps = {
  companyDetails: Company;
  onSmartBillUpdate: (
    smartBillApiKey: string | undefined,
    smartBillEmail: string | undefined
  ) => void;
};

const SmartBillSettings: FC<SmartBillSettingsProps> = ({
  companyDetails,
  onSmartBillUpdate,
}) => {
  const { button, buttonBox } = useStyles();

  const [smartBillApiKey, setSmartBillApiKey] = useState<string>();
  const [smartBillEmail, setSmartBillEmail] = useState<string>();

  //region memos

  useEffect(() => {
    if (companyDetails) {
      setSmartBillApiKey(companyDetails.smartBillApiKey);
      setSmartBillEmail(companyDetails.smartBillEmail);
    }
  }, [companyDetails]);

  return (
    <>
      <h4>SmartBill™</h4>
      <TextField
        label="API Key"
        value={smartBillApiKey}
        onChange={(e) => setSmartBillApiKey(e.target.value)}
        style={{ marginBottom: 8 }}
      />
      <TextField
        label="SmartBill email"
        value={smartBillEmail}
        onChange={(e) => setSmartBillEmail(e.target.value)}
      />
      <Box className={buttonBox}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          fullWidth
          onClick={async () => {
            await onSmartBillUpdate(smartBillApiKey, smartBillEmail);
          }}
        >
          Save Data
        </Button>
        <Button
          variant="contained"
          size="small"
          fullWidth
          className={button}
          onClick={async () => {
            await onSmartBillUpdate('', '');
          }}
        >
          Delete Data
        </Button>
      </Box>
    </>
  );
};

export default SmartBillSettings;
