import {
  ConfirmationModal,
  DVKObject,
  InputModal,
  useModal,
} from '@dvkiin/material-commons';
import {
  booleanResultToError,
  getInvalidFields,
  goBackIfCurrentPageEmpty,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
  usePaginationFromUrl,
} from '@lib';
import { IconButton, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import BackIcon from '@material-ui/icons/ArrowBack';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { ApolloModalErrors, ApolloSnackbars } from '@components/Feedback';

import { ProductBundle, ProductBundlePage } from '../domain';
import { productBundleDefaultFields } from '../fields';
import {
  CLONE_PRODUCT_BUNDLE,
  CREATE_PRODUCT_BUNDLE,
  DELETE_PRODUCT_BUNDLE,
  FIND_ALL_PRODUCT_BUNDLES,
} from '../graphql';
import useStyles from '../styles';
import ProductBundlesTable from './Table';

type ProductBundleListPageProps = {};

const ProductBundleListPage: FC<ProductBundleListPageProps> = () => {
  const { addButton, backButton, headerContainer } = useStyles();
  const { push } = useHistory();

  const {
    isOpen: isCreateModalOpen,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal();

  const {
    isOpen: isDeleteModalOpen,
    data: deleteModalData,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal<ProductBundle>();
  const {
    pagination,
    sort,
    graphQLPagination,
    handlePaginationUpdate,
  } = usePaginationFromUrl();
  const [createProductBundleKey, setCreateProductBundleKey] = useState(0);
  const handleNavigateToProductBundle = useCallback(
    ({ id }: ProductBundle) => {
      push(`/product/bundle/${id}`);
    },
    [push]
  );

  //region queries
  const {
    data: { findAllProductBundles } = { findAllProductBundles: undefined },
    error: getError,
  } = useEnhancedQuery<{ findAllProductBundles: ProductBundlePage }>(
    FIND_ALL_PRODUCT_BUNDLES,
    {
      variables: { pagination: graphQLPagination },
    }
  );
  //endregion

  //region mutations
  const [
    createProductBundle,
    { data: createResult, error: createError },
  ] = useEnhancedMutation(CREATE_PRODUCT_BUNDLE, {
    refetchQueries: ['findAllProductBundles'],
  });
  const [
    deleteProductBundle,
    { data: deleteResult, error: deleteError },
  ] = useEnhancedMutation(DELETE_PRODUCT_BUNDLE, {
    refetchQueries: ['findAllProductBundles'],
  });
  const [cloneProductBundle, { error: cloneError }] = useEnhancedMutation(
    CLONE_PRODUCT_BUNDLE,
    {
      refetchQueries: ['findAllProductBundles'],
    }
  );
  //endregion

  //region errors
  const errors = useMemo(
    () => [
      {
        error: createError,
        message:
          'There was an error while creating the product bundle. Please check your input and try again.',
      },
      {
        error: cloneError,
        message:
          'There was an error while cloning the product bundle. Please try again.',
      },
      {
        error: booleanResultToError(deleteResult, 'deleteProductBundle'),
        message:
          'There was an error while deleting the product bundle. Please try again.',
      },
      {
        error: deleteError,
        message:
          'There was an error while deleting the product bundle. Please try again.',
      },
    ],
    [createError, cloneError, deleteResult, deleteError]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      {
        result: deleteResult,
        message: 'Product bundle deleted.',
        booleanKey: 'deleteProductBundle',
      },
      {
        result: createResult,
        message: () =>
          `Product bundle '${createResult.createProductBundle.name}' created.`,
        action: () => (
          <Button
            color="inherit"
            size="small"
            onClick={() =>
              handleNavigateToProductBundle(createResult.createProductBundle)
            }
          >
            View and edit
          </Button>
        ),
      },
    ],
    [deleteResult, createResult, handleNavigateToProductBundle]
  );
  //endregion

  useEffect(
    () =>
      goBackIfCurrentPageEmpty(
        findAllProductBundles,
        pagination,
        sort,
        handlePaginationUpdate
      ),
    [findAllProductBundles, pagination, sort, handlePaginationUpdate]
  );

  async function handleProductBundleDelete() {
    if (!deleteModalData) return;
    await deleteProductBundle({ variables: { id: deleteModalData.id } });
    closeDeleteModal();
  }

  async function handleProductBundleSave(productBundle: DVKObject) {
    try {
      const productBundleToSave = {
        ...productBundleDefaultFields.reduce(
          (acc, { name }) => ({ ...acc, [name]: productBundle[name] }),
          {}
        ),
        image: productBundle.image,
      };
      const { error } = (await createProductBundle({
        variables: { productBundle: productBundleToSave },
      })) as any;
      if (!error) {
        setCreateProductBundleKey((key) => key + 1);
      }
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleProductBundleClone(productBundle: ProductBundle) {
    try {
      const {
        data: { cloneProductBundle: clonedProductBundle },
      } = await cloneProductBundle({
        variables: {
          id: productBundle.id,
        },
      });
      handleNavigateToProductBundle(clonedProductBundle);
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  function renderTable() {
    if (getError) return null;
    if (!findAllProductBundles) return 'Loading...';
    if (!findAllProductBundles.productBundles.length)
      return 'You have no product bundles yet. Add some ;)';

    return (
      <ProductBundlesTable
        productBundles={findAllProductBundles.productBundles}
        total={findAllProductBundles.total}
        pagination={pagination}
        sort={sort}
        onPaginationUpdate={handlePaginationUpdate}
        onEdit={handleNavigateToProductBundle}
        onDelete={openDeleteModal}
        onClone={handleProductBundleClone}
      />
    );
  }

  return (
    <>
      <Typography variant="h4" className={headerContainer}>
        <IconButton className={backButton} onClick={() => push('/product')}>
          <BackIcon />
        </IconButton>
        Product bundles
        <Button
          className={addButton}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openCreateModal}
        >
          New product bundle
        </Button>
      </Typography>

      {renderTable()}

      <InputModal
        title="Add new Product Bundle"
        formKey={`${createProductBundleKey}`}
        open={isCreateModalOpen}
        fields={[
          {
            name: 'image',
            label: 'Image of the product bundle',
            type: 'image',
            errorMessage: { mimetype: 'This image type is not supported.' },
          },
          ...productBundleDefaultFields,
        ]}
        invalidFields={getInvalidFields(createError) || undefined}
        onClose={closeCreateModal}
        onCreate={handleProductBundleSave}
      />

      <ConfirmationModal
        title={`Delete product bundle '${deleteModalData?.name}'`}
        message={`Are you sure you want to delete product bundle '${deleteModalData?.name}'?`}
        open={isDeleteModalOpen}
        onCancel={closeDeleteModal}
        onAccept={handleProductBundleDelete}
      />

      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default ProductBundleListPage;
