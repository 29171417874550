import React, { FC } from 'react';

import { HeavyDutyResult } from './domain';

type HeavyDutyResultsProps = {
  results: HeavyDutyResult[];
};

const HeavyDutyResults: FC<HeavyDutyResultsProps> = ({ results }) => {
  return (
    <>
      {results.map((result) => (
        <p key={result.id}>
          {result.status === 'OK' ? (
            <span role="img" aria-label="ok">
              ✔
            </span>
          ) : result.status === 'WARNING' ? (
            <span role="img" aria-label="warning">
              ⚠
            </span>
          ) : (
            <span role="img" aria-label="error">
              ❌
            </span>
          )}{' '}
          {result.message}
        </p>
      ))}
    </>
  );
};

export default HeavyDutyResults;
