import { uuid } from '@dvkiin/material-commons';
import { IMAGE_SIZE_FOR_PRODUCTS, resizeImage } from '@lib';
import { Box, Button, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

import { downloadLink, ooBrand } from '@optioffer/core';

import { AddProductImageIcon } from '@resources/icons';

const useLocalStyles = makeStyles(() => ({
  mediaOverlay: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: `${ooBrand.colors.primary['200']}7F`,
    color: '#fff',
    padding: `5px 10px`,
    opacity: 0,
    position: 'relative',
    '&:hover': {
      opacity: 1,
    },
  },
  mediaOverlayButton: {
    color: '#fff',
    minWidth: 0,
    padding: 0,
  },
  inputBox: {
    height: 150,
    width: '100%',
    position: 'relative',

    cursor: 'pointer',
    color: ooBrand.colors.primary['100'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    border: `2px dashed ${ooBrand.colors.primary['100']}`,
    borderRadius: 5,
  },
  inputBoxRealInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0,
    width: 'inherit',
  },
  inputBoxSmall: {
    width: 150,
  },
  inputBoxBig: {
    width: '100%',
  },
}));

type AbstractProductMedia = {
  id: string;
  thumbnail: string;
};

export type ProductMedia = ProductImageMedia | ProductVideoMedia;

export type ProductImageMedia = AbstractProductMedia & {
  fullImage: string;
  type: 'IMAGE';
  isMainPhoto: boolean;
  imageFileForUpload?: File;
};

export type ProductVideoMedia = AbstractProductMedia & {
  videoLink: string;
  type: 'VIDEO';
};

export function isImage(
  mediaItem: ProductMedia
): mediaItem is ProductImageMedia {
  return mediaItem.type === 'IMAGE';
}

export type MediaInputProps = {
  productCode: string;
  value: ProductMedia[];
  onChange?: (newMedia: ProductMedia[]) => void;
  multiple?: boolean;
};

const DEFAULT_VALUE: ProductMedia[] = [];

const MediaInput: FC<MediaInputProps> = ({
  productCode,
  value = DEFAULT_VALUE,
  onChange,
  multiple,
}) => {
  const localClasses = useLocalStyles();

  async function handleAddImages(files?: FileList) {
    if (!files) return;

    function blobToFile(theBlob: Blob, fileName: string): File {
      return new File([theBlob], fileName, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });
    }

    const newMedia: ProductImageMedia[] = (
      await Promise.all(
        Array.from(files).map((pic) =>
          resizeImage({
            file: pic,
            ...IMAGE_SIZE_FOR_PRODUCTS,
          }).then((resizedBlob) => ({
            blob: blobToFile(resizedBlob.blob, pic.name),
            dataUrl: resizedBlob.dataUrl,
          }))
        )
      )
    ).map((pic) => ({
      id: uuid(),
      type: 'IMAGE',
      isMainPhoto: false,
      imageFileForUpload: pic.blob,
      thumbnail: pic.dataUrl,
      fullImage: pic.dataUrl,
    }));

    onChange?.(multiple ? [...value, ...newMedia] : [...newMedia].slice(0, 1));
  }

  async function handleRemoveMedia(mediaItemId: ProductMedia['id']) {
    onChange?.(value.filter((media) => media.id !== mediaItemId));
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      flexWrap="wrap"
      gridGap={16}
    >
      {value.map((mediaItem, idx) => (
        <Box
          key={mediaItem.id}
          width={150}
          height={150}
          borderRadius={5}
          overflow="hidden"
          style={{
            background: `url("${mediaItem.thumbnail}") no-repeat center/contain, ${ooBrand.colors.gray['A100']}`,
          }}
        >
          <label
            htmlFor={`product-image-${idx}-${productCode}`}
            className={clsx(localClasses.mediaOverlay)}
          >
            <input
              id={`product-image-${idx}-${productCode}`}
              className={clsx(localClasses.inputBoxRealInput)}
              type="file"
              multiple
              accept="image/*"
              onChange={({ target: { files } }) =>
                handleAddImages(files ?? undefined)
              }
            />
            <Box
              width="100%"
              display="flex"
              flexDirection="row-reverse"
              gridGap={10}
            >
              <Button
                className={localClasses.mediaOverlayButton}
                variant="text"
                onClick={(e) => {
                  e.stopPropagation();
                  return handleRemoveMedia(mediaItem.id);
                }}
              >
                <CloseIcon />
              </Button>
              {isImage(mediaItem) && (
                <Button
                  className={localClasses.mediaOverlayButton}
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation();

                    return downloadLink(
                      mediaItem.fullImage,
                      `${productCode}-${idx + 1}.jpg`
                    );
                  }}
                >
                  <GetAppIcon />
                </Button>
              )}
            </Box>
            {isImage(mediaItem) && mediaItem.isMainPhoto && (
              <Box
                flex={1}
                display="flex"
                alignItems="end"
                justifyContent="center"
              >
                Main Photo
              </Box>
            )}
          </label>
        </Box>
      ))}
      {(multiple || !value.length) && (
        <Box
          className={clsx(
            value.length ? localClasses.inputBoxSmall : localClasses.inputBoxBig
          )}
        >
          <label
            htmlFor={`product-image-${productCode}`}
            className={clsx(localClasses.inputBox)}
          >
            <input
              id={`product-image-${productCode}`}
              className={clsx(localClasses.inputBoxRealInput)}
              type="file"
              multiple
              accept="image/*"
              onChange={({ target: { files } }) =>
                handleAddImages(files ?? undefined)
              }
            />
            <AddProductImageIcon />
            <div>
              Drop photo{multiple ? 's' : ''} here
              <br />
              or <strong>click to upload</strong>
            </div>
          </label>
        </Box>
      )}
    </Box>
  );
};

export default MediaInput;
