export var hu = {
    global: {
        offerId: 'Azonosító: {{id}}',
        vatId: 'Adószám: {{id}}',
        validity: 'Érvenyesség: {{validity}}',
    },
    product: {
        accessoryFor: "A '{{parent}}' termék kiegészítője",
        specifications: 'Technikai leírás',
        bundleProducts: 'Termékek',
        description: 'Leírás',
        quantity: 'Db.',
        total: 'Összeg',
        listPrice: 'Árjegyzéki ár',
        discount: 'Kedv. {{discount}}',
        addonDiscount: 'Kedv. {{addon}} {{discount}}',
        additionalDiscount: 'További kedv. {{discount}}',
        netPrice: 'Nettó ár',
        portrait: {
            code: 'Termék ID / SKU: {{code}}',
        },
    },
    addons: {
        title: 'Szolgáltatás',
        total: 'Összes szolgáltatás',
        listPrice: 'Árjegyzéki ár',
        netPrice: 'Nettó ár',
    },
    lastPage: {
        listPrice: 'Árjegyzéki ár (szolgáltatások nélkül)',
        productAddonsTotal: 'Termékhez tartozó szolgáltatások összege',
        addonsTotal: 'Összes szolgáltatás',
        totalListPrice: 'Árjegyzéki ár összege',
        cumulativeDiscount: 'Összesített Kedvezmény',
        additionalDiscount: 'További kedvezmény ({{discount}})',
        grandTotal: 'VÉGÖSSZEG:',
    },
};
