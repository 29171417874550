export var gr = {
    global: {
        offerId: 'ID Προσφοράς: {{id}}',
        vatId: 'Κωδικός Φ.Π.Α.: {{id}}',
        validity: 'Εγκυρότητα: {{validity}}',
    },
    product: {
        accessoryFor: "Αξεσουάρ για '{{parent}}'",
        specifications: 'Τεχνικές προδιαγραφές',
        bundleProducts: 'Προϊόντα που περιλαμβάνονται στο πακέτο',
        description: 'Περιγραφή',
        quantity: 'ΠΟΣ.',
        total: 'ΣΥΝΟΛΟ',
        listPrice: 'Τιμοκατάλογος',
        discount: 'ΕΚΠ. {{discount}}',
        addonDiscount: 'ΕΚΠ. {{addon}} {{discount}}',
        additionalDiscount: 'Πρόσθετη ΕΚΠ. {{discount}}',
        netPrice: 'Καθαρή τιμή',
        portrait: {
            code: 'Κωδικός προϊόντος: {{code}}',
        },
    },
    addons: {
        title: 'Πρόσθετα',
        total: 'Σύνολο πρόσθετων',
        listPrice: 'Τιμοκατάλογος',
        netPrice: 'Καθαρή τιμή',
    },
    lastPage: {
        listPrice: 'Τιμή καταλόγου (χωρίς πρόσθετα)',
        productAddonsTotal: 'Σύνολο πρόσθετων προϊόντων',
        addonsTotal: 'Σύνολο πρόσθετων',
        totalListPrice: 'Συνολική τιμή λίστας',
        cumulativeDiscount: 'Σωρευτική έκπτωση',
        additionalDiscount: 'Πρόσθετη έκπτωση ({{discount}})',
        grandTotal: 'Σύνολο:',
    },
};
