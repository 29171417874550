import {
  NumberFormatInput,
  OptiAccordion,
  OptiAccordionDetails,
  OptiAccordionSummary,
  OptiInlineTextField,
} from '@components';
import { mapFormikToTextField, SecurityContext, useMenu } from '@lib';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PanoramaIcon from '@material-ui/icons/Panorama';
import SearchIcon from '@material-ui/icons/Search';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import clsx from 'clsx';
import * as React from 'react';
import { FC, Fragment, useContext } from 'react';

import { formatDiscount, formatPrice, ooBrand } from '@optioffer/core';
import {
  AddonPriceInOfferModuleFragment,
  CalculationType,
  OfferItem,
  TaxCalculated,
} from '@optioffer/graphql';

import { OfferContext } from '@containers/OfferContext';

import ProductListItem from '../ProductListItem';
import {
  convertToOfferItem,
  getProductSearchResult,
  ProductSearchResult,
} from '../domain';
import useStyles from '../styles';
import OfferItemModalContext from './context';

const useLocalStyles = makeStyles((theme) => ({
  productImage: {
    height: '125px',
  },
  placeholderIcon: {
    '& > svg': {
      fontSize: '125px',
      opacity: '30%',
    },
  },
  productCode: {
    fontSize: theme.typography.pxToRem(12),
    opacity: '50%',
  },
  productPrice: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    opacity: '50%',
  },
}));

type OfferItemModalMobileProps = {};

const OfferItemModalMobile: FC<OfferItemModalMobileProps> = () => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const offer = useContext(OfferContext);
  const { company } = useContext(SecurityContext);

  const moreOptionsMenu = useMenu();

  const {
    control,
    getProduct,
    openEditProduct,
    isAccessoryModal,
    accessoryModal,
    accessorySearchModal,

    formik,
    pricing,
    offerItem,
    offerItemInQuote,

    getAddonPricing,
    getOfferItem,
    getDirtyOfferItem,

    handleEditAddon,
    handleEditDiscount,
    updateAccessory,

    handleRemoveOfferItem,

    setShouldCalculatePricing,
    setShouldLoadAccessories,

    pricingQuery,
    recommendedAccessoriesQuery,
  } = useContext(OfferItemModalContext);

  const addonsPricing: AddonPriceInOfferModuleFragment[] =
    formik.values.addons
      .map(getAddonPricing)
      .filter(Boolean)
      .map((pricing) => pricing!) ?? [];

  function renderDescription() {
    if (!control.data) return;

    return (
      <OptiAccordion>
        <OptiAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-description-content"
          id="panel-description-header"
        >
          <Typography className={classes.sectionHeading}>
            Description
          </Typography>
        </OptiAccordionSummary>
        <OptiAccordionDetails>
          <Box className={classes.simpleRow}>
            <Box flex={1} whiteSpace="pre-line">
              {formik.values.description || <pre>(no description)</pre>}
            </Box>
          </Box>
        </OptiAccordionDetails>
      </OptiAccordion>
    );
  }

  function renderSpecifications() {
    if (!control.data) return;

    return (
      <OptiAccordion>
        <OptiAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-specifications-content"
          id="panel-specifications-header"
        >
          <Typography className={classes.sectionHeading}>
            Specifications
          </Typography>
        </OptiAccordionSummary>
        <OptiAccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            {!formik.values.specifications.length && (
              <Box marginY={1} marginX={2}>
                <pre>(no specifications)</pre>
              </Box>
            )}
            {formik.values.specifications.map((spec, idx, self) => (
              <Fragment key={spec.id}>
                <Box
                  className={clsx(
                    classes.simpleRow,
                    classes.simpleRowWithLabel
                  )}
                  marginBottom={1}
                >
                  <Box flex={1}>{spec.name}:</Box>
                  <Box flex={1}>
                    {spec.value}
                    {spec.unit ? ` (${spec.unit})` : ''}
                  </Box>
                </Box>
                {idx < self.length - 1 && (
                  <Box marginBottom={1}>
                    <Divider className={classes.divider} />
                  </Box>
                )}
              </Fragment>
            ))}
          </Box>
        </OptiAccordionDetails>
      </OptiAccordion>
    );
  }

  function renderAddons() {
    if (!pricing) return;
    return (
      <>
        {formik.values.addons.map((addon) => {
          const calculatedPricing = getAddonPricing(addon);

          return (
            <Fragment key={addon.id}>
              <Box
                className={clsx(
                  classes.actionableRow,
                  classes.simpleRowWithLabel
                )}
                marginBottom={1}
                onClick={() => handleEditAddon(addon)}
              >
                <Box flex={2}>{addon.name}</Box>

                <Box>{pricing.offerItemCurrency.symbol}</Box>
                <Box flex={1} textAlign="right">
                  {formatPrice(addon.price, pricing.offerItemCurrency, true)}
                </Box>
                <ChevronRightIcon />
              </Box>
              {!!calculatedPricing?.discount?.valueTotal && (
                <Box
                  className={clsx(
                    classes.simpleRow,
                    classes.simpleRowWithLabel
                  )}
                  marginBottom={1}
                  onClick={() => handleEditAddon(addon)}
                >
                  <Box flex={2}>
                    Disc.{' '}
                    {calculatedPricing.discount.discount.calculationType ===
                    CalculationType.PERCENTAGE
                      ? formatDiscount(
                          calculatedPricing.discount.discount.value
                        )
                      : ''}{' '}
                    {addon.name}
                  </Box>

                  <Box>{pricing.offerItemCurrency.symbol}</Box>
                  <Box flex={1} textAlign="right">
                    {formatPrice(
                      -1 * calculatedPricing.discount.valueTotal,
                      pricing.offerItemCurrency,
                      true
                    )}
                  </Box>
                </Box>
              )}
            </Fragment>
          );
        })}
        <Box paddingX={2}>
          <Button
            className={classes.flatInlineButton}
            fullWidth
            onClick={() => handleEditAddon(undefined)}
          >
            <StarBorderIcon /> Add{' '}
            {formik.values.addons.length ? 'another' : 'an'} extra service to
            product?
          </Button>
        </Box>
      </>
    );
  }

  function renderTax(tax: TaxCalculated) {
    const taxValues =
      tax.valueTotal +
      addonsPricing
        .flatMap((addonPricing) => [
          ...addonPricing.taxesBeforeDiscount,
          ...addonPricing.taxesAfterDiscount,
          ...addonPricing.taxesFinal,
        ])
        .filter((addonTax) => addonTax.tax.id === tax.tax.id)
        .reduce((acc, tax) => acc + tax.valueTotal, 0);

    return (
      pricing && (
        <Box
          key={tax.tax.id}
          className={clsx(classes.simpleRow, classes.simpleRowWithLabel)}
          marginBottom={1}
        >
          <Box flex={2}>
            {tax.tax.name}{' '}
            {tax.tax.calculationType === CalculationType.PERCENTAGE
              ? formatDiscount(tax.tax.value)
              : ''}
          </Box>

          <Box>{pricing.offerItemCurrency.symbol}</Box>
          <Box flex={1} textAlign="right">
            {formatPrice(taxValues, pricing.offerItemCurrency, true)}
          </Box>
        </Box>
      )
    );
  }

  function renderPricing() {
    return (
      <OptiAccordion
        defaultExpanded
        onChange={(_event, isExpanded) =>
          isExpanded && setShouldCalculatePricing(!offerItem?.pricing)
        }
      >
        <OptiAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-pricing-content"
          id="panel-pricing-header"
        >
          <Typography className={classes.sectionHeading}>Pricing</Typography>
        </OptiAccordionSummary>
        <OptiAccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            {!pricing && pricingQuery.loading && (
              <Box
                display="flex"
                width="100%"
                justifyContent="center"
                paddingY={2}
              >
                <CircularProgress />
              </Box>
            )}

            {pricing && (
              <>
                <Box
                  className={clsx(
                    classes.simpleRow,
                    classes.simpleRowWithLabel
                  )}
                  marginBottom={1}
                >
                  <Box flex={2}>List Price Unit</Box>

                  <Box>{pricing.offerItemCurrency.symbol}</Box>
                  <Box flex={1} textAlign="right">
                    {formatPrice(
                      formik.values.listPrice,
                      pricing.offerItemCurrency,
                      true
                    )}
                  </Box>
                </Box>

                <Box
                  className={clsx(
                    classes.simpleRow,
                    classes.simpleRowWithLabel
                  )}
                  marginBottom={1}
                >
                  <Box flex={2}>Quantity</Box>

                  <Box flex={1} textAlign="right">
                    <OptiInlineTextField
                      required
                      alignTextRight
                      {...mapFormikToTextField(formik, 'quantity')}
                      InputProps={{
                        inputComponent: NumberFormatInput as any,
                        inputProps: {
                          min: 0,
                          prefix: 'x',
                          onFocus: (e) => {
                            requestAnimationFrame(() => {
                              e.target.select();
                            });
                          },
                        },
                      }}
                      onBlur={() => setShouldCalculatePricing(true)}
                    />
                  </Box>
                </Box>

                <Box marginY={1.5}>
                  <Divider />
                </Box>

                {renderAddons()}

                <Box marginY={1.5}>
                  <Divider />
                </Box>

                {pricing.taxesBeforeDiscount.map(renderTax)}

                {!!pricing.discount?.valueTotal && (
                  <Box
                    className={clsx(
                      classes.actionableRow,
                      classes.simpleRowWithLabel
                    )}
                    marginBottom={1}
                    onClick={handleEditDiscount}
                  >
                    <Box flex={2}>
                      Disc.{' '}
                      {pricing.discount.discount.calculationType ===
                      CalculationType.PERCENTAGE
                        ? formatDiscount(pricing.discount.discount.value)
                        : ''}
                    </Box>

                    <Box>{pricing.offerItemCurrency.symbol}</Box>
                    <Box flex={1} textAlign="right">
                      {formatPrice(
                        -1 * pricing.discount.valueTotal,
                        pricing.offerItemCurrency,
                        true
                      )}
                    </Box>
                    <ChevronRightIcon />
                  </Box>
                )}

                {!!pricing.discountFinal?.valueTotal && (
                  <Box
                    className={clsx(
                      classes.simpleRow,
                      classes.simpleRowWithLabel
                    )}
                    marginBottom={1}
                  >
                    <Box flex={2}>
                      Additional Disc.{' '}
                      {pricing.discountFinal.discount.calculationType ===
                      CalculationType.PERCENTAGE
                        ? formatDiscount(pricing.discountFinal.discount.value)
                        : ''}
                    </Box>

                    <Box>{pricing.offerItemCurrency.symbol}</Box>
                    <Box flex={1} textAlign="right">
                      {formatPrice(
                        -1 * pricing.discountFinal.valueTotal,
                        pricing.offerItemCurrency,
                        true
                      )}
                    </Box>
                  </Box>
                )}

                {pricing.taxesAfterDiscount.map(renderTax)}
                {pricing.taxesFinal.map(renderTax)}

                <Box
                  className={clsx(
                    classes.simpleRow,
                    classes.simpleRowWithLabel
                  )}
                  marginBottom={1}
                >
                  <Box flex={2}>Net Price Total</Box>

                  <Box>{pricing.offerItemCurrency.symbol}</Box>
                  <Box flex={1} textAlign="right">
                    {formatPrice(
                      pricing.netPriceTotal +
                        addonsPricing.reduce((acc, it) => acc + it.netPrice, 0),
                      pricing.offerItemCurrency,
                      true
                    )}
                  </Box>
                </Box>

                {!pricing.discount?.valueTotal && (
                  <Box paddingX={2}>
                    <Button
                      className={classes.flatInlineButton}
                      fullWidth
                      onClick={handleEditDiscount}
                    >
                      <LocalOfferIcon /> Add Discount?
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        </OptiAccordionDetails>
      </OptiAccordion>
    );
  }

  function renderAccessories() {
    if (isAccessoryModal) return;

    return (
      <OptiAccordion
        onChange={(_event, isExpanded) =>
          isExpanded && setShouldLoadAccessories(true)
        }
      >
        <OptiAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-accessories-content"
          id="panel-accessories-header"
        >
          <Typography className={classes.sectionHeading}>
            Accessories
          </Typography>
        </OptiAccordionSummary>
        <OptiAccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            {recommendedAccessoriesQuery.loading && (
              <Box
                display="flex"
                width="100%"
                justifyContent="center"
                paddingY={2}
              >
                <CircularProgress />
              </Box>
            )}
            {recommendedAccessoriesQuery.data &&
              !recommendedAccessoriesQuery.data.getRecommendedAccessories
                .length && (
                <Box padding={2}>
                  <pre>No recommended accessories found.</pre>
                </Box>
              )}
            <Box paddingX={2}>
              {[
                ...(recommendedAccessoriesQuery.data?.getRecommendedAccessories
                  .map((item) => ({
                    product: item.product,
                    version: item.productVersion,
                  }))
                  .map((item) => ({
                    ...item,
                    offerItem: getOfferItem(item),
                  })) ?? []),
                ...formik.values.children
                  .filter(
                    (child) =>
                      !recommendedAccessoriesQuery.data?.getRecommendedAccessories?.some(
                        (accessory) =>
                          accessory.productVersion.id ===
                          child.product?.productVersionId
                      )
                  )
                  .map((accessory) => {
                    const productSearchResult = getProductSearchResult(
                      accessory
                    );
                    return {
                      product: productSearchResult?.product,
                      version: productSearchResult?.version,
                      offerItem: accessory,
                    };
                  })
                  .filter(
                    (
                      it
                    ): it is ProductSearchResult & { offerItem: OfferItem } =>
                      !!(it.product && it.version)
                  ),
              ].map((item, index, self) => (
                <ProductListItem
                  key={item.version.id}
                  item={item}
                  offerItem={item.offerItem}
                  handleIncreaseQuantity={async () =>
                    updateAccessory(
                      item.offerItem
                        ? {
                            ...item.offerItem,
                            quantity: item.offerItem.quantity + 1,
                          }
                        : await convertToOfferItem(getProduct, item)
                    )
                  }
                  handleUpdateQuantity={updateAccessory}
                  onClick={async (it) => accessoryModal.open(it)}
                  actionIsCheckbox={false}
                  hideAction={false}
                >
                  {index < self.length - 1 && (
                    <Box marginX={-2}>
                      <Divider />
                    </Box>
                  )}
                </ProductListItem>
              ))}
            </Box>

            <Box paddingX={2}>
              <Button
                className={classes.flatInlineButton}
                fullWidth
                onClick={() => {
                  const dirtyOfferItem = getDirtyOfferItem();
                  if (!dirtyOfferItem) return;

                  accessorySearchModal.open({
                    productName: dirtyOfferItem.product?.name ?? '',
                    productVersionCode: dirtyOfferItem.product?.code ?? '',
                    offerItemAccessories: dirtyOfferItem.children ?? [],
                  });
                }}
              >
                <SearchIcon /> Search other Accessories
              </Button>
            </Box>
          </Box>
        </OptiAccordionDetails>
      </OptiAccordion>
    );
  }

  function renderContent() {
    if (!control.data) return;

    return (
      <Box maxWidth="100vw" height={'100%'}>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={0.5}
          marginBottom={1.5}
        >
          {offerItem?.product?.image?.thumbnail ? (
            <img
              className={localClasses.productImage}
              src={offerItem.product.image.thumbnail}
              alt="product"
            />
          ) : (
            <Box className={localClasses.placeholderIcon}>
              <PanoramaIcon />
            </Box>
          )}
        </Box>

        <Box className={classes.simpleRow} textAlign="center" fontWeight="bold">
          <Box flex={1}>{formik.values.name}</Box>
        </Box>

        <Box display="flex" justifyContent="center" marginTop={0.5}>
          <Box className={localClasses.productCode} marginRight={1}>
            {formik.values.code}
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className={localClasses.productPrice} marginLeft={1}>
            {formatPrice(
              control.data.version.price,
              offer?.currency ?? company.currency
            )}
          </Box>
        </Box>

        {renderDescription()}
        {renderSpecifications()}
        {renderAccessories()}
        {renderPricing()}
      </Box>
    );
  }

  function renderButtons() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          onClick={moreOptionsMenu.open}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          anchorEl={moreOptionsMenu.anchorElement}
          keepMounted
          open={moreOptionsMenu.isOpen}
          onClose={moreOptionsMenu.close}
        >
          <MenuItem
            onClick={() => {
              moreOptionsMenu.close();
              return control.data && openEditProduct(control.data);
            }}
          >
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Edit Product" />
          </MenuItem>
          {offerItemInQuote && (
            <>
              <Divider />
              <MenuItem
                onClick={() => {
                  moreOptionsMenu.close();
                  return handleRemoveOfferItem();
                }}
              >
                <ListItemIcon>
                  <DeleteIcon
                    fontSize="small"
                    htmlColor={ooBrand.colors.error}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Remove from Quote"
                  style={{ color: ooBrand.colors.error }}
                />
              </MenuItem>
            </>
          )}
        </Menu>
        {!offerItemInQuote ? (
          <Button variant="contained" color="primary" type="submit" fullWidth>
            {isAccessoryModal ? 'Add Accessory' : 'Add to Quote'}
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={!formik.dirty}
            >
              {!formik.dirty
                ? 'Changes Saved'
                : formik.values.quantity
                ? 'Save Changes'
                : isAccessoryModal
                ? 'Remove Accessory'
                : 'Remove from Quote'}
            </Button>
          </>
        )}
      </>
    );
  }

  return (
    <>
      {renderContent()}

      <Box height={80} />

      <Box display="flex" gridGap={18} className={clsx(classes.floatingFooter)}>
        {renderButtons()}
      </Box>
    </>
  );
};

export default OfferItemModalMobile;
