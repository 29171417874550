export type ProductCategory = {
  displayName: string;
  slug: string;
};

export const PRODUCT_CATEGORIES: ProductCategory[] = [
  {
    displayName: 'Cuptoare rapide ventless',
    slug: 'echipamente-calde_cuptoare_cuptoare-rapide-ventless',
  },
  {
    displayName: 'Cuptoare convectie gastro',
    slug: 'echipamente-calde_cuptoare_cuptoare-convectie-gastro',
  },
  {
    displayName: 'Cuptoare pizza',
    slug: 'echipamente-calde_cuptoare_cuptoare-pizza',
  },
  {
    displayName: 'Cuptoare microunde',
    slug: 'echipamente-calde_cuptoare_cuptoare-microunde',
  },
  {
    displayName: 'Cuptoare patiserie & cofetarie',
    slug: 'echipamente-calde_cuptoare_cuptoare-patiserie-cofetarie',
  },
  {
    displayName: 'Grill & Fry-top electrice',
    slug: 'echipamente-calde_grill_grill-fry-top_electric',
  },
  {
    displayName: 'Grill & Fry-top gaz',
    slug: 'echipamente-calde_grill_grill-fry-top_gaz',
  },
  {
    displayName: 'Grill Apa',
    slug: 'echipamente-calde_grill_grill-apa',
  },
  {
    displayName: 'Panini & Contact grill',
    slug: 'echipamente-calde_grill_panini-contact-grill',
  },
  {
    displayName: 'Grill roca vulcanica',
    slug: 'echipamente-calde_grill_grill-roca-vulcanica',
  },
  {
    displayName: 'Fierbatoare orez',
    slug: 'echipamente-calde_fierbatoare_fierbatoare-orez',
  },
  {
    displayName: 'Fierbatoare paste electrice',
    slug: 'echipamente-calde_fierbatoare_fierbatoare-paste_electric',
  },
  {
    displayName: 'Fierbatoare paste gaz',
    slug: 'echipamente-calde_fierbatoare_fierbatoare-paste_gaz',
  },
  {
    displayName: 'Tigai basculante',
    slug: 'echipamente-calde_tigai-basculante',
  },
  {
    displayName: 'Tigai basculante electrice',
    slug: 'echipamente-calde_tigai-basculante_fierbatoare-paste_electric',
  },
  {
    displayName: 'Tigai basculante gaz',
    slug: 'echipamente-calde_tigai-basculante_fierbatoare-paste_gaz',
  },
  {
    displayName: 'Plite multifunctionale',
    slug: 'echipamente-calde_plite-multifunctionale',
  },
  {
    displayName: 'Friteuze electrice',
    slug: 'echipamente-calde_friteuze_electric',
  },
  {
    displayName: 'Friteuze gaz',
    slug: 'echipamente-calde_friteuze_gaz',
  },
  {
    displayName: 'Masini/Plite de gatit & Aragaze electrice',
    slug: 'echipamente-calde_masini-de-gatit_electric',
  },
  {
    displayName: 'Masini/Plite de gatit & Aragaze gaz',
    slug: 'echipamente-calde_masini-de-gatit_gaz',
  },
  {
    displayName: 'Placi calde & Lampi incalzire',
    slug: 'echipamente-calde_placi-calde-lampi-incalzire',
  },
  {
    displayName: 'Plite inductie',
    slug: 'echipamente-calde_plite-inductie',
  },
  {
    displayName: 'Crepiere & Aparate vafe',
    slug: 'echipamente-calde_crepiere-aparate-vafe',
  },
  {
    displayName: 'Aparate Hot Dog',
    slug: 'echipamente-calde_aparate-hot-dog',
  },
  {
    displayName: 'Salamandre',
    slug: 'echipamente-calde_salamandre',
  },
  {
    displayName: 'Chafing dish',
    slug: 'echipamente-calde_chafing-dish',
  },
  {
    displayName: 'Pentru bauturi',
    slug: 'echipamente-calde_pentru-bauturi',
  },
  {
    displayName: 'Prajitoare paine',
    slug: 'echipamente-calde_prajitoare-paine',
  },
  {
    displayName: 'Incalzitoare vesela',
    slug: 'echipamente-calde_incalzitoare-vesela',
  },
  {
    displayName: 'Sous-Vide',
    slug: 'echipamente-calde_sous-vide',
  },
  {
    displayName: 'Gyros & Kebab grill',
    slug: 'echipamente-calde_gyros-kebab-grill',
  },
  {
    displayName: 'Bain-marie',
    slug: 'echipamente-calde_bain-marie',
  },
  {
    displayName: 'Vitrine calde',
    slug: 'echipamente-calde_vitrine-calde',
  },
  {
    displayName: 'Rotisoare',
    slug: 'echipamente-calde_rotisoare',
  },
  {
    displayName: 'Uscator alimente',
    slug: 'echipamente-calde_uscator-alimente',
  },
  {
    displayName: 'Accessori echipamente calde',
    slug: 'echipamente-calde_accessori-echipamente-calde',
  },
  {
    displayName: 'Curatare radacinoase',
    slug: 'echipamente-de-procesare_curatare_curatare-radacinoase',
  },
  {
    displayName: 'Curatare cochilii & scoici',
    slug: 'echipamente-de-procesare_curatare_curatare-cochilii-scoici',
  },
  {
    displayName: 'Mixere carne',
    slug: 'echipamente-de-procesare_carmangerie-macelarie_mixere-carne',
  },
  {
    displayName: 'Masini umplut carnati',
    slug:
      'echipamente-de-procesare_carmangerie-macelarie_masini-umplut-carnati',
  },
  {
    displayName: 'Tocat carne',
    slug: 'echipamente-de-procesare_carmangerie-macelarie_tocat-carne',
  },
  {
    displayName: 'Fragezitoare carne',
    slug: 'echipamente-de-procesare_carmangerie-macelarie_fragezitoare-carne',
  },
  {
    displayName: 'Fierastraie oase',
    slug: 'echipamente-de-procesare_carmangerie-macelarie_fierastraie-oase',
  },
  {
    displayName: 'Prese',
    slug: 'echipamente-de-procesare_prese',
  },
  {
    displayName: 'Feliatoare',
    slug: 'echipamente-de-procesare_feliatoare',
  },
  {
    displayName: 'Mixere verticale de mana',
    slug: 'echipamente-de-procesare_mixere_mixere-verticale-de-mana',
  },
  {
    displayName: 'Mixere planetare',
    slug: 'echipamente-de-procesare_mixere_mixere-planetare',
  },
  {
    displayName: 'Mixere spirale',
    slug: 'echipamente-de-procesare_mixere_mixere-spirale',
  },
  {
    displayName: 'Dezhidratare',
    slug: 'echipamente-de-procesare_dezhidratare',
  },
  {
    displayName: 'Formatoare aluat',
    slug: 'echipamente-de-procesare_formatoare-aluat',
  },
  {
    displayName: 'Roboti multifunctionali',
    slug: 'echipamente-de-procesare_roboti-multifunctionali',
  },
  {
    displayName: 'Roboti legume & branzeturi',
    slug: 'echipamente-de-procesare_roboti-legume-branzeturi',
  },
  {
    displayName: 'Pacojet',
    slug: 'echipamente-de-procesare_pacojet',
  },
  {
    displayName: 'Masini de paste',
    slug: 'echipamente-de-procesare_paste',
  },
  {
    displayName: 'Cutter',
    slug: 'echipamente-de-procesare_cutter',
  },
  {
    displayName: 'Accessori echipamente procesare',
    slug: 'echipamente-de-procesare_accessori-echipamente-procesare',
  },
  {
    displayName: 'Mese reci suport utilaje',
    slug: 'echipamente-reci_mese-reci_mese-reci-suport-utilaje',
  },
  {
    displayName: 'Mese reci refrigerare',
    slug: 'echipamente-reci_mese-reci_mese-reci-refrigerare',
  },
  {
    displayName: 'Mese pizza',
    slug: 'echipamente-reci_mese-reci_mese-pizza',
  },
  {
    displayName: 'Mese reci congelare',
    slug: 'echipamente-reci_mese-reci_mese-reci-congelare',
  },
  {
    displayName: 'Dulapuri refrigerare',
    slug: 'echipamente-reci_dulapuri-frigorifice_dulapuri-refrigerare',
  },
  {
    displayName: 'Dulapuri congelare',
    slug: 'echipamente-reci_dulapuri-frigorifice_dulapuri-congelare',
  },
  {
    displayName: 'Dulapuri frigorifice maturare',
    slug: 'echipamente-reci_dulapuri-frigorifice_dulapuri-frigorifice-maturare',
  },
  {
    displayName: 'Salatiere',
    slug: 'echipamente-reci_salatiere',
  },
  {
    displayName: 'Vitrine frigorifice',
    slug: 'echipamente-reci_vitrine-frigorifice',
  },
  {
    displayName: 'Lazi congelare',
    slug: 'echipamente-reci_lazi-congelare',
  },
  {
    displayName: 'Blast chillers & Abatidoare',
    slug: 'echipamente-reci_blast-chillers-abatidoare',
  },
  {
    displayName: 'Farmacie & Laborator',
    slug: 'echipamente-reci_farmacie-laborator',
  },
  {
    displayName: 'Accessori echipamente reci',
    slug: 'echipamente-reci_accessori-echipamente-reci',
  },
  {
    displayName: 'Accessori mobilier',
    slug: 'mobilier_accessori-mobilier',
  },
  {
    displayName: 'Deschis',
    slug: 'mobilier_dulapuri_dulapuri-perete_deschis',
  },
  {
    displayName: 'Cu usi',
    slug: 'mobilier_dulapuri_dulapuri-perete_cu-usi',
  },
  {
    displayName: 'Depozitare',
    slug: 'mobilier_dulapuri_dulapuri-perete_depozitare',
  },
  {
    displayName: 'Dulapuri depozitare',
    slug: 'mobilier_dulapuri_dulapuri-depozitare',
  },
  {
    displayName: 'Polite perete',
    slug: 'mobilier_polite-perete',
  },
  {
    displayName: 'Rafturi depozitare',
    slug: 'mobilier_rafturi-depozitare',
  },
  {
    displayName: 'Pubele',
    slug: 'mobilier_pubele',
  },
  {
    displayName: 'Vitrine neutre',
    slug: 'mobilier_vitrine-neutre',
  },
  {
    displayName: 'Tavi',
    slug: 'mobilier_carucioare_tavi',
  },
  {
    displayName: 'Marfa',
    slug: 'mobilier_carucioare_marfa',
  },
  {
    displayName: 'Servire',
    slug: 'mobilier_carucioare_servire',
  },
  {
    displayName: 'Suprastructuri',
    slug: 'mobilier_suprastructuri',
  },
  {
    displayName: 'Fara polita',
    slug: 'mobilier_mese-de-lucru_centrale_fara-polita',
  },
  {
    displayName: 'Cu polita',
    slug: 'mobilier_mese-de-lucru_centrale_cu-polita',
  },
  {
    displayName: 'Ranforsate',
    slug: 'mobilier_mese-de-lucru_centrale_ranforsate',
  },
  {
    displayName: 'Tip dulap',
    slug: 'mobilier_mese-de-lucru_centrale_tip-dulap',
  },
  {
    displayName: 'Fara polita',
    slug: 'mobilier_mese-de-lucru_perete_fara-polita',
  },
  {
    displayName: 'Cu polita',
    slug: 'mobilier_mese-de-lucru_perete_cu-polita',
  },
  {
    displayName: 'Ranforsate',
    slug: 'mobilier_mese-de-lucru_perete_ranforsate',
  },
  {
    displayName: 'Tip dulap',
    slug: 'mobilier_mese-de-lucru_perete_tip-dulap',
  },
  {
    displayName: 'Mese de lucru de colt',
    slug: 'mobilier_mese-de-lucru_colt',
  },
  {
    displayName: 'Mese de lucru cu gura debarasare',
    slug: 'mobilier_mese-de-lucru_cu-gura-debarasare',
  },
  {
    displayName: 'Chiuvete bucatar',
    slug: 'mobilier_cu-cuva_chiuvete-bucatar',
  },
  {
    displayName: 'Spalatoare oale mari',
    slug: 'mobilier_cu-cuva_spalatoare-oale-mari',
  },
  {
    displayName: 'Fara polita',
    slug: 'mobilier_cu-cuva_mese-de-lucru-cu-chiuveta_fara-polita',
  },
  {
    displayName: 'Cu polita',
    slug: 'mobilier_cu-cuva_mese-de-lucru-cu-chiuveta_cu-polita',
  },
  {
    displayName: 'Ranforsate',
    slug: 'mobilier_cu-cuva_mese-de-lucru-cu-chiuveta_ranforsate',
  },
  {
    displayName: 'Tip dulap',
    slug: 'mobilier_cu-cuva_mese-de-lucru-cu-chiuveta_tip-dulap',
  },
  {
    displayName: 'Butuc',
    slug: 'mobilier_butuc',
  },
  {
    displayName: 'Mase de intrare pentru masina de spalat vesela',
    slug: 'mobilier_spalatorie-vesela_intrare',
  },
  {
    displayName: 'Mase de iesire pentru masina de spalat vesela',
    slug: 'mobilier_spalatorie-vesela_iesire',
  },
  {
    displayName: 'Mese de lucru cu spatiu pentru echipamente',
    slug: 'mobilier_spalatorie-vesela_cu-spatiu-pentru-masina-de-spalat-vase',
  },
  {
    displayName: 'Incalzitoare terasa',
    slug: 'mobilier_incalzitoare-terasa',
  },
  {
    displayName: 'Mobilier pliant',
    slug: 'mobilier_mobilier-pliant',
  },
  {
    displayName: 'Suporturi & Baze',
    slug: 'mobilier_suporturi-baze',
  },
  {
    displayName: 'Mese calde',
    slug: 'mobilier_mese-calde',
  },
  {
    displayName: 'Mobilier pentru procesare',
    slug: 'mobilier_preparare',
  },
  {
    displayName: 'Hote de perete',
    slug: 'mobilier_hote_perete',
  },
  {
    displayName: 'Hote centrale',
    slug: 'mobilier_hote_centrale',
  },
  {
    displayName: 'Motoare hota',
    slug: 'mobilier_hote_motoare-hota',
  },
  {
    displayName: 'Bariere',
    slug: 'mobilier_bariere',
  },
  {
    displayName: 'Masini de vidat cu camera',
    slug: 'echipamente-de-ambalare_masini-de-vidat_cu-camera',
  },
  {
    displayName: 'Masini de vidat fara camera',
    slug: 'echipamente-de-ambalare_masini-de-vidat_fara-camera',
  },
  {
    displayName: 'Termosudare caserole',
    slug: 'echipamente-de-ambalare_termosudare-caserole',
  },
  {
    displayName: 'Dispensere folie',
    slug: 'echipamente-de-ambalare_dispensere-folie',
  },
  {
    displayName: 'Sigilare termica',
    slug: 'echipamente-de-ambalare_sigilare-termica',
  },
  {
    displayName: 'Accessori echipamente de ambalare',
    slug: 'echipamente-de-ambalare_accessori-echipamente-de-ambalare',
  },
  {
    displayName: 'Vitrine bar',
    slug: 'echipamente-pentru-bar-cafea_vitrine_vitrine-bar',
  },
  {
    displayName: 'Vitrine vin',
    slug: 'echipamente-pentru-bar-cafea_vitrine_vitrine-vin',
  },
  {
    displayName: 'Frappe & Milkshake',
    slug: 'echipamente-pentru-bar-cafea_frappe-milkshake',
  },
  {
    displayName: 'Storcatoare fructe & legume',
    slug: 'echipamente-pentru-bar-cafea_storcatoare-fructe-legume',
  },
  {
    displayName: 'Grupuri modulare bar & cafea',
    slug: 'echipamente-pentru-bar-cafea_grupuri-modulare',
  },
  {
    displayName: 'Blendere',
    slug: 'echipamente-pentru-bar-cafea_blendere',
  },
  {
    displayName: 'Spargatoare gheata',
    slug: 'echipamente-pentru-bar-cafea_gheata_spargatoare-gheata',
  },
  {
    displayName: 'Masini gheata',
    slug: 'echipamente-pentru-bar-cafea_gheata_masini-gheata',
  },
  {
    displayName: 'Aparate cafea',
    slug: 'echipamente-pentru-bar-cafea_cafea_aparate-cafea',
  },
  {
    displayName: 'Rasnite',
    slug: 'echipamente-pentru-bar-cafea_cafea_rasnite',
  },
  {
    displayName: 'Accessori echipamente pentru bar & cafea',
    slug: 'echipamente-pentru-bar-cafea_accessori-echipamente-pentru-bar-cafea',
  },
  {
    displayName: 'Masini de spalat vesela',
    slug: 'spalatorie-igiena_spalatorie-vesela_masini-de-spalat-vesela',
  },
  {
    displayName: 'Accessori masini de spalat vesela',
    slug:
      'spalatorie-igiena_spalatorie-vesela_accessori-masini-de-spalat-vesela',
  },
  {
    displayName: 'Spalatorie rufe',
    slug: 'spalatorie-igiena_spalatorie-rufe',
  },
  {
    displayName: 'Sterilizatoare',
    slug: 'spalatorie-igiena_sterilizatoare',
  },
  {
    displayName: 'Filtre apa & dedurizatoare',
    slug: 'spalatorie-igiena_filtre-apa-dedurizatoare',
  },
  {
    displayName: 'Eliminare deseuri',
    slug: 'spalatorie-igiena_eliminare-deseuri',
  },
  {
    displayName: 'Robineti',
    slug: 'spalatorie-igiena_robineti',
  },
  {
    displayName: 'Lampi anti-insecte',
    slug: 'spalatorie-igiena_lampi-anti-insecte',
  },
  {
    displayName: 'Standuri',
    slug: 'vesela_standuri',
  },
  {
    displayName: 'Platouri & Tavi',
    slug: 'vesela_platouri-tavi',
  },
  {
    displayName: 'Boluri',
    slug: 'vesela_boluri',
  },
  {
    displayName: 'Dozatoare',
    slug: 'vesela_dozatoare',
  },
  {
    displayName: 'Vesela mini',
    slug: 'vesela_mini',
  },
  {
    displayName: 'Pahare & Cani',
    slug: 'vesela_de-baut',
  },
  {
    displayName: 'Vesela desert',
    slug: 'vesela_pentru-dulciuri',
  },
  {
    displayName: 'Posuri & Duiuri',
    slug: 'accesorii_ustensile_posuri-duiuri',
  },
  {
    displayName: 'Spatule & Pensule',
    slug: 'accesorii_ustensile_spatule-pensule',
  },
  {
    displayName: 'Sucitoare',
    slug: 'accesorii_ustensile_sucitoare',
  },
  {
    displayName: 'Ustensile inghetata',
    slug: 'accesorii_ustensile_inghetata',
  },
  {
    displayName: 'Cutite',
    slug: 'accesorii_ustensile_cutite',
  },
  {
    displayName: 'Taietoare',
    slug: 'accesorii_ustensile_taietoare',
  },
  {
    displayName: 'Teluri',
    slug: 'accesorii_ustensile_teluri',
  },
  {
    displayName: 'Arzatoare',
    slug: 'accesorii_ustensile_arzatoare',
  },
  {
    displayName: 'Ustensile sosuri',
    slug: 'accesorii_ustensile_sosuri',
  },
  {
    displayName: 'Site & Cernatoare',
    slug: 'accesorii_ustensile_site-cernatoare',
  },
  {
    displayName: 'Polonice',
    slug: 'accesorii_ustensile_polonice',
  },
  {
    displayName: 'Boluri & Cosuri',
    slug: 'accesorii_boluri-cosuri',
  },
  {
    displayName: 'Tavi & Containere GN/EN',
    slug: 'accesorii_tavi-containere-gnen',
  },
  {
    displayName: 'Cantare',
    slug: 'accesorii_cantare',
  },
  {
    displayName: 'Oale & Cratite',
    slug: 'accesorii_oale-cratite',
  },
  {
    displayName: 'Tigai',
    slug: 'accesorii_tigai',
  },
  {
    displayName: 'Termosuri & Cutii transport',
    slug: 'accesorii_termosuri-cutii-transport',
  },
  {
    displayName: 'Accesorii pizza',
    slug: 'accesorii_pizza',
  },
].sort((a, b) => a.displayName.localeCompare(b.displayName));

export const PRODUCT_CATEGORIES_MAP = new Map<string, ProductCategory>(
  PRODUCT_CATEGORIES.map((c) => [c.slug, c])
);
