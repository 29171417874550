import React, { ReactNodeArray } from 'react';
import { Route } from 'react-router-dom';

import JoinTeamPage from './JoinTeam';
import LoginPage from './Login';
import ResetPasswordModule from './ResetPassword';
import SignupModule from './Signup';
import DirectSignupPage from './Signup/DirectSignup';

const loginRoutes: () => ReactNodeArray = () => [
  <Route exact path="/login" key="login" component={LoginPage} />,
  <Route exact path="/signup" key="signup" component={SignupModule} />,
  <Route
    exact
    path="/direct-signup"
    key="direct-signup"
    component={DirectSignupPage}
  />,
  <Route exact path="/join-team" key="join-team" component={JoinTeamPage} />,
  <Route
    exact
    path="/reset-password"
    key="reset-password"
    component={ResetPasswordModule}
  />,
];

export default loginRoutes;
