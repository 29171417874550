import { SecurityContext, useEnhancedQuery, useIsMobile } from '@lib';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FC, useContext, useMemo } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { useLocation } from 'react-router';

import { VirtualAssistant_FindNotificationCountDocument } from '@optioffer/graphql';

import DesktopMenu from '@containers/Layout/DesktopMenu';
import MobileMenu from '@containers/Layout/MobileMenu';
import NPSSurveyProvider from '@containers/NPSSurvey/NPSSurveyProvider';

import logo from '@resources/OO_logo_wo_safespace_Secondary - Mark - Color.svg';
import { LinkedinIcon, YoutubeIcon } from '@resources/icons';

import useStyles from './styles';

const Layout: FC = ({ children }) => {
  const classes = useStyles();
  const context = useContext(SecurityContext);
  const location = useLocation();
  const shouldShowChat = useMemo(
    () => location.pathname.indexOf('support') > -1,
    [location]
  );
  const shouldHideLayout = useMemo(
    () => location.pathname.indexOf('profile/new') > -1,
    [location]
  );
  const shouldPartiallyHideLayout = useMemo(
    () => location.pathname.indexOf('new-offer') > -1,
    [location]
  );
  const isMobile = useIsMobile();

  const { data: findNotificationsCount } = useEnhancedQuery(
    VirtualAssistant_FindNotificationCountDocument,
    {
      error: {
        type: 'SNACKBAR',
        message: 'An error occurred while loading the notification count',
      },
    }
  );
  const notificationCount = useMemo(
    () => findNotificationsCount?.findNotificationsCount,
    [findNotificationsCount]
  );

  const chat = useMemo(
    () => (
      <MessengerCustomerChat
        version="7.0"
        pageId="2237333013192965"
        themeColor="#0F5C52"
        appId=""
        shouldShowDialog={shouldShowChat}
      />
    ),
    [shouldShowChat]
  );

  function renderMain() {
    return <NPSSurveyProvider>{children}</NPSSurveyProvider>;
  }

  if (shouldHideLayout) {
    return renderMain();
  }

  if (shouldPartiallyHideLayout) {
    return (
      <>
        <MobileMenu notificationCount={notificationCount} />
        {renderMain()}
      </>
    );
  }

  return (
    <div className={classes.container}>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        {isMobile ? (
          <MobileMenu notificationCount={notificationCount} />
        ) : (
          <DesktopMenu notificationCount={notificationCount} />
        )}
      </AppBar>

      <div className={classes.content}>
        <main className={classes.main}>
          <div className={classes.toolbar} />
          {renderMain()}
        </main>
        <footer>
          <Divider />
          <Grid
            container
            className={classes.footer}
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography>
                © OptiOffer, 2022, v{process.env.REACT_APP_VERSION}
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={logo}
                alt="OptiOffer logo"
                className={classes.logoSmall}
                onClick={() => {
                  context.navigate('/');
                }}
              />
            </Grid>
            <Grid item className={classes.socialIconContainer}>
              <a
                className={classes.socialIcon}
                href="https://www.linkedin.com/company/optioffer/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon />
              </a>
              <a
                className={classes.socialIcon}
                href="https://www.youtube.com/channel/UCyJZcY5SnI8UJT993PFS57Q"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeIcon />
              </a>
            </Grid>
          </Grid>
        </footer>
      </div>

      {chat}
    </div>
  );
};

export default Layout;
