import { ErrorWithMessage } from '@lib';
import React, { FC } from 'react';

import ErrorSnackbar from '@components/Feedback/ErrorSnackbar';

type ApolloSnackbarErrorsProps = {
  errors: ErrorWithMessage[];
};

const ApolloSnackbarErrors: FC<ApolloSnackbarErrorsProps> = ({ errors }) => {
  function renderError(errorWithMessage: ErrorWithMessage) {
    if (!errorWithMessage.error) return null;
    return (
      <ErrorSnackbar
        key={JSON.stringify(errorWithMessage.error)}
        message={errorWithMessage.message}
      />
    );
  }

  return <>{errors.map(renderError)}</>;
};

export default ApolloSnackbarErrors;
