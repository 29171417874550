import { LocalizationProvider } from 'localize-react';
import * as React from 'react';
import { en } from './en';
import { gr } from './gr';
import { hu } from './hu';
import { ro } from './ro';
var TranslationsProvider = function (_a) {
    var locale = _a.locale, children = _a.children;
    var translations = {
        en: en,
        gr: gr,
        ro: ro,
        hu: hu,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(LocalizationProvider, { locale: locale, translations: translations }, children)));
};
export default TranslationsProvider;
