export interface IResizeImageOptions {
  maxWidth: number;
  maxHeight: number;
  file: File;
  compressionRatio: number;
}

export const IMAGE_SIZE_FOR_PRODUCTS: Pick<
  IResizeImageOptions,
  'maxWidth' | 'maxHeight' | 'compressionRatio'
> = {
  maxHeight: 1754,
  maxWidth: 1240,
  compressionRatio: 0.7,
};

type ResizeImageReturnType = { blob: Blob; dataUrl: string };

export const resizeImage = (
  settings: IResizeImageOptions
): Promise<ResizeImageReturnType> => {
  const file = settings.file;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };
  const resize = (): ResizeImageReturnType => {
    const ratioWidth = image.width / settings.maxWidth; // width and height are maximum thumbnail's bounds
    const ratioHeight = image.height / settings.maxHeight;

    let finalWidth = Math.round(
      image.width / Math.max(ratioHeight, ratioWidth)
    );
    let finalHeight = Math.round(
      image.height / Math.max(ratioHeight, ratioWidth)
    );

    canvas.width = finalWidth;
    canvas.height = finalHeight;
    canvas?.getContext('2d')?.drawImage(image, 0, 0, finalWidth, finalHeight);
    const dataUrl = canvas.toDataURL('image/jpeg', settings.compressionRatio);
    return { dataUrl, blob: dataURItoBlob(dataUrl) };
  };

  return new Promise<ResizeImageReturnType>((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error('Not an image'));
      return undefined;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => resolve(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};
