import { NumberFormatInput, OptiInlineTextField } from '@components';
import {
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  useIsMobile,
} from '@lib';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import PanoramaIcon from '@material-ui/icons/Panorama';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';

import { formatPrice } from '@optioffer/core';
import { OfferItemInOfferModuleFragment } from '@optioffer/graphql';

import useStyles from '../styles';

const useLocalStyles = makeStyles<Theme, { numberOfChildren?: number }>(
  (theme) => ({
    productName: {
      fontSize: theme.typography.pxToRem(14),
      opacity: '85%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    productCode: {
      fontSize: theme.typography.pxToRem(12),
      opacity: '50%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    productQuantity: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 'bold',
    },
    placeholderIcon: {
      '& > svg': {
        fontSize: '3rem',
        opacity: '30%',
      },
    },
    productIcon: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    draggingItem: {
      opacity: '10%',
      cursor: 'grabbing !important',
    },
    dragIndicator: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'grab',
    },
    summaryRow: {
      backgroundColor: '#FAF9F8',
    },
    summaryRowDesktop: {
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FAF9F8',
        cursor: 'pointer',
      },
    },
    rowWithChildren: {
      '&::after': {
        height: ({ numberOfChildren }) =>
          `${((numberOfChildren ?? 0) + 1) * 100}%`,
      },
    },
    quantityBox: {
      width: 55,
      alignSelf: 'center',
    },
  })
);

enum DragItemType {
  OFFER_ITEM = 'OFFER_ITEM',
  ACCESSORY = 'ACCESSORY',
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

type OfferItemListItemProps = {
  className?: string;
  item: OfferItemInOfferModuleFragment;
  index: number;
  numberOfChildren?: number;
  moveOfferItem: (dragIndex: number, hoverIndex: number) => Promise<any>;
  updateQuantity?: (newQuantity: number) => Promise<any>; // this is not used on mobile
  onMoveDone: () => void;
  onClick: (item: OfferItemInOfferModuleFragment) => Promise<any>;
  parentId?: OfferItemInOfferModuleFragment['id'];
};

const OfferItemListItem: FC<OfferItemListItemProps> = ({
  children,
  className,
  item,
  index,
  numberOfChildren,
  moveOfferItem,
  updateQuantity,
  onMoveDone,
  onClick,
  parentId,
}) => {
  const classes = useStyles();
  const localClasses = useLocalStyles({ numberOfChildren });
  const isAccessory = !!parentId;
  const isMobile = useIsMobile();

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: isAccessory
      ? `${DragItemType.ACCESSORY}_${parentId}`
      : DragItemType.OFFER_ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveOfferItem(dragIndex, hoverIndex).catch(NOOP_graphqlErrorManagement);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: onMoveDone,
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      id: item.id,
      index,
      type: isAccessory
        ? `${DragItemType.ACCESSORY}_${parentId}`
        : DragItemType.OFFER_ITEM,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      quantity: item.quantity,
    },
    onSubmit: async (values) => updateQuantity?.(values.quantity),
  });

  preview(drop(ref));
  return (
    <Box marginX={-2} className={clsx(isDragging && localClasses.draggingItem)}>
      <div
        ref={ref}
        className={clsx(
          className,
          classes.actionableRow,
          localClasses.rowWithChildren,
          isMobile ? localClasses.summaryRow : localClasses.summaryRowDesktop
        )}
        data-handler-id={handlerId}
      >
        <Box flex={isMobile ? 2 : 4} display="flex" minWidth={0}>
          <div ref={drag} className={localClasses.dragIndicator}>
            <DragIndicatorIcon />
          </div>
          <Box
            minWidth={54}
            maxWidth={54}
            maxHeight={60}
            marginRight={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => !isMobile && onClick(item)}
          >
            {item.product?.image?.thumbnail ? (
              <img
                className={localClasses.productIcon}
                src={item.product?.image?.thumbnail}
                alt="product"
              />
            ) : (
              <Box className={localClasses.placeholderIcon}>
                <PanoramaIcon />
              </Box>
            )}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            flex={1}
            minWidth={0}
            onClick={() => !isMobile && onClick(item)}
          >
            <Box className={localClasses.productName}>{item.product?.name}</Box>
            <Box className={localClasses.productCode}>{item.product?.code}</Box>
            {isMobile && (
              <Box className={localClasses.productQuantity}>
                x{item.quantity}
              </Box>
            )}
          </Box>
          {!isMobile && (
            <OptiInlineTextField
              className={localClasses.quantityBox}
              alignTextRight
              {...mapFormikToTextField(formik, 'quantity')}
              InputProps={{
                inputComponent: NumberFormatInput as any,
                inputProps: {
                  min: 0,
                  prefix: 'x',
                  onFocus: (e) => {
                    requestAnimationFrame(() => {
                      e.target.select();
                    });
                  },
                },
              }}
              onBlur={() => formik.dirty && formik.submitForm()}
            />
          )}
        </Box>

        {item.pricing && (
          <>
            {isMobile && (
              <>
                <Box>{item.pricing.offerItemCurrency.symbol}</Box>
                <Box flex={1} textAlign="right">
                  {formatPrice(
                    item.pricing.listPriceTotal,
                    item.pricing.offerItemCurrency,
                    true
                  )}
                </Box>
              </>
            )}
            {!isMobile && (
              <Box
                display={'flex'}
                flexDirection={'row'}
                flex={1}
                minWidth={0}
                onClick={() => !isMobile && onClick(item)}
              >
                <Box flex={1} paddingLeft={2}>
                  {item.pricing.offerItemCurrency.symbol}
                </Box>
                <Box flex={1} textAlign="right">
                  {formatPrice(
                    item.pricing.listPriceTotal,
                    item.pricing.offerItemCurrency,
                    true
                  )}
                </Box>
              </Box>
            )}
          </>
        )}
        {isMobile && (
          <Box marginRight={-1.5}>
            <IconButton color="primary" onClick={() => onClick(item)}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        )}
      </div>

      {children}
    </Box>
  );
};

export default OfferItemListItem;
