import {
  mapFormikToButtonSwitchField,
  mapFormikToTextField,
  ModalControl,
  useEnhancedMutation,
} from '@lib';
import { Button, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import { Auth_SaveNpsResponseDocument } from '@optioffer/graphql';

import ButtonSwitchInput from '@components/form/ButtonSwitchInput';
import TextField from '@components/form/TextField';
import OOModal from '@components/modals/OOModal';

const useLocalStyles = makeStyles((theme) => ({
  root: {},
  header: {
    alignText: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(26),
    color: theme.palette.primary.main,
  },
}));

type NPSSurveyModalProps = {
  control: ModalControl;
};

const npsScoreOptions = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
];

const NPSSurveyModal: FC<NPSSurveyModalProps> = ({ control }) => {
  const localClasses = useLocalStyles();
  // const classes = useStyles();
  // const { push } = useHistory();
  // const isMobile = useIsMobile();

  const [saveNPS] = useEnhancedMutation(Auth_SaveNpsResponseDocument);

  const formik = useFormik({
    initialValues: {
      score: null as null | number,
      comment: '',
    },
    onSubmit: async (values) => {
      await saveNPS({
        variables: {
          score: values.score ?? 0,
          comment: values.comment,
        },
      });

      control.close();
    },
  });

  return (
    <OOModal
      open={control.isOpen}
      onClose={control.close}
      title=""
      minWidth="sm"
      keepMounted={false}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box className={clsx()} padding={2}>
          <Box className={localClasses.header} marginBottom={4}>
            How likely are you to recommend OptiOffer to a friend?
          </Box>
          <ButtonSwitchInput
            {...mapFormikToButtonSwitchField(formik, 'score')}
            id="NPSSurvey"
            options={npsScoreOptions}
          />
          <Box display="flex" justifyContent="space-between">
            <Box>0 - not at all likely</Box>
            <Box>10 - extremely likely</Box>
          </Box>
          <Box paddingTop={4}>
            {formik.values.score !== null ? (
              <TextField
                {...mapFormikToTextField(formik, 'comment')}
                multiline
                rows={3}
                label={
                  formik.values.score! < 7
                    ? 'How can we improve your experience at OptiOffer?'
                    : formik.values.score! < 9
                    ? 'What can we do better at OptiOffer?'
                    : 'What do you like most about OptiOffer?'
                }
              />
            ) : null}
          </Box>
        </Box>
        <Box padding={2}>
          <Box width="100%" marginTop={3.5}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={formik.values.score === null}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </OOModal>
  );
};

export default NPSSurveyModal;
