import { LinearProgress } from '@material-ui/core';
import React, { FC } from 'react';

import FullScreenCircularProgress from '@components/Feedback/FullScreenCircularProgress';

import { useApolloNetworkStatus } from '../../apolloClient';
import useStyles from './styles';

type LoadingIndicatorProps = {};

const LoadingIndicator: FC<LoadingIndicatorProps> = () => {
  const { linearProgress } = useStyles();
  const { numPendingQueries, numPendingMutations } = useApolloNetworkStatus();

  return (
    <>
      {numPendingQueries > 0 && <LinearProgress className={linearProgress} />}

      <FullScreenCircularProgress open={numPendingMutations > 0} />
    </>
  );
};

export default LoadingIndicator;
