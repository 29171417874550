import { SuccessSnackbar } from '@dvkiin/material-commons';
import {
  IMAGE_SIZE_FOR_PRODUCTS,
  resizeImage,
  useEnhancedLazyQuery,
  useEnhancedMutation,
} from '@lib';
import { IconButton, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { ApolloModalErrors, ApolloSnackbars } from '@components/Feedback';
import { HeavyDutyResult } from '@components/Feedback/domain';

import {
  BULK_DELETE_PRODUCTS,
  BULK_UPDATE_PRODUCTS,
  PRODUCT_IMPORT,
  PRODUCT_IMPORT_IMAGE,
  SIMULATE_IMPORT,
  VALIDATE_BULK_DELETE_PRODUCTS,
  VALIDATE_BULK_UPDATE_PRODUCTS,
  VALIDATE_IMAGE_IMPORT,
} from '../graphql';
import useStyles from '../styles';
import ImportSection from './sections';

type ProductListPageProps = {};

type LoadingInformation = {
  key: string;
  active: boolean;
  currentItem: number;
  totalItems: number;
  message: string;
};

const ProductImportPage: FC<ProductListPageProps> = () => {
  const { backButton, headerContainer } = useStyles();
  const { push } = useHistory();

  const [
    loadingInformation,
    setLoadingInformation,
  ] = useState<LoadingInformation>({
    key: '0',
    active: false,
    currentItem: 0,
    totalItems: 0,
    message: 'No loading yet',
  });

  //region queries
  const [
    simulateImport,
    {
      data: { simulateHeavyDuty: simulateImportResult } = {
        simulateHeavyDuty: undefined,
      },
      error: simulateImportError,
    },
  ] = useEnhancedLazyQuery<{
    simulateHeavyDuty: { importProducts: HeavyDutyResult[] };
  }>(SIMULATE_IMPORT, { fetchPolicy: 'network-only' });
  const [
    validateBulkUpdateProducts,
    {
      data: { simulateHeavyDuty: validateBulkUpdateProductsResult } = {
        simulateHeavyDuty: undefined,
      },
      error: validateBulkUpdateProductsError,
    },
  ] = useEnhancedLazyQuery<{
    simulateHeavyDuty: { validateBulkUpdateProducts: HeavyDutyResult[] };
  }>(VALIDATE_BULK_UPDATE_PRODUCTS, {
    fetchPolicy: 'network-only',
  });

  const [
    validateImageImport,
    {
      data: { simulateHeavyDuty: validateImageImportResult } = {
        simulateHeavyDuty: undefined,
      },
      error: validateImageImportError,
    },
  ] = useEnhancedLazyQuery<{
    simulateHeavyDuty: { validateImages: HeavyDutyResult[] };
  }>(VALIDATE_IMAGE_IMPORT, { fetchPolicy: 'network-only' });
  const [
    validateBulkDeleteProducts,
    {
      data: { simulateHeavyDuty: validateBulkDeleteProductsResult } = {
        simulateHeavyDuty: undefined,
      },
      error: validateBulkDeleteProductsError,
    },
  ] = useEnhancedLazyQuery<{
    simulateHeavyDuty: { validateBulkDeleteProducts: HeavyDutyResult[] };
  }>(VALIDATE_BULK_DELETE_PRODUCTS, {
    fetchPolicy: 'network-only',
  });
  //endregion

  //region mutations
  const [
    importProducts,
    { data: importResult, error: importError },
  ] = useEnhancedMutation<{ heavyDuty: { importProducts: HeavyDutyResult[] } }>(
    PRODUCT_IMPORT,
    { refetchQueries: ['findAllProducts'] }
  );
  const [
    bulkUpdateProducts,
    { data: bulkUpdateProductsResult, error: bulkUpdateProductsError },
  ] = useEnhancedMutation<{
    heavyDuty: { bulkUpdateProducts: HeavyDutyResult[] };
  }>(BULK_UPDATE_PRODUCTS, { refetchQueries: ['findAllProducts'] });
  const [
    importProductImage,
    { data: importProductImageResult, error: importProductImageError },
  ] = useEnhancedMutation<{
    heavyDuty: { importProductImage: HeavyDutyResult[] };
  }>(PRODUCT_IMPORT_IMAGE);
  const [
    bulkDeleteProducts,
    { data: bulkDeleteProductsResult, error: bulkDeleteProductsError },
  ] = useEnhancedMutation<{
    heavyDuty: { bulkDeleteProducts: HeavyDutyResult[] };
  }>(BULK_DELETE_PRODUCTS, { refetchQueries: ['findAllProducts'] });
  //endregion

  //region errors
  const errors = useMemo(
    () => [
      {
        error: simulateImportError,
        message:
          'There was an error while analysing your import file. Please check that the file you selected is valid and try again.',
      },
      {
        error: importError,
        message:
          'There was an error while importing your products. Please check that the file you selected is valid and try again.',
      },
      {
        error: importProductImageError,
        message:
          'There was an error while importing your product images. Please try again.',
      },
      {
        error: validateImageImportError,
        message:
          'There was an error while validating your product images. Please try again.',
      },
      {
        error: validateBulkUpdateProductsError,
        message:
          'There was an error while validating your multiple product upload. Please try again.',
      },
      {
        error: bulkUpdateProductsError,
        message:
          'There was an error while performing the multiple product update.',
      },
      {
        error: validateBulkDeleteProductsError,
        message:
          'There was an error while validating your multiple product delete. Please try again.',
      },
      {
        error: bulkDeleteProductsError,
        message:
          'There was an error while performing the multiple product delete.',
      },
    ],
    [
      simulateImportError,
      importError,
      validateImageImportError,
      importProductImageError,
      validateBulkUpdateProductsError,
      bulkUpdateProductsError,
      validateBulkDeleteProductsError,
      bulkDeleteProductsError,
    ]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      { result: simulateImportResult, message: 'Analysis complete.' },
      {
        result: validateImageImportResult,
        message: 'Product image import validation complete.',
      },
      { result: importResult, message: 'Product import was successful.' },
      {
        result: importProductImageResult,
        message: 'Product image import was successful.',
      },
      {
        result: validateBulkUpdateProductsResult,
        message: 'Product multiple update validation was successful.',
      },
      {
        result: bulkUpdateProductsResult,
        message: 'Product multiple update successful.',
      },
      {
        result: validateBulkDeleteProductsResult,
        message: 'Product multiple delete validation was successful.',
      },
      {
        result: bulkDeleteProductsResult,
        message: 'Product multiple delete successful.',
      },
    ],
    [
      simulateImportResult,
      importResult,
      validateImageImportResult,
      importProductImageResult,
      validateBulkUpdateProductsResult,
      bulkUpdateProductsResult,
      validateBulkDeleteProductsResult,
      bulkDeleteProductsResult,
    ]
  );
  //endregion

  function blobToFile(theBlob: Blob, fileName: string): File {
    return new File([theBlob], fileName, {
      type: 'image/jpeg',
      lastModified: Date.now(),
    });
  }

  async function handleSimulateProductImport(
    file: File
  ): Promise<HeavyDutyResult[]> {
    const { data, error } = await simulateImport({ variables: { file } });
    if (!data) throw error;
    return data.simulateHeavyDuty.importProducts;
  }

  async function handleValidateBulkUpdateProducts(
    file: File
  ): Promise<HeavyDutyResult[]> {
    const { data, error } = await validateBulkUpdateProducts({
      variables: { file },
    });
    if (!data) throw error;
    return data.simulateHeavyDuty.validateBulkUpdateProducts;
  }

  async function handleBulkUpdateProducts(
    file: File
  ): Promise<HeavyDutyResult[]> {
    const { data, errors } = await bulkUpdateProducts({ variables: { file } });
    if (errors) throw errors;
    return data!.heavyDuty.bulkUpdateProducts;
  }

  async function handleValidateImageImport(
    files: File[]
  ): Promise<HeavyDutyResult[]> {
    const { data, error } = await validateImageImport({
      variables: {
        imageNames: files.map((file) => file.name),
      },
    });
    if (!data) throw error;
    return data.simulateHeavyDuty.validateImages;
  }

  async function handleImportProductImages(
    files: File[]
  ): Promise<HeavyDutyResult[]> {
    setLoadingInformation({
      key: '1',
      active: true,
      currentItem: 0,
      totalItems: files.length,
      message: `Preparing product images for import...`,
    });

    let resp = await Promise.all(
      files.map((pic) => {
        return resizeImage({
          file: pic,
          ...IMAGE_SIZE_FOR_PRODUCTS,
        })
          .then((resizedImage) => blobToFile(resizedImage.blob, pic.name))
          .then((fileResult) => {
            setLoadingInformation((loadingInformation: LoadingInformation) => ({
              key: `${fileResult.name}_${loadingInformation.currentItem}`,
              active: true,
              currentItem: loadingInformation.currentItem + 1,
              totalItems: loadingInformation.totalItems,
              message: loadingInformation.message,
            }));
            return fileResult;
          });
      })
    );

    setLoadingInformation({ ...loadingInformation, currentItem: 0 });

    const results = await Promise.all(
      resp.map((file) =>
        importProductImage({ variables: { file } }).then((result) => {
          setLoadingInformation((loadingInformation: LoadingInformation) => ({
            key: `${(result.data as any).timestamp}_${
              loadingInformation.currentItem
            }`,
            active: true,
            currentItem: loadingInformation.currentItem + 1,
            totalItems: loadingInformation.totalItems,
            message: `Importing product images...`,
          }));
          return result;
        })
      )
    );

    // todo refetch products

    setLoadingInformation({
      key: '',
      active: false,
      currentItem: 0,
      totalItems: 0,
      message: 'No loading',
    });

    return results
      .map((result) => result.data!.heavyDuty.importProductImage)
      .flat();
  }

  async function handleValidateBulkDeleteProducts(
    file: File
  ): Promise<HeavyDutyResult[]> {
    const { data, error } = await validateBulkDeleteProducts({
      variables: { file },
    });
    if (!data) throw error;
    return data.simulateHeavyDuty.validateBulkDeleteProducts ?? [];
  }

  async function handleBulkDeleteProducts(
    file: File
  ): Promise<HeavyDutyResult[]> {
    const { data, errors } = await bulkDeleteProducts({ variables: { file } });
    if (errors) throw errors;
    return data!.heavyDuty.bulkDeleteProducts;
  }

  async function handleImportProducts(file: File): Promise<HeavyDutyResult[]> {
    const { data, errors } = await importProducts({ variables: { file } });
    if (errors) throw errors;
    return data!.heavyDuty.importProducts;
  }

  return (
    <>
      <Typography variant="h4" className={headerContainer}>
        <IconButton className={backButton} onClick={() => push('/product')}>
          <BackIcon />
        </IconButton>
        Import products and bulk operations
      </Typography>

      <ImportSection
        importProductImages={handleImportProductImages}
        simulateImport={handleSimulateProductImport}
        importProducts={handleImportProducts}
        validateImageImport={handleValidateImageImport}
        validateBulkUpdateProducts={handleValidateBulkUpdateProducts}
        bulkUpdateProducts={handleBulkUpdateProducts}
        validateBulkDeleteProducts={handleValidateBulkDeleteProducts}
        bulkDeleteProducts={handleBulkDeleteProducts}
      />

      {loadingInformation && loadingInformation.active && (
        <SuccessSnackbar
          key={loadingInformation.key}
          message={`${loadingInformation.message} ${loadingInformation.currentItem}/${loadingInformation.totalItems}`}
          action={() => {}}
        />
      )}
      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default ProductImportPage;
