var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Document, Page, pdf, Text } from '@react-pdf/renderer';
import React, { Fragment, useCallback, useContext } from 'react';
import TranslationsProvider from './i18n/TranslationsProvider';
export var OfferPrintContext = React.createContext({
    isDev: true,
    offer: {
        id: 'Offer id',
        client: undefined,
        displayDiscounts: true,
        ownedByCompany: {
            id: 'Missing company id',
            name: 'Missing company',
            email: 'Missing company email',
            address: 'Missing company address',
            addressSecondary: 'Missing company addressSecondary',
            website: 'Missing company website',
            phoneNumber: 'Missing company phoneNumber',
            motto: 'Missing company motto',
            vat: 'Missing company vat id',
            companyLogo: {},
            companyIntro: 'Missing company intro',
            currency: {},
        },
        createdByUser: {
            id: 'Missing author id',
            name: 'Missing author name',
            title: 'Missing author title',
            email: 'Missing author email',
        },
        expireAt: -1,
        offerItems: [],
        createdAt: -1,
        pricing: {
            netPrice: 0,
            taxesAfterDiscount: [],
            taxesBeforeDiscount: [],
            taxesFinal: [],
            listPrice: 0,
            offerCurrency: {
                id: 'EUR',
                name: 'EUR',
                symbol: '€',
            },
        },
        addons: [],
        companyIntro: 'Missing company intro',
        termsAndConditions: 'Missing terms and conditions',
        // template: BasicLayoutVariant.DETAILED,
        // language: 'en',
    },
    resources: {
        optiOfferLogo: 'invalid url',
        placeholderLogo: 'invalid url',
        userPlaceholderLogo: 'invalid url',
    },
});
export var useDevColor = function () {
    var isDev = useContext(OfferPrintContext).isDev;
    return useCallback(function (color) {
        return isDev && color
            ? {
                backgroundColor: isDev && color,
            }
            : {};
    }, [isDev]);
};
export var pdfIf = function (condition, renderFn) {
    if (condition)
        return renderFn();
    else
        return React.createElement(Fragment, null);
};
var DocumentLayoutErrorBoundary = /** @class */ (function (_super) {
    __extends(DocumentLayoutErrorBoundary, _super);
    function DocumentLayoutErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false };
        return _this;
    }
    DocumentLayoutErrorBoundary.getDerivedStateFromError = function (error) {
        console.error('Document layouting failed with: ', error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    };
    // componentDidCatch(error, errorInfo) {
    //   // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    // }
    DocumentLayoutErrorBoundary.prototype.render = function () {
        if (this.state.hasError) {
            var errorMessage = (React.createElement(Text, null, "There was an error rendering this section."));
            if (this.props.global) {
                return (React.createElement(Document, null,
                    React.createElement(Page, null, errorMessage)));
            }
            return errorMessage;
        }
        return this.props.children;
    };
    return DocumentLayoutErrorBoundary;
}(React.Component));
export { DocumentLayoutErrorBoundary };
export function render(offer, Layout, resources, variant, locale) {
    return __awaiter(this, void 0, void 0, function () {
        var renderedOffer, blob;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    renderedOffer = (React.createElement(DocumentLayoutErrorBoundary, { global: true },
                        React.createElement(TranslationsProvider, { locale: locale },
                            React.createElement(OfferPrintContext.Provider, { value: { offer: offer, isDev: false, resources: resources } },
                                React.createElement(Layout, { variant: variant })))));
                    return [4 /*yield*/, pdf(renderedOffer).toBlob()];
                case 1:
                    blob = _a.sent();
                    return [2 /*return*/, URL.createObjectURL(blob)];
            }
        });
    });
}
