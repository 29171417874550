import { ITax } from '@optioffer/core';
import { UserRole } from '@optioffer/graphql';

type Status = 'trialing' | 'active' | 'past_due' | 'incomplete' | 'canceled';
const USER_ROLE_IMPORTANCE: UserRole[] = [
  UserRole.USER,
  UserRole.MANAGER,
  UserRole.OWNER,
];

export function getMostImportantRole(roles: UserRole[]): UserRole {
  return roles
    .map((role) => [USER_ROLE_IMPORTANCE.indexOf(role), role])
    .sort()
    .reverse()[0][1] as UserRole;
}

export type Subscription = {
  id: string;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  cancelAtPeriodEnd: boolean;
  status: Status;
  plan: Plan;
  nextActionSecret?: string;
};

export type SubscriptionStatus = Pick<Subscription, 'id' | 'status' | 'plan'>;

export type Company = {
  id?: string;
  name: string;
  email: string;
  address: string;
  addressSecondary: string;
  website: string;
  phoneNumber: string;
  motto: string;
  vat: string;
  companyLogo: any;
  companyIntro?: string;
  companyTerms?: string;
  companyThankYouMessage?: string;
  costAndMarginsOnlyForManagers: boolean;
  currency: Currency;
  defaultTaxes: ITax[];
  smartBillApiKey: string;
  smartBillEmail?: string;
  defaultOfferLanguage: string;
  gDriveTemplateFile?: string;
  gDriveTargetFolder?: string;
  gDriveEveryProduct?: boolean;
};

export type CompanyUpdateInput = {
  name: string;
  email: string;
  address: string;
  addressSecondary: string;
  website: string;
  phoneNumber: string;
  motto: string;
  vat: string;
  companyLogo: any;
  currencyKey: string;
  costAndMarginsOnlyForManagers: boolean;
};

export type CompanyUpdateOfferSettingsInput = {
  companyIntro: string;
  companyTerms: string;
  companyThankYouMessage: string;
  defaultOfferAvailability: number;
  defaultOfferLanguage: string;
  defaultOfferFormat: string;
};

export interface Currency {
  name: string;
  symbol: string;
  id: string;
}

export type UserUpdateRoleInput = {
  role: UserRole;
  enabled: boolean;
};

export type CompanyUser = {
  id: string;
  name: string;
  email: string;
  roles: UserRole[];
};

export type PendingInvitation = {
  id: string;
  email: string;
  expiresAt: number;
};

export type Quota = {
  name: string;
  color?: string;

  current: number;
  total: number;
  measuringUnit?: string;
};

export type Plan = {
  id: string;
  name: string;
  description: string;
  price: number;
  duration: number;
  usersLimit: number;
  productsLimit: number;
  offersLimit: number;
  hasTrial: boolean;
  isPricePerUser: boolean;
};

export type BillingDetails = {
  value: string;
  countryCode: string;
  type: string;
};

export type CompanyQuotas = {
  nrProducts: number;
  nrOffers: number;
  nrUsers: number;
};
