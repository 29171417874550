import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Message } from 'localize-react';
import React, { Fragment, useContext } from 'react';
import { formatDate } from '@optioffer/core';
import { ooBrand } from '@optioffer/core';
import { OfferPrintContext, useDevColor } from '../../helpers';
import { commonStyles } from '../style';
var headerStyles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        height: '50mm',
        marginVertical: '5mm',
        justifyContent: 'space-between',
    },
    headerCompact: {
        height: '15mm',
    },
    companyBox: {
        flexDirection: 'column',
        width: '60mm',
        height: '100%',
        borderColor: ooBrand.colors.secondary['200'],
        borderWidth: 1,
        borderLeft: 0,
        borderTop: 0,
        paddingRight: '2mm',
        paddingBottom: '2mm',
        fontSize: '9pt',
    },
    logoBox: {
        flexDirection: 'column',
        width: '80mm',
        height: '100%',
    },
    companyLogo: {
        width: '100%',
        height: '40mm',
    },
    logo: {
        objectFit: 'contain',
    },
    offerDetails: {
        flexDirection: 'column',
        width: '100%',
        height: '10mm',
        textAlign: 'center',
    },
    companyBoxCompanyName: {
        fontSize: '13pt',
        fontWeight: 'bold',
    },
    companyBoxUserName: {
        marginTop: '3mm',
    },
});
var clientBoxStyles = StyleSheet.create({
    clientBox: {
        flexDirection: 'column',
        width: '60mm',
        height: '100%',
        borderColor: ooBrand.colors.secondary['200'],
        borderWidth: 1,
        borderRight: 0,
        borderTop: 0,
        paddingLeft: '2mm',
        paddingBottom: '2mm',
        fontSize: '9pt',
    },
    vatID: {
        marginBottom: '3mm',
    },
});
var Header = function (_a) {
    var _b;
    var _c = _a.compact, compact = _c === void 0 ? false : _c;
    var _d = useContext(OfferPrintContext), _e = _d.offer, id = _e.id, createdAt = _e.createdAt, expireAt = _e.expireAt, ownedByCompany = _e.ownedByCompany, createdByUser = _e.createdByUser, client = _e.client, placeholderLogo = _d.resources.placeholderLogo;
    var devColor = useDevColor();
    function renderClientBox() {
        return (React.createElement(View, { style: [clientBoxStyles.clientBox, devColor('#66bb6a')] }, client ? (React.createElement(React.Fragment, null,
            React.createElement(Text, { style: [
                    commonStyles.p,
                    commonStyles.h,
                    !client.companyVatId ? clientBoxStyles.vatID : {},
                ] }, client.companyName || client.name),
            client.companyVatId ? (React.createElement(Text, { style: [
                    commonStyles.p,
                    clientBoxStyles.vatID,
                    compact ? commonStyles.compact : {},
                ] },
                React.createElement(Message, { descriptor: "global.vatId", values: { id: client.companyVatId } }))) : (React.createElement(Fragment, null)),
            compact ? (React.createElement(Fragment, null)) : (React.createElement(React.Fragment, null,
                client.companyName ? (React.createElement(Text, { style: [commonStyles.p] }, client.name)) : (React.createElement(Fragment, null)),
                React.createElement(Text, { style: [commonStyles.p] }, client.email),
                React.createElement(Text, { style: [commonStyles.p] }, client.phoneNumber))))) : (React.createElement(Fragment, null))));
    }
    return (React.createElement(View, { fixed: true, style: [
            headerStyles.header,
            compact ? headerStyles.headerCompact : {},
            devColor('#fff'),
        ] },
        React.createElement(View, { style: [headerStyles.companyBox, devColor('#8e24aa')] },
            React.createElement(Text, { style: [commonStyles.p, commonStyles.h] }, ownedByCompany.name),
            ownedByCompany.vat ? (React.createElement(Text, { style: [commonStyles.p, compact ? commonStyles.compact : {}] },
                React.createElement(Message, { descriptor: "global.vatId", values: { id: ownedByCompany.vat } }))) : (React.createElement(Fragment, null)),
            compact ? (React.createElement(Fragment, null)) : (React.createElement(React.Fragment, null,
                React.createElement(Text, { style: [commonStyles.p, headerStyles.companyBoxUserName] }, createdByUser.name),
                React.createElement(Text, { style: [commonStyles.p] }, createdByUser.email),
                React.createElement(Text, { style: [commonStyles.p] }, createdByUser.phoneNumber)))),
        React.createElement(View, { style: [headerStyles.logoBox, devColor('#29b6f6')] },
            compact ? (React.createElement(Fragment, null)) : (React.createElement(View, { style: headerStyles.companyLogo },
                React.createElement(Image, { style: headerStyles.logo, src: ((_b = ownedByCompany.companyLogo) === null || _b === void 0 ? void 0 : _b.fullImage) || placeholderLogo }))),
            React.createElement(View, { style: headerStyles.offerDetails },
                React.createElement(Text, null,
                    React.createElement(Message, { descriptor: "global.offerId", values: { id: id } })),
                React.createElement(Text, null,
                    React.createElement(Message, { descriptor: "global.validity", values: {
                            validity: formatDate(createdAt) + " - " + formatDate(expireAt),
                        } })))),
        renderClientBox()));
};
export default Header;
