import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Message } from 'localize-react';
import React, { useContext } from 'react';
import { CalculationType } from '@optioffer/core';
import { formatDiscount, formatPrice } from '@optioffer/core';
import { ooBrand } from '@optioffer/core';
import { OfferPrintContext, pdfIf, useDevColor } from '../helpers';
import { MUIIcon } from '../style';
import Footer from './snippets/Footer';
import Header from './snippets/Header';
import { commonStyles } from './style';
var bodyStyles = StyleSheet.create({
    intro: {
        flex: 1,
        marginHorizontal: '5mm',
        width: '190mm',
        marginBottom: '5mm',
    },
    //region Total
    total: {
        marginHorizontal: '5mm',
        width: '190mm',
        marginBottom: '5mm',
    },
    totalRow: {
        flexDirection: 'row',
    },
    totalLabel: {
        flex: 1,
    },
    totalPrice: {
        textAlign: 'left',
    },
    totalGrandTotalRow: {
        color: ooBrand.colors.primary['200'],
        fontSize: '17pt',
        fontWeight: 'bold',
        marginTop: '3mm',
    },
    totalGrandTotalLabel: {
        textAlign: 'right',
        marginRight: '30mm',
    },
    //endregion
    //region Agent
    agent: {
        marginHorizontal: '5mm',
        width: '190mm',
        height: '45mm',
        marginBottom: '5mm',
        padding: '2mm',
        borderColor: ooBrand.colors.secondary['200'],
        borderWidth: 1,
        borderRadius: 5,
        backgroundColor: ooBrand.colors.secondary['100'],
        flexDirection: 'row',
    },
    agentTitle: {
        fontSize: '10pt',
        marginTop: '0pt',
        color: ooBrand.colors.gray['700'],
    },
    agentThumbnail: {
        width: '41mm',
        borderRadius: '20.5mm',
        objectFit: 'cover',
    },
    agentDetails: {
        paddingLeft: '5mm',
    },
    agentDetailsName: {
        fontSize: '15pt',
        marginBottom: '3mm',
    },
    agentDetailsContact: {
        fontSize: '13pt',
        marginTop: '2mm',
        marginBottom: '1mm',
        flexDirection: 'row',
        alignItems: 'center',
    },
    agentDetailsContactIcon: {
        fontSize: '20pt',
        color: ooBrand.colors.primary['200'],
        paddingRight: '3mm',
    },
    //endregion
    //region Company
    company: {
        marginHorizontal: '5mm',
        width: '190mm',
        height: '45mm',
        marginBottom: '5mm',
        padding: '2mm',
        borderColor: ooBrand.colors.secondary['200'],
        borderWidth: 1,
        borderRadius: 5,
        backgroundColor: ooBrand.colors.secondary['100'],
        flexDirection: 'row',
    },
    companyThumbnail: {
        width: '41mm',
        objectFit: 'contain',
    },
    companyDetails: {
        paddingLeft: '5mm',
    },
    companyDetailsContact: {
        fontSize: '12pt',
        marginVertical: '1mm',
        flexDirection: 'row',
        alignItems: 'center',
    },
    companyDetailsMotto: {
        fontSize: '10pt',
        marginTop: '0pt',
        color: ooBrand.colors.gray['700'],
    },
    //endregion
});
var TotalPage = function () {
    var _a = useContext(OfferPrintContext), _b = _a.offer, termsAndConditions = _b.termsAndConditions, ownedByCompany = _b.ownedByCompany, createdByUser = _b.createdByUser, pricing = _b.pricing, displayDiscounts = _b.displayDiscounts, addons = _b.addons, offerItems = _b.offerItems, _c = _a.resources, userPlaceholderLogo = _c.userPlaceholderLogo, placeholderLogo = _c.placeholderLogo;
    var devColor = useDevColor();
    function renderMessage() {
        return (React.createElement(View, { style: [bodyStyles.intro, devColor('#80deea')] },
            React.createElement(Text, { style: [commonStyles.p] }, termsAndConditions !== null && termsAndConditions !== void 0 ? termsAndConditions : ownedByCompany.companyThankYouMessage)));
    }
    function renderTotal() {
        var _a, _b, _c, _d;
        var listPrice = pricing.listPrice;
        var productAddons = offerItems.reduce(function (acc, item) {
            return acc +
                item.addons.reduce(function (acc, addon) { var _a; return acc + ((_a = addon === null || addon === void 0 ? void 0 : addon.pricing) === null || _a === void 0 ? void 0 : _a.listPrice); }, 0);
        }, 0);
        var listPriceBeforeAddons = pricing.listPrice -
            productAddons -
            addons.map(function (addon) { return addon.pricing.listPrice; }).reduce(function (a, b) { return a + b; }, 0);
        var hideDiscounts = !displayDiscounts &&
            !pricing.taxesBeforeDiscount.length &&
            !pricing.taxesAfterDiscount.length;
        if (hideDiscounts) {
            listPrice -= ((_a = pricing.discount) === null || _a === void 0 ? void 0 : _a.valueTotal) || 0;
            listPrice -= ((_b = pricing.discountFinal) === null || _b === void 0 ? void 0 : _b.valueTotal) || 0;
        }
        return (React.createElement(View, { style: [bodyStyles.total, devColor('#607D8B')] },
            pdfIf(addons.length, function () { return (React.createElement(View, { style: [commonStyles.p, commonStyles.h, bodyStyles.totalRow] },
                React.createElement(Text, { style: [bodyStyles.totalLabel] },
                    React.createElement(Message, { descriptor: "lastPage.listPrice" })),
                React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(listPriceBeforeAddons, pricing.offerCurrency)))); }),
            pdfIf(addons.length && productAddons > 0, function () { return (React.createElement(View, { style: [commonStyles.p, commonStyles.h, bodyStyles.totalRow] },
                React.createElement(Text, { style: [bodyStyles.totalLabel] },
                    React.createElement(Message, { descriptor: "lastPage.productAddonsTotal" })),
                React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(productAddons, pricing.offerCurrency)))); }),
            pdfIf(addons.length <= 2, function () { return (React.createElement(React.Fragment, null, addons.map(function (addon) { return (React.createElement(View, { key: addon.id, style: [commonStyles.p, commonStyles.h, bodyStyles.totalRow] },
                React.createElement(Text, { style: [bodyStyles.totalLabel] }, addon.name),
                React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(addon.pricing.listPrice, pricing.offerCurrency)))); }))); }),
            pdfIf(addons.length > 2, function () { return (React.createElement(View, { style: [commonStyles.p, commonStyles.h, bodyStyles.totalRow] },
                React.createElement(Text, { style: [bodyStyles.totalLabel] },
                    React.createElement(Message, { descriptor: "lastPage.addonsTotal" })),
                React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(addons
                    .map(function (addon) { return addon.pricing.listPrice; })
                    .reduce(function (acc, it) { return acc + it; }, 0), pricing.offerCurrency, false)))); }),
            React.createElement(View, { style: [commonStyles.p, commonStyles.h, bodyStyles.totalRow] },
                React.createElement(Text, { style: [bodyStyles.totalLabel] },
                    React.createElement(Message, { descriptor: "lastPage.totalListPrice" })),
                React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(listPrice, pricing.offerCurrency))),
            pricing.taxesBeforeDiscount.map(function (beforeDiscountTax) { return (React.createElement(View, { key: beforeDiscountTax.tax.id, style: [commonStyles.p, commonStyles.h, bodyStyles.totalRow] },
                React.createElement(Text, { style: [bodyStyles.totalLabel] },
                    beforeDiscountTax.tax.name,
                    beforeDiscountTax.tax.calculationType ===
                        CalculationType.PERCENTAGE
                        ? " (" + formatDiscount(beforeDiscountTax.tax.value) + ")"
                        : ''),
                React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(beforeDiscountTax.valueTotal, pricing.offerCurrency)))); }),
            pdfIf(!hideDiscounts && ((_c = pricing.discount) === null || _c === void 0 ? void 0 : _c.valueTotal), function () {
                var _a;
                return (React.createElement(View, { style: [commonStyles.p, commonStyles.h, bodyStyles.totalRow] },
                    React.createElement(Text, { style: [bodyStyles.totalLabel] },
                        React.createElement(Message, { descriptor: "lastPage.cumulativeDiscount" })),
                    React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(-((_a = pricing.discount) === null || _a === void 0 ? void 0 : _a.valueTotal), pricing.offerCurrency))));
            }),
            pdfIf(!hideDiscounts && ((_d = pricing.discountFinal) === null || _d === void 0 ? void 0 : _d.valueTotal), function () { return (React.createElement(View, { style: [commonStyles.p, commonStyles.h, bodyStyles.totalRow] },
                React.createElement(Text, { style: [bodyStyles.totalLabel] },
                    React.createElement(Message, { descriptor: "lastPage.additionalDiscount", values: {
                            discount: formatDiscount(pricing.discountFinal.discount.value),
                        } })),
                React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(-pricing.discountFinal.valueTotal, pricing.offerCurrency)))); }),
            pricing.taxesAfterDiscount.map(function (afterDiscountTax) { return (React.createElement(View, { key: afterDiscountTax.tax.id, style: [commonStyles.p, commonStyles.h, bodyStyles.totalRow] },
                React.createElement(Text, { style: [bodyStyles.totalLabel] },
                    afterDiscountTax.tax.name,
                    afterDiscountTax.tax.calculationType ===
                        CalculationType.PERCENTAGE
                        ? " (" + formatDiscount(afterDiscountTax.tax.value) + ")"
                        : ''),
                React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(afterDiscountTax.valueTotal, pricing.offerCurrency)))); }),
            pricing.taxesFinal.map(function (finalTax) { return (React.createElement(View, { key: finalTax.tax.id, style: [commonStyles.p, commonStyles.h, bodyStyles.totalRow] },
                React.createElement(Text, { style: [bodyStyles.totalLabel] },
                    finalTax.tax.name,
                    finalTax.tax.calculationType === CalculationType.PERCENTAGE
                        ? " (" + formatDiscount(finalTax.tax.value) + ")"
                        : ''),
                React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(finalTax.valueTotal, pricing.offerCurrency)))); }),
            React.createElement(View, { style: [
                    commonStyles.p,
                    bodyStyles.totalRow,
                    bodyStyles.totalGrandTotalRow,
                ] },
                React.createElement(Text, { style: [bodyStyles.totalLabel, bodyStyles.totalGrandTotalLabel] },
                    React.createElement(Message, { descriptor: "lastPage.grandTotal" })),
                React.createElement(Text, { style: [bodyStyles.totalPrice] }, formatPrice(pricing.netPrice, pricing.offerCurrency)))));
    }
    function renderAgent() {
        var _a;
        return (React.createElement(View, { style: [bodyStyles.agent, devColor('#7B1FA2')] },
            React.createElement(Image, { style: [bodyStyles.agentThumbnail], src: ((_a = createdByUser.avatar) === null || _a === void 0 ? void 0 : _a.fullImage) || userPlaceholderLogo }),
            React.createElement(View, { style: [bodyStyles.agentDetails] },
                React.createElement(Text, { style: [
                        commonStyles.p,
                        commonStyles.h,
                        bodyStyles.agentDetailsName,
                    ] }, createdByUser.name),
                React.createElement(Text, { style: [commonStyles.p, bodyStyles.agentTitle] }, createdByUser.title),
                React.createElement(View, { style: [commonStyles.p, bodyStyles.agentDetailsContact] },
                    React.createElement(MUIIcon, { style: [bodyStyles.agentDetailsContactIcon], name: "mail" }),
                    React.createElement(Text, null, createdByUser.email)),
                createdByUser.phoneNumber ? (React.createElement(View, { style: [commonStyles.p, bodyStyles.agentDetailsContact] },
                    React.createElement(MUIIcon, { style: [bodyStyles.agentDetailsContactIcon], name: "phone" }),
                    React.createElement(Text, null, createdByUser.phoneNumber))) : null)));
    }
    function renderCompany() {
        var _a;
        return (React.createElement(View, { style: [bodyStyles.company, devColor('#f44336')] },
            React.createElement(Image, { style: [bodyStyles.companyThumbnail], src: ((_a = ownedByCompany.companyLogo) === null || _a === void 0 ? void 0 : _a.fullImage) || placeholderLogo }),
            React.createElement(View, { style: [bodyStyles.companyDetails] },
                React.createElement(Text, { style: [commonStyles.p, commonStyles.h] }, ownedByCompany.name),
                React.createElement(Text, { style: [commonStyles.p, bodyStyles.companyDetailsMotto] }, ownedByCompany.motto),
                ownedByCompany.website ? (React.createElement(View, { style: [commonStyles.p, bodyStyles.companyDetailsContact] },
                    React.createElement(MUIIcon, { style: [bodyStyles.agentDetailsContactIcon], name: "web" }),
                    React.createElement(Text, null, ownedByCompany.website))) : null,
                ownedByCompany.email ? (React.createElement(View, { style: [commonStyles.p, bodyStyles.companyDetailsContact] },
                    React.createElement(MUIIcon, { style: [bodyStyles.agentDetailsContactIcon], name: "email" }),
                    React.createElement(Text, null, ownedByCompany.email))) : null,
                ownedByCompany.phoneNumber ? (React.createElement(View, { style: [commonStyles.p, bodyStyles.companyDetailsContact] },
                    React.createElement(MUIIcon, { style: [bodyStyles.agentDetailsContactIcon], name: "phone" }),
                    React.createElement(Text, null, ownedByCompany.phoneNumber))) : null)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Page, { wrap: false, size: "A4", style: [commonStyles.page, devColor('#ccc')] },
            React.createElement(Header, null),
            renderMessage(),
            renderTotal(),
            renderAgent(),
            renderCompany(),
            React.createElement(Footer, null))));
};
export default TotalPage;
