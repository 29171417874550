import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import React, { FC, ReactNode, useState } from 'react';

export type ErrorSnackbarProps = {
  message: ReactNode;
  action?: ReactNode;
};

const ErrorSnackbar: FC<ErrorSnackbarProps> = ({ message, action }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar open={open} onClose={handleClose}>
      <Alert onClose={handleClose} action={action} severity="error">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
