import {
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useIsMobile,
} from '@lib';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  Auth_CurrentUserDocument,
  EmployeeExperienceOnProfilePageFragment,
  Profile_UpdateProfileDocument,
  ProfileOnProfilePageFragment,
} from '@optioffer/graphql';

import LoadingAwareButton from '@components/LoadingAwareButton';
import OOModal from '@components/modals/OOModal';

import { getQueryName } from '@lib/graphql';
import { ModalControl } from '@lib/materialCommons';

import addCustomerIllustration from '@resources/illustrations/add_customer.svg';

import useStyles from '../../NewOffer/styles';
import ExperienceCard from './ExperienceCard';
import { mapToProfileInput } from './helpers';

// const useExperienceModalStyles = makeStyles((theme) => ({
//   personalInfoModalIcon: {
//     opacity: '30%',
//     fontSize: '65px',
//     color: theme.palette.primary.main,
//   },
// }));

type ExperienceModalProps = {
  control: ModalControl<ProfileOnProfilePageFragment>;
};

const ExperienceModal: FC<ExperienceModalProps> = ({ control }) => {
  const classes = useStyles();
  // const localClasses = useExperienceModalStyles();
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      experience: [] as EmployeeExperienceOnProfilePageFragment[],
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (!control.data) return;

      setSubmitting(true);
      try {
        await updateProfile({
          variables: {
            id: control.data.id,
            profile: {
              ...mapToProfileInput(control.data),

              // actually changed
              experience: values.experience.map(
                ({
                  yearEnd,
                  description,
                  yearStart,
                  companyName,
                  location,
                  jobTitle,
                }) => ({
                  companyName,
                  jobTitle,

                  description: description || undefined,
                  location: location || undefined,

                  yearStart,
                  yearEnd: yearEnd || undefined,
                })
              ),
            },
          },
        });

        control.close();
      } catch {
        NOOP_graphqlErrorManagement();
      } finally {
        setSubmitting(false);
      }
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const [updateProfile] = useEnhancedMutation(Profile_UpdateProfileDocument, {
    refetchQueries: [getQueryName(Auth_CurrentUserDocument)],
    success: {
      message: 'Experience saved.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while saving your experience.',
    },
    formik,
  });

  useEffect(() => {
    if (control.data) {
      formikRef.current.setValues({
        experience: control.data.experience.length
          ? control.data.experience
          : [
              {
                companyName: '',
                jobTitle: '',
                description: '',
                location: '',
                yearStart: '' as any,
                yearEnd: '' as any,
              },
            ],
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = 'Show off your experience';
  return (
    <>
      <OOModal title={title} open={control.isOpen} onClose={control.close}>
        <Box display="flex">
          <Box
            className={classes.illustrationWrapper}
            marginLeft={isMobile ? 0 : 6}
          >
            <img
              className={classes.illustration}
              src={addCustomerIllustration}
              alt="add customer illustration"
            />
          </Box>
          <Box
            flex={1}
            marginLeft={isMobile ? 0 : 4}
            marginRight={isMobile ? 0 : 6}
            marginBottom={isMobile ? 0 : 4}
          >
            {control.data && (
              <form onSubmit={formik.handleSubmit}>
                {!isMobile && (
                  <Box className={classes.desktopModalTitle}>{title}</Box>
                )}

                <Box paddingX={1.5} marginBottom={isMobile ? 10 : 2}>
                  {formik.values.experience.map((xp, idx) => (
                    <Box marginY={2} key={idx}>
                      <ExperienceCard
                        formik={formik}
                        fieldName={`experience[${idx}]`}
                        removeExperience={() =>
                          formik.setFieldValue(
                            'experience',
                            formik.values.experience.filter((it) => it !== xp)
                          )
                        }
                      />
                    </Box>
                  ))}

                  <Button
                    color="primary"
                    onClick={() => {
                      formik.setFieldValue('experience', [
                        ...formik.values.experience,
                        {
                          companyName: '',
                          jobTitle: '',
                          description: '',
                          location: '',
                          yearStart: '',
                          yearEnd: '',
                        },
                      ]);
                    }}
                  >
                    + Add Experience
                  </Button>
                </Box>

                <Box display="flex" className={clsx(classes.floatingFooter)}>
                  <Box flex="1" margin={isMobile ? 0 : 1.5}>
                    <LoadingAwareButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      loading={formik.isSubmitting}
                    >
                      Save Experience
                    </LoadingAwareButton>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </OOModal>
    </>
  );
};

export default ExperienceModal;
