import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';

import { timeAgo } from '@optioffer/core';

import { CustomNotification } from './domain';
import useStyles from './styles';

type NotificationCardProps = {
  notification: CustomNotification;
  onSnooze: (obj: CustomNotification) => void;
  onDismiss: (obj: CustomNotification) => void;
  onGoToOffer: (obj: CustomNotification) => void;
};

const NotificationCard: FC<NotificationCardProps> = ({
  notification,
  onSnooze,
  onDismiss,
  onGoToOffer,
}) => {
  const { tableCard, subtitle } = useStyles();

  const snoozeAction = () => {
    onSnooze(notification);
  };
  const sendEmailAction = () => {};
  const changeOfferStatusAction = () => {};
  const goToOfferAction = () => {
    onGoToOffer(notification);
  };
  const dismissAction = () => {
    onDismiss(notification);
  };
  const buttonMap: Record<string, any> = {
    SNOOZE: { label: 'SNOOZE for 1 day', action: snoozeAction },
    SEND_EMAIL_TO_CLIENT: {
      label: 'Send email to client',
      action: sendEmailAction,
    },
    CHANGE_OFFER_STATUS: {
      label: 'CHANGE OFFER STATUS',
      action: changeOfferStatusAction,
    },
    GO_TO_OFFER: { label: 'GO TO OFFER', action: goToOfferAction },
    MARK_AS_RESOLVED: { label: 'DISMISS', action: dismissAction },
  };

  return (
    <Card className={tableCard}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {notification.title}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="h3">
            {notification.label}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        component="p"
        className={subtitle}
      >
        {timeAgo(notification.nextNotificationDate ?? notification.createdAt)}
      </Typography>
      <CardActions>
        {['SNOOZE'].concat(notification?.possibleActions).map((action, idx) => (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              buttonMap[action.toString()].action();
            }}
            key={idx}
          >
            {buttonMap[action.toString()].label}
          </Button>
        ))}
      </CardActions>
    </Card>
  );
};

export default NotificationCard;
