import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

type PriceFormatInputProps = NumberFormatProps & {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: {
    target: { name: string; value: number | undefined };
  }) => void;
  name: string;
  textAlign?: string;
};

const useLocalStyles = makeStyles(() => ({
  root: {
    textAlign: (props: Pick<PriceFormatInputProps, 'textAlign'>) =>
      props.textAlign as any,
  },
}));

const NumberFormatInput: FC<PriceFormatInputProps> = (props) => {
  const { inputRef, onChange, className, textAlign, ...other } = props;
  const localClasses = useLocalStyles({ textAlign });

  return (
    <NumberFormat
      thousandSeparator
      allowEmptyFormatting={true}
      decimalScale={2}
      {...other}
      className={clsx(className, localClasses.root)}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
    />
  );
};

export default NumberFormatInput;
