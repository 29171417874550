import { FormControlLabel, makeStyles, withStyles } from '@material-ui/core';
import MuiCheckBox from '@material-ui/core/Checkbox';
import { CheckboxProps } from '@material-ui/core/Checkbox/Checkbox';
import MuiFormControl from '@material-ui/core/FormControl';
import * as React from 'react';
import { FC, ReactNode } from 'react';

const CheckBox = withStyles((theme) => ({
  root: {},
}))(MuiCheckBox);

const FormControl = withStyles((theme) => ({
  root: {
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}))(MuiFormControl);

const useLocalStyles = makeStyles(() => ({
  rootWithLabel: {
    marginTop: '20px',
  },
}));

export type CheckBoxInputProps = CheckboxProps & {
  formControlClassName?: string;
  label?: ReactNode;
  error?: boolean;
};

const CheckBoxInput: FC<CheckBoxInputProps> = ({
  formControlClassName,
  ...props
}) => {
  const localClasses = useLocalStyles();

  return (
    <FormControl
      className={formControlClassName}
      variant="outlined"
      size="small"
      fullWidth
      classes={{
        root: props.label ? localClasses.rootWithLabel : undefined,
      }}
    >
      <FormControlLabel control={<CheckBox {...props} />} label={props.label} />
    </FormControl>
  );
};

export default CheckBoxInput;
