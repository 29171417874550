import {
  ConfirmationModal,
  DVKObject,
  DVKTable,
  InputModal,
  useModal,
} from '@dvkiin/material-commons';
import { FieldErrors, NOOP_graphqlErrorManagement, useHasRole } from '@lib';
import { Button, Toolbar } from '@material-ui/core';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import SecurityIcon from '@material-ui/icons/Security';
import PasswordIcon from '@material-ui/icons/VpnKey';
import React, { FC, useCallback, useMemo } from 'react';

import { formatDateTime, formatExpirationDate } from '@optioffer/core';
import { UserRole } from '@optioffer/graphql';

import {
  CompanyUser,
  getMostImportantRole,
  PendingInvitation,
  UserUpdateRoleInput,
} from '../domain';
import useStyles from '../styles';

type UsersSectionProps = {
  users: CompanyUser[];
  pendingInvitations: PendingInvitation[];
  onUpdateUserRoles: (
    userId: CompanyUser['id'],
    rolesUpdateInput: UserUpdateRoleInput[]
  ) => void;
  onTransferOwnership: (userId: CompanyUser['id']) => void;
  onDeleteUser: (userId: CompanyUser['id']) => void;
  onAddUser: (email: CompanyUser['email']) => Promise<any>;
  onResetUserPassword: (email: CompanyUser['email']) => Promise<any>;
  onResendInvitation: (userId: CompanyUser['id']) => void;
  onDeleteInvitation: (userId: CompanyUser['id']) => void;

  inviteUserInvalidFields: FieldErrors | undefined;
};

type ChangeRolesModalData = {
  userId: string;
  userRole: boolean;
  managerRole: boolean;
};

const UsersSection: FC<UsersSectionProps> = ({
  users,
  pendingInvitations,
  onDeleteUser,
  onAddUser,
  onResetUserPassword,
  onResendInvitation,
  onDeleteInvitation,
  inviteUserInvalidFields,
  onUpdateUserRoles,
  onTransferOwnership,
}) => {
  const { addUserButton, header } = useStyles();

  const isManager = useHasRole(UserRole.MANAGER);

  const {
    isOpen: isAddModalOpen,
    open: openAddModal,
    close: closeAddModal,
  } = useModal();
  const {
    isOpen: isDeleteModalOpen,
    open: openDeleteModal,
    close: closeDeleteModal,
    data: deleteModalData,
  } = useModal();
  const {
    isOpen: isChangeRolesModalOpen,
    open: openChangeRolesModal,
    close: closeChangeRolesModal,
    data: changeRolesModalData,
  } = useModal<ChangeRolesModalData>();
  const {
    isOpen: isDeleteInvitationModalOpen,
    open: openDeleteInvitationModal,
    close: closeDeleteInvitationModal,
    data: deleteInvitationModalData,
  } = useModal();

  const {
    isOpen: isTransferOwnershipModalOpen,
    open: openTransferOwnershipModal,
    close: closeTransferOwnershipModal,
    data: acceptTransferOwnershipModalData,
  } = useModal();

  //region handlers
  const handleViewUser = useCallback(({ id }: CompanyUser) => {
    console.log('view', id);
  }, []);

  const handleChangeRolesUser = useCallback(
    async ({ userId, userRole, managerRole }: DVKObject) => {
      onUpdateUserRoles(userId as any, [
        { role: UserRole.USER, enabled: userRole as any },
        { role: UserRole.MANAGER, enabled: managerRole as any },
      ]);
    },
    [onUpdateUserRoles]
  );

  const handleChangeRolesUserOption = useCallback(
    ({ id, roles }: CompanyUser) => {
      openChangeRolesModal({
        userId: id,
        userRole: !!roles.find((role) => role === 'USER'),
        managerRole: !!roles.find((role) => role === 'MANAGER'),
      });
    },
    [openChangeRolesModal]
  );

  const handleTransferOwnership = useCallback(
    ({ userId }: ChangeRolesModalData) => {
      onTransferOwnership(userId);
      closeTransferOwnershipModal();
      closeChangeRolesModal();
    },
    [onTransferOwnership, closeTransferOwnershipModal, closeChangeRolesModal]
  );

  const handleResetUserPassword = useCallback(
    ({ email }: CompanyUser) => {
      onResetUserPassword(email).then(NOOP_graphqlErrorManagement);
    },
    [onResetUserPassword]
  );

  const handleDeleteUser = useCallback(
    ({ id }: CompanyUser) => {
      onDeleteUser(id);
      closeDeleteModal();
    },
    [onDeleteUser, closeDeleteModal]
  );

  const handleAddUser = useCallback(
    async ({ email }: DVKObject) => {
      const user = await onAddUser(email as CompanyUser['email']);
      user && closeAddModal();
    },
    [onAddUser, closeAddModal]
  );

  const handleResendInvitation = useCallback(
    ({ id }: PendingInvitation) => {
      onResendInvitation(id);
    },
    [onResendInvitation]
  );

  const handleCancelInvitation = useCallback(
    ({ id }: PendingInvitation) => {
      onDeleteInvitation(id);
      closeDeleteInvitationModal();
    },
    [onDeleteInvitation, closeDeleteInvitationModal]
  );
  //endregion

  //region DVKTable
  const renderRoles = useCallback((roles: UserRole[]): any => {
    if (roles?.length > 0) {
      return getMostImportantRole(roles);
    }
    return 'Pending';
  }, []);

  const columns = useMemo(
    () => [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'email', label: 'Email', type: 'text' },
      { name: 'roles', label: 'Role', type: 'text', project: renderRoles },
      {
        name: 'lastLoginOn',
        label: 'Last Log In',
        type: 'text',
        project: formatDateTime,
      },
      {
        name: 'createdOn',
        label: 'Joined',
        type: 'text',
        project: formatDateTime,
      },
    ],
    [renderRoles]
  );

  const invitationsColumns = useMemo(
    () => [
      { name: 'email', label: 'Email', type: 'text' },
      {
        name: 'expiresAt',
        label: 'Expires in',
        type: 'text',
        project: (expiresAt: number) => {
          return formatExpirationDate(expiresAt);
        },
      },
    ],
    []
  );

  const actions = useMemo(
    () => [
      {
        name: 'view',
        label: 'Profile',
        Icon: ProfileIcon,
        onClick: handleViewUser,
      },
      {
        name: 'changeRoles',
        label: 'Change roles',
        Icon: SecurityIcon,
        onClick: handleChangeRolesUserOption,
      },
      {
        name: 'resetPassword',
        label: 'Reset password',
        Icon: PasswordIcon,
        onClick: handleResetUserPassword,
      },
      { name: 'divider' },
      {
        name: 'delete',
        label: 'Delete',
        color: 'red',
        Icon: DeleteIcon,
        onClick: openDeleteModal,
      },
    ],
    [
      handleViewUser,
      handleChangeRolesUserOption,
      openDeleteModal,
      handleResetUserPassword,
    ]
  );

  const invitationsActions = useMemo(
    () => [
      {
        name: 'resend',
        label: 'Resend invitation',
        Icon: RefreshIcon,
        onClick: handleResendInvitation,
      },
      { name: 'divider' },
      {
        name: 'cancel',
        label: 'Cancel invitation',
        color: 'red',
        Icon: DeleteIcon,
        onClick: openDeleteInvitationModal,
      },
    ],
    [handleResendInvitation, openDeleteInvitationModal]
  );
  //endregion

  return (
    <>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          marginTop: 8,
          paddingLeft: 0,
        }}
      >
        <h2 className={header}>My Team</h2>
        {isManager && (
          <Button
            className={addUserButton}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={openAddModal}
          >
            Add Member
          </Button>
        )}
      </Toolbar>

      <div style={{ padding: 16 }}>
        <DVKTable
          columns={columns}
          rows={users}
          actions={(isManager && actions) || undefined}
        />
      </div>
      <h3 style={{ paddingLeft: 24 }}>Pending invitations</h3>
      <div style={{ padding: 16 }}>
        <DVKTable
          columns={invitationsColumns}
          rows={pendingInvitations}
          actions={(isManager && invitationsActions) || undefined}
        />
      </div>

      <InputModal
        open={isAddModalOpen}
        onClose={closeAddModal}
        onCreate={handleAddUser}
        invalidFields={inviteUserInvalidFields}
        title="Add new user"
        fields={[
          {
            name: 'email',
            label: 'Email of the new user',
            type: 'email',
            required: true,
            errorMessage: {
              duplicate: 'An account is already registered with this email.',
              format: 'We do not support this email format.',
              default: 'This is not a valid email.',
            },
          },
        ]}
      />

      <InputModal
        open={isChangeRolesModalOpen}
        onClose={closeChangeRolesModal}
        onCreate={handleChangeRolesUser}
        defaultValue={changeRolesModalData as any}
        invalidFields={inviteUserInvalidFields}
        title="Change user roles"
        fields={[
          {
            name: 'userRole',
            label: 'Employee role',
            type: 'checkbox',
            required: false,
            disabled: true,
          },
          {
            name: 'managerRole',
            label: 'Manager role',
            type: 'checkbox',
            required: false,
          },
        ]}
        saveLabel="Update roles"
      >
        <Button
          onClick={() =>
            changeRolesModalData &&
            openTransferOwnershipModal(changeRolesModalData)
          }
          color="primary"
          variant="contained"
        >
          Transfer Ownership
        </Button>
      </InputModal>

      <ConfirmationModal
        open={isDeleteModalOpen}
        title="Delete User"
        message="Are you sure you want to PERMANENTLY delete this user? Their personal info will be completely erased, but their work will be preserved. This operation is NOT reversible!"
        onAccept={() => handleDeleteUser(deleteModalData)}
        onCancel={closeDeleteModal}
      />

      <ConfirmationModal
        open={isDeleteInvitationModalOpen}
        title="Cancel invitation"
        message="Are you sure you want to cancel this invitation? If they create an account without an invitation, they will not be able to join your company. You can always invite this person again if needed."
        onAccept={() => handleCancelInvitation(deleteInvitationModalData)}
        onCancel={closeDeleteInvitationModal}
      />

      <ConfirmationModal
        open={isTransferOwnershipModalOpen}
        title="Transfer Ownership"
        message="Are you sure you want to transfer the ownership of the company?"
        onAccept={() =>
          handleTransferOwnership(acceptTransferOwnershipModalData)
        }
        onCancel={closeTransferOwnershipModal}
      />
    </>
  );
};

export default UsersSection;
