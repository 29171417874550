export type CustomNotification = {
  id: string;
  label: string;
  userId: string;
  notificationType: string;
  quoteId: string;
  possibleActions: string[];
  notificationState: NotificationState;
  nextNotificationDate?: number;
  createdAt: number;
  title: string;
};

export enum NotificationState {
  Active = 'ACTIVE',
  Snoozed = 'SNOOZED',
  Resolved = 'RESOLVED',
}

export type NotificationPage = {
  total: number;
  notifications: CustomNotification[];
};
