import { Separator } from '@components';
import { DVKForm, DVKObject } from '@dvkiin/material-commons';
import {
  getInvalidFields,
  getOrigin,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
} from '@lib';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Team from '@material-ui/icons/Group';
import * as queryString from 'query-string';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';

import {
  Auth_GoogleLoginOrSignupDocument,
  Auth_GoogleLoginOrSignupMutation,
  Auth_LoginDocument,
  Auth_LoginMutation,
} from '@optioffer/graphql';

import useStyles from './styles';
import LoginWrapper from './wrapper';

const googleClientId: string | undefined =
  process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

const LoginPage: FC = () => {
  const styles = useStyles();
  const [returnToSender, setReturnToSender] = useState(false);
  const history = useHistory();
  const { state } = useLocation<{
    from: Location | string | undefined;
    auth: string | undefined;
  }>();

  const [
    login,
    { error, loading: emailAndPasswordLoginLoading, called: loadingCalled },
  ] = useEnhancedMutation(Auth_LoginDocument, {
    refetchQueries: [],
  });
  const [
    signupWithGoogle,
    { loading: signupWithGoogleLoading },
  ] = useEnhancedMutation(Auth_GoogleLoginOrSignupDocument, {
    error: {
      type: 'MODAL',
      message:
        'We were not able to log you in with Google. Please try again or use email and password.',
    },
    success: { message: 'Login successful.' },
  });

  const invalidFields = useMemo(() => {
    const errorsObj = getInvalidFields(error);
    if (errorsObj)
      Object.keys(errorsObj).forEach(
        (fieldName) => (errorsObj[fieldName] = 'Invalid email or password')
      );
    return errorsObj;
  }, [error]);

  const handleLogin = useCallback(
    async function handleLogin({ email, password }: DVKObject) {
      try {
        const { data } = await login({
          variables: {
            email: email as string,
            password: password as string,
            rememberMe: true,
          },
        });
        const {
          login: { accessToken, refreshToken },
        } = data as Auth_LoginMutation;
        localStorage.setItem('x-token', accessToken);
        localStorage.setItem('x-refresh-token', refreshToken);
        setReturnToSender(true);
      } catch {
        NOOP_graphqlErrorManagement();
      }
    },
    [login, setReturnToSender]
  );

  async function handleGoogleSignup({
    code,
  }: GoogleLoginResponse | GoogleLoginResponseOffline) {
    if (!code) throw new Error('Invalid code from Google login');
    try {
      const { data } = await signupWithGoogle({
        variables: {
          code,
          origin: getOrigin(),
        },
      });
      const {
        googleLoginOrSignup: { accessToken, refreshToken },
      } = data as Auth_GoogleLoginOrSignupMutation;

      localStorage.setItem('x-token', accessToken);
      localStorage.setItem('x-refresh-token', refreshToken);

      history.push('/profile/new');
    } catch (_e) {
      NOOP_graphqlErrorManagement();
    }
  }

  useEffect(() => {
    if (state.auth && !loadingCalled) {
      const [email, password] = Buffer.from(state.auth, 'base64')
        .toString('ascii')
        .split('::');
      handleLogin({ email, password }).catch(console.error);
    }
  }, [state.auth, handleLogin, loadingCalled]);

  if (returnToSender) {
    let destination = state?.from || '/';
    if (typeof destination.search === 'string') {
      const { auth, ...remainingSearchParams } = queryString.parse(
        destination.search
      );
      destination = {
        ...(destination as Location),
        search: queryString.stringify(remainingSearchParams),
      };
    }
    return <Redirect to={destination} />;
  }

  const loading = signupWithGoogleLoading || emailAndPasswordLoginLoading;

  function renderBottomContent() {
    return (
      <>
        <Typography
          className={styles.forgotPassword}
          component="p"
          variant="caption"
        >
          Forgot password? Click <Link to="/reset-password">here</Link>.
        </Typography>

        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          className={styles.submit}
        >
          Login
        </Button>

        {googleClientId && (
          <>
            <Separator variant="or" />

            <GoogleLogin
              clientId={googleClientId}
              buttonText="Continue with Google"
              responseType="code"
              disabled={loading}
              className={styles.googleLoginButton}
              onSuccess={handleGoogleSignup}
              onFailure={console.error}
            />
          </>
        )}

        <Separator variant="or" />

        <Typography className={styles.header} variant="h5" gutterBottom>
          New to OptiOffer?
        </Typography>
        <Button
          fullWidth
          variant="contained"
          className={styles.submit}
          disabled={loading}
          onClick={() => history.push('/direct-signup')}
        >
          Create a new account
        </Button>

        <Separator variant="or" />

        <Typography className={styles.header} variant="h5" gutterBottom>
          <Team />
          My team has an account
        </Typography>
        <Typography variant="body2">
          You company already has an OptiOffer Account? Click below to see how
          you can get access.
        </Typography>
        <Button
          fullWidth
          variant="contained"
          className={styles.submit}
          disabled={loading}
          onClick={() => history.push('/join-team')}
        >
          How can I get access?
        </Button>
      </>
    );
  }

  return (
    <LoginWrapper
      title="Please login to continue"
      outsideContent={
        <Typography className={styles.footer} align="center">
          © OptiOffer, 2022
        </Typography>
      }
    >
      <DVKForm
        className={styles.form}
        fields={[
          {
            name: 'email',
            label: 'Email Address',
            type: 'email',
            required: true,
            autoFocus: true,
          },
          {
            name: 'password',
            label: 'Password',
            type: 'password',
            required: true,
          },
        ]}
        invalidFields={invalidFields}
        onSubmit={handleLogin}
        bottomContent={renderBottomContent()}
      />
    </LoginWrapper>
  );
};

export default LoginPage;
