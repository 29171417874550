import moment from 'moment';
export function formatDateTime(dateNumber) {
    if (!dateNumber)
        return '';
    return moment(dateNumber).format('lll');
}
export function formatDate(dateNumber) {
    if (!dateNumber)
        return '';
    return moment(dateNumber).format('ll');
}
export function formatExpirationDate(dateNumber) {
    var now = new Date().getTime();
    if (now > dateNumber)
        return 'EXPIRED';
    return moment(dateNumber).fromNow();
}
export function timeAgo(dateNumber) {
    return moment(dateNumber).fromNow();
}
export function createdDate(dateNumber) {
    var today = new Date();
    var res = new Date(dateNumber);
    var past24Hours = new Date().getTime() - 24 * 1000 * 60 * 60;
    if (dateNumber > past24Hours) {
        return res.getHours() + ":" + res.getMinutes();
    }
    if (today.getFullYear() === res.getFullYear()) {
        return "" + res.toLocaleDateString('default', {
            day: 'numeric',
            month: 'short',
        });
    }
    return "" + res.toLocaleDateString('default', {
        day: 'numeric',
        month: 'short',
        year: '2-digit',
    });
}
