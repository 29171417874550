import {
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
  useIsMobile,
  useModal,
} from '@lib';
import { pdf } from '@react-pdf/renderer';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { downloadBlob, formatDateTime } from '@optioffer/core';
import {
  NewOffer_SendOfferToClientDocument,
  OfferInOfferModuleFragment,
  OfferStatus,
  Printing_GetOfferDocument,
} from '@optioffer/graphql';
import { BasicLayout, layoutOffer } from '@optioffer/printing';

import { NPSSurveyContext } from '@containers/NPSSurvey/context';

import { printingResources } from '@resources/printingResources';

import { ExportModalData } from '../ExportModal';
import { QuoteSentModalData } from '../QuoteSentModal';
import SendStepContext from './context';
import SendStepPageDesktop from './desktop';
import SendStepPageMobile from './mobile';

type SendStepPageProps = {
  quote: OfferInOfferModuleFragment;
  updateOffer(
    offer: OfferInOfferModuleFragment
  ): Promise<OfferInOfferModuleFragment>;

  setStep(step: number): void;
};

const SendStepPage: FC<SendStepPageProps> = ({
  quote,
  updateOffer,
  setStep,
}) => {
  // const classes = useStyles();
  const isMobile = useIsMobile();
  const { tryToShowNPSSurvey } = useContext(NPSSurveyContext);

  const quoteSentModal = useModal<QuoteSentModalData>();
  const exportModal = useModal<ExportModalData>();
  const renderingErrorModal = useModal<string>();
  const openRenderingErrorModal = renderingErrorModal.open;

  // no success feedback here, because we have a custom modal for it
  const [
    sendOfferToClient,
    { loading: sendingOfferToClient },
  ] = useEnhancedMutation(NewOffer_SendOfferToClientDocument, {
    refetchQueries: ['getOffer'],
    error: {
      type: 'MODAL',
      message: 'An error occurred while sending the quote to the client.',
    },
  });
  const {
    data: offerForPrintQueryData,
    loading: downloadingQuoteForPrint,
  } = useEnhancedQuery(Printing_GetOfferDocument, {
    variables: {
      id: quote.id,
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while generating the quote document.',
    },
  });

  const sendFormik = useFormik({
    initialValues: {
      subject: '',
      message: '',
      sendToMyself: false,
    },
    onSubmit: async () => {
      await handleSendOfferViaEmail();
    },
  });
  const sendFormikRef = useRef(sendFormik);
  useUpdateRefIfShallowNew(sendFormikRef, sendFormik);

  useEffect(() => {
    sendFormikRef.current.resetForm({
      values: {
        subject: 'Requested quote',
        message: `Dear ${
          quote.client?.name ?? 'REPLACE-WITH-CLIENT-NAME'
        },        

Here's the quote you requested. Let me know what you think.

If you have any questions or follow-ups, just reply to this email.


Best regards,
${quote.createdByUser.name} from ${quote.ownedByCompany.name}
${quote.createdByUser.phoneNumber ?? ''}
        `,
        sendToMyself: false,
      },
    });
  }, [sendFormikRef, quote]);

  const quoteManagementFormik = useFormik({
    initialValues: {
      title: '',
    },
    onSubmit: async (values) => {
      return await updateOffer({
        ...quote,
        title: values.title as OfferStatus,
      });
    },
  });
  const quoteManagementFormikRef = useRef(quoteManagementFormik);
  useUpdateRefIfShallowNew(quoteManagementFormikRef, quoteManagementFormik);

  useEffect(() => {
    quoteManagementFormikRef.current.resetForm({
      values: {
        title: quote.title ?? '',
      },
    });
  }, [quoteManagementFormikRef, quote]);

  const [loadingQuoteFiles, setLoadingQuoteFiles] = useState(true);
  const [renderedOfferPdfBlob, setRenderedOfferPdfBlob] = useState<Blob>();
  const [quotePdfFiles, setQuotePdfFiles] = useState<File[]>();

  useEffect(() => {
    let cancelled = false;

    if (!offerForPrintQueryData) return;
    (async () => {
      setLoadingQuoteFiles(true);

      try {
        const layoutedOffer = layoutOffer(
          offerForPrintQueryData.getOffer as any,
          BasicLayout,
          printingResources,
          quote.template,
          quote.language
        );

        if (cancelled) {
          setLoadingQuoteFiles(false);
          return;
        }
        const renderedOfferPdfBlob = await pdf(layoutedOffer).toBlob();
        const pdfFiles = [
          new File(
            [renderedOfferPdfBlob],
            `Quote from ${quote.ownedByCompany.name} - ${formatDateTime(
              moment()
            )}.pdf`,
            { type: 'application/pdf' }
          ),
        ];

        if (!cancelled) {
          setQuotePdfFiles(pdfFiles);
          setRenderedOfferPdfBlob(renderedOfferPdfBlob);
          setLoadingQuoteFiles(false);
        }
      } catch (ex) {
        console.log(ex);
        openRenderingErrorModal('There was an error generating your quote.');
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [
    quote,
    setQuotePdfFiles,
    setRenderedOfferPdfBlob,
    setLoadingQuoteFiles,
    offerForPrintQueryData,
    openRenderingErrorModal,
  ]);

  async function handleSendOfferViaEmail() {
    sendFormik.setSubmitting(true);
    try {
      await sendOfferToClient({
        variables: {
          id: quote.id,
          offerPdf: renderedOfferPdfBlob,
          sendToUserAlso: sendFormik.values.sendToMyself,
          subject: sendFormik.values.subject,
          message: sendFormik.values.message,
        },
      });

      quoteSentModal.open({ offer: quote });
      tryToShowNPSSurvey();
    } catch {
      NOOP_graphqlErrorManagement();
    } finally {
      sendFormik.setSubmitting(false);
    }
  }

  async function handleDownloadQuote() {
    if (!renderedOfferPdfBlob) return;
    downloadBlob(
      renderedOfferPdfBlob,
      `Quote ${
        quote.client ? `for ${quote.client.name}` : ''
      } - ${formatDateTime(moment())}.pdf`
    );
    tryToShowNPSSurvey();
  }

  async function handleSendOfferViaShare() {
    if (!quotePdfFiles) return;
    try {
      await navigator.share({
        // title: `Quote from ${quote.ownedByCompany.name} - ${formatDateTime(
        //   moment()
        // )}.pdf`,
        files: quotePdfFiles,
      } as any);
      tryToShowNPSSurvey();
    } catch (ex) {
      if (~ex.toString().indexOf('Permission denied')) {
        openRenderingErrorModal(
          `Your system doesn't support sharing PDF files.`
        );
      } else if (!~ex.toString().indexOf('Share canceled')) {
        openRenderingErrorModal(`Sharing the quote PDF failed.`);
      }
      console.error(ex, JSON.stringify(ex, null, 2));
    }
  }

  return (
    <>
      <SendStepContext.Provider
        value={{
          quote,
          updateOffer,

          quoteSentModal,
          exportModal,
          renderingErrorModal,

          sendFormik,
          quoteManagementFormik,

          loadingQuoteFiles,
          renderedOfferPdfBlob,
          quotePdfFiles,
          downloadingQuoteForPrint,
          sendingOfferToClient,

          handleDownloadQuote,
          handleSendOfferViaEmail,
          handleSendOfferViaShare,

          setStep,
        }}
      >
        {isMobile ? <SendStepPageMobile /> : <SendStepPageDesktop />}
      </SendStepContext.Provider>
    </>
  );
};

export default SendStepPage;
