import { Link, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

import useStyles from './styles';

const SupportPage: FC = () => {
  const styles = useStyles();

  return (
    <>
      <Typography variant="h4">Support</Typography>

      <Paper className={styles.card}>
        <Typography variant="h5" className={styles.header} gutterBottom>
          Problems or questions? We are here to help!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please contact us via <strong>Messenger</strong> (bottom right corner
          button).
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you prefer email, please message us at{' '}
          <a href="mailto:support@optioffer.com">support@optioffer.com</a>. Our
          support team will respond as soon as possible.
        </Typography>
      </Paper>

      <Paper className={styles.card}>
        <Typography variant="h5" className={styles.header} gutterBottom>
          Other useful links
        </Typography>
        <ul>
          <li>
            <Link
              href={`${process.env.REACT_APP_LANDING_PAGE}/privacy-policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Our privacy policy
            </Link>
          </li>
          <li>
            <Link
              href={`${process.env.REACT_APP_LANDING_PAGE}/cookie-policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Our cookie policy
            </Link>
          </li>
          <li>
            <Link
              href={`${process.env.REACT_APP_LANDING_PAGE}/terms-and-conditions`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and conditions
            </Link>
          </li>
        </ul>
      </Paper>
    </>
  );
};

export default SupportPage;
