import {
  ApolloModalErrors,
  ApolloSnackbarErrors,
  ApolloSnackbars,
} from '@components';
import * as React from 'react';
import { FC, useContext } from 'react';

import { ApolloFeedbackContext } from '@components/Feedback/context';

type ApolloFeedbackProps = {};

const ApolloFeedback: FC<ApolloFeedbackProps> = () => {
  const { results, modalErrors, snackbarErrors } = useContext(
    ApolloFeedbackContext
  );

  return (
    <>
      <ApolloSnackbars results={results} />
      <ApolloSnackbarErrors errors={snackbarErrors} />
      <ApolloModalErrors errors={modalErrors} />
    </>
  );
};

export default ApolloFeedback;
