import { StyleSheet } from '@react-pdf/renderer';
export var commonStyles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        flexDirection: 'column',
        backgroundColor: '#fff',
        paddingHorizontal: '5mm',
        fontSize: '11pt',
        textAlign: 'left',
        paddingBottom: '25mm',
    },
    p: {
        marginVertical: '1mm',
    },
    h: {
        fontSize: '13pt',
        fontWeight: 'bold',
    },
    b: {
        fontWeight: 'bold',
    },
    compact: {
        fontSize: '8pt',
        marginTop: '0mm',
    },
});
