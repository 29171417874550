import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  card: {
    width: '100%',
    marginTop: theme.spacing(3),

    padding: theme.spacing(2),
  },

  videoCard: {
    margin: theme.spacing(3),
  },

  header: {
    marginBottom: theme.spacing(2),
  },
}));
