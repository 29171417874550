import { Button, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Elements } from 'react-stripe-elements';

import CardForm from './CardForm';
import PaymentMethodCard from './PaymentMethodCard';
import { IPaymentMethodCard } from './domain';
import useStyles from './styles';

type PaymentMethodSectionProps = {
  customerName: string;
  defaultPaymentMethod?: IPaymentMethodCard;
  setDefaultPaymentMethod: (
    paymentMethodId: stripe.paymentMethod.PaymentMethod['id'],
    addressLine: string,
    country: string
  ) => void;
  removePaymentMethod: () => void;
};

const PaymentMethodSection: FC<PaymentMethodSectionProps> = ({
  customerName,
  defaultPaymentMethod,
  setDefaultPaymentMethod,
  removePaymentMethod,
}) => {
  const { paymentSection } = useStyles();
  const [showCardInput, setShowCardInput] = useState(false);

  function setPaymentMethod(
    paymentMethod: stripe.paymentMethod.PaymentMethod,
    addressLine: string,
    country: string
  ) {
    setDefaultPaymentMethod(paymentMethod.id, addressLine, country);
    setShowCardInput(false);
  }

  function renderCardInput() {
    return (
      <>
        <CardForm customerName={customerName} onSuccess={setPaymentMethod} />
      </>
    );
  }

  return (
    <Elements>
      <div className={paymentSection}>
        <h3>Payment method</h3>
        {defaultPaymentMethod ? (
          <PaymentMethodCard paymentMethodCard={defaultPaymentMethod} />
        ) : (
          <Typography variant="body1" gutterBottom>
            You don't have any payment method. Please add one.
          </Typography>
        )}

        {showCardInput ? (
          renderCardInput()
        ) : (
          <Button
            variant="outlined"
            color={'primary'}
            onClick={() => setShowCardInput(true)}
          >
            {defaultPaymentMethod
              ? 'Change payment method'
              : 'Add payment method'}
          </Button>
        )}
        {defaultPaymentMethod && (
          <Button
            onClick={() => removePaymentMethod()}
            variant="contained"
            color="secondary"
            style={{ marginLeft: 16 }}
          >
            Remove payment method
          </Button>
        )}
      </div>
    </Elements>
  );
};

export default PaymentMethodSection;
