import { useIsMobile } from '@lib';
import {
  Box,
  Button,
  ButtonProps,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import * as React from 'react';
import { FC, Fragment, ReactNode } from 'react';

import { OptiTooltip } from '@components/Tooltip';

const useLocalStyles = makeStyles((theme) => ({
  buttonWrapper: {
    gap: theme.spacing(1),
  },
  button: {
    padding: 6,
    minWidth: 40,
    alignItems: 'flex-start',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export type ButtonSwitchInputProps<T = any> = {
  id?: string;
  label?: string;
  tooltip?: string;
  createButtonProps?: (value: T) => Partial<ButtonProps>;
  buttonWrapperClassName?: string;

  value?: T;
  onChange?: (value: T) => void;

  options: { value: T; label: ReactNode }[];
  error?: boolean;
  helperText?: React.ReactNode;
};

const ButtonSwitchInput: FC<ButtonSwitchInputProps> = ({
  id,
  label,
  tooltip,
  createButtonProps,
  buttonWrapperClassName,

  value,
  onChange,
  options,
  error,
  helperText,
}) => {
  const localClasses = useLocalStyles();
  const isMobile = useIsMobile();

  return (
    <FormControl
      id={id}
      variant="standard"
      size="small"
      fullWidth
      error={error}
    >
      {label && (
        <InputLabel className={localClasses.label} shrink={true}>
          {label}{' '}
          {tooltip && (
            <OptiTooltip title={tooltip} placement="top">
              <InfoIcon />
            </OptiTooltip>
          )}
        </InputLabel>
      )}

      <Box
        className={clsx(localClasses.buttonWrapper, buttonWrapperClassName)}
        display="flex"
        justifyContent={isMobile ? 'space-around' : 'space-between'}
        flexWrap="wrap"
        marginTop={label ? (tooltip ? '20px' : '16px') : 0}
      >
        {options.map((option, idx) => (
          <Fragment key={option.value}>
            <Button
              id={id ? `${id}-${option.value}` : undefined}
              classes={{
                root: `${id}-${option.value}`,
                label: `${id}-label-${option.value}`,
              }}
              className={localClasses.button}
              variant="contained"
              color={option.value === value ? 'primary' : undefined}
              onClick={() => onChange && onChange(option.value)}
              {...(createButtonProps ? createButtonProps(option.value) : {})}
            >
              {option.label}
            </Button>
            {isMobile && !!idx && !(idx % 5) && <Box width="100%" height={0} />}
          </Fragment>
        ))}
      </Box>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ButtonSwitchInput;
