import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import React, { createContext, FC, ReactNode, useContext } from 'react';

import useStyles from './styles';

const OOTableContext = createContext({} as OOTableProps<any>);

export const OOTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: '#707070',
      opacity: 0.7,
    },
    body: {
      fontSize: 14,
      color: '#414141',
      borderBottom: 'none',
      padding: `0 ${theme.spacing(2)}px`,
      maxWidth: 0, // for the overflow ellipsis to work
    },
  })
)(TableCell);

const useOOTableCellWithSortStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
  sortIcon: {
    marginLeft: theme.spacing(2),
    transition: 'transform 0.5s ease-in-out',
    transform: 'rotateX(180deg)',
  },
  reverseSortIcon: {
    transform: 'rotateX(0deg)',
  },
}));

export const OOTableCellWithSort: FC<
  TableCellProps & { sortString: string }
> = ({ sortString, children, className, onClick, align, ...rest }) => {
  const classes = useOOTableCellWithSortStyles();
  const { sort, onSortUpdate } = useContext(OOTableContext);
  const reverseSort = `-${sortString}`;

  return (
    <OOTableCell
      className={clsx(onSortUpdate && classes.root, className)}
      onClick={(e) => {
        if (onSortUpdate) {
          onSortUpdate(sort === sortString ? reverseSort : sortString);
        }

        if (onClick) return onClick(e);
      }}
      align={align}
      {...rest}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={align === 'right' ? 'flex-end' : undefined}
      >
        {children}
        {(sort === sortString || sort === reverseSort) && (
          <SortIcon
            className={clsx(
              classes.sortIcon,
              sort === reverseSort && classes.reverseSortIcon
            )}
          />
        )}
      </Box>
    </OOTableCell>
  );
};

export const OOTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 52,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export type OOTableGenericProps<T> = {
  data: T[];
  onEdit: (dataItem: T) => unknown;
  pageCount?: number;
  onPaginationUpdate?: (page: number) => unknown;
  page?: number;
  sort?: string;
  onSortUpdate?: (sort: string) => unknown;
};

type OOTableProps<T> = OOTableGenericProps<T> & {
  renderHeader: () => ReactNode;
  renderRow: (
    dataItem: T,
    handlers: { onEdit(dataItem: T): void }
  ) => ReactNode;
};

function OOTable<T>(props: OOTableProps<T>) {
  const {
    data,
    pageCount = 1,
    onPaginationUpdate,
    onEdit,
    renderHeader,
    renderRow,
    page,
  } = props;
  const { topSpacer, optiPaper } = useStyles();

  return (
    <OOTableContext.Provider value={props}>
      <TableContainer component={Paper} className={clsx(topSpacer, optiPaper)}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>{renderHeader()}</TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => renderRow(item, { onEdit }))}
          </TableBody>
        </Table>
      </TableContainer>
      {onPaginationUpdate && (
        <Pagination
          showFirstButton
          showLastButton
          size="small"
          count={pageCount}
          className={topSpacer}
          onChange={(e, value) => onPaginationUpdate(value - 1)}
          page={page}
        />
      )}
    </OOTableContext.Provider>
  );
}

export default OOTable;
