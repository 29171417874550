import { OptiTextField } from '@components';
import { uuid } from '@dvkiin/material-commons';
import {
  mapFormikToMediaInput,
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useIsMobile,
} from '@lib';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  Auth_CurrentUserDocument,
  Profile_UpdateProfileDocument,
  ProfileOnProfilePageFragment,
  ProfileType,
} from '@optioffer/graphql';

import LoadingAwareButton from '@components/LoadingAwareButton';
import MediaInput, { isImage, ProductMedia } from '@components/form/MediaInput';
import OOModal from '@components/modals/OOModal';

import { getQueryName } from '@lib/graphql';
import { ModalControl } from '@lib/materialCommons';

import addCustomerIllustration from '@resources/illustrations/add_customer.svg';

import useStyles from '../../NewOffer/styles';
import { mapToProfileInput } from './helpers';

// const usePersonalInfoModalStyles = makeStyles((theme) => ({
//   personalInfoModalIcon: {
//     opacity: '30%',
//     fontSize: '65px',
//     color: theme.palette.primary.main,
//   },
// }));

type PersonalInfoModalProps = {
  control: ModalControl<ProfileOnProfilePageFragment>;
};

const PersonalInfoModal: FC<PersonalInfoModalProps> = ({ control }) => {
  const classes = useStyles();
  // const localClasses = usePersonalInfoModalStyles();
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      companyName: '',
      contactPersonName: '',
      address: '',
      media: [] as ProductMedia[],
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (!control.data) return;

      setSubmitting(true);
      try {
        const images = values.media
          .filter(isImage)
          .map((it) => it.imageFileForUpload || it.id);

        await updateProfile({
          variables: {
            id: control.data.id,
            profile: {
              ...mapToProfileInput(control.data),

              // actually changed
              image: images.length ? images[0] : undefined,
              companyName: values.companyName,
              contactPersonName: values.contactPersonName,
              address: values.address,
            },
          },
        });

        control.close();
      } catch {
        NOOP_graphqlErrorManagement();
      } finally {
        setSubmitting(false);
      }
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const [updateProfile] = useEnhancedMutation(Profile_UpdateProfileDocument, {
    refetchQueries: [getQueryName(Auth_CurrentUserDocument)],
    success: {
      message: 'Personal info saved.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while saving your personal info.',
    },
    formik,
  });

  useEffect(() => {
    if (control.data) {
      formikRef.current.setValues({
        companyName: control.data.companyName ?? '',
        contactPersonName: control.data.contactPersonName ?? '',
        address: control.data.address ?? '',
        media: control.data.image
          ? [
              {
                id: uuid(),
                type: 'IMAGE',
                thumbnail: control.data.image.thumbnail,
                fullImage:
                  control.data.image.fullImage ?? control.data.image.thumbnail, // 🤔
                isMainPhoto: true,
              },
            ]
          : [],
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = 'Edit Personal Info';
  return (
    <>
      <OOModal title={title} open={control.isOpen} onClose={control.close}>
        <Box display="flex">
          <Box
            className={classes.illustrationWrapper}
            marginLeft={isMobile ? 0 : 6}
          >
            <img
              className={classes.illustration}
              src={addCustomerIllustration}
              alt="add customer illustration"
            />
          </Box>
          <Box
            flex={1}
            marginLeft={isMobile ? 0 : 4}
            marginRight={isMobile ? 0 : 6}
            marginBottom={isMobile ? 0 : 4}
          >
            {control.data && (
              <form onSubmit={formik.handleSubmit}>
                {!isMobile && (
                  <Box className={classes.desktopModalTitle}>{title}</Box>
                )}

                <Box paddingX={1.5} marginBottom={isMobile ? 10 : 2}>
                  <Box marginBottom={2}>
                    <Typography>Profile Image</Typography>
                    <MediaInput
                      productCode="profile-picture"
                      {...mapFormikToMediaInput(formik, 'media')}
                    />
                  </Box>
                  {control.data.profileType === ProfileType.EMPLOYEE && (
                    <Box marginBottom={2}>
                      <OptiTextField
                        required
                        label="Name"
                        {...mapFormikToTextField(formik, 'contactPersonName')}
                      />
                    </Box>
                  )}
                  {control.data.profileType !== ProfileType.EMPLOYEE && (
                    <>
                      <Box marginY={2}>
                        <OptiTextField
                          required={
                            control.data.profileType === ProfileType.SUPPLIER
                          }
                          label="Company name"
                          placeholder="e.g. You Company SRL"
                          {...mapFormikToTextField(formik, 'companyName')}
                        />
                      </Box>
                      <Box marginY={2}>
                        <OptiTextField
                          required={
                            control.data.profileType !== ProfileType.SUPPLIER
                          }
                          label="Contact person name"
                          {...mapFormikToTextField(formik, 'contactPersonName')}
                        />
                      </Box>
                    </>
                  )}
                  <Box marginY={2}>
                    <OptiTextField
                      required
                      label="Address"
                      {...mapFormikToTextField(formik, 'address')}
                    />
                  </Box>
                </Box>

                <Box display="flex" className={clsx(classes.floatingFooter)}>
                  <Box flex="1" margin={isMobile ? 0 : 1.5}>
                    <LoadingAwareButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      loading={formik.isSubmitting}
                    >
                      Save Personal Info
                    </LoadingAwareButton>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </OOModal>
    </>
  );
};

export default PersonalInfoModal;
