import {
  CheckBoxInput,
  OptiTextField,
  textFieldCommonProps,
} from '@components';
import {
  mapFormikToAutocomplete,
  mapFormikToCheckBoxInput,
  mapFormikToImgField,
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  resizeImage,
  useEnhancedQuery,
} from '@lib';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { NewOffer_GetAvailableCurrenciesDocument } from '@optioffer/graphql';

import ImageInput from '../../../components/form/ImageInput';
import { Company, CompanyUpdateInput, Currency } from '../domain';
import useStyles from '../styles';

type CompanyMetaSectionProps = {
  companyDetails: Company;
  onUpdateCompany: (companyUpdateInput: CompanyUpdateInput) => Promise<any>;
};

const CompanyMetaSection: FC<CompanyMetaSectionProps> = ({
  companyDetails,
  onUpdateCompany,
}) => {
  const { header } = useStyles();
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      address: '',
      addressSecondary: '',
      website: '',
      phoneNumber: '',
      motto: '',
      vat: '',
      currency: (null as unknown) as Currency,
      companyLogo: null,
      costAndMarginsOnlyForManagers: false,
    },
    onSubmit: async (values) => {
      try {
        await onUpdateCompany({
          currencyKey: values.currency.id,
          name: values.name,
          email: values.email,
          address: values.address,
          website: values.website,
          phoneNumber: values.phoneNumber,
          motto: values.motto,
          vat: values.vat,
          companyLogo: values.companyLogo,
          addressSecondary: values.addressSecondary,
          costAndMarginsOnlyForManagers: values.costAndMarginsOnlyForManagers,
        });
      } catch {
        NOOP_graphqlErrorManagement();
      }
    },
  });

  const searchCurrenciesQuery = useEnhancedQuery(
    NewOffer_GetAvailableCurrenciesDocument,
    {
      variables: { search: '' },
      error: {
        type: 'SNACKBAR',
        message: 'An error occurred while loading available currencies.',
      },
    }
  );

  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (companyDetails) {
      formikRef.current.resetForm({
        values: {
          currency: companyDetails.currency,
          name: companyDetails.name,
          email: companyDetails.email,
          address: companyDetails.address,
          website: companyDetails.website,
          phoneNumber: companyDetails.phoneNumber,
          motto: companyDetails.motto,
          vat: companyDetails.vat,
          companyLogo: companyDetails.companyLogo,
          addressSecondary: companyDetails.addressSecondary,
          costAndMarginsOnlyForManagers:
            companyDetails.costAndMarginsOnlyForManagers,
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, companyDetails]);

  return (
    <>
      <h2 className={header}>Company Settings</h2>

      <form onSubmit={formik.handleSubmit} style={{ paddingLeft: 16 }}>
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Box height={'100%'} marginBottom={10}>
              <Box paddingX={1.5}>
                <Box marginY={2}>
                  <OptiTextField
                    required
                    label="Company Name"
                    {...mapFormikToTextField(formik, 'name')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Email"
                    {...mapFormikToTextField(formik, 'email')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Address"
                    {...mapFormikToTextField(formik, 'address')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Website"
                    {...mapFormikToTextField(formik, 'website')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Phone Number"
                    {...mapFormikToTextField(formik, 'phoneNumber')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Company Motto"
                    {...mapFormikToTextField(formik, 'motto')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="VAT ID"
                    {...mapFormikToTextField(formik, 'vat')}
                  />
                </Box>
                <Box marginY={2}>
                  <Autocomplete
                    {...mapFormikToAutocomplete(formik, 'currency')}
                    fullWidth
                    getOptionSelected={(option: Currency, value: Currency) => {
                      return option.id === value.id;
                    }}
                    getOptionLabel={(option: Currency) =>
                      `${option.symbol} - ${option.name}`
                    }
                    options={
                      searchCurrenciesQuery.data?.getAvailableCurrencies ?? []
                    }
                    defaultValue={companyDetails.currency}
                    loading={searchCurrenciesQuery.loading}
                    renderInput={(params) => (
                      <OptiTextField
                        {...params}
                        label="Currency"
                        {...textFieldCommonProps}
                        InputLabelProps={{
                          shrink: true,
                          ...params.InputLabelProps,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {searchCurrenciesQuery.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
                <Box marginY={2}>
                  <CheckBoxInput
                    color="primary"
                    label="Cost and margins visible only for Manager and above"
                    {...mapFormikToCheckBoxInput(
                      formik,
                      'costAndMarginsOnlyForManagers'
                    )}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={!formik.dirty || !formik.values.name}
                >
                  Save data
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} style={{ paddingRight: 24 }}>
            <ImageInput
              buttonLabel="+ Add Logo"
              imageChanged={async (event: any) => {
                const img = await resizeImage({
                  file: event.target.files[0] as File,
                  maxHeight: 877,
                  maxWidth: 620,
                  compressionRatio: 0.92,
                });
                await formik.setFieldValue('companyLogo', img.blob);
                await formik.submitForm();
              }}
              {...mapFormikToImgField(formik, 'companyLogo')}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CompanyMetaSection;
