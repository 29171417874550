import {
  DVKComboBoxFieldValue,
  DVKForm,
  FlexExpander,
} from '@dvkiin/material-commons';
import {
  getInvalidFields,
  useEnhancedMutation,
  useEnhancedProgrammaticQuery,
  useEnhancedQuery,
} from '@lib';
import { Button, Card, CardHeader } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { promiseDebounce } from '@optioffer/core';
import {
  CompanyAdminListItemOnAdminPageFragment,
  Feature,
  GetAllCompaniesDocument,
  GetAllFeaturesDocument,
  GetPlanDocument,
  PlanOnAdminPageFragment,
  UpdatePlanDocument,
} from '@optioffer/graphql';

import { ApolloModalErrors, ApolloSnackbars } from '@components/Feedback';

import usePlanFields from './fields';
import useStyles from './styles';

type PlanPageParams = {
  id: string;
};

const PlanViewEditPage: FC<RouteComponentProps<PlanPageParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const { viewEditCard } = useStyles();

  const getPlanQuery = useEnhancedQuery(GetPlanDocument, {
    variables: { id },
  });
  const getPlan = getPlanQuery.data?.getPlan;
  const [
    updatePlan,
    { data: updateResult, error: updateError },
  ] = useEnhancedMutation(UpdatePlanDocument);

  const [fetchCompanies] = useEnhancedProgrammaticQuery(
    GetAllCompaniesDocument
  );
  const getAllFeaturesQuery = useEnhancedQuery(GetAllFeaturesDocument);
  const featureEnumValues = getAllFeaturesQuery.data?.__type?.enumValues ?? [];

  //region errors
  const errors = useMemo(
    () => [
      {
        error: getPlanQuery.error,
        message:
          'The server was unable to load your plan. Please try again later. If this persists, contact support.',
      },
      {
        error: updateError,
        message:
          'There was an error while updating the plan. Please check your input and try again.',
      },
    ],
    [getPlanQuery.error, updateError]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      updateResult && {
        result: updateResult,
        message: `Plan ${updateResult.updatePlan.name} updated.`,
      },
    ],
    [updateResult]
  );

  const getPlanWithCompanyIds = useMemo(
    () =>
      getPlan && {
        ...getPlan,
        publicFor: getPlan.publicFor.map((company) => company.id),
      },
    [getPlan]
  );

  const defaultFields = usePlanFields(featureEnumValues);

  //endregion
  const searchCompanies = useCallback(
    async (search: string): Promise<any[]> => {
      const companiesToSearch =
        (await fetchCompanies({ variables: { search } })).data?.getAllCompanies
          .companies ?? [];

      return [...companiesToSearch, ...(getPlan?.publicFor || [])];
    },
    [getPlan, fetchCompanies]
  );

  const debouncedSearch = useMemo(
    () =>
      promiseDebounce<
        DVKComboBoxFieldValue[],
        (search: string) => Promise<DVKComboBoxFieldValue[]>
      >(
        async (search: string) =>
          (await searchCompanies(search)).map(
            (company: CompanyAdminListItemOnAdminPageFragment) => ({
              name: company.id!!,
              label: company.name,
            })
          ),
        1000
      ),
    [searchCompanies]
  );

  function renderPlan(plan: PlanOnAdminPageFragment) {
    const invalidFields = getInvalidFields(updateError);
    return (
      <Card className={viewEditCard}>
        <CardHeader title={plan.name} />
        <DVKForm
          fields={[
            {
              name: 'id',
              label: 'Plan unique identifier',
              type: 'text',
              disabled: true,
            },
            ...defaultFields,
            {
              name: 'publicFor',
              label: 'Public for companies',
              type: 'combo-box',
              multiple: true,
              search: debouncedSearch,
            },
          ]}
          defaultValue={plan as any} // TODO [material-commons] defaultValue field should be able to be undefined
          ContentWrapper={CardContent}
          ActionsWrapper={CardActions}
          renderActions={() => (
            <>
              <FlexExpander />
              <Button color="primary" type="submit">
                Save
              </Button>
            </>
          )}
          onSubmit={({
            name,
            description,
            price,
            duration,
            usersLimit,
            productsLimit,
            includedFeatures,
            offersLimit,
            isPublic,
            isDefault,
            hasTrial,
            stripePlanId,
            isPricePerUser,
            publicFor,
          }) =>
            updatePlan({
              variables: {
                id,
                plan: {
                  name: name as string,
                  description: description as string,
                  price: price as number,
                  duration: duration as number,
                  usersLimit: usersLimit as number,
                  productsLimit: productsLimit as number,
                  offersLimit: offersLimit as number,
                  includedFeatures: (includedFeatures as Feature[]) || [],
                  isPublic: (isPublic as unknown) as boolean,
                  publicFor: (publicFor as string[]) || [],
                  isDefault: (isDefault as unknown) as boolean,
                  hasTrial: (hasTrial as unknown) as boolean,
                  isPricePerUser: (isPricePerUser as unknown) as boolean,
                  stripePlanId: stripePlanId as string,
                },
              },
            })
          }
          invalidFields={invalidFields}
        />
      </Card>
    );
  }

  return (
    <>
      {getPlanQuery.loading && 'Loading your plan...'}{' '}
      {/* fancy loading screen here */}
      {getPlanWithCompanyIds && renderPlan(getPlanWithCompanyIds as any)}
      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default PlanViewEditPage;
