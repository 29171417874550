import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Message } from 'localize-react';
import React, { Fragment } from 'react';
import { ooBrand } from '@optioffer/core';
import { DocumentLayoutErrorBoundary, pdfIf, useDevColor, } from '../../../helpers';
import { commonStyles } from '../../style';
import PricingTable from '../PricingTable';
var offerItemStyles = StyleSheet.create({
    container: {
        paddingLeft: '5mm',
        paddingBottom: '2mm',
        fontSize: '9pt',
        borderColor: ooBrand.colors.secondary['200'],
        borderWidth: 1,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
    },
    h: {
        fontSize: '11pt',
        fontWeight: 'bold',
    },
    accessoryFor: {
        fontWeight: 'normal',
    },
    name: {
        maxHeight: '20mm',
        paddingBottom: '3mm',
    },
    row: {
        flexDirection: 'row',
        textOverflow: 'ellipsis',
    },
    imageAndSpecsOnlySpecs: {
        flex: 2,
        flexDirection: 'row',
        paddingRight: '2mm',
        borderRight: "1 solid " + ooBrand.colors.secondary[200],
    },
    imageAndSpecsWithImage: {
        flex: 3,
    },
    imageAndSpecsOnlyImage: {
        borderRight: 0,
    },
    specs: {
        flex: 1,
    },
    description: {
        flex: 2,
        borderRight: "1 solid " + ooBrand.colors.secondary[200],
        paddingHorizontal: '2mm',
    },
    pricing: {
        width: '30%',
        alignSelf: 'flex-end',
        borderTop: 0,
        borderBottom: 0,
    },
    image: {
        maxHeight: '100%',
        maxWidth: '30mm',
        width: '30mm',
        objectFit: 'contain',
        marginRight: '5mm',
    },
    sectionTitle: {
        paddingLeft: '5mm',
    },
});
var OfferItemPage = function (_a) {
    var offerItem = _a.offerItem;
    var devColor = useDevColor();
    var isBundle = !offerItem.product;
    var code = isBundle
        ? offerItem.productBundle.items.map(function (item) { return item.product.code; }).join('+')
        : offerItem.product.code;
    function renderName() {
        return (React.createElement(View, { wrap: false, fixed: true, style: [offerItemStyles.name, devColor('#4FC3F7')] },
            React.createElement(Text, { style: [commonStyles.p, commonStyles.h] },
                offerItem.position,
                ". [",
                code,
                "]",
                ' ',
                isBundle ? offerItem.productBundle.name : offerItem.product.name,
                ' ',
                pdfIf(offerItem.parentCode, function () { return (React.createElement(Text, { style: [offerItemStyles.accessoryFor] },
                    "(",
                    React.createElement(Message, { descriptor: "product.accessoryFor", values: { parent: offerItem.parentCode } }),
                    ")")); }))));
    }
    function renderImageAndSpecs() {
        var _a, _b, _c, _d;
        var hasImage = !!(isBundle
            ? (_a = offerItem.productBundle.image) === null || _a === void 0 ? void 0 : _a.fullImage
            : (_b = offerItem.product.image) === null || _b === void 0 ? void 0 : _b.fullImage);
        var specs = (_d = (_c = offerItem.product) === null || _c === void 0 ? void 0 : _c.specifications) === null || _d === void 0 ? void 0 : _d.filter(function (spec) { return !~[null, undefined, ''].indexOf(spec.value); });
        return (React.createElement(View, { style: [
                offerItemStyles.imageAndSpecsOnlySpecs,
                hasImage && (isBundle || specs.length)
                    ? offerItemStyles.imageAndSpecsWithImage
                    : {},
                hasImage && !(isBundle || specs.length)
                    ? offerItemStyles.imageAndSpecsOnlyImage
                    : {},
                devColor('#D500F9'),
            ] },
            pdfIf(hasImage, function () { return (React.createElement(Image, { style: [offerItemStyles.image, devColor('#673AB7')], src: isBundle
                    ? offerItem.productBundle.image.fullImage
                    : offerItem.product.image.fullImage })); }),
            React.createElement(View, { style: [offerItemStyles.specs, devColor('#303F9F')] },
                pdfIf(specs === null || specs === void 0 ? void 0 : specs.length, function () { return (React.createElement(React.Fragment, null,
                    React.createElement(Text, { style: [
                            commonStyles.p,
                            commonStyles.h,
                            offerItemStyles.sectionTitle,
                        ], fixed: true },
                        React.createElement(Message, { descriptor: "product.specifications" })),
                    specs.map(function (spec) { return (React.createElement(Text, { key: spec.id, style: [commonStyles.p] },
                        React.createElement(Text, { style: [commonStyles.b] }, spec.name),
                        ': ',
                        React.createElement(Text, null, spec.value),
                        ' ',
                        React.createElement(Text, null, spec.unit ? "(" + spec.unit + ")" : ''))); }))); }),
                pdfIf(isBundle, function () { return (React.createElement(React.Fragment, null,
                    React.createElement(Text, { style: [
                            commonStyles.p,
                            commonStyles.h,
                            offerItemStyles.sectionTitle,
                        ], fixed: true },
                        React.createElement(Message, { descriptor: "product.bundleProducts" })),
                    offerItem.productBundle.items.map(function (item) { return (React.createElement(Text, { key: item.id, style: [commonStyles.p] },
                        React.createElement(Text, null, item.quantity),
                        'x ',
                        React.createElement(Text, { style: [commonStyles.b] }, "[" + item.product.code + "]"),
                        '  ',
                        React.createElement(Text, null, item.product.name))); }))); }))));
    }
    function renderDescription() {
        var _a;
        var description = isBundle
            ? (_a = offerItem.productBundle) === null || _a === void 0 ? void 0 : _a.description
            : offerItem.product.description;
        if (!description)
            return React.createElement(Fragment, null);
        return (React.createElement(View, { style: [offerItemStyles.description, devColor('#1DE9B6')] },
            React.createElement(Text, { style: [commonStyles.p, commonStyles.h, offerItemStyles.sectionTitle], fixed: true },
                React.createElement(Message, { descriptor: "product.description" })),
            React.createElement(Text, null, description)));
    }
    return (React.createElement(View, { wrap: false, style: [offerItemStyles.container, devColor('#7B1FA2')] },
        renderName(),
        React.createElement(DocumentLayoutErrorBoundary, null,
            React.createElement(View, { style: [offerItemStyles.row, devColor('#f44336')] },
                renderImageAndSpecs(),
                renderDescription(),
                React.createElement(PricingTable, { style: offerItemStyles.pricing, offerItem: offerItem, hStyle: offerItemStyles.h })))));
};
export default OfferItemPage;
