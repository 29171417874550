import { NOOP_graphqlErrorManagement, SecurityContext } from '@lib';
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { useDebouncedMemo } from '@optioffer/core';
import {
  HistogramEntry,
  SearchAttributeFilter,
  SearchAttributeWithCount,
} from '@optioffer/graphql';

import { OfferContext } from '@containers/OfferContext';

import { PriceFilter } from '@lib/product/search';

import ProductAttributeFilters from './ProductAttributeFilters';
import ProductPriceFilter from './ProductPriceFilter';
import useStyles from './styles';

const useLocalStyles = makeStyles(() => ({
  expandButton: {
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',

      '& > svg': {
        transition: 'transform 0.5s ease-in-out',
        transform: 'rotateZ(90deg)',
      },

      '& > svg:first-child': {
        marginTop: -4,
        marginBottom: -6,
      },

      '& > svg:last-child': {
        marginTop: -6,
        marginBottom: -4,
      },
    },
  },
  expandButtonCollapse: {
    '& .MuiButton-label > svg': {
      transform: 'rotateZ(270deg)',
    },
  },
}));

type DesktopProductFilterSectionProps = {
  filters: SearchAttributeFilter[];
  priceFilter: PriceFilter;
  possibleFilters: SearchAttributeWithCount[];
  priceHistogram: HistogramEntry[];
  priceBoundaries?: number[];
  updateFilters: (
    filters: SearchAttributeFilter[],
    priceFilter: PriceFilter
  ) => unknown;
};

const DesktopProductFilterSection: FC<DesktopProductFilterSectionProps> = ({
  filters,
  priceFilter,
  possibleFilters,
  priceHistogram,
  priceBoundaries,
  updateFilters,
}) => {
  const classes = useStyles();
  const localClasses = useLocalStyles();

  const offer = useContext(OfferContext);
  const { company } = useContext(SecurityContext);
  const [searchFilterString, setSearchFilterString] = useState('');
  const debouncedSearchFilterString = useDebouncedMemo(
    () => searchFilterString,
    [searchFilterString],
    500
  );
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  const formik = useFormik({
    initialValues: {
      filters: [] as SearchAttributeFilter[],
      priceFilter: [0, 0] as PriceFilter,
    },
    onSubmit: async (values) => {
      await updateFilters(values.filters, values.priceFilter);
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  // TODO change this
  const hackRef = useRef({ priceFilter, priceHistogram, filters });

  useEffect(() => {
    const min = Math.floor(
      hackRef.current.priceHistogram.reduce(
        (acc, it) => Math.min(acc, it.min),
        Infinity
      )
    );
    const max = Math.ceil(
      hackRef.current.priceHistogram.reduce(
        (acc, it) => Math.max(acc, it.max),
        -Infinity
      )
    );

    formikRef.current.resetForm({
      values: {
        filters: hackRef.current.filters,
        priceFilter: [
          hackRef.current.priceFilter[0] ?? min,
          hackRef.current.priceFilter[1] ?? max,
        ],
      },
    });
  }, [formikRef, hackRef]);

  useEffect(() => {
    formikRef.current.submitForm().catch(NOOP_graphqlErrorManagement);
  }, [formikRef, formik.values]);

  const minBoundary = priceBoundaries?.[0];
  const maxBoundary = priceBoundaries?.[1];

  function getFilterCount(filter: SearchAttributeWithCount): number {
    return filter.total;
  }

  return (
    <>
      <Box display="flex" width="100%" marginTop={2}>
        <Box marginLeft={1} marginRight={1} flex={1}>
          <TextField
            fullWidth
            placeholder="Search filter or value"
            variant="outlined"
            margin="none"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchFilterString}
            onChange={({ target: { value } }) => {
              setSearchFilterString(value);
            }}
          />
        </Box>
        <Button
          aria-label="open filter panel"
          variant="text"
          onClick={() => setFiltersExpanded((it) => !it)}
          className={clsx(
            classes.iconButton,
            classes.grayTextButton,
            localClasses.expandButton,
            filtersExpanded && localClasses.expandButtonCollapse
          )}
        >
          <ChevronLeft />
          <ChevronRight />
        </Button>
      </Box>

      <Box marginRight={0.5}>
        <ProductPriceFilter
          currency={offer?.currency ?? company.currency}
          formik={formik}
          histogram={priceHistogram ?? []}
          min={minBoundary}
          max={maxBoundary}
        />
      </Box>

      <Box marginRight={0.5} style={{ overflowY: 'auto' }}>
        <ProductAttributeFilters
          formik={formik}
          possibleFilters={possibleFilters}
          getFilterCount={getFilterCount}
          debouncedSearchString={debouncedSearchFilterString}
        />
      </Box>
    </>
  );
};

export default DesktopProductFilterSection;
