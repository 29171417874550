import { TextFieldProps, withStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import * as React from 'react';
import { FC } from 'react';

import { ooBrand } from '@optioffer/core';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

const StyledTextField = withStyles((theme) => ({
  root: {
    marginTop: '20px',

    '& > .MuiOutlinedInput-root': {
      backgroundColor: ooBrand.colors.pureWhite,
    },
    '& > .MuiFormHelperText-contained': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& > label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0, -17px)',
      fontSize: theme.typography.pxToRem(13),
    },
    '& fieldset.MuiOutlinedInput-notchedOutline legend': {
      maxWidth: '0.01px',
    },
    '& input.MuiInputBase-input.Mui-disabled': {
      background: '#DEDEDE',
    },
  },
}))(MuiTextField);

const InlineStyledTextField = withStyles((theme) => ({
  root: {
    '& > label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0, -17px)',
      fontSize: theme.typography.pxToRem(13),
    },
    '& fieldset.MuiOutlinedInput-notchedOutline legend': {
      maxWidth: '0.01px',
    },
    '& input.MuiOutlinedInput-inputMarginDense': {
      padding: theme.spacing(0.5, 1),
    },

    '& input': {
      textAlign: (props: OptiInlineTextFieldProps) =>
        props.alignTextRight ? 'right' : undefined,
    },

    marginRight: theme.spacing(-1),
  },
}))(({ alignTextRight, children, ...rest }: OptiInlineTextFieldProps) => (
  <MuiTextField {...rest}>{children}</MuiTextField>
));

export const textFieldCommonProps: Partial<TextFieldProps> = {
  fullWidth: true,
  variant: 'outlined',
  size: 'small',
  autoComplete: 'off',
  InputLabelProps: { shrink: true },
};

const OptiTextField: FC<TextFieldProps> = (props) => {
  // const localClasses = useLocalStyles();
  return <StyledTextField {...textFieldCommonProps} {...props} />;
};

export default OptiTextField;

type OptiInlineTextFieldProps = TextFieldProps & { alignTextRight?: boolean };

export const OptiInlineTextField: FC<OptiInlineTextFieldProps> = (props) => {
  // const localClasses = useLocalStyles();
  return (
    <InlineStyledTextField
      {...textFieldCommonProps}
      margin="none"
      size="small"
      {...props}
    />
  );
};
