import {
  ModalTransition,
  OptiAccordion,
  OptiAccordionDetails,
  OptiAccordionSummary,
} from '@components';
import { SecurityContext, useEnhancedQuery } from '@lib';
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useContext, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { useDebouncedMemo } from '@optioffer/core';
import {
  HistogramEntry,
  ProductSection_PreviewSearchProductsCountDocument,
  SearchAttributeFilter,
  SearchAttributeWithCount,
} from '@optioffer/graphql';

import { OfferContext } from '@containers/OfferContext';

import { ModalControl } from '@lib/materialCommons';
import { areFiltersEqual, PriceFilter } from '@lib/product/search';

import ProductAttributeFilters from './ProductAttributeFilters';
import ProductPriceFilter from './ProductPriceFilter';
import useStyles from './styles';

export const OptiFormControlLabel = withStyles({
  root: {
    flex: 1,
    minWidth: 0,
  },
  label: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})(MuiFormControlLabel);

export type ProductFilterModalData = {
  searchString: string;
  sort: string;
  filters: SearchAttributeFilter[];
  priceFilter: PriceFilter;
  possibleFilters: SearchAttributeWithCount[];
  priceHistogram: HistogramEntry[];
  priceBoundaries?: number[];
};

type ProductFilterModalProps = {
  control: ModalControl<ProductFilterModalData>;
  updateFilters: (
    sort: string,
    filters: SearchAttributeFilter[],
    priceFilter: PriceFilter
  ) => Promise<any>;
};

const ProductFilterModal: FC<ProductFilterModalProps> = ({
  control,
  updateFilters,
}) => {
  const classes = useStyles();
  const offer = useContext(OfferContext);
  const { company } = useContext(SecurityContext);

  const formik = useFormik({
    initialValues: {
      sort: 'name',
      filters: [] as SearchAttributeFilter[],
      priceFilter: [0, 0] as PriceFilter,
    },
    onSubmit: async (values) => {
      if (!control.data) return;

      await updateFilters(values.sort, values.filters, values.priceFilter);

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.data) {
      const min = Math.floor(
        control.data.priceHistogram.reduce(
          (acc, it) => Math.min(acc, it.min),
          Infinity
        )
      );
      const max = Math.ceil(
        control.data.priceHistogram.reduce(
          (acc, it) => Math.max(acc, it.max),
          -Infinity
        )
      );

      formikRef.current.resetForm({
        values: {
          sort: control.data.sort,
          filters: control.data.filters,
          priceFilter: [
            control.data.priceFilter[0] ?? min,
            control.data.priceFilter[1] ?? max,
          ],
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const debouncedFilters = useDebouncedMemo(
    () => ({
      filters: formik.values.filters,
      minPrice: formik.values.priceFilter[0],
      maxPrice: formik.values.priceFilter[1],
    }),
    [formik.values],
    1000,
    false
  );

  const searchQuery = useEnhancedQuery(
    ProductSection_PreviewSearchProductsCountDocument,
    {
      skip: !control.isOpen,
      variables: {
        searchString: control.data?.searchString ?? '',
        ...debouncedFilters,
      },
      error: {
        type: 'SNACKBAR',
        message: 'An error occurred while loading filters.',
      },
    }
  );

  const minBoundary =
    searchQuery.data?.searchProducts.priceBoundaries[0] ??
    control.data?.priceBoundaries?.[0];
  const maxBoundary =
    searchQuery.data?.searchProducts.priceBoundaries[1] ??
    control.data?.priceBoundaries?.[1];

  const possibleFilters =
    searchQuery.data?.searchProducts.attributesWithCount ??
    control.data?.possibleFilters ??
    [];

  function getFilterCount(filter: SearchAttributeWithCount): number {
    if (!searchQuery.data?.searchProducts) return filter.total;
    return (
      searchQuery.data.searchProducts.attributesWithCount.find(
        areFiltersEqual.bind(null, filter)
      )?.total ?? 0
    );
  }

  async function handleFiltersReset() {
    // this needs to be separate from formReset to prevent instant closes.
    await updateFilters('name', [], [undefined, undefined]);

    control.close();
  }

  function renderSort() {
    return (
      <OptiAccordion defaultExpanded>
        <OptiAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-sort-content"
          id="panel-sort-header"
        >
          <Typography className={classes.sectionHeading}>Sort</Typography>
        </OptiAccordionSummary>
        <OptiAccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                aria-label="customer"
                {...formik.getFieldProps('sort')}
              >
                <Box display="flex" paddingX={1.5}>
                  <OptiFormControlLabel
                    value="name"
                    control={<Radio color="primary" />}
                    label="Name A-Z"
                  />
                </Box>
                <Box display="flex" paddingX={1.5}>
                  <OptiFormControlLabel
                    value="-name"
                    control={<Radio color="primary" />}
                    label="Name Z-A"
                  />
                </Box>

                <Box display="flex" paddingX={1.5}>
                  <OptiFormControlLabel
                    value="versions.code"
                    control={<Radio color="primary" />}
                    label="Code / SKU A-Z"
                  />
                </Box>
                <Box display="flex" paddingX={1.5}>
                  <OptiFormControlLabel
                    value="-versions.code"
                    control={<Radio color="primary" />}
                    label="Code / SKU Z-A"
                  />
                </Box>

                <Box display="flex" paddingX={1.5}>
                  <OptiFormControlLabel
                    value="versions.price"
                    control={<Radio color="primary" />}
                    label="Price: Low to High"
                  />
                </Box>
                <Box display="flex" paddingX={1.5}>
                  <OptiFormControlLabel
                    value="-versions.price"
                    control={<Radio color="primary" />}
                    label="Price: High to Low"
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        </OptiAccordionDetails>
      </OptiAccordion>
    );
  }

  return (
    <>
      <Dialog
        TransitionComponent={ModalTransition}
        keepMounted
        fullScreen
        open={control.isOpen}
        onClose={control.close}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box height={'100%'} marginBottom={10}>
            <Box
              boxShadow={2}
              width="100%"
              display="flex"
              alignItems="center"
              marginBottom={0.5}
            >
              <IconButton color="primary" onClick={control.close}>
                <ChevronLeftIcon />
              </IconButton>

              <Box>
                <Typography className={classes.modalHeader}>Filters</Typography>
              </Box>
            </Box>

            {renderSort()}

            <Box marginY={0.5}>
              <Divider />
            </Box>

            <ProductPriceFilter
              currency={offer?.currency ?? company.currency}
              formik={formik}
              histogram={
                searchQuery.data?.searchProducts.priceHistogram ??
                control.data?.priceHistogram ??
                []
              }
              min={minBoundary}
              max={maxBoundary}
            />

            <Box marginY={0.5}>
              <Divider />
            </Box>

            <ProductAttributeFilters
              formik={formik}
              possibleFilters={possibleFilters}
              getFilterCount={getFilterCount}
            />
          </Box>

          <Box marginBottom={10} />

          <Box display="flex" className={classes.floatingFooter}>
            <Box marginRight={2}>
              <Button fullWidth onClick={handleFiltersReset}>
                {'Clear all'}
              </Button>
            </Box>
            <Box flex="1">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Show{' '}
                {searchQuery.data?.searchProducts.totalProductVersions ?? ''}{' '}
                results
              </Button>
            </Box>
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default ProductFilterModal;
