import { NumberFormatInput, OptiTextField, SimpleCard } from '@components';
import {
  mapFormikToSelectField,
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
} from '@lib';
import { Box, Button, Grid } from '@material-ui/core';
import { ImageOutlined, PortraitOutlined } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { BasicLayoutVariant } from '@optioffer/printing';

import { ApolloModalErrors, ApolloSnackbars } from '@components/Feedback';
import ButtonSwitchInput from '@components/form/FormatSelectorInput';
import SelectInput from '@components/form/SelectInput';

import { Company, CompanyUpdateOfferSettingsInput } from '../domain';
import { GET_COMPANY_DETAILS, UPDATE_COMPANY_OFFER_SETTINGS } from '../graphql';
import useStyles from '../styles';

type ViewEditPageProps = {};

const GeneralSettingsPage: FC<ViewEditPageProps> = () => {
  const languages = [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'gr',
      label: 'Greek',
    },
    {
      value: 'ro',
      label: 'Romanian',
    },
    {
      value: 'hu',
      label: 'Hungarian',
    },
  ];
  const formik = useFormik({
    initialValues: {
      defaultOfferAvailability: 0,
      defaultOfferLanguage: 'en',
      defaultOfferFormat: '',
      companyIntro: '',
      companyTerms: '',
      companyThankYouMessage: '',
    },
    onSubmit: async (values) => {
      try {
        // await handleUpdateUser(values);
        await handleUpdateCompanyOfferSettings(values);
      } catch {
        NOOP_graphqlErrorManagement();
      }
    },
  });
  //#region queries
  const {
    data: { getCompanyDetails } = { getCompanyDetails: [] },
    error: getCompanyDetailsError,
    loading: loadingCompanyDetails,
  } = useEnhancedQuery<{ getCompanyDetails: Company }>(GET_COMPANY_DETAILS);
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);
  const { header } = useStyles();

  useEffect(() => {
    if (getCompanyDetails) {
      formikRef.current.resetForm({
        values: {
          defaultOfferAvailability: (getCompanyDetails as any)
            .defaultOfferAvailability,
          defaultOfferLanguage: (getCompanyDetails as any).defaultOfferLanguage,
          defaultOfferFormat: (getCompanyDetails as any).defaultOfferFormat,
          companyIntro: (getCompanyDetails as any).companyIntro,
          companyThankYouMessage: (getCompanyDetails as any)
            .companyThankYouMessage,
          companyTerms: (getCompanyDetails as any).companyTerms,
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, getCompanyDetails]);

  //#endregion queries

  //#region mutations

  const [
    updateCompanyOfferSettings,
    {
      data: updateCompanyOfferSettingsData,
      error: updateCompanyOfferSettingsError,
    },
  ] = useEnhancedMutation(UPDATE_COMPANY_OFFER_SETTINGS, {});

  //#endregion mutations

  //#region errors
  const errors = useMemo(
    () => [
      {
        error: updateCompanyOfferSettingsError,
        message:
          'There was an error while updating the company offer settings. If this persists, please contact support.',
      },
      {
        error: getCompanyDetailsError,
        message: 'No company found for current user. ',
      },
    ],
    [updateCompanyOfferSettingsError, getCompanyDetailsError]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      {
        result: updateCompanyOfferSettingsData,
        message: 'Company offer settings updated.',
      },
    ],
    [updateCompanyOfferSettingsData]
  );
  //endregion
  const handleUpdateCompanyOfferSettings = useCallback(
    async (
      companyUpdateOfferSettingsInput: CompanyUpdateOfferSettingsInput
    ) => {
      return updateCompanyOfferSettings({
        variables: { companyUpdateOfferSettingsInput },
      }).catch(NOOP_graphqlErrorManagement);
    },
    [updateCompanyOfferSettings]
  );

  if (!getCompanyDetails) {
    return <SimpleCard>This user is not part of any company.</SimpleCard>;
  }

  return (
    <>
      {loadingCompanyDetails ? (
        'Loading details'
      ) : (
        <>
          <h2 className={header}>Quote Settings</h2>

          <form onSubmit={formik.handleSubmit} style={{ marginLeft: 16 }}>
            <Grid container>
              <Grid item xs={6}>
                <Box height={'100%'} marginBottom={10}>
                  <Box paddingX={1.5}>
                    <Box marginY={2} width={150}>
                      <OptiTextField
                        required
                        label="Quote Availability"
                        {...mapFormikToTextField(
                          formik,
                          'defaultOfferAvailability'
                        )}
                        InputProps={{
                          inputComponent: NumberFormatInput as any,
                          inputProps: {
                            textAlign: 'center',
                            onFocus: (e) => e.target.select(),
                          },
                          endAdornment: (
                            <label style={{ color: '#818181', marginLeft: 8 }}>
                              days
                            </label>
                          ),
                        }}
                      />
                    </Box>
                    <Box marginY={2} width={350}>
                      <SelectInput
                        label="Quote Language"
                        options={languages}
                        defaultValue={
                          (getCompanyDetails as any).defaultOfferLanguage
                        }
                        {...mapFormikToSelectField(
                          formik,
                          'defaultOfferLanguage'
                        )}
                      />
                    </Box>
                    <Box marginY={2}>
                      <ButtonSwitchInput
                        formik={formik as any}
                        name="defaultOfferFormat"
                        label="Quote Orientation"
                        values={[
                          {
                            value: BasicLayoutVariant.DETAILED,
                            label: 'Portrait',
                            icon: <PortraitOutlined />,
                          },
                          {
                            value: BasicLayoutVariant.COMPACT,
                            label: 'Landscape',
                            icon: <ImageOutlined />,
                          },
                        ]}
                      />
                    </Box>
                    <Box marginY={2}>
                      <OptiTextField
                        label="Company Introduction Text"
                        multiline
                        rows={5}
                        {...mapFormikToTextField(formik, 'companyIntro')}
                      />
                    </Box>
                    {/*<Box marginY={2}>*/}
                    {/*  <OptiTextField*/}
                    {/*    label="Terms and conditions"*/}
                    {/*    multiline*/}
                    {/*    rows={5}*/}
                    {/*    {...mapFormikToTextField(formik, 'companyTerms')}*/}
                    {/*  />*/}
                    {/*</Box>*/}
                    {/* TODO it's max hack, i know */}
                    <Box marginY={2}>
                      <OptiTextField
                        label="Terms and conditions"
                        multiline
                        rows={5}
                        {...mapFormikToTextField(
                          formik,
                          'companyThankYouMessage'
                        )}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      disabled={!formik.dirty}
                    >
                      Save data
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </>
      )}

      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default GeneralSettingsPage;
