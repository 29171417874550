import { makeStyles } from '@material-ui/core';

import { OOTheme } from '../../theme';

export default makeStyles((theme: OOTheme) => ({
  tableCard: {
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    paddingLeft: theme.spacing(3),
  },
}));
