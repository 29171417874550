var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var formatDiscount = function (discount, options) {
    if (options === void 0) { options = {}; }
    return discount.toLocaleString(undefined, __assign({ minimumFractionDigits: 0, maximumFractionDigits: 2 }, options)) + "%";
};
// for most currencies, there are no divisions smaller than .01
// TODO: move precision to currency, this way we can support bitcoin and what not
export var formatPrice = function (price, currency, hideCurrencySymbol, options) {
    if (hideCurrencySymbol === void 0) { hideCurrencySymbol = false; }
    if (options === void 0) { options = {}; }
    return price.toLocaleString(undefined, __assign({ minimumFractionDigits: 2, maximumFractionDigits: 2, style: hideCurrencySymbol ? undefined : 'currency', currency: hideCurrencySymbol ? undefined : currency.id }, options));
};
