import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 3),
  },
  avatar: {
    margin: theme.spacing(1),
    width: 80,
    height: 80,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(5),
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '& > svg:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  cardInput: {
    marginTop: theme.spacing(2),
  },
  priceBreakdown: {
    marginTop: theme.spacing(2),
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
  },
  googleLoginButton: {
    width: '100%',
    justifyContent: 'center',
  },
}));
