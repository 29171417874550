import { mapFormikToSelectField, useIsMobile } from '@lib';
import { Box, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { BasicLayoutVariant } from '@optioffer/printing';

import SelectInput from '@components/form/SelectInput';
import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';

import useStyles from '../styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

export type QuoteTemplateModalData = {
  quoteTemplate: string;
};

type QuoteTemplateModalProps = {
  control: ModalControl<QuoteTemplateModalData>;
  updateQuoteTemplate: (quoteTemplate: string) => Promise<any>;
};

const QuoteTemplateModal: FC<QuoteTemplateModalProps> = ({
  control,
  updateQuoteTemplate,
}) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      quoteTemplate: BasicLayoutVariant.COMPACT as string,
    },
    onSubmit: async (values) => {
      if (!control.data) return;

      await updateQuoteTemplate(values.quoteTemplate);

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.data) {
      formikRef.current.resetForm({
        values: {
          quoteTemplate:
            control.data.quoteTemplate ?? BasicLayoutVariant.COMPACT,
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = 'Quote Template';
  return (
    <OOModal
      open={control.isOpen}
      onClose={control.close}
      title={title}
      minWidth="sm"
    >
      <Box marginX={isMobile ? 0 : 3} marginBottom={isMobile ? 0 : 3}>
        <form onSubmit={formik.handleSubmit}>
          {!isMobile && (
            <Box className={classes.desktopModalTitle}>{title}</Box>
          )}
          <Box height={'100%'} marginBottom={10}>
            <Box paddingX={1.5}>
              <Box marginY={2}>
                <SelectInput
                  label="Template"
                  options={[
                    {
                      value: BasicLayoutVariant.COMPACT,
                      label: 'Landscape',
                    },
                    {
                      value: BasicLayoutVariant.DETAILED,
                      label: 'Portrait',
                    },
                  ]}
                  {...mapFormikToSelectField(formik, 'quoteTemplate')}
                />
              </Box>
            </Box>
          </Box>

          <Box marginBottom={10} />

          <Box display="flex" className={classes.floatingFooter}>
            <Box flex="1">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={!formik.dirty}
              >
                {'Save Changes'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </OOModal>
  );
};

export default QuoteTemplateModal;
