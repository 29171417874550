import { useQueryParams } from '@lib';
import React, { FC } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

import RequestSignupPage from './RequestSignup';
import SignupPage from './Signup';

const SignupModule: FC = () => {
  const query = useQueryParams();

  if (!query.token) {
    return <RequestSignupPage />;
  }

  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY as string}>
      <Elements>
        <SignupPage />
      </Elements>
    </StripeProvider>
  );
};

export default SignupModule;
