import { DVKField } from '@dvkiin/material-commons';

export const attributeCollectionListFields = [
  {
    name: 'name',
    label: 'Attribute collection name',
    type: 'text',
    required: true,
    autoFocus: true,
    errorMessage: 'Attribute collection name should not be empty',
  },
  {
    name: 'description',
    label: 'Attribute collection description',
    type: 'text',
    multiline: true,
  },
] as DVKField[];
