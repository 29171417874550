import { NumberFormatInput } from '@components';
import { mapFormikToSelectAndTextField, useIsMobile } from '@lib';
import { Box, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';
import * as Yup from 'yup';

import { CalculationType, DiscountInput } from '@optioffer/graphql';

import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';

import moneyIllustration from '@resources/illustrations/money_pig.svg';

import SelectAndTextInput from '../../../components/form/SelectAndTextInput';
import useStyles from '../styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

export type QuoteDiscountModalData = {
  discount: DiscountInput;
};

type QuoteDiscountModalProps = {
  control: ModalControl<QuoteDiscountModalData>;
  updateDiscount: (discount: DiscountInput) => Promise<any>;
};

const QuoteDiscountModal: FC<QuoteDiscountModalProps> = ({
  control,
  updateDiscount,
}) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();
  // const { currency } = useContext(OfferContext);

  const formik = useFormik({
    initialValues: {
      type: CalculationType.PERCENTAGE,
      value: 0,
    },
    validationSchema: Yup.object({
      value: Yup.number().min(0, 'Discount should be positive.'),
    }),
    onSubmit: async (values) => {
      if (!control.data) return;

      await updateDiscount({
        calculationType: values.type,
        value: values.value ?? 0,
      });

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.data) {
      formikRef.current.resetForm({
        values: {
          type: control.data.discount.calculationType,
          value: control.data.discount.value,
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = 'Quote Discount';
  return (
    <OOModal open={control.isOpen} onClose={control.close} title={title}>
      <Box display="flex">
        {!isMobile && (
          <Box
            position="absolute"
            right={0}
            top={-90}
            className={classes.clickThrough}
          >
            <img
              className={classes.illustration}
              src={moneyIllustration}
              alt="discount illustration"
            />
          </Box>
        )}
        <Box
          flex={1}
          marginLeft={isMobile ? 0 : 4}
          marginRight={isMobile ? 0 : 6}
          marginBottom={isMobile ? 0 : 4}
        >
          <form onSubmit={formik.handleSubmit}>
            {!isMobile && (
              <Box className={classes.desktopModalTitle}>{title}</Box>
            )}

            <Box paddingX={1.5}>
              <Box marginY={2}>
                <SelectAndTextInput
                  label="Discount"
                  options={[
                    { label: '%', value: CalculationType.PERCENTAGE },
                    // { label: currency.symbol, value: CalculationType.FIXED },
                    // disabled for now as it's not correctly calculated. enable this back after the new discounts are implemented
                  ]}
                  {...mapFormikToSelectAndTextField(
                    formik,
                    'type',
                    'value',
                    { disabled: true },
                    {
                      InputProps: {
                        inputComponent: NumberFormatInput as any,
                        inputProps: {
                          textAlign: 'right',
                          onFocus: (e) => e.target.select(),
                        },
                      },
                    }
                  )}
                />
              </Box>
            </Box>

            <Box marginBottom={10} />

            <Box display="flex" className={classes.floatingFooter}>
              <Box flex="1">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={!formik.dirty}
                >
                  {!formik.dirty
                    ? 'Changes Saved'
                    : formik.values.value
                    ? 'Apply Discount'
                    : 'Remove Discount'}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </OOModal>
  );
};

export default QuoteDiscountModal;
