import {
  ConfirmationModal,
  DVKColumn,
  DVKField,
  DVKTable,
  InputModal,
  useModal,
} from '@dvkiin/material-commons';
import { FieldErrors, useHasRole } from '@lib';
import { Button, Toolbar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BuildIcon from '@material-ui/icons/Build';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { FC, useCallback, useMemo } from 'react';

import {
  CalculationType,
  CreateTaxInput,
  ITax,
  TaxCalculationOrderType,
  TaxType,
  TaxTypeLabels,
  UpdateTaxInput,
} from '@optioffer/core';
import { UserRole } from '@optioffer/graphql';

import useStyles from '../styles';

type TaxSectionProps = {
  taxes: ITax[];
  onAddTax: (tax: CreateTaxInput) => Promise<any>;
  onDeleteTax: (taxId: ITax['id']) => Promise<any>;
  onUpdateTax: (tax: UpdateTaxInput) => Promise<any>;
  invalidTaxFields: FieldErrors | undefined;
};

const TAX_FIELDS: DVKField[] = [
  {
    name: 'type',
    label: 'Tax type',
    type: 'select',
    values: Object.values(TaxType).map((taxType) => ({
      name: taxType,
      label: TaxTypeLabels[taxType],
    })),
    required: true,
  },
  {
    name: 'name',
    label: 'Tax name',
    type: 'text',
    required: true,
  },
  {
    name: 'calculationType',
    label: 'Calculation type',
    type: 'select',
    values: Object.values(CalculationType),
    required: true,
  },
  {
    name: 'taxCalculationOrder',
    label: 'Calculation order',
    type: 'select',
    values: Object.values(TaxCalculationOrderType),
    required: true,
  },
  {
    name: 'value',
    label: 'Tax value',
    type: 'number',
    required: true,
  },
  {
    name: 'enabled',
    label: 'Activate tax?',
    type: 'checkbox',
  },
];

const TAX_COLUMNS: DVKColumn[] = [
  {
    name: 'name',
    label: 'TAX name',
    type: 'text',
  },
  {
    name: 'enabled',
    label: 'Status',
    type: 'text',
    project: (it: boolean) => (it ? 'Active' : 'Not active'),
  },
  {
    name: 'type',
    label: 'Type',
    type: 'text',
    project: (it: TaxType) => TaxTypeLabels[it],
  },
  {
    name: 'taxCalculationOrder',
    label: 'Calculation order',
    type: 'text',
  },
  {
    name: 'value',
    label: 'Tax value',
    type: 'number',
  },
];

const TaxSection: FC<TaxSectionProps> = ({
  taxes,
  onAddTax,
  onDeleteTax,
  onUpdateTax,
  invalidTaxFields,
}) => {
  const { addTaxButton, tableMargin, header } = useStyles();
  const isManager = useHasRole(UserRole.MANAGER);

  //region DVKTable
  const {
    isOpen: isUpdateTaxModalOpen,
    data: updateTaxModalData,
    open: openUpdateTaxModal,
    close: closeUpdateTaxModal,
  } = useModal();
  const {
    isOpen: isAddTaxModalOpen,
    open: openAddTaxModal,
    close: closeAddTaxModal,
  } = useModal();
  const {
    isOpen: isDeleteTaxModalOpen,
    data: deleteTaxModalData,
    open: openDeleteTaxModal,
    close: closeDeleteTaxModal,
  } = useModal();

  const handleAddTax = useCallback(
    async (tax: any) => {
      const company = await onAddTax({
        name: tax.name,
        type: tax.type,
        calculationType: tax.calculationType,
        taxCalculationOrder: tax.taxCalculationOrder,
        value: tax.value,
        enabled: !!tax.enabled,
      });
      company && closeAddTaxModal();
    },
    [onAddTax, closeAddTaxModal]
  );

  const handleDeleteTax = useCallback(
    async (tax: any) => {
      const company = await onDeleteTax(tax.id);
      company && closeDeleteTaxModal();
    },
    [onDeleteTax, closeDeleteTaxModal]
  );

  const handleUpdateTax = useCallback(
    async (tax: any) => {
      const company = await onUpdateTax({
        id: tax.id,
        name: tax.name,
        type: tax.type,
        calculationType: tax.calculationType,
        taxCalculationOrder: tax.taxCalculationOrder,
        value: tax.value,
        enabled: !!tax.enabled,
      });
      company && closeUpdateTaxModal();
    },
    [onUpdateTax, closeUpdateTaxModal]
  );

  const actions = useMemo(
    () => [
      {
        name: 'update',
        label: 'Update tax',
        Icon: BuildIcon,
        onClick: openUpdateTaxModal,
      },
      {
        name: 'delete',
        label: 'Delete tax',
        color: 'red',
        Icon: DeleteIcon,
        onClick: openDeleteTaxModal,
      },
    ],
    [openUpdateTaxModal, openDeleteTaxModal]
  );

  //endregion
  return (
    <>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          marginTop: 8,
          paddingLeft: 0,
        }}
      >
        <h2 className={header}>Taxes</h2>

        {isManager && (
          <Button
            className={addTaxButton}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={openAddTaxModal}
          >
            Add Tax
          </Button>
        )}
      </Toolbar>

      <div className={tableMargin}>
        <DVKTable
          columns={TAX_COLUMNS}
          rows={taxes}
          actions={(isManager && actions) || undefined}
        />
      </div>

      <InputModal
        open={isAddTaxModalOpen}
        onClose={closeAddTaxModal}
        onCreate={handleAddTax}
        invalidFields={invalidTaxFields}
        title="Add new tax"
        fields={TAX_FIELDS}
      />
      <InputModal
        open={isUpdateTaxModalOpen}
        onClose={closeUpdateTaxModal}
        onCreate={handleUpdateTax}
        defaultValue={updateTaxModalData as any}
        invalidFields={invalidTaxFields}
        title="Update tax"
        fields={TAX_FIELDS}
        saveLabel="Update tax"
      />

      <ConfirmationModal
        open={isDeleteTaxModalOpen}
        title="Delete tax"
        message="Are you sure you want to delete this tax?"
        onAccept={() => handleDeleteTax(deleteTaxModalData)}
        onCancel={closeDeleteTaxModal}
      />
    </>
  );
};

export default TaxSection;
