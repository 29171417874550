import {
  DVKObject,
  ErrorModal,
  FlexExpander,
  InputModal,
  SuccessSnackbar,
  useIncrementalKey,
  useModal,
} from '@dvkiin/material-commons';
import {
  getInvalidFields,
  goBackIfCurrentPageEmpty,
  useEnhancedMutation,
  useEnhancedQuery,
  usePaginationFromUrl,
} from '@lib';
import { Box, InputAdornment, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useThrottledMemo } from '@optioffer/core';

import SupplierCustomerTable from '@components/Table/SupplierCustomerTable';
import CountrySelector from '@components/form/CountrySelector';

import { ApolloModalErrors, ApolloSnackbars } from '../../components/Feedback';
import { Country, Supplier, SupplierPage } from './domain';
import defaultFields from './fields';
import {
  CREATE_SUPPLIER,
  FIND_ALL_SUPPLIERS,
  SEARCH_SUPPLIERS,
} from './graphql';
import useStyles from './styles';

const SupplierListPage: FC<RouteComponentProps> = ({ history }) => {
  const { addButton, toolbar, searchBar, searchInput, icon } = useStyles();

  const {
    isOpen: isCreateModalOpen,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal();
  const {
    pagination,
    sort,
    graphQLPagination,
    handlePaginationUpdate,
  } = usePaginationFromUrl();
  const [createKey, incrementCreateKey] = useIncrementalKey();
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [
    inputSelectedCountry,
    setInputSelectedCountry,
  ] = useState<Country | null>(null);
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useThrottledMemo(
    () => searchString,
    [searchString],
    1000
  );

  //region queries
  const {
    data: { findAllSuppliers } = { findAllSuppliers: undefined },
    error: getError,
  } = useEnhancedQuery<{ findAllSuppliers?: SupplierPage }>(
    FIND_ALL_SUPPLIERS,
    {
      variables: { pagination: graphQLPagination },
    }
  );

  const {
    data: { searchSuppliers } = { searchSuppliers: undefined },
    error: searchSuppliersError,
  } = useEnhancedQuery<{ searchSuppliers?: SupplierPage }>(SEARCH_SUPPLIERS, {
    variables: {
      searchString: debouncedSearchString,
      pagination: graphQLPagination,
      country: selectedCountry?.isoCode,
    },
  });

  //endregion

  //region mutations
  const [
    createSupplier,
    { data: createResult, error: createError },
  ] = useEnhancedMutation<{ createSupplier: Supplier }>(CREATE_SUPPLIER, {
    refetchQueries: ['findAllSuppliers'],
  });
  // endregion

  //region errors
  const errors = useMemo(() => [], []);
  // endregion

  //region results
  const results = useMemo(() => [], []);
  //endregion

  useEffect(
    () =>
      goBackIfCurrentPageEmpty(
        findAllSuppliers,
        pagination,
        sort,
        handlePaginationUpdate
      ),
    [findAllSuppliers, pagination, sort, handlePaginationUpdate]
  );

  function handleNavigateToSupplier({ id }: Supplier) {
    history.push(`/supplier/${id}`);
  }

  async function handleSupplierSave(supplier: DVKObject) {
    const supplierToSave = {
      ...defaultFields.reduce(
        (acc, { name }) => ({ ...acc, [name]: supplier[name] }),
        {}
      ),
      country: inputSelectedCountry?.isoCode,
    };
    const { error } = (await createSupplier({
      variables: { supplier: supplierToSave },
    })) as any; // WTF 😂
    setInputSelectedCountry(null);
    if (!error) {
      incrementCreateKey();
    }
  }

  const handlePageChange = (page: number) => {
    handlePaginationUpdate({ page, rowsPerPage: 10 }, undefined);
  };

  function renderTable() {
    if (getError || searchSuppliersError)
      return (
        <ErrorModal
          error={getError}
          message="There was an error while loading the suppliers. Please try again later. If the problem persists, please contact and administrator."
        />
      );
    if (!findAllSuppliers) return 'Loading...';
    if (!findAllSuppliers.total)
      return 'You have no suppliers yet. Add some ;)';
    return (
      <SupplierCustomerTable
        data={searchSuppliers?.suppliers ?? findAllSuppliers.suppliers}
        pageCount={Math.round(
          (searchSuppliers ? searchSuppliers.total : findAllSuppliers.total) /
            10
        )}
        page={pagination.page + 1}
        onPaginationUpdate={handlePageChange}
        onEdit={handleNavigateToSupplier}
      />
    );
  }

  return (
    <>
      <h2>Suppliers</h2>
      <div className={toolbar}>
        <div className={searchBar}>
          <TextField
            autoFocus
            placeholder="Search by contact person, Company, VAT Number"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={icon} />
                </InputAdornment>
              ),
              className: searchInput,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={searchString}
            onChange={({ target: { value } }) => {
              setSearchString(value);
              handlePageChange(0);
            }}
          />
        </div>
        <Box marginTop={'-20px'}>
          <CountrySelector onCountrySelected={setSelectedCountry} />
        </Box>

        <FlexExpander />
        <Button
          className={addButton}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openCreateModal}
        >
          Create Supplier
        </Button>
      </div>

      {renderTable()}

      <InputModal
        title="Add new Supplier"
        formKey={`${createKey}`}
        open={isCreateModalOpen}
        fields={defaultFields}
        invalidFields={getInvalidFields(createError) || undefined} // TODO [material-commons] accept null
        onClose={closeCreateModal}
        onCreate={handleSupplierSave}
        bottomContent={
          <CountrySelector
            onCountrySelected={setInputSelectedCountry}
            selectorStyleOptions={{
              size: 'medium',
              showAdornment: false,
              inputVariant: 'standard',
              label: 'Country',
            }}
          />
        }
      />

      {createError && (
        <ErrorModal
          key={(createError as any).timestamp}
          error={createError}
          message="There was an error while creating the supplier. Please check your input and try again."
        />
      )}

      {createResult && (
        <SuccessSnackbar
          key={createResult.createSupplier.id}
          message={
            <span>Supplier '{createResult.createSupplier.name}' created.</span>
          }
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() =>
                handleNavigateToSupplier(createResult.createSupplier)
              }
            >
              View and edit
            </Button>
          }
        />
      )}
      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default SupplierListPage;
