import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import { ooBrand } from '@optioffer/core';
import { OfferPrintContext, useDevColor } from '../../helpers';
var footerStyles = StyleSheet.create({
    footer: {
        height: '15mm',
        minHeight: '15mm',
        maxHeight: '15mm',
        flexDirection: 'row',
        position: 'absolute',
        bottom: '5mm',
        left: '5mm',
        right: '5mm',
        justifyContent: 'space-between',
    },
    credits: {
        width: '82mm',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    paging: {
        width: '36mm',
        alignItems: 'center',
        justifyContent: 'center',
    },
    marketing: {
        width: '82mm',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    creditsImage: {
        width: '40mm',
        objectFit: 'contain',
    },
    marketingWebsite: {
        color: ooBrand.colors.primary.default,
        fontSize: '14pt',
        fontWeight: 'bold',
    },
    marketingMotto: {
        fontSize: '9pt',
    },
});
var Footer = function () {
    var optiOfferLogo = useContext(OfferPrintContext).resources.optiOfferLogo;
    var devColor = useDevColor();
    return (React.createElement(View, { fixed: true, wrap: false, style: [footerStyles.footer, devColor('#fff')] },
        React.createElement(View, { style: [footerStyles.credits, devColor('#ffa726')] },
            React.createElement(Text, null, "Created using"),
            React.createElement(Image, { style: [footerStyles.creditsImage], src: optiOfferLogo })),
        React.createElement(View, { style: [footerStyles.paging, devColor('#f06292')] },
            React.createElement(Text, { fixed: true, render: function (_a) {
                    var pageNumber = _a.pageNumber, totalPages = _a.totalPages;
                    return pageNumber + " of " + (totalPages || 0);
                } })),
        React.createElement(View, { style: [footerStyles.marketing, devColor('#fbc02d')] },
            React.createElement(Text, { style: [footerStyles.marketingWebsite] }, "optioffer.com"),
            React.createElement(Text, { style: [footerStyles.marketingMotto] }, "A better, faster, smarter way of building your offers!"))));
};
export default Footer;
