import { ModalTransition, OptiTextField } from '@components';
import { mapFormikToTextField } from '@lib';
import { Box, Button, Dialog, IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import { ModalControl } from '@lib/materialCommons';

import useStyles from '../NewOffer/styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

export type CreateQuoteModalData = {};

type CreateQuoteModalProps = {
  control: ModalControl<CreateQuoteModalData>;
  createQuote: (title: string) => Promise<any>;
};

const CreateQuoteModal: FC<CreateQuoteModalProps> = ({
  control,
  createQuote,
}) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    onSubmit: async (values) => {
      await createQuote(values.title);

      control.close();
    },
  });

  return (
    <>
      <Dialog
        TransitionComponent={ModalTransition}
        fullScreen
        open={control.isOpen}
        onClose={control.close}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box height={'100%'} marginBottom={10}>
            <Box boxShadow={2} width="100%" display="flex" alignItems="center">
              <IconButton color="primary" onClick={control.close}>
                <ChevronLeftIcon />
              </IconButton>

              <Box>
                <Typography className={classes.modalHeader}>
                  Create a new Quote
                </Typography>
              </Box>
            </Box>

            <Box paddingX={1.5}>
              <Box marginY={2}>
                <OptiTextField
                  label="Quote title"
                  placeholder="e.g.: the name of the project"
                  autoFocus
                  {...mapFormikToTextField(formik, 'title')}
                />
              </Box>
            </Box>

            <Box paddingX={1.5}>
              The title will help you identify the quote later. It's only used
              internally (your customers will not see it).
            </Box>
          </Box>

          <Box marginBottom={10} />

          <Box display="flex" className={classes.floatingFooter}>
            <Box flex="1">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                {'Create Quote'}
              </Button>
            </Box>
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default CreateQuoteModal;
