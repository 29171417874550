import {
  NumberFormatInput,
  OptiTextField,
  textFieldCommonProps,
} from '@components';
import {
  mapFormikToAutocomplete,
  mapFormikToTextField,
  SecurityContext,
  useEnhancedQuery,
  useIsMobile,
} from '@lib';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useContext, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { formatPrice } from '@optioffer/core';
import {
  Currency,
  NewOffer_GetAvailableCurrenciesDocument,
} from '@optioffer/graphql';

import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';

import moneyIllustration from '@resources/illustrations/money_coin.svg';

import useStyles from '../styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

export type QuoteCurrencyModalData = {
  quoteCurrency: Currency;
};

type QuoteCurrencyModalProps = {
  control: ModalControl<QuoteCurrencyModalData>;
  updateQuoteCurrency: (quoteCurrency: Currency) => Promise<any>;
};

const QuoteCurrencyModal: FC<QuoteCurrencyModalProps> = ({
  control,
  updateQuoteCurrency,
}) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const {
    company: { currency: defaultCurrency },
  } = useContext(SecurityContext);

  const formik = useFormik({
    initialValues: {
      quoteCurrency: null as Currency | null,
      currencyRate: 1,
    },
    onSubmit: async (values) => {
      if (!control.data) return;

      await updateQuoteCurrency(
        values.quoteCurrency && values.quoteCurrency.id !== defaultCurrency.id
          ? {
              ...values.quoteCurrency,
              rate: values.currencyRate,
            }
          : defaultCurrency
      );

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.data) {
      formikRef.current.resetForm({
        values: {
          quoteCurrency: control.data.quoteCurrency ?? null,
          currencyRate: control.data.quoteCurrency.rate ?? 1,
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const searchCurrenciesQuery = useEnhancedQuery(
    NewOffer_GetAvailableCurrenciesDocument,
    {
      variables: { search: '' },
      error: {
        type: 'SNACKBAR',
        message: 'An error occurred while loading available currencies.',
      },
    }
  );

  const title = 'Quote Currency';
  return (
    <OOModal open={control.isOpen} onClose={control.close} title={title}>
      <Box display="flex">
        {!isMobile && (
          <Box
            position="absolute"
            right={0}
            top={-90}
            className={classes.clickThrough}
          >
            <img
              className={classes.illustration}
              src={moneyIllustration}
              alt="discount illustration"
            />
          </Box>
        )}
        <Box
          flex={1}
          marginLeft={isMobile ? 0 : 4}
          marginRight={isMobile ? 0 : 6}
          marginBottom={isMobile ? 0 : 4}
        >
          <form onSubmit={formik.handleSubmit}>
            {!isMobile && (
              <Box className={classes.desktopModalTitle}>{title}</Box>
            )}

            <Box paddingX={1.5}>
              <Box marginY={2}>
                <Autocomplete
                  {...mapFormikToAutocomplete(formik, 'quoteCurrency')}
                  fullWidth
                  getOptionSelected={(option: Currency, value: Currency) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option: Currency) =>
                    `${option.symbol} - ${option.name}`
                  }
                  options={
                    searchCurrenciesQuery.data?.getAvailableCurrencies ?? []
                  }
                  loading={searchCurrenciesQuery.loading}
                  renderInput={(params) => (
                    <OptiTextField
                      {...params}
                      label="Currency"
                      {...textFieldCommonProps}
                      InputLabelProps={{
                        shrink: true,
                        ...params.InputLabelProps,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {searchCurrenciesQuery.loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>

              {formik.values.quoteCurrency &&
                defaultCurrency.id !== formik.values.quoteCurrency.id && (
                  <>
                    <Box marginY={2}>
                      <OptiTextField
                        label="Exchange rate"
                        {...mapFormikToTextField(formik, 'currencyRate')}
                        InputProps={{
                          inputComponent: NumberFormatInput as any,
                          inputProps: {
                            textAlign: 'right',
                            onFocus: (e) => e.target.select(),
                          },
                        }}
                      />
                    </Box>
                    <Box marginY={2}>
                      {formatPrice(1, defaultCurrency)} ={' '}
                      {formatPrice(
                        formik.values.currencyRate,
                        formik.values.quoteCurrency
                      )}
                    </Box>
                  </>
                )}
            </Box>

            <Box marginBottom={10} />

            <Box display="flex" className={classes.floatingFooter}>
              <Box flex="1">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={!formik.dirty}
                >
                  {formik.dirty && !formik.values.quoteCurrency
                    ? 'Reset Currency'
                    : 'Save Changes'}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </OOModal>
  );
};

export default QuoteCurrencyModal;
