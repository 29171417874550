import { Button, Typography } from '@material-ui/core';
import CheckMark from '@material-ui/icons/Done';
import clsx from 'clsx';
import React, { FC } from 'react';

import { SimpleCard } from '../../../components/Card';
import { Separator } from '../../../components/Separator';
import { Plan } from '../domain';
import useStyles from '../styles';

type PlanBoxProps = {
  plan: Plan | undefined;
  owned?: boolean;
  showButton?: boolean;
  daysLeft?: number | undefined;
  onButtonClick?: (plan: Plan) => void;
};

const PlanBox: FC<PlanBoxProps> = ({
  plan,
  owned = false,
  daysLeft = undefined,
  showButton = false,
  onButtonClick,
}) => {
  const { planCard, planActive, planDescriptionItem } = useStyles();
  return (
    <SimpleCard className={clsx(planCard, owned && planActive)}>
      <Typography variant="h6" align="center">
        {plan?.name ?? 'Free'}
      </Typography>
      <Typography variant="h4" align="center">
        {plan ? plan.price / plan.duration / 100 : 0}€
        <Typography variant="body2" component="small">
          {plan?.isPricePerUser ? '/user' : ''}/month
        </Typography>
      </Typography>
      {plan && (
        <Typography variant="body2" align="center">
          Paid{' '}
          {plan.duration === 1
            ? 'monthly'
            : plan.duration === 12
            ? 'yearly'
            : `every ${plan.duration} months`}
        </Typography>
      )}
      <Separator />
      {plan ? (
        <>
          <Typography
            variant="body2"
            className={planDescriptionItem}
            gutterBottom
          >
            <CheckMark /> Everything from the Free Plan
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body2" className={planDescriptionItem}>
            <CheckMark /> Basic quoting
          </Typography>
          <Typography variant="body2" className={planDescriptionItem}>
            <CheckMark /> Product Catalog
          </Typography>
          <Typography
            variant="body2"
            className={planDescriptionItem}
            gutterBottom
          >
            <CheckMark /> Client Database
          </Typography>
        </>
      )}
      {showButton && (
        <Button
          fullWidth
          variant="contained"
          color={owned ? 'default' : 'primary'}
          disabled={owned}
          onClick={() => plan && onButtonClick && onButtonClick(plan)}
        >
          {owned
            ? daysLeft
              ? `${daysLeft} days left`
              : 'You own this'
            : 'Buy'}
        </Button>
      )}
    </SimpleCard>
  );
};

export default PlanBox;
