import { Button, InputLabel, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, ReactElement } from 'react';

const useLocalStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.pxToRem(13),
    marginBottom: theme.spacing(0.5),
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  activeSwitch: {
    color: theme.palette.primary.main,
    border: `solid 2px ${theme.palette.primary.main}`,
  },
  switchButton: {
    width: 200,
  },
}));

type ButtonSwitchInputProps = {
  className?: string;

  formik: ReturnType<typeof useFormik>;
  name: string;
  label?: string;
  values: {
    label: string;
    value: string;
    icon: ReactElement;
  }[];
  showError?: boolean;
};

const ButtonSwitchInput: FC<ButtonSwitchInputProps> = ({
  className,

  formik,
  name,
  label,
  values,
  showError = true,
}) => {
  const localClasses = useLocalStyles();

  return (
    <>
      {label && <InputLabel className={localClasses.label}>{label}</InputLabel>}
      <div className={clsx(localClasses.buttonWrapper, className)}>
        {values.map((val) => (
          <Button
            key={val.value}
            variant="outlined"
            className={clsx(
              formik.values[name] === val.value && localClasses.activeSwitch,
              localClasses.switchButton
            )}
            onClick={(event) => {
              event.preventDefault();
              formik.setFieldValue(name, val.value);
            }}
          >
            <div>
              <div>{val.icon}</div>
              <div>{val.label}</div>
            </div>
          </Button>
        ))}
      </div>
      {showError && formik.touched[name] && formik.errors[name] ? (
        <div className="text-error">{formik.errors[name]}</div>
      ) : null}
    </>
  );
};

export default ButtonSwitchInput;
