import { OptiTextField } from '@components';
import {
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useIsMobile,
} from '@lib';
import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  NewOffer_GenerateGDriveDocumentsDocument,
  OfferInOfferModuleFragment,
} from '@optioffer/graphql';

import InfoButton from '@components/InfoButton';
import { gDriveFields } from '@components/googleDriveTemplates/domain';
import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';

import useStyles from '../styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

export type GenerateGDriveDocumentsModalData = {
  offer: OfferInOfferModuleFragment;
};

type GenerateGDriveDocumentsModalProps = {
  control: ModalControl<GenerateGDriveDocumentsModalData>;
};

const GenerateGDriveDocumentsModal: FC<GenerateGDriveDocumentsModalProps> = ({
  control,
}) => {
  const classes = useStyles();
  // const localClasses = useLocalStyles();
  const isMobile = useIsMobile();

  const [generateGDriveDocuments] = useEnhancedMutation(
    NewOffer_GenerateGDriveDocumentsDocument,
    {
      success: {
        message: 'Google drive documents generated',
        action: () => (
          <Button
            color="inherit"
            size="small"
            onClick={() => openGoogleDrive()}
          >
            Open google drive
          </Button>
        ),
      },
      error: {
        type: 'MODAL',
        message:
          'There was an error while generating your google drive documents. Please ensure that the given links are correct and you allowed OptiDriveGenerator to use them. If this persists, please contact support.',
      },
    }
  );

  const lastSuccessfulGDriveFolderLinkRef = useRef('');
  const openGoogleDrive = () => {
    const newWindow = window.open(
      lastSuccessfulGDriveFolderLinkRef.current,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  const formik = useFormik({
    initialValues: {
      gDriveTemplateFile: '',
      gDriveTargetFolder: '',
      gDriveEveryProduct: false,
    },
    onSubmit: async (values) => {
      if (!control.data) return;

      try {
        await generateGDriveDocuments({
          variables: {
            offerId: control.data.offer.id,
            googleDriveTemplateFile: values.gDriveTemplateFile,
            googleDriveFolderLink: values.gDriveTargetFolder,
            generateForEveryProduct: values.gDriveEveryProduct,
          },
        });
        lastSuccessfulGDriveFolderLinkRef.current = values.gDriveTargetFolder;

        control.close();
      } catch {
        NOOP_graphqlErrorManagement();
      }
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.data) {
      formikRef.current.setValues({
        gDriveTemplateFile:
          control.data.offer.ownedByCompany.gDriveTemplateFile ?? '',
        gDriveTargetFolder:
          control.data.offer.ownedByCompany.gDriveTargetFolder ?? '',
        gDriveEveryProduct:
          control.data.offer.ownedByCompany.gDriveEveryProduct ?? false,
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = 'Generate GDrive Documents';
  return (
    <OOModal open={control.isOpen} onClose={control.close} title={title}>
      <Box display="flex">
        <Box
          flex={1}
          marginLeft={isMobile ? 0 : 4}
          marginRight={isMobile ? 0 : 6}
          marginBottom={isMobile ? 0 : 4}
        >
          <form onSubmit={formik.handleSubmit}>
            {!isMobile && (
              <Box className={classes.desktopModalTitle}>{title}</Box>
            )}

            <Box paddingX={1.5} marginBottom={10}>
              <Box marginY={2} display="flex" alignItems="center">
                <OptiTextField
                  required
                  label="Google Drive template file"
                  {...mapFormikToTextField(formik, 'gDriveTemplateFile')}
                />
                <Box marginTop="20px">
                  <InfoButton
                    title={gDriveFields[0].infoModal?.title!}
                    message={gDriveFields[0].infoModal?.message}
                  />
                </Box>
              </Box>
              <Box marginY={2} display="flex" alignItems="center">
                <OptiTextField
                  required
                  label="Google Drive target folder"
                  {...mapFormikToTextField(formik, 'gDriveTargetFolder')}
                />
                <Box marginTop="20px">
                  <InfoButton
                    title={gDriveFields[1].infoModal?.title!}
                    message={gDriveFields[1].infoModal?.message}
                  />
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...formik.getFieldProps({
                          name: 'gDriveEveryProduct',
                          type: 'checkbox',
                        })}
                      />
                    }
                    label="Generate 1 file for every offer item"
                  />
                </Box>
                <InfoButton
                  title={gDriveFields[2].infoModal?.title!}
                  message={gDriveFields[2].infoModal?.message}
                />
              </Box>
            </Box>

            <Box display="flex" className={classes.floatingFooter}>
              <Box flex="1">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={
                    !formik.values.gDriveTemplateFile.length ||
                    !formik.values.gDriveTargetFolder.length
                  }
                >
                  Generate Documents
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </OOModal>
  );
};

export default GenerateGDriveDocumentsModal;
