import { uuid } from '@dvkiin/material-commons';
import {
  useEnhancedProgrammaticQuery,
  useIsMobile,
  useMenu,
  useModal,
} from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  Addon,
  AddonInOfferModuleFragment,
  AddonInput,
  CalculationType,
  Currency,
  DiscountInput,
  OfferInOfferModuleFragment,
  OfferItem,
  OfferItemInOfferModuleFragment,
  ProductInProductSectionModuleFragment,
  ProductSection_GetProductDocument,
  ProductVersionInProductSectionModuleFragment,
  Tax,
} from '@optioffer/graphql';
import { BasicLayoutVariant } from '@optioffer/printing';

import AddonModal, {
  AddonModalData,
} from '@containers/ProductSection/AddonModal';
import OfferItemModal from '@containers/ProductSection/OfferItemModal';
import ProductModal from '@containers/ProductSection/ProductModal';
import { mapToOfferItem } from '@containers/ProductSection/domain';

import { getProductSearchResult, ProductSearchResult } from '../../domain';
import CompanyIntroModal, { CompanyIntroModalData } from '../CompanyIntroModal';
import QuoteDiscountModal, { QuoteDiscountModalData } from '../DiscountModal';
import PreviewModal from '../PreviewModal';
import PricingLevelModal, { PricingLevelModalData } from '../PricingLevelModal';
import QuoteCurrencyModal, {
  QuoteCurrencyModalData,
} from '../QuoteCurrencyModal';
import QuoteLanguageModal, {
  QuoteLanguageModalData,
} from '../QuoteLanguageModal';
import QuoteTaxesModal, { QuoteTaxesModalData } from '../QuoteTaxesModal';
import QuoteTemplateModal, {
  QuoteTemplateModalData,
} from '../QuoteTemplateModal';
import TermsAndConditionsModal, {
  TermsAndConditionsModalData,
} from '../TermsAndConditionsModal';
import ReviewModalContext from './context';
import ReviewModalDesktop from './desktop';
import ReviewModalMobile from './mobile';

type ReviewModalProps = {
  quote: OfferInOfferModuleFragment;
  updateOffer(
    offer: OfferInOfferModuleFragment
  ): Promise<OfferInOfferModuleFragment>;
  setStep(step: number): void;
};

const Index: FC<ReviewModalProps> = ({ quote, updateOffer, setStep }) => {
  const isMobile = useIsMobile();

  const [currentAccessoryParent, setCurrentAccessoryParent] = useState<
    OfferItemInOfferModuleFragment | undefined
  >(undefined);
  const companyIntroModal = useModal<CompanyIntroModalData>();
  const termsAndConditionsModal = useModal<TermsAndConditionsModalData>();
  const productModal = useModal<ProductSearchResult>();
  const accessoryModal = useModal<ProductSearchResult>();
  const offerItemModal = useModal<ProductSearchResult>();
  const offerItemAccessoryModal = useModal<ProductSearchResult>();
  const addonModal = useModal<AddonModalData>();
  const discountModal = useModal<QuoteDiscountModalData>();
  const quoteTemplateModal = useModal<QuoteTemplateModalData>();
  const quoteLanguageModal = useModal<QuoteLanguageModalData>();
  const quoteCurrencyModal = useModal<QuoteCurrencyModalData>();
  const pricingLevelModal = useModal<PricingLevelModalData>();
  const quoteTaxesModal = useModal<QuoteTaxesModalData>();
  const previewModal = useModal();
  const quoteDetailsMenu = useMenu();
  const quoteSummaryMenu = useMenu();

  const formik = useFormik({
    initialValues: {
      offerItems: [] as OfferItemInOfferModuleFragment[],
      companyIntro: '',
      termsAndConditions: '',
      createdAt: 0,
      expireAt: 0,
      creator: '',
      quoteLanguage: 'en' as string,
      quoteTemplate: BasicLayoutVariant.COMPACT,
      quoteCurrency: {} as Currency,
      discountType: CalculationType.PERCENTAGE,
      discountValue: 0,
      addons: [] as AddonInOfferModuleFragment[],
      displayDiscounts: true,
      taxes: [] as Tax[],
    },
    onSubmit: async (values) => {
      await saveQuote(values);
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const [getProduct] = useEnhancedProgrammaticQuery(
    ProductSection_GetProductDocument,
    {
      error: {
        type: 'MODAL',
        message: 'An error occurred while loading the product.',
      },
    }
  );

  async function saveQuote(
    values = formik.values
  ): Promise<OfferInOfferModuleFragment> {
    return await updateOffer({
      ...quote,
      offerItems: values.offerItems,
      expireAt: values.expireAt,
      offerDiscount: {
        value: values.discountValue,
        calculationType: values.discountType,
      },
      addons: values.addons,
      currency: values.quoteCurrency,
      displayDiscounts: values.displayDiscounts,
      taxes: values.taxes,
      template: values.quoteTemplate,
      language: values.quoteLanguage,
      companyIntro: values.companyIntro,
      termsAndConditions: values.termsAndConditions,
    });
  }

  useEffect(() => {
    formikRef.current.resetForm({
      values: {
        offerItems: quote.offerItems as OfferItemInOfferModuleFragment[],
        companyIntro: quote.companyIntro,
        termsAndConditions: quote.termsAndConditions,
        createdAt: quote.createdAt ?? 0,
        expireAt: quote.expireAt,
        creator: quote.createdBy,
        quoteLanguage: quote.language || 'en',
        quoteTemplate:
          (quote.template as BasicLayoutVariant) || BasicLayoutVariant.DETAILED,
        discountValue: quote.offerDiscount?.value ?? 0,
        discountType:
          quote.offerDiscount?.calculationType ?? CalculationType.PERCENTAGE,
        addons: quote.addons ?? [],
        quoteCurrency: quote.currency,
        displayDiscounts: quote.displayDiscounts,
        taxes: quote.taxes ?? [],
      },
    });
  }, [formikRef, quote]);

  const pricing = quote.pricing;

  async function handleUpdateQuoteTemplate(quoteTemplate: string) {
    await formik.setFieldValue('quoteTemplate', quoteTemplate);
    await formik.submitForm();
  }

  async function handleUpdateQuoteLanguage(quoteLanguage: string) {
    await formik.setFieldValue('quoteLanguage', quoteLanguage);
    await formik.submitForm();
  }

  async function handleUpdateQuoteCurrency(quoteCurrency: Currency) {
    await formik.setFieldValue('quoteCurrency', quoteCurrency);
    await formik.submitForm();
  }

  async function handleUpdatePricingLevel(displayDiscounts: boolean) {
    await formik.setFieldValue('displayDiscounts', displayDiscounts);
    await formik.submitForm();
  }

  async function handleUpdateQuoteTaxes(taxes: Tax[]) {
    await formik.setFieldValue('taxes', taxes);
    await formik.submitForm();
  }

  async function handleUpdateCompanyIntro(companyIntro: string): Promise<void> {
    await formik.setFieldValue('companyIntro', companyIntro);
    await formik.submitForm();
  }

  function handleEditTermsAndConditions() {
    termsAndConditionsModal.open({
      termsAndConditions: formik.values.termsAndConditions,
    });
  }

  async function handleUpdateTermsAndConditions(
    termsAndConditions: string
  ): Promise<void> {
    await formik.setFieldValue('termsAndConditions', termsAndConditions);
    await formik.submitForm();
  }

  const handleMoveOfferItem = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const newOfferItems = [...formikRef.current.values.offerItems];
      const dragTarget = newOfferItems[dragIndex];

      newOfferItems.splice(dragIndex, 1);
      newOfferItems.splice(hoverIndex, 0, dragTarget);

      await formikRef.current.setFieldValue('offerItems', newOfferItems);
    },
    [formikRef]
  );

  const handleMoveAccessory = useCallback(
    async (
      parentId: OfferItem['id'],
      dragIndex: number,
      hoverIndex: number
    ) => {
      const children = formikRef.current.values.offerItems.find(
        (it) => it.id === parentId
      )?.children;
      if (!children || !children.length) return;
      const newChildren = [...children];
      const dragTarget = newChildren[dragIndex];

      newChildren.splice(dragIndex, 1);
      newChildren.splice(hoverIndex, 0, dragTarget);

      await formikRef.current.setFieldValue(
        'offerItems',
        formikRef.current.values.offerItems.map((it) =>
          it.id === parentId ? { ...it, children: newChildren } : it
        )
      );
    },
    [formikRef]
  );

  async function handleEditDiscount() {
    discountModal.open({
      discount: {
        value: formik.values.discountValue,
        calculationType: formik.values.discountType,
      },
    });
  }

  async function handleUpdateDiscount(discount: DiscountInput) {
    await formik.setFieldValue('discountType', discount.calculationType);
    await formik.setFieldValue('discountValue', discount.value);

    await formik.submitForm();
  }

  async function handleEditAddon(addon?: Addon) {
    addonModal.open({
      addon: addon
        ? {
            ...addon,
            addonDiscount: addon.discount,
          }
        : undefined,
    });
  }

  async function handleUpdateAddon(addon: AddonInput) {
    const newAddon: Addon = {
      ...addon,
      id: addon.id ?? uuid(),
      discount: addon.addonDiscount,
      pricing: undefined as any,
    };
    if (formik.values.addons.some((it) => it.id === addon.id)) {
      await formik.setFieldValue(
        'addons',
        formik.values.addons.map((oldAddon) =>
          oldAddon.id === newAddon.id ? newAddon : oldAddon
        )
      );
    } else {
      await formik.setFieldValue('addons', [...formik.values.addons, newAddon]);
    }

    await formik.submitForm();
  }

  async function handleUpdateOfferItem(
    offerItem: OfferItem
  ): Promise<OfferItem> {
    const newOfferItems = formik.values.offerItems
      .map((oldOfferItem) =>
        oldOfferItem.id === offerItem.id ? { ...offerItem } : oldOfferItem
      )
      .filter((oi) => !!oi.quantity);
    await formik.setFieldValue('offerItems', newOfferItems);
    const newOffer: OfferInOfferModuleFragment = await saveQuote({
      ...formik.values,
      offerItems: newOfferItems,
    });
    const savedOfferItem =
      (newOffer.offerItems.find(
        (newOfferItem) => newOfferItem?.id === offerItem.id
      ) as OfferItem) ?? offerItem;
    if (offerItemModal.isOpen) {
      offerItemModal.open({
        offerItem: savedOfferItem,
        ...getProductSearchResult(savedOfferItem)!,
      } as any);
    }
    return savedOfferItem;
  }

  async function handleUpdateAccessory(
    accessory: OfferItem,
    parentId?: OfferItem['id']
  ): Promise<OfferItem> {
    const parent = formik.values.offerItems.find(
      (oi) => oi?.id === (parentId ?? currentAccessoryParent?.id)
    );
    if (!parent) return accessory;
    parent.children = parent.children
      ?.map((oi) => (oi.id === accessory.id ? accessory : oi))
      .filter((oi) => !!oi.quantity) ?? [accessory];

    const savedParent = await handleUpdateOfferItem(parent as OfferItem);

    return (
      savedParent.children?.find((acc) => acc.id === accessory.id) ?? accessory
    );
  }

  async function handleProductSave(
    product: ProductInProductSectionModuleFragment,
    version: ProductVersionInProductSectionModuleFragment
  ) {
    const data = { product, version };
    const offerItem = formik.values.offerItems.find(
      (oi) =>
        oi.product?.productId === product.id &&
        oi.product?.productVersionId === version.id
    );

    // update fields
    if (offerItem?.product) {
      const { product: offerItemProduct } = mapToOfferItem(
        data,
        product,
        version
      );
      offerItem.product = offerItemProduct;

      await handleUpdateOfferItem(offerItem as any);
    }
    offerItemModal.open(data);
  }

  async function handleProductAccessorySave(
    product: ProductInProductSectionModuleFragment,
    version: ProductVersionInProductSectionModuleFragment
  ) {
    const data = { product, version };
    const parent = formik.values.offerItems.find(
      (oi) => oi?.id === currentAccessoryParent?.id
    );
    const accessory = parent?.children?.find(
      (oi) =>
        oi.product?.productId === product.id &&
        oi.product?.productVersionId === version.id
    );
    if (!accessory) return;

    // update fields
    if (accessory?.product) {
      const { product: offerItemProduct } = mapToOfferItem(
        data,
        product,
        version
      );
      accessory.product = offerItemProduct;

      await handleUpdateAccessory(accessory as any, parent!.id);
    }

    // not sure why in this case we need to reset the modal data
    // for root offer items and in the product step it works fine ¯\_(ツ)_/¯
    offerItemAccessoryModal.close();
    offerItemAccessoryModal.open(data);
  }

  return (
    <>
      <ReviewModalContext.Provider
        value={{
          getProduct,
          quote,

          companyIntroModal,
          termsAndConditionsModal,
          offerItemModal,
          offerItemAccessoryModal,
          addonModal,
          discountModal,
          quoteTemplateModal,
          quoteLanguageModal,
          quoteCurrencyModal,
          pricingLevelModal,
          quoteTaxesModal,
          previewModal,
          quoteDetailsMenu,
          quoteSummaryMenu,

          formik,
          pricing,
          currentAccessoryParent,

          handleEditTermsAndConditions,
          handleMoveOfferItem,
          handleMoveAccessory,
          handleUpdateCompanyIntro,
          handleUpdateTermsAndConditions,
          handleUpdateOfferItem,
          handleUpdateAccessory,
          handleUpdateDiscount,
          handleUpdateAddon,
          handleUpdateQuoteTemplate,
          handleUpdateQuoteLanguage,
          handleUpdateQuoteCurrency,
          handleUpdatePricingLevel,
          handleUpdateQuoteTaxes,
          setCurrentAccessoryParent,
          setStep,

          handleEditAddon,
          handleEditDiscount,
        }}
      >
        {isMobile ? <ReviewModalMobile /> : <ReviewModalDesktop />}

        <PreviewModal
          control={previewModal}
          confirm={() => setStep(4)}
          offer={quote}
          variant={formik.values.quoteTemplate}
          locale={formik.values.quoteLanguage}
        />
        <CompanyIntroModal
          control={companyIntroModal}
          updateCompanyIntro={handleUpdateCompanyIntro}
        />
        <TermsAndConditionsModal
          control={termsAndConditionsModal}
          updateTermsAndConditions={handleUpdateTermsAndConditions}
        />

        <OfferItemModal
          control={offerItemModal}
          updateOfferItem={handleUpdateOfferItem}
          openEditProduct={productModal.open}
          getProduct={getProduct}
          offerItems={(quote.offerItems ?? []) as OfferItem[]}
        />
        <OfferItemModal
          isAccessoryModal
          control={offerItemAccessoryModal}
          updateOfferItem={handleUpdateAccessory}
          openEditProduct={accessoryModal.open}
          getProduct={getProduct}
          offerItems={(currentAccessoryParent?.children ?? []) as OfferItem[]}
        />
        <ProductModal
          control={productModal}
          onProductSave={handleProductSave}
        />
        <ProductModal
          control={accessoryModal}
          onProductSave={handleProductAccessorySave}
        />
        <QuoteDiscountModal
          control={discountModal}
          updateDiscount={handleUpdateDiscount}
        />
        <AddonModal control={addonModal} updateAddon={handleUpdateAddon} />
        <QuoteTemplateModal
          control={quoteTemplateModal}
          updateQuoteTemplate={handleUpdateQuoteTemplate}
        />
        <QuoteLanguageModal
          control={quoteLanguageModal}
          updateQuoteLanguage={handleUpdateQuoteLanguage}
        />
        <QuoteCurrencyModal
          control={quoteCurrencyModal}
          updateQuoteCurrency={handleUpdateQuoteCurrency}
        />
        <PricingLevelModal
          control={pricingLevelModal}
          updatePricingLevel={handleUpdatePricingLevel}
        />
        <QuoteTaxesModal
          control={quoteTaxesModal}
          updateQuoteTaxes={handleUpdateQuoteTaxes}
        />
      </ReviewModalContext.Provider>
    </>
  );
};

export default Index;
