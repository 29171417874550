import { useHasRole } from '@lib';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import HelpIcon from '@material-ui/icons/Help';
import React, { FC } from 'react';

import { UserRole } from '@optioffer/graphql';

import { HeavyDutyResult } from '@components/Feedback/domain';

import useStyles from '../../styles';
import DeleteProducts from './DeleteProducts';
import ImportProductImages from './ImportProductImages';
import ImportProducts from './ImportProducts';
import UpdateProducts from './UpdateProducts';

type ImportSectionProps = {
  simulateImport: (file: File) => Promise<HeavyDutyResult[]>;
  importProducts: (file: File) => Promise<HeavyDutyResult[]>;

  validateImageImport: (files: File[]) => Promise<HeavyDutyResult[]>;
  importProductImages: (files: File[]) => Promise<HeavyDutyResult[]>;

  validateBulkUpdateProducts: (file: File) => Promise<HeavyDutyResult[]>;
  bulkUpdateProducts: (file: File) => Promise<HeavyDutyResult[]>;

  validateBulkDeleteProducts: (file: File) => Promise<HeavyDutyResult[]>;
  bulkDeleteProducts: (file: File) => Promise<HeavyDutyResult[]>;
};

const ImportSection: FC<ImportSectionProps> = ({
  simulateImport,
  importProducts,

  validateImageImport,
  importProductImages,

  validateBulkUpdateProducts,
  bulkUpdateProducts,

  validateBulkDeleteProducts,
  bulkDeleteProducts,
}) => {
  const { importCard, bulkOperationsHeader, addButton } = useStyles();

  const isManager = useHasRole(UserRole.MANAGER);

  if (!isManager) {
    return null;
  }

  return (
    <>
      <Paper className={importCard}>
        <Typography className={bulkOperationsHeader} variant="h5" gutterBottom>
          Import products
        </Typography>
        <Typography variant="body1" gutterBottom>
          Use this to add products from a CSV file.
        </Typography>
        <div>
          <ImportProducts
            simulateImport={simulateImport}
            executeImport={importProducts}
          />
          <Button
            className={addButton}
            variant="contained"
            color="secondary"
            startIcon={<HelpIcon />}
          >
            View docs
          </Button>
          <Button
            className={addButton}
            variant="contained"
            color="secondary"
            startIcon={<DownloadIcon />}
          >
            Download template
          </Button>
        </div>
      </Paper>

      <Paper className={importCard}>
        <Typography className={bulkOperationsHeader} variant="h5" gutterBottom>
          Import product images
        </Typography>
        <Typography variant="body1" gutterBottom>
          Use this to upload images for your products.
        </Typography>
        <div>
          <ImportProductImages
            validateImport={validateImageImport}
            executeImport={importProductImages}
          />
          <Button
            className={addButton}
            variant="contained"
            color="secondary"
            startIcon={<HelpIcon />}
          >
            View docs
          </Button>
        </div>
      </Paper>

      <Paper className={importCard}>
        <Typography className={bulkOperationsHeader} variant="h5" gutterBottom>
          Update multiple products
        </Typography>
        <Typography variant="body1" gutterBottom>
          Use this to make changes to your products using a CSV.
        </Typography>
        <div>
          <UpdateProducts
            validateUpdate={validateBulkUpdateProducts}
            executeUpdate={bulkUpdateProducts}
          />
          <Button
            className={addButton}
            variant="contained"
            color="secondary"
            startIcon={<HelpIcon />}
          >
            View docs
          </Button>
          <Button
            className={addButton}
            variant="contained"
            color="secondary"
            startIcon={<DownloadIcon />}
          >
            Download template
          </Button>
        </div>
      </Paper>

      <Paper className={importCard}>
        <Typography className={bulkOperationsHeader} variant="h5" gutterBottom>
          Delete multiple products
        </Typography>
        <Typography variant="body1" gutterBottom>
          Use this to delete multiple products using a CSV.
        </Typography>
        <div>
          <DeleteProducts
            validateDelete={validateBulkDeleteProducts}
            executeDelete={bulkDeleteProducts}
          />
          <Button
            className={addButton}
            variant="contained"
            color="secondary"
            startIcon={<HelpIcon />}
          >
            View docs
          </Button>
          <Button
            className={addButton}
            variant="contained"
            color="secondary"
            startIcon={<DownloadIcon />}
          >
            Download template
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default ImportSection;
