import gql from 'graphql-tag';

import {
  costFields,
  cumulatedDiscountCalculatedFields,
  cumulatedTaxCalculatedFields,
  currencyFields,
  discountCalculatedFields,
  discountFields,
  productListReportFields,
  productReportFields,
  taxCalculatedFields,
  taxFields,
} from '@optioffer/core';

const HEAVY_DUTY_FIELDS = `
  id
  status
  message
  data
`;

const commonClientFields = `
  companyAddress
  companyEmail
  companyPhoneNumber
  companyName
  companyVatId
  contactPersonName
  email
  name
  phoneNumber
`;

const offerClientFields = `
  clientId
  ${commonClientFields}
`;

const clientFields = `
  id
  ${commonClientFields}
`;

const productFields = `
  product {
    id
    offerId
    productId
    productVersionId
    name
    code
    description
    image {
      id
      fullImage 
      thumbnail
      fileName
    }
    price
    specifications {
      id
      name
      unit
      value
    }
   }
`;

export const offerItemPriceFields = `
    offerItemCurrency {
      ${currencyFields}
    }
    listPrice
    quantity
    listPriceTotal
    taxesBeforeDiscount { 
      ${taxCalculatedFields}
    }
    discount {
      ${discountCalculatedFields}
    }
    discountFinal {
      ${discountCalculatedFields}
    }
    taxesAfterDiscount {
      ${taxCalculatedFields}
    }
    taxesFinal {
      ${taxCalculatedFields}
    }
    netPrice
    netPriceTotal
`;

export const offerPriceFields = `
    listPrice
    offerCurrency {
      ${currencyFields}
    }
    taxesBeforeDiscount {
      ${cumulatedTaxCalculatedFields}
    }
    discount {
      ${cumulatedDiscountCalculatedFields}
    }
    taxesAfterDiscount {
      ${cumulatedTaxCalculatedFields}
    }
    discountFinal {
      ${cumulatedDiscountCalculatedFields}
    }
    taxesFinal {
      ${cumulatedTaxCalculatedFields}
    }
    netPrice
`;

export const addonPriceFields = `
  listPrice
  netPrice
  taxesBeforeDiscount {
    ${taxCalculatedFields}
  }
  discount {
    ${discountCalculatedFields}
  }
  taxesAfterDiscount {
    ${taxCalculatedFields}
  }
  discountFinal {
    ${discountCalculatedFields}
  }
  taxesFinal {
    ${taxCalculatedFields}
  }
  addonCurrency {
    ${currencyFields}
  }
`;

export const addonFields = `
  id
  name
  price
  discount {
    ${discountFields}
  }
  pricing {
    ${addonPriceFields}
  }
  cost {
    ${costFields}
  }
  report {
    ${productReportFields}
  }
`;

const offerFields = `
  id
  title
  taxes {
    ${taxFields}
  }
  offerDiscount { 
    ${discountFields}
  }
  pricing {
    ${offerPriceFields}
  }
  report {
    ${productListReportFields}
  }
  displayDiscounts
  client {
    ${offerClientFields}
  }
  createdBy
  createdByUser {
    email
    roles
    name
    title
    companyName
    phoneNumber
    avatar {
      id
      fullImage 
      thumbnail
      fileName
      height
      width
    }
  }
  createdAt
  expireAt
  ownedBy
  ownedByCompany {
    id
    name
    email
    address
    addressSecondary
    website
    phoneNumber
    motto
    vat
    companyIntro
    companyThankYouMessage
    companyLogo {
      id
      fullImage 
      thumbnail
      fileName
      height
      width
    }
    currency {
      symbol
      id
    }
    smartBillApiKey
    smartBillEmail
  }
  offerItems {
    id
    pricing {
      ${offerItemPriceFields}
    }
    offerItemDiscount {
      ${discountFields}
    }
    parentId
    quantity
    children {
      id
      parentId
      quantity
      pricing {
        ${offerItemPriceFields}
      }
      offerItemDiscount {
        ${discountFields}
      }
      addons {
        ${addonFields}
      }
      ${productFields}
    }
    addons {
      ${addonFields}
    }
    ${productFields}
  }
  addons {
    ${addonFields}
  }
  currency {
    symbol
    name
    rate
    id
  }
  status
  lastModifiedDate
`;

export const FIND_ALL_OFFERS = gql`
  query findAllOffers($pagination: Pagination) {
    findAllOffers(pagination: $pagination) {
      total
      offers {
        id
        title
        client {
          name
        }
        report {
          ${productListReportFields}
        }
        offerItemsCount
        createdBy
        status
        createdAt
        expireAt
        ownedByCompany {
          id
          currency {
            id
            symbol
          }
        }
        lastModifiedDate
      }
    }
  }
`;

export const CREATE_OFFER = gql`
  mutation createOffer($clientId: ID!, $title: String) {
    createOffer(clientId: $clientId, title: $title) {
      id
      client {
        name
      }
    }
  }
`;

export const CLONE_OFFER = gql`
  mutation cloneOffer($id: ID!) {
    cloneOffer(id: $id) {
      ${offerFields}
    }
  }
`;

export const SEND_OFFER_TO_CLIENT = gql`
  mutation sendOfferToClient($id: ID!, $sendToUserAlso:Boolean!, $offerPdf: Upload!) {
    sendOfferToClient(id: $id, sendToUserAlso: $sendToUserAlso, offerPdf: $offerPdf) {
      ${offerFields}
    }
  }
`;

export const SEND_SMARTBILL_PROFORMA = gql`
  mutation sendProformaToSmartBill($offerId: String!) {
    sendProformaToSmartBill(offerId: $offerId) {
      ${offerFields}
    }
  }
`;

export const FIND_ALL_CLIENTS = gql`
  query findAllClients($pagination: Pagination) {
    findAllClients(pagination: $pagination) {
      total
      clients {
        id
        name
        companyName
      }
    }
  }
`;

export const FIND_ALL_CLIENTS_BY_COMPLEX_FILTER = gql`
  query findAllClientsByComplexFilter($topCount: Int!, $filter: String!) {
    findAllClientsByComplexFilter(topCount:$topCount, filter:$filter) {
      ${clientFields}
    }
  }
`;

export const GET_OFFER = gql`
  query getOffer($id: ID!) {
    getOffer(id: $id) {
      ${offerFields}
    }
    currentUser {
      id
      emailPreferences {
        smtpUser
      }
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation updateOffer($id: ID!, $offer: OfferInputUpdate!) {
    updateOffer(id: $id, offer: $offer) {
      ${offerFields}
    }
  }
`;

export const UPDATE_OFFER_CURRENCY = gql`
  mutation updateOfferCurrency($id: ID!, $currency: OfferCurrencyUpdateInput!) {
    updateOfferCurrency(id: $id, currency: $currency) {
      ${offerFields}
    }
  }
`;

export const FIND_ALL_PRODUCTS_BY_FILTER = gql`
  query($topCount: Int!, $searchString: String) {
    findAllProductsByFilter(topCount: $topCount, searchString: $searchString) {
      id
      name
      image {
        id
        thumbnail
      }
      versions {
        id
        code
        price
      }
    }
  }
`;

export const GET_RECOMMENDED_ACCESSORIES = gql`
  query($productId: ID!, $productVersionId: ID!) {
    getRecommendedAccessories(
      productId: $productId
      productVersionId: $productVersionId
    ) {
      product {
        id
        name
        image {
          id
          thumbnail
        }
      }
      productVersion {
        id
        code
        price
      }
    }
  }
`;

export const DELETE_OFFER = gql`
  mutation deleteOffer($id: ID!) {
    deleteOffer(id: $id)
  }
`;

export const ADD_OFFER_ITEMS = gql`
  mutation addOfferItems($id: ID!, $offerItems: [CreateOfferItemInput!]!) {
    updateOfferItems(id: $id) {
      add(offerItems: $offerItems) {
        ${offerFields}
      }
    }
  }
`;

export const MOVE_OFFER_ITEM = gql`
  mutation addOfferItem($id: ID!, $offerItemId: ID!, $parentId: ID, $position: Int!) {
    updateOfferItems(id: $id) {
      move(offerItemId: $offerItemId, parentId: $parentId, newPosition: $position) {
        ${offerFields}
      }
    }
  }
`;

export const DELETE_OFFER_ITEM = gql`
  mutation deleteOfferItem($id: ID!, $offerItemId: ID!) {
    updateOfferItems(id: $id) {
      delete(offerItemId: $offerItemId) {
        ${offerFields}
      }
    }
  }
`;

export const UPDATE_OFFER_ITEM = gql`
  mutation updateOfferItem($id: ID!, $offerItem: UpdateOfferItemInput!) {
    updateOfferItems(id: $id) {
      update(offerItem: $offerItem) {
        ${offerFields}
      }
    }
  }
`;

export const DISCOUNT_ALL_OFFER_ITEMS = gql`
  mutation discountAllOfferItems($id: ID!, $offerItemsDiscount: Float!) {
    updateOfferItems(id: $id) {
      discountAll(offerItemsDiscount: $offerItemsDiscount) {
        ${offerFields}
      }
    }
  }
`;

export const UPDATE_OFFER_TAX = gql`
  mutation updateOfferTax($id: ID!, $tax: UpdateOfferTaxInput!) {
    updateOfferTaxes(id: $id) {
      update(tax: $tax) {
        ${offerFields}
      }
    }
  }
`;

export const ADD_ADDONS = gql`
  mutation addAddons($id: ID!, $addons: [CreateAddonInput!]!) {
    updateOfferAddons(id: $id) {
      add(addons: $addons) {
        ${offerFields}
      }
    }
  }
`;

export const UPDATE_ADDON = gql`
  mutation addAddons($id: ID!, $addon: UpdateAddonInput!) {
    updateOfferAddons(id: $id) {
      update(addon: $addon) {
        ${offerFields}
      }
    }
  }
`;

export const DELETE_ADDON = gql`
  mutation addAddons($id: ID!, $addonId: ID!) {
    updateOfferAddons(id: $id) {
      delete(addonId: $addonId) {
        ${offerFields}
      }
    }
  }
`;

export const ADD_OFFER_ITEM_ADDONS = gql`
  mutation addOfferItemAddons($id: ID!, $offerItemId: ID!, $parentId: ID, $addons: [CreateAddonInput!]!) {
    updateOfferItems(id: $id) {
      mutateAddons(offerItemId: $offerItemId, parentId: $parentId) {
        add(addons: $addons) {
          ${offerFields}
        }
      }
    }
  }
`;

export const UPDATE_OFFER_ITEM_ADDON = gql`
  mutation updateOfferItemAddon($id: ID!, $offerItemId: ID!, $parentId: ID, $addon: UpdateAddonInput!) {
    updateOfferItems(id: $id) {
      mutateAddons(offerItemId: $offerItemId, parentId: $parentId) {
        update(addon: $addon) {
          ${offerFields}
        }
      }
    }
  }
`;

export const DELETE_OFFER_ITEM_ADDON = gql`
  mutation deleteOfferItemAddon($id: ID!, $offerItemId: ID!, $parentId: ID, $addonId: ID!) {
    updateOfferItems(id: $id) {
      mutateAddons(offerItemId: $offerItemId, parentId: $parentId) {
        delete(addonId: $addonId) {
          ${offerFields}
        }
      }
    }
  }
`;

export const VALIDATE_PO_GENERATION = gql`
  query validateGenerateOfferPOs($offerId: ID!) {
    simulateHeavyDuty {
      validateGenerateOfferPOs(offerId: $offerId) {
        ${HEAVY_DUTY_FIELDS}
      }
    }
  }
`;

export const GENERATE_POS = gql`
  mutation generateOfferPOs($offerId: ID!) {
    heavyDuty {
      generateOfferPOs(offerId: $offerId) {
        resultZipBase64
      }
    }
  }
`;

export const GET_COMPANY_DETAILS = gql`
  query getCompanyDetails {
    getCompanyDetails {
      id
      defaultOfferLanguage
      defaultOfferFormat
      gDriveTemplateFile
      gDriveTargetFolder
      gDriveEveryProduct
    }
  }
`;

export const GENERATE_GDRIVE_DOCUMENTS = gql`
  mutation generateGDriveDocuments($offerId: ID!, $generateForEveryProduct: Boolean!, $googleDriveFolderLink: String!, $googleDriveTemplateFile: String!) {
    generateGDriveDocuments(offerId: $offerId, generateForEveryProduct: $generateForEveryProduct,
      googleDriveFolderLink: $googleDriveFolderLink, googleDriveTemplateFile: $googleDriveTemplateFile) {
      ${offerFields}
    }
  }
`;

export const SEARCH_OFFERS = gql`
  query searchOffers(
    $searchString: String
    $pagination: Pagination
    $status: String
    $creationDate: Float
    $creatorId: String
  ) {
    searchOffers(
      searchString: $searchString
      pagination: $pagination
      status: $status
      creationDate: $creationDate
      creatorId: $creatorId
    ) {
      total
      offers {
        id
        title
        client {
          name
          companyName
        }
        report {
          ${productListReportFields}
        }
        createdBy
        status
        createdAt
        expireAt
        lastModifiedDate
      }
    }
  }
`;
