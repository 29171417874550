import { useEnhancedProgrammaticQuery, useIsMobile, useModal } from '@lib';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import React, { FC } from 'react';

import {
  Currency,
  OfferItem,
  ProductInProductSectionModuleFragment,
  ProductSection_GetProductDocument,
  ProductVersionInProductSectionModuleFragment,
} from '@optioffer/graphql';

import OfferItemModal from '@containers/ProductSection/OfferItemModal';
import ProductList from '@containers/ProductSection/ProductList';
import ProductModal from '@containers/ProductSection/ProductModal';
import { mapToOfferItem } from '@containers/ProductSection/domain';

import { ProductSearchResult } from '../domain';
import useStyles from '../styles';

// const useLocalStyles = makeStyles((theme) => ({
//
// }));

type ProductsStepProps = {
  offerItems: OfferItem[];
  setOfferItems: (offerItems: OfferItem[]) => void;
  setStep: (step: number) => void;
  currencyId: Currency['id'];
};

const ProductsStep: FC<ProductsStepProps> = ({
  offerItems,
  setOfferItems,
  setStep,
}) => {
  const classes = useStyles();
  // const localClasses = useLocalStyles();
  const isMobile = useIsMobile();

  const productModal = useModal<ProductSearchResult>();
  const offerItemModal = useModal<ProductSearchResult>();

  const [getProduct] = useEnhancedProgrammaticQuery(
    ProductSection_GetProductDocument,
    {
      error: {
        type: 'MODAL',
        message: 'An error occurred while loading the product.',
      },
    }
  );

  async function handleUpdateOfferItem(offerItem: OfferItem) {
    if (!offerItems.some((oi) => oi.id === offerItem.id)) {
      setOfferItems([...offerItems, offerItem]);
    } else {
      setOfferItems(
        offerItems
          .map((oi) => (oi.id === offerItem.id ? offerItem : oi))
          .filter((oi) => !!oi.quantity)
      );
    }
    return offerItem;
  }

  async function handleProductSave(
    product: ProductInProductSectionModuleFragment,
    version: ProductVersionInProductSectionModuleFragment
  ) {
    const data = { product, version };
    const offerItem = offerItems.find(
      (oi) =>
        oi.product?.productId === product.id &&
        oi.product?.productVersionId === version.id
    );

    // update fields
    if (offerItem?.product) {
      const { product: offerItemProduct } = mapToOfferItem(
        data,
        product,
        version
      );
      offerItem.product = offerItemProduct;

      await handleUpdateOfferItem(offerItem);
    }
    offerItemModal.open(data);
  }

  return (
    <div className="ProductsStep">
      <Box
        display="flex"
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
      >
        <ProductList
          getProduct={getProduct}
          productModal={productModal}
          offerItemModal={offerItemModal}
          offerItems={offerItems}
          updateOfferItem={handleUpdateOfferItem}
        />

        <Box className={clsx(classes.floatingFooter, 'floatOnDesktop')}>
          <Box display="flex">
            <Box paddingRight={2}>
              <Button
                className="NavigationButton"
                variant={isMobile ? 'text' : 'outlined'}
                fullWidth
                onClick={() => setStep(1)}
              >
                Back
              </Button>
            </Box>
            {!isMobile && <Box flex="1" />}
            <Box
              flex={isMobile ? 1 : undefined}
              display="flex"
              flexDirection="row-reverse"
            >
              <Button
                className="NavigationButton"
                variant="contained"
                color="primary"
                fullWidth
                disabled={false}
                onClick={() => setStep(3)}
              >
                Review Quote
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <OfferItemModal
        control={offerItemModal}
        updateOfferItem={handleUpdateOfferItem}
        openEditProduct={productModal.open}
        getProduct={getProduct}
        offerItems={offerItems}
      />

      <ProductModal control={productModal} onProductSave={handleProductSave} />
    </div>
  );
};

export default ProductsStep;
