import {
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedProgrammaticQuery,
  useIsMobile,
  useModal,
} from '@lib';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import * as React from 'react';
import { FC } from 'react';

import { downloadLink } from '@optioffer/core';
import {
  NewOffer_GenerateOfferPOsDocument,
  NewOffer_SendProformaToSmartBillDocument,
  NewOffer_ValidateGenerateOfferPOsDocument,
  OfferInOfferModuleFragment,
} from '@optioffer/graphql';

import { HeavyDutyResult } from '@components/Feedback/domain';
import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';
import { openSmartBill } from '@lib/smartbill';

import { GoogleDriveIcon, SmartBillIcon } from '@resources/icons';

import useStyles from '../styles';
import ConfirmGeneratePOsModal, {
  ConfirmGeneratePOsModalData,
} from './ConfirmGeneratePOsModal';
import GenerateGDriveDocumentsModal, {
  GenerateGDriveDocumentsModalData,
} from './GenerateGDriveDocumentsModal';

// const useLocalStyles = makeStyles(() => ({
//   root: {},
// }));

export type ExportModalData = {
  offer: OfferInOfferModuleFragment;
};

type ExportModalProps = {
  control: ModalControl<ExportModalData>;
  downloadQuote: () => Promise<any>;
};

const ExportModal: FC<ExportModalProps> = ({ control, downloadQuote }) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const confirmGeneratePOsModal = useModal<ConfirmGeneratePOsModalData>();
  const generateGDriveDocumentModal = useModal<GenerateGDriveDocumentsModalData>();

  const [validateGeneratePO] = useEnhancedProgrammaticQuery(
    NewOffer_ValidateGenerateOfferPOsDocument,
    {
      fetchPolicy: 'network-only',
      error: {
        type: 'SNACKBAR',
        message: 'An error occurred while generating the POs.',
      },
    }
  );
  const [generatePOs] = useEnhancedMutation(NewOffer_GenerateOfferPOsDocument, {
    success: {
      message: 'POs generated.',
    },
    error: {
      type: 'MODAL',
      message: 'An error occurred while generating the POs.',
    },
  });
  const [sendToSmartbill] = useEnhancedMutation(
    NewOffer_SendProformaToSmartBillDocument,
    {
      success: {
        message: 'Proforma generated.',
        action: () => (
          <Button color="inherit" size="small" onClick={() => openSmartBill()}>
            View
          </Button>
        ),
      },
      error: {
        type: 'MODAL',
        message: 'An error occurred while generating the proforma.',
      },
    }
  );

  async function handleValidatePOsGeneration() {
    if (!control.data) return;

    try {
      const { data, error } = await validateGeneratePO({
        variables: {
          offerId: control.data.offer.id,
        },
      });
      if (!data) throw error;
      const result = data.simulateHeavyDuty
        .validateGenerateOfferPOs as HeavyDutyResult[];
      confirmGeneratePOsModal.open({
        validationResult: result,
        hasErrors: result.some((result) => result.status === 'ERROR'),
      });
    } catch (e) {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleGeneratePOs() {
    if (!control.data) return;

    try {
      const { data } = await generatePOs({
        variables: {
          offerId: control.data.offer.id,
        },
      });

      if (data?.heavyDuty.generateOfferPOs.resultZipBase64) {
        downloadLink(
          `data:application/zip;base64,${data?.heavyDuty.generateOfferPOs.resultZipBase64}`,
          `pos-quote-${control.data.offer.title ?? control.data.offer.id}.zip`
        );
      }
      confirmGeneratePOsModal.close();
    } catch (e) {
      NOOP_graphqlErrorManagement();
    }
  }
  async function handleSendToSmartbill() {
    if (!control.data) return;

    try {
      await sendToSmartbill({
        variables: {
          offerId: control.data.offer.id,
        },
      });
    } catch (e) {
      NOOP_graphqlErrorManagement();
    }
  }

  return (
    <>
      <OOModal
        open={control.isOpen}
        onClose={control.close}
        title=""
        stickToBottom
      >
        <Box paddingLeft={isMobile ? 1 : 3} paddingRight={isMobile ? 0 : 10}>
          <Box marginTop={3.5}>
            <Button
              variant="text"
              color="primary"
              onClick={handleValidatePOsGeneration}
            >
              <Box display="flex" alignItems="center">
                <Box display="flex" marginRight={2}>
                  <ShoppingCartIcon />
                </Box>
                Generate Procurement Orders
              </Box>
            </Button>
          </Box>

          {control.data?.offer.ownedByCompany?.smartBillApiKey &&
            control.data?.offer.ownedByCompany?.smartBillEmail && (
              <Box marginTop={0.5}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleSendToSmartbill}
                >
                  <Box display="flex" alignItems="center">
                    <Box display="flex" marginRight={2}>
                      <SmartBillIcon />
                    </Box>
                    Generate Proforma via Smartbill
                  </Box>
                </Button>
              </Box>
            )}

          <Box marginTop={0.5}>
            <Button
              variant="text"
              color="primary"
              onClick={() =>
                control.data && generateGDriveDocumentModal.open(control.data)
              }
            >
              <Box display="flex" alignItems="center">
                <Box display="flex" marginRight={2}>
                  <GoogleDriveIcon />
                </Box>
                Generate GDrive documents
              </Box>
            </Button>
          </Box>

          {(navigator as any).canShare && (
            <Box marginTop={0.5}>
              <Button variant="text" color="primary" onClick={downloadQuote}>
                <Box display="flex" alignItems="center">
                  <Box display="flex" marginRight={2}>
                    <GetAppIcon />
                  </Box>
                  Download as PDF
                </Box>
              </Button>
            </Box>
          )}
        </Box>
        <Box marginBottom={10} />

        {isMobile && (
          <Box className={classes.floatingFooter}>
            <Box display="flex">
              <Box paddingX={2}>
                <Button fullWidth onClick={control.close}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </OOModal>

      <ConfirmGeneratePOsModal
        control={confirmGeneratePOsModal}
        onConfirm={handleGeneratePOs}
      />
      <GenerateGDriveDocumentsModal control={generateGDriveDocumentModal} />
    </>
  );
};

export default ExportModal;
