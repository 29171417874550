import gql from 'graphql-tag';

const SUPPLIER_FIELDS = `
  id
  name
  email
  phoneNumber
  customerNo
  companyName
  companyVatId
  companyEmail
  companyPhoneNumber
  companyAddress
  country
  city
  zipCode
`;

export const SEARCH_SUPPLIERS = gql`
    query searchSuppliers(
        $searchString: String
        $pagination: Pagination
        $country: String
    ) {
      searchSuppliers(
        searchString: $searchString
        pagination: $pagination
        country: $country
      ) {
        total
        suppliers {
          ${SUPPLIER_FIELDS}
        }
      }
    }
`;

export const FIND_ALL_SUPPLIERS = gql`
  query findAllSuppliers($pagination: Pagination) {
    findAllSuppliers(pagination: $pagination) {
      total
      suppliers {
        ${SUPPLIER_FIELDS}
      }
    }
  }
`;

export const CREATE_SUPPLIER = gql`
  mutation createSupplier($supplier: SupplierInput!) {
    createSupplier(supplier: $supplier) {
      ${SUPPLIER_FIELDS}
    }
  }
`;

export const GET_SUPPLIER = gql`
  query getSupplier($id: ID!) {
    getSupplier(id: $id) {
      ${SUPPLIER_FIELDS}
    }
  }
`;

export const UPDATE_SUPPLIER = gql`
  mutation updateSupplier($id: ID!, $supplier: SupplierInput!) {
    updateSupplier(id: $id, supplier: $supplier) {
      ${SUPPLIER_FIELDS}
    }
  }
`;

export const DELETE_SUPPLIER = gql`
  mutation deleteSupplier($id: ID!) {
    deleteSupplier(id: $id)
  }
`;
