import React, { FC } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import ClientListPage from './ListPage';
import ClientViewEditPage from './ViewEditPage';

const ClientModule: FC<RouteComponentProps> = ({ match }) => {
  return (
    <>
      <Route path={`${match.path}/:id`} component={ClientViewEditPage} />
      <Route exact path={match.path} component={ClientListPage} />
    </>
  );
};

export default ClientModule;
