import React, { FC } from 'react';

import { createdDate } from '@optioffer/core';

import OOTable, {
  OOTableCell,
  OOTableCellWithSort,
  OOTableGenericProps,
  OOTableRow,
} from '@components/Table/OOTable';

import { Offer } from './domain';

type QuoteTableProps<T = Offer> = OOTableGenericProps<T>;

const QuoteTable: FC<QuoteTableProps> = ({ onEdit, ...rest }) => {
  return (
    <OOTable
      {...rest}
      onEdit={onEdit}
      renderHeader={() => (
        <>
          <OOTableCellWithSort sortString="title" width="18%">
            Quote Title
          </OOTableCellWithSort>
          <OOTableCellWithSort
            sortString="client.name"
            width="20%"
            align="left"
          >
            Client Name
          </OOTableCellWithSort>
          <OOTableCellWithSort sortString="createdBy" width="14%" align="left">
            Creator
          </OOTableCellWithSort>
          <OOTableCell width="12%" align="left">
            Total Cost
          </OOTableCell>
          <OOTableCell width="12%" align="left">
            Profit
          </OOTableCell>
          <OOTableCellWithSort sortString="createdAt" width="12%" align="left">
            Created
          </OOTableCellWithSort>
          <OOTableCellWithSort
            sortString="lastModifiedDate"
            width="12%"
            align="left"
          >
            Last Modified
          </OOTableCellWithSort>
        </>
      )}
      renderRow={(row) => (
        <OOTableRow key={row.id} onClick={() => onEdit(row)}>
          <OOTableCell component="th" scope="row">
            {row.title}
          </OOTableCell>
          <OOTableCell align="left">
            {row.client?.name}
            {row.client?.companyName && `, ${row.client?.companyName}`}
          </OOTableCell>
          <OOTableCell align="left">{row.createdBy}</OOTableCell>
          <OOTableCell align="left">
            {row.report?.currency?.symbol} {row.report?.cost?.toFixed(2)}
          </OOTableCell>
          <OOTableCell align="left">
            {row.report?.currency?.symbol} {row.report?.margin?.toFixed(2)}
          </OOTableCell>
          <OOTableCell align="left">{createdDate(row.createdAt)}</OOTableCell>
          <OOTableCell align="left">
            {createdDate(row.lastModifiedDate)}
          </OOTableCell>
        </OOTableRow>
      )}
    />
  );
};

export default QuoteTable;
