import { useModal } from '@lib';
import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import * as React from 'react';
import { FC, ReactNode } from 'react';

import InfoModal from '@components/InfoModal';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

type InfoButtonProps = {
  className?: string;
  title: string;
  message?: ReactNode;
};

const InfoButton: FC<InfoButtonProps> = ({ className, title, message }) => {
  // const localClasses = useLocalStyles();

  const infoModal = useModal();

  return (
    <>
      <IconButton
        className={className}
        color="primary"
        onClick={() => infoModal.open()}
      >
        <InfoIcon />
      </IconButton>
      <InfoModal control={infoModal} title={title} message={message} />
    </>
  );
};

export default InfoButton;
