import {
  DVKField,
  DVKObject,
  ErrorModal,
  InputModal,
  useIncrementalKey,
  useModal,
} from '@dvkiin/material-commons';
import {
  getInvalidFields,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
} from '@lib';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import React, { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {
  CompanyAdminListItemOnAdminPageFragment,
  CreatePlanDocument,
  FindAllPlansDocument,
  GetAllCompaniesDocument,
  GetAllFeaturesDocument,
  PlanOnAdminPageFragment,
  PlanInput,
  Feature,
} from '@optioffer/graphql';

import { ApolloModalErrors, ApolloSnackbars } from '@components/Feedback';

import PlansTable from './Table';
import usePlanFields from './fields';
import useStyles from './styles';

const defaultPlan = {
  isPublic: false,
  isDefault: false,
  hasTrial: false,
} as any;

const PlanListPage: FC = () => {
  const { addButton } = useStyles();

  const { push } = useHistory();
  const {
    isOpen: isCreateModalOpen,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal();
  const [createKey, incrementCreateKey] = useIncrementalKey();

  // apollo
  const {
    data: { findAllPlans } = { findAllPlans: undefined },
    error: getError,
  } = useEnhancedQuery(FindAllPlansDocument);
  const [
    createPlan,
    { data: createResult, error: createError },
  ] = useEnhancedMutation(CreatePlanDocument, {
    refetchQueries: ['findAllPlans'],
  });

  const getAllCompaniesQuery = useEnhancedQuery(GetAllCompaniesDocument);
  const companies = getAllCompaniesQuery.data?.getAllCompanies.companies ?? [];

  const getAllFeaturesQuery = useEnhancedQuery(GetAllFeaturesDocument);
  const featureEnumValues = getAllFeaturesQuery.data?.__type?.enumValues ?? [];

  const handleNavigateToPlan = useCallback(
    ({ id }: PlanOnAdminPageFragment) => push(`/admin/plan/${id}`),
    [push]
  );

  //region errors
  const errors = useMemo(
    () => [
      {
        error: getError,
        message:
          'The server was unable to load the plans. Please try again later. If this persists, contact support.',
      },
      {
        error: createError,
        message:
          'There was an error while creating the plan. Please check your input and try again.',
      },
    ],
    [getError, createError]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      createResult && {
        result: createResult,
        message: `Plan ${createResult.createPlan.name} created.`,

        action: () => (
          <Button
            color="inherit"
            size="small"
            onClick={() => handleNavigateToPlan(createResult.createPlan)}
          >
            View and edit
          </Button>
        ),
      },
    ],
    [createResult, handleNavigateToPlan]
  );

  //endregion
  const defaultFields = usePlanFields(featureEnumValues);

  async function handlePlanSave(plan: DVKObject) {
    const planToSave: PlanInput = {
      name: plan.name as string,
      description: plan.description as string,
      price: plan.price as number,
      duration: plan.duration as number,
      usersLimit: plan.usersLimit as number,
      productsLimit: plan.productsLimit as number,
      offersLimit: plan.offersLimit as number,
      isPublic: (plan.isPublic as unknown) as boolean,
      isDefault: (plan.isDefault as unknown) as boolean,
      hasTrial: (plan.hasTrial as unknown) as boolean,
      isPricePerUser: (plan.isPricePerUser as unknown) as boolean,
      stripePlanId: plan.stripePlanId as string,
      publicFor: (plan.publicFor as string[]) || [],
      includedFeatures: (plan.includedFeatures as Feature[]) || [],
    };

    try {
      await createPlan({ variables: { plan: planToSave } });
      incrementCreateKey();
    } catch (_e) {
      NOOP_graphqlErrorManagement();
    }
  }

  const searchCompanies = async (searchString: string) => {
    return companies.filter(
      (company: CompanyAdminListItemOnAdminPageFragment) =>
        company.name.toLowerCase().includes(searchString.toLowerCase()) ||
        company.id?.toLowerCase().includes(searchString.toLowerCase())
    );
  };

  function renderTable() {
    if (getError)
      return (
        <ErrorModal
          error={getError}
          message="There was an error while loading the plans. Please try again later. If the problem persists, please contact and administrator."
        />
      );
    if (!findAllPlans) return 'Loading...';
    if (!findAllPlans.length) return 'You have no plans yet. Add some ;)';
    return (
      <PlansTable
        plans={findAllPlans}
        total={findAllPlans.length}
        onEdit={handleNavigateToPlan}
      />
    );
  }

  return (
    <>
      <h3>
        Plans
        <Button
          className={addButton}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openCreateModal}
        >
          New Plan
        </Button>
      </h3>

      {renderTable()}

      <InputModal
        title="Add new Plan"
        formKey={`${createKey}`}
        open={isCreateModalOpen}
        defaultValue={defaultPlan}
        fields={[
          ...defaultFields,
          {
            name: 'publicFor',
            label: 'Public for companies',
            type: 'combo-box',
            multiple: true,
            search: (search) =>
              searchCompanies(search).then((companies) =>
                companies.map((company) => ({
                  name: company.id,
                  label: company.name,
                }))
              ),
          } as DVKField,
        ]}
        invalidFields={getInvalidFields(createError)}
        onClose={closeCreateModal}
        onCreate={handlePlanSave}
      />

      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default PlanListPage;
