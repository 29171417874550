import React, { FC } from 'react';

import { OfferInOfferModuleFragment } from '@optioffer/graphql';

import ReviewModal from './ReviewModal';

type ReviewStepProps = {
  offer: OfferInOfferModuleFragment;
  updateOffer(
    offer: OfferInOfferModuleFragment
  ): Promise<OfferInOfferModuleFragment>;
  setStep(step: number): void;
};

const ReviewStep: FC<ReviewStepProps> = ({ offer, updateOffer, setStep }) => {
  return (
    <ReviewModal quote={offer} updateOffer={updateOffer} setStep={setStep} />
  );
};

export default ReviewStep;
