import { Box, makeStyles, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';

import { OfferStatus } from '@optioffer/graphql';

const STATUSES: Map<
  OfferStatus,
  {
    label: string;
    description: string;
    color: string;
  }
> = new Map([
  [
    OfferStatus.DRAFT,
    {
      label: 'D',
      description: 'Draft',
      color: '#9A9A9A',
    },
  ],
  [
    OfferStatus.IN_PROGRESS,
    {
      label: 'P',
      description: 'In Progress',
      color: '#00849F',
    },
  ],
  [
    OfferStatus.CLOSED,
    {
      label: 'C',
      description: 'Closed',
      color: '#E86C41',
    },
  ],
]);

const useLocalStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600, // semi-bold
    border: '1px solid black',
    borderRadius: 5,
    padding: theme.spacing(0.25, 0.5),
  },
}));

type QuoteStatusChipProps = {
  status: OfferStatus;
};

const QuoteStatusChip: FC<QuoteStatusChipProps> = ({ status }) => {
  const localClasses = useLocalStyles();

  const statusObject = STATUSES.get(status);
  if (!statusObject) return <>{status}</>;

  return (
    <Tooltip
      title={statusObject.description}
      aria-label={`${statusObject.label} status`}
      placement="top-end"
    >
      <Box
        className={localClasses.root}
        style={{
          backgroundColor: `${statusObject.color}47`, // 28%
          borderColor: statusObject.color,
          color: statusObject.color,
        }}
      >
        {statusObject.label}
      </Box>
    </Tooltip>
  );
};

export default QuoteStatusChip;
