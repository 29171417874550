import gql from 'graphql-tag';

const ATTRIBUTE_COLLECTION_LIST_FIELDS = `
  id
  name
  description
  numberOfAttributes
`;

const ATTRIBUTE_COLLECTION_FIELDS = `
  id
  name
  description
  attributes {
    id
    name
    type
    description
    unit
    defaultValue
  }
`;

export const FIND_ALL_ATTRIBUTE_COLLECTIONS = gql`
  query findAllAttributeCollections($pagination: Pagination) {
    findAllAttributeCollections(pagination: $pagination) {
      total
      attributeCollections {
        ${ATTRIBUTE_COLLECTION_LIST_FIELDS}
      }
    }
  }
`;

export const CREATE_ATTRIBUTE_COLLECTION = gql`
  mutation createAttributeCollection($attributeCollection: AttributeCollectionInput!) {
    createAttributeCollection(attributeCollection: $attributeCollection) {
      ${ATTRIBUTE_COLLECTION_LIST_FIELDS}
    }
  }
`;

export const GET_ATTRIBUTE_COLLECTION = gql`
  query getAttributeCollection($id: ID!) {
    getAttributeCollection(id: $id) {
      ${ATTRIBUTE_COLLECTION_FIELDS}
    }
  }
`;

export const UPDATE_ATTRIBUTE_COLLECTION = gql`
  mutation updateAttributeCollection($id: ID!, $attributeCollection: AttributeCollectionInput!) {
    updateAttributeCollection(id: $id, attributeCollection: $attributeCollection) {
      ${ATTRIBUTE_COLLECTION_FIELDS}
    }
  }
`;

export const DELETE_ATTRIBUTE_COLLECTION = gql`
  mutation deleteAttributeCollection($id: ID!) {
    deleteAttributeCollection(id: $id)
  }
`;

export const CLONE_ATTRIBUTE_COLLECTION = gql`
  mutation cloneAttributeCollection($id: ID!) {
    cloneAttributeCollection(id: $id) {
      ${ATTRIBUTE_COLLECTION_FIELDS}
    }
  }
`;

export const FIND_ALL_ATTRIBUTE_TYPES = gql`
  query findAllAttributeTypes {
    findAllAttributeTypes
  }
`;
