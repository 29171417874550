import gql from 'graphql-tag';

import { costFields } from '@optioffer/core';

const ATTRIBUTE_FIELDS = `
  id
  name
  type
  description
  unit
  defaultValue
`;

const PRODUCT_FIELDS = `
  id
  name
  description
  image {
    id
    fullImage 
    thumbnail
    fileName
  }
  attributes {
    ${ATTRIBUTE_FIELDS}
  }
  versions {
    id
    code
    price
    attributeValues {
      id
      attribute {
        ${ATTRIBUTE_FIELDS}
      }
      value
    }
    cost {
      ${costFields}
    }
    supplier {
      id
      name
    }
    accessoryFor {    
      product {
        id
        name
        description
        image {
          id
          thumbnail
          fileName
        }
      } 
      productVersion {
        id
        code
        price
      }      
    }
  }
`;
export const GET_PRODUCT = gql`
  query getProduct($id: ID!) {
    getProduct(id: $id) {
      ${PRODUCT_FIELDS}
    }
  }
`;

export const REMOVE_ATTRIBUTE = gql`
  mutation removeAttribute($productId: ID!, $attributeId: ID!) {
    updateProductAttributes(id: $productId) {
      remove(attributeId: $attributeId) {
        ${PRODUCT_FIELDS}
      }
    }
  }
`;

const HEAVY_DUTY_FIELDS = `
  id
  status
  message
  data
`;

export const SIMULATE_IMPORT = gql`
  query simulateProductImport($file: Upload!) {
    simulateHeavyDuty {
      importProducts(file: $file) {
        ${HEAVY_DUTY_FIELDS}
      }
    }
  }
`;

export const VALIDATE_IMAGE_IMPORT = gql`
  query validateImageImport($imageNames: [String!]) {
    simulateHeavyDuty {
      validateImages(imageNames: $imageNames) {
        ${HEAVY_DUTY_FIELDS}
      }
    }
  }
`;
export const VALIDATE_BULK_UPDATE_PRODUCTS = gql`
  query validateBulkUpdateProducts($file: Upload!) {
    simulateHeavyDuty {
      validateBulkUpdateProducts(file: $file) {
        ${HEAVY_DUTY_FIELDS}
      }
    }
  }
`;

export const PRODUCT_IMPORT = gql`
  mutation productImport($file: Upload!) {
    heavyDuty {
      importProducts(file: $file) {
        ${HEAVY_DUTY_FIELDS}
      }
    }
  }
`;

export const BULK_UPDATE_PRODUCTS = gql`
  mutation bulkUpdateProducts($file: Upload!) {
    heavyDuty {
      bulkUpdateProducts(file: $file) {
        ${HEAVY_DUTY_FIELDS}
      }
    }
  }
`;

export const PRODUCT_IMPORT_IMAGE = gql`
  mutation importProductImage($file: Upload!) {
    heavyDuty {
      importProductImage(file: $file) {
        ${HEAVY_DUTY_FIELDS}
      }
    }
  }
`;

export const VALIDATE_BULK_DELETE_PRODUCTS = gql`
  query validateBulkDeleteProducts($file: Upload!) {
    simulateHeavyDuty {
      validateBulkDeleteProducts(file: $file) {
        ${HEAVY_DUTY_FIELDS}
      }
    }
  }
`;

export const BULK_DELETE_PRODUCTS = gql`
  mutation bulkDeleteProducts($file: Upload!) {
    heavyDuty {
      bulkDeleteProducts(file: $file) {
        ${HEAVY_DUTY_FIELDS}
      }
    }
  }
`;

const PRODUCT_BUNDLE_LIST_FIELDS = `
  id
  name
  description
  image {
    id
    thumbnail
  }
`;

const PRODUCT_BUNDLE_FIELDS = `
  id
  name
  description
  image {
    id
    thumbnail
    fullImage
  }
  items {
    id
    product {
      id
      name
    }
    productVersion {
      id
      code
      price
    }
    quantity
  }
  discount {
    calculationType
    value
  }
`;

export const FIND_ALL_PRODUCT_BUNDLES = gql`
  query findAllProductBundles($pagination: Pagination) {
    findAllProductBundles(pagination: $pagination) {
      total
      productBundles {
        ${PRODUCT_BUNDLE_LIST_FIELDS}
      }
    }
  }
`;

export const CREATE_PRODUCT_BUNDLE = gql`
  mutation createProductBundle($productBundle: ProductBundleInput!) {
    createProductBundle(productBundle: $productBundle) {
      ${PRODUCT_BUNDLE_LIST_FIELDS}
    }
  }
`;

export const UPDATE_PRODUCT_BUNDLE = gql`
  mutation updateProductBundle($id: ID!, $productBundle: UpdateProductBundleInput!) {
    updateProductBundle(id: $id, productBundle: $productBundle) {
      ${PRODUCT_BUNDLE_FIELDS}
    }
  }
`;

export const DELETE_PRODUCT_BUNDLE = gql`
  mutation deleteProductBundle($id: ID!) {
    deleteProductBundle(id: $id)
  }
`;

export const CLONE_PRODUCT_BUNDLE = gql`
  mutation cloneProductBundle($id: ID!) {
    cloneProductBundle(id: $id) {
      ${PRODUCT_BUNDLE_LIST_FIELDS}
    }
  }
`;

export const GET_PRODUCT_BUNDLE = gql`
  query getProductBundle($id: ID!) {
    getProductBundle(id: $id) {
      ${PRODUCT_BUNDLE_FIELDS}
    }
  }
`;

export const ADD_PRODUCT_BUNDLE_ITEM = gql`
  mutation addProductBundleItem($productBundleId: ID!, $productBundleItem: BundleItemInput!) {
    updateProductBundleItems(id: $productBundleId) {
      add(bundleItem: $productBundleItem) {
        ${PRODUCT_BUNDLE_FIELDS}
      }
    }
  }
`;

export const REMOVE_PRODUCT_BUNDLE_ITEM = gql`
  mutation removeProductBundleItem($productBundleId: ID!, $productBundleItemId: ID!) {
    updateProductBundleItems(id: $productBundleId) {
      remove(bundleItemId: $productBundleItemId) {
        ${PRODUCT_BUNDLE_FIELDS}
      }
    }
  }
`;

export const MOVE_PRODUCT_BUNDLE_ITEM = gql`
  mutation moveProductBundleItem($productBundleId: ID!, $productBundleItemId: ID!, $newPosition: Int!) {
    updateProductBundleItems(id: $productBundleId) {
      move(bundleItemId: $productBundleItemId, newPosition: $newPosition) {
        ${PRODUCT_BUNDLE_FIELDS}
      }
    }
  }
`;

export const UPDATE_PRODUCT_BUNDLE_ITEM = gql`
  mutation updateProductBundleItems($productBundleId: ID!, $productBundleItemId: ID!, $productBundleItem: BundleItemInput!) {
    updateProductBundleItems(id: $productBundleId) {
      update(bundleItemId: $productBundleItemId, bundleItem: $productBundleItem) {
        ${PRODUCT_BUNDLE_FIELDS}
      }
    }
  }
`;
