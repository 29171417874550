import { Box, Button, Container, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import clsx from 'clsx';
import React, { FC } from 'react';

import { ooBrand } from '@optioffer/core';

import ProfileSetupCard from './Profile/ProfileSetupCard';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: 0,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    padding: theme.spacing(3, 2),
  },
  demoCard: {
    backgroundColor: `${ooBrand.colors.primary.default}19`, // 10%
    gap: theme.spacing(2),
    position: 'relative',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      paddingLeft: theme.spacing(25),
      marginTop: theme.spacing(20),
    },
  },
  demoCardIllustration: {
    display: 'none',
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  normalCard: {
    boxShadow: ooBrand.boxShadow.primary,
  },
  tasksCard: {
    [theme.breakpoints.up('lg')]: {},
  },
  header1: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.primary.main,

    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  header1V2: {
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.palette.primary.dark,
    padding: theme.spacing(0, 5),
  },
  header2: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600, // semi-bold
  },
  helpButton: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    '& svg': {
      marginRight: theme.spacing(1.5),
    },
  },
  tasksProgressBar: {
    width: 120,
    alignSelf: 'center',
    marginBottom: theme.spacing(1.2),
    borderRadius: 4,
  },
  task: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    padding: theme.spacing(2, 0),

    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0.5, 0),
      padding: theme.spacing(2, 1.5),
      backgroundColor: ooBrand.colors.gray['100'],
      borderRadius: 5,
      border: `1px solid transparent`,
      cursor: 'pointer',

      '&:hover': {
        borderColor: `${ooBrand.colors.primary['100']}64`, // 40%
      },
    },
  },
  activeTask: {
    borderColor: `${ooBrand.colors.primary['100']}64`, // 40%
  },
  backgroundTaskProgressRing: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: ooBrand.colors.black,
    opacity: '7%',
  },
  taskCategoryCompletion: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600, // semi-bold
  },
  taskCategoryTitle: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 600, // semi-bold
  },
  taskCategorySubTitle: {
    fontSize: theme.typography.pxToRem(12),
    color: ooBrand.colors.gray['500'],
    maxWidth: 400,
  },
  taskText: {
    flex: 1,
  },
  taskTextComplete: {
    textDecoration: 'line-through',
    color: `${ooBrand.colors.black}64`, // 40%
  },
  taskCompleteIcon: {
    color: theme.palette.primary.main,
  },
  taskBubble: {
    borderRadius: '50%',
    width: theme.spacing(1),
    height: theme.spacing(1),
    margin: theme.spacing(0, 1),
  },
  taskIcon: {
    color: theme.palette.primary.main,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

const EmployeeProHome: FC = () => {
  const localClasses = useLocalStyles();

  return (
    <Container maxWidth="md" className={localClasses.root}>
      <ProfileSetupCard />

      <Box className={clsx(localClasses.card, localClasses.normalCard)}>
        <Box className={localClasses.header2}>Need any help?</Box>
        <Box marginTop={2} display="flex" flexDirection="column" gridGap={16}>
          <Button
            variant="text"
            color="primary"
            className={localClasses.helpButton}
            href={`mailto:support@optioffer.com?subject=I need help setting my profile`}
            target="_blank"
          >
            <FileCopyIcon fontSize="small" /> I need help setting my profile
          </Button>
          <Button
            variant="text"
            color="primary"
            className={localClasses.helpButton}
            href={`mailto:support@optioffer.com`}
            target="_blank"
          >
            <HelpOutlineIcon fontSize="small" />
            Ask us anything
          </Button>
          <Button
            variant="text"
            color="primary"
            className={localClasses.helpButton}
            href={`mailto:innovation@optioffer.com`}
            target="_blank"
          >
            <EditIcon fontSize="small" />I have a feature recommendation
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EmployeeProHome;
