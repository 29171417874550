import { Formik, MenuControl, ModalControl } from '@lib';
import { createContext } from 'react';

import {
  Addon,
  AddonInOfferModuleFragment,
  AddonInput,
  CalculationType,
  Currency,
  DiscountInput,
  OfferInOfferModuleFragment,
  OfferItem,
  OfferItemInOfferModuleFragment,
  Tax,
} from '@optioffer/graphql';
import { BasicLayoutVariant } from '@optioffer/printing';

import { AddonModalData } from '@containers/ProductSection/AddonModal';
import { GetProductFn } from '@containers/ProductSection/domain';

import { ProductSearchResult } from '../../domain';
import { CompanyIntroModalData } from '../CompanyIntroModal';
import { QuoteDiscountModalData } from '../DiscountModal';
import { PricingLevelModalData } from '../PricingLevelModal';
import { QuoteCurrencyModalData } from '../QuoteCurrencyModal';
import { QuoteLanguageModalData } from '../QuoteLanguageModal';
import { QuoteTaxesModalData } from '../QuoteTaxesModal';
import { QuoteTemplateModalData } from '../QuoteTemplateModal';
import { TermsAndConditionsModalData } from '../TermsAndConditionsModal';

type ReviewModalFormik = Formik<{
  offerItems: OfferItemInOfferModuleFragment[];
  companyIntro: string;
  termsAndConditions: string;
  creator: string;
  createdAt: number;
  expireAt: number;
  quoteLanguage: string;
  quoteTemplate: BasicLayoutVariant;
  quoteCurrency: Currency;
  discountType: CalculationType;
  discountValue: number;
  addons: AddonInOfferModuleFragment[];
  displayDiscounts: boolean;
  taxes: Tax[];
}>;

const ReviewModalContext = createContext<{
  getProduct: GetProductFn;

  pricing: any;
  quote: OfferInOfferModuleFragment;

  companyIntroModal: ModalControl<CompanyIntroModalData>;
  termsAndConditionsModal: ModalControl<TermsAndConditionsModalData>;

  // productModal: ModalControl<ProductSearchResult>;
  offerItemModal: ModalControl<ProductSearchResult>;
  offerItemAccessoryModal: ModalControl<ProductSearchResult>;

  addonModal: ModalControl<AddonModalData>;
  discountModal: ModalControl<QuoteDiscountModalData>;
  quoteTemplateModal: ModalControl<QuoteTemplateModalData>;
  quoteLanguageModal: ModalControl<QuoteLanguageModalData>;
  quoteCurrencyModal: ModalControl<QuoteCurrencyModalData>;
  pricingLevelModal: ModalControl<PricingLevelModalData>;
  quoteTaxesModal: ModalControl<QuoteTaxesModalData>;
  previewModal: ModalControl;
  quoteDetailsMenu: MenuControl;
  quoteSummaryMenu: MenuControl;

  formik: ReviewModalFormik;
  currentAccessoryParent: OfferItemInOfferModuleFragment | undefined;

  handleUpdateQuoteTemplate(quoteTemplate: string): Promise<any>;
  handleUpdateQuoteLanguage(quoteLanguage: string): Promise<any>;
  handleUpdateQuoteCurrency(quoteCurrency: Currency): Promise<any>;
  handleUpdatePricingLevel(displayDiscounts: boolean): Promise<any>;
  handleUpdateQuoteTaxes(taxes: Tax[]): Promise<any>;
  handleUpdateCompanyIntro(companyIntro: string): Promise<void>;
  handleEditTermsAndConditions(): void;
  handleUpdateTermsAndConditions(termsAndConditions: string): Promise<void>;
  handleMoveOfferItem(dragIndex: number, hoverIndex: number): Promise<any>;
  handleMoveAccessory(
    parentId: OfferItem['id'],
    dragIndex: number,
    hoverIndex: number
  ): Promise<any>;
  handleEditDiscount(): void;
  handleUpdateDiscount(discount: DiscountInput): Promise<void>;
  handleEditAddon(addon?: Addon): void;
  handleUpdateAddon(addon: AddonInput): Promise<any>;
  handleUpdateOfferItem(offerItem: OfferItem): Promise<OfferItem>;
  handleUpdateAccessory(
    accessory: OfferItem,
    parentId?: OfferItem['id']
  ): Promise<OfferItem>;
  setCurrentAccessoryParent(
    accessory: OfferItemInOfferModuleFragment | undefined
  ): void;
  setStep(step: number): void;
}>(undefined as any);

export default ReviewModalContext;
