import React, { FC } from 'react';

import { Catalog } from '@optioffer/graphql';

import OOTable, {
  OOTableCell,
  OOTableGenericProps,
  OOTableRow,
} from '@components/Table/OOTable';

type CatalogTableProps<T = Catalog> = OOTableGenericProps<T>;

const CatalogTable: FC<CatalogTableProps> = ({ onEdit, ...rest }) => {
  return (
    <OOTable
      {...rest}
      onEdit={onEdit}
      renderHeader={() => (
        <>
          <OOTableCell component="th" width="20%">
            Name
          </OOTableCell>
          <OOTableCell component="th" width="20%">
            Created By
          </OOTableCell>
          <OOTableCell component="th" width="20%">
            Date
          </OOTableCell>
          <OOTableCell component="th" width="20%">
            # of products
          </OOTableCell>
          <OOTableCell component="th" width="20%">
            Status
          </OOTableCell>
        </>
      )}
      renderRow={(row) => (
        <OOTableRow key={row.name} onClick={() => onEdit(row)}>
          <OOTableCell scope="row">{row.name}</OOTableCell>
          <OOTableCell scope="row">
            {row.profile ? (
              <div>
                <div>{row.profile?.companyName}</div>
                <strong>{row.profile?.contactPersonName}</strong>
              </div>
            ) : (
              'Unlinked'
            )}
          </OOTableCell>
          <OOTableCell scope="row">{row.createdAt}</OOTableCell>
          <OOTableCell scope="row">{row.products.length}</OOTableCell>
          <OOTableCell scope="row">{row.catalogStatus}</OOTableCell>
        </OOTableRow>
      )}
    />
  );
};

export default CatalogTable;
