import { ApolloModalErrors, ApolloSnackbars, SimpleCard } from '@components';
import {
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
} from '@lib';
import React, { FC, useCallback, useMemo } from 'react';

import { Company, CompanyUpdateInput } from '../domain';
import { GET_COMPANY_DETAILS, UPDATE_COMPANY } from '../graphql';
import CompanyMetaSection from '../sections/Meta';

type ViewEditPageProps = {};

const CompanyDetailsSettingsPage: FC<ViewEditPageProps> = () => {
  //#region queries
  const {
    data: { getCompanyDetails } = { getCompanyDetails: [] },
    error: getCompanyDetailsError,
    loading: loadingCompanyDetails,
  } = useEnhancedQuery<{ getCompanyDetails: Company }>(GET_COMPANY_DETAILS);
  //#endregion

  //region mutations
  const [
    updateCompany,
    { data: updateCompanyData, error: updateCompanyError },
  ] = useEnhancedMutation(UPDATE_COMPANY, {});

  //region errors
  const errors = useMemo(
    () => [
      {
        error: updateCompanyError,
        message:
          'There was an error while updating the company. If this persists, please contact support.',
      },
      {
        error: getCompanyDetailsError,
        message: 'No company found for current user. ',
      },
    ],
    [updateCompanyError, getCompanyDetailsError]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [{ result: updateCompanyData, message: 'Company details updated.' }],
    [updateCompanyData]
  );
  //endregion

  const handleUpdateCompany = useCallback(
    async (companyUpdateInput: CompanyUpdateInput) => {
      return updateCompany({ variables: { companyUpdateInput } }).catch(
        NOOP_graphqlErrorManagement
      );
    },
    [updateCompany]
  );

  if (!getCompanyDetails) {
    return <SimpleCard>This user is not part of any company.</SimpleCard>;
  }
  return (
    <>
      {loadingCompanyDetails ? (
        'Loading details'
      ) : (
        <CompanyMetaSection
          onUpdateCompany={handleUpdateCompany}
          companyDetails={getCompanyDetails as any}
        />
      )}

      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default CompanyDetailsSettingsPage;
