import { deepGet } from '@dvkiin/material-commons';
import { ResultWithMessage } from '@lib';
import React, { FC } from 'react';

import { Nullable } from '@optioffer/core';

import SuccessSnackbar from '@components/Feedback/SuccessSnackbar';

type ApolloSnackbarsProps = {
  results: Nullable<ResultWithMessage>[];
};

const ApolloSnackbars: FC<ApolloSnackbarsProps> = ({ results }) => {
  function renderResult({
    result,
    message,
    action,
    booleanKey,
  }: ResultWithMessage) {
    if (!result || (booleanKey && !deepGet(result, booleanKey, false)))
      return null;

    const messageToRender =
      typeof message === 'function' ? message(result) : message;
    const actionToRender =
      typeof action === 'function' ? action(result) : action;
    return (
      <SuccessSnackbar
        key={JSON.stringify(result)}
        message={messageToRender}
        action={actionToRender}
      />
    );
  }

  return (
    <>
      {results
        .filter(Boolean)
        .map((it) => it as NonNullable<ResultWithMessage>)
        .map(renderResult)}
    </>
  );
};

export default ApolloSnackbars;
