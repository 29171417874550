import { makeStyles } from '@material-ui/core';

import { ooBrand } from '@optioffer/core';

export default makeStyles((theme) => ({
  topSpacer: {
    marginTop: theme.spacing(2),
  },
  optiPaper: {
    boxShadow: ooBrand.boxShadow.primary,
  },
}));
