var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var primary = {
    '100': '#057869',
    '200': '#0F5C52',
    '300': '#0A423B',
    '400': '#052621',
};
var secondary = {
    '100': '#EBE5E3',
    '200': '#DBCCBA',
    '300': '#B08075',
    '400': '#966E66',
};
var emp = {
    '100': '#FACFBF',
    '200': '#FAB59E',
    '300': '#F79E80',
    '400': '#F5855E',
};
var muiGray = {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
};
export var ooBrand = {
    colors: {
        primary: __assign(__assign({}, primary), { default: primary['100'] }),
        secondary: __assign(__assign({}, secondary), { default: secondary['300'] }),
        emp: __assign(__assign({}, emp), { default: emp['400'] }),
        error: '#E8624F',
        warning: '#FAC05F',
        transparent: 'transparent',
        black: primary['400'],
        white: secondary['100'],
        pureWhite: '#fff',
        gray: muiGray,
    },
    fontFamily: {
        body: ['"Poppins"', 'Verdana', 'sans-serif'],
        emp: ['Nexa', 'Helvetica', 'Arial', 'sans-serif'],
    },
    boxShadow: {
        primary: '2px 2px 10px 0px rgb(5 120 105 / 15%)',
        grayTop: "\n      0px -2px 1px -2px rgb(0 0 0 / 20%), \n      0px -2px 2px 0px rgb(0 0 0 / 14%), \n      0px -1px 5px 0px rgb(0 0 0 / 12%);\n    ",
        grayBottom: "\n      0px 3px 1px -2px rgb(0 0 0 / 20%), \n      0px 2px 2px 0px rgb(0 0 0 / 14%), \n      0px 1px 5px 0px rgb(0 0 0 / 12%);\n    ",
    },
};
