import { InputModal, useModal } from '@dvkiin/material-commons';
import { FieldErrors, NOOP_graphqlErrorManagement } from '@lib';
import { Button, Grid, Typography } from '@material-ui/core';
import StorageIcon from '@material-ui/icons/Storage';
import React, { FC, useEffect, useMemo, useState } from 'react';

import {
  EmailPreferences,
  EmailPreferencesInput,
  EmailProvider,
  SmtpAuthenticationType,
} from '@optioffer/graphql';

import { GoogleMailIcon, YahooMailIcon } from '@resources/icons';

import useStyles from '../styles';
import { useEmailSettingsFields } from './fields';

type EmailSettingsProps = {
  emailPreferences?: EmailPreferences | null;
  connectedProvider?: EmailProvider | null;
  updateEmailPreferences: (
    preferences?: EmailPreferencesInput
  ) => Promise<unknown>;
  invalidFields?: FieldErrors;
  createGmailAuthUrl: () => Promise<string>;
};

const EmailSettings: FC<EmailSettingsProps> = ({
  emailPreferences,
  connectedProvider,
  updateEmailPreferences,
  invalidFields,
  createGmailAuthUrl,
}) => {
  const classes = useStyles();
  const [authenticationType, setAuthenticationType] = useState<
    SmtpAuthenticationType | undefined
  >(undefined);
  const {
    isOpen: isSmtpModalOpen,
    open: openSmtpModal,
    close: closeSmtpModal,
  } = useModal();

  const emailSettingsFields = useEmailSettingsFields(authenticationType);

  useEffect(() => {
    if (emailPreferences) {
      setAuthenticationType(emailPreferences.authenticationType);
    }
  }, [emailPreferences]);

  const defaultValue: any = useMemo(
    () =>
      connectedProvider
        ? {}
        : {
            authenticationType: undefined,
            smtpPort: 465,
            smtpSecure: true,
            ...(emailPreferences || {}),
          },
    [emailPreferences, connectedProvider]
  );

  const handleGmailAuth = async () => {
    const redirectUrl = await createGmailAuthUrl();
    window.location = redirectUrl as any;
  };

  return (
    <>
      <Grid item style={{ margin: 24 }}>
        <h3>Email preferences</h3>
        <p>
          If you want to send quotes from your personal email adress, you can
          choose one of the following.
          <br /> Currently your quotes are sent from quotes@optioffer.com.
        </p>
        <Grid container>
          <Grid item xs={3} style={{ paddingRight: 8 }}>
            {connectedProvider && (
              <Typography
                className={classes.connectedMessage}
                variant="body1"
                component="p"
              >
                ✔{' '}
                {emailPreferences?.smtpUser
                  ? `sending mails as ${emailPreferences?.smtpUser}`
                  : `connected via ${connectedProvider}`}
              </Typography>
            )}

            <Button
              variant="outlined"
              color="primary"
              onClick={handleGmailAuth}
              fullWidth
            >
              <GoogleMailIcon className={classes.socialIcon} />
              Connect Gmail
            </Button>
          </Grid>
          <Grid item xs={3} style={{ paddingRight: 8, paddingLeft: 8 }}>
            <Button variant="outlined" color="primary" fullWidth>
              <YahooMailIcon className={classes.socialIcon} /> Connect Yahoo
            </Button>
          </Grid>
          <Grid item xs={3} style={{ marginRight: 8, paddingLeft: 8 }}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={openSmtpModal}
            >
              <StorageIcon className={classes.socialIcon} /> Connect SMTP Server
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <InputModal
        open={isSmtpModalOpen}
        onClose={closeSmtpModal}
        fields={emailSettingsFields}
        defaultValue={defaultValue}
        title="SMTP Settings"
        onCreate={async ({
          smtpSecure,
          smtpServer,
          smtpPort,
          smtpUser,
          authenticationType,
          smtpPassword,
          smtpServiceClient,
          smtpPrivateKey,
        }) => {
          updateEmailPreferences({
            smtpServer,
            smtpPort,
            smtpUser,
            smtpSecure: !!smtpSecure,
            authenticationType,
            smtpPassword,
            smtpServiceClient,
            smtpPrivateKey: (smtpPrivateKey as string)?.replace(/\\n/g, '\n'),
          } as EmailPreferencesInput).catch(NOOP_graphqlErrorManagement);
        }}
        onChange={(preferences) =>
          setAuthenticationType(
            preferences.authenticationType as SmtpAuthenticationType
          )
        }
        invalidFields={invalidFields}
      />
    </>
  );
};

export default EmailSettings;
