import queryString from 'query-string';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router';

import { SecurityContext } from '@lib/security';

const tagManagerArgs = process.env.REACT_APP_GTM_ID
  ? {
      gtmId: process.env.REACT_APP_GTM_ID as string,
    }
  : undefined;
// initial load
tagManagerArgs && TagManager.initialize(tagManagerArgs);

export const useQueryParams = () => {
  const location = useLocation();
  return useMemo(() => queryString.parse(location.search) || {}, [location]);
};

export const getOrigin = () => window.location.origin;

type TrackerProps = {};
export const PageTracker: FC<TrackerProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // TODO: hide ids for better visualization
    tagManagerArgs &&
      TagManager.initialize({
        ...tagManagerArgs,
        events: {
          event: 'reactPageView',
          path: location.pathname,
        },
      });
  }, [location.pathname]);

  return <>{children}</>;
};

export const UserDataTracker: FC<TrackerProps> = ({ children }) => {
  const { currentUser } = useContext(SecurityContext);

  useEffect(() => {
    tagManagerArgs &&
      TagManager.dataLayer({
        ...tagManagerArgs,
        dataLayer: {
          userId: currentUser.id,
        },
      });
  }, [currentUser]);

  return <>{children}</>;
};
