import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > p': {
      margin: theme.spacing(0.5, 0),
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}));
