import {
  Box,
  FormHelperText,
  makeStyles,
  MenuItem,
  SelectProps,
  TextFieldProps,
  withStyles,
} from '@material-ui/core';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';
import MuiTextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactNode } from 'react';

import { textFieldCommonProps } from '@components/form/TextField';

const StyledTextField = withStyles((theme) => ({
  root: {
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderLeftWidth: 0,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    },
    '& input.MuiOutlinedInput-inputMarginDense': {
      padding: theme.spacing(0.5, 1),
    },
  },
}))(MuiTextField);

const InputLabel = withStyles((theme) => ({
  root: {
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0, -17px)',
      fontSize: theme.typography.pxToRem(13),
    },
  },
}))(MuiInputLabel);

const Select = withStyles((theme) => ({
  root: {},
}))(MuiSelect);

const FormControl = withStyles((theme) => ({
  root: {
    marginTop: '20px',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}))(MuiFormControl);

const useLocalStyles = makeStyles(() => ({
  rootInline: {
    marginTop: 0,
  },
  selectInline: {
    padding: '4px 32px 4px 8px',
  },
}));

export type SelectAndTextInputProps = {
  label?: string;
  select?: SelectProps;
  text?: TextFieldProps;
  options: { value: any; label: ReactNode }[];

  size?: 'inline';

  error?: boolean;
  helperText?: React.ReactNode;
};

const SelectAndTextInput: FC<SelectAndTextInputProps> = ({
  label,
  select = {},
  text = {},
  options,

  size,

  error,
  helperText,
}) => {
  const localClasses = useLocalStyles();

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      error={error}
      className={clsx(size === 'inline' && localClasses.rootInline)}
    >
      {label && <InputLabel shrink={true}>{label}</InputLabel>}

      <Box display="flex">
        <Select
          {...select}
          classes={{
            ...(select.classes ?? {}),
            root: clsx(
              select.classes?.root,
              size === 'inline' && localClasses.selectInline
            ),
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <StyledTextField
          {...textFieldCommonProps}
          {...(size === 'inline'
            ? {
                margin: 'none',
                size: 'small',
              }
            : {})}
          {...text}
        />
      </Box>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectAndTextInput;
