import { QueryResult } from '@apollo/client';
import { Formik, ModalControl } from '@lib';
import { createContext, Dispatch, SetStateAction } from 'react';

import {
  Addon,
  AddonPriceInOfferModuleFragment,
  CalculationType,
  OfferItem,
  OfferItemPriceInOfferModuleFragment,
  ProductSection_CalculateOfferItemPriceQuery,
  ProductSection_CalculateOfferItemPriceQueryVariables,
  ProductSection_GetRecommendedAccessoriesQuery,
  ProductSection_GetRecommendedAccessoriesQueryVariables,
  ProductSpecification,
} from '@optioffer/graphql';

import { SearchAccessoryModalData } from '@containers/ProductSection/SearchAccessoryModal';

import { AddonModalData } from '../AddonModal';
import { DiscountModalData } from '../DiscountModal';
import { GetProductFn, ProductSearchResult } from '../domain';

type OfferItemModalFormik = Formik<{
  name: string;
  code: string;
  description: string;
  listPrice: number;
  discountType: CalculationType;
  discountValue: number;
  quantity: number;
  specifications: ProductSpecification[];
  children: OfferItem[];
  addons: Addon[];
}>;

const OfferItemModalContext = createContext<{
  control: ModalControl<ProductSearchResult>;
  updateOfferItem: (offerItem: OfferItem) => Promise<OfferItem>;
  openEditProduct: (data: ProductSearchResult) => void;
  getProduct: GetProductFn;
  isAccessoryModal?: boolean;
  offerItems: OfferItem[];

  accessoryModal: ModalControl<ProductSearchResult>;
  accessorySearchModal: ModalControl<SearchAccessoryModalData>;
  addonModal: ModalControl<AddonModalData>;
  discountModal: ModalControl<DiscountModalData>;

  formik: OfferItemModalFormik;
  pricing: OfferItemPriceInOfferModuleFragment | undefined;
  offerItem: OfferItem | undefined;
  offerItemInQuote: boolean;

  getAddonPricing(addon: Addon): AddonPriceInOfferModuleFragment | undefined;
  getOfferItem(it: ProductSearchResult): OfferItem | undefined;
  getDirtyOfferItem(
    values?: OfferItemModalFormik['initialValues']
  ): OfferItem | undefined;

  handleEditAddon(addon?: Addon): void;
  handleEditDiscount(): void;
  updateAccessory(it: OfferItem): Promise<OfferItem>;

  handleRemoveOfferItem(): Promise<any>;

  setShouldCalculatePricing: Dispatch<SetStateAction<boolean>>;
  setShouldLoadAccessories: Dispatch<SetStateAction<boolean>>;

  pricingQuery: QueryResult<
    ProductSection_CalculateOfferItemPriceQuery,
    ProductSection_CalculateOfferItemPriceQueryVariables
  >;
  recommendedAccessoriesQuery: QueryResult<
    ProductSection_GetRecommendedAccessoriesQuery,
    ProductSection_GetRecommendedAccessoriesQueryVariables
  >;
}>(undefined as any);

export default OfferItemModalContext;
