import { DVKField } from '@dvkiin/material-commons';
import React from 'react';

const GDRIVE_SERVICE_ACCOUNT =
  'optioffer-drive-generator@oo-backend.iam.gserviceaccount.com'; // TODO get this from the server

export const gDriveFields: DVKField[] = [
  {
    name: 'gDriveTemplateFile',
    label: 'Google Drive template file',
    type: 'text',
    disabled: false,
    infoModal: {
      title: 'Template used to generate documents',
      message: (
        <>
          <p>This is the file used as a template. It will not be modified.</p>
          <p>
            Important: this should be a Google Docs, NOT a doc, docx, pdf, or
            any other format. You can change a document type by opening it in
            Google Docs and going to{' '}
            <strong>File {`>`} Save as Google Docs</strong>.
          </p>
          <p>
            Important: please make sure you allow OptiDriveGenerator to view
            this file:
          </p>
          <ul>
            <li>
              Recommended: Share the folder with{' '}
              <strong>{GDRIVE_SERVICE_ACCOUNT}</strong> (make sure the{' '}
              <strong>Viewer</strong> role is selected).
            </li>
            or
            <li>
              Less secure: Get the <strong>share link</strong>, select{' '}
              <strong>Anyone with the link</strong> and choose{' '}
              <strong>Viewer</strong> as the role.
            </li>
          </ul>
        </>
      ) as any,
    },
  },
  {
    name: 'gDriveTargetFolder',
    label: 'Google Drive target folder',
    type: 'text',
    disabled: false,
    infoModal: {
      title: 'Target folder',
      message: (
        <>
          <p>This is the folder where the generated files to be created.</p>
          <p>
            Important: please make sure you allow OptiDriveGenerator to edit
            this folder:
          </p>
          <ul>
            <li>
              Recommended: Share the folder with{' '}
              <strong>{GDRIVE_SERVICE_ACCOUNT}</strong> (make sure the{' '}
              <strong>Editor</strong> role is selected).
            </li>
            or
            <li>
              Less secure: Get the <strong>share link</strong>, select{' '}
              <strong>Anyone with the link</strong> and choose{' '}
              <strong>Editor</strong> as the role.
            </li>
          </ul>
        </>
      ) as any,
    },
  },
  {
    name: 'gDriveEveryProduct',
    label: 'Generate 1 file for every offer item',
    type: 'checkbox',
    disabled: false,
    infoModal: {
      title: 'Generation strategy',
      message:
        'Choose to generate: one document for the entire quote (leave the box unchecked) or multiple documents, one for each offer item (check the box). One document can be used to generate a contract or delivery notice. Multiple documents can be used to generate warranties.',
    },
  } as DVKField,
];
