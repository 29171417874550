import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Auth_CurrentUserQuery,
  Auth_GetCompanyDetailsQuery,
  ProfileInAuthModuleFragment,
  ProfileType,
  UserInAuthModuleFragment,
  UserRole,
} from '@optioffer/graphql';

import { useLocalStorageState } from '@lib/hooks/useLocalStorageState';

export type SubscriptionStatus =
  | 'trialing'
  | 'active'
  | 'past_due'
  | 'incomplete'
  | 'canceled'
  | 'no_subscription';

export enum MenuMode {
  QUOTING = 'QUOTING',
  PRO = 'PRO',
  EMPLOYEE = 'EMPLOYEE',
  PRO_EMPLOYEE = 'PRO_EMPLOYEE',
}

export const SecurityContext = React.createContext<{
  navigate: (path: string) => void;
  currentUser: UserInAuthModuleFragment;
  currentProfile?: ProfileInAuthModuleFragment;
  company: {
    id: string;
    name: string;
    subscription: SubscriptionStatus;
    costAndMarginsOnlyForManagers: boolean;
    currency: {
      id: string;
      name: string;
      symbol: string;
    };
    // owner: any;
  };
  drawerOpen: boolean;
  setDrawerState: (state?: boolean) => void;
  setCurrentProfile: (profile: ProfileInAuthModuleFragment) => void;

  menuMode: MenuMode;
}>({
  navigate: () => null,
  currentUser: {
    id: 'default id',
    name: 'name',
    title: 'title',
    email: 'nobody@optioffer.com',
    roles: [],
    profiles: [],
  },
  company: {
    id: 'default id',
    name: 'default name',
    subscription: 'no_subscription',
    costAndMarginsOnlyForManagers: false,
    currency: {
      id: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    // owner: null,
  },
  drawerOpen: false,
  setDrawerState: () => null,
  setCurrentProfile: () => null,
  menuMode: MenuMode.QUOTING,
});

export type SecurityContextProviderProps = {
  userData: Auth_CurrentUserQuery;
  companyData: Auth_GetCompanyDetailsQuery | undefined;
};

export const SecurityContextProvider: FC<SecurityContextProviderProps> = ({
  children,
  userData,
  companyData,
}) => {
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentProfileId, setCurrentProfileId] = useLocalStorageState(
    'currentProfile'
  );

  useEffect(() => {
    if (!currentProfileId && userData?.currentUser?.profiles.length) {
      setCurrentProfileId(userData.currentUser.profiles[0].id);
    }
  }, [setCurrentProfileId, currentProfileId, userData?.currentUser]);

  const currentProfile = userData.currentUser!.profiles.find(
    (it) => it.id === currentProfileId
  );
  return (
    <SecurityContext.Provider
      value={{
        currentUser: userData.currentUser!,
        company: {
          ...companyData?.getCompanyDetails,
          subscription: (companyData?.getCurrentSubscriptionStatus?.status ||
            'no_subscription') as SubscriptionStatus,
        } as any,
        drawerOpen: drawerOpen,
        navigate: (path: string) =>
          history.push(path, { from: history.location.pathname }),
        setDrawerState: (state) => setDrawerOpen(() => state ?? !drawerOpen),

        currentProfile,
        setCurrentProfile: ({ id }: ProfileInAuthModuleFragment) =>
          setCurrentProfileId(id),
        menuMode:
          currentProfile?.profileType === ProfileType.EMPLOYEE
            ? MenuMode.EMPLOYEE
            : currentProfile?.profileType === ProfileType.PROFESSIONAL
            ? MenuMode.PRO
            : currentProfile?.profileType ===
              ProfileType.PROFESSIONAL_AND_EMPLOYEE
            ? MenuMode.PRO_EMPLOYEE
            : MenuMode.QUOTING,
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
};

export const useRoles = () => {
  const {
    currentUser: { roles },
  } = useContext(SecurityContext);
  return roles;
};

export const useHasRole = (role: UserRole) => {
  const roles = useRoles();
  return roles.indexOf(role) > -1;
};

export const useIsManagerOrCostsVisibleForEveryone = () => {
  const {
    currentUser: { roles },
    company: { costAndMarginsOnlyForManagers },
  } = useContext(SecurityContext);
  return !costAndMarginsOnlyForManagers || roles.indexOf(UserRole.MANAGER) > -1;
};
