import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useDrag } from 'react-dnd';

import ColoredCard from './ColoredCard';
import { DragItemType, Offer, OfferItemProduct } from './domain';
import useStyles from './styles';

type OfferItemProductCardProps = {
  data: OfferItemProduct;
  greyBackground?: boolean;
  navigate?: (id: string) => void;

  parentOfferId?: Offer['id'];
  currency: string;
  className?: string;
  disableColor?: boolean;
};

const OfferItemProductCard: FC<OfferItemProductCardProps> = ({
  data,
  greyBackground,
  navigate,
  parentOfferId,
  currency,
  disableColor = false,
  className,
}) => {
  const {
    productThumbnail,
    dragging,
    greyBackground: greyBackgroundClass,
  } = useStyles();
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: DragItemType.PRODUCT_VERSION,
      id: data.productId,
      productVersionId: data.productVersionId,
      parentOfferId,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function handleOnClick(event: React.MouseEvent) {
    event.stopPropagation();
    navigate!(data.productId);
  }

  return (
    <ColoredCard
      ref={drag}
      group={disableColor ? undefined : 'product'}
      className={clsx(
        className,
        isDragging && dragging,
        greyBackground && greyBackgroundClass
      )}
      onClick={navigate && handleOnClick}
    >
      <Grid container alignItems="flex-start" spacing={1}>
        <Grid item>
          {data.image && (
            <img
              className={productThumbnail}
              src={data.image.thumbnail}
              alt={`Thumbnail of ${data.name}`}
            />
          )}
        </Grid>
        <Grid item xs>
          <Typography variant="h6">{data.name}</Typography>
          <Typography variant="caption">{data.code}</Typography>
          <Typography align="right">
            {currency} {data.price}
          </Typography>
        </Grid>
      </Grid>
    </ColoredCard>
  );
};

export default OfferItemProductCard;
