import { SecurityContext } from '@lib';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { FC, useContext } from 'react';

import { OfferInOfferModuleFragment } from '@optioffer/graphql';

import {
  CustomerStepIcon,
  ProductsStepIcon,
  ReviewStepIcon,
  SendStepIcon,
  StepDoneIcon,
} from '@resources/icons';

export const MenuSeparator: FC<{
  className?: string;
  activeLeft?: boolean;
  activeRight?: boolean;
}> = ({ className, activeLeft, activeRight }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="8"
    height="30"
    viewBox="0 0 8 30"
    className={className}
    data-active-left={!!activeLeft}
    data-active-right={!!activeRight}
  >
    {!activeRight && !activeLeft && (
      <path
        id="MenuSeparator"
        data-name="MenuSeparator"
        d="M-1,-3,8,16,-1,33"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.4"
      />
    )}
    {activeLeft && (
      <polygon
        id="MenuSeparator"
        data-name="MenuSeparator"
        points="-1,-1 8,16 -1,31"
        fill="currentColor"
      />
    )}
    {activeRight && (
      <polygon
        id="MenuSeparator"
        data-name="MenuSeparator"
        points="0,0 16,0 16,30 0,30 8,16"
        fill="currentColor"
      />
    )}
  </svg>
);

const useLocalStyles = makeStyles((theme) => ({
  root: {},
  stepperWrapper: {
    height: 48,
  },
  step: {
    padding: theme.spacing(0.5, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1.4px solid #00000021',
    height: '100%',
    position: 'relative',

    '&:first-child': {
      borderRadius: '5px 0 0 5px',
    },

    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },

    '&:not(:last-child)': {
      borderRight: 0,
    },

    '&:not(:first-child)': {
      borderLeft: 0,
    },
  },
  activeStep: {
    border: `1.4px solid ${theme.palette.secondary.main}`,
    flex: 1,
    '& > $stepIcon': {
      marginRight: theme.spacing(1),
    },
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  stepIcon: {
    height: theme.spacing(2),
  },
  stepDoneIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  separator: {
    height: '100%',
    minWidth: '14px',
    color: '#dedede',
    border: '1.4px solid #00000021',
    borderLeft: 0,
    borderRight: 0,

    '&[data-active-left=true]': {
      color: theme.palette.secondary.main,
    },

    '&[data-active-right=true]': {
      color: theme.palette.secondary.main,
      border: 0,
    },
  },
}));

type MobileHeaderProps = {
  offer?: OfferInOfferModuleFragment;
  step: number;
  setStep: (step: number) => void;
};

const MobileHeader: FC<MobileHeaderProps> = ({ step, setStep }) => {
  const localClasses = useLocalStyles();
  const context = useContext(SecurityContext);

  return (
    <Box display="flex">
      <Box marginLeft={-1.5} marginRight={1}>
        <IconButton
          aria-label="go to offer list"
          onClick={() => context.setDrawerState()}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Box
        className={localClasses.stepperWrapper}
        display={'flex'}
        flex={1}
        alignItems={'center'}
      >
        <Box
          className={clsx(
            localClasses.step,
            step === 1 && localClasses.activeStep
          )}
          onClick={() => setStep(1)}
        >
          <CustomerStepIcon className={localClasses.stepIcon} />
          {step === 1 ? 'Customer' : ''}
          {step > 1 && <StepDoneIcon className={localClasses.stepDoneIcon} />}
        </Box>
        <MenuSeparator
          activeLeft={step === 1}
          activeRight={step === 2}
          className={clsx(localClasses.separator)}
        />
        <Box
          className={clsx(
            localClasses.step,
            step === 2 && localClasses.activeStep
          )}
          onClick={() => setStep(2)}
        >
          <ProductsStepIcon className={localClasses.stepIcon} />
          {step === 2 ? 'Products' : ''}
          {step > 2 && <StepDoneIcon className={localClasses.stepDoneIcon} />}
        </Box>
        <MenuSeparator
          activeLeft={step === 2}
          activeRight={step === 3}
          className={clsx(localClasses.separator)}
        />
        <Box
          className={clsx(
            localClasses.step,
            step === 3 && localClasses.activeStep
          )}
          onClick={() => setStep(3)}
        >
          <ReviewStepIcon className={localClasses.stepIcon} />
          {step === 3 ? 'Review' : ''}
          {step > 3 && <StepDoneIcon className={localClasses.stepDoneIcon} />}
        </Box>
        <MenuSeparator
          activeLeft={step === 3}
          activeRight={step === 4}
          className={clsx(localClasses.separator)}
        />
        <Box
          className={clsx(
            localClasses.step,
            step === 4 && localClasses.activeStep
          )}
          onClick={() => setStep(4)}
        >
          <SendStepIcon className={localClasses.stepIcon} />
          {step === 4 ? 'Send' : ''}
        </Box>
      </Box>
    </Box>
  );
};

export default MobileHeader;
