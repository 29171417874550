import { OptiTextField } from '@components';
import { mapFormikToTextField, useIsMobile } from '@lib';
import { Box, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import OOModal from '@components/modals/OOModal';

import { ModalControl } from '@lib/materialCommons';

import useStyles from '../styles';

// const useLocalStyles = makeStyles((theme) => ({
//   root: {},
// }));

export type TermsAndConditionsModalData = {
  termsAndConditions: string;
};

type TermsAndConditionsModalProps = {
  control: ModalControl<TermsAndConditionsModalData>;
  updateTermsAndConditions: (termsAndConditions: string) => Promise<any>;
};

const TermsAndConditionsModal: FC<TermsAndConditionsModalProps> = ({
  control,
  updateTermsAndConditions,
}) => {
  // const localClasses = useLocalStyles();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      termsAndConditions: '',
    },
    onSubmit: async (values) => {
      if (!control.data) return;

      await updateTermsAndConditions(values.termsAndConditions);

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (control.data) {
      formikRef.current.resetForm({
        values: {
          termsAndConditions: control.data.termsAndConditions ?? '',
        },
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, control.data]);

  const title = 'Terms and Conditions';
  return (
    <>
      <OOModal
        open={control.isOpen}
        onClose={control.close}
        title={title}
        minWidth="sm"
      >
        <Box marginX={isMobile ? 0 : 3} marginBottom={isMobile ? 0 : 3}>
          <form onSubmit={formik.handleSubmit}>
            {!isMobile && (
              <Box className={classes.desktopModalTitle}>{title}</Box>
            )}
            <Box height={'100%'} marginBottom={10}>
              <Box paddingX={1.5}>
                <Box marginY={2}>
                  <OptiTextField
                    label="Terms and Conditions"
                    multiline
                    {...mapFormikToTextField(formik, 'termsAndConditions')}
                  />
                </Box>
              </Box>
            </Box>

            <Box marginBottom={10} />

            <Box display="flex" className={classes.floatingFooter}>
              <Box flex="1">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={!formik.dirty}
                >
                  {'Save Changes'}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </OOModal>
    </>
  );
};

export default TermsAndConditionsModal;
