import { makeStyles } from '@material-ui/core';

import { addColoredBar } from '../../components/Card/styles';
import { OOTheme } from '../../theme';

export default makeStyles((theme: OOTheme) => ({
  tableCard: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    ...addColoredBar(theme, theme.oo.colors.client),
  },
  viewEditCard: {
    maxWidth: 700,
    ...addColoredBar(theme, theme.oo.colors.client),
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  toolbar: {
    height: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
  },
  searchInput: {
    width: 400,
    marginRight: theme.spacing(2),
    fontSize: 14,
    height: 40,
    backgroundColor: theme.palette.common.white,
  },
  countryInput: {
    height: theme.spacing(5),
    minWidth: 140,
    fontSize: 14,
  },
  icon: {
    width: 20,
    height: 20,
  },
  searchBar: { position: 'relative', display: 'inline-block' },
}));
