import React, { FC, useCallback, useMemo } from 'react';

import { UserRole } from '@optioffer/graphql';

import {
  ApolloModalErrors,
  ApolloSnackbars,
} from '../../../components/Feedback';
import {
  booleanResultToError,
  getInvalidFields,
  getOrigin,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
  useHasRole,
} from '../../../lib';
import { CompanyUser, PendingInvitation, UserUpdateRoleInput } from '../domain';
import {
  ADD_USER_TO_COMPANY,
  FIND_ALL_COMPANY_USERS,
  FIND_ALL_PENDING_INVITATIONS,
  REMOVE_USER_FROM_COMPANY,
  RESEND_USER_INVITATION,
  SEND_RESET_PASSWORD_LINK,
  TRANSFER_OWNERSHIP,
  UPDATE_USER_ROLES,
} from '../graphql';
import UsersSection from '../sections/Users';

type TeamSettingsPageProps = {};

const TeamSettingsPage: FC<TeamSettingsPageProps> = () => {
  const isManager = useHasRole(UserRole.MANAGER);

  //region Queries
  const {
    data: { getAllCompanyUsers } = { getAllCompanyUsers: [] },
    error: findUsersError,
    loading: loadingUsers,
  } = useEnhancedQuery<{ getAllCompanyUsers: CompanyUser[] }>(
    FIND_ALL_COMPANY_USERS
  );
  const {
    data: { getAllPendingInvitations } = { getAllPendingInvitations: [] },
    error: findInvitationsError,
  } = useEnhancedQuery<{ getAllPendingInvitations: PendingInvitation[] }>(
    FIND_ALL_PENDING_INVITATIONS,
    { skip: !isManager }
  );
  //endregion

  //region Mutations
  const [
    updateUserRoles,
    { data: updateUserRolesData, error: updateUserRolesError },
  ] = useEnhancedMutation(UPDATE_USER_ROLES, {
    refetchQueries: ['getAllCompanyUsers'],
  });
  const [
    transferOwnership,
    { data: transferOwnershipData, error: transferOwnershipError },
  ] = useEnhancedMutation(TRANSFER_OWNERSHIP, {
    refetchQueries: ['getAllCompanyUsers'],
  });
  const [
    removeUserFromCompany,
    { data: removeUserData, error: removeUserError },
  ] = useEnhancedMutation(REMOVE_USER_FROM_COMPANY, {
    refetchQueries: ['getAllCompanyUsers'],
  });
  const [
    addUserToCompany,
    { data: addUserData, error: addUserError },
  ] = useEnhancedMutation(ADD_USER_TO_COMPANY, {
    refetchQueries: ['getAllPendingInvitations'],
  });
  const [
    resendUserInvitation,
    { data: resendInvitationData, error: resendInvitationError },
  ] = useEnhancedMutation(RESEND_USER_INVITATION, {
    refetchQueries: ['getAllPendingInvitations'],
  });
  const [
    deleteUserInvitation,
    { data: deleteInvitationData, error: deleteInvitationError },
  ] = useEnhancedMutation(REMOVE_USER_FROM_COMPANY, {
    refetchQueries: ['getAllPendingInvitations'],
  });
  const [
    sendResetPasswordLink,
    { data: sendResetPasswordLinkData, error: sendResetPasswordLinkError },
  ] = useEnhancedMutation(SEND_RESET_PASSWORD_LINK);
  //endregion

  //region errors
  const errors = useMemo(
    () => [
      {
        error: findUsersError,
        message:
          'There was an error while fetching the company users. If this persists, please contact support.',
      },
      {
        error: findInvitationsError,
        message:
          'There was an error while fetching the pending invitations. If this persists, please contact support.',
      },
      {
        error: removeUserError,
        message:
          'There was an error while deleting the user. If this persists, please contact support.',
      },
      {
        error: booleanResultToError(removeUserData, 'removeUserFromCompany'),
        message:
          'The user you try to delete is invalid. Please refresh the page. If this persists, please contact support.',
      },
      {
        error: addUserError,
        message:
          'We could not send an invitation to the email address you provided. Please check the input and try again.',
      },
      {
        error: resendInvitationError,
        message:
          'We could not resend the invitation. Please try again later. If this persists, please contact support.',
      },
      {
        error: deleteInvitationError,
        message:
          'We could not cancel the invitation. Please try again later. If this persists, please contact support.',
      },
      {
        error: booleanResultToError(
          deleteInvitationData,
          'removeUserFromCompany'
        ),
        message:
          'The invitation you try to delete is invalid. Please refresh the page. If this persists, please contact support.',
      },
      {
        error: sendResetPasswordLinkError,
        message:
          'We were not able to send the password reset link. Please try again. If this persists, please contact support.',
      },
      {
        error: updateUserRolesError,
        message:
          'There was an error updating the user roles. Please try again and if this persists, please contact support.',
      },
      {
        error: transferOwnershipError,
        message:
          'There was an error while transferring the ownership. Please try again and if this persists, please contact support.',
      },
    ],
    [
      findUsersError,
      findInvitationsError,
      removeUserError,
      removeUserData,
      addUserError,
      resendInvitationError,
      deleteInvitationError,
      deleteInvitationData,
      sendResetPasswordLinkError,
      updateUserRolesError,
      transferOwnershipError,
    ]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      {
        result: removeUserData,
        message: 'User deleted.',
        booleanKey: 'removeUserFromCompany',
      },
      { result: addUserData, message: 'User successfully invited.' },
      {
        result: deleteInvitationData,
        message: 'Invitation canceled.',
        booleanKey: 'removeUserFromCompany',
      },
      {
        result: resendInvitationData,
        message: 'Invitation successfully resent.',
      },
      {
        result: sendResetPasswordLinkData,
        message: 'Password invalidated & reset password link sent to user.',
      },
      { result: updateUserRolesData, message: 'User roles updated.' },
      { result: transferOwnershipData, message: 'Ownership transferred. ' },
    ],
    [
      removeUserData,
      addUserData,
      deleteInvitationData,
      resendInvitationData,
      sendResetPasswordLinkData,
      updateUserRolesData,
      transferOwnershipData,
    ]
  );
  //endregion

  //region Handlers
  const handleDeleteUser = useCallback(
    async (userId: CompanyUser['id']) => {
      return removeUserFromCompany({ variables: { id: userId } }).catch(
        NOOP_graphqlErrorManagement
      );
    },
    [removeUserFromCompany]
  );

  const handleUpdateUserRoles = useCallback(
    async (
      userId: CompanyUser['id'],
      rolesUpdateInput: UserUpdateRoleInput[]
    ) => {
      return updateUserRoles({
        variables: {
          userId: userId,
          userUpdateRoleInputList: rolesUpdateInput,
        },
      }).catch(NOOP_graphqlErrorManagement);
    },
    [updateUserRoles]
  );

  const handleTransferOwnership = useCallback(
    async (userId: CompanyUser['id']) => {
      return transferOwnership({
        variables: {
          userId,
        },
      }).catch(NOOP_graphqlErrorManagement);
    },
    [transferOwnership]
  );

  const handleAddUser = useCallback(
    async (email: CompanyUser['email']) => {
      return addUserToCompany({
        variables: { email, origin: getOrigin() },
      }).catch(NOOP_graphqlErrorManagement);
    },
    [addUserToCompany]
  );

  const handleResendInvitation = useCallback(
    async (userId: CompanyUser['id']) => {
      return resendUserInvitation({
        variables: { id: userId, origin: getOrigin() },
      }).catch(NOOP_graphqlErrorManagement);
    },
    [resendUserInvitation]
  );

  const handleDeleteInvitation = useCallback(
    async (userId: CompanyUser['id']) => {
      return deleteUserInvitation({ variables: { id: userId } }).catch(
        NOOP_graphqlErrorManagement
      );
    },
    [deleteUserInvitation]
  );

  const handleResetUserPassword = useCallback(
    async (email: CompanyUser['email']) => {
      return sendResetPasswordLink({
        variables: { email, origin: getOrigin() },
      }).catch(NOOP_graphqlErrorManagement);
    },
    [sendResetPasswordLink]
  );
  //endregion

  return (
    <>
      {loadingUsers ? (
        'Loading details'
      ) : (
        <UsersSection
          users={getAllCompanyUsers}
          pendingInvitations={getAllPendingInvitations}
          onDeleteUser={handleDeleteUser}
          onAddUser={handleAddUser}
          onUpdateUserRoles={handleUpdateUserRoles}
          onTransferOwnership={handleTransferOwnership}
          onResetUserPassword={handleResetUserPassword}
          onResendInvitation={handleResendInvitation}
          onDeleteInvitation={handleDeleteInvitation}
          inviteUserInvalidFields={getInvalidFields(addUserError) || undefined} // TODO: material commons
        />
      )}

      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default TeamSettingsPage;
