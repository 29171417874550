import { OptiDatePicker, OptiTextField } from '@components';
import MomentUtils from '@date-io/moment';
import {
  ErrorModal,
  FlexExpander,
  SuccessSnackbar,
} from '@dvkiin/material-commons';
import {
  mapFormikToTextField,
  toStandardSort,
  useEnhancedMutation,
  useEnhancedQuery,
  useIsMobile,
  useModal,
  usePaginationFromUrl,
} from '@lib';
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateRangeIcon } from '@material-ui/pickers/_shared/icons/DateRangeIcon';
import clsx from 'clsx';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import React, { FC, useCallback, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { useThrottledMemo } from '@optioffer/core';
import {
  CompanyUser,
  NewOffer_CreateOfferDocument,
  OfferStatus,
} from '@optioffer/graphql';

import QuoteTable from '@components/Table/OptiQuoteTable';
import CreatorSelector from '@components/form/CreatorSelector';
import OOModal from '@components/modals/OOModal';

import { FIND_ALL_COMPANY_USERS } from '../../Settings/graphql';
import CreateQuoteModal, { CreateQuoteModalData } from '../CreateQuoteModal';
import { Offer, OfferPage } from '../domain';
import { SEARCH_OFFERS } from '../graphql';
import useStyles from '../styles';
import OffersTableMobile from './mobile';

const OfferListPage: FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { push } = useHistory();

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    onSubmit: async (values) => {
      await handleCreateOffer(values.title);
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const {
    isOpen: isCreateModalOpen,
    close: closeCreateModal,
    open: openCreateModal,
  } = useModal();

  // const {
  //   isOpen: isDeleteModalOpen,
  //   data: deleteModalData,
  //   open: openDeleteModal,
  //   close: closeDeleteModal,
  // } = useModal<Offer>();
  const createQuoteModal = useModal<CreateQuoteModalData>();

  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>('All');
  const [selectedCreator, setSelectedCreator] = useState<CompanyUser | null>(
    null
  );
  const defaultPagination = { page: 0, rowsPerPage: 10 };
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useThrottledMemo(
    () => searchString,
    [searchString],
    1000
  );
  const {
    graphQLPagination,
    pagination,
    handlePaginationUpdate,
  } = usePaginationFromUrl({
    pagination: { page: 0, rowsPerPage: 10 },
    sort: '-createdAt',
  });

  // const {
  //   data: { findAllOffers } = { findAllOffers: undefined },
  //   error: getError,
  // } = useEnhancedQuery<{ findAllOffers?: OfferPage }>(FIND_ALL_OFFERS, {
  //   variables: { pagination: graphQLPagination },
  // });

  // const [deleteOffer, { error: deleteError }] = useEnhancedMutation<
  //   { deleteOffer: boolean },
  //   unknown
  // >(DELETE_OFFER, { refetchQueries: ['findAllOffers'] });

  // const [
  //   cloneOffer,
  //   { data: cloneOfferResult, error: cloneOfferError },
  // ] = useEnhancedMutation(CLONE_OFFER, {
  //   refetchQueries: ['findAllOffers'],
  // });

  const [
    createNewOffer,
    { data: createResult, error: createError },
  ] = useEnhancedMutation(NewOffer_CreateOfferDocument, {
    refetchQueries: ['findAllOffers'],
  });

  const {
    data: { getAllCompanyUsers } = { getAllCompanyUsers: [] },
  } = useEnhancedQuery<{ getAllCompanyUsers: CompanyUser[] }>(
    FIND_ALL_COMPANY_USERS
  );

  const {
    data: { searchOffers } = { searchOffers: undefined },
    error: searchOffersError,
  } = useEnhancedQuery<{ searchOffers?: OfferPage }>(SEARCH_OFFERS, {
    variables: {
      searchString: debouncedSearchString,
      pagination: graphQLPagination,
      status: selectedStatus === 'All' ? null : selectedStatus,
      creatorId: selectedCreator?.id,
      creationDate: selectedDate?.startOf('day').valueOf(),
    },
  });

  // async function handleCloneOffer(offer: Offer) {
  //   try {
  //     const {
  //       data: { cloneOffer: clonedOffer },
  //     } = await cloneOffer({
  //       variables: {
  //         id: offer.id,
  //       },
  //     });
  //     handleNavigateToOffer(clonedOffer);
  //   } catch {
  //     NOOP_graphqlErrorManagement();
  //   }
  // }

  const isMobile = useIsMobile();
  const handleNavigateToOffer = useCallback(
    ({ id }: Pick<Offer, 'id'>) => {
      push(`/new-offer/${id}`);
    },
    [push]
  );

  // async function handleOfferDelete() {
  //   await deleteOffer({
  //     variables: { id: deleteModalData && deleteModalData.id },
  //   });
  //   closeDeleteModal();
  // }

  async function handleCreateOffer(title: string) {
    const newOffer = await createNewOffer({ variables: { title } });
    if (newOffer.data) {
      return handleNavigateToOffer(newOffer.data.createOffer);
    }
  }

  const handlePageChange = (page: number) => {
    handlePaginationUpdate({ page, rowsPerPage: 10 }, undefined);
  };

  function renderTable() {
    if (searchOffersError)
      return (
        <ErrorModal
          error={searchOffersError}
          message="There was an error while loading the offers. Please try again later. If the problem persists, please contact and administrator."
        />
      );
    if (!searchOffers) return 'Loading...';
    if (!searchOffers?.total) return 'You have no offers yet.';

    if (isMobile)
      return (
        <OffersTableMobile
          data={searchOffers?.offers ?? []}
          onEdit={handleNavigateToOffer}
          page={pagination.page + 1}
          pageCount={Math.round(searchOffers?.total / 10)}
          onPaginationUpdate={handlePageChange}
        />
      );
    return (
      <QuoteTable
        data={searchOffers?.offers}
        pageCount={Math.round(searchOffers?.total / 10)}
        onPaginationUpdate={handlePageChange}
        onEdit={handleNavigateToOffer}
        sort={graphQLPagination.sort}
        page={pagination.page + 1}
        onSortUpdate={(newSort: string) => {
          handlePaginationUpdate(defaultPagination, toStandardSort(newSort));
        }}
      />
    );
  }

  const searchTextField = (
    <TextField
      style={{ flex: 1 }}
      autoFocus
      placeholder="Search title or client"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        ),
        endAdornment: searchString && (
          <InputAdornment
            position="end"
            onClick={() => {
              setSearchString('');
              handlePageChange(0);
            }}
          >
            <CloseIcon cursor={'pointer'} />
          </InputAdornment>
        ),
        className: classes.searchInput,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      value={searchString}
      onChange={({ target: { value } }) => {
        setSearchString(value);
        handlePageChange(0);
      }}
    />
  );
  return (
    <>
      {isMobile ? (
        <Box display="flex" marginBottom={2} width="100%">
          {searchTextField}

          <Button
            className={classes.iconButton}
            variant="contained"
            color="primary"
            onClick={openCreateModal}
          >
            <AddIcon />
          </Button>
        </Box>
      ) : (
        <>
          <h2>Quotes</h2>
          <div className={classes.subHeader}>
            <MenuItem
              className={clsx(
                classes.inlineBlock,
                selectedStatus?.toString() === 'All' && classes.activeStatus
              )}
              onClick={() => {
                setSelectedStatus('All');
                handlePageChange(0);
              }}
            >
              All
            </MenuItem>
            {Object.values(OfferStatus).map((status) => (
              <MenuItem
                key={status.toString()}
                className={clsx(
                  classes.inlineBlock,
                  selectedStatus === status.toString() && classes.activeStatus
                )}
                onClick={() => {
                  setSelectedStatus(status);
                  handlePageChange(0);
                }}
              >
                {status}
              </MenuItem>
            ))}
            <FlexExpander />
            <Button
              className={classes.addButton}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={openCreateModal}
            >
              Create Quote
            </Button>
          </div>
          <div className={classes.toolbar}>
            <div className={classes.searchBar}>{searchTextField}</div>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="en-gb">
              <Box display={'inline-flex'}>
                <OptiDatePicker
                  style={{ marginTop: 0, color: '#707070' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeIcon className={classes.icon} />
                      </InputAdornment>
                    ),
                    endAdornment: selectedDate && (
                      <InputAdornment
                        position="end"
                        onClick={(event) => {
                          event.stopPropagation();
                          setSelectedDate(null);
                          handlePageChange(0);
                        }}
                      >
                        <CloseIcon cursor={'pointer'} />
                      </InputAdornment>
                    ),
                    className: classes.pickerInput,
                  }}
                  emptyLabel="Creation date"
                  value={selectedDate ? moment(selectedDate) : null}
                  onChange={(creationDate) => {
                    setSelectedDate(creationDate);
                    handlePageChange(0);
                  }}
                />
              </Box>
            </MuiPickersUtilsProvider>
            <Box display={'inline-flex'} className={classes.pickerInput}>
              <CreatorSelector
                options={getAllCompanyUsers}
                onCreatorSelected={(creator) => {
                  setSelectedCreator(creator);
                  handlePageChange(0);
                }}
                value={selectedCreator}
              />
            </Box>
          </div>
        </>
      )}
      {renderTable()}
      <OOModal
        open={isCreateModalOpen}
        title="New Quote"
        onClose={closeCreateModal}
      >
        <Box marginX={isMobile ? 2 : 6} marginBottom={isMobile ? 0 : 3}>
          <h2 className={classes.header}>New Quote</h2>
          <form onSubmit={formik.handleSubmit}>
            <Box height={'100%'} marginBottom={2}>
              <Box paddingX={1.5}>
                <Box marginY={2}>
                  <OptiTextField
                    required
                    label="Quote name"
                    {...mapFormikToTextField(formik, 'title')}
                  />
                </Box>
              </Box>
            </Box>

            <Box display="flex">
              <Box flex="1" margin={isMobile ? 2 : 1.5}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={!formik.dirty || !formik.values.title}
                >
                  {'Create'}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </OOModal>
      <CreateQuoteModal
        control={createQuoteModal}
        createQuote={handleCreateOffer}
      />

      {/*<ConfirmationModal*/}
      {/*  title={`Delete offer ? `}*/}
      {/*  message={`Are you sure you want to delete offer with total sum of '${deleteModalData?.pricing?.netPrice}' for client '${deleteModalData?.client?.name}'?`}*/}
      {/*  open={isDeleteModalOpen}*/}
      {/*  onCancel={closeDeleteModal}*/}
      {/*  onAccept={handleOfferDelete}*/}
      {/*/>*/}

      {createError && (
        <ErrorModal
          key={(createError as any).timestamp}
          error={createError}
          message="There was an error while creating the offer. Please check your input and try again."
        />
      )}

      {/*{deleteError && (*/}
      {/*  <ErrorModal*/}
      {/*    key={(deleteError as any).timestamp}*/}
      {/*    error={deleteError}*/}
      {/*    message="There was an error while deleting the offer. Please try again."*/}
      {/*  />*/}
      {/*)}*/}

      {/*{cloneOfferError && (*/}
      {/*  <ErrorModal*/}
      {/*    key={(cloneOfferError as any).timestamp}*/}
      {/*    error={cloneOfferError}*/}
      {/*    message="There was an error while cloning the offer. Please try again."*/}
      {/*  />*/}
      {/*)}*/}

      {createResult && (
        <SuccessSnackbar
          key={createResult.createOffer.id}
          message={
            <span>
              Offer{' '}
              {createResult.createOffer.client
                ? `for client '${createResult.createOffer.client!.name}' `
                : ' '}
              created.
            </span>
          }
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => handleNavigateToOffer(createResult.createOffer)}
            >
              View and edit
            </Button>
          }
        />
      )}

      {/*{cloneOfferResult && (*/}
      {/*  <SuccessSnackbar*/}
      {/*    key={cloneOfferResult.cloneOffer.id}*/}
      {/*    message={*/}
      {/*      <span>*/}
      {/*        Offer for client '{cloneOfferResult.cloneOffer.client!.name}'*/}
      {/*        cloned.*/}
      {/*      </span>*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default OfferListPage;
