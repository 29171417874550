import {
  DVKPagination,
  DVKPaginationWithSort,
  DVKSort,
} from '@dvkiin/material-commons';

export type GraphQLSortString = string; // 'column', '-column', '+column1 -column2', ...

export type GraphQLPagination = {
  first: number;
  offset: number;
  sort: GraphQLSortString;
};

export type Paged = {
  total: number;
};

export type DVKPaginationState = {
  pagination: DVKPagination;
  sort: DVKSort | null;
};

export type PaginationState = DVKPaginationState & {
  graphQLPagination: GraphQLPagination;
};

export type UpdatePaginationHandler = (
  pagination: DVKPagination | undefined,
  sort: DVKSort | null | undefined
) => void;

export function toBothStates(
  pagination: DVKPagination,
  sort: DVKSort | null
): PaginationState {
  return {
    pagination,
    sort,
    graphQLPagination: toGraphQLPagination(pagination, sort),
  };
}

export function toStandardPagination(
  graphQLPagination: GraphQLPagination
): DVKPaginationWithSort {
  const { first, offset, sort } = graphQLPagination;

  return {
    ...toStandardSort(sort),
    page: ~~(offset / first),
    rowsPerPage: first,
  };
}

export function toSortString(
  order: string,
  orderBy: string
): GraphQLSortString {
  if (!orderBy) return '';
  return `${order === 'desc' ? '-' : ''}${orderBy}`;
}

export function toStandardSort(sort?: string): DVKSort {
  if (!sort || !sort.length) return { order: 'asc', orderBy: '' };
  const orderBy = /^-*(.*)$/g.exec(sort);

  return {
    order: sort[0] === '-' ? 'desc' : 'asc',
    orderBy: orderBy ? orderBy[1] : '',
  };
}

export function toGraphQLPagination(
  pagination: DVKPagination,
  sort: DVKSort | null
): GraphQLPagination {
  const { page, rowsPerPage } = pagination;
  return {
    first: rowsPerPage,
    offset: rowsPerPage * page,
    sort: sort ? toSortString(sort.order, sort.orderBy) : '',
  };
}

export function goBackIfCurrentPageEmpty(
  pagedResponse: Paged | undefined,
  pagination: DVKPagination,
  sort: DVKSort | null,
  handlePaginationUpdate: UpdatePaginationHandler
) {
  if (
    pagedResponse &&
    pagedResponse.total &&
    pagination.page * pagination.rowsPerPage >= pagedResponse.total
  ) {
    handlePaginationUpdate(
      { ...pagination, page: Math.max(0, pagination.page - 1) },
      sort
    );
  }
}
