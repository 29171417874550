import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

import { Separator } from '../../components';
import LoginWrapper from './wrapper';

type JoinTeamPageProps = {};

const JoinTeamPage: FC<JoinTeamPageProps> = () => {
  return (
    <LoginWrapper title="How do I join my Team?" backLink>
      <Typography variant="body2" gutterBottom>
        In order to guarantee the security of your company, we require your
        administrator to invite you before we can give you access.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Please provide them with your email address and ask them to send you and
        invitation.
      </Typography>

      <Typography variant="body1">
        After this, you will receive and invitation in your inbox. Please follow
        the instructions you receive in order to finish the signup process. You
        will then have access to your team's account!
      </Typography>

      <Separator />

      <Typography variant="h6">For your admin</Typography>

      <Typography variant="body1" gutterBottom>
        You can invite additional people to your team from the company settings
        page.
      </Typography>

      <Typography variant="body1" gutterBottom>
        If you require help, please contact{' '}
        <a href="mailto:support@optioffer.com">support@optioffer.com</a>. We'll
        be more than happy to assist you!
      </Typography>
    </LoginWrapper>
  );
};

export default JoinTeamPage;
