import { ModalTransition } from '@components';
import { useIsMobile } from '@lib';
import {
  Box,
  Dialog,
  DialogProps,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100vw',
    [theme.breakpoints.up('lg')]: {
      overflowY: 'visible',
    },
  },
  modalHeader: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 'bold',
  },
  modalSubHeader: { fontSize: theme.typography.pxToRem(13), opacity: '60%' },
  minWidthSm: {
    [theme.breakpoints.up('lg')]: {
      minWidth: theme.breakpoints.values.sm,
    },
  },
  minWidthMd: {
    [theme.breakpoints.up('lg')]: {
      minWidth: theme.breakpoints.values.md,
    },
  },
  minWidthLg: {
    [theme.breakpoints.up('lg')]: {
      minWidth: theme.breakpoints.values.lg,
    },
  },

  stickToBottomDialogPaper: {
    margin: 0,
  },

  stickToBottomDialogPaperWidthFalse: {
    width: '100%',
    maxWidth: '100%',
  },

  stickToBottomDialogScrollPaper: {
    alignItems: 'flex-end',
  },
}));

type OOModalProps = DialogProps & {
  title: string;
  subTitle?: string;
  minWidth?: 'sm' | 'md' | 'lg';
  stickToBottom?: boolean;
  innerWrapperClassName?: string;
};

const OOModal: FC<OOModalProps> = ({
  children,
  title,
  subTitle,
  minWidth,
  onClose,
  className,
  innerWrapperClassName,
  stickToBottom,
  ...rest
}) => {
  const localClasses = useLocalStyles();
  const isMobile = useIsMobile();

  return (
    <Dialog
      TransitionComponent={ModalTransition}
      keepMounted
      fullScreen={isMobile && !stickToBottom}
      maxWidth={isMobile ? false : 'lg'}
      scroll={isMobile ? 'paper' : 'body'}
      onClose={onClose}
      className={clsx(className)}
      classes={{
        ...(stickToBottom
          ? {
              paper: localClasses.stickToBottomDialogPaper,
              paperWidthFalse: localClasses.stickToBottomDialogPaperWidthFalse,
              scrollPaper: localClasses.stickToBottomDialogScrollPaper,
            }
          : {
              paper: localClasses.root,
            }),
      }}
      {...rest}
    >
      <Box
        height={'100%'}
        className={clsx(
          innerWrapperClassName,
          minWidth === 'sm' && localClasses.minWidthSm,
          minWidth === 'md' && localClasses.minWidthMd,
          minWidth === 'lg' && localClasses.minWidthLg
        )}
      >
        <Box
          boxShadow={isMobile ? 2 : 0}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent={isMobile ? 'flex-start' : 'flex-end'}
        >
          {isMobile ? (
            !stickToBottom && (
              <>
                <IconButton
                  color="primary"
                  onClick={(e) => onClose?.(e, 'backdropClick')}
                >
                  <ChevronLeftIcon />
                </IconButton>

                <Box>
                  <Typography className={localClasses.modalHeader}>
                    {title}
                  </Typography>
                  {subTitle && (
                    <Typography className={localClasses.modalSubHeader}>
                      {subTitle}
                    </Typography>
                  )}
                </Box>
              </>
            )
          ) : (
            <>
              <IconButton
                color="primary"
                onClick={(e) => onClose?.(e, 'backdropClick')}
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Box>

        {children}
      </Box>
    </Dialog>
  );
};

export default OOModal;
