import { OptiTextField } from '@components';
import {
  getOrigin,
  hasInvalidField,
  mapFormikToButtonSwitchField,
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
  useIsMobile,
  useQueryParams,
} from '@lib';
import {
  Box,
  Container,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import { useFormik } from 'formik';
import jwtDecode from 'jwt-decode';
import React, {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { ooBrand } from '@optioffer/core';
import {
  Auth_SendSignupLinkDocument,
  Auth_SignupDocument,
  Auth_SignupMutation,
  Auth_UpdateUserMarketingDataDocument,
  Auth_ValidateSignupTokenDocument,
} from '@optioffer/graphql';

import BubbleStepIndicator from '@components/BubbleStepIndicator';
import LoadingAwareButton from '@components/LoadingAwareButton';
import ButtonSwitchInput from '@components/form/ButtonSwitchInput';

import { ReactComponent as CompanySize1Illustration } from '@resources/illustrations/onboarding/company_size_1.svg';
import { ReactComponent as CompanySize2Illustration } from '@resources/illustrations/onboarding/company_size_2-10.svg';
import { ReactComponent as CompanySize3Illustration } from '@resources/illustrations/onboarding/company_size_11-50.svg';
import { ReactComponent as CompanySize4Illustration } from '@resources/illustrations/onboarding/company_size_50.svg';
import { ReactComponent as CompanyTypeDistributorIllustration } from '@resources/illustrations/onboarding/company_type_distributor.svg';
import { ReactComponent as CompanyTypeManufacturerIllustration } from '@resources/illustrations/onboarding/company_type_manufacturer.svg';
import { ReactComponent as CompanyTypeOtherIllustration } from '@resources/illustrations/onboarding/company_type_other.svg';
import { ReactComponent as CompanyTypeResellerIllustration } from '@resources/illustrations/onboarding/company_type_reseller.svg';

import useStyles from '../styles';
import LoginWrapper from '../wrapper';

const useLocalStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(10, 0),
    },
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 600, // semi-bold
    textAlign: 'center',
  },
  subHeader: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
  },
  toc: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(12),
    color: `${ooBrand.colors.black}78`, // 60%
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 4),

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0),
    },
  },
  nextButtonWrapper: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        maxWidth: 200,
      },
    },
  },
  extraLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600, // semi-bold
  },
  onBoardingButtonsWrapper: {
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  onBoardingButton: {
    width: 160,
    height: 150,
  },
  onBoardingButtonIcon: {
    height: '55%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '&:not(.active)': {
      color: ooBrand.colors.gray['400'],
    },
  },
  onBoardingButtonSubHeader: {
    marginTop: theme.spacing(-0.5),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    '&:not(.active)': {
      color: ooBrand.colors.gray['400'],
    },
  },
}));

type SignupProps = {};

const Signup: FC<SignupProps> = () => {
  const { submit } = useStyles();

  const localClasses = useLocalStyles();
  const isMobile = useIsMobile();

  const { token } = useQueryParams();
  const { push } = useHistory();
  const [step, setStep] = useState(1);

  const {
    data: { validateSignupToken } = { validateSignupToken: undefined },
    error: validateError,
    loading,
    refetch,
  } = useEnhancedQuery(Auth_ValidateSignupTokenDocument, {
    variables: { token: token as string },
  });

  const [
    sendSignupLink,
    {
      loading: sendSignupLinkLoading,
      data: sendSignupLinkData,
      error: sendSignupLinkError,
      called: sendSignupLinkCalled,
    },
  ] = useEnhancedMutation(Auth_SendSignupLinkDocument, {
    fetchPolicy: 'no-cache',
  });
  const [updateMarketingData] = useEnhancedMutation(
    Auth_UpdateUserMarketingDataDocument
  );

  const isOwner = useMemo(() => validateSignupToken?.isOwner, [
    validateSignupToken,
  ]);

  useEffect(() => {
    if (!sendSignupLinkCalled && validateError && token) {
      const email = jwtDecode<{ email: string }>(token as string).email;
      sendSignupLink({ variables: { email, origin: getOrigin() } }).catch(
        NOOP_graphqlErrorManagement
      );
    }
  }, [validateError, token, sendSignupLink, sendSignupLinkCalled]);

  const step1Formik = useFormik({
    initialValues: {
      name: '',
      password: '',
      companyName: '',
      jobTitle: '',
      typeOfProducts: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const { data } = await signup({
          variables: {
            user: {
              token: token as string,
              name: values.name,
              password: values.password,
              companyName: values.companyName,
            },
          },
        });
        const {
          signup: { accessToken, refreshToken },
        } = data as Auth_SignupMutation;

        localStorage.setItem('x-token', accessToken);
        localStorage.setItem('x-refresh-token', refreshToken);

        try {
          await updateMarketingData({
            variables: {
              marketingData: {
                jobTitle: values.jobTitle,
                typeOfProducts: values.typeOfProducts,
              },
            },
          });
        } finally {
          if (isOwner) {
            setStep(2);
          } else {
            push('/');
          }
        }
      } catch (_e) {
        if (hasInvalidField('token', signupError)) {
          // token expired
          refetch().catch(NOOP_graphqlErrorManagement);
        }
      } finally {
        setSubmitting(false);
      }
    },
  });
  const step1FormikRef = useRef(step1Formik);
  useUpdateRefIfShallowNew(step1FormikRef, step1Formik);

  const [signup, { error: signupError }] = useEnhancedMutation(
    Auth_SignupDocument,
    {
      formik: step1Formik,
      fetchPolicy: 'no-cache',
      error: {
        type: 'MODAL',
        message:
          'We were not able finish the signup process. Please check your input and try again.',
      },
    }
  );

  useEffect(() => {
    if (validateSignupToken) {
      step1FormikRef.current.resetForm({
        values: {
          ...step1FormikRef.current.initialValues,
          name: validateSignupToken.name ?? '',
          companyName: validateSignupToken.companyName ?? '',
        },
      });
    }
  }, [step1FormikRef, validateSignupToken]);

  const step2Formik = useFormik({
    initialValues: {
      companySize: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await updateMarketingData({
          variables: {
            marketingData: {
              companySize: values.companySize,
            },
          },
        });
      } finally {
        setSubmitting(false);
        setStep(3);
      }
    },
  });

  const step3Formik = useFormik({
    initialValues: {
      companyType: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await updateMarketingData({
          variables: {
            marketingData: {
              companyType: values.companyType,
            },
          },
        });
      } finally {
        setSubmitting(false);
        push('/');
      }
    },
  });

  if (loading)
    return (
      <LoginWrapper title="Checking your token...">
        <Typography variant="body2" gutterBottom>
          We're making sure the link is valid...
        </Typography>
      </LoginWrapper>
    );
  if (validateError)
    return (
      <LoginWrapper title="Link expired">
        <Typography variant="body2" gutterBottom>
          Looks like the link you clicked was expired. It was probably old.
        </Typography>
        {sendSignupLinkLoading && (
          <Typography variant="body1" gutterBottom>
            We're sending you a fresh one...
          </Typography>
        )}
        {sendSignupLinkData && (
          <Typography variant="body1" gutterBottom>
            We sent you a new one. Please{' '}
            <strong>check your email and follow the link</strong> you received.
          </Typography>
        )}
        {sendSignupLinkError && (
          <>
            <Typography variant="body1" gutterBottom>
              We were not able to send you a new link. Please try to
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={submit}
              onClick={() => push('/signup')}
            >
              Signup again
            </Button>
            <Typography variant="body2" gutterBottom>
              or
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={submit}
              onClick={() => push('/resetPassword')}
            >
              Reset your password
            </Button>
          </>
        )}
      </LoginWrapper>
    );

  if (step === 1) {
    const acceptToc = (
      <Box className={localClasses.toc}>
        By clicking "Continue" you accept our{' '}
        <Link
          href={`${process.env.REACT_APP_LANDING_PAGE}/terms-and-conditions`}
          target="_blank"
        >
          terms and conditions
        </Link>
        .
      </Box>
    );

    return (
      <Container
        className={localClasses.root}
        component="form"
        onSubmit={step1Formik.handleSubmit}
        maxWidth="md"
      >
        <Container maxWidth="xs">
          <Box
            marginTop={5}
            marginBottom={1.5}
            display="flex"
            justifyContent="center"
          >
            {isOwner && <BubbleStepIndicator step={1} of={3} />}
          </Box>
          <Box className={localClasses.header} marginBottom={4}>
            Personal Details
          </Box>
          <Box marginBottom={2}>
            <OptiTextField
              required
              label="Name"
              {...mapFormikToTextField(step1Formik, 'name')}
            />
          </Box>
          <Box marginBottom={2}>
            <input
              type="hidden"
              id="email"
              name="email"
              value={validateSignupToken?.email}
            />
            <OptiTextField
              required
              label="Password"
              type="password"
              {...mapFormikToTextField(step1Formik, 'password')}
              helperText="Must be at least 8 characters long, contain a lower and uppercase letter, a number, and a symbol."
            />
          </Box>
          <Box marginBottom={2}>
            <OptiTextField
              label="Company Name"
              disabled={!isOwner}
              {...mapFormikToTextField(step1Formik, 'companyName')}
            />
          </Box>
          <Box marginBottom={2}>
            <OptiTextField
              label="Job Title"
              {...mapFormikToTextField(step1Formik, 'jobTitle')}
            />
          </Box>
          <Box marginBottom={2}>
            <OptiTextField
              label="What kind of products do you sell?"
              {...mapFormikToTextField(step1Formik, 'typeOfProducts')}
            />
          </Box>
        </Container>
        <Box flex={1} />
        {isMobile && acceptToc}
        <Box paddingBottom={2} className={localClasses.nextButtonWrapper}>
          <LoadingAwareButton
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            loading={step1Formik.isSubmitting}
          >
            <Box display="flex" gridGap={8}>
              {isOwner ? 'Continue' : 'Start Quoting'}
              <ArrowForwardIcon />
            </Box>
          </LoadingAwareButton>
        </Box>
        {!isMobile && acceptToc}
      </Container>
    );
  }

  function onBoardingButton(
    current: string,
    value: string,
    icon: ReactNode,
    header: string,
    subHeader?: string
  ) {
    return {
      value,
      label: (
        <Box className={localClasses.onBoardingButton}>
          <Box
            className={clsx(
              localClasses.onBoardingButtonIcon,
              current === value && 'active'
            )}
          >
            {icon}
          </Box>
          <Box>{header}</Box>
          <Box
            className={clsx(
              localClasses.onBoardingButtonSubHeader,
              current === value && 'active'
            )}
          >
            {subHeader}
          </Box>
        </Box>
      ),
    };
  }

  if (step === 2) {
    return (
      <Container
        className={localClasses.root}
        component="form"
        onSubmit={step2Formik.handleSubmit}
        maxWidth="md"
      >
        <Box
          marginTop={5}
          marginBottom={1.5}
          display="flex"
          justifyContent="center"
        >
          <BubbleStepIndicator step={2} of={3} />
        </Box>
        <Box className={localClasses.header} marginBottom={1}>
          Tailor-made just for you!
        </Box>
        <Box
          className={localClasses.subHeader}
          marginBottom={6}
          maxWidth={310}
          alignSelf="center"
        >
          Tell us about yourself & your organization so we can customize your
          account.
        </Box>
        <Box className={localClasses.extraLabel} marginBottom={1}>
          Company Size
        </Box>
        <Box marginBottom={2}>
          <ButtonSwitchInput
            buttonWrapperClassName={localClasses.onBoardingButtonsWrapper}
            createButtonProps={() => ({
              variant: 'outlined',
            })}
            options={[
              onBoardingButton(
                step2Formik.values.companySize,
                '1',
                <CompanySize1Illustration />,
                "I'm on my own",
                '1 employee'
              ),
              onBoardingButton(
                step2Formik.values.companySize,
                '2-10',
                <CompanySize2Illustration />,
                'Small company',
                '2-10 employees'
              ),
              onBoardingButton(
                step2Formik.values.companySize,
                '11-50',
                <CompanySize3Illustration />,
                'Medium company',
                '11-50 employees'
              ),
              onBoardingButton(
                step2Formik.values.companySize,
                '50+',
                <CompanySize4Illustration />,
                'Enterprise',
                '50+ employees'
              ),
            ]}
            {...mapFormikToButtonSwitchField(step2Formik, 'companySize')}
          />
        </Box>

        <Box flex={1} />
        <Box paddingBottom={2} className={localClasses.nextButtonWrapper}>
          <LoadingAwareButton
            type="submit"
            color="primary"
            variant="contained"
            loading={step2Formik.isSubmitting}
            disabled={!step2Formik.dirty}
            fullWidth
          >
            <Box display="flex" gridGap={8}>
              Continue
              <ArrowForwardIcon />
            </Box>
          </LoadingAwareButton>
        </Box>
      </Container>
    );
  }

  return (
    <Container
      className={localClasses.root}
      component="form"
      onSubmit={step3Formik.handleSubmit}
      maxWidth="md"
    >
      <Box
        marginTop={5}
        marginBottom={1.5}
        display="flex"
        justifyContent="center"
      >
        <BubbleStepIndicator step={3} of={3} />
      </Box>
      <Box className={localClasses.header} marginBottom={1}>
        Tailor-made just for you!
      </Box>
      <Box
        className={localClasses.subHeader}
        marginBottom={6}
        maxWidth={310}
        alignSelf="center"
      >
        Tell us about yourself & your organization so we can customize your
        account.
      </Box>
      <Box className={localClasses.extraLabel} marginBottom={1}>
        Company Type
      </Box>
      <Box marginBottom={2}>
        <ButtonSwitchInput
          buttonWrapperClassName={localClasses.onBoardingButtonsWrapper}
          createButtonProps={() => ({
            variant: 'outlined',
          })}
          options={[
            onBoardingButton(
              step3Formik.values.companyType,
              'Manufacturer',
              <CompanyTypeManufacturerIllustration />,
              'Manufacturer',
              ' '
            ),
            onBoardingButton(
              step3Formik.values.companyType,
              'Distributor',
              <CompanyTypeDistributorIllustration />,
              'Distributor',
              ' '
            ),
            onBoardingButton(
              step3Formik.values.companyType,
              'Reseller',
              <CompanyTypeResellerIllustration />,
              'Reseller',
              ' '
            ),
            onBoardingButton(
              step3Formik.values.companyType,
              'Other',
              <CompanyTypeOtherIllustration />,
              'Other',
              ' '
            ),
          ]}
          {...mapFormikToButtonSwitchField(step3Formik, 'companyType')}
        />
      </Box>

      <Box flex={1} />
      <Box paddingBottom={2} className={localClasses.nextButtonWrapper}>
        <LoadingAwareButton
          type="submit"
          color="primary"
          variant="contained"
          loading={step3Formik.isSubmitting}
          disabled={!step3Formik.dirty}
          fullWidth
        >
          <Box display="flex" gridGap={8}>
            Start Quoting
            <ArrowForwardIcon />
          </Box>
        </LoadingAwareButton>
      </Box>
    </Container>
  );
};

export default Signup;
