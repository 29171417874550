import { useHasRole } from '@lib';
import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { UserRole } from '@optioffer/graphql';

import ProfileListPage from './ListPage';
import ProfileViewEditPage from './ProfileViewEditPage';

const ProfileModule: FC<RouteComponentProps> = ({ match }) => {
  const isSuperAdmin = useHasRole(UserRole.SUPER_ADMIN);
  if (!isSuperAdmin) return <Redirect to={'/'} />;

  return (
    <>
      <Route path={`${match.path}/:id`} component={ProfileViewEditPage} />
      <Route exact path={match.path} component={ProfileListPage} />
    </>
  );
};

export default ProfileModule;
