import { useIsMobile } from '@lib';
import * as React from 'react';
import { FC } from 'react';

import { OfferInOfferModuleFragment } from '@optioffer/graphql';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

type QuoteHeaderProps = {
  offer?: OfferInOfferModuleFragment;
  step: number;
  setStep: (step: number) => void;
};

const QuoteHeader: FC<QuoteHeaderProps> = (props) => {
  const isMobile = useIsMobile();

  return isMobile ? <MobileHeader {...props} /> : <DesktopHeader {...props} />;
};

export default QuoteHeader;
