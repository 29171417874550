import gql from 'graphql-tag';

const CLIENT_FIELDS = `
  id
  name
  email
  phoneNumber
  companyName
  companyVatId
  companyEmail
  companyPhoneNumber
  companyAddress
  country
  city
  zipCode
`;

export const SEARCH_CLIENTS = gql`
  query searchClients(
    $searchString: String
    $pagination: Pagination
    $country: String
  ) {
    searchClients(
      searchString: $searchString
      pagination: $pagination
      country: $country
    ) {
      total
      clients {
        ${CLIENT_FIELDS}
      }
    }
  }
`;

export const FIND_ALL_CLIENTS = gql`
  query findAllClients($pagination: Pagination) {
    findAllClients(pagination: $pagination) {
      total
      clients {
        ${CLIENT_FIELDS}
      }
    }
  }
`;

export const SIMULATE_IMPORT = gql`
  query simulateClientImport($file: Upload!) {
    simulateHeavyDuty {
      importClients(file: $file) {
        id
        status
        message
      }
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient($client: ClientInput!) {
    createClient(client: $client) {
      ${CLIENT_FIELDS}
    }
  }
`;

export const GET_CLIENT = gql`
  query getClient($id: ID!) {
    getClient(id: $id) {
      ${CLIENT_FIELDS}
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($id: ID!, $client: ClientInput!) {
    updateClient(id: $id, client: $client) {
      ${CLIENT_FIELDS}
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation deleteClient($id: ID!) {
    deleteClient(id: $id)
  }
`;

export const CLIENT_IMPORT = gql`
  mutation clientImport($file: Upload!) {
    heavyDuty {
      importClients(file: $file) {
        id
        status
        message
      }
    }
  }
`;
