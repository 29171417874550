import { NumberFormatInput, OptiInlineTextField, Separator } from '@components';
import { mapFormikToTextField, SecurityContext } from '@lib';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  makeStyles,
  Paper,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PanoramaIcon from '@material-ui/icons/Panorama';
import SearchIcon from '@material-ui/icons/Search';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import clsx from 'clsx';
import * as React from 'react';
import { FC, Fragment, useContext, useEffect, useRef } from 'react';

import { formatDiscount, formatPrice, ooBrand } from '@optioffer/core';
import {
  AddonPriceInOfferModuleFragment,
  CalculationType,
  OfferItem,
  TaxCalculated,
} from '@optioffer/graphql';

import ExpandableBox from '@components/ExpandableBox';

import { OfferContext } from '@containers/OfferContext';

import { OpenRowIcon } from '@resources/icons';

import ProductListItem from '../ProductListItem';
import {
  convertToOfferItem,
  getProductSearchResult,
  ProductSearchResult,
} from '../domain';
import useStyles from '../styles';
import OfferItemModalContext from './context';

const useLocalStyles = makeStyles((theme) => ({
  productImageContainer: {
    height: '160px',
    width: '160px',
    display: 'flex',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  productImage: {
    height: '160px',
  },
  placeholderIcon: {
    '& > svg': {
      fontSize: '160px',
      opacity: '30%',
    },
  },
  productName: {
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 'bold',
  },
  productCode: {
    fontSize: theme.typography.pxToRem(16),
    opacity: '50%',
  },
  productPrice: {
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  paperContainer: {
    marginBottom: theme.spacing(3),
    boxShadow: ooBrand.boxShadow.primary,
  },
}));

type OfferItemModalDesktopProps = {};

const OfferItemModalDesktop: FC<OfferItemModalDesktopProps> = () => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const offer = useContext(OfferContext);
  const { company } = useContext(SecurityContext);
  const {
    control,
    getProduct,
    openEditProduct,
    isAccessoryModal,
    accessoryModal,
    accessorySearchModal,

    formik,
    pricing,
    offerItem,
    offerItemInQuote,

    getAddonPricing,
    getOfferItem,
    getDirtyOfferItem,

    handleEditAddon,
    handleEditDiscount,
    updateAccessory,

    handleRemoveOfferItem,

    setShouldCalculatePricing,
    setShouldLoadAccessories,

    pricingQuery,
    recommendedAccessoriesQuery,
  } = useContext(OfferItemModalContext);
  const offerItemRef = useRef(offerItem);

  const addonsPricing: AddonPriceInOfferModuleFragment[] =
    formik.values.addons
      .map(getAddonPricing)
      .filter(Boolean)
      .map((pricing) => pricing!) ?? [];

  useEffect(() => {
    setShouldLoadAccessories(true);
    setShouldCalculatePricing(!offerItemRef.current?.pricing);
  }, [setShouldLoadAccessories, setShouldCalculatePricing, offerItemRef]);

  function renderDescription() {
    if (!control.data) return;

    return (
      <Box padding={3}>
        <Box display="flex" alignItems="center" width="100%" marginBottom={2}>
          <Typography className={classes.sectionHeading}>
            Description
          </Typography>
        </Box>
        <ExpandableBox
          flex={1}
          whiteSpace="pre-line"
          defaultState={!formik.values.description}
        >
          {formik.values.description || <pre>(no description)</pre>}
        </ExpandableBox>
      </Box>
    );
  }

  function renderSpecifications() {
    if (!control.data) return;

    return (
      <Box padding={3}>
        <Box marginBottom={2}>
          <Typography className={classes.sectionHeading}>
            Specifications
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" marginX={-1}>
          {!formik.values.specifications.length && (
            <Box marginY={1} marginX={2}>
              <pre>(no specifications)</pre>
            </Box>
          )}
          {formik.values.specifications.map((spec, idx, self) => (
            <Fragment key={spec.id}>
              <Box
                className={clsx(
                  classes.simpleRowDesktop,
                  classes.simpleRowWithLabelDesktop
                )}
                marginBottom={1}
              >
                <Box flex={1}>{spec.name}:</Box>
                <Box flex={1}>
                  {spec.value}
                  {spec.unit ? ` (${spec.unit})` : ''}
                </Box>
              </Box>
              {idx < self.length - 1 && (
                <Box marginBottom={1}>
                  <Divider className={classes.divider} />
                </Box>
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
    );
  }

  function renderAddons() {
    if (!pricing) return;
    return (
      <>
        {formik.values.addons.map((addon) => {
          const calculatedPricing = getAddonPricing(addon);

          return (
            <Fragment key={addon.id}>
              <Box
                className={clsx(
                  classes.actionableRowDesktop,
                  classes.simpleRowWithLabelDesktop
                )}
                marginBottom={1}
                onClick={() => handleEditAddon(addon)}
              >
                <Box flex={2}>{addon.name}</Box>

                <Box>{pricing.offerItemCurrency.symbol}</Box>
                <Box flex={1} textAlign="right">
                  {formatPrice(addon.price, pricing.offerItemCurrency, true)}
                </Box>
                <OpenRowIcon />
              </Box>
              {!!calculatedPricing?.discount?.valueTotal && (
                <Box
                  className={clsx(
                    classes.simpleRow,
                    classes.simpleRowWithLabelDesktop
                  )}
                  marginBottom={1}
                  onClick={() => handleEditAddon(addon)}
                >
                  <Box flex={2}>
                    Disc.{' '}
                    {calculatedPricing.discount.discount.calculationType ===
                    CalculationType.PERCENTAGE
                      ? formatDiscount(
                          calculatedPricing.discount.discount.value
                        )
                      : ''}{' '}
                    {addon.name}
                  </Box>

                  <Box>{pricing.offerItemCurrency.symbol}</Box>
                  <Box flex={1} textAlign="right">
                    {formatPrice(
                      -1 * calculatedPricing.discount.valueTotal,
                      pricing.offerItemCurrency,
                      true
                    )}
                  </Box>
                </Box>
              )}
            </Fragment>
          );
        })}
        <Box paddingX={2}>
          <Button
            className={classes.flatInlineButton}
            onClick={() => handleEditAddon(undefined)}
          >
            <StarBorderIcon /> Add{' '}
            {formik.values.addons.length ? 'another' : 'an'} extra service to
            product?
          </Button>
        </Box>
      </>
    );
  }

  function renderTax(tax: TaxCalculated) {
    const taxValues =
      tax.valueTotal +
      addonsPricing
        .flatMap((addonPricing) => [
          ...addonPricing.taxesBeforeDiscount,
          ...addonPricing.taxesAfterDiscount,
          ...addonPricing.taxesFinal,
        ])
        .filter((addonTax) => addonTax.tax.id === tax.tax.id)
        .reduce((acc, tax) => acc + tax.valueTotal, 0);

    return (
      pricing && (
        <Box
          key={tax.tax.id}
          className={clsx(classes.simpleRow, classes.simpleRowWithLabelDesktop)}
          marginBottom={1}
        >
          <Box flex={2}>
            {tax.tax.name}{' '}
            {tax.tax.calculationType === CalculationType.PERCENTAGE
              ? formatDiscount(tax.tax.value)
              : ''}
          </Box>

          <Box>{pricing.offerItemCurrency.symbol}</Box>
          <Box flex={1} textAlign="right">
            {formatPrice(taxValues, pricing.offerItemCurrency, true)}
          </Box>
        </Box>
      )
    );
  }

  function renderPricing() {
    return (
      <Box padding={3}>
        <Box marginBottom={2}>
          <Typography className={classes.sectionHeading}>Pricing</Typography>
        </Box>

        <Box display="flex" flexDirection="column" marginX={-2}>
          {!pricing && pricingQuery.loading && (
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              paddingY={2}
            >
              <CircularProgress />
            </Box>
          )}

          {pricing && (
            <>
              <Box
                className={clsx(
                  classes.simpleRow,
                  classes.simpleRowWithLabelDesktop
                )}
                marginBottom={1}
              >
                <Box flex={2}>List Price Unit</Box>

                <Box>{pricing.offerItemCurrency.symbol}</Box>
                <Box flex={1} textAlign="right">
                  {formatPrice(
                    formik.values.listPrice,
                    pricing.offerItemCurrency,
                    true
                  )}
                </Box>
              </Box>

              {offer && (
                <>
                  <Box
                    className={clsx(
                      classes.simpleRow,
                      classes.simpleRowWithLabelDesktop
                    )}
                    marginBottom={1}
                  >
                    <Box flex={2}>Quantity</Box>

                    <Box flex={1} textAlign="right">
                      <OptiInlineTextField
                        required
                        alignTextRight
                        {...mapFormikToTextField(formik, 'quantity')}
                        InputProps={{
                          inputComponent: NumberFormatInput as any,
                          inputProps: {
                            min: 0,
                            prefix: 'x',
                            onFocus: (e) => {
                              requestAnimationFrame(() => {
                                e.target.select();
                              });
                            },
                          },
                        }}
                        onBlur={() => setShouldCalculatePricing(true)}
                      />
                    </Box>
                  </Box>

                  <Box marginY={1.5}>
                    <Divider className={classes.divider} />
                  </Box>

                  {renderAddons()}
                </>
              )}

              <Box marginY={1.5}>
                <Divider className={classes.divider} />
              </Box>

              {pricing.taxesBeforeDiscount.map(renderTax)}

              {!!pricing.discount?.valueTotal && (
                <Box
                  className={clsx(
                    classes.actionableRowDesktop,
                    classes.simpleRowWithLabelDesktop
                  )}
                  marginBottom={1}
                  onClick={handleEditDiscount}
                >
                  <Box flex={2}>
                    Disc.{' '}
                    {pricing.discount.discount.calculationType ===
                    CalculationType.PERCENTAGE
                      ? formatDiscount(pricing.discount.discount.value)
                      : ''}
                  </Box>

                  <Box>{pricing.offerItemCurrency.symbol}</Box>
                  <Box flex={1} textAlign="right">
                    {formatPrice(
                      -1 * pricing.discount.valueTotal,
                      pricing.offerItemCurrency,
                      true
                    )}
                  </Box>
                  <OpenRowIcon />
                </Box>
              )}

              {!!pricing.discountFinal?.valueTotal && (
                <Box
                  className={clsx(
                    classes.simpleRow,
                    classes.simpleRowWithLabelDesktop
                  )}
                  marginBottom={1}
                >
                  <Box flex={2}>
                    Additional Disc.{' '}
                    {pricing.discountFinal.discount.calculationType ===
                    CalculationType.PERCENTAGE
                      ? formatDiscount(pricing.discountFinal.discount.value)
                      : ''}
                  </Box>

                  <Box>{pricing.offerItemCurrency.symbol}</Box>
                  <Box flex={1} textAlign="right">
                    {formatPrice(
                      -1 * pricing.discountFinal.valueTotal,
                      pricing.offerItemCurrency,
                      true
                    )}
                  </Box>
                </Box>
              )}

              {pricing.taxesAfterDiscount.map(renderTax)}
              {pricing.taxesFinal.map(renderTax)}

              <Box marginY={1.5}>
                <Separator variant="dashed" disableSpacing />
              </Box>

              <Box
                className={clsx(
                  classes.simpleRow,
                  classes.simpleRowWithLabelDesktop
                )}
                fontWeight="bold"
                marginBottom={1}
              >
                <Box flex={2}>Product Total</Box>

                <Box>{pricing.offerItemCurrency.symbol}</Box>
                <Box flex={1} textAlign="right">
                  {formatPrice(
                    pricing.netPriceTotal +
                      addonsPricing.reduce((acc, it) => acc + it.netPrice, 0),
                    pricing.offerItemCurrency,
                    true
                  )}
                </Box>
              </Box>

              {offer && !pricing.discount?.valueTotal && (
                <Box paddingX={2}>
                  <Button
                    className={classes.flatInlineButton}
                    onClick={handleEditDiscount}
                  >
                    <LocalOfferIcon /> Add Discount?
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  }

  function renderAccessories() {
    if (isAccessoryModal) return;

    return (
      <Box padding={3}>
        <Box marginBottom={2}>
          <Typography className={classes.sectionHeading}>
            Accessories
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" width="100%">
          {recommendedAccessoriesQuery.loading && (
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              paddingY={2}
            >
              <CircularProgress />
            </Box>
          )}
          {recommendedAccessoriesQuery.data &&
            !recommendedAccessoriesQuery.data.getRecommendedAccessories
              .length && (
              <Box padding={2}>
                <pre>No recommended accessories found.</pre>
              </Box>
            )}

          {[
            ...(recommendedAccessoriesQuery.data?.getRecommendedAccessories
              .map((item) => ({
                product: item.product,
                version: item.productVersion,
              }))
              .map((item) => ({
                ...item,
                offerItem: getOfferItem(item),
              })) ?? []),
            ...formik.values.children
              .filter(
                (child) =>
                  !recommendedAccessoriesQuery.data?.getRecommendedAccessories?.some(
                    (accessory) =>
                      accessory.productVersion.id ===
                      child.product?.productVersionId
                  )
              )
              .map((accessory) => {
                const productSearchResult = getProductSearchResult(accessory);
                return {
                  product: productSearchResult?.product,
                  version: productSearchResult?.version,
                  offerItem: accessory,
                };
              })
              .filter(
                (it): it is ProductSearchResult & { offerItem: OfferItem } =>
                  !!(it.product && it.version)
              ),
          ].map((item, index, self) => (
            <ProductListItem
              key={item.version.id}
              item={item}
              offerItem={item.offerItem}
              handleIncreaseQuantity={async () =>
                updateAccessory(
                  item.offerItem
                    ? {
                        ...item.offerItem,
                        quantity: item.offerItem.quantity + 1,
                      }
                    : await convertToOfferItem(getProduct, item)
                )
              }
              handleUpdateQuantity={updateAccessory}
              onClick={async (it) => accessoryModal.open(it)}
              actionIsCheckbox={false}
              hideAction={false}
            >
              {index < self.length - 1 && (
                <Box>
                  <Divider className={classes.divider} />
                </Box>
              )}
            </ProductListItem>
          ))}

          <Box>
            <Button
              className={classes.flatInlineButton}
              onClick={() => {
                const dirtyOfferItem = getDirtyOfferItem();
                if (!dirtyOfferItem) return;

                accessorySearchModal.open({
                  productName: dirtyOfferItem.product?.name ?? '',
                  productVersionCode: dirtyOfferItem.product?.code ?? '',
                  offerItemAccessories: dirtyOfferItem.children ?? [],
                });
              }}
            >
              <SearchIcon /> Search other Accessories
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  function renderButton() {
    return (
      <Box display="flex" gridGap={18} whiteSpace="nowrap">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => control.data && openEditProduct(control.data)}
        >
          <Box display="flex" gridGap={10} alignItems="center">
            <EditIcon fontSize="small" />
            {isAccessoryModal ? 'Edit Accessory' : 'Edit Product'}
          </Box>
        </Button>
        {offer && !offerItemInQuote ? (
          <Button variant="contained" color="primary" type="submit">
            <Box display="flex" marginRight={1}>
              <AddIcon />
            </Box>
            {isAccessoryModal ? 'Add Accessory' : 'Add to Quote'}
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formik.dirty}
            >
              {!formik.dirty
                ? 'Changes Saved'
                : formik.values.quantity
                ? 'Save Changes'
                : isAccessoryModal
                ? 'Remove Accessory'
                : 'Remove from Quote'}
            </Button>
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={handleRemoveOfferItem}
            >
              <Box display="flex" gridGap={10} alignItems="center">
                <DeleteIcon fontSize="small" />
                {isAccessoryModal ? 'Remove Accessory' : 'Remove from Quote'}
              </Box>
            </Button>
          </>
        )}
      </Box>
    );
  }

  if (!control.data) return null;

  return (
    <Box display="flex" flexDirection="column" padding={3} paddingTop={0}>
      {/*header*/}
      <Box width="100%" display="flex">
        {/*image */}
        <Paper
          className={localClasses.productImageContainer}
          style={{
            backgroundImage: offerItem?.product?.image?.thumbnail
              ? `url("${offerItem.product.image.thumbnail}")`
              : undefined,
          }}
        >
          {!offerItem?.product?.image?.thumbnail && (
            <Box className={localClasses.placeholderIcon}>
              <PanoramaIcon />
            </Box>
          )}
        </Paper>

        {/*title and button*/}
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          marginLeft={3}
          paddingY={1}
        >
          <Box className={clsx(localClasses.productName)}>
            {formik.values.name}
          </Box>

          <Box className={clsx(localClasses.productCode)}>
            {formik.values.code}
          </Box>

          <Box flex={1} />

          {/*price and button*/}
          <Box display="flex" marginTop={2} justifyContent="space-between">
            <Box className={localClasses.productPrice}>
              {formatPrice(
                control.data.version.price,
                offer?.currency ?? company.currency
              )}
            </Box>
            {renderButton()}
          </Box>
        </Box>
      </Box>
      <Box display="flex" marginTop={3}>
        {/*description, spec & accessories*/}
        <Box flex={4} display="flex" flexDirection="column" marginRight={3}>
          <Paper className={localClasses.paperContainer}>
            {renderAccessories()}
          </Paper>
          <Paper className={localClasses.paperContainer}>
            {renderDescription()}
          </Paper>
          <Paper className={localClasses.paperContainer}>
            {renderSpecifications()}
          </Paper>
        </Box>
        {/*pricing*/}
        <Box flex={3} display="flex" flexDirection="column">
          <Paper className={localClasses.paperContainer}>
            {renderPricing()}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default OfferItemModalDesktop;
