import { DVKForm, DVKObject } from '@dvkiin/material-commons';
import {
  getInvalidFields,
  getOrigin,
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
} from '@lib';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { Auth_SendResetPasswordLinkDocument } from '@optioffer/graphql';

import useStyles from '../styles';
import LoginWrapper from '../wrapper';

type RequestResetPasswordPageProps = {};

const RequestResetPasswordPage: FC<RequestResetPasswordPageProps> = () => {
  const { footer, form, submit } = useStyles();

  const [sendResetPasswordLink, { data, error, loading }] = useEnhancedMutation(
    Auth_SendResetPasswordLinkDocument,
    {
      error: {
        type: 'MODAL',
        message:
          'We were not able to send you the reset password email. Please check your input and try again.',
      },
      success: {
        message: 'Email sent.',
      },
    }
  );

  const invalidFields = useMemo(() => getInvalidFields(error), [error]);
  const [emailSentTo, setEmailSentTo] = useState<string | null>(null);

  useEffect(
    () => setEmailSentTo((data && data.sendResetPasswordLink.email) || null),
    [data]
  );

  async function handleRequestResetPassword({ email }: DVKObject) {
    try {
      await sendResetPasswordLink({
        variables: { email: email as string, origin: getOrigin() },
      });
    } catch (_e) {
      NOOP_graphqlErrorManagement();
    }
  }

  function renderSuccess() {
    return (
      <>
        <Typography variant="body1" gutterBottom>
          We've sent a email to <strong>{emailSentTo}</strong>. Follow the
          instructions in the email to reset your password.
        </Typography>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          className={submit}
          onClick={() => handleRequestResetPassword({ email: emailSentTo! })}
        >
          Resend email
        </Button>
      </>
    );
  }

  function renderRequestResetPasswordForm() {
    return (
      <>
        <Typography variant="body2" gutterBottom>
          Please enter your email address and we'll send you a password reset
          link.
        </Typography>

        <DVKForm
          className={form}
          fields={[
            {
              name: 'email',
              label: 'Email Address',
              type: 'email',
              required: true,
              autoFocus: true,
              errorMessage: {
                notFound:
                  'There is no account with this email. You can create one from the signup page.',
              },
            },
          ]}
          invalidFields={invalidFields}
          onSubmit={handleRequestResetPassword}
          bottomContent={
            <>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                className={submit}
              >
                Reset password
              </Button>
            </>
          }
        />
      </>
    );
  }

  return (
    <LoginWrapper title="Reset your password" backLink>
      {emailSentTo ? renderSuccess() : renderRequestResetPasswordForm()}

      <Typography variant="caption" gutterBottom className={footer}>
        If you already did this, there should be an email waiting for you in
        your inbox. If not, you could try again or contact{' '}
        <a href="mailto:support@optioffer.com">support@optioffer.com</a>. We'll
        be more than happy to assist you!
      </Typography>
    </LoginWrapper>
  );
};

export default RequestResetPasswordPage;
