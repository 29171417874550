import {
  FormHelperText,
  makeStyles,
  MenuItem,
  SelectProps,
  withStyles,
} from '@material-ui/core';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';
import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactNode } from 'react';

const InputLabel = withStyles((theme) => ({
  root: {
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0, -17px)',
      fontSize: theme.typography.pxToRem(13),
    },
  },
}))(MuiInputLabel);

const Select = withStyles((theme) => ({
  root: {},
}))(MuiSelect);

const FormControl = withStyles((theme) => ({
  root: {
    marginTop: '20px',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}))(MuiFormControl);

const useLocalStyles = makeStyles(() => ({
  rootInline: {
    marginTop: 0,
  },
  selectInline: {
    padding: '4px 32px 4px 8px',
  },
}));

export type SelectInputProps = SelectProps & {
  label?: string;
  options: { value: any; label: ReactNode }[];

  size?: 'inline';

  error?: boolean;
  helperText?: React.ReactNode;
};

const SelectInput: FC<SelectInputProps> = ({
  label,
  options,

  size,

  error,
  helperText,
  ...rest
}) => {
  const localClasses = useLocalStyles();

  return (
    <FormControl
      variant="outlined"
      size="small"
      className={clsx(size === 'inline' && localClasses.rootInline)}
      fullWidth
      error={error}
    >
      {label && <InputLabel shrink={true}>{label}</InputLabel>}

      <Select
        fullWidth
        classes={{
          ...(rest.classes ?? {}),
          root: clsx(
            rest.classes?.root,
            size === 'inline' && localClasses.selectInline
          ),
        }}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectInput;
