import { ProfileType } from '@optioffer/graphql';

import { capitalizeFirstLetter } from '@lib/index';

const PRO_CATEGORIES = [
  'Ospatar',
  'Sef de sala',
  'Bucatar',
  'Barman',
  'Spalator vase',
  'Picollo',
  'Barista',
  'Cofetar',
  'Curatenie',
  'Somelier',
  'Mentenanta & reparatii',
  'Concierge',
  'Front Office',
  'Securitate',
  'Bellboy',
  'Pizzer',
  'Gelatier',
  'Manager',
  'Marketing',
  'General manager',
  'Vanzari',
  'Design',
  'Constructor',
  'Utilaje & echipamente',
  'Solutii digitale',
  'Finantare',
  'Mobilier',
  'Fransizare',
  'Consultant',
  'Event planner',
  'Hostess',
].sort();

export const PROFILE_CATEGORIES: { [key: string]: string[] } = {
  [ProfileType.EMPLOYEE]: [
    'Ospatar',
    'Sef de sala',
    'Bucatar',
    'Ajutor bucatar',
    'Barman',
    'Ajutor barman',
    'Spalator vase',
    'Picollo',
    'Barista',
    'Cofetar',
    'Curatenie',
    'Somelier',
    'Mentenanta & reparatii',
    'Concierge',
    'Front Office',
    'Securitate',
    'Bellboy',
    'Pizzer',
    'Gelatier',
  ].sort(),
  [ProfileType.PROFESSIONAL]: PRO_CATEGORIES,
  [ProfileType.PROFESSIONAL_AND_EMPLOYEE]: PRO_CATEGORIES,
  [ProfileType.SUPPLIER]: [
    'Ambalaje & consumabile',
    'Cafea',
    'Alimente',
    'Producator alimente',
    'Afaceri la cheie',
    'Mobilier',
    'Instalatii sanitare',
    'Producator Ready To Eat',
    'Bauturi racoritoare',
    'Bauturi alcoolice',
    'Producator bauturi',
    'Tehnologie',
    'Solutii fiscale',
    'Utilaje & echipamente',
    'Gelaterie',
    'Produse curatenie',
    'Spalare rufe',
    'Piese de schimb',
    'HVAC',
    'Materiale constructii',
    'Materiale electrice',
    'Patiserie',
    'Producator local',
    'Productie publicitara',
    'Ingrediente de specialitate',
    'Finantare',
  ].sort(),
};

export const DEFAULT_SERVICES = Array.from(
  new Set(
    [
      'Consultanta',
      'Consultat',
      'Proiectare',
      'Solutii fiscale',
      'amenajare',
      'case de marcat',
      'comercializare',
      'constructie',
      'consultanta',
      'cost control',
      'creare de meniu',
      'creare meniu',
      'deschidere locatii',
      'design',
      'design interior',
      'dezvoltare meniu',
      'distrbutie',
      'distributie',
      'evenimente',
      'finantare',
      'fotografii',
      'gestionare furnizori',
      'implementare HACCP',
      'inchiriere',
      'instalare',
      'leasing',
      'livrare',
      'management',
      'meniu bar',
      'mentenanta',
      'organizare bucatarie',
      'personal',
      'personalizare',
      'pizza napoletana',
      'prajire',
      'prezentare',
      'proiectare',
      'promovare',
      'reconditionare',
      'releveu',
      'remodelare',
      'reparatii',
      'restuarare',
      'retapitare',
      'simulare',
      'sonorizare',
      'tipologii aluat',
      'training',
      'vendori',
      'vizualizari 3D',
      'comercializare',
      'consultanta',
      'finantare',
      'instalare',
      'mentenanta',
      'proiectare',
    ].map((it) => capitalizeFirstLetter(it))
  )
).sort();
