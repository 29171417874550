import React, { useContext } from 'react';
import { OfferPrintContext } from '../../../helpers';
import { getDetailedOfferItems } from '../helpers';
import OfferItemPage from './OfferItem';
var OfferItems = function () {
    var offerItems = useContext(OfferPrintContext).offer.offerItems;
    var detailedOfferItems = getDetailedOfferItems(offerItems);
    return (React.createElement(React.Fragment, null, detailedOfferItems.map(function (oi) { return (React.createElement(OfferItemPage, { key: oi.id, offerItem: oi })); })));
};
export default OfferItems;
