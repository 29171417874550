import { Box, makeStyles, SliderProps, withStyles } from '@material-ui/core';
import MuiSlider from '@material-ui/core/Slider';
import * as React from 'react';
import { FC, useMemo } from 'react';

import { ooBrand } from '@optioffer/core';
import { HistogramEntry } from '@optioffer/graphql';

const Slider = withStyles(() => ({
  root: {
    paddingTop: 0,
  },
}))(MuiSlider);

const useLocalStyles = makeStyles(() => ({
  histogramChartBar: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: ooBrand.colors.primary['100'],
    opacity: '30%',
  },
}));

export type RangeInputProps = SliderProps & {
  histogram: HistogramEntry[];
  numberOfColumns?: number;
  min?: number;
  max?: number;
};

const RangeInput: FC<RangeInputProps> = ({
  histogram,
  numberOfColumns = 25,
  min: minOverride,
  max: maxOverride,
  ...rest
}) => {
  const localClasses = useLocalStyles();
  const min = useMemo(
    () =>
      minOverride ??
      Math.floor(
        histogram.reduce((acc, it) => Math.min(acc, it.min), Infinity)
      ),
    [histogram, minOverride]
  );
  const max = useMemo(
    () =>
      maxOverride ??
      Math.ceil(
        histogram.reduce((acc, it) => Math.max(acc, it.max), -Infinity)
      ),
    [histogram, maxOverride]
  );

  /**
   * this is for the visual representation only
   * it is not exact, but accurate enough
   * we do this because we need an evenly distributed histogram
   */
  const remappedHistogram = useMemo(() => {
    if (!isFinite(min) || !isFinite(max)) return [];

    const newHistogram = new Array(numberOfColumns)
      .fill(null)
      .map((_, index) => ({
        min: ((max - min) / numberOfColumns) * index + min,
        max: ((max - min) / numberOfColumns) * (index + 1) + min,
        count: 0,
      }));

    histogram.forEach((entry) => {
      const newEntriesThatFit = newHistogram.filter(
        (newEntry) => entry.min < newEntry.max && entry.max > newEntry.min
      );
      newEntriesThatFit.forEach((newEntry) => {
        newEntry.count += entry.count / newEntriesThatFit.length;
      });
    });

    return newHistogram;
  }, [histogram, min, max, numberOfColumns]);
  const maxCount = useMemo(
    () => Math.max(...remappedHistogram.map((it) => it.count)),
    [remappedHistogram]
  );
  const space = 10 / remappedHistogram.length; // 10% of the width

  return (
    <Box>
      <Box width="100%" height="75px" position="relative" marginBottom={0}>
        {remappedHistogram.map((col, index, { length }) => {
          const left = (100 * (col.min - min)) / (max - min);
          const right = 100 - (100 * (col.max - min)) / (max - min);

          return (
            <Box
              className={localClasses.histogramChartBar}
              key={`${col.min}-${col.max}`}
              style={{
                height: `${Math.floor((col.count / maxCount) * 100)}%`,
                left: `${index ? left + space : left}%`,
                right: `${index === length - 1 ? right : right + space}%`,
              }}
            />
          );
        })}
      </Box>
      <Slider aria-labelledby="range-slider" {...rest} min={min} max={max} />
    </Box>
  );
};

export default RangeInput;
