import {
  DVKObject,
  ErrorModal,
  FlexExpander,
  InputModal,
  SuccessSnackbar,
  useIncrementalKey,
  useModal,
} from '@dvkiin/material-commons';
import {
  getInvalidFields,
  goBackIfCurrentPageEmpty,
  useEnhancedLazyQuery,
  useEnhancedMutation,
  useEnhancedQuery,
  usePaginationFromUrl,
} from '@lib';
import { Box, InputAdornment, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useThrottledMemo } from '@optioffer/core';

import { ApolloModalErrors, ApolloSnackbars } from '@components/Feedback';
import { HeavyDutyResult } from '@components/Feedback/domain';
import SupplierCustomerTable from '@components/Table/SupplierCustomerTable';

import CountrySelector from '../../components/form/CountrySelector';
import { Country } from '../Supplier/domain';
import ClientImportSection from './ClientImportSection';
import { Client, ClientPage } from './domain';
import defaultFields from './fields';
import {
  CLIENT_IMPORT,
  CREATE_CLIENT,
  FIND_ALL_CLIENTS,
  SEARCH_CLIENTS,
  SIMULATE_IMPORT,
} from './graphql';
import useStyles from './styles';

const ClientListPage: FC<RouteComponentProps> = ({ history }) => {
  const { addButton, toolbar, searchBar, searchInput, icon } = useStyles();

  const {
    isOpen: isCreateModalOpen,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal();
  const {
    pagination,
    sort,
    graphQLPagination,
    handlePaginationUpdate,
  } = usePaginationFromUrl();
  const [createKey, incrementCreateKey] = useIncrementalKey();
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [
    inputSelectedCountry,
    setInputSelectedCountry,
  ] = useState<Country | null>(null);
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useThrottledMemo(
    () => searchString,
    [searchString],
    1000
  );

  //region queries
  const {
    data: { findAllClients } = { findAllClients: undefined },
    error: getError,
  } = useEnhancedQuery<{ findAllClients?: ClientPage }>(FIND_ALL_CLIENTS, {
    variables: { pagination: graphQLPagination },
  });

  const {
    data: { searchClients } = { searchClients: undefined },
    error: searchClientsError,
  } = useEnhancedQuery<{ searchClients?: ClientPage }>(SEARCH_CLIENTS, {
    variables: {
      searchString: debouncedSearchString,
      pagination: graphQLPagination,
      country: selectedCountry?.isoCode,
    },
  });
  const [
    simulateImport,
    {
      data: { simulateHeavyDuty: simulateImportResult } = {
        simulateHeavyDuty: undefined,
      },
      error: simulateImportError,
    },
  ] = useEnhancedLazyQuery<{
    simulateHeavyDuty: { importClients: HeavyDutyResult[] };
  }>(SIMULATE_IMPORT, { fetchPolicy: 'network-only' });
  //endregion

  //region mutations
  const [
    importClients,
    { data: importResult, error: importError },
  ] = useEnhancedMutation<{ heavyDuty: { importClients: HeavyDutyResult[] } }>(
    CLIENT_IMPORT,
    { refetchQueries: ['findAllClients'] }
  );
  const [
    createClient,
    { data: createResult, error: createError },
  ] = useEnhancedMutation<{ createClient: Client }>(CREATE_CLIENT, {
    refetchQueries: ['findAllClients'],
  });
  // endregion

  //region errors
  const errors = useMemo(
    () => [
      {
        error: simulateImportError,
        message:
          'There was an error while analysing your import file. Please check that the file you selected is valid and try again.',
      },
      {
        error: importError,
        message:
          'There was an error while importing your clients. Please check that the file you selected is valid and try again.',
      },
    ],
    [simulateImportError, importError]
  );
  // endregion

  //region results
  const results = useMemo(
    () => [
      { result: simulateImportResult, message: 'Analysis complete.' },
      { result: importResult, message: 'Client import was successful.' },
    ],
    [simulateImportResult, importResult]
  );
  //endregion

  useEffect(
    () =>
      goBackIfCurrentPageEmpty(
        findAllClients,
        pagination,
        sort,
        handlePaginationUpdate
      ),
    [findAllClients, pagination, sort, handlePaginationUpdate]
  );

  function handleNavigateToClient({ id }: Client) {
    history.push(`/client/${id}`);
  }

  async function handleClientSave(client: DVKObject) {
    const clientToSave = {
      ...defaultFields.reduce(
        (acc, { name }) => ({ ...acc, [name]: client[name] }),
        {}
      ),
      country: inputSelectedCountry?.isoCode,
    };
    const { error } = (await createClient({
      variables: { client: clientToSave },
    })) as any; // WTF 😂
    if (!error) {
      incrementCreateKey();
    }
  }

  async function handleSimulateClientsImport(
    file: File
  ): Promise<HeavyDutyResult[]> {
    const { data, error } = await simulateImport({ variables: { file } });
    if (!data) throw error;
    return data.simulateHeavyDuty.importClients;
  }

  async function handleImportClients(file: File): Promise<HeavyDutyResult[]> {
    const { data, errors } = await importClients({ variables: { file } });
    if (errors) throw errors;
    return data!.heavyDuty.importClients;
  }

  const handlePageChange = (page: number) => {
    handlePaginationUpdate({ page, rowsPerPage: 10 }, undefined);
  };

  function renderTable() {
    if (getError || searchClientsError)
      return (
        <ErrorModal
          error={getError}
          message="There was an error while loading the clients. Please try again later. If the problem persists, please contact and administrator."
        />
      );
    if (!findAllClients) return 'Loading...';
    if (!findAllClients.total) return 'You have no clients yet. Add some ;)';
    return (
      <SupplierCustomerTable
        data={searchClients?.clients ?? findAllClients.clients}
        pageCount={Math.round(
          (searchClients ? searchClients.total : findAllClients.total) / 10
        )}
        page={pagination.page + 1}
        onPaginationUpdate={handlePageChange}
        onEdit={handleNavigateToClient}
      />
    );
  }

  return (
    <>
      <h2>Customers</h2>
      <div className={toolbar}>
        <div className={searchBar}>
          <TextField
            autoFocus
            placeholder="Search by contact person, Company, VAT Number"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={icon} />
                </InputAdornment>
              ),
              className: searchInput,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={searchString}
            onChange={({ target: { value } }) => {
              setSearchString(value);
              handlePageChange(0);
            }}
          />
        </div>
        <Box marginTop={'-20px'}>
          <CountrySelector onCountrySelected={setSelectedCountry} />
        </Box>
        <FlexExpander />
        <ClientImportSection
          simulateImport={handleSimulateClientsImport}
          importClients={handleImportClients}
        />
        <Button
          className={addButton}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openCreateModal}
        >
          Create Customer
        </Button>
      </div>

      {renderTable()}

      <InputModal
        title="Add new Client"
        formKey={`${createKey}`}
        open={isCreateModalOpen}
        fields={defaultFields}
        invalidFields={getInvalidFields(createError) || undefined} // TODO [material-commons] accept null
        onClose={closeCreateModal}
        onCreate={handleClientSave}
        bottomContent={
          <CountrySelector
            onCountrySelected={setInputSelectedCountry}
            selectorStyleOptions={{
              size: 'medium',
              showAdornment: false,
              inputVariant: 'standard',
              label: 'Country',
            }}
          />
        }
      />

      {createError && (
        <ErrorModal
          key={(createError as any).timestamp}
          error={createError}
          message="There was an error while creating the client. Please check your input and try again."
        />
      )}

      {createResult && (
        <SuccessSnackbar
          key={createResult.createClient.id}
          message={
            <span>Client '{createResult.createClient.name}' created.</span>
          }
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => handleNavigateToClient(createResult.createClient)}
            >
              View and edit
            </Button>
          }
        />
      )}
      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default ClientListPage;
