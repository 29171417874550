import {
  ProfileInput,
  ProfileOnProfilePageFragment,
  ProfileType,
} from '@optioffer/graphql';

export function mapToProfileInput(
  profile: ProfileOnProfilePageFragment
): ProfileInput {
  return {
    profileType: profile.profileType,
    profileStatus: profile.profileStatus,

    // personal info
    image: profile.image?.id,
    companyName: profile.companyName,
    contactPersonName: profile.contactPersonName,
    address: profile.address,

    // contact
    email: profile.email,
    phone: profile.phone,
    website: profile.website,
    fbLink: profile.fbLink,
    linLink: profile.linLink,
    instaLink: profile.instaLink,
    tikTokLink: profile.tikTokLink,
    continueWithoutSocialLinks: profile.continueWithoutSocialLinks,

    // services
    category: profile.category,
    description: profile.description,
    areaServed: profile.areaServed,
    services: profile.services,

    // experience
    experience: profile.experience.map(
      ({
        yearEnd,
        description,
        yearStart,
        companyName,
        location,
        jobTitle,
      }) => ({
        companyName,
        jobTitle,
        description: description || undefined,
        location: location || undefined,
        yearStart,
        yearEnd: yearEnd || undefined,
      })
    ),
    continueWithoutExperience: profile.continueWithoutExperience,

    // projects
    projectContributions: profile.projectContributions.map((c) => {
      return {
        id: c.id,
        description: c.description,
        services: c.services,
        project: {
          id: c.project.id,
          name: c.project.name,
          location: c.project.location,
          date: c.project.date,
          link: c.project.link,
          description: c.project.description,
          media: c.project.media.map((it) => it.image?.id),
        },
      };
    }),
    continueWithoutProjects: profile.continueWithoutProjects,
  };
}

export function isPersonalInfoComplete(
  profile: ProfileOnProfilePageFragment
): boolean {
  return !!(profile.name && profile.image && profile.address);
}

export function isContactInfoComplete(
  profile: ProfileOnProfilePageFragment
): boolean {
  return !!(
    profile.email &&
    profile.phone &&
    (profile.continueWithoutSocialLinks ||
      profile.website ||
      profile.fbLink ||
      profile.instaLink ||
      profile.linLink ||
      profile.tikTokLink)
  );
}

export function isServicesComplete(
  profile: ProfileOnProfilePageFragment
): boolean {
  return !!(
    profile.description &&
    profile.category &&
    profile.areaServed &&
    profile.services.length
  );
}

export function shouldHaveExperience(
  profile: ProfileOnProfilePageFragment
): boolean {
  return (
    profile.profileType === ProfileType.EMPLOYEE ||
    profile.profileType === ProfileType.PROFESSIONAL_AND_EMPLOYEE
  );
}

export function isExperienceComplete(
  profile: ProfileOnProfilePageFragment
): boolean {
  return !!(profile.experience.length || profile.continueWithoutExperience);
}

export function shouldHaveProjects(
  profile: ProfileOnProfilePageFragment
): boolean {
  return profile.profileType !== ProfileType.EMPLOYEE;
}
export function isProjectsComplete(
  profile: ProfileOnProfilePageFragment
): boolean {
  return !!(
    profile.projectContributions.length || profile.continueWithoutProjects
  );
}

export function isProfileComplete(
  profile: ProfileOnProfilePageFragment
): boolean {
  return (
    isPersonalInfoComplete(profile) &&
    isContactInfoComplete(profile) &&
    isServicesComplete(profile) &&
    (!shouldHaveExperience(profile) || isExperienceComplete(profile)) &&
    (!shouldHaveProjects(profile) || isProjectsComplete(profile))
  );
}
