import { useEnhancedQuery, useIsMobile, useModal } from '@lib';
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  makeStyles,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { ooBrand, useThrottledMemo } from '@optioffer/core';
import {
  ClientInOfferModuleFragment,
  NewOffer_SearchClientDocument,
  OfferClient,
} from '@optioffer/graphql';

import customerIllustration from '@resources/illustrations/customer_step.svg';

import {
  convertOfferClientToClient,
  getRealClientId,
  isOfferClientTemporary,
} from '../domain';
import useStyles from '../styles';
import ClientList, { ClientListItem } from './ClientList';
import ClientModal from './ClientModal';

const useClientStepStyles = makeStyles((theme) => ({
  customerFieldset: {
    marginTop: theme.spacing(2),
    marginBottom: '118px',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      overflowY: 'auto',
      overflowX: 'hidden',
      flex: 1,
      width: 'initial',
      maxHeight: '100%',
      margin: theme.spacing(2, 0, 0),
      padding: theme.spacing(0, 2, 12),
    },
  },
  clientModalIcon: {
    opacity: '30%',
    fontSize: '65px',
    color: theme.palette.primary.main,
  },
  listWrapper: {
    position: 'relative',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: ooBrand.colors.pureWhite,
      maxWidth: '50%',
      maxHeight: 'calc(100vh - 182px)',
      overflowY: 'hidden',
      margin: theme.spacing(4),
      padding: theme.spacing(5, 7),
    },
  },
  illustrationWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  illustration: {
    width: '80%',
  },
  currentCustomerDesktop: {
    '&.overrideParentComponent': {
      '&::before': {
        display: 'block',
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(1.5, 2),
      },
    },
  },
  searchIcon: {
    color: 'rgba(0 0 0 / 50%)',
  },
}));

type ClientStepProps = {
  currentCustomer?: OfferClient;
  setCurrentCustomer: (
    customer: ClientInOfferModuleFragment | undefined
  ) => void;
  setStep: (step: number) => void;
};

const ClientStep: FC<ClientStepProps> = ({
  currentCustomer,
  setCurrentCustomer,
  setStep,
}) => {
  const classes = useStyles();
  const localClasses = useClientStepStyles();
  const isMobile = useIsMobile();
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useThrottledMemo(
    () => searchString,
    [searchString],
    1000
  );
  const [currentCustomerInListRef, currentCustomerInView] = useInView();
  const [endOfListRef, endOfListInView] = useInView();
  const [numberOfClientsVisible, setNumberOfClientsVisible] = useState(50);

  const [temporaryClientsToPersist, setTemporaryClientsToPersist] = useState<
    OfferClient[]
  >([]);

  const { data: clients, called, loading } = useEnhancedQuery(
    NewOffer_SearchClientDocument,
    {
      variables: {
        filter: debouncedSearchString,
        topCount: numberOfClientsVisible,
      },
      error: {
        type: 'SNACKBAR',
        message: 'An error occurred while loading the clients.',
      },
    }
  );

  useEffect(() => {
    let cancelled = false;
    if (
      endOfListInView &&
      called &&
      !loading &&
      (clients?.findAllClientsByComplexFilter.length ?? 0) >=
        numberOfClientsVisible
    ) {
      setTimeout(() => {
        if (!cancelled) {
          setNumberOfClientsVisible((it) => it + 50);
        }
      }, 1000);
    }

    return () => {
      cancelled = true;
    };
  }, [
    endOfListInView,
    called,
    loading,
    clients,
    numberOfClientsVisible,
    setNumberOfClientsVisible,
  ]);

  const clientModal = useModal<ClientInOfferModuleFragment>();

  const clientList = useMemo(() => {
    const list: ClientInOfferModuleFragment[] = [
      ...(clients?.findAllClientsByComplexFilter ?? []),
    ];

    [
      ...(currentCustomer && isOfferClientTemporary(currentCustomer)
        ? [currentCustomer]
        : []),
      ...temporaryClientsToPersist,
    ].forEach((temporaryCustomer) => {
      const realId = getRealClientId(temporaryCustomer.clientId!); // isOfferClientTemporary verifies this is not null

      const rootCustomerPosition = list.findIndex((it) => it.id === realId);
      if (rootCustomerPosition > -1) {
        list.splice(
          rootCustomerPosition + 1,
          0,
          convertOfferClientToClient(temporaryCustomer)
        );
      }
    });

    return list;
  }, [clients, currentCustomer, temporaryClientsToPersist]);

  function persistTemporaryClients() {
    if (currentCustomer && isOfferClientTemporary(currentCustomer)) {
      setTemporaryClientsToPersist((old) => [...old, currentCustomer]);
    }
  }

  function renderSelectedCustomer() {
    return (
      currentCustomer &&
      !currentCustomerInView && (
        <RadioGroup
          aria-label="current-customer"
          name="current-customer"
          value={currentCustomer.clientId}
        >
          <Box marginX={-1.5}>
            <Divider />
          </Box>
          <ClientListItem
            client={convertOfferClientToClient(currentCustomer)}
            openClientDetails={clientModal.open}
          >
            <Box marginX={-1.5} marginBottom={1.5}>
              <Divider />
            </Box>
          </ClientListItem>
        </RadioGroup>
      )
    );
  }

  return (
    <Box className="ClientStep">
      <Box display="flex">
        <Box className={localClasses.illustrationWrapper}>
          <img
            className={localClasses.illustration}
            src={customerIllustration}
            alt="customer illustration"
          />
        </Box>

        <Box
          className={clsx(
            localClasses.listWrapper,
            !isMobile && classes.sectionPaper
          )}
        >
          <Box paddingX={0.5} marginY={3}>
            <Typography className={classes.header1} color="primary">
              Who do you want to send the quote to?
            </Typography>
          </Box>
          <Box display="flex" width="100%">
            <Box marginRight={1} flex={1}>
              <TextField
                fullWidth
                placeholder="Search customer"
                variant="outlined"
                margin="none"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={localClasses.searchIcon} />
                    </InputAdornment>
                  ),
                }}
                value={searchString}
                onChange={({ target: { value } }) => setSearchString(value)}
              />
            </Box>
            <Button
              className={clsx(isMobile && classes.iconButton)}
              variant="contained"
              color="primary"
              onClick={() =>
                clientModal.open(({} as unknown) as ClientInOfferModuleFragment)
              }
            >
              <AddIcon />
              {!isMobile && 'New Customer'}
            </Button>
          </Box>

          <FormControl
            component="fieldset"
            className={localClasses.customerFieldset}
          >
            <RadioGroup
              aria-label="customer"
              name="customer"
              value={currentCustomer?.clientId ?? ''}
              onChange={({ target }) => {
                persistTemporaryClients();
                setCurrentCustomer(
                  clients?.findAllClientsByComplexFilter.find(
                    (it) => it.id === target.value
                  ) ??
                    convertOfferClientToClient(
                      temporaryClientsToPersist.find(
                        (it) => it.clientId === target.value
                      )
                    )
                );
              }}
            >
              <ClientList
                clients={clientList}
                openClientDetails={clientModal.open}
                currentCustomerId={currentCustomer?.clientId ?? undefined}
                currentCustomerInListRef={currentCustomerInListRef}
              />
              <div ref={endOfListRef} />
            </RadioGroup>
          </FormControl>

          {!isMobile && (
            <>
              <Box
                className={clsx(
                  classes.floatingFooter,
                  localClasses.currentCustomerDesktop,
                  'overrideParentComponent'
                )}
              >
                {renderSelectedCustomer()}
              </Box>
              <Box display="flex">
                <Box flex="1" display="flex" flexDirection="row-reverse">
                  <Button
                    className="NavigationButton"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!currentCustomer}
                    onClick={() => setStep(2)}
                  >
                    Confirm Customer
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>

      {isMobile && (
        <>
          <Box className={classes.floatingFooter}>
            {renderSelectedCustomer()}
            <Box display="flex">
              <Box flex="1" display="flex" flexDirection="row-reverse">
                <Button
                  className="NavigationButton"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!currentCustomer}
                  onClick={() => setStep(2)}
                >
                  Confirm Customer
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}

      <ClientModal
        control={clientModal}
        setCurrentCustomer={(client) => {
          persistTemporaryClients();
          setCurrentCustomer(client);
        }}
      />
    </Box>
  );
};

export default ClientStep;
