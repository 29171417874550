import { ConfirmationModal, uuid } from '@dvkiin/material-commons';
import {
  useEnhancedMutation,
  useEnhancedProgrammaticQuery,
  useEnhancedQuery,
  useIsMobile,
  useModal,
  waitForAnimationFrame,
} from '@lib';
import { Box, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  Attribute,
  CalculationType,
  ProductInProductSectionModuleFragment,
  ProductSection_CloneProductDocument,
  ProductSection_DeleteProductDocument,
  ProductSection_DeleteProductVersionDocument,
  ProductSection_FindAllSuppliersDocument,
  ProductSection_GetProductDocument,
  ProductSection_SaveProductDocument,
  ProductSection_SearchProductsDocument,
  ProductSection_UpdateProductVersionDocument,
  ProductVersionInProductSectionModuleFragment,
  SupplierInProductSectionModuleFragment,
} from '@optioffer/graphql';

import { isImage, ProductMedia } from '@components/form/MediaInput';
import OOModal from '@components/modals/OOModal';

import NewAttributeModal, {
  NewAttributeModalData,
} from '@containers/ProductSection/NewAttributeModal';
import SearchAccessoryModal, {
  SearchAccessoryModalData,
} from '@containers/ProductSection/SearchAccessoryModal';

import { getQueryName } from '@lib/graphql';
import { ModalControl } from '@lib/materialCommons';
import { PRODUCT_CATEGORIES_MAP, ProductCategory } from '@lib/product';

import {
  extractSpecifications,
  ProductSearchResult,
  ProductSpecificationWithAttrId,
} from '../domain';
import ProductModalContext from './context';
import ProductModalDesktop from './desktop';
import ProductModalMobile from './mobile';

type ProductModalProps = {
  control: ModalControl<ProductSearchResult>;
  isAccessoryModal?: boolean;

  onProductSave?: (
    product: ProductInProductSectionModuleFragment,
    productVersion: ProductVersionInProductSectionModuleFragment
  ) => Promise<any>;
};

const initialValues = {
  name: '',
  code: '',
  category: '' as ProductCategory | string,
  supplier: null as SupplierInProductSectionModuleFragment | null,
  description: '',
  listPrice: 0,
  costType: CalculationType.PERCENTAGE,
  costValue: 0,
  specifications: [] as ProductSpecificationWithAttrId[],
  accessories: [] as ProductSearchResult[],
  media: [] as ProductMedia[],
};

const ProductModal: FC<ProductModalProps> = ({
  control,
  isAccessoryModal,
  onProductSave,
}) => {
  const isMobile = useIsMobile();
  const newAttributeModal = useModal<NewAttributeModalData>();
  const accessoryModal = useModal<ProductSearchResult>();
  const accessorySearchModal = useModal<SearchAccessoryModalData>();
  const deleteModal = useModal();
  const [persistProductInDB, setPersistProductInDB] = useState(true);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    onSubmit: async (values) => {
      const productInput = {
        name: values.name,
        code: values.code,
        supplierId: values.supplier?.id,
        description: values.description,
        category: ((values.category as ProductCategory)?.slug ??
          values.category) as string,
        price: values.listPrice,
        cost: {
          calculationType: values.costType,
          value: values.costValue ?? 0,
        },
        images: values.media
          .filter(isImage)
          .map((it) => it.imageFileForUpload || it.id),
        specifications: values.specifications.map((spec) => ({
          name: spec.name,
          unit: spec.unit,
          value: spec.value,
          type: spec.type,
        })),
        accessories: values.accessories.map((acc) => ({
          productId: acc.product.id,
          productVersionId: acc.version.id,
        })),
      };

      let savedProduct: ProductInProductSectionModuleFragment | undefined;
      if (persistProductInDB) {
        if (control.data?.product.id && control.data?.version.id) {
          const { data } = await updateProductVersion({
            variables: {
              id: control.data.product.id,
              productVersionId: control.data.version.id,
              product: productInput,
            },
          });
          savedProduct = data?.updateProductVersion;
        } else {
          const { data } = await saveNewProduct({
            variables: { product: productInput },
          });
          savedProduct = data?.createProduct;
        }
      } else {
        savedProduct = {
          id: control.data?.product.id!,
          name: productInput.name,
          description: productInput.description,
          category: productInput.category,
          // image: productInput.images[0], // TODO
          versions: [
            {
              id: control.data?.version.id!,
              code: productInput.code,
              price: productInput.price,
              cost: productInput.cost,
              supplier: availableSuppliers.find(
                (it) => it.id === productInput.supplierId
              ),
              attributeValues: productInput.specifications.map((spec) => ({
                id: uuid(),
                value: spec.value,
                attribute: {
                  id: uuid(),
                  type: spec.type,
                  name: spec.name,
                  unit: spec.unit,
                },
              })),
            },
          ],
        };
      }

      if (savedProduct && onProductSave) {
        const savedProductVersion =
          savedProduct.versions.length === 1
            ? savedProduct.versions[0]
            : savedProduct.versions.find(
                (it) => it.id === control.data?.version.id
              );
        if (savedProductVersion) {
          await onProductSave(savedProduct, savedProductVersion);
        }
      }

      control.close();
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  const [getProduct] = useEnhancedProgrammaticQuery(
    ProductSection_GetProductDocument,
    {
      error: {
        type: 'MODAL',
        message: 'An error occurred while loading the product.',
      },
    }
  );

  const { data: getProductData } = useEnhancedQuery(
    ProductSection_GetProductDocument,
    {
      skip: !(control.data?.product.id && control.data?.version.id),
      variables: {
        productId: control.data?.product.id as string,
        productVersionId: control.data?.version.id as string,
      },
      error: {
        type: 'MODAL',
        message: 'Could not load product.',
      },
    }
  );

  const product = getProductData?.getProduct;
  const productVersion = product?.versions.find(
    (ver) => ver.id === control.data?.version.id
  );
  const accessories = getProductData?.getRecommendedAccessories;

  const { data: suppliersData } = useEnhancedQuery(
    ProductSection_FindAllSuppliersDocument
  );

  const availableSuppliers = useMemo(
    () => suppliersData?.findAllSuppliers.suppliers ?? [],
    [suppliersData]
  );

  const [saveNewProduct] = useEnhancedMutation(
    ProductSection_SaveProductDocument,
    {
      refetchQueries: [getQueryName(ProductSection_SearchProductsDocument)],
      error: {
        type: 'MODAL',
        message: 'Could not save product in database.',
      },
      success: { message: 'Product saved.' },
    }
  );

  const [updateProductVersion] = useEnhancedMutation(
    ProductSection_UpdateProductVersionDocument,
    {
      refetchQueries: [getQueryName(ProductSection_SearchProductsDocument)],
      error: {
        type: 'MODAL',
        message: 'Could not save product in database.',
      },
      success: { message: 'Product updated.' },
    }
  );

  const [deleteProduct] = useEnhancedMutation(
    ProductSection_DeleteProductDocument,
    {
      refetchQueries: [getQueryName(ProductSection_SearchProductsDocument)],
      error: {
        type: 'MODAL',
        message: 'Could not delete product from database.',
      },
      booleanError: {
        type: 'MODAL',
        message: 'Could not delete product from database.',
        booleanKey: 'deleteProduct',
      },
      success: { message: 'Product deleted.' },
    }
  );

  const [deleteProductVersion] = useEnhancedMutation(
    ProductSection_DeleteProductVersionDocument,
    {
      refetchQueries: [getQueryName(ProductSection_SearchProductsDocument)],
      error: {
        type: 'MODAL',
        message: 'Could not delete product from database.',
      },
      success: { message: 'Product deleted.' },
    }
  );

  const [cloneProduct] = useEnhancedMutation(
    ProductSection_CloneProductDocument,
    {
      refetchQueries: [getQueryName(ProductSection_SearchProductsDocument)],
      error: {
        type: 'MODAL',
        message: 'Could not clone product.',
      },
      success: { message: 'Product cloned.' },
    }
  );

  useEffect(() => {
    if (control.data && product && productVersion) {
      formikRef.current.resetForm({
        values: {
          name: product.name ?? '',
          code: productVersion.code ?? '',
          category:
            (product.category &&
              (PRODUCT_CATEGORIES_MAP.get(product.category) as any)) ??
            '',
          supplier: productVersion.supplier ?? null,
          description: product.description ?? '',
          listPrice: productVersion.price ?? 0,
          costType:
            productVersion.cost?.calculationType ?? CalculationType.PERCENTAGE,
          costValue: productVersion.cost?.value ?? 0,
          specifications: extractSpecifications(product, productVersion),
          accessories:
            accessories?.map((item) => ({
              product: item.product,
              version: item.productVersion,
            })) ?? [],
          media: product.image
            ? [
                {
                  id: uuid(),
                  type: 'IMAGE',
                  thumbnail: product.image.thumbnail,
                  fullImage: product.image.fullImage ?? product.image.thumbnail, // 🤔
                  isMainPhoto: true,
                },
              ]
            : [],
        },
      });
    } else {
      formikRef.current.resetForm({
        values: initialValues,
      });
    }
    setPersistProductInDB(true);
  }, [
    formikRef,
    product,
    productVersion,
    accessories,
    control.data,
    control.isOpen,
    setPersistProductInDB,
  ]);

  async function handleCreateNewAttribute(newAttribute: Omit<Attribute, 'id'>) {
    await formik.setFieldValue('specifications', [
      ...formik.values.specifications,
      {
        id: uuid(),
        name: newAttribute.name,
        unit: newAttribute.unit,
        type: newAttribute.type,
        value: '',
      },
    ]);
  }

  async function handleRemoveSpecification(
    specificationId: ProductSpecificationWithAttrId['id']
  ) {
    await formik.setFieldValue(
      'specifications',
      formik.values.specifications.filter((spec) => spec.id !== specificationId)
    );
  }

  async function handleUpdateSelectedProduct(
    accessory: ProductSearchResult,
    selected: boolean
  ) {
    const newAccessoriesWithoutSelected = formik.values.accessories.filter(
      (acc) =>
        acc.product.id !== accessory.product.id &&
        acc.version.id !== accessory.version.id
    );
    // we remove it first to avoid duplicates
    const newAccessories = selected
      ? [...newAccessoriesWithoutSelected, accessory]
      : newAccessoriesWithoutSelected;
    await formik.setFieldValue('accessories', newAccessories);

    if (accessorySearchModal.isOpen) {
      accessorySearchModal.open({
        productName: formik.values.name,
        productVersionCode: formik.values.code,
        productAccessories: newAccessories,
      });
    }
  }

  async function handleDeleteProduct() {
    if (!control.data?.product.id || !product || !productVersion) return;

    if (product.versions.length === 1) {
      await deleteProduct({ variables: { id: control.data.product.id } });
    } else {
      await deleteProductVersion({
        variables: {
          productId: control.data.product.id,
          productVersionId: control.data.version.id,
        },
      });
    }
    control.close();
  }

  async function handleCloneProduct() {
    if (!control.data?.product.id) return;

    const cloneResult = await cloneProduct({
      variables: { id: control.data.product.id },
    });
    control.close();

    if (cloneResult.data) {
      await waitForAnimationFrame();

      control.open({
        product: cloneResult.data.cloneProduct,
        version: cloneResult.data.cloneProduct.versions[0],
      });
    }
  }

  const title = control.data?.product.name ?? 'New Product';
  return (
    <>
      <OOModal
        title={title}
        open={control.isOpen}
        onClose={control.close}
        minWidth="lg"
      >
        <form onSubmit={formik.handleSubmit}>
          <ProductModalContext.Provider
            value={{
              control,
              isAccessoryModal,
              availableSuppliers,

              canChangeSaveType: !!onProductSave,
              persistProductInDB,
              setPersistProductInDB,

              handleRemoveSpecification,
              handleUpdateSelectedProduct,

              handleCloneProduct,

              newAttributeModal,
              accessoryModal,
              accessorySearchModal,
              deleteModal,

              formik,
            }}
          >
            {(product && productVersion) ||
            (!control.data && control.isOpen) ? (
              isMobile ? (
                <ProductModalMobile />
              ) : (
                <ProductModalDesktop title={title} />
              )
            ) : (
              <Box
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
                paddingY={2}
                height={300}
              >
                <CircularProgress />
              </Box>
            )}
          </ProductModalContext.Provider>
        </form>
      </OOModal>

      <NewAttributeModal
        control={newAttributeModal}
        createAttribute={handleCreateNewAttribute}
      />

      {!isAccessoryModal && (
        <>
          <SearchAccessoryModal
            control={accessorySearchModal}
            getProduct={getProduct}
            accessoryModalControl={accessoryModal}
            handleUpdateSelectedProduct={handleUpdateSelectedProduct}
          />
          <ProductModal isAccessoryModal control={accessoryModal} />
        </>
      )}

      <ConfirmationModal
        title={`Confirm delete product`}
        message={`Are you sure you want to delete the product '${control.data?.product.name}' with code '${control.data?.version.code}'?`}
        open={deleteModal.isOpen}
        onCancel={deleteModal.close}
        onAccept={handleDeleteProduct}
      />
    </>
  );
};

export default ProductModal;
