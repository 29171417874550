import {
  NOOP_graphqlErrorManagement,
  useEnhancedMutation,
  useEnhancedQuery,
} from '@lib';
import { Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React, { FC } from 'react';

import { Company } from '../domain';
import {
  GET_COMPANY_DETAILS,
  UPDATE_GDRIVE_SETTINGS,
  UPDATE_SMARTBILL_API_KEY,
} from '../graphql';
import GDriveSettings from '../sections/GDriveSettings';
import SmartBillSettings from '../sections/SmartBillSettings';
import useStyles from '../styles';

type ExternalSettingsPageProps = {};

const ExternalSettingsPage: FC<ExternalSettingsPageProps> = () => {
  const { header, marginer } = useStyles();

  //region mutations
  const [updateSmartBillApiKey] = useEnhancedMutation(
    UPDATE_SMARTBILL_API_KEY,
    {
      error: {
        type: 'MODAL',
        message:
          'There was an error while updating your smart bill information. Please ensure that the given link is correct. If this persists, please contact support.',
      },
      success: {
        message: 'Smart bill information updated.',
      },
    }
  );

  const [updateGDriveSettings] = useEnhancedMutation(UPDATE_GDRIVE_SETTINGS, {
    error: {
      type: 'MODAL',
      message:
        'There was an error while updating your google drive folder data. Please ensure that the given link is correct. If this persists, please contact support.',
    },
    success: {
      message: 'Google drive folder link updated.',
    },
  });

  const {
    data: { getCompanyDetails } = { getCompanyDetails: [] },
  } = useEnhancedQuery<{ getCompanyDetails: Company }>(GET_COMPANY_DETAILS);
  //endregion

  async function handleGdriveUpdate(
    gDriveTemplateFile: string | undefined,
    gDriveTargetFolder: string | undefined,
    gDriveEveryProduct: boolean | undefined
  ) {
    try {
      await updateGDriveSettings({
        variables: {
          gDriveTemplateFile: gDriveTemplateFile ?? '',
          gDriveTargetFolder: gDriveTargetFolder ?? '',
          gDriveEveryProduct: !!gDriveEveryProduct,
        },
      });
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleSmartBillUpdate(
    smartBillApiKey: string | undefined,
    smartBillEmail: string | undefined
  ) {
    try {
      await updateSmartBillApiKey({
        variables: {
          key: smartBillApiKey ?? '',
          email: smartBillEmail ?? '',
        },
      });
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  return (
    <>
      <h2 className={header}>Integrations</h2>
      <Grid container>
        <Grid xs={4} item className={marginer}>
          <SmartBillSettings
            companyDetails={getCompanyDetails as Company}
            onSmartBillUpdate={handleSmartBillUpdate}
          />
        </Grid>
        <Grid xs={12} item>
          <Divider style={{ marginTop: 16 }} />
        </Grid>
        <Grid xs={4} item className={marginer}>
          <GDriveSettings
            companyDetails={getCompanyDetails as Company}
            onGdriveUpdate={handleGdriveUpdate}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ExternalSettingsPage;
