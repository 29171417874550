import {
  ApolloModalErrors,
  ApolloSnackbars,
  NumberFormatInput,
  OptiTextField,
} from '@components';
import {
  getInvalidFields,
  getOrigin,
  mapFormikToImgField,
  mapFormikToTextField,
  NOOP_graphqlErrorManagement,
  resizeImage,
  SecurityContext,
  useEnhancedMutation,
  useEnhancedProgrammaticQuery,
  useEnhancedQuery,
} from '@lib';
import { Box, Button, Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useFormik } from 'formik';
import React, { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { EmailPreferencesInput, User } from '@optioffer/graphql';

import ImageInput from '@components/form/ImageInput';

import {
  CONNECT_TO_GMAIL,
  CREATE_GMAIL_AUTH_URL,
  GET_USER,
  UPDATE_USER,
  UPDATE_USER_EMAIL_PREFERENCES,
} from '../graphql';
import EmailSettings from '../sections/EmailSettings';
import useStyles from '../styles';

const ProfilePage: FC = () => {
  const { currentUser } = useContext(SecurityContext);
  const { replace } = useHistory();
  const [gmailOauth2Code] = useQueryParam('code', StringParam);
  const { header } = useStyles();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      title: '',
      quoteNotUpdatedDuration: 0,
      avatar: null as any,
    },
    onSubmit: async (values) => {
      try {
        await handleUpdateUser(values);
      } catch {
        NOOP_graphqlErrorManagement();
      }
    },
  });

  const handleUpdateUser = async (values: typeof formik.values) => {
    await updateUser({
      variables: {
        userUpdateInput: {
          name: values.name,
          email: values.email,
          title: values.title,
          phoneNumber: values.phoneNumber,
          quoteNotUpdatedDuration: values.quoteNotUpdatedDuration,
          avatar:
            values.avatar && (values.avatar as any).thumbnail
              ? {}
              : values.avatar,
        },
      },
    });
  };

  //region mutations
  const [updateUser] = useEnhancedMutation(UPDATE_USER, {
    success: {
      message: 'Profile information updated.',
    },
    error: {
      message:
        'There was an error while updating your info. Please check your input and try again.',
      type: 'MODAL',
    },
    refetchQueries: ['getUserForProfile'],
    formik,
  });
  const [
    updateUserEmailPreferences,
    {
      data: updateUserEmailPreferencesData,
      error: updateUserEmailPreferencesError,
    },
  ] = useEnhancedMutation(UPDATE_USER_EMAIL_PREFERENCES, {
    refetchQueries: ['getUserForProfile'],
  });
  const [
    connectToGmail,
    {
      data: connectToGmailData,
      error: connectToGmailError,
      called: connectToGmailCalled,
    },
  ] = useEnhancedMutation(CONNECT_TO_GMAIL, {
    refetchQueries: ['getUserForProfile'],
  });
  //endregion

  //region queries
  const {
    data: getCurrentUserData,
    error: getCurrentUserError,
  } = useEnhancedQuery<{ currentUser: User }>(GET_USER);
  const [createGmailAuthUrl] = useEnhancedProgrammaticQuery(
    CREATE_GMAIL_AUTH_URL
  );
  //endregion
  const formikRef = useRef(formik);
  useEffect(() => {
    if (currentUser) {
      formikRef.current.setValues({
        name: currentUser.name ?? '',
        email: currentUser.email ?? '',
        phoneNumber: currentUser.phoneNumber ?? '',
        title: currentUser.title ?? '',
        avatar: currentUser.avatar ?? null,
        quoteNotUpdatedDuration: currentUser.quoteNotUpdatedDuration ?? 0,
      });
    } else {
      formikRef.current.resetForm();
    }
  }, [formikRef, currentUser]);

  //  region errors
  const errors = useMemo(
    () => [
      {
        error: getCurrentUserError,
        message:
          'There was an error while loading your data. If this persists, please contact support.',
      },
      {
        error: updateUserEmailPreferencesError,
        message:
          'There was an error while updating your email preferences. Please check your input and try again.',
      },
      {
        error: connectToGmailError,
        message:
          'There was an error while connecting to GMail. Please try again. If this persists, contact support.',
      },
    ],
    [getCurrentUserError, updateUserEmailPreferencesError, connectToGmailError]
  );
  //endregion

  //  region results
  const results = useMemo(
    () => [
      {
        result: updateUserEmailPreferencesData,
        message: 'Email preferences updated.',
      },
      {
        result: connectToGmailData,
        message: 'GMail connected successfully.',
      },
    ],
    [updateUserEmailPreferencesData, connectToGmailData]
  );
  //endregion

  useEffect(() => {
    if (gmailOauth2Code && !connectToGmailCalled) {
      (async (code: string) => {
        try {
          await connectToGmail({
            variables: { code, origin: getOrigin() },
          });
        } catch {
          NOOP_graphqlErrorManagement();
        }
        replace('/profile');
      })(gmailOauth2Code);
    }
  }, [gmailOauth2Code, connectToGmail, connectToGmailCalled, replace]);

  return (
    <>
      <h2 className={header}>My Account</h2>

      <form onSubmit={formik.handleSubmit} style={{ marginLeft: 16 }}>
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <h3 style={{ marginLeft: 8 }}>Edit profile</h3>
            <Box height={'100%'} marginBottom={10}>
              <Box paddingX={1.5}>
                <Box marginY={2}>
                  <OptiTextField
                    required
                    label="Full Name"
                    {...mapFormikToTextField(formik, 'name')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    required
                    label="Email"
                    {...mapFormikToTextField(formik, 'email')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Phone Number"
                    {...mapFormikToTextField(formik, 'phoneNumber')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Job title"
                    {...mapFormikToTextField(formik, 'title')}
                  />
                </Box>
                <Box marginY={2}>
                  <OptiTextField
                    label="Quote not updated duration"
                    style={{ width: 200 }}
                    {...mapFormikToTextField(formik, 'quoteNotUpdatedDuration')}
                    InputProps={{
                      inputComponent: NumberFormatInput as any,
                      inputProps: {
                        decimalScale: 0,
                        textAlign: 'right',
                        onFocus: (e) => e.target.select(),
                      },
                      endAdornment: (
                        <label style={{ color: '#818181', marginLeft: 8 }}>
                          days
                        </label>
                      ),
                    }}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={
                    !formik.dirty || !formik.values.email || !formik.values.name
                  }
                >
                  Save data
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} style={{ paddingRight: 24 }}>
            <ImageInput
              buttonLabel="+ Add profile picture"
              imageChanged={async (event: any) => {
                const img = await resizeImage({
                  file: event.target.files[0] as File,
                  maxHeight: 877,
                  maxWidth: 620,
                  compressionRatio: 0.6,
                });
                await formik.setFieldValue('avatar', img.blob);
                await formik.submitForm();
              }}
              {...mapFormikToImgField(formik, 'avatar')}
            />
          </Grid>
        </Grid>
      </form>

      <Divider />
      <EmailSettings
        emailPreferences={getCurrentUserData?.currentUser.emailPreferences}
        connectedProvider={getCurrentUserData?.currentUser.connectedProvider}
        invalidFields={getInvalidFields(updateUserEmailPreferencesError)}
        updateEmailPreferences={(emailPreferences?: EmailPreferencesInput) =>
          updateUserEmailPreferences({ variables: { emailPreferences } })
        }
        createGmailAuthUrl={() =>
          createGmailAuthUrl({ variables: { origin: getOrigin() } }).then(
            ({ data: { createGmailAuthUrl } }) => createGmailAuthUrl
          )
        }
      />

      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default ProfilePage;
