import React, { FC } from 'react';

import { ProfileOnAdminListPageFragment } from '@optioffer/graphql';

import OOTable, {
  OOTableCell,
  OOTableGenericProps,
  OOTableRow,
} from '@components/Table/OOTable';

type ProfileTableProps<
  T = ProfileOnAdminListPageFragment
> = OOTableGenericProps<T>;

const ProfileTable: FC<ProfileTableProps> = ({ onEdit, ...rest }) => {
  return (
    <OOTable
      {...rest}
      onEdit={onEdit}
      renderHeader={() => (
        <>
          <OOTableCell component="th" width="60px">
            Thumbnail
          </OOTableCell>
          <OOTableCell component="th">Name</OOTableCell>
          <OOTableCell component="th">Type</OOTableCell>
          <OOTableCell component="th">Category</OOTableCell>
          <OOTableCell component="th">Location</OOTableCell>
          <OOTableCell component="th">Status</OOTableCell>
          <OOTableCell component="th">Badge</OOTableCell>
        </>
      )}
      renderRow={(row) => (
        <OOTableRow key={row.name} onClick={() => onEdit(row)}>
          <OOTableCell scope="row">
            {row.image?.thumbnail && (
              <img
                style={{ width: '50px' }}
                src={row.image?.thumbnail}
                alt="product"
              />
            )}
          </OOTableCell>
          <OOTableCell scope="row">
            <div>
              <div>{row.companyName}</div>
              <strong>{row.contactPersonName}</strong>
            </div>
          </OOTableCell>
          <OOTableCell scope="row">{row.profileType}</OOTableCell>
          <OOTableCell scope="row">{row.category}</OOTableCell>
          <OOTableCell align="left">
            <div>
              <div>{row.address}</div>
              <div>{row.areaServed}</div>
            </div>
          </OOTableCell>
          <OOTableCell align="left">{row.profileStatus}</OOTableCell>
          <OOTableCell align="left">{row.badgeStatus}</OOTableCell>
        </OOTableRow>
      )}
    />
  );
};

export default ProfileTable;
