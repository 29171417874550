import {
  createDefaultActions,
  DVKPagination,
  DVKSort,
  DVKTable,
} from '@dvkiin/material-commons';
import Paper from '@material-ui/core/Paper';
import React, { FC, useMemo } from 'react';

import { UpdatePaginationHandler } from '../../lib';
import { AttributeCollection } from './domain';
import useStyles from './styles';

type AttributeCollectionsTableProps = {
  attributeCollections: AttributeCollection[];
  total: number;
  pagination: DVKPagination;
  sort: DVKSort | null;
  onPaginationUpdate: UpdatePaginationHandler;
  onEdit: (attributeCollection: any) => void;
  onDelete: (attributeCollection: any) => void;
  onClone: (attributeCollection: any) => void;
};

const AttributeCollectionsTable: FC<AttributeCollectionsTableProps> = ({
  attributeCollections,
  total,
  pagination,
  sort,
  onPaginationUpdate,
  onEdit,
  onDelete,
  onClone,
}) => {
  const { tableCard } = useStyles();
  const actions = useMemo(
    () => createDefaultActions({ onEdit, onDelete, onClone }),
    [onEdit, onDelete, onClone]
  );

  return (
    <Paper className={tableCard}>
      <DVKTable
        columns={[
          { name: 'name', label: 'Attribute collection name', type: 'text' },
          {
            name: 'description',
            label: 'Attribute collection description',
            type: 'text',
          },
          { name: 'numberOfAttributes', label: '# Attributes', type: 'number' },
        ]}
        actions={actions}
        rows={attributeCollections}
        total={total}
        pagination={pagination}
        sort={sort}
        onPaginationSortUpdate={onPaginationUpdate}
        onRowClick={onEdit}
      />
    </Paper>
  );
};

export default AttributeCollectionsTable;
