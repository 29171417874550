import { IDiscount, OfferPrice } from '@optioffer/core';

export type Client = {
  id: string;
  name: string;
  email?: string;
  phoneNumber?: string;
};

export type Attribute = {
  id: string;
  name: string;
  type: string;
  unit?: string;
};

export type AttributeValue = {
  id: string;
  attribute: Attribute;
  value: any;
};

export type ProductVersion = {
  id: string;
  code: string;
  price: number;
  attributeValues?: AttributeValue[];
};

export type Product = {
  id: string;
  name: string;
  description?: string;
  image: { thumbnail: string };
  versions: ProductVersion[];
};

export type OfferItemProduct = {
  id: string;
  productId: string;
  productVersionId: string;
  name: string;
  code: string;
  price: number;
  image?: { thumbnail: string };
};

export type OfferItem = {
  id: string;
  quantity: number;
  offerItemDiscount: IDiscount;
  product: OfferItemProduct;
  parentId: OfferItem['id'];
};

export type Offer = {
  id: string;
  title?: string;
  client?: Client;
  offerDiscount: IDiscount;
  displayDiscounts: boolean;
  pricing: OfferPrice;
  offerItems: OfferItem[];
  status: 'DRAFT' | 'IN_PROGRESS';
  ownedByCompany: {
    currency: {
      symbol: string;
    };
  };
};

export const DragItemType = {
  CLIENT: 'CLIENT',
  PRODUCT_VERSION: 'PRODUCT_VERSION',
};

export type DragItem = {
  type: keyof typeof DragItemType;
  id: Product['id'] | Client['id'];
  productVersionId?: ProductVersion['id'];
  parentOfferId?: Offer['id'];
};

export type Country = {
  isoCode: string;
  dialCode: string;
  name: string;
  flag: string;
};

export type CountrySelectorStyleParams = {
  size?: 'small' | 'medium';
  showAdornment?: boolean;
  inputVariant?: 'outlined' | 'filled' | 'standard';
  placeholder?: string;
  label?: string;
};
