import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { OOTheme } from '../../theme';
import OfferWizard from './OfferWizard';

// no idea why the simple webpack 'react-pdf/dist/esm/entry.webpack' does not work 🤷‍♂️
pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const useLocalStyles = makeStyles((theme: OOTheme) => ({
  root: {
    width: '100%',
    backgroundColor: '#FAFAFA',
  },
}));

const NewOfferModule: FC<RouteComponentProps> = ({ match }) => {
  const localClasses = useLocalStyles();
  return (
    <div className={localClasses.root}>
      <Switch>
        <Route path={`${match.path}/:id`} component={OfferWizard} />
      </Switch>
    </div>
  );
};

export default NewOfferModule;
