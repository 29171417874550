import {
  ConfirmationModal,
  DVKField,
  DVKForm,
  DVKObject,
  FlexExpander,
  lett,
  useModal,
  uuid,
} from '@dvkiin/material-commons';
import {
  booleanResultToError,
  getInvalidFields,
  NOOP_graphqlErrorManagement,
  SecurityContext,
  useEnhancedMutation,
  useEnhancedQuery,
} from '@lib';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import PreviewIcon from '@material-ui/icons/Visibility';
import { Document } from '@react-pdf/renderer';
import React, { FC, useContext, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import { ArrayElement, CalculationType, sum } from '@optioffer/core';
import {
  BasicLayoutCompactOfferItems,
  BasicLayoutDetailedOfferItems,
  BasicLayoutVariant,
  Offer,
  useRenderedOffer,
} from '@optioffer/printing';

import { ApolloModalErrors, ApolloSnackbars } from '@components/Feedback';
import SafePDFViewer from '@components/PDF/SafePDFViewer';

import { mockClient } from '@lib/client';

import { printingResources } from '@resources/printingResources';

import { FIND_ALL_PRODUCTS_BY_FILTER } from '../../Offer/graphql';
import {
  Product,
  ProductBundle,
  ProductBundleItem,
  ProductBundleItemInput,
} from '../domain';
import { productBundleDefaultFields } from '../fields';
import {
  ADD_PRODUCT_BUNDLE_ITEM,
  DELETE_PRODUCT_BUNDLE,
  GET_PRODUCT_BUNDLE,
  MOVE_PRODUCT_BUNDLE_ITEM,
  REMOVE_PRODUCT_BUNDLE_ITEM,
  UPDATE_PRODUCT_BUNDLE,
  UPDATE_PRODUCT_BUNDLE_ITEM,
} from '../graphql';
import useStyles from '../styles';
import ItemsSection from './ItemsSection';

type ProductBundlePageParams = {
  id: string;
};

const ProductBundleViewEditPage: FC<
  RouteComponentProps<ProductBundlePageParams>
> = ({
  match: {
    params: { id },
  },
}) => {
  const { bundleViewEditCard, selectProductVersion, pdfViewer } = useStyles();
  const { company } = useContext(SecurityContext);
  const { replace } = useHistory();
  const [selectedVariantForPreview, setSelectedVariantForPreview] = useState(
    BasicLayoutVariant.DETAILED
  );
  const [shouldRenderPreview, enablePreviewRender] = useState(false);

  const {
    isOpen: isDeleteModalOpen,
    data: deleteModalData,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal();

  //region queries
  const {
    data: { getProductBundle } = { getProductBundle: undefined },
    error: getError,
    loading,
  } = useEnhancedQuery<{ getProductBundle: ProductBundle }>(
    GET_PRODUCT_BUNDLE,
    {
      variables: { id },
    }
  );

  const {
    error: findAllProductsByFilterError,
    refetch: refetchProducts,
  } = useEnhancedQuery<{
    findAllProductsByFilter: Product[];
  }>(FIND_ALL_PRODUCTS_BY_FILTER, {
    variables: { topCount: 10, searchString: '' },
    skip: true,
  });
  //endregion

  //region mutations
  const [
    updateProductBundle,
    { data: updateResult, error: updateError },
  ] = useEnhancedMutation(UPDATE_PRODUCT_BUNDLE);
  const [
    deleteProductBundle,
    { data: deleteResult, error: deleteError },
  ] = useEnhancedMutation(DELETE_PRODUCT_BUNDLE, {
    refetchQueries: ['findAllProductBundles'],
  });
  const [
    addProductBundleItem,
    { data: addProductBundleItemResult, error: addProductBundleItemError },
  ] = useEnhancedMutation(ADD_PRODUCT_BUNDLE_ITEM, {
    refetchQueries: ['findAllOffers', 'getOffer'], // because we have to recalculate cost & margin
  });
  const [
    removeProductBundleItem,
    {
      data: removeProductBundleItemResult,
      error: removeProductBundleItemError,
    },
  ] = useEnhancedMutation(REMOVE_PRODUCT_BUNDLE_ITEM, {
    refetchQueries: ['findAllOffers', 'getOffer'], // because we have to recalculate cost & margin
  });
  const [
    moveProductBundleItem,
    { data: moveProductBundleItemResult, error: moveProductBundleItemError },
  ] = useEnhancedMutation(MOVE_PRODUCT_BUNDLE_ITEM);
  const [
    updateProductBundleItem,
    {
      data: updateProductBundleItemResult,
      error: updateProductBundleItemError,
    },
  ] = useEnhancedMutation(UPDATE_PRODUCT_BUNDLE_ITEM, {
    refetchQueries: ['findAllOffers', 'getOffer'], // because we have to recalculate cost & margin
  });
  //endregion

  //region Memos
  const defaultProductBundleValue = useMemo(
    (): any =>
      getProductBundle
        ? {
            ...getProductBundle,
            discountValue: getProductBundle.discount?.value,
          }
        : undefined,
    [getProductBundle]
  );

  const getProductOfferItemDummy = useMemo(():
    | ArrayElement<Offer['offerItems']>
    | undefined => {
    if (!getProductBundle?.items?.length) return undefined;

    const listPrice = getProductBundle.items
      .map((item) => item.productVersion.price)
      .reduce(sum, 0);
    const discountValue = getProductBundle.discount
      ? getProductBundle.discount.calculationType === CalculationType.FIXED
        ? getProductBundle.discount.value
        : (listPrice * getProductBundle.discount.value) / 100
      : undefined;

    const netPrice = listPrice - (discountValue ?? 0);
    return {
      id: 'test',
      parentId: 'test',
      productBundle: {
        ...getProductBundle,
        items: getProductBundle.items.map((item) => ({
          ...item,
          product: {
            ...item.product,
            code: item.productVersion.code,
            price: item.productVersion.price,
            productId: item.product.id,
            productVersionId: item.productVersion.id,
            specifications:
              item.product.attributes?.map((attribute) => ({
                id: uuid(),
                name: attribute.name,
                unit: attribute.unit,
                value: lett(
                  item.productVersion?.attributeValues.find(
                    (attrVal) => attrVal.attribute.id === attribute.id
                  ),
                  (attrVal) => attrVal.value
                ),
              })) || [],
          },
        })),
      },
      children: [],
      quantity: 1,
      pricing: {
        taxesBeforeDiscount: [],
        taxesAfterDiscount: [],
        taxesFinal: [],
        listPrice,
        quantity: 1,
        listPriceTotal: listPrice,
        offerItemCurrency: company.currency,
        netPrice,
        netPriceTotal: netPrice,
        discount: discountValue
          ? {
              discount: getProductBundle.discount!,
              value: discountValue,
              valueTotal: discountValue,
            }
          : undefined,
      },
      addons: [],
    };
  }, [company, getProductBundle]);
  const getOfferDummy = useMemo((): Offer | undefined => {
    if (!getProductOfferItemDummy) return undefined;

    return {
      id: getProductOfferItemDummy.id!,
      createdAt: new Date().getTime(),
      offerItems: [getProductOfferItemDummy],
      ownedByCompany: (company as unknown) as Offer['ownedByCompany'],
      client: mockClient,
      displayDiscounts: true,
      addons: [],

      pricing: undefined as any, // this is not used, i promise
      expireAt: undefined as any, // this is not used, i promise
      createdByUser: undefined as any, // this is not used, i promise
    };
  }, [company, getProductOfferItemDummy]);

  const renderedOffer = useRenderedOffer(
    getOfferDummy,
    ({ variant }) => (
      <Document>
        {variant === BasicLayoutVariant.DETAILED ? (
          <BasicLayoutDetailedOfferItems />
        ) : (
          <BasicLayoutCompactOfferItems />
        )}
      </Document>
    ),
    printingResources,
    selectedVariantForPreview,
    'en',
    shouldRenderPreview
  );
  //endregion

  //region fields
  const productBundleFields = useMemo(() => {
    return [
      {
        name: 'id',
        label: 'Unique id',
        type: 'text',
        disabled: true,
      } as DVKField,
      { name: 'image', label: 'Image of bundle', type: 'image' },
      ...productBundleDefaultFields,
      { name: 'discountValue', label: 'Discount (%)', type: 'number' },
    ] as DVKField[];
  }, []);

  const selectRenderFields = useMemo(
    () =>
      [
        {
          name: 'variant',
          label: 'Offer layout',
          type: 'select',
          required: true,
          values: [
            {
              name: BasicLayoutVariant.DETAILED,
              label: 'Detailed',
            },
            {
              name: BasicLayoutVariant.COMPACT,
              label: 'Compact',
            },
          ],
        },
      ] as DVKField[],
    []
  );
  //endregion

  //region errors
  const errors = useMemo(
    () => [
      {
        error: getError,
        message:
          'There was an error while retrieving the product bundle. Please try again.',
      },
      {
        error: updateError,
        message:
          'There was an error while updating the product bundle. Please check your input and try again.',
      },
      {
        error: booleanResultToError(deleteResult, 'deleteProductBundle'),
        message:
          'There was an error while deleting the product bundle. Please try again.',
      },
      {
        error: deleteError,
        message:
          'There was an error while deleting the product bundle. Please try again.',
      },

      {
        error: addProductBundleItemError,
        message:
          'There was an error while adding a new item to the product bundle. Please try again.',
      },
      {
        error: removeProductBundleItemError,
        message:
          'There was an error while removing the item from to the product bundle. Please try again.',
      },
      {
        error: updateProductBundleItemError,
        message:
          'There was an error while updating the item of the product bundle. Please check your input and try again.',
      },
      {
        error: moveProductBundleItemError,
        message:
          'There was an error while moving the product bundle item. Please try again.',
      },
      {
        error: findAllProductsByFilterError,
        message:
          'There was an error while searching for products. Please try again.',
      },
    ],
    [
      getError,
      updateError,
      deleteResult,
      deleteError,
      addProductBundleItemError,
      removeProductBundleItemError,
      updateProductBundleItemError,
      moveProductBundleItemError,
      findAllProductsByFilterError,
    ]
  );
  //endregion

  //region results
  const results = useMemo(
    () => [
      {
        result: deleteResult,
        message: 'Product bundle deleted.',
        booleanKey: 'deleteProductBundle',
      },
      updateResult && {
        result: updateResult,
        message: `Product bundle '${updateResult.updateProductBundle.name}' updated.`,
      },
      { result: addProductBundleItemResult, message: 'Item added.' },
      { result: removeProductBundleItemResult, message: 'Item removed.' },
      { result: moveProductBundleItemResult, message: 'Item moved.' },
      { result: updateProductBundleItemResult, message: 'Item updated.' },
    ],
    [
      deleteResult,
      updateResult,
      addProductBundleItemResult,
      removeProductBundleItemResult,
      updateProductBundleItemResult,
      moveProductBundleItemResult,
    ]
  );

  //endregion

  async function handleUpdateProductBundle({
    name,
    description,
    image,
    discountValue,
  }: DVKObject) {
    try {
      await updateProductBundle({
        variables: {
          id,
          productBundle: {
            name,
            description,
            image,
            discount: {
              calculationType: CalculationType.PERCENTAGE,
              value: discountValue,
            },
          },
        },
      });
    } catch {
      NOOP_graphqlErrorManagement();
    }
  }

  async function handleProductBundleDelete() {
    const {
      data: { deleteProductBundle: deleteProductBundleResult },
    } = await deleteProductBundle({ variables: { id: deleteModalData.id } });
    if (deleteProductBundleResult) {
      replace('/product/bundle');
    }
  }

  async function handleAddProductBundleItem(
    productBundleItem: ProductBundleItemInput
  ) {
    await addProductBundleItem({
      variables: {
        productBundleId: getProductBundle!.id,
        productBundleItem,
      },
    });
  }

  async function handleRemoveProductBundleItem(
    productBundleItemId: ProductBundleItem['id']
  ) {
    await removeProductBundleItem({
      variables: {
        productBundleId: getProductBundle!.id,
        productBundleItemId,
      },
    });
  }

  async function handleMoveProductBundleItem(
    productBundleItemId: ProductBundleItem['id'],
    newPosition: number
  ) {
    await moveProductBundleItem({
      variables: {
        productBundleId: getProductBundle!.id,
        productBundleItemId,
        newPosition,
      },
    });
  }

  async function handleUpdateProductBundleItem(
    productBundleItemId: ProductBundleItem['id'],
    productBundleItem: ProductBundleItemInput
  ) {
    const { errors } = await updateProductBundleItem({
      variables: {
        productBundleId: getProductBundle!.id,
        productBundleItemId,
        productBundleItem,
      },
    });
    if (errors && errors.length) {
      throw errors[0];
    }
  }

  function renderProductBundle(productBundle: ProductBundle) {
    const invalidFields = getInvalidFields(updateError);
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Card className={bundleViewEditCard}>
            <CardHeader title={productBundle.name} />
            <DVKForm
              fields={[...productBundleFields]}
              defaultValue={defaultProductBundleValue}
              ContentWrapper={CardContent}
              ActionsWrapper={CardActions}
              renderActions={() => (
                <>
                  <Button onClick={() => openDeleteModal(productBundle)}>
                    Delete
                  </Button>
                  <FlexExpander />
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </>
              )}
              onSubmit={handleUpdateProductBundle}
              invalidFields={invalidFields}
            />
          </Card>
          <ItemsSection
            productBundle={productBundle}
            addProductBundleItem={handleAddProductBundleItem}
            removeProductBundleItem={handleRemoveProductBundleItem}
            moveProductBundleItem={handleMoveProductBundleItem}
            updateProductBundleItem={handleUpdateProductBundleItem}
            updateItemInvalidFields={
              getInvalidFields(updateProductBundleItemError) || undefined
            }
            addItemInvalidFields={
              getInvalidFields(addProductBundleItemError) || undefined
            }
            refetchProducts={refetchProducts}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={shouldRenderPreview ? 12 : 6}>
              <DVKForm
                className={selectProductVersion}
                fields={selectRenderFields}
                defaultValue={{
                  variant: selectedVariantForPreview,
                }}
                onChange={({ variant }) => {
                  setSelectedVariantForPreview(variant as BasicLayoutVariant);
                }}
              />
            </Grid>
            {!shouldRenderPreview && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PreviewIcon />}
                  onClick={() => enablePreviewRender(true)}
                >
                  Render Preview
                </Button>
              </Grid>
            )}
          </Grid>

          <SafePDFViewer
            className={pdfViewer}
            document={renderedOffer}
            height={900}
            width={700}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {loading && 'Loading your product bundle...'}{' '}
      {/* fancy loading screen here */}
      <ConfirmationModal
        open={isDeleteModalOpen}
        onCancel={closeDeleteModal}
        onAccept={handleProductBundleDelete}
        message={`Are you sure you want to delete product bundle '${
          deleteModalData && deleteModalData.code
        }'?`}
        title="Please confirm product bundle delete"
      />
      {getProductBundle && renderProductBundle(getProductBundle)}
      <ApolloModalErrors errors={errors} />
      <ApolloSnackbars results={results} />
    </>
  );
};

export default ProductBundleViewEditPage;
