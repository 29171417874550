import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import AttributeCollectionListPage from './ListPage';
import AttributeCollectionViewEditPage from './ViewEditPage';

const AttributeCollectionModule: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/:id`}
        component={AttributeCollectionViewEditPage}
      />
      <Route path={match.path} component={AttributeCollectionListPage} />
    </Switch>
  );
};

export default AttributeCollectionModule;
