import {
  ApolloError,
  ApolloQueryResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryLazyOptions,
  QueryResult,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { QueryTuple } from '@apollo/client/react/types/types';
import { uuid } from '@dvkiin/material-commons';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import { TypedDocumentNode } from '@optioffer/graphql';

import { ApolloFeedbackContext } from '@components/Feedback/context';

import { booleanResultToError } from '@lib/error';
import {
  Formik,
  getInvalidFormikFields,
  mapInvalidFormikFieldNames,
} from '@lib/formik';

export type EnhancedMutationHookOptions<
  TData,
  TVariables
> = MutationHookOptions<TData, TVariables> & {
  success?: {
    message: ReactNode | ((result: TData) => ReactNode);
    action?: ReactNode | ((result: TData) => ReactNode);
    booleanKey?: string; // we check in the response if this is true before showing success
  };
  // this error is triggered when an error is returned by the server
  error?: {
    type: 'MODAL' | 'SNACKBAR';
    message: string;
  };
  // this error is triggered when data is returned by the server (no error), but the boolean property is not true
  // this is mainly used for delete operations that return a boolean result, showing if the operation was successful or not
  booleanError?: {
    type: 'MODAL' | 'SNACKBAR';
    message: string;
    booleanKey: string;
  };
  formik?: Formik<any>;
  remapFormikErrorFields?: Map<string, string>;
};

export function useEnhancedMutation<
  TData = any,
  TVariables = OperationVariables
>(
  mutation: TypedDocumentNode<TData, TVariables>,
  options?: EnhancedMutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  const { registerSuccess, registerError, unregisterOperation } = useContext(
    ApolloFeedbackContext
  );
  const operationUUID = useMemo(uuid, []);
  const feedbackRef = useRef({
    registerSuccess,
    registerError,
    operationUUID,
    unregisterOperation,

    success: options?.success,
    error: options?.error,
    booleanError: options?.booleanError,

    formik: options?.formik,
    remapFormikErrorFields: options?.remapFormikErrorFields,
  });
  useEffect(() => {
    feedbackRef.current.success = options?.success;
    feedbackRef.current.error = options?.error;
    feedbackRef.current.booleanError = options?.booleanError;

    feedbackRef.current.formik = options?.formik;
    feedbackRef.current.remapFormikErrorFields =
      options?.remapFormikErrorFields;
  }, [options]);

  const apolloClient = useApolloClient();
  const [mutate, result] = useMutation(mutation, options);
  const resultRef = useRef(result);
  useUpdateRefIfShallowNew(resultRef, result);

  const wrappedMutate = useCallback(
    async (overrideOptions?: MutationFunctionOptions<TData, TVariables>) => {
      const result = await mutate({
        ...(overrideOptions || {}),
        refetchQueries: [],
      });

      if (
        (options && options.refetchQueries) ||
        (overrideOptions && overrideOptions.refetchQueries)
      ) {
        const awaitRefetchQueries = options
          ? options.awaitRefetchQueries || false
          : overrideOptions
          ? overrideOptions.awaitRefetchQueries || false
          : false;
        if (awaitRefetchQueries) {
          await apolloClient.resetStore();
        } else {
          apolloClient.resetStore();
        }
      }

      return result;
    },
    [mutate, options, apolloClient]
  );

  useEffect(() => {
    const onlySoReactDoesNotShoutAtMe = feedbackRef.current;

    return () => {
      onlySoReactDoesNotShoutAtMe.unregisterOperation(
        onlySoReactDoesNotShoutAtMe.operationUUID
      );
    };
  }, [feedbackRef]);

  useEffect(() => {
    if (feedbackRef.current.success) {
      feedbackRef.current.registerSuccess(feedbackRef.current.operationUUID, {
        result: resultRef.current.data,
        ...feedbackRef.current.success,
      });
    }

    // both errors use the same uuid, because they should appear at the same time.
    // if they do, and are the same type (modal or snackbar) the error will override the booleanError
    let payload: ErrorWithMessage | undefined;
    if (feedbackRef.current.booleanError) {
      payload = {
        error: booleanResultToError(
          resultRef.current.data,
          feedbackRef.current.booleanError.booleanKey
        ),
        ...feedbackRef.current.booleanError,
      };
    }
    if (feedbackRef.current.error) {
      payload = {
        error: resultRef.current.error,
        ...feedbackRef.current.error,
      };
    }

    if (feedbackRef.current.formik) {
      if (resultRef.current.error) {
        try {
          const invalidFields = mapInvalidFormikFieldNames(
            getInvalidFormikFields(resultRef.current.error),
            feedbackRef.current.remapFormikErrorFields
          );
          if (invalidFields) {
            feedbackRef.current.formik.setErrors(invalidFields);
            // do not show error modal / snackbar if we managed to show it in formik
            payload = undefined;
          }
        } catch {
          // nothing to do here, could not map invalid fields, such is life ¯\_(ツ)_/¯
        }
      } else {
        feedbackRef.current.formik.setErrors({});
      }
    }

    if (payload) {
      feedbackRef.current.registerError(
        feedbackRef.current.operationUUID,
        payload
      );
    }
  }, [feedbackRef, resultRef, result.loading]);

  return [wrappedMutate, result];
}

export type EnhancedQueryHookOptions<TData, TVariables> = QueryHookOptions<
  TData,
  TVariables
> & {
  // this error is triggered when an error is returned by the server
  error?: Pick<ErrorWithMessage, 'type' | 'message'>;
};

export function useEnhancedQuery<TData = any, TVariables = OperationVariables>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: EnhancedQueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const { registerError, unregisterOperation } = useContext(
    ApolloFeedbackContext
  );
  const operationUUID = useMemo(uuid, []);
  const feedbackRef = useRef({
    registerError,
    operationUUID,
    unregisterOperation,

    error: options?.error,
  });
  useEffect(() => {
    feedbackRef.current.error = options?.error;
  }, [options]);

  const result = useQuery<TData, TVariables>(query, options);
  const resultRef = useRef(result);
  useUpdateRefIfShallowNew(resultRef, result);

  useEffect(() => {
    const onlySoReactDoesNotShoutAtMe = feedbackRef.current;

    return () => {
      onlySoReactDoesNotShoutAtMe.unregisterOperation(
        onlySoReactDoesNotShoutAtMe.operationUUID
      );
    };
  }, [feedbackRef]);

  // TODO: refech does not trigger this, because it does not change the result.error when failing
  // TODO: fix it
  useEffect(() => {
    if (feedbackRef.current.error) {
      const payload: ErrorWithMessage = {
        error: resultRef.current.error,
        ...feedbackRef.current.error,
      };

      feedbackRef.current.registerError(
        feedbackRef.current.operationUUID,
        payload
      );
    }
  }, [feedbackRef, resultRef, result.loading]);

  return result;
}

export function useEnhancedLazyQuery<
  TData = any,
  TVariables = OperationVariables
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: EnhancedQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> {
  const { registerError, unregisterOperation } = useContext(
    ApolloFeedbackContext
  );
  const operationUUID = useMemo(uuid, []);
  const feedbackRef = useRef({
    registerError,
    operationUUID,
    unregisterOperation,

    error: options?.error,
  });
  useEffect(() => {
    feedbackRef.current.error = options?.error;
  }, [options]);

  const [queryMethod, result] = useLazyQuery<TData, TVariables>(query, options);
  const resultRef = useRef(result);
  useUpdateRefIfShallowNew(resultRef, result);

  useEffect(() => {
    const onlySoReactDoesNotShoutAtMe = feedbackRef.current;

    return () => {
      onlySoReactDoesNotShoutAtMe.unregisterOperation(
        onlySoReactDoesNotShoutAtMe.operationUUID
      );
    };
  }, [feedbackRef]);

  useEffect(() => {
    if (feedbackRef.current.error) {
      const payload: ErrorWithMessage = {
        error: resultRef.current.error,
        ...feedbackRef.current.error,
      };

      feedbackRef.current.registerError(
        feedbackRef.current.operationUUID,
        payload
      );
    }
  }, [feedbackRef, resultRef, result.loading]);

  return [queryMethod, result];
}

export type ProgrammaticQueryMethod<TData, TVariables> = (
  options?: QueryLazyOptions<TVariables>
) => Promise<ApolloQueryResult<TData | undefined>>;
export type ProgrammaticQueryTuple<TData, TVariables> = [
  ProgrammaticQueryMethod<TData, TVariables>,
  QueryTuple<TData, TVariables>['1']
];

// basically a read only mutation
export function useEnhancedProgrammaticQuery<
  TData = any,
  TVariables = OperationVariables
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: EnhancedQueryHookOptions<TData, TVariables>
): ProgrammaticQueryTuple<TData, TVariables> {
  const [queryMethod, result] = useEnhancedLazyQuery<TData, TVariables>(
    query,
    options
  );
  const { fetchMore, called } = result;
  const reqMethod = useCallback(
    (
      options?: QueryLazyOptions<TVariables>
    ): Promise<ApolloQueryResult<TData | undefined>> => {
      if (called) return fetchMore(options ?? {});
      else return queryMethod(options);
    },
    [queryMethod, fetchMore, called]
  );

  return [reqMethod, result];
}

export type ResultWithMessage<TData = any> = {
  result: TData;
  message: ReactNode | ((result: TData) => ReactNode);
  action?: ReactNode | ((result: TData) => ReactNode);
  booleanKey?: string;
};

export type ErrorWithMessage = {
  type?: 'MODAL' | 'SNACKBAR'; // default is MODAL
  error?: ApolloError;
  message: string;
};
