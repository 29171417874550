import { useQueryParams } from '@lib';
import React, { FC } from 'react';

import RequestResetPasswordPage from './RequestResetPassword';
import ResetPasswordPage from './ResetPassword';

const ResetPasswordModule: FC = () => {
  const query = useQueryParams();

  if (!query.token) {
    return <RequestResetPasswordPage />;
  }

  return <ResetPasswordPage />;
};

export default ResetPasswordModule;
