import gql from 'graphql-tag';

import { currencyFields, taxFields } from '@optioffer/core';

const COMPANY_FIELDS = `
    id
    name
    email
    address
    addressSecondary
    website
    phoneNumber
    motto
    vat
    companyIntro
    companyTerms
    companyThankYouMessage
    companyLogo {
      id
      thumbnail
      fullImage
      fileName
      height
      width
    }
    costAndMarginsOnlyForManagers
    currency {
      ${currencyFields}
     }
    defaultTaxes {
      ${taxFields}
    }
    smartBillApiKey
    smartBillEmail
    defaultOfferAvailability
    defaultOfferLanguage
    defaultOfferFormat
    gDriveTemplateFile
    gDriveTargetFolder
    gDriveEveryProduct
`;

export const INITIATE_TERMINATION = gql`
  mutation initiateTermination {
    initiateTermination
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($companyUpdateInput: CompanyUpdateInput!) {
    updateCompany(companyUpdateInput: $companyUpdateInput) {
      ${COMPANY_FIELDS}
    }
  }
`;

export const UPDATE_COMPANY_OFFER_SETTINGS = gql`
  mutation updateCompanyOfferSettings($companyUpdateOfferSettingsInput: CompanyUpdateOfferSettingsInput!) {
    updateCompanyOfferSettings(companyUpdateOfferSettingsInput: $companyUpdateOfferSettingsInput) {
      ${COMPANY_FIELDS}
    }
  }
`;

export const GET_COMPANY_DETAILS = gql`
  query getCompanyDetails {
    getCompanyDetails {
      ${COMPANY_FIELDS}
    }
  }
`;

export const GET_COMPANY_QUOTAS = gql`
  query getCompanyQuotas {
    getCompanyQuotas {
      nrProducts
      nrOffers
      nrUsers
    }
  }
`;

export const GET_AVAILABLE_CURRENCIES = gql`
  query getAvailableCurrencies($search: String) {
    getAvailableCurrencies(search: $search) {
      id
      name
      symbol
    }
  }
`;

export const FIND_ALL_COMPANY_USERS = gql`
  query getAllCompanyUsers {
    getAllCompanyUsers {
      id
      name
      email
      roles
    }
  }
`;

export const FIND_ALL_PENDING_INVITATIONS = gql`
  query getAllPendingInvitations {
    getAllPendingInvitations {
      id
      email
      expiresAt
    }
  }
`;

const planFields = `
  id
  name
  price
  duration
  offersLimit
  productsLimit
  usersLimit
  hasTrial
  isPricePerUser
`;

export const FIND_ALL_PLANS = gql`
  query findAllPublicPlans {
    findAllPublicPlans {
      ${planFields}
    }
  }
`;

const subscriptionFields = `
  id
  currentPeriodStart
  currentPeriodEnd
  cancelAtPeriodEnd
  status
  plan {
    ${planFields}
  }
  nextActionSecret
`;

export const GET_SUBSCRIPTION = gql`
  query getCurrentSubscription {
    getCurrentSubscription {
      ${subscriptionFields}
    }
  }
`;

export const GET_SUBSCRIPTION_STATUS = gql`
  query getCurrentSubscriptionStatus {
    getCurrentSubscriptionStatus {
      id
      status
      plan {
        ${planFields}
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($planId: ID!) {
    updateSubscription(planId: $planId) {
      ${subscriptionFields}
    }
  }
`;

export const UPDATE_USER_ROLES = gql`
  mutation updateUserRoles(
    $userId: ID!
    $userUpdateRoleInputList: [UserUpdateRoleInput!]!
  ) {
    updateUserRoles(
      userId: $userId
      userUpdateRoleInputList: $userUpdateRoleInputList
    ) {
      id
      roles
    }
  }
`;

export const TRANSFER_OWNERSHIP = gql`
  mutation transferOwnership($userId: ID!) {
    transferOwnership(userId: $userId) {
      id
      roles
    }
  }
`;

export const RETRY_PAYMENT = gql`
  mutation retryPayment {
    retryPayment {
      ${subscriptionFields}
    }
  }
`;

const paymentMethodCardFields = `
  id
  brand
  last4
  extMonth
  extYear
  country
  address
`;

export const GET_DEFAULT_PAYMENT_METHOD = gql`
  query getDefaultPaymentMethod {
    getDefaultPaymentMethod {
      ${paymentMethodCardFields}
    }
  }
`;

export const REMOVE_DEFAULT_PAYMENT_METHOD = gql`
  mutation removeDefaultPaymentMethod {
    removeDefaultPaymentMethod {
      ${paymentMethodCardFields}
    }
  }
`;

export const UPDATE_DEFAULT_PAYMENT_METHOD = gql`
  mutation updateDefaultPaymentMethod($paymentMethodInput: PaymentMethodInput!) {
    updateDefaultPaymentMethod(paymentMethodInput: $paymentMethodInput) {
      ${paymentMethodCardFields}
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription {
    cancelSubscription {
      ${subscriptionFields}
    }
  }
`;

export const REACTIVATE_SUBSCRIPTION = gql`
  mutation reactivateSubscription {
    reactivateSubscription {
      ${subscriptionFields}
    }
  }
`;

export const REMOVE_USER_FROM_COMPANY = gql`
  mutation removeUserFromCompany($id: ID!) {
    removeUserFromCompany(id: $id)
  }
`;

export const RESEND_USER_INVITATION = gql`
  mutation resendUserInvitation($id: ID!, $origin: String!) {
    resendUserInvitation(id: $id, origin: $origin) {
      id
      email
    }
  }
`;

export const ADD_USER_TO_COMPANY = gql`
  mutation inviteUserToCompany($email: String!, $origin: String!) {
    inviteUserToCompany(email: $email, origin: $origin) {
      id
      email
    }
  }
`;

export const SEND_RESET_PASSWORD_LINK = gql`
  mutation sendResetPasswordLink($email: String!, $origin: String!) {
    sendResetPasswordLink(
      email: $email
      invalidateOldPassword: true
      origin: $origin
    ) {
      email
    }
  }
`;

export const GET_BILLING_DETAILS = gql`
  query getBillingDetails {
    getBillingDetails {
      countryCode
      value
      type
    }
  }
`;

export const UPDATE_BILLING_DETAILS = gql`
  mutation updateBillingDetails($billing: BillingDetailsInput!) {
    updateBillingDetails(billing: $billing) {
      value
      type
    }
  }
`;

export const DELETE_BILLING_DETAILS = gql`
  mutation deleteBillingDetails {
    deleteBillingDetails
  }
`;

export const ADD_DEFAULT_TAX = gql`
  mutation addDefaultTax($taxes: [CreateTaxInput!]!) {
    updateDefaultTaxes {
      add(taxes: $taxes) {
        ${COMPANY_FIELDS}
      }
    }
  }
`;

export const UPDATE_DEFAULT_TAX = gql`
  mutation updateDefaultTax($tax: UpdateTaxInput!) {
    updateDefaultTaxes {
      update(tax: $tax) {
        ${COMPANY_FIELDS}
      }
    }
  }
`;

export const DELETE_DEFAULT_TAX = gql`
  mutation updateDefaultTax($taxId: ID!) {
    updateDefaultTaxes {
      delete(taxId: $taxId) {
        ${COMPANY_FIELDS}
      }
    }
  }
`;

export const UPDATE_SMARTBILL_API_KEY = gql`
  mutation updateSmartBillApiKey($key: String!, $email: String!) {
    updateSmartBillApiKey (key: $key, email: $email){
      ${COMPANY_FIELDS}
    }
  }
`;

export const GET_VAT_RATE = gql`
  query getVatRate($country: String!) {
    getVatRate(country: $country) {
      standard
    }
  }
`;

export const UPDATE_GDRIVE_SETTINGS = gql`
  mutation updateGDriveSettings($gDriveTemplateFile: String!, $gDriveTargetFolder: String!, $gDriveEveryProduct: Boolean!) {
    updateGDriveSettings (gDriveTemplateFile: $gDriveTemplateFile, gDriveTargetFolder: $gDriveTargetFolder, gDriveEveryProduct: $gDriveEveryProduct){
      ${COMPANY_FIELDS}
    }
  }
`;

const userFields = `
  id
  name
  email
  title
  phoneNumber
  avatar {
    id
    thumbnail
    fileName
    width
    height
  }
  emailPreferences {
    smtpServer
    smtpPort
    smtpUser
    authenticationType
    smtpSecure

    smtpPassword

    smtpServiceClient
    smtpPrivateKey
  }
  connectedProvider
`;

export const GET_USER = gql`
  query getUserForProfile {
    currentUser {
      ${userFields}
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userUpdateInput: UserUpdateInput!) {
    updateUser(userUpdateInput: $userUpdateInput) {
      ${userFields}
    }
  }
`;

export const UPDATE_USER_EMAIL_PREFERENCES = gql`
  mutation updateUserEmailPreferences($emailPreferences: EmailPreferencesInput) {
    updateUserEmailPreferences(emailPreferences: $emailPreferences) {
      ${userFields}
    }
  }
`;

export const GET_LAST_PAGE_OFFER_DATA = gql`
  query getLastPageOfferData {
    getCompanyDetails {
      id
      name
      email
      website
      phoneNumber
      motto
      vat
      companyLogo {
        id
        fullImage
      }
      companyThankYouMessage
      currency {
        id
        symbol
      }
    }
  }
`;

export const CREATE_GMAIL_AUTH_URL = gql`
  query createGmailAuthUrl($origin: String!) {
    createGmailAuthUrl(origin: $origin)
  }
`;

export const CONNECT_TO_GMAIL = gql`
  mutation connectUserToGmail($code: String!, $origin: String!) {
    connectUserToGmail(code: $code, origin: $origin) {
      ${userFields}
    }
  }
`;
