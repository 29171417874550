import { ErrorModal } from '@dvkiin/material-commons';
import { ErrorWithMessage } from '@lib';
import { Typography } from '@material-ui/core';
import React, { FC } from 'react';

type ApolloModalErrorsProps = {
  errors: ErrorWithMessage[];
};

const ApolloModalErrors: FC<ApolloModalErrorsProps> = ({ errors }) => {
  function renderError(errorWithMessage: ErrorWithMessage) {
    if (
      !errorWithMessage.error
      // !(errorWithMessage.error as TimeStampedApolloError).timestamp
    )
      return null;
    return (
      <ErrorModal
        key={JSON.stringify(errorWithMessage.error)}
        error={errorWithMessage.error}
        message={
          (
            <>
              <Typography variant={'body1'} gutterBottom>
                {errorWithMessage.message}
              </Typography>
              {(errorWithMessage.error.graphQLErrors || []).map((error) => (
                <Typography
                  key={error.message}
                  variant="body2"
                  color="error"
                  gutterBottom
                >
                  {error.message}
                </Typography>
              ))}
            </>
          ) as any
        }
      />
    );
  }

  return <>{errors.map(renderError)}</>;
};

export default ApolloModalErrors;
