import { makeStyles } from '@material-ui/core';

import { OOTheme } from '../../theme';

export default makeStyles((theme: OOTheme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
  linearProgress: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },
  gridContainer: {
    height: '100%',
    textAlign: 'center',
  },
}));
