import { FlexExpander } from '@dvkiin/material-commons';
import { MenuMode, SecurityContext, useHasRole } from '@lib';
import { Badge, ListItemAvatar, withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import { NotificationsOutlined } from '@material-ui/icons';
import AccountIcon from '@material-ui/icons/AccountCircle';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useContext, useState } from 'react';
import { useLocation } from 'react-router';

import { UserRole } from '@optioffer/graphql';

import ProfileTypeChip from '@components/Chip/ProfileType';

import useStyles from '@containers/Layout/styles';

import logo from '@resources/OO_logo_wo_safespace_Secondary - Mark - Color.svg';

const ListItem = withStyles((theme) => ({
  root: {
    '&$selected': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      borderBottom: `2px ${theme.palette.primary.main} solid`,
      borderRadius: '5px 5px 0px 0px;',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: `${theme.palette.primary.main}`,
    },
    '&$selected:hover': {
      backgroundColor: 'transparent',
    },
    alignItems: 'center',
    display: 'flex',
    flexBasis: 0,
  },
}))(MuiListItem);

const ListItemText = withStyles((theme) => ({
  inset: {
    paddingLeft: theme.spacing(1),
  },
}))(MuiListItemText);

type DesktopMenuProps = { notificationCount: number | undefined };

const DesktopMenu: FC<DesktopMenuProps> = ({ notificationCount }) => {
  const classes = useStyles();
  // const localClasses = useLocalStyles();
  const context = useContext(SecurityContext);
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const isSuperAdmin = useHasRole(UserRole.SUPER_ADMIN);

  function handleLogout() {
    localStorage.removeItem('x-token');
    localStorage.removeItem('x-refresh-token');
    context.navigate('/login');
  }

  function renderAppBarButtons() {
    const open = !!anchorEl;

    return (
      <>
        {context.menuMode === MenuMode.QUOTING && (
          <Badge
            badgeContent={notificationCount || '0'}
            color="primary"
            className={classes.badge}
          >
            <NotificationsOutlined
              onClick={() => context.navigate('/virtual-assistant')}
              className={
                '/virtual-assistant' === location.pathname
                  ? classes.notificationSelected
                  : classes.notification
              }
            />
          </Badge>
        )}
        {context.currentProfile ? (
          <Button
            className={classes.appBarButton}
            aria-owns={open ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            startIcon={
              context.currentProfile?.image?.thumbnail ? (
                <Avatar src={context.currentProfile.image.thumbnail} />
              ) : (
                <AccountIcon className={classes.avatarIcon} />
              )
            }
          >
            {context.currentProfile.name}
          </Button>
        ) : (
          <Button
            className={classes.appBarButton}
            aria-owns={open ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            startIcon={
              context.currentUser?.avatar?.thumbnail ? (
                <Avatar src={context.currentUser.avatar.thumbnail} />
              ) : (
                <AccountIcon className={classes.avatarIcon} />
              )
            }
          >
            {context.currentUser.name}
          </Button>
        )}

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          getContentAnchorEl={null}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          {context.currentUser.profiles.map((profile) => (
            <MenuItem
              key={profile.id}
              onClick={() => {
                setAnchorEl(null);
                context.setCurrentProfile(profile);
              }}
            >
              <ListItemAvatar>
                {profile.image?.thumbnail ? (
                  <Avatar src={profile.image.thumbnail} />
                ) : (
                  <AccountIcon className={classes.avatarIcon} />
                )}
              </ListItemAvatar>
              <ListItemText
                primary={profile.name}
                secondary={<ProfileTypeChip status={profile.profileType} />}
              />
            </MenuItem>
          ))}

          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              context.navigate('/profile/new');
            }}
          >
            <ListItemText
              className={classes.menuItemText}
              disableTypography
              inset
              primary="+ Create a new Profile"
            />
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              context.navigate('/settings/profile');
            }}
          >
            <ListItemText
              className={classes.menuItemText}
              disableTypography
              inset
              primary="Quoting Account"
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              context.navigate('/settings/team');
            }}
          >
            <ListItemText
              className={classes.menuItemText}
              disableTypography
              inset
              primary="My Team"
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              context.navigate('/settings/general');
            }}
          >
            <ListItemText
              className={classes.menuItemText}
              disableTypography
              inset
              primary="Quote Settings"
            />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              context.navigate('/support');
            }}
          >
            <ListItemText
              className={classes.menuItemText}
              disableTypography
              inset
              primary="Help & Feedback"
            />
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemText
              inset
              primary="Log out"
              className={classes.logoutText}
              disableTypography
            />
          </MenuItem>
        </Menu>
      </>
    );
  }

  function renderQuotingMenu() {
    return (
      <>
        <ListItem
          button
          onClick={() =>
            context.navigate('/offer?order=desc&orderBy=createdAt')
          }
          selected={'/offer' === location.pathname}
        >
          <ListItemText primary="Quotes" className={classes.listItemText} />
        </ListItem>

        <ListItem
          button
          onClick={() => context.navigate('/product')}
          selected={'/product' === location.pathname}
        >
          <ListItemText primary="Products" className={classes.listItemText} />
        </ListItem>

        <ListItem
          button
          onClick={() => context.navigate('/client')}
          selected={'/client' === location.pathname}
        >
          <ListItemText primary="Customers" className={classes.listItemText} />
        </ListItem>

        <ListItem
          button
          onClick={() => context.navigate('/supplier')}
          selected={'/supplier' === location.pathname}
        >
          <ListItemText primary="Suppliers" className={classes.listItemText} />
        </ListItem>
      </>
    );
  }

  function renderProfessionalMenu() {
    return (
      <>
        <ListItem
          button
          onClick={() => context.navigate('/')}
          selected={'/' === location.pathname}
        >
          <ListItemText
            primary="PRO Dashboard"
            className={classes.listItemText}
          />
        </ListItem>
      </>
    );
  }

  function renderEmployeeMenu() {
    return (
      <>
        <ListItem
          button
          onClick={() => context.navigate('/')}
          selected={'/' === location.pathname}
        >
          <ListItemText
            primary="Employee Dashboard"
            className={classes.listItemText}
          />
        </ListItem>
      </>
    );
  }

  function renderProEmployeeMenu() {
    return (
      <>
        <ListItem
          button
          onClick={() => context.navigate('/')}
          selected={'/' === location.pathname}
        >
          <ListItemText
            primary="PRO & Employee Dashboard"
            className={classes.listItemText}
          />
        </ListItem>
      </>
    );
  }

  return (
    <Toolbar className={classes.toolbar}>
      <img
        src={logo}
        alt="OptiOffer logo"
        className={clsx(classes.logo)}
        onClick={() => context.navigate('/')}
      />

      {context.menuMode === MenuMode.QUOTING && renderQuotingMenu()}
      {context.menuMode === MenuMode.PRO && renderProfessionalMenu()}
      {context.menuMode === MenuMode.EMPLOYEE && renderEmployeeMenu()}
      {context.menuMode === MenuMode.PRO_EMPLOYEE && renderProEmployeeMenu()}

      {isSuperAdmin && (
        <>
          <ListItem
            button
            onClick={() => context.navigate('/admin/plan')}
            className={clsx(classes.adminGroup)}
          >
            <ListItemText primary="Plans" />
          </ListItem>
          <ListItem
            button
            onClick={() => context.navigate('/admin/company')}
            className={clsx(classes.adminGroup)}
          >
            <ListItemText primary="Companies" />
          </ListItem>
          <ListItem
            button
            onClick={() => context.navigate('/admin/profile')}
            className={clsx(classes.adminGroup)}
          >
            <ListItemText primary="Profiles" />
          </ListItem>
          <ListItem
            button
            onClick={() => context.navigate('/admin/project')}
            className={clsx(classes.adminGroup)}
          >
            <ListItemText primary="Projects" />
          </ListItem>
          <ListItem
            button
            onClick={() => context.navigate('/admin/catalog')}
            className={clsx(classes.adminGroup)}
          >
            <ListItemText primary="Catalogs" />
          </ListItem>
        </>
      )}

      <FlexExpander />
      {renderAppBarButtons()}
    </Toolbar>
  );
};

export default DesktopMenu;
